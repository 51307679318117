import { BASE_PATH } from 'shared/constants/constants';
import {
  IClassificationDictionaryValueGetDto,
  IClassificationDictionaryValueParamsDto,
  IPostClassificationDictionaryValueDto,
  IPutClassificationDictionaryValueDto,
} from 'shared/interfaces';
import HTTPService from './HttpService';

class ClassificationDictionaryValue extends HTTPService {
  constructor() {
    super(BASE_PATH.CLASSIFICATION_DICTIONARIES_VALUES);
  }

  public getClassificationDictionaryValues(params: IClassificationDictionaryValueParamsDto): Promise<IClassificationDictionaryValueGetDto[]> {
    return this.GET('', { params });
  }

  public postClassificationDictionaryValue(body: IPostClassificationDictionaryValueDto): Promise<Promise<number>> {
    return this.POST('', body);
  }

  public putClassificationDictionaryValue(id: number, body: IPutClassificationDictionaryValueDto) {
    return this.PUT(`${id}`, body);
  }

  public deleteClassificationDictionaryValue(id: number) {
    return this.DELETE(`${id}`);
  }
}

export default new ClassificationDictionaryValue();
