import React from 'react';
import { observer } from 'mobx-react-lite';
import { parameterGroupStore } from 'stores';
import InputSearch from 'components/UI/InputSearch';
import { ParameterGroupModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

interface IParameterGroupTreeSearchProps {
  isExpanded?: boolean;
  onScroll: (key: React.Key) => void;
  onChangeParamGroup?: (value: ParameterGroupModel) => void;
  onCloseSearch?: () => void;
}

const ParameterGroupTreeSearch: React.FC<IParameterGroupTreeSearchProps> = (props) => {
  const { isExpanded, onChangeParamGroup, onScroll, onCloseSearch } = props;

  const firstAndLastKey = (): { first: boolean; last: boolean } => {
    const first = parameterGroupStore.searchArrays[0].key;
    const last = parameterGroupStore.searchArrays[parameterGroupStore.searchArrays.length - 1].key;

    if (parameterGroupStore.selectedParamGroup.key === first) return { first: true, last: false };
    else if (parameterGroupStore.selectedParamGroup.key === last) return { first: false, last: true };

    return { first: false, last: false };
  };

  const onSearch = () => {
    const newExpandedKeys = [...parameterGroupStore.expandedKeys];

    if (parameterGroupStore.searchValue !== '') {
      const findNodes = Utils.findSearch(parameterGroupStore.parameterGroupTree, parameterGroupStore.searchValue);

      if (findNodes.length !== 0) {
        // Expand tree
        findNodes.forEach((find) => {
          const item = parameterGroupStore.listTransformParamGroupDto.find((dto) => dto.key === find.key);
          if (item && item.parentId) {
            const parents = [item.parentId];
            for (const parentId of parents) {
              const parent = parameterGroupStore.listTransformParamGroupDto.find((dto) => dto.id === parentId);
              if (parent) {
                if (!newExpandedKeys.includes(parent.key)) {
                  newExpandedKeys.push(parent.key);
                }

                if (parent.parentId) {
                  parents.push(parent.parentId);
                }
              }
            }
          }
        });
      }

      parameterGroupStore.setSearchArrays(findNodes);
      parameterGroupStore.setExpandedKeys(newExpandedKeys);

      if (findNodes.length !== 0) {
        const findKey = parameterGroupStore.listTransformParamGroupDto.find((item) => item.key === findNodes[0].key);
        parameterGroupStore.selectedParamGroup = new ParameterGroupModel(findKey);
        onScroll(parameterGroupStore.searchArrays[0].key);
        onChangeParamGroup && onChangeParamGroup(parameterGroupStore.selectedParamGroup);
      }
    } else parameterGroupStore.setSearchArrays([]);
  };

  const onSearchParameter = (event: React.ChangeEvent<HTMLInputElement>) => {
    parameterGroupStore.setSearchValue(event.target.value);

    onSearch();
  };

  const updateParameterGroup = (searchDirection: 1 | -1) => {
    const findIndex = parameterGroupStore.searchArrays.findIndex((item) => item.key === parameterGroupStore.selectedParamGroup.key);

    const findKey = parameterGroupStore.listTransformParamGroupDto.find(
      (item) => item.key === parameterGroupStore.searchArrays[findIndex + searchDirection].key
    );

    parameterGroupStore.selectedParamGroup = new ParameterGroupModel(findKey);
    onScroll(parameterGroupStore.searchArrays[findIndex + searchDirection].key);
  };

  const onUpSearch = () => {
    updateParameterGroup(-1);
    onChangeParamGroup && onChangeParamGroup(parameterGroupStore.selectedParamGroup);
  };

  const onDownSearch = () => {
    updateParameterGroup(1);
    onChangeParamGroup && onChangeParamGroup(parameterGroupStore.selectedParamGroup);
  };

  const onClose = () => {
    parameterGroupStore.setSearchValue('');

    onCloseSearch && onCloseSearch();
  };

  return (
    <InputSearch
      isExpanded={isExpanded}
      value={parameterGroupStore.searchValue}
      isArrayResult={parameterGroupStore.searchArrays.length > 1}
      disabledPrev={parameterGroupStore.searchArrays.length <= 1 || firstAndLastKey().first}
      disabledNext={parameterGroupStore.searchArrays.length <= 1 || firstAndLastKey().last}
      onPrev={onUpSearch}
      onNext={onDownSearch}
      onSearch={onSearch}
      onChange={onSearchParameter}
      onClose={onClose}
    />
  );
};

export default observer(ParameterGroupTreeSearch);
