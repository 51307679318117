import { makeAutoObservable, runInAction } from 'mobx';
import { arrayToTree } from 'performant-array-to-tree';
import { DataNode } from 'rc-tree/lib/interface';
import { Translator, itemStore, sectionsStore, toastStore, xmlDocumentInstanceStore } from 'stores';
import ClassService from 'services/ClassService';
import { ObjectLayer, ObjectLayerNames } from 'shared/enums';
import { IClassDto, IClassParamsDto, ICreateItemForm, ISelectOption, ITransformClassDto } from 'shared/interfaces';
import { ClassModel, ItemModel } from 'shared/models';
import TreeModel from 'shared/models/TreeModel';
import Utils from 'shared/utils/Utils';

class ClassStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public waiting = false;

  public classesDto: IClassDto[] = [];

  public class: ClassModel[] = [];

  public allClass: ITransformClassDto[] = [];

  public classObject = new ClassModel();

  public treeObject = new TreeModel();

  public classById: IClassDto | null = null;

  public classFilters: IClassParamsDto = { layerId: ObjectLayer.Main };

  public isDialogCreateOpen = false;

  public isDialogEditOpen = false;

  public isDialogDeleteOpen = false;

  public KeyInMain = false;

  public get classLayerOptions(): ISelectOption[] {
    return [
      {
        id: ObjectLayer.Main,
        name: ObjectLayerNames.get(ObjectLayer.Main)!,
      },
      {
        id: ObjectLayer.Additional,
        name: ObjectLayerNames.get(ObjectLayer.Additional)!,
      },
    ];
  }

  public setSelectedLayer(layer?: ObjectLayer) {
    this.classFilters.layerId = layer;
  }

  public setIsDialogCreateOpen(value: boolean) {
    this.isDialogCreateOpen = value;
  }

  public setIsDialogEditOpen(value: boolean) {
    this.isDialogEditOpen = value;
  }

  public setIsDialogDeleteOpen(value: boolean) {
    this.isDialogDeleteOpen = value;
  }

  public setKeyInMain(value: boolean) {
    this.KeyInMain = value;
  }

  //выбранные узлы содержат много родителей или же только один
  public isMoreParentsInCheckedNode(): boolean {
    const isMoreParents: boolean[] = [];

    this.treeObject.checkedNodesInfo.forEach((checkedKey) => {
      const findNode = this.allClass.find((item) => item.id === checkedKey.id);
      if (findNode !== undefined) {
        isMoreParents.push(findNode.parents.length > 1 ? true : false);
      }
    });

    return isMoreParents.includes(true);
  }

  public get classOptions(): ISelectOption[] {
    const sortClasses = Utils.sortByField(this.classesDto, 'name');
    return sortClasses.map((item) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
  }

  public get allChildByClassIdForItem(): ICreateItemForm[] {
    const res = this.allClass
      .filter((el) => el.parents.includes(itemStore.selectItem.class!.id))
      .map((radio) => {
        return {
          id: radio.id,
          name: radio.name,
        };
      });

    return res;
  }

  public filterClassForSection() {
    const filterClasses = this.classesDto.filter((f) => f.layerId === this.classFilters.layerId);

    this.class = this.classTreeData(filterClasses).map((dto) => new ClassModel(dto));

    this.treeObject.setExpandedKeys(this.allClass.filter((f) => f.layerId === this.classFilters.layerId).map((cl) => cl.key));

    const filterSectionClass = this.allClass.filter(
      (f) =>
        sectionsStore.selectedSection.classConstraints.find((classConstraint) => f.key === classConstraint.classKey) &&
        f.layerId === this.classFilters.layerId
    );

    this.treeObject.setCheckedKeys(filterSectionClass.map((cl) => cl.key));
    this.treeObject.checkedNodesInfo = filterSectionClass;

    this.treeObject.updateTreeData(this.class);
  }

  public filterClassForXMLNode() {
    const filterClasses = this.classesDto.filter((f) => f.layerId === this.classFilters.layerId);

    this.class = this.classTreeData(filterClasses).map((dto) => new ClassModel(dto));

    this.treeObject.setExpandedKeys(this.allClass.filter((f) => f.layerId === this.classFilters.layerId).map((cl) => cl.key));

    const filterSectionClass = this.allClass.filter(
      (f) =>
        xmlDocumentInstanceStore.selectedXMLNode.classConstraints.find((classConstraint) => f.key === classConstraint.classKey) &&
        f.layerId === this.classFilters.layerId
    );

    this.treeObject.setCheckedKeys(filterSectionClass.map((cl) => cl.key));
    this.treeObject.checkedNodesInfo = filterSectionClass;

    this.treeObject.updateTreeData(this.class);
  }

  public classTreeData(dto: IClassDto[]): DataNode[] {
    const transformDto: ITransformClassDto[] = [];
    this.allClass = [];

    dto.forEach((value) => {
      if (value.parents.length <= 1) {
        const newClass: ITransformClassDto = {
          ...value,
          title: value.name,
          key: String(value.id),
          parentId: value.parents.length === 0 ? null : value.parents[0],
          parentName: dto.some((item) => item.id === value.parents[0]) ? dto.find((item) => item.id === value.parents[0])?.name ?? '' : null,
        };

        transformDto.push(newClass);
      } else {
        value.parents.forEach((parentId) => {
          const newClass: ITransformClassDto = {
            ...value,
            title: value.name,
            key: String(value.id),
            parentId,
            parentName: dto.some((item) => item.id === parentId) ? dto.find((item) => item.id === parentId)?.name ?? '' : null,
          };

          transformDto.push(newClass);
        });
      }
    });

    const treeData = arrayToTree(transformDto, { dataField: null }) as any[];

    //добавление уникального ключа
    const loop = (data: any[], prefix?: string): any[] =>
      data.map((item: any) => {
        if (item.children) {
          const data = {
            ...item,
            key: prefix ? `${prefix}-${item.key}` : `${item.key}`,
            children: loop(item.children, prefix ? `${prefix}-${item.key}` : `${item.key}`),
          };

          this.allClass.push(data);

          return data;
        }

        const rootData = {
          ...item,
          key: prefix ? `${prefix}-${item.key}` : `${item.key}`,
        };

        this.allClass.push(rootData);

        return rootData;
      });

    return loop(treeData);
  }

  public createClassTree() {
    this.class = this.classTreeData(this.classesDto).map((dto) => new ClassModel(dto));
    if (this.treeObject.expandedKeys.length === 0) {
      const expandedKeys: React.Key[] = [];
      for (const item of this.class) {
        expandedKeys.push(item.key);
      }
      this.treeObject.setExpandedKeys(expandedKeys);
    }
    this.treeObject.updateTreeData(this.class);
  }

  public async getClasses() {
    try {
      this.waiting = true;

      const result = await ClassService.getAllClasses(this.classFilters);
      if (!result) return;

      this.classesDto = result;
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Classes`);
    } finally {
      this.waiting = false;
    }
  }

  public async getClassChildrenList(): Promise<void> {
    try {
      this.waiting = true;

      const result = await ClassService.getClassChildren({
        layerId: itemStore.selectItem.class?.layerId ?? 1,
        parentId: itemStore.selectItem.class?.id ?? null,
      });
      if (!result) return;

      itemStore.createItems = result.map(
        (cl) => new ItemModel({ class: { id: cl.id, name: cl.name }, isMaster: false, parentId: itemStore.selectItem.id })
      );
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} ClassesChildren`);
    } finally {
      this.waiting = false;
    }
  }

  public async getClassById() {
    try {
      this.waiting = true;

      const dtos = await ClassService.getClassById(this.treeObject.selected.id);
      if (!dtos) return;

      runInAction(() => {
        this.classById = dtos;
        this.classObject = new ClassModel(dtos);
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} Class`);
    } finally {
      this.waiting = false;
    }
  }

  public async createClass(): Promise<void> {
    if (!this.classObject.postDto) return;

    try {
      await ClassService.postClass(this.classObject.postDto()!);

      await this.getClasses();
      this.createClassTree();
      this.classObject.clearModel();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} InformationBlock`);
    } finally {
      this.setIsDialogCreateOpen(false);
    }
  }

  public async updateClass(): Promise<void> {
    if (!this.classObject.putDto()) return;

    ClassService.putClass(this.treeObject.selected.id, this.classObject.putDto()!)
      .then(async () => {
        await this.getClasses();
        this.createClassTree();
        this.setIsDialogEditOpen(false);
      })
      .catch((e) => {
        const mes = e.data.extensions.validationErrors.parents;
        toastStore.showError(mes ?? e.data?.message);
      });
  }

  public async copyClass(): Promise<void> {
    if (!this.classObject.putCopyDto()) return;

    ClassService.putClass(this.classObject.putCopyDto()?.id!, this.classObject.putCopyDto()!)
      .then(async () => {
        await this.getClasses();
        this.createClassTree();
        this.classObject.clearModel();
      })
      .catch((e) => {
        const mes = e.data.extensions.validationErrors.parents;
        toastStore.showError(mes ?? e.data?.message);
      })
      .finally(() => {
        this.setIsDialogCreateOpen(false);
      });
  }

  public async deleteAllClass(): Promise<void> {
    try {
      const promises = this.treeObject.checkedNodesInfo.map((checkedKey) => {
        return ClassService.deleteClass(checkedKey.id);
      });

      this.treeObject.resetCheckedSelectedNodes();
      const results = await Promise.allSettled(promises).finally(() => this.getClasses());
      this.createClassTree();

      const rejectStatus: any = results.filter((f) => f.status === 'rejected');
      if (rejectStatus.length !== 0) {
        rejectStatus.forEach((value: any) => toastStore.showError(value.reason.data.message));
      }

      this.setIsDialogEditOpen(false);
      this.classObject.clearModel();
    } catch (e: any) {
      const mes = e.data.extensions.validationErrors.parents;
      toastStore.showError(mes ?? e.data?.message);
    }
  }

  public preparedDataForDelete(): IClassDto[] {
    const array: IClassDto[] = [];

    this.treeObject.checkedNodesInfo.map((checkedKeyInfo) => {
      if (array.length === 0) array.push(checkedKeyInfo);
      else {
        const findIndex = array.findIndex((item) => item.id === checkedKeyInfo.id);
        if (findIndex === -1) array.push(checkedKeyInfo);
      }

      array.forEach((item) => {
        const findIndex = item.parents.findIndex((parent) => parent === checkedKeyInfo.parentId);
        if (findIndex !== -1) item.parents.splice(findIndex, 1);
      });
    });

    return array;
  }

  public async deleteCheckedClass() {
    try {
      const dataForDelete = this.preparedDataForDelete();

      const promises = dataForDelete.map((data) => {
        if (data.parents.length === 0) return ClassService.deleteClass(data.id);
        else {
          return ClassService.putClass(data.id, { id: data.id, name: data.name, parents: data.parents });
        }
      });

      const results = await Promise.allSettled(promises).finally(() => this.getClasses());
      this.createClassTree();

      const rejectStatus: any = results.filter((f) => f.status === 'rejected');
      if (rejectStatus.length !== 0) {
        toastStore.showError(rejectStatus[0].reason.data.message);
      }

      this.treeObject.resetCheckedSelectedNodes();
    } catch (e: any) {
      const mes = e.data.extensions.validationErrors.parents;
      toastStore.showError(mes ?? e.data?.message);
    }
  }
}

export default new ClassStore();
