import React from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Translator, mergeStore, parameterGroupStore } from 'stores';
import { TableViewCollapse } from 'components';
import { Box, Link } from '@mui/material';
import { DataType, LangNames } from 'shared/enums';
import { ISectionParametersDto, ITableColumn } from 'shared/interfaces';
import { CollapseTableModel } from 'shared/models';
import { ParameterValueMergeTableModel } from 'shared/models/MergeModel';

const ParameterValueMergeTable: React.FC = () => {
  const displayNameDataType = (row: ParameterValueMergeTableModel, value: string) => {
    if (row.dataType === DataType.Boolean) {
      if (value === 'true') return Translator.translate('system.true');
      else if (value === 'false') return Translator.translate('system.false');
    } else if (row.dataType === DataType.Link) {
      return (
        <Link href={value as string} target="_blank">
          {value}
        </Link>
      );
    } else if (row.dataType === DataType.DateTime) {
      return dayjs(value as string).format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY');
    } else {
      return value;
    }
  };

  const columns: ITableColumn[] = [
    {
      key: 'checkbox',
      label: '',
      width: '2.5%',
      align: 'left',
      cell: () => {
        return <div>{''}</div>;
      },
    },
    {
      key: 'parameterName',
      label: Translator.translate('merge.columns.parameterName'),
      width: '25%',
      align: 'left',
      cell: (row: ParameterValueMergeTableModel) => {
        return (
          <Box
            sx={(theme) => ({
              wordBreak: 'break-word',
              color: theme.palette.text.disabled,
            })}>
            {row.parameterName}
          </Box>
        );
      },
    },
    {
      key: 'itemName',
      label: Translator.translate('merge.columns.itemName'),
      width: '25%',
      align: 'left',
      cell: (row: ParameterValueMergeTableModel) => {
        return (
          <Box
            sx={(theme) => ({
              wordBreak: 'break-word',
              color: theme.palette.text.disabled,
            })}>
            {row.itemName}
          </Box>
        );
      },
    },
    {
      key: 'currentValue',
      label: Translator.translate('merge.columns.currentValue'),
      width: '25%',
      align: 'left',
      cell: (row: ParameterValueMergeTableModel) => {
        return (
          <Box
            sx={(theme) => ({
              wordBreak: 'break-word',
              color: theme.palette.text.disabled,
              textDecoration: 'line-through',
            })}>
            {row.newValue && displayNameDataType(row, row.newValue)}
          </Box>
        );
      },
    },
    {
      key: 'newValue',
      label: Translator.translate('merge.columns.newValue'),
      width: '25%',
      align: 'left',
      cell: (row: ParameterValueMergeTableModel) => {
        return <Box sx={{ wordBreak: 'break-word' }}>{row.currentValue && displayNameDataType(row, row.currentValue)}</Box>;
      },
    },
  ];

  const checkedMergeTableCollapse = (checked: number[] | ISectionParametersDto[], row?: any, e?: any, parentRow?: any) => {
    if (parentRow) {
      const findEl = (mergeStore.merge.mergeData as CollapseTableModel<any>[]).find((f) => f.id === parentRow.id);

      if (findEl && findEl.groupItems.some((f) => !f.checked)) {
        mergeStore.merge.updateCheckedIds(parentRow.id, false);
      } else if (findEl && findEl.groupItems.every((f) => f.checked)) {
        mergeStore.merge.updateCheckedIds(parentRow.id, true);
      }
    } else {
      mergeStore.merge.updateCheckedIds(row.id, row.checked);
    }
  };

  return (
    <TableViewCollapse
      data={mergeStore.merge.mergeData as CollapseTableModel<any>[]}
      columns={columns}
      isCollapse={false}
      isHideHeaderCheckbox
      isCheckedOnClickLine
      onChecked={checkedMergeTableCollapse}
      scrollRowId={parameterGroupStore.selectedParamGroup.id!}
      height="100%"
    />
  );
};

export default observer(ParameterValueMergeTable);
