import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { Translator } from 'stores';
import { Typography } from '@mui/material';
import { PAGE_TITLE_HEIGHT } from 'shared/constants/constants';
import { Pages, Routes } from 'shared/enums/Routes';

const PageTitle: React.FC = () => {
  const location = useLocation();

  const getCurrentPageLabel = () => {
    if (Object.values(Routes).includes(location.pathname as Routes)) {
      return Translator.translate(Pages.get(location.pathname as Routes));
    } else if (location.pathname.includes(Routes.OBJECT_VALUE)) {
      return Translator.translate(Pages.get(Routes.OBJECT_VALUE));
    } else if (location.pathname.includes(Routes.COMMENTS)) {
      return Translator.translate(Pages.get(Routes.COMMENTS));
    } else if (location.pathname.includes(Routes.MERGE)) {
      return Translator.translate(Pages.get(Routes.MERGE));
    } else if (location.pathname.includes(Routes.CATALOG)) {
      return Translator.translate(Pages.get(Routes.CATALOG));
    } else if (location.pathname.includes(Routes.APPROVALS)) {
      return Translator.translate(Pages.get(Routes.APPROVALS));
    } else if (location.pathname.includes(Routes.SPECIFICATIONS)) {
      return Translator.translate(Pages.get(Routes.SPECIFICATIONS));
    } else if (location.pathname.includes(Routes.CLASSES)) {
      return Translator.translate(Pages.get(Routes.CLASSES));
    } else if (location.pathname.includes(Routes.PARAM)) {
      return Translator.translate(Pages.get(Routes.PARAM));
    } else if (location.pathname.includes(Routes.CATALOG)) {
      return Translator.translate(Pages.get(Routes.CATALOG));
    } else if (location.pathname.includes(Routes.SETTINGS)) {
      return Translator.translate(Pages.get(Routes.SETTINGS));
    } else if (location.pathname.includes(Routes.XML_VIEWER)) {
      return Translator.translate(Pages.get(Routes.XML_VIEWER));
    } else return '';
  };

  return (
    <Typography variant="h6" sx={{ minHeight: `${PAGE_TITLE_HEIGHT}px` }}>
      {getCurrentPageLabel()}
    </Typography>
  );
};

export default observer(PageTitle);
