import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Translator, sectionSetCollectionStore, sectionSetStore, sectionsStore, stageStore } from 'stores';
import { IconButtonNew, NestedTable } from 'components';
import { useConfirm } from 'components/App/Confirm';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import PlusIcon from 'components/Icons/PlusIcon';
import TrashIcon from 'components/Icons/TrashIcon';
import { Box, Checkbox, FormControlLabel, MenuItem, Typography } from '@mui/material';
import { NestedLevel } from 'shared/enums';
import { ISectionSetCollection, ITableColumn } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';
import { EditCollectionDialog, SpecTemplateDialog } from './Components';

const HEIGHT_CONTENT = 60;

const SpecificationTemplate: React.FC = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [hoveredRowId, setHoveredRowId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    stageStore.getStages();

    setIsLoading(true);
    sectionSetStore.getSectionSets().finally(() => setIsLoading(false));

    return () => {
      sectionSetStore.clearFilterStageIds();

      runInAction(() => {
        sectionsStore.nestedSections = [];
        sectionSetStore.filterName = '';
      });
    };
  }, []);

  const columns: ITableColumn[] = [
    {
      key: 'collapse',
      label: '',
      align: 'left',
      calcWidth: (row: ISectionSetCollection) => (row.level === NestedLevel.First ? '30px' : '40px'),
    },
    {
      key: 'name',
      label: Translator.translate('catalogs.specTemplates.columns.name'),
      align: 'left',
      calcWidth: (row: ISectionSetCollection) => (row.level === NestedLevel.First ? '620px' : '610px'),
      cell: (row: ISectionSetCollection) => (
        <Typography
          variant="body2"
          sx={{
            color: row.level === NestedLevel.Second && row.id === hoveredRowId ? 'primary.main' : 'text.primary',
            textAlign: 'left',
            wordBreak: 'break-word',
            fontWeight: row.level === NestedLevel.First ? 500 : 400,
          }}>
          {row.name}
        </Typography>
      ),
    },
    {
      key: 'stages',
      label: Translator.translate('catalogs.specTemplates.columns.stages'),
      width: 'auto',
      minWidth: '200px',
      align: 'left',
      hasFilters: sectionSetStore.filterStageIds.length > 0,
      filterOptions: (): Array<JSX.Element> => {
        return stageStore.stageForOptions.map((el) => (
          <MenuItem sx={{ minWidth: '200px' }} key={el.id} component="li">
            <FormControlLabel
              sx={{ ml: 0, mr: 4, width: '100%' }}
              control={
                <Checkbox
                  checked={sectionSetStore.filterStageIds.includes(el.id as number)}
                  onChange={() => {
                    sectionSetStore.setFilterStageIds(el.id as number);
                    sectionSetStore.getSectionSets();
                  }}
                />
              }
              label={el.name}
            />
          </MenuItem>
        ));
      },
      cell: (row: ISectionSetCollection) => {
        return <div>{row.stages.map((el) => el.stage.name).join(', ')}</div>;
      },
    },
    {
      key: 'actions',
      label: '',
      width: '200px',
      minWidth: '150px',
      align: 'right',
      cell: (row: ISectionSetCollection) => {
        const onEditSectionSetCollection = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();

          sectionSetCollectionStore.setDialogOpen(true);
          sectionSetCollectionStore.getSectionSetCollection(row.id!);
        };

        const onEditSectionSet = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();

          sectionSetStore.setDialogOpen(true);
          await sectionSetStore.getSectionSetById(row.id!);
        };

        const onCreateSectionSet = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();

          sectionSetStore.setDialogOpen(true);
          sectionSetStore.selectedSectionSet.setSectionSetCollectionId(row.id!);
        };

        const onRemoveSectionSet = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();

          confirm
            .show(Translator.translate('catalogs.specTemplates.confirmMessage.deleteTemplate'), Translator.translate('actions.delete'))
            .then(() => sectionSetStore.removeSectionSet(row.id!));
        };

        return (
          <Stack
            mr={2}
            flexDirection="row"
            gap={2}
            justifyContent="flex-end"
            alignItems="center"
            sx={(theme) => ({
              'button svg': {
                ':hover': {
                  fill: theme.palette.primary.main,
                  fillOpacity: 1,
                },
              },
            })}>
            <IconButtonNew
              tooltip={
                row.level === NestedLevel.First
                  ? Translator.translate('catalogs.specTemplates.renameTitle')
                  : Translator.translate('catalogs.specTemplates.editTitle')
              }
              onClick={(e) => (row.level === NestedLevel.First ? onEditSectionSetCollection(e) : onEditSectionSet(e))}>
              <EditPencilIcon />
            </IconButtonNew>

            {row.level === NestedLevel.First && (
              <IconButtonNew tooltip={Translator.translate('catalogs.specTemplates.createTitle')} onClick={(e) => onCreateSectionSet(e)}>
                <PlusIcon />
              </IconButtonNew>
            )}

            {row.level === NestedLevel.Second && (
              <IconButtonNew tooltip={Translator.translate('actions.delete')} onClick={onRemoveSectionSet}>
                <TrashIcon />
              </IconButtonNew>
            )}
          </Stack>
        );
      },
    },
  ];

  const onHover = (rowId: number | null) => {
    setHoveredRowId(rowId);
  };

  const onRedirectSection = (selectedRow: ISectionSetCollection) => {
    if (selectedRow.level !== NestedLevel.Second) return;

    //Переход в разделы
    sectionsStore.setFilterSectionSetId(selectedRow.id);
    navigate(`${selectedRow.id}`);
  };

  return (
    <>
      <Box sx={{ height: `calc(100vh - ${HEIGHT_CONTENT + sectionSetStore.filterHeight! + Utils.projectHeight()}px)` }}>
        <NestedTable<ISectionSetCollection>
          columns={columns}
          tableData={sectionSetStore.sectionSetCollections}
          isLoading={isLoading}
          isColorFirstLevel={true}
          isActionsHover
          isExpandOnClickRow
          hasFilteredData={sectionSetStore.filterName !== ''}
          onSelectRow={(selectedRow) => onRedirectSection(selectedRow as ISectionSetCollection)}
          onHover={onHover}
          hoveredRowIds={sectionSetStore.hoveredRowIds}
        />
      </Box>

      {sectionSetStore.isDialogOpen && <SpecTemplateDialog />}
      {sectionSetCollectionStore.isDialogOpen && <EditCollectionDialog />}
    </>
  );
};

export default observer(SpecificationTemplate);
