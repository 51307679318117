import { DependencyList, EffectCallback, useEffect } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { Translator, approvalStore, definitionStore, sectionSetStore, sectionsStore, specificationStore } from 'stores';
import { DownloadSpecificationButton } from 'components';
import ArrowLeftIcon from 'components/Icons/ArrowLeftIcon';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import UnExpandIcon from 'components/Icons/UnExpandIcon';
import { ApprovalDialog } from 'pages/Approvals/ApprovalPage/Components';
import { Box, Button, Chip, Paper, Skeleton, Stack, Typography, Link as MuiLink } from '@mui/material';
import { LangNames, SectionMode, SpecificationApprovalStatus, SpecificationApprovalStatusNames } from 'shared/enums';
import { Routes } from 'shared/enums/Routes';

const watchParam = (id: string | undefined, request: (id: number) => void): [EffectCallback, DependencyList] => [
  () => {
    if (id) {
      request.call(specificationStore, Number(id));
    }
  },
  [id],
];

interface ISectionsPageProps {
  mode: SectionMode;
}

const SectionsPage: React.FC<ISectionsPageProps> = ({ mode }) => {
  const { specificationId, sectionSetId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      sectionSetStore.clearFilterStageIds();
      specificationStore.selectedSpecification.clear();

      runInAction(() => {
        sectionSetStore.currentSectionSet = null;
        specificationStore.currentSpecification = null;
      });
    };
  }, []);

  useEffect(...watchParam(specificationId, specificationStore.getCurrentSpecification));
  useEffect(...watchParam(sectionSetId, sectionSetStore.getCurrentSectionSet));

  const onOpenSectionParameters = () => {
    sectionsStore.setOpenParameters(true);
  };

  const onOpenCreateDialog = () => {
    sectionsStore.setDialogOpen(true);
  };

  const paint = (status: SpecificationApprovalStatus) => {
    switch (status) {
      case SpecificationApprovalStatus.Approved:
        return 'success';

      /*case SpecificationApprovalStatus.InProgress:
        return 'primary';

      case SpecificationApprovalStatus.Formed:
        return 'warning';*/

      case SpecificationApprovalStatus.PartiallyApproved:
        return 'info';
    }
  };

  return (
    <Box
      sx={
        mode === SectionMode.Specification
          ? { p: 6, borderRadius: '12px', display: 'flex', flexDirection: 'column', flex: 1, position: 'relative', boxShadow: 'none' }
          : { position: 'relative' }
      }
      component={mode === SectionMode.Specification ? Paper : Box}>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Stack direction="row" gap={3} alignItems="center">
          {mode === SectionMode.Template ? (
            <Link style={{ display: 'flex', alignItems: 'center' }} to={`${Routes.CATALOG}/${Routes.TEMPLATES}`}>
              <ArrowLeftIcon />
            </Link>
          ) : (
            <MuiLink style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => navigate(-1)}>
              <ArrowLeftIcon />
            </MuiLink>
          )}

          <Stack flexDirection="row" justifyContent="flex-start" alignItems="center" gap={2}>
            {sectionSetStore.isLoading || specificationStore.isLoading ? (
              <Box sx={{ width: 100 }}>
                <Skeleton />
              </Box>
            ) : (
              <>
                {mode === SectionMode.Template ? (
                  <Typography variant="h5">{sectionSetStore.currentSectionSet?.name}</Typography>
                ) : (
                  <Typography variant="h5">{specificationStore.currentSpecification?.name}</Typography>
                )}
              </>
            )}

            {mode === SectionMode.Specification && (
              <Chip
                variant="outlined"
                size="small"
                color={paint(specificationStore.currentSpecification?.status!)}
                label={
                  SpecificationApprovalStatusNames.get(specificationStore.currentSpecification?.status!)
                    ? Translator.translate(SpecificationApprovalStatusNames.get(specificationStore.currentSpecification?.status!))
                    : ''
                }
              />
            )}
          </Stack>
        </Stack>

        <Stack direction="row" gap={6}>
          {mode === SectionMode.Specification && specificationStore.currentSpecification && (
            <>
              <DownloadSpecificationButton
                specificationId={specificationStore.currentSpecification ? specificationStore.currentSpecification.id : null}
                name={specificationStore.currentSpecification.name}
              />

              <Button
                sx={{ minWidth: '210px' }}
                variant="contained"
                startIcon={<PlusIconForButton />}
                onClick={() => {
                  approvalStore.setOpenApprovalDialog(true);

                  approvalStore.selectedApproval.setSpecification({
                    id: specificationStore.currentSpecification?.id!,
                    name: specificationStore.currentSpecification?.name!,
                  });

                  approvalStore.selectedApproval.setDefinition({
                    id: definitionStore.currentDefinition.id,
                    name: `${definitionStore.currentDefinition.projectName} / ${definitionStore.currentDefinition.name}`,
                  });
                }}>
                {Translator.translate('sections.createApprovalButtonTitle')}
              </Button>
            </>
          )}

          {mode === SectionMode.Template && (
            <Button variant="contained" startIcon={<PlusIconForButton />} onClick={onOpenCreateDialog}>
              {Translator.translate('sections.addSectionButtonTitle')}
            </Button>
          )}
        </Stack>
      </Stack>

      {!sectionsStore.isOpenParameters && (
        <Button
          sx={(theme) => ({
            position: 'absolute',
            top: '50%',
            right: mode === SectionMode.Template ? '-66px' : Translator.selectedLocalization === LangNames.Ru ? '-88px' : '-70px',
            zIndex: 1,
            transform: 'rotate(-90deg)',
            svg: { fill: theme.palette.common.white },
            borderRadius: '6px 6px 0px 0px',
          })}
          variant="contained"
          size="small"
          color="primary"
          onClick={onOpenSectionParameters}
          startIcon={<UnExpandIcon />}>
          {mode === SectionMode.Template ? Translator.translate('sections.parameters') : Translator.translate('sections.excludedParameters')}
        </Button>
      )}

      <Outlet />

      {approvalStore.isApprovalDialogOpen && <ApprovalDialog />}
    </Box>
  );
};

export default observer(SectionsPage);
