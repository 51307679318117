import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { globalSettingStore } from 'stores';
import { Input } from 'components';
import { Stack, Typography, Box } from '@mui/material';
import { IGetGlobalSettingDto } from 'shared/interfaces';

const GlobalSettings: React.FC = () => {
  useEffect(() => {
    globalSettingStore.getGlobalSettings();

    return () => {
      globalSettingStore.clearGlobalSettings();
    };
  }, []);

  const onFocus = async (setting: IGetGlobalSettingDto) => {
    await globalSettingStore.getGlobalSettingById(setting.id);
  };

  return (
    <Stack gap={6}>
      {globalSettingStore.globalSettings.map((setting) => {
        return (
          <Stack key={setting.id} flexDirection="column" gap={2}>
            <Typography variant="caption" width="fit-content">{`${setting.name}:`}</Typography>

            <Box width="100px">
              <Input
                name={setting.name}
                type="number"
                size="small"
                value={Number(setting.value)}
                onFocus={() => onFocus(setting)}
                onChange={globalSettingStore.updateValue}
              />
            </Box>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default observer(GlobalSettings);
