export enum ApprovalAction {
  Start = 'Start',
  Cancel = 'Cancel',
  Confirm = 'Confirm',
  Approve = 'Approve',
  Reject = 'Reject',
}

export enum ApprovalStatus {
  InProgress = 'InProgress',
  Confirmed = 'Confirmed',
  Approved = 'Approved',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
  Draft = 'Draft',
}

export const ApprovalStatusNames = new Map([
  [ApprovalStatus.InProgress, 'approvals.approvalStatusNames.inProgress'],
  [ApprovalStatus.Confirmed, 'approvals.approvalStatusNames.confirmed'],
  [ApprovalStatus.Approved, 'approvals.approvalStatusNames.approved'],
  [ApprovalStatus.Canceled, 'approvals.approvalStatusNames.canceled'],
  [ApprovalStatus.Rejected, 'approvals.approvalStatusNames.rejected'],
  [ApprovalStatus.Draft, 'approvals.approvalStatusNames.draft'],
]);

export enum PartialApprovalStatus {
  InProgress = 'InProgress',
  PendingApproval = 'PendingApproval',
  Canceled = 'Canceled',
  Draft = 'Draft',
  Approved = 'Approved',
}

export const PartialApprovalStatusNames = new Map([
  [PartialApprovalStatus.InProgress, 'approvals.partialApprovalStatusNames.inProgress'],
  [PartialApprovalStatus.PendingApproval, 'approvals.partialApprovalStatusNames.pendingApproval'],
  [PartialApprovalStatus.Canceled, 'approvals.partialApprovalStatusNames.canceled'],
  [PartialApprovalStatus.Draft, 'approvals.partialApprovalStatusNames.draft'],
  [PartialApprovalStatus.Approved, 'approvals.partialApprovalStatusNames.approved'],
]);

export enum ApprovalUserRole {
  Initiator = 'Initiator',
  Approver = 'Approver',
  Examiner = 'Examiner',
  Observer = 'Observer',
  Executor = 'Executor',
}

export const ApprovalUserRoleNames = new Map([
  [ApprovalUserRole.Initiator, 'approvals.approvalUserRoleNames.initiator'],
  [ApprovalUserRole.Approver, 'approvals.approvalUserRoleNames.approver'],
  [ApprovalUserRole.Examiner, 'approvals.approvalUserRoleNames.examiner'],
  [ApprovalUserRole.Observer, 'approvals.approvalUserRoleNames.observer'],
  [ApprovalUserRole.Executor, 'approvals.approvalUserRoleNames.executor'],
]);

export enum SpecificationApprovalStatus {
  PartiallyApproved = 'PartiallyApproved',
  Approved = 'Approved',
}

export const SpecificationApprovalStatusNames = new Map([
  [SpecificationApprovalStatus.Approved, 'sections.specificationApprovalStatusNames.approved'],
  [SpecificationApprovalStatus.PartiallyApproved, 'sections.specificationApprovalStatusNames.partiallyApproved'],
]);

export enum ApprovalDialogMode {
  Create,
  Watch,
  Edit,
}

export enum ApprovalStatusFilters {
  All = 1,
  Active = 2,
  Closed = 3,
  Overdue = 4,
}

export const ApprovalStatusFiltersNames = new Map([
  [ApprovalStatusFilters.All, 'approvals.approvalStatusFiltersNames.all'],
  [ApprovalStatusFilters.Active, 'approvals.approvalStatusFiltersNames.active'],
  [ApprovalStatusFilters.Closed, 'approvals.approvalStatusFiltersNames.closed'],
  [ApprovalStatusFilters.Overdue, 'approvals.approvalStatusFiltersNames.overdue'],
]);

export enum DefinitionApprovalStatus {
  Formed = 'Formed',
  InProgress = 'InProgress',
  PartiallyApproved = 'PartiallyApproved',
  Approved = 'Approved',
}

export const DefinitionApprovalStatusNames = new Map([
  [DefinitionApprovalStatus.Formed, 'Формируемый'],
  [DefinitionApprovalStatus.InProgress, 'В согласовании'],
  [DefinitionApprovalStatus.Approved, 'Согласован'],
  [DefinitionApprovalStatus.PartiallyApproved, 'Частично согласован'],
]);

export enum PartialApprovalIteration {
  Examination = 'Examination',
  Execution = 'Execution',
  Recheck = 'Recheck',
  Approval = 'Approval',
  Approved = 'Approved',
}

export const PartialApprovalIterationNames = new Map([
  [PartialApprovalIteration.Examination, 'approvals.partialApprovalIterationNames.examination'],
  [PartialApprovalIteration.Execution, 'approvals.partialApprovalIterationNames.execution'],
  [PartialApprovalIteration.Recheck, 'approvals.partialApprovalIterationNames.recheck'],
  [PartialApprovalIteration.Approval, 'approvals.partialApprovalIterationNames.approval'],
  [PartialApprovalIteration.Approved, 'approvals.partialApprovalIterationNames.approved'],
]);

export const PartialApprovalIterationButtonsTitle = new Map([
  [PartialApprovalIteration.Examination, 'approvals.partialApprovalIterationButtonsTitle.examination'],
  [PartialApprovalIteration.Execution, 'approvals.partialApprovalIterationButtonsTitle.execution'],
  [PartialApprovalIteration.Recheck, 'approvals.partialApprovalIterationButtonsTitle.recheck'],
  [PartialApprovalIteration.Approval, 'approvals.partialApprovalIterationButtonsTitle.approval'],
  [PartialApprovalIteration.Approved, 'approvals.partialApprovalIterationButtonsTitle.approved'],
]);

export enum RowStructureType {
  Header = 'Header',
  ParameterValue = 'ParameterValue',
}

export enum PartialApprovalIterationActions {
  ToExecution = 'ToExecution',
  ToRecheck = 'ToRecheck',
  ToApproval = 'ToApproval',
  ToApproved = 'ToApproved',
}

export enum SuggestionType {
  Update = 'Update',
  Create = 'Create',
  Delete = 'Delete',
}
