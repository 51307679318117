import React, { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Translator, approvalStore, partialApprovalStore, projectStore } from 'stores';
import { IconButtonNew, InputSearch, NestedTable, Select, Switch } from 'components';
import InfoIcon from 'components/Icons/InfoIcon';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import SettingsIcon from 'components/Icons/SettingsIcon';
import { Button, Chip, Stack, Typography, SelectChangeEvent, Box, styled } from '@mui/material';
import {
  ApprovalStatus,
  ApprovalStatusFilters,
  ApprovalStatusNames,
  ApprovalUserRole,
  ApprovalUserRoleNames,
  LangNames,
  NestedLevel,
  PartialApprovalStatus,
  PartialApprovalStatusNames,
  SpecificationApprovalStatus,
  SpecificationApprovalStatusNames,
} from 'shared/enums';
import { Routes } from 'shared/enums/Routes';
import { INestedApprovals, ITableColumn } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';
import { ApprovalDialog, ApprovalSetsDialog } from './Components';

const HEIGHT_CONTENT = 64;
interface StyledIterationProps {
  isActive: boolean;
  disabled: boolean;
}

const StyledIteration = styled(Box)<StyledIterationProps>(({ theme, isActive, disabled }) => {
  const paintBlock = (): string => {
    if (disabled) return theme.palette.action.disabled;

    if (isActive) {
      return theme.palette.primary.main;
    } else {
      return theme.palette.primary.light;
    }
  };

  return {
    height: '6px',
    width: '40px',
    borderRadius: '8px',
    backgroundColor: paintBlock(),
    opacity: isActive ? '0.5' : '1',
  };
});

const ApprovalPage: React.FC = () => {
  const filterRef = useRef<any>(null);
  const navigate = useNavigate();
  const [filterStatus, setFilterStatus] = useState(ApprovalStatusFilters.All);
  const [hoveredRowId, setHoveredRowId] = useState<number | null>(null);
  const heightContent = filterRef.current?.offsetHeight + HEIGHT_CONTENT;

  useEffect(() => {
    approvalStore.getApprovalList();

    return () => {
      approvalStore.approvalFilterState.clear();
    };
  }, []);

  const columns: ITableColumn[] = [
    {
      key: 'collapse',
      label: '',
      align: 'left',
      width: '30px',
      calcWidth: (row: INestedApprovals) => (row.level === NestedLevel.First ? '30px' : row.level === NestedLevel.Second ? '40px' : '50px'),
    },
    {
      key: 'name',
      label: Translator.translate('approvals.columns.name'),
      align: 'left',
      width: '460px',
      calcWidth: (row: INestedApprovals) => (row.level === NestedLevel.First ? '460px' : row.level === NestedLevel.Second ? '450px' : '440px'),
      cell: (row: INestedApprovals) => {
        return (
          <Typography
            variant="body2"
            sx={{
              color: row.level === NestedLevel.Third && row.id === hoveredRowId ? 'primary.main' : 'text.primary',
              textAlign: 'left',
              wordBreak: 'break-word',
              cursor: row.level === NestedLevel.Third && row.id === hoveredRowId && row.userRoles?.length !== 0 ? 'pointer' : 'default',
              fontWeight: row.level === NestedLevel.First ? 500 : 400,
            }}>
            {row.name}
          </Typography>
        );
      },
    },
    {
      key: 'status',
      label: Translator.translate('approvals.columns.status'),
      align: 'left',
      width: '150px',
      minWidth: '120px',
      cell: (row: INestedApprovals) => {
        switch (row.level) {
          case NestedLevel.First:
            return (
              <Chip
                variant="filled"
                size="small"
                color={paintSpecification(row.specificationStatus!)}
                label={
                  SpecificationApprovalStatusNames.get(row.specificationStatus!)
                    ? Translator.translate(SpecificationApprovalStatusNames.get(row.specificationStatus!))
                    : ''
                }
              />
            );

          case NestedLevel.Second:
            return (
              <Chip
                variant="outlined"
                size="small"
                color={paint(row.status as ApprovalStatus)}
                label={Translator.translate(ApprovalStatusNames.get(row.status as ApprovalStatus))}
              />
            );

          case NestedLevel.Third:
            return (
              <Chip
                variant="outlined"
                size="small"
                color={paint(row.status as PartialApprovalStatus)}
                label={Translator.translate(PartialApprovalStatusNames.get(row.status as PartialApprovalStatus))}
              />
            );

          default:
            return <></>;
        }
      },
    },
    {
      key: 'createdAt',
      label: Translator.translate('approvals.columns.createdAt'),
      align: 'left',
      width: '200px',
      minWidth: '110px',
      cell: (row: INestedApprovals) => (
        <div>
          {row.createdAt &&
            row.level === NestedLevel.Second &&
            dayjs(row.createdAt).format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY')}
        </div>
      ),
    },
    {
      key: 'deadLine',
      label: Translator.translate('approvals.columns.deadLine'),
      align: 'left',
      width: '200px',
      minWidth: '110px',
      cell: (row: INestedApprovals) => (
        <div>
          {row.deadLine &&
            row.level === NestedLevel.Second &&
            dayjs(row.deadLine).format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY')}
        </div>
      ),
    },
    {
      key: 'role',
      label: Translator.translate('approvals.columns.role'),
      align: 'left',
      width: 'auto',
      minWidth: '250px',
      cell: (row: INestedApprovals) =>
        row.level === NestedLevel.Second || row.level === NestedLevel.Third ? (
          <Typography
            variant="body2"
            sx={{
              color: 'text.primary',
              textAlign: 'left',
              wordBreak: 'break-word',
            }}>
            {row.userRoles?.map((role) => Translator.translate(ApprovalUserRoleNames.get(role as ApprovalUserRole))).join(', ')}
          </Typography>
        ) : (
          <></>
        ),
    },
    {
      key: 'indication',
      label: '',
      align: 'left',
      width: '160px',
      minWidth: '160px',
      cell: (row: INestedApprovals) => {
        const getIterationBlocks = () => {
          const index = partialApprovalStore.iterations.findIndex((el) => el.iteration === row.partialIteration);
          if (index === -1) return partialApprovalStore.iterations;

          // return done iterations array
          return partialApprovalStore.iterations.map((el, idx) => {
            if (index - 1 >= idx) {
              return {
                ...el,
                isDone: true,
              };
            } else {
              return { ...el };
            }
          });
        };

        return row.level === NestedLevel.Third ? (
          <Stack flexDirection="row" alignItems="center" justifyContent="center" gap={1} px={4}>
            {getIterationBlocks().map((el) => {
              return <StyledIteration key={el.iteration} isActive={el.isDone} disabled={row.status === PartialApprovalStatus.Draft} />;
            })}
          </Stack>
        ) : (
          <></>
        );
      },
    },
    {
      key: 'actions',
      label: '',
      align: 'left',
      width: '30px',
      cell: (row: INestedApprovals) => {
        if (row.level !== NestedLevel.Second) return null;

        const isInitiatorOrApprover = (): boolean => {
          return row.userRoles !== undefined && row.userRoles.includes(ApprovalUserRole.Initiator || ApprovalUserRole.Approver);
        };

        return (
          <Stack
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={(theme) => ({
              'button svg': {
                ':hover': {
                  fill: theme.palette.primary.main,
                  fillOpacity: 1,
                },
              },
            })}>
            <IconButtonNew
              tooltip={isInitiatorOrApprover() ? Translator.translate('actions.edit') : Translator.translate('actions.detInfo')}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`${Routes.APPROVALS}/${row.id}`);
              }}>
              {isInitiatorOrApprover() ? <SettingsIcon /> : <InfoIcon />}
            </IconButtonNew>
          </Stack>
        );
      },
    },
  ];

  const onChangeFilterMine = (event: React.ChangeEvent<HTMLInputElement>) => {
    approvalStore.approvalFilterState.setIsMine(event.target.checked);
    approvalStore.getApprovalList();
  };

  const onChangeFilterStatus = (e: SelectChangeEvent<any>) => {
    setFilterStatus(e.target.value);

    if (approvalStore.approvalFilterState.isExpired) approvalStore.approvalFilterState.setIsExpired(false);

    switch (e.target.value) {
      case ApprovalStatusFilters.All: {
        approvalStore.approvalFilterState.setStatuses([]);
        approvalStore.getApprovalList();
        return;
      }

      case ApprovalStatusFilters.Active: {
        approvalStore.approvalFilterState.setStatuses([ApprovalStatus.InProgress, ApprovalStatus.Draft]);
        approvalStore.getApprovalList();
        return;
      }

      case ApprovalStatusFilters.Closed: {
        approvalStore.approvalFilterState.setStatuses([ApprovalStatus.Approved, ApprovalStatus.Canceled]);
        approvalStore.getApprovalList();
        return;
      }

      case ApprovalStatusFilters.Overdue: {
        approvalStore.approvalFilterState.setStatuses([ApprovalStatus.InProgress, ApprovalStatus.Draft]);
        approvalStore.approvalFilterState.setIsExpired(true);
        approvalStore.getApprovalList();
        return;
      }

      default:
        return;
    }
  };

  const paintSpecification = (status: SpecificationApprovalStatus) => {
    switch (status) {
      case SpecificationApprovalStatus.PartiallyApproved:
        return 'primary';

      case SpecificationApprovalStatus.Approved:
        return 'success';
    }
  };

  const paint = (status: ApprovalStatus | PartialApprovalStatus) => {
    switch (status) {
      case ApprovalStatus.Approved:
      case PartialApprovalStatus.PendingApproval:
        return 'success';

      case ApprovalStatus.InProgress:
      case PartialApprovalStatus.InProgress:
        return 'primary';

      case ApprovalStatus.Canceled:
      case PartialApprovalStatus.Canceled:
        return 'secondary';

      case ApprovalStatus.Draft:
      case PartialApprovalStatus.Draft:
        return 'default';

      default:
        return 'secondary';
    }
  };

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    approvalStore.approvalFilterState.setFilterName(event.target.value.trim());
  };

  const onOpenCreateApproval = () => {
    approvalStore.setOpenApprovalDialog(true);
    projectStore.getProjectList();
  };

  const onRedirectPartialApprovals = async (selectedRow: INestedApprovals) => {
    //Переход в частичное согласование
    switch (selectedRow.level) {
      case NestedLevel.First:
        return;

      case NestedLevel.Second:
        if (selectedRow.children.every((el) => el.userRoles?.length === 0)) return;
        selectedRow.id && approvalStore.setPreSelectedApprovalId(selectedRow.id);
        await approvalStore.getPartialApprovalSets();

        if (approvalStore.approvalSets.length > 1) approvalStore.setOpenSetsApprovalDialog(true);
        else navigate(`${Routes.APPROVALS}/${selectedRow.id}/${Routes.PARTIAL_APPROVALS_SETS}/${approvalStore.partialApprovalSets[0].id}`);

        break;

      case NestedLevel.Third:
        if (selectedRow.userRoles?.length === 0) return;
        navigate(`${Routes.APPROVALS}/${selectedRow.approvalId}/${Routes.PARTIAL_APPROVALS}/${selectedRow.id}`);
    }
  };

  const onHover = (rowId: number | null) => {
    setHoveredRowId(rowId);
  };

  return (
    <Stack gap={4}>
      <Stack ref={filterRef} direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" gap={5}>
          <Select
            fullWidth
            hideNotSelectItem
            sx={{
              width: '220px',
              backgroundColor: '#F2F2F9',
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
              borderRadius: '6px',
            }}
            placeholder="Статус"
            value={filterStatus}
            options={approvalStore.approvalStatusOptions}
            onChange={onChangeFilterStatus}
            size="small"
          />

          <Switch
            label={Translator.translate('approvals.availableMe')}
            labelPlacement="end"
            checked={approvalStore.approvalFilterState.isMine}
            onChange={onChangeFilterMine}
          />
        </Stack>

        <Stack direction="row" gap={5}>
          <InputSearch value={approvalStore.approvalFilterState.filterName} onSearch={() => console.log('1')} onChange={onChangeSearch} />

          <Button sx={{ minWidth: '210px' }} variant="contained" startIcon={<PlusIconForButton />} onClick={onOpenCreateApproval}>
            {Translator.translate('approvals.createApprovalButtonTitle')}
          </Button>
        </Stack>
      </Stack>

      <Box sx={{ height: `calc(100vh - ${heightContent + Utils.projectHeight()}px)`, width: '100%' }}>
        <NestedTable<INestedApprovals>
          selectedRow={null}
          columns={columns}
          tableData={approvalStore.preparedApprovalData}
          isLoading={approvalStore.isLoading}
          isColorFirstLevel
          hasFilteredData={approvalStore.approvalFilterState.hasFilter}
          hoveredRowIds={approvalStore.hoveredRowIds}
          onSelectRow={onRedirectPartialApprovals}
          onHover={onHover}
        />
      </Box>

      {approvalStore.isApprovalDialogOpen && <ApprovalDialog />}
      {approvalStore.isApprovalSetsDialogOpen && <ApprovalSetsDialog />}
    </Stack>
  );
};
export default observer(ApprovalPage);
