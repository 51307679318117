import React, { useEffect, useState } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Translator, parameterListValueStore, parameterStore } from 'stores';
import { DialogWrapper } from 'components';
import { Box, CircularProgress } from '@mui/material';
import { ListValuesParamTable } from './Components';

const ListValuesParamDialog: React.FC = () => {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    parameterStore.selectedParameterTable.id &&
      parameterListValueStore.getParameterListValues(parameterStore.selectedParameterTable.id).finally(() => setLoading(false));

    return () => {
      runInAction(() => {
        parameterListValueStore.parameterListValues = [];
      });
    };
  }, []);

  const onClose = async () => {
    parameterListValueStore.selectedParameterListValue.clear();
    parameterListValueStore.setOpenDialog(false);
  };

  return (
    <DialogWrapper
      maxWidth="xl"
      title={Translator.translate('params.parameter.listValuesParamDialog.title')}
      subTitle={`${Translator.translate('params.parameter.listValuesParamDialog.subTitle')}: ${parameterStore.selectedParameterTable.fullName}.${
        parameterStore.selectedParameterTable.name
      }`}
      open={parameterListValueStore.isDialogOpen}
      onClose={onClose}
      hideActions>
      {isLoading ? (
        <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <ListValuesParamTable />
      )}
    </DialogWrapper>
  );
};

export default observer(ListValuesParamDialog);
