import React from 'react';

const MapIcon: React.FC = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="#CAC1FF" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11C6 11 10 8.27273 10 5.09091C10 4.00593 9.57857 2.96539 8.82843 2.1982C8.07828 1.43101 7.06087 1 6 1C4.93913 1 3.92172 1.43101 3.17157 2.1982C2.42143 2.96539 2 4.00593 2 5.09091C2 8.27273 6 11 6 11ZM8 5C8 6.10457 7.10457 7 6 7C4.89543 7 4 6.10457 4 5C4 3.89543 4.89543 3 6 3C7.10457 3 8 3.89543 8 5Z"
      />
    </svg>
  );
};

export default MapIcon;
