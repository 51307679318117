import React from 'react';

const ObjectValueIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.75 3C4.75 2.58579 4.41421 2.25 4 2.25C3.58579 2.25 3.25 2.58579 3.25 3V8C3.25 8.41421 3.58579 8.75 4 8.75C4.41421 8.75 4.75 8.41421 4.75 8V3Z" />
      <path d="M16.75 3C16.75 2.58579 16.4142 2.25 16 2.25C15.5858 2.25 15.25 2.58579 15.25 3V8C15.25 8.41421 15.5858 8.75 16 8.75C16.4142 8.75 16.75 8.41421 16.75 8V3Z" />
      <path d="M10 11.25C10.4142 11.25 10.75 11.5858 10.75 12V17C10.75 17.4142 10.4142 17.75 10 17.75C9.58579 17.75 9.25 17.4142 9.25 17V12C9.25 11.5858 9.58579 11.25 10 11.25Z" />
      <path d="M2 11.25C1.58579 11.25 1.25 11.5858 1.25 12C1.25 12.4142 1.58579 12.75 2 12.75H3.25L3.25 17C3.25 17.4142 3.58579 17.75 4 17.75C4.41421 17.75 4.75 17.4142 4.75 17L4.75 12.75H6C6.41421 12.75 6.75 12.4142 6.75 12C6.75 11.5858 6.41421 11.25 6 11.25H2Z" />
      <path d="M13.25 12C13.25 11.5858 13.5858 11.25 14 11.25H18C18.4142 11.25 18.75 11.5858 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75H16.75V17C16.75 17.4142 16.4142 17.75 16 17.75C15.5858 17.75 15.25 17.4142 15.25 17V12.75H14C13.5858 12.75 13.25 12.4142 13.25 12Z" />
      <path d="M12 8.75C12.4142 8.75 12.75 8.41421 12.75 8C12.75 7.58579 12.4142 7.25 12 7.25H10.75V3C10.75 2.58579 10.4142 2.25 10 2.25C9.58579 2.25 9.25 2.58579 9.25 3L9.25 7.25H8C7.58579 7.25 7.25 7.58579 7.25 8C7.25 8.41421 7.58579 8.75 8 8.75H12Z" />
    </svg>
  );
};

export default ObjectValueIcon;
