import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Translator } from 'stores';
import { NestedItems } from 'components';
import { List } from '@mui/material';
import { NestedLevel } from 'shared/enums';
import { Routes } from 'shared/enums/Routes';
import { IGroupSettingList } from 'shared/interfaces';

const GroupsSetting: React.FC = () => {
  const location = useLocation();
  const { currentDefinitionId } = useParams();
  const navigate = useNavigate();

  const settingsPagePath = currentDefinitionId ? `/${currentDefinitionId}${Routes.SETTINGS}` : `${Routes.SETTINGS}`;

  const groupsList: IGroupSettingList[] = [
    {
      key: currentDefinitionId ? `/${currentDefinitionId}${Routes.SETTINGS}/${Routes.ROLES}` : `${Routes.SETTINGS}/${Routes.ROLES}`,
      name: Translator.translate('roles.pageTitle'),
      isExpand: false,
      nestedLevel: NestedLevel.First,
    },
    {
      key: 'export',
      name: Translator.translate('settings.globalSettings.exportTypeNames.export'),
      isExpand: true,
      nestedLevel: NestedLevel.First,
      children: [
        {
          key: currentDefinitionId
            ? `/${currentDefinitionId}${Routes.SETTINGS}/${Routes.EXPORT_DOCUMENTS}`
            : `${Routes.SETTINGS}/${Routes.EXPORT_DOCUMENTS}`,
          name: Translator.translate('settings.globalSettings.exportTypeNames.generalSettings'),
          isExpand: false,
          nestedLevel: NestedLevel.Second,
        },
        {
          key: 'displayType',
          name: Translator.translate('settings.globalSettings.exportTypeNames.displayType'),
          isExpand: true,
          nestedLevel: NestedLevel.Second,
          children: [
            {
              key: currentDefinitionId
                ? `/${currentDefinitionId}${Routes.SETTINGS}/${Routes.EXPORT_VIEW_LIST}`
                : `${Routes.SETTINGS}/${Routes.EXPORT_VIEW_LIST}`,
              name: Translator.translate('settings.globalSettings.exportTypeNames.list'),
              isExpand: false,
              nestedLevel: NestedLevel.Third,
            },
            {
              key: currentDefinitionId
                ? `/${currentDefinitionId}${Routes.SETTINGS}/${Routes.EXPORT_VIEW_UNTITLED}`
                : `${Routes.SETTINGS}/${Routes.EXPORT_VIEW_UNTITLED}`,
              name: Translator.translate('settings.globalSettings.exportTypeNames.untitled'),
              isExpand: false,
              nestedLevel: NestedLevel.Third,
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    if (location.pathname === settingsPagePath) {
      navigate(groupsList[0].key);
      return;
    }

    navigate(location.pathname);
  }, []);

  const onChangeSettingItem = (value: IGroupSettingList) => {
    if (!value.isExpand) {
      navigate(value.key);
    }
  };

  return (
    <List sx={{ p: 0 }}>
      <NestedItems data={groupsList} onSelectRow={onChangeSettingItem} />
    </List>
  );
};

export default observer(GroupsSetting);
