import React, { ReactNode } from 'react';
import { Translator } from 'stores';
import DialogWrapper from 'components/UI/DialogWrapper';
import { Button, Button as MuiButton, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface ConfirmProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  isSuccess?: boolean;
  content: ReactNode | string;
  okText?: string;
  title?: string;
}

export const Confirm = ({
  visible,
  content,
  okText = Translator.translate('common.confirm.okText'),
  isSuccess,
  onCancel,
  onOk,
  title,
}: ConfirmProps) => {
  return (
    <DialogWrapper isCustom open={visible} isHideClose={true} onClose={onCancel}>
      <DialogTitle>{title ?? Translator.translate('common.confirm.title')}</DialogTitle>
      <DialogContent style={{ fontSize: 16 }}>{content}</DialogContent>
      <DialogActions>
        <MuiButton variant="outlined" onClick={onCancel}>
          {Translator.translate('actions.cancel')}
        </MuiButton>

        <Button variant="contained" color={isSuccess ? 'primary' : 'error'} onClick={onOk}>
          {okText}
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};
