import { makeAutoObservable } from 'mobx';
import { MeasurementUnitType } from 'shared/enums';
import { IGetMeasurementUnitDto, IPostPutMeasurementUnitDto } from 'shared/interfaces';

class MeasurementUnitModel {
  constructor(dto?: IGetMeasurementUnitDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.isArchived = dto.isArchived;
    this.name = dto.name;
    this.fullName = dto.fullName;
    this.okei = dto.okei;
    this.type = dto.type;
  }

  public id: number | null = null;

  public isArchived = false;

  public name = '';

  public fullName = '';

  public okei = '';

  public type: MeasurementUnitType = MeasurementUnitType.Time;

  public get postPutDto(): IPostPutMeasurementUnitDto | null {
    if (this.name === '' || this.fullName === '') return null;

    return {
      name: this.name,
      fullName: this.fullName,
      okei: this.okei,
      type: this.type,
    };
  }

  public setId(id: number) {
    this.id = id;
  }

  public setName(name: string) {
    this.name = name;
  }

  public setFullName(fullName: string) {
    this.fullName = fullName;
  }

  public setOKEI(okei: string) {
    this.okei = okei;
  }

  public setType(type: MeasurementUnitType) {
    this.type = type;
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.fullName = '';
    this.okei = '';
    this.type = MeasurementUnitType.Time;
  }
}

export default MeasurementUnitModel;
