import { ChangeEvent, useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Translator, toastStore } from 'stores';
import Input from 'components/UI/Input';
import { IconButton } from '@mui/material';
import { TableEditorProps } from '../../types';

import s from './RangeEditor.module.scss';

export const RangeEditor = ({ value, onChange }: TableEditorProps<string>) => {
  const [startRange, setStartRange] = useState<string>();
  const [endRange, setEndRange] = useState<string>();

  useEffect(() => {
    if (value) {
      const _value = value.slice(1, -1).split('..');
      setStartRange(_value[0]);
      setEndRange(_value[1]);
    }
  }, [value]);

  const onStartRange = (value: string) => {
    setStartRange(value);
  };

  const onEndRange = (value: string) => {
    setEndRange(value);
  };

  const save = () => {
    if (startRange && endRange && Number(startRange) >= Number(endRange)) {
      toastStore.showWarning(Translator.translate('common.editableTable.toastRangeMessage'));
      return;
    }

    onChange(startRange && endRange ? `[${startRange}..${endRange}]` : '');
  };

  const onBlurAway = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    onChange && onChange(startRange && endRange ? `[${startRange}..${endRange}]` : '');
  };

  return (
    <div className={s.rangeWrapper}>
      <Input
        sx={{ width: '50px' }}
        variant="standard"
        type="number"
        value={startRange}
        onChange={onStartRange}
        onBlur={onBlurAway}
        placeholder="от"
        autoFocus
        inputProps={{ 'aria-label': 'Without label', disableUnderline: 'true', step: '.1' }}
      />

      <div className={s.dots}>...</div>

      <Input
        sx={{ width: '50px' }}
        variant="standard"
        type="number"
        value={endRange}
        onChange={onEndRange}
        onBlur={onBlurAway}
        placeholder="до"
        inputProps={{ 'aria-label': 'Without label', disableUnderline: 'true', min: startRange, step: '.1' }}
      />

      <IconButton size="small" onClick={save}>
        <CheckIcon />
      </IconButton>
    </div>
  );
};
