import { makeAutoObservable, runInAction } from 'mobx';
import { ApprovalService } from 'services';
import { Translator, toastStore, usersStore } from 'stores';
import {
  ApprovalAction,
  ApprovalDialogMode,
  ApprovalStatus,
  ApprovalStatusFilters,
  ApprovalStatusFiltersNames,
  ApprovalUserRole,
  NestedLevel,
} from 'shared/enums';
import { IGetApprovalDto, INestedApprovals, ISelectOption, IShortPartialApprovalDto } from 'shared/interfaces';
import { IdNameDto } from 'shared/interfaces/Dto/BaseDto';
import { ApprovalFiltersModel, ApprovalModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

interface IPreSelectedApproval {
  approvalId: number | null;
  partialApprovalId?: number | null;
}

class ApprovalStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public approvalListDto: IGetApprovalDto[] = [];

  public approvalFilterState = new ApprovalFiltersModel();

  public selectedApproval = new ApprovalModel();

  public isApprovalDialogOpen = false;

  public isApprovalSetsDialogOpen = false;

  public approvalDialogMode = ApprovalDialogMode.Watch;

  public isMineApprovals = false;

  public hoveredRowIds: number[] = [];

  public partialApprovalSets: IdNameDto[] = [];

  public approvalSets: IdNameDto[][] = [];

  public preSelectedApprovalId: IPreSelectedApproval = {
    approvalId: null,
    partialApprovalId: null,
  };

  public get partialApprovalSetIds(): number[] {
    return [...new Set(this.partialApprovalSets.map((_) => _.id!))];
  }

  public get selectedPartialApprovalSets(): IShortPartialApprovalDto[] {
    const result: IShortPartialApprovalDto[] = [];

    this.selectedApproval.partialApprovals.forEach((el) => {
      this.partialApprovalSets.forEach((item) => {
        if (el.id === item.id && !result.includes(el)) result.push(el);
      });
    });

    return result;
  }

  public get approvalStatusOptions(): ISelectOption[] {
    const options = Object.values(ApprovalStatusFilters).filter((f) => typeof f === 'number');
    return options.map((item) => {
      return {
        id: item,
        name: Translator.translate(ApprovalStatusFiltersNames.get(item as ApprovalStatusFilters)),
      };
    });
  }

  public get filterApprovals(): IGetApprovalDto[] {
    return this.approvalListDto
      .filter(
        (f) =>
          f.partialApprovals.find((partialApproval) => Utils.includes(partialApproval.name, this.approvalFilterState.filterName)) ||
          Utils.includes(f.project.name, this.approvalFilterState.filterName) ||
          Utils.includes(f.definition.name, this.approvalFilterState.filterName) ||
          Utils.includes(f.specification.name, this.approvalFilterState.filterName) ||
          Utils.includes(f.name, this.approvalFilterState.filterName)
      )
      .map((data) => {
        return {
          ...data,
          partialApprovals: data.partialApprovals.filter((partialApproval) =>
            Utils.includes(partialApproval.name, this.approvalFilterState.filterName)
          ),
        };
      });
  }

  public get preparedApprovalData(): INestedApprovals[] {
    //mapping data to IApprovalTable
    const hoveredIds: number[] = [];
    const listApprovalData: INestedApprovals[] = this.filterApprovals.map((el) => {
      return {
        id: el.id,
        definitionName: el.definition.name,
        projectName: el.project.name,
        status: el.status,
        specificationStatus: el.specification.status,
        specificationName: el.specification.name,
        specificationId: el.specification.id,
        createdAt: el.createdAt,
        deadLine: el.deadLine,
        userRoles: el.userRoles,

        level: NestedLevel.Second,
        name: el.name,
        children: el.partialApprovals.map((partial) => {
          if (partial.userRoles.length !== 0 || partial.status !== 'Draft') {
            hoveredIds.push(partial.id);
          }

          return {
            id: partial.id,
            name: partial.name,
            status: partial.status,
            userRoles: partial.userRoles,
            approvalId: el.id,
            partialIteration: partial.iteration,
            userIteration: el.users.find((_) => _.user.id === usersStore.getUserData().id)?.iteration,
            level: NestedLevel.Third,
            children: [],
          };
        }),
      };
    });

    this.hoveredRowIds = hoveredIds;

    //grouping data by specificationId
    const groupApprovalMap = listApprovalData.reduce((acc: Map<number, INestedApprovals>, cur) => {
      if (!acc.has(cur.specificationId!)) {
        acc.set(cur.specificationId!, {
          id: cur.specificationId!,
          level: NestedLevel.First,
          name: `${cur.projectName}/${cur.definitionName}/${cur.specificationName}`,
          status: cur.specificationStatus!,
          createdAt: cur.createdAt,
          deadLine: cur.deadLine,
          children: [],
        });
      }

      acc.get(cur.specificationId!)?.children.push(cur);
      return acc;
    }, new Map());

    return Array.from(groupApprovalMap.values());
  }

  public setOpenApprovalDialog(isOpen: boolean) {
    this.isApprovalDialogOpen = isOpen;
  }

  public setOpenSetsApprovalDialog(isOpen: boolean) {
    this.isApprovalSetsDialogOpen = isOpen;
  }

  public setIsMineApprovals(isMine: boolean) {
    this.isMineApprovals = isMine;
  }

  public setApprovalDialogMode(mode: ApprovalDialogMode) {
    this.approvalDialogMode = mode;
  }

  public setPreSelectedApprovalId(approvalId: number | null, partialApprovalId?: number) {
    this.preSelectedApprovalId.approvalId = approvalId;
    this.preSelectedApprovalId.partialApprovalId = partialApprovalId ?? null;
  }

  public async getApprovalList(): Promise<void> {
    try {
      this.isLoading = true;
      const result = await ApprovalService.getApprovals(this.approvalFilterState.paramsDto);
      if (!result) return;

      this.approvalListDto = result;
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Approvals`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getPartialApprovalSets(): Promise<void> {
    if (this.preSelectedApprovalId.approvalId === null) return;

    try {
      const result = await ApprovalService.getPartialApprovalSets(
        this.preSelectedApprovalId.approvalId,
        this.preSelectedApprovalId.partialApprovalId
      );
      if (!result) return;

      runInAction(() => {
        this.approvalSets = [];
        this.partialApprovalSets = [];

        result.sets.forEach((el) => {
          this.approvalSets.push(el);
          el.forEach((item) => this.partialApprovalSets.push(item));
        });
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} Approval`);
    } finally {
    }
  }

  public async getApprovalById(id: number): Promise<void> {
    try {
      this.isLoading = true;
      const result = await ApprovalService.getApproval(id);
      if (!result) return;

      runInAction(() => {
        this.selectedApproval = new ApprovalModel(result);
      });

      if (this.selectedApproval.status === ApprovalStatus.Draft && this.selectedApproval.userRoles.includes(ApprovalUserRole.Initiator)) {
        this.setApprovalDialogMode(ApprovalDialogMode.Create);
      } else if (this.selectedApproval.userRoles?.length === 0) {
        this.setApprovalDialogMode(ApprovalDialogMode.Watch);
      } else if (this.selectedApproval.status !== ApprovalStatus.Draft) {
        this.setApprovalDialogMode(ApprovalDialogMode.Edit);
      }
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} Approval`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createApproval(): Promise<number | void> {
    if (!this.selectedApproval.postDto) return;

    try {
      const result = await ApprovalService.postApproval(this.selectedApproval.postDto);
      return result;
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} Approval`);
    } finally {
      this.isLoading = false;
    }
  }

  public async updateApproval(): Promise<void> {
    if (!this.selectedApproval.id) return;

    try {
      this.isLoading = true;
      await ApprovalService.putApproval(this.selectedApproval.id, this.selectedApproval.putDto);

      this.getApprovalById(this.selectedApproval.id);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} Approval`);
    } finally {
      this.isLoading = false;
    }
  }

  public async removeApproval(id: number): Promise<void> {
    try {
      this.isLoading = true;
      await ApprovalService.deleteApproval(id);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} Approval`);
    } finally {
      this.isLoading = false;
    }
  }

  public async updateApprovalStatus(action: ApprovalAction): Promise<void> {
    if (!this.selectedApproval.id) return;

    try {
      this.isLoading = true;
      await ApprovalService.putApprovalStatus(this.selectedApproval.id, { action });
      this.getApprovalById(this.selectedApproval.id);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} ApprovalStatus`);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new ApprovalStore();
