import { BASE_PATH } from 'shared/constants/constants';
import { IGetGlobalSettingDto, IPutGlobalSettingDto } from 'shared/interfaces';
import HTTPService from './HttpService';

class GlobalSettingService extends HTTPService {
  constructor() {
    super(BASE_PATH.GLOBAL_SETTINGS);
  }

  public getGlobalSettings(): Promise<IGetGlobalSettingDto[]> {
    return this.GET<IGetGlobalSettingDto[]>('');
  }

  public getGlobalSetting(id: number): Promise<IGetGlobalSettingDto[]> {
    const params = {
      id,
    };

    return this.GET<IGetGlobalSettingDto[]>('', { params });
  }

  public putGlobalSetting(id: number, body: IPutGlobalSettingDto) {
    return this.PUT(`${id}`, body);
  }
}

export default new GlobalSettingService();
