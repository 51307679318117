import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { ParameterValueService } from 'services';
import { Translator, parameterListValueStore, parameterValueStore, specificationStore, toastStore } from 'stores';
import { DialogWrapper, IconButtonNew, InputValidate } from 'components';
import { useConfirm } from 'components/App/Confirm';
import DocIcon from 'components/Icons/DocIcon';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import JpgIcon from 'components/Icons/JpgIcon';
import NotFoundFileTypeIcon from 'components/Icons/NotFoundFileTypeIcon';
import PdfIcon from 'components/Icons/PdfIcon';

import PptIcon from 'components/Icons/PptIcon';
import TrashIcon from 'components/Icons/TrashIcon';
import TxtIcon from 'components/Icons/TxtIcon';
import UploadIcon from 'components/Icons/UploadIcon';
import XlsIcon from 'components/Icons/XlsIcon';
import { Stack, Box, List, InputLabel, Link, Typography, ListItem, DialogTitle, DialogContent } from '@mui/material';
import { FileType, LangNames } from 'shared/enums';
import Utils from 'shared/utils/Utils';

const FileDialog: React.FC = () => {
  const confirm = useConfirm();
  const [isHover, setHover] = useState(false);
  const [isChangeNameMode, setChangeNameMode] = useState(false);

  const onClose = () => {
    parameterListValueStore.setFilesDialogOpen(false);
  };

  const getIconType = (type: FileType): JSX.Element => {
    switch (type) {
      case FileType.DOC:
        return <DocIcon />;

      case FileType.TXT:
        return <TxtIcon />;

      case FileType.XLS:
        return <XlsIcon />;

      case FileType.JPG:
        return <JpgIcon />;

      case FileType.PPT:
        return <PptIcon />;

      case FileType.PDF:
        return <PdfIcon />;

      default:
        return <NotFoundFileTypeIcon />;
    }
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length !== 0 && e.target.files?.length) parameterValueStore.selectedParamValue.file.setFile(e.target.files[0] as File);
    await parameterValueStore.createParameterValueFile();

    if (parameterValueStore.selectedParamValue.isInheritedValue) {
      parameterValueStore.createParameterValue();
    } else parameterValueStore.updateParameterValue();
  };

  const onEdit = () => {
    parameterValueStore.selectedParamValue.setEditValue(parameterValueStore.selectedParamValue.value.split('.').slice(0, -1).join('.'));
    setChangeNameMode(true);
  };

  const onDownloadFile = () => {
    if (parameterValueStore.selectedParamValue.valueId === null) return;

    ParameterValueService.downloadFile(parameterValueStore.selectedParamValue.valueId)
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp as any]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${parameterValueStore.selectedParamValue.value}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e: any) => {
        Utils.parseBlobError(e, `${Translator.translate('stores.getListErrorMessage')} downloadDocument`).then(toastStore.showError);
      });
  };
  return (
    <DialogWrapper
      title={Translator.translate('objectValues.objectValueTable.fileDialog.title')}
      subTitle={`${Translator.translate('objectValues.objectValueTable.fileDialog.subTitle')}: ${parameterValueStore.selectedParamValue.paramName}`}
      maxWidth="md"
      open={parameterListValueStore.isDialogFilesOpen}
      onClose={onClose}
      hideActions>
      {specificationStore.isLoading ? (
        <Stack height={56} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {!isChangeNameMode ? (
            <List sx={{ p: 0, display: 'flex', flexDirection: 'column', gap: 4 }}>
              <ListItem
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  height: 42,
                  p: 0,
                  borderRadius: '8px',
                  ':hover': {
                    backgroundColor: 'action.hover',
                  },
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <Stack px={1} flexDirection="row" gap={2} alignItems="center">
                  {getIconType(parameterValueStore.selectedParamValue.file.fileType)}

                  <Stack>
                    <Link
                      sx={{
                        cursor: 'pointer',
                        ':hover': {
                          color: 'text.primary',
                        },
                      }}
                      color="text.primary"
                      variant="body2"
                      onClick={onDownloadFile}>
                      {parameterValueStore.selectedParamValue.value}
                    </Link>

                    <Typography color="text.secondary" variant="caption">
                      {dayjs(parameterValueStore.selectedParamValue.file.createdAt).format(
                        Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY HH:mm' : 'MM/DD/YYYY h:mm A'
                      )}
                    </Typography>
                  </Stack>
                </Stack>

                {isHover && (
                  <Stack flexDirection="row" alignItems="center">
                    <IconButtonNew
                      sx={{
                        svg: {
                          width: 16,
                          height: 16,
                        },
                      }}
                      tooltip={Translator.translate('actions.edit')}
                      onClick={onEdit}>
                      <EditPencilIcon />
                    </IconButtonNew>

                    <InputLabel
                      sx={{
                        mt: 2,
                        cursor: 'pointer',
                      }}
                      htmlFor="files">
                      <Box
                        sx={(theme) => ({
                          svg: {
                            ':hover': {
                              fill: theme.palette.primary.main,
                              fillOpacity: 1,
                            },
                          },
                        })}>
                        <UploadIcon />
                      </Box>
                      <input id="files" type="file" hidden onChange={handleUpload} />
                    </InputLabel>

                    <IconButtonNew
                      sx={{
                        svg: {
                          width: 16,
                          height: 16,
                        },
                      }}
                      tooltip={Translator.translate('actions.delete')}
                      onClick={() => {
                        confirm
                          .show(
                            Translator.translate('objectValues.objectValueTable.fileDialog.confirmMessage.deleteFile'),
                            Translator.translate('actions.delete')
                          )
                          .then(() => {
                            parameterValueStore.deleteParameterValue().then(() => onClose());
                          });
                      }}>
                      <TrashIcon />
                    </IconButtonNew>
                  </Stack>
                )}
              </ListItem>
            </List>
          ) : (
            <Stack direction="row" gap={3} alignItems="center">
              <DocIcon />

              <InputValidate
                size="small"
                label={Translator.translate('objectValues.objectValueTable.fileDialog.dialogFormFields.fileName')}
                name="name"
                value={parameterValueStore.selectedParamValue.editValue}
                onChange={(e: any) => {
                  parameterValueStore.selectedParamValue.setEditValue(e.target.value);
                }}
                onBlur={async (e: any) => {
                  parameterValueStore.selectedParamValue.setValue(
                    `${parameterValueStore.selectedParamValue.editValue}${parameterValueStore.selectedParamValue.file.extension}`
                  );
                  parameterValueStore.selectedParamValue.setEditValue('');
                  await parameterValueStore.updateParameterValue();
                  await parameterValueStore.getParameterValueById();
                  setChangeNameMode(false);
                }}
                endIcon={<>{parameterValueStore.selectedParamValue.file.extension}</>}
              />
            </Stack>
          )}
        </>
      )}
    </DialogWrapper>
  );
};

export default observer(FileDialog);
