import React from 'react';
import { observer } from 'mobx-react-lite';
import { usersStore } from 'stores';
import { RoleDialog, RoleTable } from './Components';

const Roles: React.FC = () => {
  return (
    <>
      <RoleTable />

      {usersStore.isDialogOpen && <RoleDialog />}
    </>
  );
};

export default observer(Roles);
