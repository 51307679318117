import { GridRowsProp } from '@mui/x-data-grid-pro';
import { makeAutoObservable, runInAction } from 'mobx';
import { SectionsService, SpecificationsService } from 'services';
import { toastStore, sectionsStore, specificationStore, Translator } from 'stores';
import { Routes } from 'shared/enums/Routes';
import { IParameterGroupPropertiesDto, IPostPutParamSectionsDto, IPutSectionParameterGroupPropertiesDto, ISectionParameter } from 'shared/interfaces';
import { ParameterGroupModel, SectionParamsModel } from 'shared/models';

class SectionParameterStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public initSectionParameters: ISectionParameter[] = [];

  public sectionParams: SectionParamsModel[] = [];

  public filterName = '';

  public paramGroupFilter = new ParameterGroupModel();

  public get sectionParamsFilteredDataGrid(): GridRowsProp {
    const groupIds = this.sectionParams
      .filter((parameter) => parameter.fullName.toLowerCase().includes(this.filterName.toLowerCase()))
      .map((data) => data.groupId!);

    const uniqueGroupIds = new Set(groupIds);

    return this.sectionParams.filter(
      (parameter) => parameter.fullName.toLowerCase().includes(this.filterName.toLowerCase()) || uniqueGroupIds.has(parameter.id!)
    );
  }

  public get isHasChanged(): boolean {
    if (window.location.pathname.includes(Routes.CATALOG)) {
      const filteredInitParams = this.initSectionParameters.filter((f) => !f.isGroup && f.checked);
      const initIds = new Set(filteredInitParams.map((data) => data.id));
      const filteredCheckedParams = this.sectionParams.filter((f) => !f.isGroup && f.checked);
      const checkedParamsIds = new Set(filteredCheckedParams.map((data) => data.id));

      const createParams = filteredCheckedParams.filter((row) => !initIds.has(row.id!));

      const deleteParams = filteredInitParams.filter((row) => !checkedParamsIds.has(row.id!));

      const updateParams = filteredCheckedParams
        .filter((row) => initIds.has(row.id!))
        .filter((data) => {
          const findEl = filteredInitParams.find((f) => f.id === data.id);

          return findEl && findEl.isVisible !== data.isVisible;
        });

      const filteredGroup = this.initSectionParameters.filter((f) => f.isGroup);
      const initGroupIds = new Set(filteredGroup.map((data) => data.id));
      const filteredTitledGroup = this.sectionParams.filter((f) => f.isGroup);

      const updateTitleParamGroup = filteredTitledGroup
        .filter((row) => initGroupIds.has(row.id!))
        .filter((data) => {
          const findEl = filteredGroup.find((f) => f.id === data.id);

          return findEl && findEl.isTitle !== data.isTitle;
        });

      if (createParams.length > 0 || deleteParams.length > 0 || updateParams.length > 0 || updateTitleParamGroup.length > 0) return true;
      else return false;
    } else {
      const filteredInitParams = this.initSectionParameters.filter((f) => !f.isGroup && f.checked);
      const filteredCheckedParams = this.sectionParams.filter((f) => !f.isGroup && f.checked);

      return filteredInitParams.length !== filteredCheckedParams.length;
    }
  }

  public updateSectionParams(sectionParams: SectionParamsModel[]) {
    this.sectionParams = [...sectionParams];
  }

  public setFilterName(value: string) {
    this.filterName = value;
  }

  public async saveParametersSection() {
    const filteredInitParams = this.initSectionParameters.filter((f) => !f.isGroup && f.checked);
    const initIds = new Set(filteredInitParams.map((data) => data.id));
    const filteredCheckedParams = this.sectionParams.filter((f) => !f.isGroup && f.checked);
    const checkedParamsIds = new Set(filteredCheckedParams.map((data) => data.id));

    const createParams = filteredCheckedParams.filter((row) => !initIds.has(row.id!));

    const deleteParams = filteredInitParams.filter((row) => !checkedParamsIds.has(row.id!));

    const updateParams = filteredCheckedParams
      .filter((row) => initIds.has(row.id!))
      .filter((data) => {
        const findEl = filteredInitParams.find((f) => f.id === data.id);

        return findEl && findEl.isVisible !== data.isVisible;
      });

    createParams.length > 0 &&
      (await this.createParamBlackList(
        createParams.map((param) => {
          return {
            parameterId: param.id,
            isVisible: param.isVisible,
          } as IPostPutParamSectionsDto;
        })
      ));

    updateParams.length > 0 &&
      (await this.updateParamBlackList(
        updateParams.map((param) => {
          return {
            parameterId: param.id,
            isVisible: param.isVisible,
          } as IPostPutParamSectionsDto;
        })
      ));

    deleteParams.length > 0 && (await this.removeParamBlackList(deleteParams.map((param) => param.id!)));

    const filteredGroup = this.initSectionParameters.filter((f) => f.isGroup);
    const initGroupIds = new Set(filteredGroup.map((data) => data.id));
    const filteredTitledGroup = this.sectionParams.filter((f) => f.isGroup);

    const updateTitleParamGroup = filteredTitledGroup
      .filter((row) => initGroupIds.has(row.id!))
      .filter((data) => {
        const findEl = filteredGroup.find((f) => f.id === data.id);

        return findEl && findEl.isTitle !== data.isTitle;
      });

    await this.getParametersSection();

    const parameterGroupProperties: IParameterGroupPropertiesDto[] = [];

    updateTitleParamGroup.forEach((param) => {
      const findEl = this.initSectionParameters.find((f) => f.id === param.id);

      if (findEl) {
        parameterGroupProperties.push({
          id: findEl.propertyId!,
          isTitle: param.isTitle,
        });
      }
    });

    if (updateTitleParamGroup.length > 0) {
      await this.updateSectionParameterGroupProperties({ parameterGroupProperties });
      await this.getParametersSection();
    }
  }

  public async saveParametersSpecSection() {
    const createParamIds = this.sectionParams.filter((f) => !f.isGroup && f.checked).map((_) => _.id!);
    this.createSpecSectionParameters(createParamIds);
  }

  public async getParametersSection(): Promise<void> {
    if (!sectionsStore.selectedSectionRow || !this.paramGroupFilter.id) return;

    this.isLoading = true;

    try {
      const result = await SectionsService.getSectionParameters(sectionsStore.selectedSectionRow, {
        parentId: this.paramGroupFilter.id,
        includeRoot: true,
      });

      if (!result) return;

      const res: ISectionParameter[] = [];

      result.forEach((el) => {
        const checkedParams = el.parameters.filter((_) => (_.isVisible === null ? false : true));

        res.push({
          hierarchy: [el.fullName],
          id: el.id,
          name: el.fullName,
          fullName: el.fullName,
          isGroup: true,
          isTitle: el.property !== null ? el.property.isTitle : false,
          propertyId: el.property !== null ? el.property.id : null,
          parameterIds: el.parameters.length === 0 ? null : el.parameters.map((parameter) => parameter.id),
          checked: checkedParams.length === el.parameters.length && el.parameters.length !== 0 ? true : false,
        });

        el.parameters.forEach((item) => {
          const checked = item.isVisible === null ? false : true;

          res.push({
            hierarchy: [el.fullName, item.name],
            groupId: el.id,
            name: item.name,
            fullName: item.name,
            id: item.id,
            isVisible: item.isVisible !== null ? item.isVisible : false,
            checked: checked,
            isGroup: false,
            parameterIds: [],
          });
        });
      });

      runInAction(() => {
        this.initSectionParameters = res;
        this.sectionParams = res.map((data) => new SectionParamsModel(data));
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} ParametersSection`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createParamBlackList(parameters: IPostPutParamSectionsDto[]): Promise<void> {
    if (sectionsStore.selectedSectionRow === null) return;

    try {
      this.isLoading = true;
      await SectionsService.postSectionParameters(sectionsStore.selectedSectionRow, parameters);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} ParamBlackList`);
    } finally {
      this.isLoading = false;
    }
  }

  public async updateParamBlackList(parameters: IPostPutParamSectionsDto[]): Promise<void> {
    if (sectionsStore.selectedSectionRow === null) return;

    try {
      this.isLoading = true;
      await SectionsService.putSectionParameters(sectionsStore.selectedSectionRow, parameters);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} ParamBlackList`);
    } finally {
      this.isLoading = false;
    }
  }

  public async updateSectionParameterGroupProperties(payload: IPutSectionParameterGroupPropertiesDto): Promise<void> {
    try {
      this.isLoading = true;
      await SectionsService.putSectionParameterGroupProperties(payload);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} SectionParameterGroupProperties`);
    } finally {
      this.isLoading = false;
    }
  }

  public async removeParamBlackList(parameterIds: number[]): Promise<void> {
    if (sectionsStore.selectedSectionRow === null) return;

    try {
      this.isLoading = true;
      await SectionsService.deleteSectionParameters(sectionsStore.selectedSectionRow, parameterIds);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} ParamBlackList`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getSpecSectionParameters(): Promise<void> {
    if (!sectionsStore.selectedSectionRow || !specificationStore.currentSpecification) return;

    this.isLoading = true;

    try {
      const result = await SpecificationsService.getSpecificationParameters(
        specificationStore.currentSpecification.id,
        sectionsStore.selectedSectionRow
      );
      if (!result) return;

      const res: ISectionParameter[] = [];

      result.forEach((el) => {
        const checkedParams = el.parameters.filter((_) => _.isSelected);

        res.push({
          hierarchy: [el.fullName],
          id: el.id,
          name: el.fullName,
          fullName: el.fullName,
          isGroup: true,
          parameterIds: el.parameters.length === 0 ? null : el.parameters.map((parameter) => parameter.id),
          checked: checkedParams.length === el.parameters.length && el.parameters.length !== 0 ? true : false,
        });

        el.parameters.forEach((item) => {
          res.push({
            hierarchy: [el.fullName, item.name],
            groupId: el.id,
            name: item.name,
            fullName: item.name,
            id: item.id,
            checked: item.isSelected,
            isGroup: false,
            parameterIds: [],
          });
        });
      });

      runInAction(() => {
        this.initSectionParameters = res;
        this.sectionParams = res.map((data) => new SectionParamsModel(data));
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} SpecSectionParameters`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createSpecSectionParameters(parameterIds: number[]): Promise<void> {
    if (!sectionsStore.selectedSectionRow || !specificationStore.currentSpecification) return;

    try {
      this.isLoading = true;
      await SpecificationsService.postSpecificationParameter(
        specificationStore.currentSpecification.id,
        sectionsStore.selectedSectionRow,
        parameterIds
      );

      this.getSpecSectionParameters();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} SpecSectionParameter`);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new SectionParameterStore();
