import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, classificationDictionaryValueStore, informationBlockStore, usersStore } from 'stores';
import { IconButtonNew, MultipleSelect } from 'components';
import { EditableTable } from 'components/App/EditableTable';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import { Typography, Stack, SelectChangeEvent } from '@mui/material';
import { ClassificationDictionaries, Order, Roles, RolesNames } from 'shared/enums';
import { IEditableTableColumn, IGetUserDto, IInformationBlock, IProject, IRegion } from 'shared/interfaces';
import { UserListModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

interface ISortInfo {
  key: string;
  ascending: boolean;
}

const HEIGHT_CONTENT = 100;

const RoleTable: React.FC = () => {
  const [sortingData, setSortingData] = useState<IGetUserDto[]>(usersStore.filterUsers);
  const [sortInfo, setSortInfo] = useState<ISortInfo>({ key: 'name', ascending: true });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    usersStore.getUsersList().finally(() => setIsLoading(false));

    informationBlockStore.getInformationBlocks();
    classificationDictionaryValueStore.getDictionaryValueList([ClassificationDictionaries.Region]);

    return () => {
      usersStore.setUserFiltersId(null);
    };
  }, []);

  useEffect(() => {
    const newSortingData = sortData(sortInfo.ascending, usersStore.filterUsers, sortInfo.key as keyof IGetUserDto);
    setSortingData(newSortingData);
  }, [usersStore.filterUsers, sortInfo]);

  const columns: IEditableTableColumn[] = [
    {
      key: 'name',
      label: Translator.translate('roles.columns.name'),
      width: '15%',
      align: 'left',
      sorting: true,
      sortOrder: Order.Asc,
      editable: false,
    },
    {
      key: 'role',
      label: Translator.translate('roles.columns.role'),
      width: '15%',
      align: 'left',
      editable: false,
      cell: (row: IGetUserDto) => {
        return (
          <Stack direction="column" gap={1}>
            {row.roles.map((role: Roles, idx) => {
              return (
                <Typography
                  key={idx}
                  variant="body2"
                  sx={{
                    color: 'text.primary',
                    textAlign: 'left',
                  }}>
                  {Translator.translate(RolesNames.get(role))}
                </Typography>
              );
            })}
          </Stack>
        );
      },
    },
    {
      key: 'informationBlock',
      label: Translator.translate('roles.columns.informationBlock'),
      width: '15%',
      align: 'left',
      editable: true,
      cell: (row: IGetUserDto) => {
        return (
          <Typography
            variant="body2"
            sx={{
              color: 'text.primary',
              textAlign: 'left',
              wordBreak: 'break-word',
            }}>
            {row.informationBlocks.map((f: IInformationBlock) => f.name).join(', ')}
          </Typography>
        );
      },
      customEditor: (row: IGetUserDto) => {
        if (!usersStore.selectedUser.id) {
          return (
            <Typography
              variant="body2"
              sx={{
                color: 'text.primary',
                textAlign: 'left',
                wordBreak: 'break-word',
              }}>
              {row.informationBlocks.map((f: IInformationBlock) => f.name).join(', ')}
            </Typography>
          );
        } else
          return (
            <MultipleSelect
              label=""
              size="small"
              sx={{ width: '100%', maxWidth: '250px' }}
              value={usersStore.selectedUser.infBlocksIds}
              options={informationBlockStore.informationBlocksForOption}
              onChange={(e: SelectChangeEvent<any>) => {
                usersStore.selectedUser.setSelectedInfBlocksIds(e.target.value);
                row.informationBlocks = informationBlockStore.informationBlocks
                  .filter((f) => e.target.value.includes(f.id as number))
                  .map((block) => {
                    return {
                      id: block.id,
                      name: block.name,
                    };
                  });
              }}
              onClose={async () => {
                await usersStore.updateUser();
                usersStore.selectedUser = new UserListModel();
              }}
            />
          );
      },
    },
    {
      key: 'region',
      label: Translator.translate('roles.columns.region'),
      width: '15%',
      align: 'left',
      editable: true,
      cell: (row: IGetUserDto) => {
        return (
          <Typography
            variant="body2"
            sx={{
              color: 'text.primary',
              textAlign: 'left',
              wordBreak: 'break-word',
            }}>
            {row.regions.map((f: IRegion) => f.name).join(', ')}
          </Typography>
        );
      },
      customEditor: (row: IGetUserDto) => {
        if (!usersStore.selectedUser.id) {
          return (
            <Typography
              variant="body2"
              sx={{
                color: 'text.primary',
                textAlign: 'left',
                wordBreak: 'break-word',
              }}>
              {row.regions.map((f: IRegion) => f.name).join(', ')}
            </Typography>
          );
        } else
          return (
            <MultipleSelect
              label=""
              size="small"
              sx={{ width: '100%', maxWidth: '250px' }}
              value={usersStore.selectedUser.regionsIds}
              options={classificationDictionaryValueStore.regions}
              onChange={(e: SelectChangeEvent<any>) => {
                usersStore.selectedUser.setSelectedRegionsIds(e.target.value);
                row.regions = classificationDictionaryValueStore.regions.filter((f) => e.target.value.includes(f.id as number)) as IRegion[];
              }}
              onClose={async () => {
                await usersStore.updateUser();
                usersStore.selectedUser = new UserListModel();
              }}
            />
          );
      },
    },
    {
      key: 'project',
      label: Translator.translate('roles.columns.project'),
      width: '30%',
      maxWidth: '30%',
      align: 'left',
      editable: false,
      cell: (row: IGetUserDto) => {
        return (
          <Stack direction="row" alignItems="center" gap={1} width="100%" justifyContent="space-between">
            {row.regions.length === 0 && (
              <Typography
                variant="body2"
                sx={{
                  color: 'text.primary',
                  textAlign: 'left',
                  width: '100%',
                }}>
                {row.projects.map((f: IProject) => f.name).join(', ')}
              </Typography>
            )}

            {row.regions.length === 0 && (
              <IconButtonNew
                sx={(theme) => ({
                  svg: {
                    fill: theme.palette.primary.main,
                    width: '16px',
                    height: '16px',
                  },
                })}
                tooltip={Translator.translate('actions.edit')}
                onClick={(e) => {
                  usersStore.setDialogOpen(true);
                }}>
                <EditPencilIcon />
              </IconButtonNew>
            )}
          </Stack>
        );
      },
    },
  ];

  const sortData = (isAscending: boolean, list: IGetUserDto[], propertyName: keyof IGetUserDto): IGetUserDto[] => {
    return isAscending
      ? list.slice().sort((a, b) => Utils.ascComp(a[propertyName]?.toString().toLocaleLowerCase(), b[propertyName]?.toString().toLocaleLowerCase()))
      : list.slice().sort((a, b) => Utils.descComp(a[propertyName]?.toString().toLocaleLowerCase(), b[propertyName]?.toString().toLocaleLowerCase()));
  };

  const handleSort = (property: string, order: Order) => {
    setSortInfo({ key: property, ascending: order === Order.Asc });
  };

  const onClick = (row: IGetUserDto, column: IEditableTableColumn) => {
    if (column.key === 'informationBlock' || column.key === 'region' || column.key === 'project') usersStore.selectedUser = new UserListModel(row);
    else usersStore.selectedUser = new UserListModel();
  };

  return (
    <EditableTable
      isLoading={isLoading}
      isFilter={usersStore.filterName || usersStore.usersFilters.id}
      height={`calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight()}px)`}
      data={sortingData}
      columns={columns}
      onSort={handleSort}
      hideSelectRowOnMount
      onClick={onClick}
    />
  );
};

export default observer(RoleTable);
