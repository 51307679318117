import { BASE_PATH } from 'shared/constants/constants';
import {
  IGetXmlDocumentDto,
  IGetXmlDocumentNodeDto,
  IPostDefinitionInstanceDto,
  IPostXmlDocumentDto,
  IPostXmlDocumentNodeDto,
  IPutXmlDocumentNodeDto,
  IXmlDocumentParamsDto,
} from 'shared/interfaces';
import HTTPService from './HttpService';

/* Сервис для получения данных документов xml*/
class XmlDocumentInstanceService extends HTTPService {
  constructor() {
    super(BASE_PATH.XML_DOCUMENTS);
  }

  public getAllXmlDocument(params: IXmlDocumentParamsDto): Promise<IGetXmlDocumentDto[]> {
    return this.GET<IGetXmlDocumentDto[]>('', { params });
  }

  public getXmlDocumentById(xmlDocumentId: number): Promise<IGetXmlDocumentDto[]> {
    const params = { id: xmlDocumentId };

    return this.GET<IGetXmlDocumentDto[]>('', { params });
  }

  public getDocumentNodesById(documentId: number) {
    return this.GET<IGetXmlDocumentNodeDto[]>(`${documentId}/nodes`);
  }

  public postXMLDocumentNode(body: IPostXmlDocumentNodeDto): Promise<number> {
    return this.POST('/node', body);
  }

  public putXMLDocumentNode(body: IPutXmlDocumentNodeDto): Promise<void> {
    return this.PUT('/node', body);
  }

  public downloadXMLFile(id: number): Promise<void> {
    return this.GET(`${id}/document`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream',
      },
    });
  }

  public postXmlDocument(body: IPostXmlDocumentDto): Promise<number> {
    return this.POST('', body);
  }

  public postDefinitionInstance(body: IPostDefinitionInstanceDto): Promise<number> {
    return this.POST('/definition-instance', body);
  }
}

export default new XmlDocumentInstanceService();
