import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldDataNode } from 'rc-tree/lib/interface';
import { Translator, approvalSuggestionRowStore, partialApprovalStore } from 'stores';
import { TreeData, ButtonItemTitle } from 'components/App/TreeData';
import { Stack, Typography } from '@mui/material';
import { PartialApprovalIteration } from 'shared/enums';
import { IApprovalRowTree } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';

interface ITreeProps {
  title: React.ReactNode;
  name: string;
  id: number | null;
  key: string | number;
}

declare type ITreeItem = FieldDataNode<ITreeProps>;

export interface IRowsProps {
  heightTree: number;
}

const ApprovalIterationRowsTree: React.FC<IRowsProps> = (props) => {
  const { heightTree } = props;
  const approvalTreeRef = useRef<any>();

  const [currentNode, setCurrentNode] = useState<React.Key | null>();

  useEffect(() => {
    if (approvalSuggestionRowStore.filteredApprovalRowsTree.length !== 0 && approvalTreeRef.current && approvalSuggestionRowStore.selectedTreeId) {
      approvalTreeRef.current.scrollTo({ key: approvalSuggestionRowStore.selectedTreeId });
    }
  }, []);

  const onExpand = (expandedKeysValue: number[]) => {
    approvalSuggestionRowStore.setExpandedKeys(expandedKeysValue);
  };

  const onSelect = (selectedKey: React.Key[]) => {
    selectedKey.length !== 0 && approvalSuggestionRowStore.setSelectedTreeId(Number(selectedKey));
  };

  const onHover = (key: React.Key | null) => {
    setCurrentNode(key);
  };

  const mapItems = (data: IApprovalRowTree[]): ITreeItem[] => {
    return data.map((item) => {
      const title = (
        <ButtonItemTitle
          title={item.title as string}
          isMaster={item.depth === 0 || item.hasConflict}
          item={item}
          isIndicationNavigation={item.hasConflict}
          hovered={item.key === currentNode}
          disabled={item.isNotHeaderPartial}
        />
      );

      if (item.children) {
        return {
          ...item,
          title: title,
          isNotHeaderPartial: item.isNotHeaderPartial,
          children: mapItems(item.children),
        };
      }

      return {
        ...item,
        title: title,
        isNotHeaderPartial: item.isNotHeaderPartial,
      };
    });
  };

  return (
    <>
      {approvalSuggestionRowStore.filteredApprovalRowsTree.length > 0 ? (
        <TreeData
          ref={approvalTreeRef}
          disabledPropName={'isNotHeaderPartial'}
          treeData={mapItems(approvalSuggestionRowStore.filteredApprovalRowsTree)}
          selectedKey={[approvalSuggestionRowStore.selectedTreeId!]}
          onSelect={onSelect}
          expandedKeys={approvalSuggestionRowStore.expandedKeys}
          onExpand={(key) => onExpand(key as number[])}
          onCheck={
            !Utils.canEditApproval(partialApprovalStore.selectedPartialApproval.iteration, partialApprovalStore.selectedPartialApproval.userRoles) &&
            partialApprovalStore.selectedPartialApproval.iteration !== PartialApprovalIteration.Examination
              ? approvalSuggestionRowStore.setCheckedGroupParameters
              : undefined
          }
          checkedKeys={{
            checked: [...approvalSuggestionRowStore.allAcceptedTreeIds],
            halfChecked: [],
          }}
          height={heightTree}
          isCheckStrictly={true}
          onHover={onHover}
        />
      ) : (
        <Stack alignItems="center" mt={8}>
          <img style={{ alignSelf: 'center' }} src={`${process.env.PUBLIC_URL}/img/empty_data.svg`} alt="" />
          <Typography variant="subtitle1" color="text.disabled">
            {Translator.translate('system.emptyData')}
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default observer(ApprovalIterationRowsTree);
