import { makeAutoObservable } from 'mobx';
import { SpecificationFilesModel } from '../SpecificationModel';
import { DataType } from 'shared/enums';
import { IPostParameterValueDto, IPutParameterValueDto, IParameterGroupListValue, IGetParameterValueDto } from 'shared/interfaces';

class ParameterValueModel {
  constructor(dto?: IGetParameterValueDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.parameter.id;
    this.valueId = dto.id;
    this.value = dto.value;
    this.paramName = dto.parameter.name;
    this.paramDataType = dto.parameter.dataType;
    this.description = dto.description;

    this.file = new SpecificationFilesModel(dto.file);
    this.parameterListValues = dto.parameterListValues;
  }

  public id: number | null = null;

  public valueId: number | null = null;

  public value = '';

  public editValue = '';

  public paramName = '';

  public paramDataType = DataType.String;

  public description = '';

  public isInheritedValue = false;

  public isDocumentExists = false;

  public hasComments = false;

  public parameterListValues: IParameterGroupListValue[] = [];

  public parameterListValueIds: number[] = [];

  public file = new SpecificationFilesModel();

  public get valueWithoutExtension(): string {
    return this.value.split('.').slice(0, -1).join('.');
  }

  public get putDto(): IPutParameterValueDto {
    return {
      id: this.valueId!,
      value: this.paramDataType === DataType.Number ? this.value.replaceAll(',', '.') : this.value,
      description: this.description,
      parameterListValueIds: this.parameterListValueIds ? this.parameterListValueIds : undefined,
      fileId: this.file.id ?? undefined,
    };
  }

  public postDto(itemId: number): IPostParameterValueDto | null {
    if (!this.id) return null;

    return {
      parameterId: this.id,
      itemId,
      value:
        this.parameterListValueIds.length === 0 ? (this.paramDataType === DataType.Number ? this.value.replaceAll(',', '.') : this.value) : undefined,
      parameterListValueIds: this.parameterListValueIds.length !== 0 ? this.parameterListValueIds : undefined,
      fileId: this.file.id ?? undefined,
    };
  }

  public setParameterListValueIds(parameterListValueIds: number[]) {
    this.parameterListValueIds = parameterListValueIds;
  }

  public setValue(value: string) {
    this.value = value;
  }

  public setEditValue(value: string) {
    this.editValue = value;
  }

  public setId(id: number) {
    this.id = id;
  }

  public setParamName(name: string) {
    this.paramName = name;
  }

  public setValueId(id: number | null) {
    this.valueId = id;
  }

  public setInheritedValue(isInheritedValue: boolean) {
    this.isInheritedValue = isInheritedValue;
  }

  public clear() {
    this.id = null;
    this.valueId = null;
    this.value = '';
    this.editValue = '';
    this.paramName = '';
    this.paramDataType = DataType.String;
    this.description = '';
    this.isInheritedValue = false;
    this.isDocumentExists = false;
    this.hasComments = false;
    this.parameterListValues = [];
    this.parameterListValueIds = [];
    this.file = new SpecificationFilesModel();
  }
}

export default ParameterValueModel;
