import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, approvalSuggestionRowStore, partialApprovalStore, usersStore } from 'stores';
import { useConfirm } from 'components/App/Confirm';
import ApprovedSuggestionIcon from 'components/Icons/ApprovedSuggestionIcon';
import CloseIcon from 'components/Icons/CloseIcon';
import RejectedSuggestionIcon from 'components/Icons/RejectedSuggestionIcon';
import SmallCheckIcon from 'components/Icons/SmallCheckIcon';
import { Stack, Typography, Avatar, Theme, Box } from '@mui/material';
import { ApprovalUserRole, PartialApprovalIteration, SuggestionType } from 'shared/enums';
import { ISuggestionUserDto } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';
import { SuggestionAction } from './Components';

interface ISuggestionRowProps {
  suggestionRowId: number;
  suggestionType?: SuggestionType;
  iteration: PartialApprovalIteration;
  isGroup: boolean;
  suggestionText: string | null;
  suggestionMultilineText: string[] | null;
  user: ISuggestionUserDto;
  userRoles: ApprovalUserRole[];
  isEvaluationAccept: boolean;
  isUserAccept: boolean;
  onSelectSuggestionRow: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const SuggestionRow: React.FC<ISuggestionRowProps> = (props) => {
  const {
    iteration,
    suggestionType,
    isEvaluationAccept,
    isUserAccept,
    suggestionText,
    suggestionMultilineText,
    isGroup,
    user,
    suggestionRowId,
    onSelectSuggestionRow,
  } = props;

  const [isHover, setHover] = useState(false);

  const confirm = useConfirm();

  const getColor = (theme: Theme): string => {
    switch (suggestionType) {
      case SuggestionType.Delete:
        return theme.palette.error.main;

      case SuggestionType.Create:
        return theme.palette.info.main;

      default:
        return isGroup ? theme.palette.background.default : 'rgba(0, 0, 0, 0)';
    }
  };

  const getEvaluationLabelIcon = (isEvaluationAccept: boolean): JSX.Element => {
    return (
      <Stack
        sx={(theme) => ({
          svg: {
            fill: !isEvaluationAccept ? theme.palette.error.main : theme.palette.success.main,
          },
        })}>
        {!isEvaluationAccept ? <CloseIcon /> : <SmallCheckIcon />}
      </Stack>
    );
  };

  const getSelfLabelIcon = (isUserAccept: boolean): JSX.Element => {
    return (
      <Stack
        alignItems="center"
        sx={{
          width: '28px',
          pr: !isHover ? (partialApprovalStore.selectedPartialApproval.iteration === PartialApprovalIteration.Execution ? '0' : '50px') : '0',
        }}>
        {!isUserAccept ? <RejectedSuggestionIcon /> : <ApprovedSuggestionIcon />}
      </Stack>
    );
  };

  const isMine = user.id === usersStore.getUserData().id;

  const getUser = (userName: string, color: string): JSX.Element => {
    return (
      <Avatar
        sx={{
          width: 24,
          height: 24,
          fontWeight: 500,
          fontSize: '12px',
          lineHeight: '20px',
          background: `linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0) 100%), ${color};}`,
        }}>
        {Utils.getInitials(userName)}
      </Avatar>
    );
  };

  const onDelete = () => {
    confirm.show(Translator.translate('ui.suggestionRow.confirmDeleteMessage'), Translator.translate('actions.delete')).then(() => {
      approvalSuggestionRowStore.removeRowSuggestionApproval(suggestionRowId);
    });
  };

  const getSuggestionMultilineText = (): JSX.Element => {
    if (suggestionMultilineText && suggestionMultilineText.length > 0) {
      return (
        <Stack>
          {suggestionMultilineText.map((text) => {
            return <Box key={Utils.generateId()}>{text}</Box>;
          })}
        </Stack>
      );
    } else {
      return <></>;
    }
  };

  const renderSuggestedValue = (): JSX.Element | string => {
    if (suggestionType === SuggestionType.Delete) {
      return Translator.translate('ui.suggestionRow.suggestedDeletion');
    } else {
      if (suggestionText && !Array.isArray(suggestionText)) {
        return Utils.deleteInnerWrap(suggestionText);
      } else if (!suggestionText && Array.isArray(suggestionMultilineText)) {
        return getSuggestionMultilineText();
      }

      return '';
    }
  };
  const showAvatar = iteration === PartialApprovalIteration.Examination && !isMine;

  return (
    <Stack
      sx={(theme) => ({
        borderLeft: '4px solid',
        borderColor: getColor(theme),
        borderRadius: '4px 0 0 4px',
        ':hover': {
          bgcolor: 'action.hover',
        },
        backgroundColor: suggestionType === SuggestionType.Delete ? theme.palette.error.light : 'rgba(0, 0, 0, 0)',
      })}
      width="100%"
      height="100%"
      minHeight="36px"
      pl={2.5}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      gap={2.5}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={(event) => onSelectSuggestionRow(event)}>
      <Stack flexDirection="row" gap={2.5} alignItems="center">
        {(iteration === PartialApprovalIteration.Recheck || iteration === PartialApprovalIteration.Approval) &&
          isEvaluationAccept !== null &&
          getEvaluationLabelIcon(isEvaluationAccept)}

        {(iteration !== PartialApprovalIteration.Examination || showAvatar) && getUser(user.name, user.color)}

        <Typography
          variant="body2"
          sx={(theme) => ({
            color: suggestionType === SuggestionType.Delete ? theme.palette.error.main : theme.palette.text.primary,
            display: 'flex',
          })}>
          {renderSuggestedValue()}
        </Typography>
      </Stack>

      <Stack flexDirection="row" gap={2.5} alignItems="center" minWidth="100px" justifyContent="flex-end">
        {isUserAccept !== null && !isHover && iteration !== PartialApprovalIteration.Examination && getSelfLabelIcon(isUserAccept)}

        <SuggestionAction
          isHideApprove={
            !isHover ||
            isUserAccept === true ||
            !partialApprovalStore.selectedPartialApproval.myIterations.includes(partialApprovalStore.selectedPartialApproval.iteration) ||
            iteration === PartialApprovalIteration.Examination
          }
          isHideCancel={
            !isHover ||
            isUserAccept === false ||
            !partialApprovalStore.selectedPartialApproval.myIterations.includes(partialApprovalStore.selectedPartialApproval.iteration) ||
            iteration === PartialApprovalIteration.Approval ||
            iteration === PartialApprovalIteration.Examination
          }
          isHideDelete={
            !isHover ||
            !partialApprovalStore.selectedPartialApproval.myIterations.includes(partialApprovalStore.selectedPartialApproval.iteration) ||
            !isMine ||
            partialApprovalStore.selectedPartialApproval.iteration === PartialApprovalIteration.Recheck
          }
          onApprove={() => approvalSuggestionRowStore.rowSuggestionSetAccepted({ id: suggestionRowId, isAccepted: true })}
          onCancel={() => approvalSuggestionRowStore.rowSuggestionSetAccepted({ id: suggestionRowId, isAccepted: false })}
          onDelete={onDelete}
        />
      </Stack>
    </Stack>
  );
};

export default observer(SuggestionRow);
