import { makeAutoObservable } from 'mobx';
import { Roles } from 'shared/enums';
import { IGetUserDto, IProject, IPutUserDto } from 'shared/interfaces';

class UserListModel {
  constructor(dto?: IGetUserDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.roles = dto.roles;
    this.projects = [...dto.projects];
    this.infBlocksIds = dto.informationBlocks.map((_) => _.id);
    this.regionsIds = dto.regions.map((_) => _.id);
  }

  public id: number | null = null;

  public name = '';

  public projects: IProject[] = [];

  public roles: Roles[] = [];

  public infBlocksIds: number[] = [];

  public regionsIds: number[] = [];

  public get putDto(): IPutUserDto | null {
    if (!this.id) return null;

    return {
      id: this.id,
      projectIds: this.projects.map((_) => _.id),
      informationBlockIds: this.infBlocksIds,
      regionIds: this.regionsIds,
    };
  }

  public setSelectedInfBlocksIds(ids: number[]) {
    this.infBlocksIds = ids;
  }

  public setSelectedRegionsIds(ids: number[]) {
    this.regionsIds = ids;
  }

  public addProject(newProjects: IProject[]) {
    newProjects.forEach((project) => this.projects.push(project));
  }

  public deleteProject(id: number) {
    const findProjectIndex = this.projects.findIndex((project) => project.id === id);
    this.projects.splice(findProjectIndex, 1);
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.projects = [];
    this.infBlocksIds = [];
    this.regionsIds = [];
    this.roles = [];
  }
}

export default UserListModel;
