import React from 'react';

const RevertIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="rgba(15, 11, 31, 0.54)" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.66078 2.0775C10.4148 1.83759 12.2028 2.15795 13.7565 2.99293C15.3106 3.82811 16.5489 5.13476 17.2795 6.72077C17.7571 7.75773 18 8.87586 18 10C18 14.4183 14.4183 18 10 18C7.76171 18 5.73661 17.0795 4.28576 15.5989C3.89922 15.2044 3.90564 14.5713 4.3001 14.1847C4.69456 13.7982 5.3277 13.8046 5.71424 14.1991C6.80442 15.3116 8.32092 16 10 16C13.3137 16 16 13.3137 16 10C16 9.16363 15.8192 8.33109 15.4629 7.55752C14.9174 6.37332 13.9882 5.38794 12.8098 4.75464C11.6309 4.12112 10.2696 3.87608 8.93181 4.05905C7.605 4.24053 6.37825 4.83246 5.43181 5.74139L3.39562 8H5C5.55228 8 6 8.44771 6 9C6 9.55228 5.55228 10 5 10H1C0.447715 10 0 9.55228 0 9V5C0 4.44771 0.447715 4 1 4C1.55228 4 2 4.44771 2 5V6.56116L3.96953 4.37649C3.98442 4.35997 3.99986 4.34395 4.01582 4.32847C5.27589 3.10555 6.90662 2.31743 8.66078 2.0775Z" />
    </svg>
  );
};

export default RevertIcon;
