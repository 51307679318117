import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';

import { useNavigate } from 'react-router-dom';
import { Translator, commentStore, definitionStore, itemStore, parameterGroupStore, parameterStore } from 'stores';

import { TableView } from 'components';
import { List, ListItemText, Collapse, ListItemButton, Link, Paper } from '@mui/material';
import { CommentMode } from 'shared/enums';
import { Routes } from 'shared/enums/Routes';
import { ITableColumn } from 'shared/interfaces';
import { ItemModel } from 'shared/models';
import Utils from 'shared/utils/Utils';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export const HEIGHT_CONTENT = 48;

const CommentPage: React.FC = () => {
  const navigate = useNavigate();

  const [itemOpen, setItemOpen] = useState(true);
  const [parameterValueOpen, setParameterValueOpen] = useState(true);

  const itemColumns: ITableColumn[] = [
    {
      key: 'name',
      label: Translator.translate('comments.columns.name'),
      width: '400px',
      align: 'left',
    },
    {
      key: 'createdAt',
      label: Translator.translate('comments.columns.createdAt'),
      width: '400px',
      align: 'left',
    },
    {
      key: 'userName',
      label: Translator.translate('comments.columns.userName'),
      width: '400px',
      align: 'left',
    },
    {
      key: 'text',
      label: Translator.translate('comments.columns.text'),
      width: '400px',
      align: 'left',
      cell: (row) => {
        return (
          <Link component="button" onClick={() => redirectToItemComment(row.itemId)}>
            {row.text}
          </Link>
        );
      },
    },
  ];

  const parameterValueColumns: ITableColumn[] = [
    {
      key: 'itemName',
      label: Translator.translate('comments.columns.name'),
      width: '400px',
      align: 'left',
    },
    {
      key: 'parameterName',
      label: Translator.translate('comments.columns.parameterName'),
      width: '400px',
      align: 'left',
    },
    {
      key: 'createdAt',
      label: Translator.translate('comments.columns.createdAt'),
      width: '400px',
      align: 'left',
    },
    {
      key: 'userName',
      label: Translator.translate('comments.columns.userName'),
      width: '400px',
      align: 'left',
    },
    {
      key: 'text',
      label: Translator.translate('comments.columns.text'),
      width: '400px',
      align: 'left',
      cell: (row) => {
        return (
          <Link component="button" onClick={() => redirectToParamComment(row.itemId, row.parameterId)}>
            {row.text}
          </Link>
        );
      },
    },
  ];

  useEffect(() => {
    commentStore.getCommentSummaryByDefinition();
  }, []);

  const onOpenItem = () => {
    setItemOpen(!itemOpen);
  };

  const onOpenParameterValue = () => {
    setParameterValueOpen(!parameterValueOpen);
  };

  const redirectToItemComment = (id: number) => {
    commentStore.setMode(CommentMode.Item);
    commentStore.setFilters(id);

    const findKey = itemStore.listTransformItemDto.find((item) => item.id === id);
    itemStore.selectItem = new ItemModel(findKey);

    navigate(`${Routes.OBJECT_VALUE}`);
    commentStore.setOpenModal(true);
    commentStore.getAllComments();
  };

  const redirectToParamComment = async (itemId: number, paramId: number) => {
    commentStore.setMode(CommentMode.Parameter);
    commentStore.setFilters(itemId, paramId);

    //навигация
    if (itemStore.treeTestItem.length === 0) {
      definitionStore.currentDefinitionId && (await itemStore.getAllItems(definitionStore.currentDefinitionId));
    }

    const findKey = itemStore.listTransformItemDto.find((item) => item.id === itemId);
    itemStore.selectItem = new ItemModel(findKey);

    parameterStore.selectedParameterTable.setId(paramId);
    await parameterStore.getParameterById();

    const groupName = parameterStore.selectDocumentByParameter.fullName.split('.')[0];

    if (parameterGroupStore.rootParameterGroup.length === 0) {
      await parameterGroupStore.getRootParameterGroupList();
    }

    const groupId = parameterGroupStore.rootParameterGroup.find((f) => f.name === groupName)?.id;
    if (groupId) parameterGroupStore.setCurrentParameterGroup(Number(groupId));

    navigate(`${Routes.OBJECT_VALUE}`);
    commentStore.setOpenModal(true);
    commentStore.getAllComments();
  };

  return (
    <Paper elevation={0} sx={{ p: 6, borderRadius: 3, display: 'flex', flex: 1, flexDirection: 'column' }}>
      <List
        sx={{
          width: '100%',
          height: `calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight()}px)`,
          overflow: 'auto',
          bgcolor: 'background.paper',
          p: 0,
        }}
        aria-labelledby="nested-list-subheader">
        <ListItemButton onClick={onOpenItem}>
          {itemOpen ? <ExpandLess /> : <ExpandMore />}
          <ListItemText primary={Translator.translate('comments.commentType.items')} />
        </ListItemButton>
        <Collapse in={itemOpen} timeout="auto" unmountOnExit>
          <List component="div" sx={{ px: 4 }}>
            <TableView data={commentStore.itemCommentsSummaryForTable} columns={itemColumns} hideSelectRowOnMount />
          </List>
        </Collapse>
        <ListItemButton onClick={onOpenParameterValue}>
          {parameterValueOpen ? <ExpandLess /> : <ExpandMore />}
          <ListItemText primary={Translator.translate('comments.commentType.parameterValues')} />
        </ListItemButton>
        <Collapse in={parameterValueOpen} timeout="auto" unmountOnExit>
          <List component="div" sx={{ px: 4 }}>
            <TableView data={commentStore.paramValueCommentsSummaryForTable} columns={parameterValueColumns} hideSelectRowOnMount />
          </List>
        </Collapse>
      </List>
    </Paper>
  );
};

export default observer(CommentPage);
