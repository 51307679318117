import { makeAutoObservable, runInAction } from 'mobx';
import { ProjectService } from 'services';
import { definitionStore, Translator, projectStore, toastStore } from 'stores';
import DefinitionService from 'services/DefinitionService';
import { IDefinitionGetDto, IRecentlyDefinitionStorage, ISelectOption } from 'shared/interfaces';
import { DefinitionListModel, DefinitionModel, DefinitionRecentlyListModel } from 'shared/models/DefinitionModel';

export const MAX_RECENTLY_DEFINITION = 4;

class DefinitionStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public isDialogOpen = false;

  public currentDefinitionId: number | null = null;

  public currentDefinition = new DefinitionModel();

  public definitions: DefinitionListModel[] = [];

  public selectedDefinition = new DefinitionModel();

  public recentlyDefinitions: DefinitionRecentlyListModel[] = [];

  public get definitionsForOptions(): ISelectOption[] {
    return this.definitions.map((item) => item.getSelectOption()!);
  }

  public setDialogOpen(isOpen: boolean) {
    this.isDialogOpen = isOpen;
  }

  public setCurrentDefinitionId(currentDefinitionId: number | null) {
    this.currentDefinitionId = currentDefinitionId;
  }

  public setStorage(data: IRecentlyDefinitionStorage[]) {
    localStorage.setItem('recentlyDefinitions', JSON.stringify(data));
  }

  public getStorage(): IRecentlyDefinitionStorage[] {
    if (localStorage.getItem('recentlyDefinitions')) {
      return JSON.parse(localStorage.getItem('recentlyDefinitions')!);
    }

    return [];
  }

  public syncDefinition(recentlyDefinitionsStorage: IRecentlyDefinitionStorage[], defIds: number[]) {
    const filterData: IRecentlyDefinitionStorage[] = recentlyDefinitionsStorage.filter((f: IRecentlyDefinitionStorage) =>
      defIds.includes(f.definitionId)
    );

    if (JSON.stringify(filterData) !== JSON.stringify(recentlyDefinitionsStorage)) {
      if (filterData.length > 0) this.setStorage(filterData);
      else localStorage.removeItem('recentlyDefinitions');
    }
  }

  public addRecentlyDefinition() {
    if (!definitionStore.currentDefinition.id) {
      return;
    }

    const recentlyDefinition: IRecentlyDefinitionStorage = {
      definitionId: definitionStore.currentDefinition.id,
      isPin: false,
    };

    const array = this.getStorage();
    if (array.length > 0) {
      if (!array.find((f: IRecentlyDefinitionStorage) => f.definitionId === recentlyDefinition.definitionId)) {
        if (array.length < MAX_RECENTLY_DEFINITION) {
          array.push(recentlyDefinition);
          this.setStorage(array);
        } else if (array.length === MAX_RECENTLY_DEFINITION) {
          if (!array.some((f: IRecentlyDefinitionStorage) => f.isPin)) {
            array.shift();
            array.push(recentlyDefinition);
            this.setStorage(array);
          } else if (array.some((f: IRecentlyDefinitionStorage) => f.isPin) && !array.every((f: IRecentlyDefinitionStorage) => f.isPin)) {
            const lastIdx = array.map((data: IRecentlyDefinitionStorage) => data.isPin).lastIndexOf(true);

            if (lastIdx !== -1) {
              array.splice(lastIdx + 1, 1);
              array.push(recentlyDefinition);
              this.setStorage(array);
            }
          }
        }
      }
    } else localStorage.setItem('recentlyDefinitions', JSON.stringify([recentlyDefinition]));
  }

  public onSetPinInLocalStorage(project: DefinitionRecentlyListModel) {
    const array = this.getStorage();
    if (array.length > 0) {
      const findEl = array.find((f) => Number(f.definitionId) === project.definitions.id);
      if (findEl) {
        findEl.isPin = !findEl.isPin;
      }

      //сортировка: первые элементы те, у которых isPin = true
      if (array.some((f: IRecentlyDefinitionStorage) => f.isPin)) {
        array.sort((left: IRecentlyDefinitionStorage, right: IRecentlyDefinitionStorage) => {
          if (left.isPin > right.isPin) {
            return -1;
          } else if (left.isPin < right.isPin) {
            return 1;
          }
          return 0;
        });
      }

      this.setStorage(array);
      this.getRecentlyDefinitions();
    }
  }

  public async setEditingMode(id: number) {
    await this.getDefinitionById(id);
  }

  public async getDefinitionList(projectId: number) {
    try {
      this.isLoading = true;
      const result = await DefinitionService.getAllDefinitions(projectId);
      if (!result) return;

      this.definitions = result.map((dto) => new DefinitionListModel(dto));
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Definitions`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getRecentlyDefinitions() {
    try {
      this.isLoading = true;
      const recentlyDefinitionsStorage = this.getStorage();

      if (recentlyDefinitionsStorage.length === 0) return;

      projectStore.projectFilters.setDefinitionIds(recentlyDefinitionsStorage.map((def: IRecentlyDefinitionStorage) => def.definitionId));
      const result = await ProjectService.postFilterProjects({
        definitionIds: projectStore.projectFilters.definitionIds,
      });
      if (!result) return;

      const recentlyDefinition: DefinitionRecentlyListModel[] = [];
      const defIds: number[] = [];
      recentlyDefinitionsStorage.forEach((data: IRecentlyDefinitionStorage) => {
        result.forEach((project) => {
          if (project.definitions) {
            project.definitions.forEach((definition) => {
              defIds.push(definition.id!);

              if (definition.id === Number(data.definitionId)) {
                const model = new DefinitionRecentlyListModel(project, Number(data.definitionId));
                model.setPin(data.isPin);
                recentlyDefinition.push(model);
              }
            });
          }
        });
      });

      this.syncDefinition(recentlyDefinitionsStorage, defIds);

      this.recentlyDefinitions = recentlyDefinition;
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} RecentlyDefinitions`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getDefinitionById(id: number | null) {
    if (id === null) return;

    try {
      this.isLoading = true;
      const result = await DefinitionService.getDefinition(id);

      if (!result) return;

      runInAction(() => {
        this.selectedDefinition = new DefinitionModel(result);
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} Definition`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getCurrentDefinition(id: number): Promise<IDefinitionGetDto | undefined> {
    try {
      this.isLoading = true;
      const result = await DefinitionService.getDefinition(id);
      if (!result) return;

      this.currentDefinition = new DefinitionModel(result);
      return result;
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} CurrentDefinition`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createDefinition() {
    if (!this.selectedDefinition.postPutDto) return;

    try {
      this.isLoading = true;
      await DefinitionService.postDefinition(this.selectedDefinition.postPutDto);
      projectStore.getProjectList();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} Definition`);
    } finally {
      this.isLoading = false;
    }
  }

  public async updateDefinition(): Promise<void> {
    if (!this.selectedDefinition.id && !this.selectedDefinition.postPutDto) return;

    try {
      this.isLoading = true;
      await DefinitionService.putDefinition(this.selectedDefinition.id!, this.selectedDefinition.postPutDto as any);
      projectStore.getProjectList();
      this.getRecentlyDefinitions();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} Definition`);
    } finally {
      this.isLoading = false;
    }
  }

  public async copyDefinition() {
    if (!this.selectedDefinition.copyDto) return;

    try {
      this.isLoading = true;
      await DefinitionService.copyDefinition(this.selectedDefinition.copyDto);
      projectStore.getProjectList();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.copyErrorMessage')} Definition`);
    } finally {
      this.isLoading = false;
    }
  }

  public async removeDefinition() {
    if (!this.selectedDefinition.id) return;

    try {
      this.isLoading = true;
      await DefinitionService.deleteDefinition(this.selectedDefinition.id);
      if (this.selectedDefinition.id === Number(this.currentDefinition.id)) {
        localStorage.removeItem('currentDefinitionId');
        this.currentDefinition.id = null;
      }

      projectStore.getProjectList();
      this.getRecentlyDefinitions();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} Definition`);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new DefinitionStore();
