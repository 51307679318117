import { BASE_PATH } from 'shared/constants/constants';
import { IGetMeasurementUnitDto, IPostPutMeasurementUnitDto } from 'shared/interfaces';
import HTTPService from './HttpService';

/* Сервис для получения данных единиц измерений*/
class MeasurementUnitService extends HTTPService {
  constructor() {
    super(BASE_PATH.MEASUREMENT_UNIT);
  }

  public getAllMeasurementUnits(): Promise<IGetMeasurementUnitDto[]> {
    return this.GET<IGetMeasurementUnitDto[]>('');
  }

  public getMeasurementUnit(id: number): Promise<IGetMeasurementUnitDto[]> {
    return this.GET('', { params: { id } });
  }

  public postMeasurementUnit(body: IPostPutMeasurementUnitDto): Promise<number> {
    return this.POST('', body);
  }

  public putMeasurementUnit(id: number, body: IPostPutMeasurementUnitDto): Promise<IGetMeasurementUnitDto> {
    return this.PUT(`${id}`, body);
  }

  public deleteMeasurementUnit(id: number): Promise<void> {
    return this.DELETE(`${id}`);
  }
}

export default new MeasurementUnitService();
