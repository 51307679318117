import { makeAutoObservable, runInAction } from 'mobx';
import { SectionSetService } from 'services';
import { Translator, sectionsStore, toastStore } from 'stores';
import { NestedLevel } from 'shared/enums';
import { IGetSectionSetDto, ISectionSetCollection, ISelectOption } from 'shared/interfaces';
import { SectionSetModel } from 'shared/models';

class SectionSetStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public isDialogOpen = false;

  public sectionSets: IGetSectionSetDto[] = [];

  public sectionSetCollections: ISectionSetCollection[] = [];

  public filterName = '';

  public filterHeight: number | null = null;

  public filterStageIds: number[] = [];

  public selectedSectionSet = new SectionSetModel();

  public currentSectionSet: IGetSectionSetDto | null = null;

  public hoveredRowIds: number[] = [];

  public get sectionSetsForOptions(): ISelectOption[] {
    return this.sectionSets.map((item) => {
      return {
        id: item.id!,
        name: item.name,
      };
    });
  }

  public filteredSectionSetCollectionCollections() {
    const filteredData = this.sectionSets.filter(
      (sectionSet) =>
        sectionSet.name.toLowerCase().includes(this.filterName.toLowerCase()) ||
        sectionSet.sectionSetCollection.name.toLowerCase().includes(this.filterName.toLowerCase())
    );

    this.sectionSetCollections = this.preparedSectionSetCollections(filteredData);
  }

  public preparedSectionSetCollections(sectionSets: IGetSectionSetDto[]): ISectionSetCollection[] {
    //grouping data by sectionSetCollectionId
    const hoveredIds: number[] = [];

    const groupCollectionMap = sectionSets.reduce((acc: Map<number, ISectionSetCollection>, cur) => {
      if (!acc.has(cur.sectionSetCollection.id)) {
        acc.set(cur.sectionSetCollection.id, {
          id: cur.sectionSetCollection.id,
          name: cur.sectionSetCollection.name,
          level: NestedLevel.First,
          stages: [],
          children: [],
        });
      }

      hoveredIds.push(cur.id);

      acc.get(cur.sectionSetCollection.id)?.children.push({
        id: cur.id,
        sectionSetCollectionId: cur.sectionSetCollection.id,
        name: cur.name,
        level: NestedLevel.Second,
        stages: cur.stages,
        children: [],
      } as ISectionSetCollection);
      return acc;
    }, new Map());

    this.hoveredRowIds = hoveredIds;
    const data = Array.from(groupCollectionMap.values());

    return data;
  }

  public setDialogOpen(isOpen: boolean) {
    this.isDialogOpen = isOpen;
  }

  public setFilterName(filterName: string) {
    this.filterName = filterName;
  }

  public setFilterHeight(filterHeight: number | null) {
    this.filterHeight = filterHeight;
  }

  public setFilterStageIds(stageId: number) {
    const idx = this.filterStageIds.indexOf(stageId);
    if (idx !== -1) {
      this.filterStageIds.splice(idx, 1);
    } else {
      this.filterStageIds.push(stageId);
    }
  }

  public setFilterStageId(stageId: number | null) {
    this.filterStageIds = stageId ? [stageId] : [];
  }

  public clearFilterStageIds() {
    this.filterStageIds = [];
  }

  public async getSectionSets(): Promise<void> {
    try {
      this.isLoading = true;

      const result = await SectionSetService.getAllSectionSets(this.filterStageIds);
      if (!result) return;

      this.sectionSets = result;
      this.filteredSectionSetCollectionCollections();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} SectionSets`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getSectionSetById(id: number): Promise<void> {
    try {
      const result = await SectionSetService.getSectionSet(id);

      if (!result) return;

      runInAction(() => {
        this.selectedSectionSet = new SectionSetModel(result[0]);
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} SectionSet`);
    }
  }

  public async getCurrentSectionSet(id: number): Promise<void> {
    this.isLoading = true;

    try {
      const result = await SectionSetService.getSectionSet(id);

      if (!result) return;

      if (result.length > 0) {
        this.currentSectionSet = result[0];
        sectionsStore.setFilterSectionSetId(this.currentSectionSet.id);
        await sectionsStore.getSections();
      }
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} CurrentSectionSet`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createSectionSet(): Promise<void> {
    if (!this.selectedSectionSet.postPutDto) return;

    try {
      await SectionSetService.postSectionSet(this.selectedSectionSet.postPutDto);
      this.getSectionSets();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} SectionSet`);
    }
  }

  public async createBasingOnSectionSet(): Promise<void> {
    if (!this.selectedSectionSet.copyDto) return;

    try {
      await SectionSetService.copySectionSet(this.selectedSectionSet.copyDto);
      this.getSectionSets();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} BasingOnSectionSet`);
    }
  }

  public async updateSectionSet(): Promise<void> {
    if (!this.selectedSectionSet.id || !this.selectedSectionSet.postPutDto) return;

    try {
      await SectionSetService.putSectionSet(this.selectedSectionSet.id, this.selectedSectionSet.postPutDto);
      this.getSectionSets();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} SectionSet`);
    }
  }

  public async removeSectionSet(id: number): Promise<void> {
    try {
      await SectionSetService.deleteSectionSet(id);
      await this.getSectionSets();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} SectionSet`);
    }
  }
}

export default new SectionSetStore();
