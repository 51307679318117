import React from 'react';

const ObjectClassifierIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3644 1.91584C10.5189 1.43273 9.48106 1.43273 8.63562 1.91584L3.63562 4.77298C2.77879 5.2626 2.25 6.17379 2.25 7.16065V12.8394C2.25 13.8262 2.77879 14.7374 3.63562 15.227L7.64334 17.5172C9.10364 18.3516 10.8964 18.3516 12.3567 17.5172L16.3644 15.227C17.2212 14.7374 17.75 13.8262 17.75 12.8394V7.16065C17.75 6.17379 17.2212 5.2626 16.3644 4.77298L11.3644 1.91584ZM9.37983 3.2182C9.76412 2.99861 10.2359 2.99861 10.6202 3.2182L15.4515 5.97893L10.0004 9.13323L4.5554 5.97502L9.37983 3.2182ZM3.75 7.24193V12.8394C3.75 13.2879 3.99036 13.7021 4.37983 13.9247L8.38755 16.2148C8.6611 16.3711 8.95169 16.4846 9.25 16.5553V10.432L3.75 7.24193ZM10.75 16.5553C11.0483 16.4846 11.3389 16.3711 11.6125 16.2148L15.6202 13.9247C16.0096 13.7021 16.25 13.2879 16.25 12.8394V7.24988L10.75 10.4325V16.5553Z"
      />
    </svg>
  );
};

export default ObjectClassifierIcon;
