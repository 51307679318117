import { makeAutoObservable } from 'mobx';
import { arrayToTree } from 'performant-array-to-tree';
import { DefinitionService } from 'services';
import { Translator, definitionStore, parameterGroupStore, toastStore } from 'stores';
import { DEFAULT_WIDTH_GROUPS_MERGE } from 'shared/constants/constants';
import { MergeState, MergeTabState } from 'shared/enums';
import { IChangesParameterValues, ICollapseTable, IGroupTree, IMergeGroupTree, IMergeTable, IParameterValueTreeDto } from 'shared/interfaces';
import { CollapseTableModel } from 'shared/models';
import { MergeModel, MergeTableModel } from 'shared/models/MergeModel';

import ParameterValueMergeTableModel from 'shared/models/MergeModel/ParameterValueMergeModel';
import Utils from 'shared/utils/Utils';

class MergeStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public groupsMergeWidth = DEFAULT_WIDTH_GROUPS_MERGE;

  public merge = new MergeModel();

  public setGroupsMergeWidth(value: number) {
    this.groupsMergeWidth = value;
  }

  public createGroupMergeData(result: any, propertyName: string, mergeState: MergeTabState): IMergeTable[] {
    const array: IMergeTable[] = [];

    switch (mergeState) {
      case MergeTabState.AddDel: {
        result[propertyName].added.forEach((data: any) => {
          array.push({
            id: data.id,
            fullName: data.fullName,
            name: data.name,
            icon: MergeState.Added,
          });
        });

        result[propertyName].deleted.forEach((data: any) => {
          array.push({
            id: data.id,
            fullName: data.fullName,
            name: data.name,
            icon: MergeState.Deleted,
          });
        });

        return array;
      }

      case MergeTabState.Changed:
        result[propertyName].updated.forEach((data: any) => {
          array.push({
            id: data.id,
            fullName: data.fullName,
            icon: MergeState.Updated,
            modifications: data.modifications,
          });
        });

        return array;
    }
  }

  public async getMerge() {
    if (!definitionStore.currentDefinition.id || !this.merge.currentMergeDefinition) return;

    try {
      this.isLoading = true;
      const result = await DefinitionService.getAllMerges(definitionStore.currentDefinition.id, this.merge.currentMergeDefinition);
      if (!result) return;

      this.merge.mergeChangeItems = this.createGroupMergeData(result, 'Item', MergeTabState.Changed)
        .map((dto) => new MergeTableModel(dto))
        .sort((a, b) => Utils.ascComp(a.fullName, b.fullName));
      this.merge.mergeAddDelItems = this.createGroupMergeData(result, 'Item', MergeTabState.AddDel)
        .map((dto) => new MergeTableModel(dto))
        .sort((a, b) => Utils.ascComp(a.fullName, b.fullName))
        .sort((a, b) => Utils.ascComp(a.icon, b.icon));

      this.merge.mergeParamValues = result['ParameterValue'].changes.map((dto: IChangesParameterValues) => {
        const data: ICollapseTable = {
          id: dto.id,
          fullName: dto.parameterGroupName,
        };
        const newModel = new CollapseTableModel<ParameterValueMergeTableModel>(data);

        if (dto.parameters) {
          newModel.groupItems = dto.parameters.map((dto) => new ParameterValueMergeTableModel(dto));
        }
        return newModel;
      });

      parameterGroupStore.setParameterGroupsMergeNavigation(result.ParameterValue.tree);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Merges`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createMerge(): Promise<void> {
    if (this.merge.postDto === null) return;

    try {
      this.isLoading = true;
      // TODO: remove undefined hack postChangedMerge input function
      await DefinitionService.postMerge(this.merge.postDto);
      this.getMerge();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.mergeErrorMessage')}`);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new MergeStore();
