import React from 'react';

const RangeValueIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="rgba(169, 171, 185, 1)" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5C1 3.34315 2.34315 2 4 2H5C5.55228 2 6 2.44772 6 3C6 3.55228 5.55228 4 5 4H4C3.44772 4 3 4.44772 3 5V11C3 11.5523 3.44772 12 4 12H5C5.55228 12 6 12.4477 6 13C6 13.5523 5.55228 14 5 14H4C2.34315 14 1 12.6569 1 11V5Z" />
      <path d="M5 10C5.55228 10 6 9.55228 6 9C6 8.44772 5.55228 8 5 8C4.44772 8 4 8.44772 4 9C4 9.55228 4.44772 10 5 10Z" />
      <path d="M11 10C11.5523 10 12 9.55228 12 9C12 8.44772 11.5523 8 11 8C10.4477 8 10 8.44772 10 9C10 9.55228 10.4477 10 11 10Z" />
      <path d="M9 9C9 9.55228 8.55228 10 8 10C7.44772 10 7 9.55228 7 9C7 8.44772 7.44772 8 8 8C8.55228 8 9 8.44772 9 9Z" />
      <path d="M12 2C13.6569 2 15 3.34315 15 5V11C15 12.6569 13.6569 14 12 14H11C10.4477 14 10 13.5523 10 13C10 12.4477 10.4477 12 11 12H12C12.5523 12 13 11.5523 13 11V5C13 4.44772 12.5523 4 12 4H11C10.4477 4 10 3.55228 10 3C10 2.44772 10.4477 2 11 2H12Z" />
    </svg>
  );
};

export default RangeValueIcon;
