import React from 'react';

const CutIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#7B68EE" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9C5.75995 9 6.47041 8.78807 7.07549 8.42009L7.70233 8.98994C8.11098 9.36145 8.74343 9.33133 9.11494 8.92267C9.48645 8.51402 9.45633 7.88157 9.04767 7.51006L8.47234 6.98703C8.80806 6.40162 9 5.72323 9 5C9 2.79086 7.20914 1 5 1ZM3 5C3 3.89543 3.89543 3 5 3C6.10457 3 7 3.89543 7 5C7 6.10457 6.10457 7 5 7C3.89543 7 3 6.10457 3 5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 11C2.79086 11 1 12.7909 1 15C1 17.2091 2.79086 19 5 19C7.20914 19 9 17.2091 9 15C9 14.2768 8.80806 13.5984 8.47234 13.013L18.6727 3.73994C19.0813 3.36843 19.1114 2.73598 18.7399 2.32733C18.3684 1.91867 17.736 1.88855 17.3273 2.26006L7.07549 11.5799C6.47041 11.2119 5.75995 11 5 11ZM3 15C3 13.8954 3.89543 13 5 13C6.10457 13 7 13.8954 7 15C7 16.1046 6.10457 17 5 17C3.89543 17 3 16.1046 3 15Z"
      />
      <path d="M18.6727 16.2601C19.0813 16.6316 19.1114 17.264 18.7399 17.6727C18.3684 18.0813 17.736 18.1114 17.3273 17.7399L11.8273 12.7399C11.4187 12.3684 11.3886 11.736 11.7601 11.3273C12.1316 10.9187 12.764 10.8886 13.1727 11.2601L18.6727 16.2601Z" />
    </svg>
  );
};

export default CutIcon;
