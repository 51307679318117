import { BASE_PATH } from 'shared/constants/constants';
import { IGetStageDto, IPostStageDto, IPutStageDto, IStageFilterDto } from 'shared/interfaces';
import HTTPService from './HttpService';

class StageService extends HTTPService {
  constructor() {
    super(BASE_PATH.STAGES);
  }

  public getAllStages(): Promise<IGetStageDto[]> {
    return this.GET<IGetStageDto[]>('');
  }

  public getStages(params: IStageFilterDto): Promise<IGetStageDto[]> {
    return this.GET<IGetStageDto[]>('', { params });
  }

  public getStage(id: number): Promise<IGetStageDto[]> {
    return this.GET('', { params: { id } });
  }

  public postStage(body: IPostStageDto): Promise<number> {
    return this.POST('', body);
  }

  public putStage(id: number, body: IPutStageDto): Promise<IGetStageDto> {
    return this.PUT(`${id}`, body);
  }

  public deleteStage(id: number): Promise<void> {
    return this.DELETE(`${id}`);
  }
}

export default new StageService();
