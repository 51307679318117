import React, { MouseEvent, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator } from 'stores';
import ExpandIcon from 'components/Icons/ExpandIcon';
import FilterIcon from 'components/Icons/FilterIcon';
import PlusIcon from 'components/Icons/PlusIcon';
import IconButtonNew from 'components/UI/IconButtonNew';
import PopupMenu from 'components/UI/PopupMenu';
import {
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableHead,
  IconButton,
  Collapse,
  Checkbox,
  Box,
  Stack,
  Divider,
  Chip,
  Typography,
  CircularProgress,
} from '@mui/material';
import { SpecificationApprovalStatus, SpecificationApprovalStatusNames } from 'shared/enums';
import { Routes } from 'shared/enums/Routes';
import { ISectionParametersDto, ITableColumn } from 'shared/interfaces';
import { CollapseTableModel } from 'shared/models';

import s from './TableViewCollapse.module.scss';

interface ITableViewCollapseProps {
  data: CollapseTableModel<any>[];
  isHideHeader?: boolean;
  isCheckedOnClickLine?: boolean;
  isCollapse?: boolean;
  currentRow?: number | null;
  columns: ITableColumn[];
  isHideColorGroup?: boolean;
  isParamSection?: boolean;
  onDoubleClick?: (row: any, column: any, mainRow: any) => void;
  onClick?: (row: any, column: any, rowIndex: number, e?: MouseEvent<HTMLTableCellElement>) => void;
  onChecked?: (checked: number[] | ISectionParametersDto[], row?: any, e?: any, parentRow?: any) => void;
  onAddGroup?: (row: any) => void;
  height: string;
  isFilter?: boolean;
  isLoading?: boolean;
  scrollRowId?: number;
  isExpandToolTip?: boolean;
  isHideHeaderCheckbox?: boolean;
}

interface IRowIndex {
  mainRow: number;
  nestedRow: number;
}

const TableViewCollapse = (props: ITableViewCollapseProps) => {
  const {
    columns,
    data,
    currentRow,
    height,
    isHideHeader,
    isParamSection = false,
    isCheckedOnClickLine,
    isCollapse,
    onDoubleClick,
    onClick,
    onChecked,
    onAddGroup,
    isFilter,
    isLoading,
    scrollRowId,
    isExpandToolTip = false,
    isHideHeaderCheckbox = false,
  } = props;

  const [selected, setSelected] = useState<any>();
  const [hoveredRow, setHoveredRow] = useState<IRowIndex>({ mainRow: -1, nestedRow: -1 });

  useEffect(() => {
    if (data.length > 0) {
      setSelected(currentRow ? currentRow : null);
    }
  }, [data, currentRow]);

  useEffect(() => {
    if (scrollRowId) {
      const rowElement = document.getElementById(String(scrollRowId));
      rowElement && rowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [scrollRowId]);

  const getAllCheckedRows = (): number[] => {
    const parameterIds: number[] = [];

    data.forEach((group) => {
      if (group.groupItems.length !== 0) {
        group.groupItems.forEach((parameter: any) => {
          if (parameter.checked) parameterIds.push(parameter.id);
        });
      }
    });

    return parameterIds;
  };

  const nestedRow = (row: any, columns: ITableColumn[], mainRowEl: any, mainRowIndex: number, rowIndex: number): JSX.Element => {
    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
      row.setChecked(!row.checked);

      if (mainRowEl.groupItems.every((el: any) => el.checked === true)) mainRowEl.setChecked(true);
      else if (mainRowEl.groupItems.every((el: any) => el.checked === false)) mainRowEl.setChecked(false);

      if (onChecked) onChecked(getAllCheckedRows(), row, event, mainRowEl);
    };

    return (
      <>
        {columns.map((column, idx) => {
          const handleDoubleClick = (e: MouseEvent<Element>) => {
            if (!onDoubleClick) return;

            onDoubleClick(row, column, mainRow);
          };

          const handleClick = (e: MouseEvent<HTMLTableCellElement>) => {
            if (isCheckedOnClickLine) {
              row.setChecked(!row.checked);
              onChecked && onChecked(getAllCheckedRows(), row, e, mainRowEl);
            }

            if (!onClick) return;

            /*  TODO: разобраться как работает всплытие события e, что является e.target? */

            onClick(row, column, parseInt(`${mainRowIndex}${rowIndex}`, 0), e);
            setSelected(row.id);
          };

          const hidden = column.showOnHover && (hoveredRow.mainRow !== mainRowIndex || hoveredRow.nestedRow !== rowIndex);

          if (onChecked && column.key === 'checkbox')
            return (
              <TableCell
                padding="checkbox"
                style={{ maxWidth: column.maxWidth, minWidth: column.minWidth, width: column.width, padding: '4px 4px 4px 8px' }}>
                <Checkbox checked={row.checked} onChange={handleCheckbox} />
              </TableCell>
            );
          else if (
            (column.key === 'description' || column.key === 'documents') &&
            row.dataType === 'List' &&
            location.pathname !== Routes.PARAM &&
            row.parameterListValues.length !== 0
          ) {
            return;
          } else
            return (
              <TableCell
                colSpan={column.colSpan ? column.colSpan(row) : 1}
                onDoubleClick={handleDoubleClick}
                onClick={handleClick}
                variant="body"
                key={column.key}
                align={column.align}
                style={{
                  maxWidth: column.key === 'value' && row.dataType === 'List' && location.pathname !== Routes.PARAM ? 'unset' : column.maxWidth,
                  width: column.key === 'value' && row.dataType === 'List' && location.pathname !== Routes.PARAM ? 'unset' : column.width,
                  padding:
                    (column.padding && column.padding(row)) ??
                    `4px 4px 4px ${columns.find((f) => f.key === 'checkbox') ? (idx === 1 ? '30px' : '4px') : idx === 0 ? '30px' : '4px'}`,
                  height: 1,
                }}>
                <Box sx={{ visibility: hidden ? 'hidden' : 'visible' }}>
                  {column.cell ? column.cell(row, parseInt(`${mainRowIndex}${rowIndex}`, 0)) : row[column.key]}
                </Box>
              </TableCell>
            );
        })}
      </>
    );
  };

  const renderHeader = (columns: ITableColumn[]): JSX.Element => {
    const handleAllCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
      data.forEach((data: any) => {
        data.setChecked(event.target.checked);
        if (data.groupItems.length !== 0) {
          data.groupItems.forEach((groupItem: any) => {
            groupItem.setChecked(event.target.checked);
          });
        }
      });

      if (onChecked) onChecked(getAllCheckedRows(), undefined, event);
    };

    let newColumns: ITableColumn[] = [];
    if ((data as any).every((elem: any) => elem.groupItems.length === 0)) {
      newColumns = columns.filter((col) => col.key !== 'checkbox');
    } else newColumns = columns;

    //общее кол-во параметров во всех группах в исходном массиве
    const countParamInData = (): number => {
      let count = 0;
      data.forEach((group) => {
        count += group.groupItems.length;
      });

      return count;
    };

    //общее кол-во чЁкнутых элементов
    const countParamInChecked = (): number => {
      let count = 0;

      data.forEach((group) => {
        group.groupItems.forEach((parameter: any) => {
          if (parameter.checked) count += 1;
        });
      });

      return count;
    };

    return (
      <>
        {newColumns.map((column, index) => {
          const getPadding = (): string | undefined => {
            if (column.filterOptions && column.filterOptions().length > 0) {
              if (column.align === 'center') return '38px';
              else return '8px';
            } else return undefined;
          };

          if (onChecked && column.key === 'checkbox')
            return (
              <TableCell
                sx={{ p: 0 }}
                style={{
                  maxWidth: column.maxWidth,
                  minWidth: column.minWidth,
                  width: column.width,
                }}>
                {!isHideHeaderCheckbox && (
                  <Checkbox
                    indeterminate={countParamInChecked() > 0 && countParamInChecked() < countParamInData()}
                    checked={countParamInChecked() > 0 && countParamInChecked() === countParamInData()}
                    onChange={handleAllCheck}
                  />
                )}
              </TableCell>
            );
          else
            return (
              <TableCell
                component="th"
                sx={{ py: 0, px: 1, height: 40 }}
                key={column.key}
                align={column.align}
                style={{
                  fontWeight: 500,
                  maxWidth: column.maxWidth,
                  width: column.width,
                }}>
                <Stack
                  position="relative"
                  direction="row"
                  alignItems="center"
                  px={getPadding()}
                  justifyContent={column.align === 'right' ? 'flex-end' : column.align === 'center' ? 'center' : 'space-between'}>
                  {column.label}
                  {column.label !== '' && newColumns.length - 1 !== index && (
                    <Stack
                      position="absolute"
                      alignItems="center"
                      direction="row"
                      sx={{
                        right: 0,
                        svg: {
                          fill: column.hasFilters ? '#7B68EE' : 'unset',
                        },
                      }}>
                      {column.filterOptions && column.filterOptions().length > 0 && (
                        <PopupMenu
                          key={column.key}
                          button={
                            <IconButtonNew>
                              <FilterIcon />
                            </IconButtonNew>
                          }
                          menuItems={column.filterOptions()}
                        />
                      )}

                      <Divider sx={{ ml: 2, borderRightWidth: 2, height: 14 }} orientation="vertical" />
                    </Stack>
                  )}
                </Stack>
              </TableCell>
            );
        })}
      </>
    );
  };

  const mainRow = (row: any, mainRowIndex: number): JSX.Element => {
    const findEl = (data as any).find((f: any) => (f as any).id === row.id)?.groupItems.filter((f: any) => f.checked === true);

    const handleAllIntoGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        row.setChecked(true);
        row.groupItems.map((groupItem: any) => {
          groupItem.setChecked(true);
        });

        if (onChecked) onChecked(getAllCheckedRows(), row, event);
        return;
      }

      //сброс в текущей группе
      row.setChecked(false);
      row.groupItems.map((groupItem: any) => {
        groupItem.setChecked(false);
      });

      if (onChecked) onChecked(getAllCheckedRows(), row, event);
    };

    const onMainRowClick = () => {
      if (isCollapse !== false) row.setCollapse(!row.collapse);
    };

    return (
      <>
        <TableRow id={String(row.id)} onClick={onMainRowClick} sx={{ backgroundColor: '#f2f2f9' }} className={s.tableRowWrapper} key={row.id}>
          <TableCell component="th" colSpan={columns.length}>
            {row.groupItems.length > 0 ? (
              <Stack flexDirection="row" alignItems="center">
                {onChecked && !(data as any).every((elem: any) => elem.groupItems.length === 0) && (
                  <>
                    {row.groupItems.length > 0 && (
                      <Checkbox
                        sx={{ p: '0 0 0 8px' }}
                        indeterminate={findEl!.length > 0 && findEl!.length < row.groupItems.length}
                        checked={row.checked}
                        onChange={handleAllIntoGroup}
                        onClick={(e) => e.stopPropagation()}
                      />
                    )}
                  </>
                )}
                {isCollapse !== false && (
                  <IconButtonNew
                    tooltip={
                      isExpandToolTip ? (row.collapse ? Translator.translate('actions.collapse') : Translator.translate('actions.expand')) : ''
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      row.setCollapse(!row.collapse);
                    }}
                    sx={{ padding: '7px', transform: `rotate(${row.collapse ? 0 : 270}deg)` }}>
                    {<ExpandIcon />}
                  </IconButtonNew>
                )}

                <Box sx={{ paddingLeft: isCollapse === false ? '8px' : '' }}>{row.group}</Box>

                {/*   TODO: добавить возможность скидывать кастомный вид обобщающей строки  */}
                {/*<Chip variant="outlined" size="small" color={paint(row.subName)} label={SpecificationApprovalStatusNames.get(row.subName)} />*/}

                {onAddGroup && (
                  <Box sx={{ ml: 'auto', mr: 2 }}>
                    <IconButtonNew tooltip={Translator.translate('actions.add')} onClick={() => onAddGroup(row)}>
                      <PlusIcon />
                    </IconButtonNew>
                  </Box>
                )}
              </Stack>
            ) : (
              <Stack flexDirection="row" alignItems="center">
                <Box sx={{ p: '4px 4px 4px 30px' }}>{row.group}</Box>

                {onAddGroup && (
                  <Box sx={{ ml: 'auto', mr: 2 }}>
                    <IconButtonNew tooltip={Translator.translate('actions.add')} onClick={() => onAddGroup(row)}>
                      <PlusIcon />
                    </IconButtonNew>
                  </Box>
                )}
              </Stack>
            )}
          </TableCell>
        </TableRow>

        {row.groupItems.length > 0 && (
          <TableRow className={s.tableRowContent}>
            <TableCell style={{ padding: 0, borderBottom: 'none', fontWeight: 500 }} colSpan={columns.length}>
              <Collapse in={row.collapse} timeout="auto" unmountOnExit>
                <Table size="small">
                  <TableBody>
                    {row.groupItems.map((item: any, index: number) => (
                      <TableRow
                        id={String(item.id)}
                        key={item.id}
                        hover={true}
                        onMouseEnter={() => setHoveredRow({ mainRow: mainRowIndex, nestedRow: index })}
                        onMouseLeave={() => setHoveredRow({ mainRow: -1, nestedRow: -1 })}
                        selected={item.id === selected}
                        className={s.tableRow}>
                        {nestedRow(item, columns, row, mainRowIndex, index)}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  const renderRows = () => {
    if (data.length === 0 && !isLoading) {
      return (
        <TableRow>
          <TableCell sx={{ borderBottom: 'none' }} colSpan={columns.length}>
            <Stack alignItems="center">
              <img
                style={{ alignSelf: 'center' }}
                src={isFilter ? `${process.env.PUBLIC_URL}/img/not_found_data.svg` : `${process.env.PUBLIC_URL}/img/empty_data.svg`}
                alt=""
              />
              <Typography variant="subtitle1" color="text.disabled">
                {isFilter ? Translator.translate('system.notFoundData') : Translator.translate('system.emptyData')}
              </Typography>
            </Stack>
          </TableCell>
        </TableRow>
      );
    } else if (isLoading) {
      return (
        <TableRow>
          <TableCell sx={{ borderBottom: 'none' }} colSpan={columns.length}>
            <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          </TableCell>
        </TableRow>
      );
    } else {
      return data.map((row: any, index: any) => mainRow(row, index));
    }
  };

  return (
    <TableContainer sx={{ height }}>
      <Table size="small" stickyHeader>
        {!isHideHeader && (
          <TableHead
            className={s.head}
            sx={(theme) => ({
              height: '10px',
              '.MuiTableCell-head': {
                bgcolor: theme.palette.common.white,
                color: 'text.secondary',
              },
            })}>
            <TableRow>{renderHeader(columns)}</TableRow>
          </TableHead>
        )}

        <TableBody
          sx={{
            '.MuiTableRow-root': {
              cursor: 'pointer',
              border: 'none',
            },
          }}>
          {renderRows()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default observer(TableViewCollapse);
