import React from 'react';

const SortingColumnAscIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.70711 2.29289C8.31658 1.90237 7.68342 1.90237 7.29289 2.29289L2.29289 7.29289C1.90237 7.68342 1.90237 8.31658 2.29289 8.70711C2.68342 9.09763 3.31658 9.09763 3.70711 8.70711L7 5.41421V13C7 13.5523 7.44772 14 8 14C8.55228 14 9 13.5523 9 13V5.41421L12.2929 8.70711C12.6834 9.09763 13.3166 9.09763 13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289L8.70711 2.29289Z" />
    </svg>
  );
};

export default SortingColumnAscIcon;
