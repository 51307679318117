import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, measurementUnitStore, toastStore } from 'stores';
import { Select, Input, DialogWrapper } from 'components';
import { CircularProgress, Box, SelectChangeEvent } from '@mui/material';

const MeasurementUnitDialog: React.FC = () => {
  const { selectedMeasurementUnit, isLoading, isDialogOpen } = measurementUnitStore;

  useEffect(() => {
    return () => {
      selectedMeasurementUnit.clear();
    };
  }, []);

  const dialogTitle = selectedMeasurementUnit.id
    ? Translator.translate('catalogs.units.editTitleDialog')
    : Translator.translate('catalogs.units.addTitleDialog');

  const onSubmit = () => {
    if (selectedMeasurementUnit.name === '' || selectedMeasurementUnit.fullName === '') {
      toastStore.showWarning(Translator.translate('catalogs.validationMessage'));
      return;
    }

    if (!selectedMeasurementUnit.id) {
      measurementUnitStore.createMeasurementUnit().then(() => onClose());
    } else {
      measurementUnitStore.updateMeasurementUnit().then(() => onClose());
    }
  };

  const onChangeName = (value: string) => {
    selectedMeasurementUnit.setName(value);
  };

  const onChangeOKEI = (value: string) => {
    selectedMeasurementUnit.setOKEI(value);
  };

  const onChangeFullName = (value: string) => {
    selectedMeasurementUnit.setFullName(value);
  };

  const onChangeType = (e: SelectChangeEvent<any>) => {
    selectedMeasurementUnit.setType(e.target.value);
  };

  const onClose = () => {
    measurementUnitStore.setDialogOpen(false);
    selectedMeasurementUnit.clear();
  };

  return (
    //TODO: Add formik
    <DialogWrapper title={dialogTitle} maxWidth="md" open={isDialogOpen} onClose={onClose} onSubmit={onSubmit} onCancel={onClose}>
      <>
        {isLoading ? (
          <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Input
              label={Translator.translate('catalogs.units.columns.name')}
              required
              value={selectedMeasurementUnit.name}
              onChange={onChangeName}
            />

            <Input
              label={Translator.translate('catalogs.units.columns.fullName')}
              required
              value={selectedMeasurementUnit.fullName}
              onChange={onChangeFullName}
            />

            <Input label={Translator.translate('catalogs.units.columns.okei')} value={selectedMeasurementUnit.okei} onChange={onChangeOKEI} />

            <Select
              //label="Выберите группу единицы измерения*"
              label={Translator.translate('catalogs.units.columns.type')}
              hideNotSelectItem
              required
              value={measurementUnitStore.selectedMeasurementUnit.type}
              options={measurementUnitStore.measurementUnitTypeForOptions}
              onChange={onChangeType}
              size="medium"
            />
          </>
        )}
      </>
    </DialogWrapper>
  );
};

export default observer(MeasurementUnitDialog);
