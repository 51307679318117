import React, { useRef, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { Translator, sectionSetCollectionStore, specificationStore } from 'stores';
import { DialogWrapper, Select } from 'components';
import { SelectChangeEvent, Stack, Typography } from '@mui/material';
import { ISpecificationCollectionForm } from 'shared/interfaces';
import { IdNameDto } from 'shared/interfaces/Dto/BaseDto';
import { SectionSetCollectionModel } from 'shared/models';

const CollectionDialog: React.FC = () => {
  const formRef = useRef<FormikProps<ISpecificationCollectionForm>>(null);
  const [isLoading, setLoading] = useState(false);
  const dialogTitle = Translator.translate('specifications.collectionDialog.title');

  useEffect(() => {
    setLoading(true);
    sectionSetCollectionStore
      .getSectionSetCollections()
      .then(() => {
        if (sectionSetCollectionStore.sectionSetCollections.length > 0) {
          sectionSetCollectionStore.selectedSectionSetCollection = new SectionSetCollectionModel(sectionSetCollectionStore.sectionSetCollections[0]);
        }
      })
      .finally(() => setLoading(false));

    return () => {
      specificationStore.selectedSpecification.clear();
    };
  }, []);

  const onSubmit = (values: ISpecificationCollectionForm) => {
    specificationStore.selectedSpecification.updateSpecificationCollectionForm(values);

    specificationStore.createSpecificationCollection().then(() => onClose());
  };

  const onClose = () => {
    specificationStore.setDialogCollectionOpen(false);
  };

  const collectionAddForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        sectionSetCollection: sectionSetCollectionStore.selectedSectionSetCollection.id,

        sectionSetCollectionOpt: sectionSetCollectionStore.sectionSetCollectionOptions,
      }}
      validationSchema={Yup.object().shape({
        sectionSetCollection: Yup.number().required(Translator.translate('validationMessage.required')).nullable(),
      })}>
      {({ values, errors, touched }) => {
        return (
          <Stack gap={6} height="320px">
            <Select
              name="sectionSetCollection"
              label={Translator.translate('specifications.collectionDialog.dialogFormFields.sectionSetCollection')}
              hideNotSelectItem
              required
              error={Boolean(touched.sectionSetCollection && errors.sectionSetCollection)}
              helperText={(touched.sectionSetCollection as any) && (errors.sectionSetCollection as any)}
              value={values.sectionSetCollection}
              options={values.sectionSetCollectionOpt}
              onChange={(e: SelectChangeEvent<any>) => {
                sectionSetCollectionStore.getSectionSetCollection(e.target.value as number);
              }}
              size="medium"
            />

            {!specificationStore.selectedSpecification.id && sectionSetCollectionStore.selectedSectionSetCollection.sectionSets.length > 0 && (
              <>
                <Typography color="text.primary">{Translator.translate('specifications.collectionDialog.setContents')}</Typography>

                <Stack gap={2} height="240px" overflow="auto">
                  {sectionSetCollectionStore.selectedSectionSetCollection.sectionSets.map((data: IdNameDto) => {
                    return (
                      <Typography key={data.id} variant="body2" color="text.secondary">
                        {data.name}
                      </Typography>
                    );
                  })}
                </Stack>
              </>
            )}
          </Stack>
        );
      }}
    </Formik>
  );

  return (
    <DialogWrapper
      title={dialogTitle}
      maxWidth="md"
      open={specificationStore.isDialogCollectionOpen}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}
      titleSubmit={Translator.translate('actions.create')}>
      {isLoading ? (
        <Stack height={56} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        collectionAddForm
      )}
    </DialogWrapper>
  );
};

export default observer(CollectionDialog);
