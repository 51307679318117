import { makeAutoObservable } from 'mobx';
import { IGetSectionSetCollectionDto, IPutSectionSetCollectionDto } from 'shared/interfaces';
import { ISectionSetCollectionForm } from 'shared/interfaces/app/ISectionSetCollection';
import { IdNameDto } from 'shared/interfaces/Dto/BaseDto';

class SectionSetCollectionModel {
  constructor(dto?: IGetSectionSetCollectionDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.sectionSets = dto.sectionSets;
  }

  public id: number | null = null;

  public name = '';

  public sectionSets: IdNameDto[] = [];

  public updateSectionSetCollectionDialogForm(values: ISectionSetCollectionForm) {
    this.name = values.name;
  }

  public get putDto(): IPutSectionSetCollectionDto | null {
    if (!this.id || this.name === '') return null;

    return {
      id: this.id,
      name: this.name,
    };
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.sectionSets = [];
  }
}

export default SectionSetCollectionModel;
