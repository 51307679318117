import { ChangeEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, conditionStore, parameterListValueStore, parameterStore } from 'stores';
import { OperationRows, TableView, DialogWrapper, Toggler } from 'components';
import { DialogContent, DialogTitle, Box, CircularProgress, DialogActions, Button } from '@mui/material';
import { ConditionParamMode, ConditionParamModeNames } from 'shared/enums';
import { IAutocompleteParameter, ITableColumn } from 'shared/interfaces';
import ConditionListModel from 'shared/models/ConditionListModel';

const RuleEditDialog: React.FC = () => {
  const [selectedMode, setSelectedMode] = useState(ConditionParamMode.DependsIf);

  const conditionParamMode = [
    {
      value: ConditionParamMode.DependsIf,
      label: Translator.translate(ConditionParamModeNames.get(ConditionParamMode.DependsIf)),
    },
    {
      value: ConditionParamMode.DependsThen,
      label: Translator.translate(ConditionParamModeNames.get(ConditionParamMode.DependsThen)),
    },
  ];

  const columns: ITableColumn[] = [
    {
      key: 'parameter',
      label: Translator.translate('params.parameter.ruleEditDialog.columns.parameter'),
      width: '400px',
      align: 'left',
    },
    {
      key: 'value',
      label: Translator.translate('params.parameter.ruleEditDialog.columns.value'),
      width: '200px',
      align: 'left',
    },
  ];

  useEffect(() => {
    conditionStore.getConditionsList(parameterListValueStore.selectedParameterListValue.id!).then(() => {
      conditionStore.conditionsIf.forEach((el) => {
        parameterListValueStore
          .getParameterListValues(el.parameterThen.id!, 'condition')
          .then(() => el.setSelectValueOptions(parameterListValueStore.parameterListValuesRules.map((_) => _.getSelectOption()!)));
      });
    });
  }, []);

  const onSubmit = async () => {
    //только удаленные
    const deletedConditions = conditionStore.conditionListDto
      .filter((f) => !conditionStore.conditionsIf.find((condinion) => condinion.id === f.id))
      .map((value) => value.id);

    const createConditions = conditionStore.conditionsIf.map((_) => _.parameterListValueThen.id!);

    if (createConditions.length > 0) {
      await conditionStore.createCondition(createConditions);
    }

    if (deletedConditions.length > 0) {
      await conditionStore.removeCondition(deletedConditions);
    }

    await parameterListValueStore.getParameterListValues(parameterStore.selectedParameterTable.id!);
    onClose();
  };

  const onClose = () => {
    parameterListValueStore.setRuleDialogOpen(false);
  };

  const onAddRow = () => {
    conditionStore.addNewConditionRow();
  };

  const onChangeParameter = (value: IAutocompleteParameter, condition: ConditionListModel) => {
    condition.setParameterThen(value);
    parameterListValueStore
      .getParameterListValues(value.id, 'condition')
      .then(() => condition.setSelectValueOptions(parameterListValueStore.parameterListValuesRules.map((_) => _.getSelectOption()!)));
  };

  const onChangeValues = (e: ChangeEvent<any>, condition: ConditionListModel) => {
    condition.setParameterListValueThen(e.target.value);
  };

  const onDeleteRow = (condition: ConditionListModel) => {
    condition.id && conditionStore.deleteConditionRow(condition.id);
  };

  const onChangeConditionParamMode = async (value: ConditionParamMode) => {
    setSelectedMode(value);
    if (value === ConditionParamMode.DependsThen) conditionStore.getConditionsList(undefined, parameterListValueStore.selectedParameterListValue.id!);
  };

  const isEmpty = (): boolean => {
    if (conditionStore.conditionsIf.length > 0) {
      const parameterListValueThenIds = conditionStore.conditionsIf.map((value) => value.parameterListValueThen.id);
      return parameterListValueThenIds.includes(null) && parameterListValueThenIds.length > 0;
    }

    return false;
  };

  return (
    <DialogWrapper
      title={`${parameterStore.selectedParameterTable.name}${
        parameterStore.selectedParameterTable.measurementUnitName !== '' ? ', ' + parameterStore.selectedParameterTable.measurementUnitName : ''
      } = ${parameterListValueStore.selectedParameterListValue.value}`}
      open={parameterListValueStore.isRuleDialogOpen}
      hideActions={selectedMode !== ConditionParamMode.DependsIf}
      onClose={onClose}
      onCancel={onClose}
      disableSubmit={isEmpty()}
      onSubmit={onSubmit}
      maxWidth="xl">
      <Toggler
        fullWidth
        value={selectedMode}
        tabItems={conditionParamMode}
        onChangeTab={(value: string | number) => onChangeConditionParamMode(value as ConditionParamMode)}
      />

      {conditionStore.isLoading ? (
        <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ maxHeight: `${document.documentElement.clientHeight - 240}px` }}>
          {selectedMode === ConditionParamMode.DependsIf ? (
            <OperationRows
              operationRows={conditionStore.conditionsIf}
              onDeleteRow={onDeleteRow}
              onChangeParameter={onChangeParameter}
              onChangeValues={onChangeValues}
              onAddRow={onAddRow}
            />
          ) : (
            <TableView data={conditionStore.conditionsForTable} columns={columns} />
          )}
        </Box>
      )}
    </DialogWrapper>
  );
};

export default observer(RuleEditDialog);
