import { makeAutoObservable } from 'mobx';
import { ApprovalUserRole, PartialApprovalIteration, PartialApprovalStatus } from 'shared/enums';
import {
  IGetPartialApprovalDto,
  IGetApprovalUserDto,
  IPostPartialApprovalDto,
  IPutInfBlockPartialApprovalDto,
  IPutPartialApprovalDto,
  IInformationBlockCreateDto,
} from 'shared/interfaces';
import { IdNameDto } from 'shared/interfaces/Dto/BaseDto';
import ApprovalUserModel from './ApprovalUser';

class PartialApprovalModel {
  constructor(dto?: IGetPartialApprovalDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.status = dto.status;
    this.iteration = dto.iteration;
    this.informationBlock = dto.informationBlock;

    dto.users.forEach((userDto: IGetApprovalUserDto) => {
      switch (userDto.role) {
        case ApprovalUserRole.Examiner:
          this.examiners.push(new ApprovalUserModel(userDto));
          this.examinersSnapshot.push(userDto.id);
          break;
        case ApprovalUserRole.Observer:
          this.observers.push(new ApprovalUserModel(userDto));
          this.observersSnapshot.push(userDto.id);
          break;
        case ApprovalUserRole.Executor:
          this.executor = new ApprovalUserModel(userDto);
          break;
      }
    });
  }

  public id: number | null = null;

  public name = '';

  public status = PartialApprovalStatus.Draft;

  public iteration = PartialApprovalIteration.Execution;

  public informationBlock: IdNameDto = {
    id: null,
    name: '',
  };

  public examiners: ApprovalUserModel[] = [];

  public examinersSnapshot: number[] = [];

  public observers: ApprovalUserModel[] = [];

  public observersSnapshot: number[] = [];

  public executor = new ApprovalUserModel();

  public userRoles: ApprovalUserRole[] = [];

  public myIterations: PartialApprovalIteration[] = [];

  public postDto(approvalId: number): IPostPartialApprovalDto | null {
    if (!this.informationBlock?.id || this.name === '') return null;

    return {
      name: this.name,
      approvalId,
      informationBlockId: this.informationBlock?.id,
    };
  }

  public get putDto(): IPutPartialApprovalDto {
    return {
      name: this.name,
    };
  }

  public putInfBlockDto(parameterGroupConfigurations?: IInformationBlockCreateDto[]): IPutInfBlockPartialApprovalDto | null {
    if (!parameterGroupConfigurations && !this.informationBlock?.id) return null;

    return {
      informationBlockId: !parameterGroupConfigurations ? this.informationBlock?.id : null,
      parameterGroupConfigurations,
    };
  }

  public setUserRoles(userRoles: ApprovalUserRole[]) {
    this.userRoles = userRoles;
  }

  public setMyIterations(userRole: ApprovalUserRole) {
    switch (userRole) {
      case ApprovalUserRole.Examiner:
        this.myIterations.push(PartialApprovalIteration.Examination);
        this.myIterations.push(PartialApprovalIteration.Recheck);
        break;

      case ApprovalUserRole.Executor:
        this.myIterations.push(PartialApprovalIteration.Execution);
        break;

      case ApprovalUserRole.Approver:
        this.myIterations.push(PartialApprovalIteration.Approval);
        break;

      default:
        break;
    }
  }

  public setExaminersSnapshot(id: number) {
    this.examinersSnapshot.push(id);
  }

  public setObserverSnapshot(id: number) {
    this.observersSnapshot.push(id);
  }

  public setId(id: number) {
    this.id = id;
  }

  public setName(name: string) {
    if (this.name !== name) this.name = name;
  }

  public setInformationBlockId(id: number) {
    this.informationBlock.id = id;
  }

  public addExaminer() {
    const user = new ApprovalUserModel();
    user.setRole(ApprovalUserRole.Examiner);
    user.setId(Math.max(...this.examiners.map((it) => it.id ?? 0), 0) + 1);
    user.setExpiration(new Date());
    this.examiners.push(user);
  }

  public deleteExaminer(index: number) {
    this.examiners = [...this.examiners.slice(0, index), ...this.examiners.slice(index + 1)];
  }

  public addObserver() {
    const user = new ApprovalUserModel();
    user.setRole(ApprovalUserRole.Observer);
    user.setId(Math.max(...this.observers.map((it) => it.id ?? 0), 0) + 1);
    user.setExpiration(new Date());
    this.observers.push(user);
  }

  public deleteObserver(index: number) {
    this.observers = [...this.observers.slice(0, index), ...this.observers.slice(index + 1)];
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.status = PartialApprovalStatus.Draft;
    this.iteration = PartialApprovalIteration.Execution;
    this.informationBlock = {
      id: null,
      name: '',
    };
    this.executor = new ApprovalUserModel();
    this.observers = [];
    this.examiners = [];
  }
}

export default PartialApprovalModel;
