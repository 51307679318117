import { makeAutoObservable } from 'mobx';
import { classStore, definitionStore, itemStore } from 'stores';
import { ObjectLayer } from 'shared/enums';
import { IClassItemDto, ICreateItemCommandDto, IPutItemDto } from 'shared/interfaces';

class ItemModel {
  constructor(dto?: any) {
    makeAutoObservable(this, undefined, { autoBind: true });
    if (!dto) return;
    this.key = dto.key;
    this.title = dto.title;
    this.children = dto.children;

    this.id = dto.id;
    this.parentId = dto.parentId;
    this.name = dto.name;
    this.isMaster = dto.isMaster;
    this.hasComments = dto.hasComments;
    this.class = dto.class;
  }

  public key = '';

  public title = '';

  public children = [];

  public id: number | null = null;

  public name = '';

  public parentId: number | null = null;

  public isMaster = false;

  public hasComments = false;

  public selected = false;

  public class: IClassItemDto | null = null;

  public childClassId: number | null = null;

  public setName(className: string) {
    this.name = className;
  }

  public setChildClassId(childClassId: number) {
    this.childClassId = childClassId;
  }

  public setSelected(isSelect: boolean) {
    this.selected = isSelect;
  }

  public setMaster(isMaster: boolean) {
    this.isMaster = isMaster;
  }

  public setCurrentClassId(id: number) {
    this.class!.id = id;
  }

  public initSectionParametersCreateForm(classId: number, name: string) {
    this.class!.id = classId;
    this.name = name;
  }

  public get putDto(): IPutItemDto | null {
    if (this.id === null) return null;

    return {
      id: this.id,
      name: this.name,
      parentId: this.parentId,
      isMaster: this.isMaster,
    };
  }

  public postDto(): ICreateItemCommandDto | null {
    let classId: number | null = null;
    let parentId: number | null = null;
    if (
      classStore.classFilters.layerId === ObjectLayer.Main ||
      (classStore.classFilters.layerId === ObjectLayer.Additional && !itemStore.selectItem.class?.layerId)
    ) {
      classId = this.class?.id!;
      parentId = this.parentId;
    } else {
      classId = Number(classStore.treeObject.selected?.id);
      parentId = this.id;
    }

    if (!definitionStore.currentDefinitionId) return null;

    return {
      name: this.name,
      isMaster: this.isMaster,
      classId: classId,
      parentId: parentId,
      definitionId: definitionStore.currentDefinitionId,
    };
  }

  public clearModel() {
    this.id = null;
    this.parentId = null;
    this.name = '';
    this.isMaster = false;
    this.hasComments = false;
    this.class = null;
  }
}

export default ItemModel;
