import { makeAutoObservable } from 'mobx';
import { parameterStore } from 'stores';
import { DataType, DocumentSettingType } from 'shared/enums';
import { IGetDocumentDto, IParameterByIdDto, IPutParameterDto } from 'shared/interfaces';

class DocumentModel {
  constructor(dto?: IParameterByIdDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.orderNum = dto.orderNum;
    this.name = dto.name;
    this.fullName = dto.fullName;
    this.description = dto.description;
    this.dataType = dto.dataType;
    this.isArchived = dto.isArchived;
    this.groupId = dto.groupId;
    this.measurementUnitId = dto.measurementUnit === null ? null : dto.measurementUnit.id;
    this.documentSettings = dto.documentSettings;
    this.documents = dto.documents;
  }

  public id: number | null = null;

  public name = '';

  public fullName = '';

  public groupId: number | null = null;

  public documents: IGetDocumentDto[] = [];

  public orderNum = 0;

  public description: string | null = '';

  public dataType: DataType = DataType.String;

  public isArchived = false;

  public measurementUnitId: number | null = null;

  public documentSettings: DocumentSettingType[] = [];

  public get putDto(): IPutParameterDto | null {
    if (this.id === null || this.groupId === null) return null;

    const parameterDocuments = parameterStore.documentsByParameter.map((doc) => {
      return {
        documentId: doc.id!,
        documentPage: doc.documentPage,
      };
    });

    return {
      id: this.id,
      orderNum: this.orderNum,
      name: this.name,
      dataType: this.dataType,
      description: this.description,
      groupId: this.groupId,
      measurementUnitId: this.measurementUnitId,
      documentSettings: this.documentSettings,
      parameterDocuments: parameterDocuments,
    };
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.fullName = '';
    this.documents = [];
    this.orderNum = 0;
    this.dataType = DataType.String;
    this.description = '';
    this.isArchived = false;
    this.groupId = 0;
    this.measurementUnitId = null;
    this.documentSettings = [];
  }
}

export default DocumentModel;
