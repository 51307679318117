import React from 'react';

const DownloadIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.79998 2.4001C8.79998 1.95827 8.4418 1.6001 7.99998 1.6001C7.55815 1.6001 7.19998 1.95827 7.19998 2.4001V7.66873L5.36566 5.83441C5.05324 5.52199 4.54671 5.52199 4.23429 5.83441C3.92187 6.14683 3.92187 6.65336 4.23429 6.96578L7.43429 10.1658C7.74671 10.4782 8.25324 10.4782 8.56566 10.1658L11.7657 6.96578C12.0781 6.65336 12.0781 6.14683 11.7657 5.83441C11.4532 5.52199 10.9467 5.52199 10.6343 5.83441L8.79998 7.66873V2.4001Z" />
      <path d="M2.39998 9.6001C2.8418 9.6001 3.19998 9.95827 3.19998 10.4001V12.5334C3.19998 12.5609 3.21168 12.6173 3.2851 12.6803C3.36232 12.7465 3.49011 12.8001 3.64442 12.8001H12.3555C12.5098 12.8001 12.6376 12.7465 12.7149 12.6803C12.7883 12.6173 12.8 12.5609 12.8 12.5334V10.4001C12.8 9.95827 13.1581 9.6001 13.6 9.6001C14.0418 9.6001 14.4 9.95827 14.4 10.4001V12.5334C14.4 13.0718 14.1495 13.5579 13.7561 13.8951C13.3666 14.229 12.8613 14.4001 12.3555 14.4001H3.64442C3.13863 14.4001 2.63336 14.229 2.24383 13.8951C1.85049 13.5579 1.59998 13.0718 1.59998 12.5334V10.4001C1.59998 9.95827 1.95815 9.6001 2.39998 9.6001Z" />
    </svg>
  );
};

export default DownloadIcon;
