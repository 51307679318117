import { makeAutoObservable, runInAction } from 'mobx';
import { SpecificationsService } from 'services';
import { Translator, sectionsStore, toastStore } from 'stores';
import { NestedLevel } from 'shared/enums';
import { IGetSpecificationDto, ISectionParameter, ISelectOption, ISpecificationCollection, ISpecificationParamsDto } from 'shared/interfaces';
import { SpecificationFilesModel, SpecificationModel } from 'shared/models';

class SpecificationStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public isDialogOpen = false;

  public isDialogCollectionOpen = false;

  public currentSpecification: IGetSpecificationDto | null = null;

  public specificationFiles: SpecificationFilesModel[] = [];

  public specifications: IGetSpecificationDto[] = [];

  public specificationCollections: ISpecificationCollection[] = [];

  public hoveredRowIds: number[] = [];

  public specificationParameters: ISectionParameter[] = [];

  public selectedSpecification = new SpecificationModel();

  public specificationFilters: ISpecificationParamsDto = { definitionId: null, name: '' };

  public specificationAttachFile = new SpecificationFilesModel();

  public get specificationsForOptions(): ISelectOption[] {
    return this.specifications.map((item) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
  }

  public filteredSpecificationCollections() {
    const filteredData = this.specifications.filter(
      (specifications) =>
        specifications.name.toLowerCase().includes(this.specificationFilters.name?.toLocaleLowerCase()!) ||
        specifications.specificationCollection.name.toLowerCase().includes(this.specificationFilters.name?.toLocaleLowerCase()!)
    );

    this.specificationCollections = this.preparedSpecificationCollections(filteredData);
  }

  public preparedSpecificationCollections(specifications: IGetSpecificationDto[]): ISpecificationCollection[] {
    //grouping data by specificationCollectionId
    const hoveredIds: number[] = [];

    const groupCollectionMap = specifications.reduce((acc: Map<number, ISpecificationCollection>, cur) => {
      if (!acc.has(cur.specificationCollection.id)) {
        acc.set(cur.specificationCollection.id, {
          id: cur.specificationCollection.id,
          name: cur.specificationCollection.name,
          level: NestedLevel.First,
          children: [],
        });
      }

      hoveredIds.push(cur.id);

      acc.get(cur.specificationCollection.id)?.children.push({
        id: cur.id,
        name: cur.name,
        comment: cur.comment,
        status: cur.status,
        createdAt: cur.createdAt,
        sectionSet: cur.sectionSet,

        level: NestedLevel.Second,
        children: [],
      } as ISpecificationCollection);
      return acc;
    }, new Map());

    this.hoveredRowIds = hoveredIds;
    const data = Array.from(groupCollectionMap.values());

    return data;
  }

  public setDialogOpen(isOpen: boolean) {
    this.isDialogOpen = isOpen;
  }

  public setDialogCollectionOpen(isOpen: boolean) {
    this.isDialogCollectionOpen = isOpen;
  }

  public setSpecificationFilters(definitionId: number | null, searchPattern: string) {
    this.specificationFilters.definitionId = definitionId;
    this.specificationFilters.name = searchPattern;
  }

  public async getSpecifications(): Promise<void> {
    this.isLoading = true;

    try {
      const result = await SpecificationsService.getSpecifications(this.specificationFilters);
      if (!result) return;

      this.specifications = result;
      this.filteredSpecificationCollections();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Specifications`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getSpecificationById(id: number): Promise<void> {
    this.isLoading = true;

    try {
      const result = await SpecificationsService.getSpecification(id);

      if (!result) return;

      runInAction(() => {
        this.selectedSpecification = new SpecificationModel(result[0]);
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} Specification`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getCurrentSpecification(id: number): Promise<void> {
    this.isLoading = true;

    try {
      const result = await SpecificationsService.getSpecification(id);

      if (!result) return;

      this.currentSpecification = result[0];
      sectionsStore.setFilterSectionSetId(this.currentSpecification.sectionSet.id);
      sectionsStore.getSections();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} CurrentSpecification`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createSpecificationCollection(): Promise<void> {
    if (!this.selectedSpecification.postDto) return;

    this.isLoading = true;

    SpecificationsService.postSpecificationCollection(this.selectedSpecification.postDto)
      .then((result) => {
        this.getSpecifications();
      })
      .catch((e) => {
        toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} SpecificationCollection`);
      })
      .finally(() => (this.isLoading = false));
  }

  public async updateSpecification(): Promise<void> {
    if (!this.selectedSpecification.putDto || this.selectedSpecification.id === null) return;

    try {
      this.isLoading = true;
      await SpecificationsService.putSpecification(this.selectedSpecification.id, this.selectedSpecification.putDto);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} Specification`);
    } finally {
      this.isLoading = false;
    }
  }

  public async deleteSpecificationCollection(id: number): Promise<void> {
    try {
      await SpecificationsService.deleteSpecificationCollection(id);
      this.getSpecifications();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} SpecificationCollection`);
    }
  }

  public async getSpecificationFiles(): Promise<void> {
    if (!this.selectedSpecification.id && !this.currentSpecification?.id) return;

    this.isLoading = true;

    try {
      const result = await SpecificationsService.getFiles(this.selectedSpecification.id! ?? this.currentSpecification?.id!);

      if (!result) return;

      runInAction(() => {
        this.specificationFiles = result.map((data) => new SpecificationFilesModel(data));
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} SpecificationFiles`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createSpecificationFile(): Promise<void> {
    if (!this.specificationAttachFile.postDto || !this.selectedSpecification.id) return;

    try {
      await SpecificationsService.postFile(this.selectedSpecification.id, this.specificationAttachFile.postDto);
      this.getSpecificationFiles();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} SpecificationFile`);
    }
  }

  public async updateSpecificationTemplateFile(): Promise<void> {
    if (!this.selectedSpecification.postFileDto || !this.selectedSpecification.id) return;

    try {
      await SpecificationsService.postTemplateFile(this.selectedSpecification.id, this.selectedSpecification.postFileDto);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} SpecificationTemplateFile`);
    }
  }

  public async deleteSpecificationFile(id: number): Promise<void> {
    if (!this.selectedSpecification.id) return;

    try {
      await SpecificationsService.deleteFile(id, this.selectedSpecification.id);
      this.getSpecificationFiles();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} SpecificationFile`);
    }
  }

  public async getParametersSpecification(): Promise<void> {
    if (!this.selectedSpecification.id || !sectionsStore.selectedSectionRow) return;

    this.isLoading = true;

    try {
      const result = await SpecificationsService.getSpecificationParameters(this.selectedSpecification.id, sectionsStore.selectedSectionRow);

      if (!result) return;

      const res: ISectionParameter[] = [];

      result.forEach((el) => {
        const checkedParams = el.parameters.filter((_) => _.isSelected);

        res.push({
          hierarchy: [el.fullName],
          id: el.id,
          name: el.fullName,
          fullName: el.fullName,
          isGroup: true,
          parameterIds: el.parameters.map((parameter) => parameter.id),
          checked: checkedParams.length === el.parameters.length && el.parameters.length !== 0 ? true : false,
        });

        el.parameters.forEach((item) => {
          res.push({
            hierarchy: [el.fullName, item.name],
            groupId: el.id,
            name: item.name,
            fullName: item.name,
            id: item.id,
            checked: item.isSelected,
            isGroup: false,
            parameterIds: [],
          });
        });
      });

      runInAction(() => {
        this.specificationParameters = res;
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} ParametersSpecification`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createSpecificationParameter(parameterIds: number[]): Promise<void> {
    if (!this.selectedSpecification.id || !sectionsStore.selectedSectionRow) return;

    try {
      this.isLoading = true;
      await SpecificationsService.postSpecificationParameter(this.selectedSpecification.id, sectionsStore.selectedSectionRow, parameterIds);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} SpecificationParameter`);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new SpecificationStore();
