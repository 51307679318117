import React, { useEffect, useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { Translator, classificationDictionaryStore, classificationDictionaryValueStore, projectStore, stageStore } from 'stores';
import { Select, DialogWrapper, InputValidate } from 'components';
import MultipleSelect from 'components/UI/MultipleSelect';
import { TextField as MuiTextField, Autocomplete, Box, CircularProgress, SelectChangeEvent } from '@mui/material';
import { ProjectType } from 'shared/enums';
import { IProjectForm } from 'shared/interfaces';

const ProjectDialog: React.FC = () => {
  const formRef = useRef<FormikProps<IProjectForm>>(null);

  useEffect(() => {
    if (projectStore.projectFilters.type === ProjectType.Local) {
      projectStore.getExternalProjects();

      stageStore.getStages().then(() => {
        if (stageStore.stages.length > 0) projectStore.selectedProject.setStageId(stageStore.stages[0].id!);
      });
    }

    classificationDictionaryStore.getDictionaryList().then(async () => {
      const ids = classificationDictionaryStore.dictionaries.map((el) => el.id);
      await classificationDictionaryValueStore.getDictionaryValueList(ids);

      if (!projectStore.selectedProject.id && projectStore.projectFilters.type === ProjectType.Local) {
        classificationDictionaryValueStore.regions.length > 0 &&
          projectStore.selectedProject.setRegion(classificationDictionaryValueStore.regions[0].id as number);
      }
    });

    return () => {
      projectStore.selectedProject.clear();
    };
  }, []);

  const onClose = () => {
    projectStore.setOpenDialog(false);
  };

  const onSubmit = async (values: IProjectForm) => {
    projectStore.selectedProject.updateProjectDialogForm(values);

    if (!projectStore.selectedProject.id) {
      projectStore.selectedProject.setType(projectStore.projectFilters.type!);

      if (projectStore.projectFilters.type === ProjectType.Standard) {
        projectStore.selectedProject.projectId !== null ? await projectStore.copyProject() : await projectStore.createProject();
      } else await projectStore.copyProject();
    } else {
      await projectStore.updateProject();
    }

    onClose();
  };

  const dialogTitle =
    projectStore.selectedProject.id && projectStore.projectFilters.type === ProjectType.Standard
      ? Translator.translate('projects.projectDialog.title.editStandard')
      : projectStore.selectedProject.id && projectStore.projectFilters.type === ProjectType.Local
      ? Translator.translate('projects.projectDialog.title.editICP')
      : projectStore.projectFilters.type === ProjectType.Standard
      ? Translator.translate('projects.projectDialog.title.addStandard')
      : Translator.translate('projects.projectDialog.title.addICP');

  const projectForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        name: projectStore.selectedProject.name,
        externalId: projectStore.selectedProject.externalId,
        externalName: projectStore.selectedProject.externalName,
        standard: projectStore.selectedProject.projectId,
        buildingType: projectStore.selectedProject.buildingType.id,
        region: projectStore.selectedProject.region.id,
        regionIds: projectStore.selectedProject.regionIds,
        address: projectStore.selectedProject.address,
        buildingClasses: projectStore.selectedProject.buildingClassIds,
        stage: projectStore.selectedProject.stageId,

        externalProjectsOpt: projectStore.externalProjectsForOptions,
        standardOpt: projectStore.projectsByStandard,
        buildingTypesOpt: classificationDictionaryValueStore.buildingTypes,
        regionOpt: classificationDictionaryValueStore.regions,
        buildingClassOpt: classificationDictionaryValueStore.buildingClasses,
        stageOpt: stageStore.stageForOptions,
      }}
      // TODO: добавить разные validationSchema в зависимости от типа проекта
      validationSchema={() =>
        Yup.lazy((values) => {
          return Yup.object().shape({
            name:
              projectStore.projectFilters.type === ProjectType.Standard ||
              (projectStore.projectFilters.type === ProjectType.Local && (projectStore.selectedProject.id !== null || values.externalId === null))
                ? Yup.string().max(255).required(Translator.translate('validationMessage.required'))
                : Yup.string(),
            standard: Yup.number().nullable(),
            region:
              projectStore.projectFilters.type === ProjectType.Standard
                ? Yup.number().nullable()
                : Yup.number().required(Translator.translate('validationMessage.required')),
            regionIds:
              projectStore.projectFilters.type === ProjectType.Standard
                ? Yup.array()
                    .min(1, Translator.translate('projects.projectDialog.validationSchema.regionIds'))
                    .required(Translator.translate('validationMessage.required'))
                : Yup.array(),
            buildingType:
              projectStore.projectFilters.type === ProjectType.Local
                ? Yup.number().required(Translator.translate('validationMessage.required'))
                : Yup.number().nullable(),
            address:
              projectStore.projectFilters.type === ProjectType.Local
                ? Yup.string().max(255).required(Translator.translate('validationMessage.required'))
                : Yup.string().nullable(),
            buildingClasses:
              projectStore.projectFilters.type === ProjectType.Local
                ? Yup.array()
                    .min(1, Translator.translate('projects.projectDialog.validationSchema.buildingClasses'))
                    .required(Translator.translate('validationMessage.required'))
                : Yup.array(),
            stage:
              projectStore.projectFilters.type === ProjectType.Local && !projectStore.selectedProject.id
                ? Yup.number().required(Translator.translate('validationMessage.required'))
                : Yup.number().nullable(),
          });
        })
      }>
      {({ handleChange, handleBlur, values, errors, touched, setFieldValue }) => {
        return (
          <>
            <InputValidate
              label={Translator.translate('projects.columns.name')}
              name="name"
              required={
                projectStore.projectFilters.type === ProjectType.Standard ||
                (projectStore.projectFilters.type === ProjectType.Local && (projectStore.selectedProject.id !== null || values.externalId === null))
              }
              error={Boolean(touched.name && errors.name)}
              helperText={(touched.name as any) && errors.name}
              disabled={values.externalId !== null}
              value={values.externalId ? '' : values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {projectStore.projectFilters.type === ProjectType.Local && !projectStore.selectedProject.id && (
              <Autocomplete
                id="externalId"
                options={values.externalProjectsOpt}
                openOnFocus
                noOptionsText={Translator.translate('projects.dialogFormFields.notFoundICP')}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => {
                  setFieldValue('externalId', value ? value.id : null);
                  setFieldValue('externalName', value ? value.name : '');
                }}
                clearOnBlur={false}
                renderInput={(params) => <MuiTextField {...params} label={Translator.translate('projects.dialogFormFields.externalProject')} />}
                disabled={values.name.length !== 0}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
              />
            )}

            {!projectStore.selectedProject.id && (
              <Select
                fullWidth
                name="standard"
                label={Translator.translate('projects.projectTypeNames.standard')}
                required
                error={Boolean(touched.standard && errors.standard)}
                helperText={(touched.standard as any) && (errors.standard as any)}
                hideNotSelectItem
                value={values.standard}
                options={values.standardOpt}
                onChange={handleChange}
                onBlur={handleBlur}
                size="medium"
              />
            )}

            {projectStore.projectFilters.type === ProjectType.Local && (
              <Select
                fullWidth
                required
                name="buildingType"
                label={Translator.translate('projects.columns.buildingType')}
                error={Boolean(touched.buildingType && errors.buildingType)}
                helperText={(touched.buildingType as any) && (errors.buildingType as any)}
                hideNotSelectItem
                value={values.buildingType}
                options={values.buildingTypesOpt}
                onChange={handleChange}
                size="medium"
              />
            )}

            {projectStore.projectFilters.type === ProjectType.Standard && (
              <MultipleSelect
                label={`${Translator.translate('projects.columns.region')} *`}
                name="regionIds"
                error={Boolean(touched.regionIds && errors.regionIds)}
                helperText={(touched.regionIds as any) && (errors.regionIds as any)}
                sx={{ width: '100%' }}
                value={values.regionIds}
                options={values.regionOpt}
                onChange={(e: SelectChangeEvent<any>) => setFieldValue('regionIds', e.target.value)}
              />
            )}

            {projectStore.projectFilters.type === ProjectType.Local && (
              <Select
                error={Boolean(touched.region && errors.region)}
                helperText={(touched.region as any) && (errors.region as any)}
                required
                label={Translator.translate('projects.columns.region')}
                name="region"
                hideNotSelectItem
                value={values.region}
                options={values.regionOpt}
                onChange={handleChange}
                size="medium"
              />
            )}

            {projectStore.projectFilters.type === ProjectType.Local && (
              <InputValidate
                name="address"
                error={Boolean(touched.address && errors.address)}
                helperText={(touched.address as any) && errors.address}
                label={Translator.translate('projects.columns.address')}
                required
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}

            {projectStore.projectFilters.type === ProjectType.Local && (
              <MultipleSelect
                required
                label={`${Translator.translate('projects.columns.buildingClass')} *`}
                sx={{ width: '100%' }}
                name="buildingClasses"
                error={Boolean(touched.buildingClasses && errors.buildingClasses)}
                helperText={(touched.buildingClasses as any) && errors.buildingClasses}
                value={values.buildingClasses}
                options={values.buildingClassOpt}
                onChange={(e: SelectChangeEvent<any>) => setFieldValue('buildingClasses', e.target.value)}
              />
            )}

            {!projectStore.selectedProject.id && projectStore.projectFilters.type === ProjectType.Local && (
              <Select
                name="stage"
                label={Translator.translate('projects.dialogFormFields.designStage')}
                hideNotSelectItem
                required
                value={values.stage}
                options={values.stageOpt}
                onChange={handleChange}
                size="medium"
              />
            )}
          </>
        );
      }}
    </Formik>
  );

  return (
    <DialogWrapper
      title={dialogTitle}
      maxWidth="md"
      open={projectStore.isDialogOpen}
      onClose={() => projectStore.setOpenDialog(false)}
      onCancel={() => projectStore.setOpenDialog(false)}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}>
      {classificationDictionaryStore.isLoading || classificationDictionaryValueStore.isLoading ? (
        <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        projectForm
      )}
    </DialogWrapper>
  );
};

export default observer(ProjectDialog);
