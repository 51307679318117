import { BASE_PATH } from 'shared/constants/constants';
import { IClassificationDictionaryGetDto, IClassificationDictionaryParamsDto } from 'shared/interfaces';
import HTTPService from './HttpService';

class ClassificationDictionary extends HTTPService {
  constructor() {
    super(BASE_PATH.CLASSIFICATION_DICTIONARIES);
  }

  public getClassificationDictionaries(params?: IClassificationDictionaryParamsDto): Promise<IClassificationDictionaryGetDto[]> {
    return this.GET('', { params });
  }

  /* public postClassificationDictionary(body: ICreateClassesCommandDto): Promise<number[]> {
    return this.POST('', body);
  }

  public putClassificationDictionary(id: number, body: IUpdateClassDto) {
    return this.PUT(`${id}`, body);
  }

  public deleteClassificationDictionary(id: number) {
    return this.DELETE(`${id}`);
  } */
}

export default new ClassificationDictionary();
