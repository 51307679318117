import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { parameterGroupStore } from 'stores';
import { Select } from 'components';
import { ParameterGroupModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

interface IParameterGroupTreeSelectProps {
  width?: string;
  onScroll: (key: React.Key) => void;
  onChangeParamGroup?: (value: ParameterGroupModel) => void;
  onChangeRootParamGroup?: (value: number) => void;
  menuItems?: ReactElement[] | ReactElement;
  addActions?: ReactNode;
}

const ParameterGroupTreeSelect: React.FC<IParameterGroupTreeSelectProps> = (props) => {
  const { width, menuItems, addActions, onChangeParamGroup, onScroll, onChangeRootParamGroup } = props;

  const onChangeRootGroup = async (value: number) => {
    if (onChangeRootParamGroup) onChangeRootParamGroup(value);
    else {
      parameterGroupStore.setCurrentParameterGroup(value);
      await parameterGroupStore.getParameterGroups();
    }

    onSearch();
  };

  const onSearch = () => {
    const newExpandedKeys = [...parameterGroupStore.expandedKeys];

    if (parameterGroupStore.searchValue !== '') {
      const findNodes = Utils.findSearch(parameterGroupStore.parameterGroupTree, parameterGroupStore.searchValue);

      if (findNodes.length !== 0) {
        // Expand tree
        findNodes.forEach((find) => {
          const item = parameterGroupStore.listTransformParamGroupDto.find((dto) => dto.key === find.key);
          if (item && item.parentId) {
            const parents = [item.parentId];
            for (const parentId of parents) {
              const parent = parameterGroupStore.listTransformParamGroupDto.find((dto) => dto.id === parentId);
              if (parent) {
                if (!newExpandedKeys.includes(parent.key)) {
                  newExpandedKeys.push(parent.key);
                }

                if (parent.parentId) {
                  parents.push(parent.parentId);
                }
              }
            }
          }
        });
      }

      parameterGroupStore.setSearchArrays(findNodes);
      parameterGroupStore.setExpandedKeys(newExpandedKeys);

      if (findNodes.length !== 0) {
        const findKey = parameterGroupStore.listTransformParamGroupDto.find((item) => item.key === findNodes[0].key);
        parameterGroupStore.selectedParamGroup = new ParameterGroupModel(findKey);
        onScroll(parameterGroupStore.searchArrays[0].key);
        onChangeParamGroup && onChangeParamGroup(parameterGroupStore.selectedParamGroup);
      }
    } else parameterGroupStore.setSearchArrays([]);
  };

  return (
    <Select
      sx={{
        width: width ? width : '100%',
        backgroundColor: '#F2F2F9',
        boxShadow: 'none',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
        borderRadius: '6px',
      }}
      hideNotSelectItem
      value={parameterGroupStore.currentParameterGroup ?? ''}
      options={parameterGroupStore.rootGroupForOptions}
      onChange={(e) => onChangeRootGroup(e.target.value as number)}
      size="small"
      menuItems={menuItems}
      addActions={addActions}
    />
  );
};

export default observer(ParameterGroupTreeSelect);
