import React from 'react';

const DownIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 3C11 2.44772 10.5523 2 10 2C9.44772 2 9 2.44772 9 3V14.5858L3.70711 9.29289C3.31658 8.90237 2.68342 8.90237 2.29289 9.29289C1.90237 9.68342 1.90237 10.3166 2.29289 10.7071L9.29289 17.7071C9.68342 18.0976 10.3166 18.0976 10.7071 17.7071L17.7071 10.7071C18.0976 10.3166 18.0976 9.68342 17.7071 9.29289C17.3166 8.90237 16.6834 8.90237 16.2929 9.29289L11 14.5858V3Z" />
    </svg>
  );
};

export default DownIcon;
