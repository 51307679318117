import { makeAutoObservable, runInAction } from 'mobx';
import { InformationBlockService } from 'services';
import { Translator, toastStore } from 'stores';
import { IGetInformationBlockDto, IInfBlockParameterGroup, ISelectOption } from 'shared/interfaces';
import { InformationBlockModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

class InformationBlockStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public informationBlocks: IGetInformationBlockDto[] = [];

  public filterName = '';

  public filterHeight: number | null = null;

  public isDialogOpen = false;

  public isDialogParameterGroupOpen = false;

  public selectedInformationBlock = new InformationBlockModel();

  public checkedParamGroup: IInfBlockParameterGroup[] = [];

  public get filterInformationBlocks(): IGetInformationBlockDto[] {
    return this.informationBlocks.filter((block) => block.name.toLowerCase().includes(this.filterName.toLowerCase()));
  }

  public get informationBlocksForOption(): ISelectOption[] {
    return this.informationBlocks.map((block) => {
      return {
        id: block.id,
        name: block.name,
      };
    });
  }

  public setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  public setDialogOpen(isOpen: boolean) {
    this.isDialogOpen = isOpen;
  }

  public setDialogParameterGroupOpen(isOpen: boolean) {
    this.isDialogParameterGroupOpen = isOpen;
  }

  public setFilterName(filterName: string) {
    this.filterName = filterName;
  }

  public setFilterHeight(filterHeight: number | null) {
    this.filterHeight = filterHeight;
  }

  public setCheckedParamGroup(parameterGroup: IInfBlockParameterGroup, stateAction?: boolean) {
    if (stateAction) {
      this.checkedParamGroup.push(parameterGroup);
    } else {
      const findGroupIndex = this.checkedParamGroup.findIndex((f) => f.id === parameterGroup.id);
      this.checkedParamGroup.splice(findGroupIndex, 1);
    }
  }

  public clearInformationBlock() {
    this.checkedParamGroup = [];
    this.selectedInformationBlock.clear();
  }

  public async getInformationBlocks(): Promise<void> {
    try {
      this.setLoading(true);
      const result = await InformationBlockService.getInformationBlocks();
      if (!result) return;

      runInAction(() => {
        this.informationBlocks = Utils.sortByField(result, 'orderNum');
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} InformationBlocks`);
    } finally {
      this.setLoading(false);
    }
  }

  public async getInformationBlockById(id: number): Promise<void> {
    try {
      this.setLoading(true);
      const result = await InformationBlockService.getInformationBlock(id);

      if (!result) return;

      runInAction(() => {
        this.selectedInformationBlock = new InformationBlockModel(result);
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} InformationBlock`);
    } finally {
      this.setLoading(false);
    }
  }

  public async createInformationBlock(): Promise<void> {
    if (!this.selectedInformationBlock.postDto) return;

    try {
      this.setLoading(true);

      await InformationBlockService.postInformationBlock(this.selectedInformationBlock.postDto);
      this.getInformationBlocks();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} InformationBlock`);
    } finally {
      this.setLoading(false);
    }
  }

  public async updateInformationBlock(): Promise<void> {
    if (!this.selectedInformationBlock.id || !this.selectedInformationBlock.putDto) return;

    try {
      this.setLoading(true);
      await InformationBlockService.putInformationBlock(this.selectedInformationBlock.id, this.selectedInformationBlock.putDto);
      this.getInformationBlocks();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} InformationBlock`);
    } finally {
      this.setLoading(false);
    }
  }

  public async removeInformationBlock(id: number): Promise<void> {
    try {
      this.setLoading(true);

      await InformationBlockService.deleteInformationBlock(id);
      this.getInformationBlocks();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} InformationBlock`);
    } finally {
      this.setLoading(false);
    }
  }
}

export default new InformationBlockStore();
