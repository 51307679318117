import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';
import { ApprovalUserRole, PartialApprovalIteration } from 'shared/enums';
import { IGetApprovalUserDto, IPostApprovalUserDto, IPutApprovalUserDto } from 'shared/interfaces';

class ApprovalUserModel {
  constructor(dto?: IGetApprovalUserDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.userId = dto.user.id;
    this.role = dto.role;
    this.userName = dto.user.name;
    this.expiration = dto.expiration ? new Date(dto.expiration) : null;
    this.actionDate = dto.actionDate ? new Date(dto.actionDate) : null;
    this.iteration = dto.iteration;
  }

  public id: number | null = null;

  public userId: number | null = null;

  public userName = '';

  public role?: ApprovalUserRole;

  public expiration: Date | null = new Date();

  public actionDate: Date | null = null;

  public iteration: PartialApprovalIteration | null = null;

  public isChanged = false;

  public get postDto(): IPostApprovalUserDto | null {
    if (!this.userId) return null;

    return {
      userId: this.userId,
      role: this.role,
      expiration: this.expiration ? dayjs(this.expiration).format('YYYY-MM-DD') : null,
    };
  }

  public get putDto(): IPutApprovalUserDto | null {
    if (!this.userId || !this.id) return null;

    return {
      id: this.id,
      userId: this.userId,
      role: this.role,
      expiration: this.expiration ? dayjs(this.expiration).format('YYYY-MM-DD') : null,
    };
  }

  public setChanged(isChange: boolean) {
    this.isChanged = isChange;
  }

  public setId(id: number) {
    this.id = id;
  }

  public setUserId(value: number) {
    this.userId = value;
  }

  public setRole(role: ApprovalUserRole) {
    this.role = role;
  }

  public setExpiration(value: Date) {
    this.expiration = value;
  }
}

export default ApprovalUserModel;
