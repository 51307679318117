import React from 'react';

const TaskIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.25 10C6.25 9.58579 6.58579 9.25 7 9.25H10C10.4142 9.25 10.75 9.58579 10.75 10C10.75 10.4142 10.4142 10.75 10 10.75H7C6.58579 10.75 6.25 10.4142 6.25 10Z" />
      <path d="M7 13.25C6.58579 13.25 6.25 13.5858 6.25 14C6.25 14.4142 6.58579 14.75 7 14.75H13C13.4142 14.75 13.75 14.4142 13.75 14C13.75 13.5858 13.4142 13.25 13 13.25H7Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 4C3.25 2.48122 4.48122 1.25 6 1.25H12C12.2278 1.25 12.4433 1.35357 12.5857 1.53148L16.5857 6.53148C16.692 6.66446 16.75 6.8297 16.75 7V16C16.75 17.5188 15.5188 18.75 14 18.75H6C4.48122 18.75 3.25 17.5188 3.25 16V4ZM6 2.75C5.30964 2.75 4.75 3.30964 4.75 4V16C4.75 16.6904 5.30964 17.25 6 17.25H14C14.6904 17.25 15.25 16.6904 15.25 16V7.75H13C12.0335 7.75 11.25 6.9665 11.25 6V2.75H6ZM12.75 4.13809L14.4395 6.25H13C12.8619 6.25 12.75 6.13807 12.75 6V4.13809Z"
      />
    </svg>
  );
};

export default TaskIcon;
