import React, { useEffect } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { observer } from 'mobx-react-lite';
import { Translator, xmlDocumentInstanceStore } from 'stores';
import { HierarchyViewer } from 'components';
import { CircularProgress, Stack, Typography } from '@mui/material';
import Utils from 'shared/utils/Utils';
import XMLNodeDialog from './Components';

const HEIGHT_CONTENT = 130;

const XMLViewerPage = () => {
  useEffect(() => {
    const init = async () => {
      await xmlDocumentInstanceStore.getXMLSchemaTemplateById();
      xmlDocumentInstanceStore.getXMLDocumentNodesById();
    };

    init();

    return () => {
      xmlDocumentInstanceStore.clear();
    };
  }, [xmlDocumentInstanceStore.selectedXMLDocument.id]);

  const emptyData = (): JSX.Element => (
    <Stack alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
      <img style={{ alignSelf: 'center' }} src={`${process.env.PUBLIC_URL}/img/empty_data.svg`} alt="" />
      <Typography variant="subtitle1" color="text.disabled">
        {Translator.translate('system.emptyData')}
      </Typography>
    </Stack>
  );

  const onEditNode = (node: any) => {
    xmlDocumentInstanceStore.onEditXMLNode(node);
  };

  return (
    <>
      <Stack flexDirection="row" gap={4} height={`calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight()}px)`}>
        {xmlDocumentInstanceStore.isLoading ? (
          <Stack alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
            <CircularProgress />
          </Stack>
        ) : xmlDocumentInstanceStore.xmlDocumentTree.id ? (
          <ParentSize>
            {({ width, height }) =>
              width &&
              height && (
                <HierarchyViewer
                  width={width}
                  height={height}
                  data={xmlDocumentInstanceStore.xmlDocumentTree}
                  expandedTreeNodeIds={xmlDocumentInstanceStore.expandedNodeIds}
                  onEditNode={onEditNode}
                />
              )
            }
          </ParentSize>
        ) : (
          emptyData()
        )}
      </Stack>

      {xmlDocumentInstanceStore.isDialogXMLNodeOpen && <XMLNodeDialog />}
    </>
  );
};

export default observer(XMLViewerPage);
