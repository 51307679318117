import React from 'react';

const SmallCheckIcon: React.FC = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="rgba(15, 11, 31, 0.54)" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.555 2.4955C10.8336 2.80199 10.811 3.27633 10.5045 3.55496L5.00451 8.55496C4.71844 8.81502 4.28157 8.81502 3.9955 8.55496L1.4955 6.28223C1.18901 6.0036 1.16642 5.52926 1.44505 5.22277C1.72368 4.91628 2.19802 4.89369 2.50451 5.17232L4.50001 6.98641L9.4955 2.44505C9.80199 2.16642 10.2763 2.189 10.555 2.4955Z"
      />
    </svg>
  );
};

export default SmallCheckIcon;
