import { makeAutoObservable } from 'mobx';
import { MergeState } from 'shared/enums';
import { IMergeTable, IModifications } from 'shared/interfaces';

class MergeTableModel {
  constructor(dto?: IMergeTable) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.fullName = dto.fullName;
    this.name = dto.name ?? '';
    this.icon = dto.icon;
    this.modifications = dto.modifications ? dto.modifications : [];
  }

  public id: number | null = null;

  public fullName = '';

  public name = '';

  public icon = MergeState.Added;

  public modifications: IModifications[] = [];

  public checked = false;

  public setChecked(checked: boolean) {
    this.checked = checked;
  }

  public clearModel() {
    this.id = null;
    this.fullName = '';
    this.name = '';
    this.checked = false;
    this.icon = MergeState.Added;
    this.modifications = [];
  }
}

export default MergeTableModel;
