import React, { MouseEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Translator } from 'stores';
import FilterIcon from 'components/Icons/FilterIcon';
import SortingColumnAscIcon from 'components/Icons/SortingColumnAscIcon';
import SortingColumnDescIcon from 'components/Icons/SortingColumnDescIcon';
import IconButtonNew from 'components/UI/IconButtonNew';
import PopupMenu from 'components/UI/PopupMenu';
import {
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TablePagination,
  LabelDisplayedRowsArgs,
  TableFooter,
  TableHead,
  Checkbox,
  Stack,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Order } from 'shared/enums';
import { ITableColumn } from 'shared/interfaces';

import s from './TableView.module.scss';

const DEFAULT_PAGINATION_OPTIONS = [5, 10, 15, 20];

const labelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => {
  return `${from}-${to === -1 ? count : to} из ${count !== -1 ? count : `больше ${to}`}`;
};

export interface ITableViewProps<ColumnType = ITableColumn> {
  data: any[];
  checkable?: number[];
  columns: ColumnType[];
  isHideHeader?: boolean;
  readOnly?: boolean;
  isActionsHover?: boolean;
  isCheckedOnClickLine?: boolean;
  currentRow?: number | null;
  totalCount?: number;
  rowsPerPageOptions?: number[];
  rowsPerPage?: number;
  page?: number;
  isSelectedById?: boolean;
  isEscSelectClear?: boolean;
  hideSelectRowOnMount?: boolean;
  setPage?: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  onDoubleClick?: (row: any, column: any, index: any) => void;
  onClick?: (row: any, column: any, rowIndex: any, e?: MouseEvent<HTMLTableCellElement>) => void;
  className?: any;
  onChecked?: (checked: number[], row?: any) => void;
  onSort?: (property: string, order: Order) => void;
  onHover?: (rowId: number | null) => void;
  height?: string | number;
  maxHeight?: string | number;
  isFilter?: boolean;
  isLoading?: boolean;
}

const TableView: React.FC<ITableViewProps> = (props) => {
  const {
    columns,
    data,
    isHideHeader,
    checkable,
    totalCount,
    rowsPerPageOptions = [10, 20, 50, 1000],
    isEscSelectClear,
    hideSelectRowOnMount,
    currentRow,
    rowsPerPage = 10,
    isSelectedById,
    isActionsHover = false,
    isCheckedOnClickLine = false,
    page = 0,
    setPage,
    onChangeRowsPerPage,
    onDoubleClick,
    onClick,
    onChecked,
    onSort,
    onHover,
    className,
    height,
    maxHeight,
    isFilter,
    isLoading,
    readOnly = false,
  } = props;

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>();
  const [hoveredRowId, setHoveredRowId] = useState(-1);
  const [checked, setChecked] = useState<number[]>([]);

  const [isShowIconSorting, setShowIconSorting] = useState('');
  const [order, setOrder] = useState<Order | null>(null);
  const [orderBy, setOrderBy] = useState<string | null>(null);

  useEffect(() => {
    if (checkable !== undefined) setChecked(checkable);
  }, [checkable]);

  useEffect(() => {
    if (!checkable) setChecked(data.filter((f) => f.checked).map((value) => value.id));
  }, [data]);

  useEffect(() => {
    window.addEventListener('keydown', onKeyEsc);

    return () => {
      window.removeEventListener('keydown', onKeyEsc);
    };
  }, []);

  const onKeyEsc = (event: any) => {
    if (!isEscSelectClear) return;
    if (event.keyCode === 27) {
      setSelectedRowIndex(null);

      if (onClick) {
        onClick(null, null, null);
      }
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      setSelectedRowIndex(currentRow ? currentRow : !hideSelectRowOnMount ? 0 : null);
    }
  }, [data]);

  const renderRow = (row: any, cols: ITableColumn[], rowIndex: number) => {
    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>, row: any) => {
      if (readOnly) return;

      const checkedIndex = checked.indexOf(row.id);

      let newChecked: number[] = [];

      //проставление чекбокса
      if (checkedIndex === -1) {
        newChecked = newChecked.concat(checked, row.id);
      }
      //снятие чекбокса у первого элемента
      else if (checkedIndex === 0) {
        newChecked = newChecked.concat(checked.slice(1));
      }
      //снятие чекбокса у последнего элемента
      else if (checkedIndex === checked.length - 1) {
        newChecked = newChecked.concat(checked.slice(0, -1));
      }
      //снятие чекбокса у внутреннего элемента
      else if (checkedIndex > 0) {
        newChecked = newChecked.concat(checked.slice(0, checkedIndex), checked.slice(checkedIndex + 1));
      }

      setChecked(newChecked);
      if (onChecked) onChecked(newChecked, row);
    };

    const isSelected = (rowId: number): boolean => {
      return checked.indexOf(rowId) !== -1;
    };

    const isItemSelected = isSelected(row.id);

    return (
      <>
        {onChecked !== undefined && (
          <TableCell padding="checkbox" style={{ maxWidth: '3%', width: '3%', padding: '4px 4px 4px 8px' }}>
            <Checkbox
              sx={{ p: 0 }}
              disabled={readOnly}
              checked={!row.hasOwnProperty('checked') ? isItemSelected : row.checked}
              onChange={(e) => handleCheckbox(e, row)}
            />
          </TableCell>
        )}

        {cols.map((column) => {
          const handleDoubleClick = (e: MouseEvent<Element>) => {
            if (!onDoubleClick) return;

            onDoubleClick(row, column, rowIndex);
          };

          const handleClick = (e: MouseEvent<Element>) => {
            if (readOnly) return;

            if (isCheckedOnClickLine) {
              if (!checked.includes(row.id)) {
                const newChecked = checked.slice();
                newChecked.push(row.id);
                onChecked && onChecked(newChecked, row);
              } else {
                onChecked &&
                  onChecked(
                    checked.filter((f) => f !== row.id),
                    row
                  );
              }
            }

            if (!onClick) return;

            onClick(row, column, rowIndex);
            isSelectedById ? setSelectedRowIndex(row.id) : setSelectedRowIndex(rowIndex);
          };

          const cellRender = () => {
            if (isActionsHover) {
              if (column.key === 'actions' && row.id === hoveredRowId) {
                return <>{column.cell ? column.cell(row, rowIndex) : row[column.key]}</>;
              } else {
                return <>{column.cell && column.key !== 'actions' ? column.cell(row, rowIndex) : row[column.key]}</>;
              }
            } else {
              return <>{column.cell ? column.cell(row, rowIndex) : row[column.key]}</>;
            }
          };

          return (
            <TableCell
              onDoubleClick={handleDoubleClick}
              onClick={handleClick}
              onMouseEnter={() => {
                onHover && onHover(row.id);
              }}
              onMouseLeave={() => {
                onHover && onHover(null);
              }}
              variant="body"
              key={column.key}
              align={column.align}
              style={{
                maxWidth: column.maxWidth,
                minWidth: column.minWidth,
                width: column.width,
                padding: '4px 4px 4px 8px',
                height: column.height,
                cursor: onHover ? 'pointer' : 'default',
              }}>
              {cellRender()}
            </TableCell>
          );
        })}
      </>
    );
  };

  const renderRows = () => {
    if (data.length === 0 && !isLoading) {
      return (
        <TableRow>
          <TableCell sx={{ borderBottom: 'none' }} colSpan={columns.length}>
            <Stack alignItems="center">
              <img
                style={{ alignSelf: 'center' }}
                src={isFilter ? `${process.env.PUBLIC_URL}/img/not_found_data.svg` : `${process.env.PUBLIC_URL}/img/empty_data.svg`}
                alt=""
              />
              <Typography variant="subtitle1" color="text.disabled">
                {isFilter ? Translator.translate('system.notFoundData') : Translator.translate('system.emptyData')}
              </Typography>
            </Stack>
          </TableCell>
        </TableRow>
      );
    } else if (isLoading) {
      return (
        <TableRow>
          <TableCell sx={{ borderBottom: 'none' }} colSpan={columns.length}>
            <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          </TableCell>
        </TableRow>
      );
    } else {
      if (totalCount === undefined || page === undefined || rowsPerPage === undefined)
        return data.map((item, index) => {
          return (
            <TableRow
              hover={!readOnly}
              onMouseEnter={() => setHoveredRowId(item.id)}
              onMouseLeave={() => setHoveredRowId(-1)}
              selected={isSelectedById ? selectedRowIndex === item.id : selectedRowIndex === index}
              className={s.tableRow}
              key={item.id}>
              {renderRow(item, columns, index)}
            </TableRow>
          );
        });
      else
        return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
          return (
            <TableRow
              hover={!readOnly}
              selected={isSelectedById ? selectedRowIndex === item.id : selectedRowIndex === index}
              className={s.tableRow}
              key={index}>
              {renderRow(item, columns, index)}
            </TableRow>
          );
        });
    }
  };

  const renderHeader = (cols: ITableColumn[]) => {
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (readOnly) return;

      if (event.target.checked) {
        const newChecked = data.map((data) => data.id);
        setChecked(newChecked);
        if (onChecked) onChecked(newChecked);
        return;
      }
      setChecked([]);
      if (onChecked) onChecked([]);
    };

    return (
      <>
        {onChecked !== undefined && (
          <TableCell style={{ maxWidth: '3%', width: '3%', padding: '4px 4px 4px 8px' }}>
            <Checkbox
              sx={{ p: 0 }}
              disabled={readOnly}
              indeterminate={checked.length > 0 && checked.length < data.length}
              checked={data.length > 0 && checked.length === data.length}
              onChange={handleSelectAllClick}
            />
          </TableCell>
        )}

        {cols.map((column) => {
          if (column.sortOrder && orderBy !== column.key) {
            setOrderBy(column.key);
            setOrder(column.sortOrder);
          }

          const handleSort = () => {
            if (column.key !== orderBy) {
              setOrderBy(column.key);
              setOrder(null);
            }

            if (!order) {
              setOrderBy(column.key);
              setOrder(Order.Asc);
            } else {
              setOrderBy(column.key);
              setOrder(order === Order.Asc ? Order.Desc : Order.Asc);
            }

            onSort && onSort(column.key, order === Order.Asc ? Order.Desc : Order.Asc);
          };

          const getClassName = (): string | undefined => {
            if (order) {
              if (orderBy === column.key) return '';
              else {
                if (isShowIconSorting === column.key) {
                  return s.sortIconShow;
                } else {
                  return s.sortIconHide;
                }
              }
            } else {
              if (isShowIconSorting === column.key) return s.sortIconShow;
              else return s.sortIconHide;
            }
          };

          const getIcon = (): JSX.Element => {
            if (!order) {
              return <SortingColumnAscIcon />;
            } else {
              if (orderBy !== column.key) return <SortingColumnAscIcon />;
              else {
                if (order === Order.Asc) return <SortingColumnAscIcon />;
                else return <SortingColumnDescIcon />;
              }
            }
          };

          return (
            <TableCell
              variant="head"
              key={column.key}
              align={column.align}
              style={{ maxWidth: column.maxWidth, width: column.width, padding: '4px 4px 4px 8px', fontWeight: 500 }}>
              <Stack
                position="relative"
                direction="row"
                alignItems="center"
                justifyContent={column.align === 'right' ? 'flex-end' : column.align === 'center' ? 'center' : 'space-between'}
                onMouseEnter={() => setShowIconSorting(column.key)}
                onMouseLeave={() => setShowIconSorting('')}>
                <Typography variant="body2" color="text.primary" fontWeight={500}>
                  {column.label}
                </Typography>

                {column.sorting && (
                  <Stack
                    position="absolute"
                    alignItems="center"
                    direction="row"
                    sx={{
                      height: '100%',
                      top: 0,
                      right: '8px',
                    }}
                    className={getClassName()}>
                    <IconButtonNew onClick={handleSort}>{getIcon()}</IconButtonNew>
                  </Stack>
                )}
                {column.filterOptions && column.filterOptions().length > 0 && (
                  <PopupMenu
                    key={column.key}
                    button={
                      <IconButtonNew
                        sx={(theme) => ({
                          svg: {
                            fill: column.hasFilters ? theme.palette.primary.main : 'unset',
                            fillOpacity: column.hasFilters ? 1 : 0.54,
                          },
                        })}>
                        <FilterIcon />
                      </IconButtonNew>
                    }
                    menuItems={column.filterOptions()}
                  />
                )}
              </Stack>
            </TableCell>
          );
        })}
      </>
    );
  };

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (!setPage) return;

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChangeRowsPerPage) return;

    onChangeRowsPerPage(Number.parseInt(event.target.value, 10));
  };

  const renderPagination = () => {
    if (totalCount === undefined || page === undefined || rowsPerPage === undefined) return null;

    return (
      <TablePagination
        labelRowsPerPage={`${Translator.translate('system.rowsPerPage')}:`}
        rowsPerPageOptions={rowsPerPageOptions || DEFAULT_PAGINATION_OPTIONS}
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={labelDisplayedRows}
      />
    );
  };

  return (
    <TableContainer sx={{ height, maxHeight }} className={classNames(s.wrapper, className)}>
      <Table stickyHeader>
        {!isHideHeader && (
          <TableHead
            sx={(theme) => ({
              '.MuiTableCell-head': {
                bgcolor: theme.palette.common.white,
                color: 'text.secondary',
              },
            })}
            className={s.head}>
            <TableRow>{renderHeader(columns)}</TableRow>
          </TableHead>
        )}

        <TableBody
          sx={{
            '.MuiTableRow-hover': {
              '&:hover': {
                bgcolor: readOnly ? 'unset' : 'action.hover',
              },
            },
          }}>
          {renderRows()}
        </TableBody>

        <TableFooter>
          <TableRow>{renderPagination()}</TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default observer(TableView);
