import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.module.scss';
import { BrowserRouter } from 'react-router-dom';
import { keycloak, keycloakOptions } from 'shared/keycloak';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY ?? '');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (Boolean(Number(process.env.REACT_APP_IS_RUBIUS))) {
  keycloak.init(keycloakOptions).then((authenticated: boolean) => {
    if (!authenticated) {
      keycloak.login();
      return;
    }

    root.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>
    );
  });
} else {
  root.render(
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App />
    </BrowserRouter>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
