import React from 'react';

const JpgIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z" fill="#E9E9F4" />
      <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#A9ABB9" />
      <path d="M30 14L24 8H30V14Z" fill="#7B68EE" fillOpacity="0.08" />
      <path d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z" fill="#7448DD" />
      <path
        d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z"
        fill="url(#paint0_linear_12607_183092)"
        fillOpacity="0.24"
      />
      <path
        d="M8.87294 18.9469C8.87294 18.2749 9.92894 18.2749 9.92894 18.9469V22.1049C9.92894 23.3609 9.32894 24.1209 7.94594 24.1209C7.26594 24.1209 6.69894 23.9359 6.20294 23.2969C5.79494 22.8089 6.56294 22.1059 6.97894 22.6169C7.31494 23.0329 7.67494 23.1289 8.02594 23.1129C8.47294 23.0969 8.86594 22.8959 8.87394 22.1049V18.9469H8.87294Z"
        fill="white"
      />
      <path
        d="M11.3339 18.9468C11.3339 18.6828 11.5419 18.3948 11.8779 18.3948H13.7249C14.7649 18.3948 15.7009 19.0908 15.7009 20.4248C15.7009 21.6888 14.7649 22.3928 13.7249 22.3928H12.3899V23.4488C12.3899 23.8008 12.1659 23.9998 11.8779 23.9998C11.6139 23.9998 11.3339 23.8008 11.3339 23.4488V18.9468ZM12.3899 19.4018V21.3938H13.7249C14.2609 21.3938 14.6849 20.9208 14.6849 20.4248C14.6849 19.8658 14.2609 19.4018 13.7249 19.4018H12.3899Z"
        fill="white"
      />
      <path
        d="M21.4109 23.3849C20.8429 23.8569 20.1469 24.0569 19.4439 24.0569C17.7639 24.0569 16.5729 23.0979 16.5729 21.1939C16.5729 19.5799 17.8289 18.325 19.5149 18.325C20.1469 18.325 20.8419 18.54 21.3379 19.0279C21.8249 19.5069 21.1219 20.2189 20.6429 19.7959C20.3469 19.5079 19.9309 19.2919 19.5149 19.2919C18.5479 19.2919 17.6129 20.0669 17.6129 21.1939C17.6129 22.3779 18.3959 23.0979 19.4439 23.0979C19.9309 23.0979 20.3469 22.9529 20.6429 22.7379V21.7549H19.4439C18.7319 21.7549 18.8039 20.7789 19.4439 20.7789H21.0429C21.3389 20.7789 21.6099 21.0029 21.6099 21.2509V22.9539C21.6099 23.0979 21.5469 23.2319 21.4109 23.3849Z"
        fill="white"
      />
      <path d="M25 27H6V28H25C25.55 28 26 27.55 26 27V26C26 26.55 25.55 27 25 27Z" fill="#7B68EE" fillOpacity="0.12" />
      <defs>
        <linearGradient id="paint0_linear_12607_183092" x1="14" y1="15" x2="14" y2="27" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default JpgIcon;
