import React from 'react';

const MergeDeleteIcon: React.FC = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="#CB213B" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.33333 5.33399L6.66667 5.33398L9.33333 5.33399C9.70152 5.33399 10 5.63246 10 6.00065C10 6.36884 9.70152 6.66732 9.33333 6.66732H6.66667L5.33333 6.66732L2.66667 6.66732C2.29848 6.66732 2 6.36884 2 6.00065C2 5.63246 2.29848 5.33399 2.66667 5.33399H5.33333Z" />
    </svg>
  );
};

export default MergeDeleteIcon;
