import React from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Translator, mergeStore } from 'stores';
import { TableView } from 'components';
import MergeAddIcon from 'components/Icons/MergeAddIcon';
import MergeChangedValueIcon from 'components/Icons/MergeChangedValueIcon';
import MergeDeleteIcon from 'components/Icons/MergeDeleteIcon';
import { Box, Stack } from '@mui/material';
import { MergeAttributeNames, MergeState, MergeTabState } from 'shared/enums';
import { IModifications, ITableColumn } from 'shared/interfaces';
import { MergeTableModel } from 'shared/models/MergeModel';

import s from './ItemMergeTable.module.scss';

const ItemMergeTable: React.FC = () => {
  const displayNameDataType = (value: string | boolean) => {
    if (typeof value === 'boolean') {
      if (value === true) return Translator.translate('system.true');
      else if (value === false) Translator.translate('system.false');
      else '';
    } else {
      return value;
    }
  };

  const displayNameAddDel = (row: MergeTableModel): JSX.Element => {
    const name = row.fullName.replace(row.name, '');

    return (
      <Box
        component="span"
        sx={(theme) => ({
          wordBreak: 'break-word',
          color: theme.palette.text.disabled,
        })}>
        {name}
        <Box
          component="span"
          sx={(theme) => ({
            color: theme.palette.text.primary,
            textDecoration: row.icon === MergeState.Deleted ? 'line-through' : 'none',
          })}>
          {row.name}
        </Box>
      </Box>
    );
  };

  const IconValue = (row: MergeTableModel): JSX.Element => {
    switch (row.icon) {
      case MergeState.Added:
        return <MergeAddIcon />;

      case MergeState.Deleted:
        return <MergeDeleteIcon />;

      default:
        return <MergeAddIcon />;
    }
  };

  const columns: ITableColumn[] = [
    {
      key: 'name',
      label: Translator.translate('merge.columns.name'),
      width: '100%',
      align: 'left',
      cell: (row: MergeTableModel) => {
        return (
          <Stack flexDirection="row" alignItems="center">
            <Stack alignItems="center" sx={{ paddingRight: '10px' }}>
              {IconValue(row)}
            </Stack>

            {row.modifications.length === 0 && displayNameAddDel(row)}
          </Stack>
        );
      },
    },
  ];

  const columnsChangedItems = () => {
    const columnsChangedItems: ITableColumn[] = [];

    mergeStore.merge.mergeChangeItems.forEach((el: MergeTableModel) => {
      el.modifications.map((modification: IModifications) => {
        if (columnsChangedItems.every((el) => el.key !== modification.attributeName)) {
          columnsChangedItems.push({
            key: `${modification.attributeName}`,
            label: `${Translator.translate(MergeAttributeNames.get(modification.attributeName))}`,
            width: `calc(100% / ${el.modifications.length} )`,
            align: 'left',
            cell: (row: MergeTableModel) => {
              const findEl = row.modifications.find((f: IModifications) => f.attributeName === modification.attributeName);
              let name = '';
              if (modification.attributeName === 'Name') {
                name = row.fullName.replace(findEl!.currentValue as string, '');
              } else name = row.fullName;

              return (
                <>
                  {findEl!.currentValue !== findEl!.newValue && (
                    <span>
                      {modification.attributeName === 'Name' && (
                        <Box
                          component="span"
                          sx={(theme) => ({
                            wordBreak: 'break-word',
                            color: theme.palette.text.disabled,
                          })}>
                          {name}
                        </Box>
                      )}
                      <Box
                        component="span"
                        sx={(theme) => ({
                          wordBreak: 'break-word',
                          color: theme.palette.text.disabled,
                          textDecoration: 'line-through',
                        })}>
                        {displayNameDataType(findEl!.currentValue)}
                      </Box>

                      <Box
                        component="span"
                        sx={{
                          padding: '0 16px',
                          position: 'relative',
                          svg: {
                            position: 'absolute',
                            top: 0,
                            right: '25%',
                          },
                        }}>
                        <MergeChangedValueIcon />
                      </Box>

                      <Box
                        component="span"
                        sx={(theme) => ({
                          wordBreak: 'break-word',
                          color: theme.palette.text.primary,
                        })}>
                        {displayNameDataType(findEl!.newValue)}
                      </Box>
                    </span>
                  )}

                  {findEl!.currentValue === findEl!.newValue && (
                    <span>
                      {modification.attributeName === 'Name' && (
                        <Box
                          component="span"
                          sx={(theme) => ({
                            wordBreak: 'break-word',
                            color: theme.palette.text.disabled,
                          })}>
                          {name}
                        </Box>
                      )}
                      <Box
                        component="span"
                        sx={(theme) => ({
                          wordBreak: 'break-word',
                          color: theme.palette.text.disabled,
                        })}>
                        {displayNameDataType(findEl!.currentValue)}
                      </Box>
                    </span>
                  )}
                </>
              );
            },
          });
        }
      });
    });

    return columnsChangedItems;
  };

  const checkedMergeTable = (checked: number[], row?: any) => {
    if (row) {
      row.setChecked(row.checked ? false : true);
    } else {
      mergeStore.merge.mergeData.map((value) => value.setChecked(checked.length === 0 ? false : true));
    }
  };

  return (
    <TableView
      data={mergeStore.merge.mergeData}
      isCheckedOnClickLine
      hideSelectRowOnMount
      columns={mergeStore.merge.selectedTab === MergeTabState.AddDel ? columns : columnsChangedItems()}
      onChecked={checkedMergeTable}
      height="calc(100vh - 180px)"
    />
  );
};

export default observer(ItemMergeTable);
