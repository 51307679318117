import axios, { AxiosRequestConfig, Method } from 'axios';
import { Translator, toastStore } from 'stores';
import { keycloak } from 'shared/keycloak';

export default class HTTPService {
  private readonly basePath: string;
  private readonly host: string = process.env.REACT_APP_HOST || window.location.origin + process.env.PUBLIC_URL;

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  /**
   * GET request
   *
   * @param {string} path
   * @param {object} options
   */
  public GET<T>(path = '', options?: AxiosRequestConfig): Promise<T> {
    return this.safeFetch<T>(`${this.basePath}/${path}`, 'get', null, options);
  }

  /**
   * POST request
   *
   * @param {string} path
   * @param data {object}
   * @param {object} options
   */
  public POST<T>(path = '', data?: any, options?: AxiosRequestConfig): Promise<T> {
    return this.safeFetch<T>(`${this.basePath}/${path}`, 'post', data, options);
  }

  /**
   * PUT request
   *
   * @param {string} path
   * @param data {object}
   * @param {object} options
   */
  public PUT<T>(path = '', data?: any, options?: AxiosRequestConfig): Promise<T> {
    return this.safeFetch<T>(`${this.basePath}/${path}`, 'put', data, options);
  }

  /**
   * DELETE request
   *
   * @param {string} path
   * @param options
   */
  public DELETE<T>(path = '', data?: any, options?: AxiosRequestConfig): Promise<T> {
    return this.safeFetch<T>(`${this.basePath}/${path}`, 'delete', data, options);
  }

  /**
   * PATCH request
   *
   * @param {string} path
   * @param options
   */
  public PATCH<T>(path = '', data?: any, options?: AxiosRequestConfig): Promise<T> {
    return this.safeFetch<T>(`${this.basePath}/${path}`, 'patch', data, options);
  }

  /**
   * Request
   *
   * @param {string} path
   * @param {string} method
   * @param {string} data
   * @param {Object} options
   * @param showAccessAlert
   * @returns {Object|string}
   */
  public async safeFetch<T>(path: string, method: Method, data?: any, options: AxiosRequestConfig = {}, showAccessAlert = true): Promise<T> {
    if (!options.headers) {
      options.headers = { 'Content-Type': 'application/json' };
    }
    if (options.headers && !options.headers['Content-Type']) {
      options.headers['Content-Type'] = 'application/json';
    }

    if (Boolean(Number(process.env.REACT_APP_IS_RUBIUS)) && !options.headers?.Authorization) {
      options.headers.Authorization = `Bearer ${keycloak.token}`;
    }

    try {
      const response = await axios.request<T>({ baseURL: this.host + '/api', url: path, method, data, ...options });

      let isShowSuccess = false;
      if (method === 'put' || method === 'delete' || method === 'post') isShowSuccess = true;

      if ((response.data as any).accessToken) isShowSuccess = false;

      if (method === 'post' && response.config.url === '/projects/filter') isShowSuccess = false;

      if (showAccessAlert && isShowSuccess) toastStore.showSuccess(Translator.translate('stores.server.successMessage'));

      return response.data;
    } catch (errorData: any) {
      if (String(errorData.response.data.statusCode)[0] === '5') toastStore.showError(Translator.translate('stores.server.errorMessage'));

      if (Boolean(Number(process.env.REACT_APP_IS_RUBIUS)) && errorData.response.status === 401) {
        keycloak.login();
      }

      throw errorData.response;
    }
  }
}
