import { BASE_PATH } from 'shared/constants/constants';
import { IExternalProjectsGetDto, IProjectCopyDto, IProjectPostPutDto, IProjectsFiltersDto, IProjectsGetDto } from 'shared/interfaces';
import HTTPService from './HttpService';

class ProjectService extends HTTPService {
  constructor() {
    super(BASE_PATH.PROJECTS);
  }

  public postFilterProjects(body: IProjectsFiltersDto): Promise<IProjectsGetDto[]> {
    return this.POST('filter', body);
  }

  public getExternalProjects(): Promise<IExternalProjectsGetDto[]> {
    return this.GET<IExternalProjectsGetDto[]>('external');
  }

  public postProject(body: IProjectPostPutDto): Promise<number> {
    return this.POST('', body);
  }

  public copyProject(body: IProjectCopyDto): Promise<number> {
    return this.POST('copy', body);
  }

  public putProject(id: number, body: IProjectPostPutDto) {
    return this.PUT(`${id}`, body);
  }

  public deleteProject(id: number) {
    return this.DELETE(`${id}`);
  }
}

export default new ProjectService();
