import React from 'react';
interface IEditProps {
  color?: string;
}

const MoreVerticalIcon: React.FC<IEditProps> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={props.color ? props.color : '#0F0B1F'}
      fillOpacity={props.color ? '1' : '0.54'}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M6.3999 3.19998C6.3999 2.31632 7.11625 1.59998 7.9999 1.59998C8.88356 1.59998 9.5999 2.31632 9.5999 3.19998C9.5999 4.08363 8.88356 4.79998 7.9999 4.79998C7.11625 4.79998 6.3999 4.08363 6.3999 3.19998Z" />
      <path d="M6.3999 7.99998C6.3999 7.11632 7.11625 6.39998 7.9999 6.39998C8.88356 6.39998 9.5999 7.11632 9.5999 7.99998C9.5999 8.88363 8.88356 9.59998 7.9999 9.59998C7.11625 9.59998 6.3999 8.88363 6.3999 7.99998Z" />
      <path d="M7.9999 11.2C7.11625 11.2 6.3999 11.9163 6.3999 12.8C6.3999 13.6836 7.11625 14.4 7.9999 14.4C8.88356 14.4 9.5999 13.6836 9.5999 12.8C9.5999 11.9163 8.88356 11.2 7.9999 11.2Z" />
    </svg>
  );
};

export default MoreVerticalIcon;
