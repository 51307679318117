import { makeAutoObservable } from 'mobx';
import { ProjectService } from 'services';
import { Translator, definitionStore, projectStore, toastStore, usersStore } from 'stores';
import { ProjectState, ProjectStateNames, ProjectType, ProjectTypeNames } from 'shared/enums';
import { IExternalProjectsGetDto, IProject, IProjectsGetDto, ISelectOption, ISortProject, ITabItem } from 'shared/interfaces';
import { ProjectFiltersModel, ProjectListModel } from 'shared/models/ProjectModel';
import Utils from 'shared/utils/Utils';

class ProjectStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public isDialogOpen = false;

  public currentProjectId = '';

  public sourceProjectList: IProjectsGetDto[] = [];

  public projectsByType: ProjectListModel[] = [];

  public projectsByStandard: ISelectOption[] = [];

  public externalProjects: IExternalProjectsGetDto[] = [];

  public projectFilters = new ProjectFiltersModel();

  public selectedProject = new ProjectListModel();

  public currentSort: ISortProject = { key: 'createdAt', ascending: true };

  public availableProjectIds: number[] = [];

  public filterName = '';

  public isShowAvailableProjects = false;

  public get projectsForOptions(): ISelectOption[] {
    const sortingProjects = Utils.sortByField(this.projectsByType, 'name');
    return sortingProjects.map((item) => item.getSelectOption()!);
  }

  public get projectsWithDefsForOptions(): ISelectOption[] {
    const data: ISelectOption[] = [];

    this.projectsByType.forEach((project) => {
      project.definitions.forEach((definition) => {
        data.push({ id: definition.id!, name: `${project.name} / ${definition.name}` });
      });
    });

    return Utils.sortByField(data, 'name');
  }

  public get externalProjectsForOptions(): ISelectOption[] {
    return Utils.sortByField(
      this.externalProjects.map((item) => {
        return { id: item.id, name: item.name };
      }),
      'name'
    );
  }

  public get projectTypesForRadioGroup(): ITabItem[] {
    return Object.values(ProjectType).map((item) => {
      return {
        value: item,
        label: Translator.translate(ProjectTypeNames.get(item)),
      };
    });
  }

  public get projectStatesForOptions(): ISelectOption[] {
    return Object.values(ProjectState).map((item) => {
      return {
        id: item,
        name: ProjectStateNames.get(item)!,
      };
    });
  }

  public get projectsForFindDialog(): IProject[] {
    return projectStore.projectsByType
      .map((value: ProjectListModel) => {
        return { id: value.id, name: value.name } as IProject;
      })
      .filter((project) => usersStore.selectedUser.projects.find((value) => value.id === project.id) === undefined);
  }

  public get filterProjects(): ProjectListModel[] {
    const projects = this.projectsByType.slice();

    if (!this.filterName && !this.isShowAvailableProjects) {
      return projects;
    }

    const data = projects
      //фильтрация по доступным проектам
      .filter((project) => !this.isShowAvailableProjects || this.availableProjectIds.includes(project.id!))
      .map((project: ProjectListModel) => {
        const findEl = this.sourceProjectList.find((el) => el.id === project.id);
        if (!findEl) return project;

        const filterDef = findEl.definitions?.filter((p) => Utils.includes(p.name, this.filterName));

        const model = new ProjectListModel({
          id: findEl.id,
          name: findEl.name,
          type: findEl.type,
          state: findEl.state,
          address: findEl.address,
          createdAt: findEl.createdAt,
          definitions: filterDef,
          projectClassificationDictionaryValues: findEl.projectClassificationDictionaryValues,
        });

        if (model.definitions.length > 0 && this.filterName) {
          model.setCollapseRow(true);
        }

        return model;
      })
      .filter((project) => Utils.includes(project.name, this.filterName) || Boolean(project.definitions.length))
      .map((project) => {
        if (!project.definitions.length) {
          return projects.find((p) => p.id === project.id) ?? project;
        }

        return project;
      });

    return data;
  }

  public async setEditingMode(id: number | null) {
    if (id) await this.getProjectById(id);
  }

  public setFilterName(value: string) {
    this.filterName = value;
  }

  public setFilterType(type: ProjectType) {
    this.projectFilters.type = type;
  }

  public setOpenDialog(isOpen: boolean) {
    this.isDialogOpen = isOpen;
  }

  public setCurrentSort(sort: ISortProject) {
    this.currentSort = sort;
  }

  public setCurrentProjectId(value: string | null) {
    if (value !== null) {
      this.currentProjectId = value;
    }
  }

  public setAvailableProjects(ids: number[]) {
    this.availableProjectIds = ids;
  }

  public setShowAvailableProjects(value: boolean) {
    this.isShowAvailableProjects = value;
  }

  public async getProjectListByStandard(): Promise<void> {
    try {
      this.isLoading = true;
      const result = await ProjectService.postFilterProjects({
        type: ProjectType.Standard,
      });
      if (!result) return;

      this.projectsByStandard = Utils.sortByField(
        result.map((item) => {
          return { id: item.id, name: item.name };
        }),
        'name'
      );
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} ProjectsByStandard`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getProjectList(): Promise<void> {
    try {
      this.isLoading = true;

      const result = await ProjectService.postFilterProjects(this.projectFilters.projParamsDto);
      if (!result) return;

      this.sourceProjectList = result;

      this.projectsByType = result.map((dto) => new ProjectListModel(dto));

      const idx = this.projectsByType.findIndex((el) => el.id === Number(projectStore.currentProjectId));
      if (idx > -1) this.projectsByType[idx].setCollapseRow(true);
    } catch (e: any) {
      toastStore.showError(`${Translator.translate('stores.getListErrorMessage')} Projects`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getProjectById(id: number): Promise<void> {
    try {
      this.isLoading = true;
      const result = await ProjectService.postFilterProjects({ id });
      if (!result) return;

      this.selectedProject = new ProjectListModel(result[0]);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} Project`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getExternalProjects(): Promise<void> {
    try {
      this.isLoading = true;
      const result = await ProjectService.getExternalProjects();
      if (!result) return;

      this.externalProjects = result;
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} ExternalProjects`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createProject(): Promise<void> {
    let payload;
    if (this.selectedProject.type === ProjectType.Local && this.selectedProject.postPutLocalDto) payload = this.selectedProject.postPutLocalDto;
    if (this.selectedProject.type === ProjectType.Standard && this.selectedProject.postPutStandardDto)
      payload = this.selectedProject.postPutStandardDto;

    if (!payload) return;

    try {
      this.isLoading = true;
      await ProjectService.postProject(payload);
      await this.getProjectList();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} Project`);
    } finally {
      this.isLoading = false;
    }
  }

  public async copyProject(): Promise<void> {
    if (!this.selectedProject.copyDto) return;
    try {
      this.isLoading = true;
      await ProjectService.copyProject(this.selectedProject.copyDto);
      await this.getProjectList();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.copyErrorMessage')} Project`);
    } finally {
      this.isLoading = false;
    }
  }

  public async updateProject(): Promise<void> {
    let payload;
    if (this.selectedProject.id && this.selectedProject.type === ProjectType.Local && this.selectedProject.postPutLocalDto)
      payload = this.selectedProject.postPutLocalDto;
    if (this.selectedProject.id && this.selectedProject.type === ProjectType.Standard && this.selectedProject.postPutStandardDto)
      payload = this.selectedProject.postPutStandardDto;

    if (!payload) return;

    try {
      this.isLoading = true;
      await ProjectService.putProject(this.selectedProject.id!, payload);

      this.getProjectList();
      definitionStore.getRecentlyDefinitions();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} Project`);
    } finally {
      this.isLoading = false;
    }
  }

  public async removeProject() {
    if (!this.selectedProject.id) return;

    try {
      this.isLoading = true;
      await ProjectService.deleteProject(this.selectedProject.id);
      if (this.selectedProject.id === Number(this.currentProjectId)) {
        localStorage.removeItem('currentProjectId');
        this.currentProjectId = '';
      }

      this.selectedProject.clear();

      this.getProjectList();
      definitionStore.getRecentlyDefinitions();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} Project`);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new ProjectStore();
