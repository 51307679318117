import { ChangeEvent } from 'react';
import { makeAutoObservable } from 'mobx';
import { AuditService } from 'services';
import { Translator, toastStore, usersStore } from 'stores';
import { AuditTables } from 'shared/enums';
import { IAuditFilters, IAuditParams, IGetAuditDto } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';

class AuditStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public isDrawerOpen = false;

  public entityAuditData: IGetAuditDto[] = [];

  public auditFilters: IAuditFilters = { entityId: null, table: AuditTables.Definitions };

  public setAuditFilters(table: AuditTables, entityId: number) {
    this.auditFilters.entityId = entityId;
    this.auditFilters.table = table;
  }

  public setUserIdFilter(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.checked && Number(usersStore.getUserData().id) !== null) {
      this.auditFilters.userId = Number(usersStore.getUserData().id);
      this.getAuditData();
    } else {
      this.auditFilters.userId = null;
      this.getAuditData();
    }
  }

  public setDrawerOpen(isOpen: boolean) {
    this.isDrawerOpen = isOpen;
  }

  public async getAuditData(): Promise<void> {
    try {
      this.isLoading = true;
      const result = await AuditService.getAuditByEntity(this.auditFilters as IAuditParams);
      if (!result) return;

      result.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      this.entityAuditData = result;
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Audits`);
    } finally {
      this.isLoading = false;
    }
  }

  public clear() {
    this.entityAuditData = [];
  }
}

export default new AuditStore();
