import React from 'react';

const MergeAddIcon: React.FC = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="#12A44D" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 2C5.63181 2 5.33333 2.29848 5.33333 2.66667V5.33333H2.66667C2.29848 5.33333 2 5.63181 2 6C2 6.36819 2.29848 6.66667 2.66667 6.66667H5.33333V9.33333C5.33333 9.70152 5.63181 10 6 10C6.36819 10 6.66667 9.70152 6.66667 9.33333V6.66667H9.33333C9.70152 6.66667 10 6.36819 10 6C10 5.63181 9.70152 5.33333 9.33333 5.33333H6.66667V2.66667C6.66667 2.29848 6.36819 2 6 2Z" />
    </svg>
  );
};

export default MergeAddIcon;
