import { makeAutoObservable } from 'mobx';
import {
  IGetInformationBlockDto,
  IInfBlockParameterGroup,
  IInformationBlockFormDto,
  IPostInformationBlockDto,
  IPutInformationBlockDto,
} from 'shared/interfaces';

class InformationBlockModel {
  constructor(dto?: IGetInformationBlockDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.orderNum = dto.orderNum;
    this.parameterGroupConfigurations = dto.parameterGroupConfigurations.map((parameterGroup) => {
      return {
        id: parameterGroup.group.id,
        name: parameterGroup.group.name,
      };
    });
  }

  public id: number | null = null;

  public orderNum: number | null = null;

  public name = '';

  public parameterGroupConfigurations: IInfBlockParameterGroup[] = [];

  public get postDto(): IPostInformationBlockDto | null {
    if (this.name === '') return null;

    const parameterGroupConfigurations = this.parameterGroupConfigurations.map((parameterGroup) => {
      return {
        groupId: parameterGroup.id,
        includeChildren: true,
      };
    });

    return {
      name: this.name,
      parameterGroupConfigurations,
    };
  }

  public get putDto(): IPutInformationBlockDto | null {
    if (this.name === '' || !this.orderNum) return null;

    const parameterGroupConfigurations = this.parameterGroupConfigurations.map((parameterGroup) => {
      return {
        groupId: parameterGroup.id,
        includeChildren: true,
      };
    });

    return {
      name: this.name,
      orderNum: this.orderNum,
      parameterGroupConfigurations,
    };
  }

  public updateInformationBlockForm(data: IInformationBlockFormDto) {
    this.name = data.name;
  }

  public setName(name: string) {
    this.name = name;
  }

  public setOrderNum(orderNum: number) {
    this.orderNum = orderNum;
  }

  public addParamGroupConfigurations(checkedParamGroup: IInfBlockParameterGroup[]) {
    this.parameterGroupConfigurations = checkedParamGroup;
  }

  public deleteParamGroupConfigurations(idx: number) {
    this.parameterGroupConfigurations.splice(idx, 1);
  }

  public clear() {
    this.id = null;
    this.orderNum = null;
    this.name = '';
    this.parameterGroupConfigurations = [];
  }
}

export default InformationBlockModel;
