import React from 'react';

const CommentSendIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4446 10.8954C18.7845 10.7264 18.9995 10.3795 18.9995 9.9999C18.9995 9.62027 18.7845 9.27338 18.4446 9.10441L2.34943 1.10441C1.98194 0.921752 1.54016 0.982159 1.23519 1.25677C0.930223 1.53137 0.823988 1.96443 0.967251 2.34899L3.76939 9.87084C3.76394 9.91314 3.76112 9.95626 3.76112 10C3.76112 10.0438 3.76393 10.0869 3.76937 10.1291L0.967336 17.6509C0.824076 18.0355 0.930315 18.4685 1.23528 18.7431C1.54025 19.0177 1.98204 19.0781 2.34953 18.8955L18.4446 10.8954ZM5.5792 11L3.72459 15.9785L13.7407 11H5.5792ZM5.57927 9.00004H13.7412L3.72452 4.02132L5.57927 9.00004Z"
      />
    </svg>
  );
};

export default CommentSendIcon;
