import React from 'react';

const InfoIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.96729 11C5.96729 10.5858 6.30307 10.25 6.71729 10.25H7.25V7.75H6.73145C6.31723 7.75 5.98145 7.41421 5.98145 7C5.98145 6.58579 6.31723 6.25 6.73145 6.25H8C8.41421 6.25 8.75 6.58579 8.75 7V10.25H9.28271C9.69693 10.25 10.0327 10.5858 10.0327 11C10.0327 11.4142 9.69693 11.75 9.28271 11.75H6.71729C6.30307 11.75 5.96729 11.4142 5.96729 11Z" />
      <path d="M8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 8C1.25 4.27208 4.27208 1.25 8 1.25C11.7279 1.25 14.75 4.27208 14.75 8C14.75 11.7279 11.7279 14.75 8 14.75C4.27208 14.75 1.25 11.7279 1.25 8ZM8 2.75C5.10051 2.75 2.75 5.10051 2.75 8C2.75 10.8995 5.10051 13.25 8 13.25C10.8995 13.25 13.25 10.8995 13.25 8C13.25 5.10051 10.8995 2.75 8 2.75Z"
      />
    </svg>
  );
};

export default InfoIcon;
