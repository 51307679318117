import React from 'react';

const TextValueIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="rgba(169, 171, 185, 1)" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 3C2 2.44772 2.44772 2 3 2H13C13.5523 2 14 2.44772 14 3V5C14 5.55228 13.5523 6 13 6C12.4477 6 12 5.55228 12 5V4H9L9 12H10C10.5523 12 11 12.4477 11 13C11 13.5523 10.5523 14 10 14H6C5.44772 14 5 13.5523 5 13C5 12.4477 5.44772 12 6 12H7L7 4H4V5C4 5.55228 3.55228 6 3 6C2.44772 6 2 5.55228 2 5V3Z" />
    </svg>
  );
};

export default TextValueIcon;
