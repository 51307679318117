import { makeAutoObservable } from 'mobx';
import { IGetAllParameterGroupDto } from 'shared/interfaces';

class RootParameterGroupModel {
  constructor(dto?: IGetAllParameterGroupDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.fullName = dto.fullName;
    this.orderNum = dto.orderNum;
  }

  public id: number | null = null;

  public name = '';

  public fullName = '';

  public orderNum: number | null = null;

  public parentId = null;
}

export default RootParameterGroupModel;
