import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useDebounce } from 'usehooks-ts';
import { Translator, stageStore } from 'stores';
import { IconButtonNew, InputSearch, TableView } from 'components';
import { useConfirm } from 'components/App/Confirm';
import DownIcon from 'components/Icons/DownIcon';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import TrashIcon from 'components/Icons/TrashIcon';
import UpIcon from 'components/Icons/UpIcon';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { ITableColumn } from 'shared/interfaces';
import { StageModel } from 'shared/models';
import Utils from 'shared/utils/Utils';
import StageDialog from './Components';

import s from './Stages.module.scss';

export const HEIGHT_CONTENT = 148;

const Stages: React.FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentOrderNum, setCurrentOrderNum] = useState<number | null>(null);
  const debouncedValue = useDebounce<number | null>(currentOrderNum, 350);

  useEffect(() => {
    setIsLoading(true);
    stageStore.getStages().then(() => setIsLoading(false));

    return () => {
      setSearchValue('');
    };
  }, []);

  useEffect(() => {
    if (debouncedValue && currentOrderNum) {
      stageStore.selectedStage.setOrderNum(stageStore.selectedStage.orderNum! + currentOrderNum);

      stageStore.updateStage().then(() => {
        stageStore.selectedStage.clear();
        setCurrentOrderNum(null);
      });
    }
  }, [debouncedValue]);

  const confirm = useConfirm();

  const { stages, isDialogOpen } = stageStore;

  enum StageReorder {
    Up = 0,
    Down = 1,
  }

  const columns: ITableColumn[] = [
    {
      key: 'name',
      label: Translator.translate('catalogs.stages.columns.name'),
      width: '400px',
      align: 'left',
    },
    {
      key: 'actions',
      label: '',
      width: '50px',
      align: 'right',
      cell: (row: StageModel) => {
        const removeStage = () => {
          confirm.show(Translator.translate('catalogs.stages.confirmMessage.deleteStage'), Translator.translate('actions.delete')).then(() => {
            stageStore.removeStage(row.id!);
          });
        };

        const changeOrderStage = async (order: 1 | -1) => {
          if (!stageStore.selectedStage.id) {
            stageStore.selectedStage = row;
          }
          /*stageStore.selectedStage.setOrderNum(stageStore.selectedStage.orderNum! + order);

          updateStages();*/

          setCurrentOrderNum(currentOrderNum ? currentOrderNum + order : order);
        };

        const canReorder = (reorder: StageReorder): boolean => {
          const findEl = stages.find((f) => f.orderNum === row.orderNum);
          if (!findEl) return false;

          if (row.orderNum === stages[reorder === StageReorder.Up ? 0 : stages.length - 1].orderNum) return true;
          else return false;
        };

        return (
          <Stack
            flexDirection="row"
            gap={2}
            justifyContent="flex-end"
            sx={(theme) => ({
              'button svg': {
                ':hover': {
                  fill: theme.palette.primary.main,
                  fillOpacity: 1,
                },
              },
            })}>
            <IconButtonNew onClick={() => stageStore.setEditingMode(row.id!)} tooltip={Translator.translate('actions.edit')}>
              <EditPencilIcon />
            </IconButtonNew>
            <IconButtonNew onClick={removeStage} tooltip={Translator.translate('actions.delete')}>
              <TrashIcon />
            </IconButtonNew>
            <IconButtonNew onClick={() => changeOrderStage(-1)} disabled={canReorder(StageReorder.Up)} tooltip={Translator.translate('actions.up')}>
              <UpIcon />
            </IconButtonNew>
            <IconButtonNew
              onClick={() => changeOrderStage(1)}
              disabled={canReorder(StageReorder.Down)}
              tooltip={Translator.translate('actions.down')}>
              <DownIcon />
            </IconButtonNew>
          </Stack>
        );
      },
    },
  ];

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    if (event.target.value === '') {
      stageStore.getStages();
    }
  };

  const onClickSearch = () => {
    stageStore.getStagesByParameter(searchValue);
  };

  const onOpenCreateDialog = () => {
    stageStore.setDialogOpen(true);
  };

  return (
    <>
      {
        <Box className={s.toolBarWrapper}>
          <Box className={s.searchWrapper}>
            <InputSearch value={searchValue} onSearch={onClickSearch} onChange={onChangeSearch} />
          </Box>

          <Button variant="contained" startIcon={<PlusIconForButton />} onClick={onOpenCreateDialog}>
            {Translator.translate('actions.add')}
          </Button>
        </Box>
      }

      {isLoading ? (
        <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableView
          data={stages}
          columns={columns}
          isActionsHover
          hideSelectRowOnMount
          height={`calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight()}px)`}
        />
      )}

      {isDialogOpen && <StageDialog />}
    </>
  );
};

export default observer(Stages);
