import { makeAutoObservable, runInAction } from 'mobx';
import { arrayToTree } from 'performant-array-to-tree';
import { ItemService } from 'services';
import { Translator, classStore, definitionStore, parameterGroupStore, parameterValueStore, toastStore } from 'stores';
import { ObjectLayer } from 'shared/enums';
import { IItemDto, IItemList, ISearch } from 'shared/interfaces';
import { ItemModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

class ItemNewStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public waitingItem = false;

  public treeTestItem: ItemModel[] = [];

  public itemsDto: IItemDto[] = [];

  public listTransformItemDto: IItemList[] = [];

  public itemHasValuesInGroup: number[] = [];

  public parentsItemHasValuesInGroup: number[] = [];

  public itemHasValuesInSubGroup: number[] = [];

  public copyItemId: number | null = null;

  public expandedKeys: React.Key[] = [];

  public searchArrays: ISearch[] = [];

  public createItems: ItemModel[] = [];

  public searchValue = '';

  public clickSearch = false;

  public selectItem = new ItemModel();

  public isDialogCreateOpen = false;

  public isDialogEditOpen = false;

  public treeItemWidth: number | null = null;

  public setTreeItemWidth(value: number) {
    this.treeItemWidth = value;
  }

  public setIsDialogCreateOpen(value: boolean) {
    this.isDialogCreateOpen = value;
  }

  public setIsDialogEditOpen(value: boolean) {
    this.isDialogEditOpen = value;
  }

  public setWaitingItem(value: boolean) {
    this.waitingItem = value;
  }

  public get canDialogCreateSubmit() {
    if (
      classStore.classFilters.layerId === ObjectLayer.Main ||
      (classStore.classFilters.layerId === ObjectLayer.Additional && !this.selectItem.class?.layerId)
    ) {
      return this.createItems.some((it) => it.selected);
    }

    return this.selectItem.name !== '';
  }

  public setSelectItem(keys?: React.Key[], info?: any) {
    if (info.selectedNodes?.length !== 0) {
      this.selectItem = new ItemModel(info.selectedNodes[0]);
      parameterValueStore.filterStateParamValues.setItemId(info.selectedNodes[0].id);
    } else {
      this.selectItem = new ItemModel();
      parameterValueStore.filterStateParamValues.setItemId(null);
    }
    parameterGroupStore.getAllParameterGroupsHasValues();
  }

  public setCopyItemId(itemId: number) {
    this.copyItemId = itemId;
  }

  public setExpandedKeys(expandedKeys: React.Key[]) {
    this.expandedKeys = expandedKeys;
  }

  public updateExpandedKeys(expandedKeys: React.Key[]) {
    const expandedArray = [...this.expandedKeys];
    expandedKeys.forEach((id: React.Key) => {
      !expandedArray.includes(id) && expandedArray.push(id);
    });

    this.setExpandedKeys(expandedArray);
  }

  public setSearchArrays(searchArrays: ISearch[]) {
    this.searchArrays = searchArrays;
  }

  public setClickSearch(clickSearch: boolean) {
    this.clickSearch = clickSearch;
  }

  public setSearchValue(searchValue: string) {
    this.searchValue = searchValue;
  }

  public createItemTreeData(dto: IItemDto[]): IItemList[] {
    const transformDto: IItemList[] = [];

    dto.forEach((value) => {
      const newItem: IItemList = {
        key: String(value.id),
        title: value.name,

        id: value.id,
        parentId: value.parentId,
        name: value.name,
        isMaster: value.isMaster,
        hasComments: value.hasComments,
        class: value.class,
      };

      transformDto.push(newItem);
    });

    this.listTransformItemDto = transformDto;

    //TODO: на выходе этого метода - interface DataNode extends info {}
    const treeData = arrayToTree(transformDto, { dataField: null });

    // сортировка по title
    const loop = (data: any[]): IItemList[] =>
      data.map((item: any) => {
        if (item.children) {
          item.children = Utils.sortByField(item.children, 'title');
          return {
            ...item,
            children: loop(item.children),
          };
        }

        return {
          ...item,
        };
      });

    return loop(treeData);
  }

  public defaultExpandItems(shouldCollapseTree?: boolean) {
    if (shouldCollapseTree) {
      const expandedIds = Array.from(Utils.filterNodesIds(this.listTransformItemDto, 0));

      // по умолчанию делаем раскрытыми узлы первого уровня
      if (!this.itemHasValuesInSubGroup.length && !this.itemHasValuesInGroup.length) {
        this.setExpandedKeys(expandedIds.map((value) => String(value)));
      } else if (this.itemHasValuesInGroup.length === 1 && expandedIds[0] === this.itemHasValuesInGroup[0]) {
        this.setExpandedKeys(expandedIds.map((value) => String(value)));
      }
    }
    //нахождение всех родительских узлов для узлов, у которых в подгруппе есть значения, чтобы сделать expand родителей этих узлов
    this.itemHasValuesInSubGroup.length > 0 &&
      this.itemHasValuesInSubGroup.forEach((itemId) => {
        const nodeIds = Utils.getParentNodeIds(itemId, this.listTransformItemDto);
        nodeIds.length > 0 && this.updateExpandedKeys(nodeIds.map((_) => String(_)));
      });

    //нахождение всех родительских узлов для узлов, у которых в группе есть значения, чтобы выделить их цветом (индикация - есть во вложенных) и сделать expand
    this.itemHasValuesInGroup.length > 0 &&
      this.itemHasValuesInGroup.forEach((itemId) => {
        const nodeIds = Utils.getParentNodeIds(itemId, this.listTransformItemDto);

        nodeIds.forEach((id) => !this.parentsItemHasValuesInGroup.includes(id) && this.parentsItemHasValuesInGroup.push(id));
        nodeIds.length > 0 && this.updateExpandedKeys(nodeIds.map((_) => String(_)));
      });
  }

  public async getItemList(definitionId: number): Promise<void> {
    try {
      const result = await ItemService.getItems({
        definitionId,
      });
      if (!result) return;

      runInAction(() => {
        this.itemsDto = result;
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} ItemList`);
    } finally {
    }
  }

  public async getAllItems(definitionId?: number) {
    try {
      const result = await ItemService.getItems({
        definitionId: definitionStore.currentDefinitionId ? definitionStore.currentDefinitionId : definitionId!,
      });
      if (!result) return;

      this.treeTestItem = this.createItemTreeData(result).map((item) => new ItemModel(item));
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Items`);
    } finally {
    }
  }

  public async getAllItemsHasValues(): Promise<void> {
    if (!parameterValueStore.filterStateParamValues.parameterGroupId || !definitionStore.currentDefinitionId) {
      this.itemHasValuesInGroup = [];
      this.parentsItemHasValuesInGroup = [];
      this.itemHasValuesInSubGroup = [];
      return;
    }

    try {
      const result = await ItemService.getItemsHasValues(
        parameterValueStore.filterStateParamValues.parameterGroupId,
        definitionStore.currentDefinitionId
      );
      if (!result) return;

      this.itemHasValuesInGroup = result.inGroupItemIds;
      this.itemHasValuesInSubGroup = result.inSubGroupsItemIds;
      this.parentsItemHasValuesInGroup = [];
      this.defaultExpandItems(!this.expandedKeys.length);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} ItemsHasValues`);
    } finally {
    }
  }

  public async createItem(items: ItemModel[]): Promise<void> {
    try {
      if (
        classStore.classFilters.layerId === ObjectLayer.Main ||
        (classStore.classFilters.layerId === ObjectLayer.Additional && !this.selectItem.class?.layerId)
      ) {
        const promises = items.map((item) => {
          const result = ItemService.postItem(item.postDto()!);
          return result;
        });

        const results = await Promise.allSettled(promises).finally(() => this.getAllItems());

        const rejectStatus: any = results.filter((f) => f.status === 'rejected');
        if (rejectStatus.length !== 0) {
          rejectStatus.forEach((value: any) => toastStore.showError(value.reason.data.message));
        }
      } else {
        await ItemService.postItem(this.selectItem.postDto()!);
        this.getAllItems();
      }
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} Item`);
    } finally {
    }
  }

  public async updateItem(): Promise<void> {
    if (!this.selectItem.putDto) return;

    try {
      await ItemService.putItem(this.selectItem.putDto!.id, this.selectItem.putDto!);
      await this.getAllItems();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} Item`);
    }
  }

  public async copyItem(copyChildren: boolean): Promise<void> {
    if (!this.copyItemId) return;
    try {
      await ItemService.postCopyItem(this.copyItemId, { copyChildren, parentId: this.selectItem.id });
      this.getAllItems();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.copyErrorMessage')} Item`);
    } finally {
      this.copyItemId = null;
    }
  }

  public async deleteItem(): Promise<void> {
    try {
      await ItemService.deleteItem(this.selectItem.id!);
      await this.getAllItems();
      if (!this.listTransformItemDto.find((f) => f.id === this.selectItem.id)) {
        if (this.treeTestItem.length !== 0) {
          const findEl = this.listTransformItemDto.find((item) => item.id === Number(this.treeTestItem[0].key));
          if (findEl) {
            parameterValueStore.filterStateParamValues.setItemId(findEl?.id);
            this.selectItem = new ItemModel(findEl);
          }
        } else {
          parameterValueStore.filterStateParamValues.setItemId(null);
          this.selectItem = new ItemModel();
        }
      }
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} Item`);
    } finally {
    }
  }
}

export default new ItemNewStore();
