import { DataType } from 'shared/enums';
import { IEditableTableColumn } from 'shared/interfaces';

export const getColumnDataType = (column: IEditableTableColumn, row: any): DataType => {
  if (column.dataType) {
    if (typeof column.dataType === 'string') {
      return column.dataType;
    }

    if (typeof column.dataType === 'function') {
      return column.dataType(row);
    }
  }

  return DataType.String;
};

export const isColumnEditable = (column: IEditableTableColumn, row: any): boolean => {
  if (column.hasOwnProperty('editable')) {
    if (typeof column.editable === 'function') {
      return column.editable(row);
    }

    return column.editable || column.editable === undefined;
  }

  return true;
};
