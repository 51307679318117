import React, { useEffect, ChangeEvent, useState } from 'react';
import {
  GridCellCheckboxRenderer,
  GridColumns,
  GridHeaderCheckbox,
  GridRowParams,
  GridCellParams,
  GridColumnHeaderParams,
} from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { Translator, sectionParameterStore, sectionsStore } from 'stores';
import { CustomDataGrid, BindingParametersSectionTable, InputSearch } from 'components';
import { useConfirm } from 'components/App/Confirm';
import { Box, Collapse, Stack, Typography } from '@mui/material';
import { NestedLevel } from 'shared/enums';
import { INestedSection, ITableColumn } from 'shared/interfaces';
import { SectionParamsModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

const HEIGHT_CONTENT = 96;
const HEIGHT_PARAMETER_CONTENT = 196;

const SpecificationSections: React.FC = () => {
  const confirm = useConfirm();

  const columns: ITableColumn[] = [
    {
      key: 'collapse',
      label: '',
      align: 'left',
      calcWidth: (row: INestedSection) => (row.level === NestedLevel.First ? '30px' : row.level === NestedLevel.Second ? '40px' : '50px'),
    },
    {
      key: 'name',
      label: Translator.translate('specifications.specificationSections.columns.name'),
      align: 'left',
      width: 'auto',
    },
  ];

  const columnsParameter: GridColumns = [
    {
      field: '__check__',
      type: 'checkboxSelection',
      width: 10,
      minWidth: 35,
      resizable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderHeader: (params) =>
        sectionParameterStore.sectionParamsFilteredDataGrid.length > 0 && (
          <Stack justifyContent="center">
            <GridHeaderCheckbox {...params} />
            <Typography variant="button" style={{ position: 'absolute', left: '40px', fontSize: '13px' }}>
              {Translator.translate('specifications.specificationSections.selectAll')}
            </Typography>
          </Stack>
        ),
      renderCell: (params) => <GridCellCheckboxRenderer {...params} />,
    },
    {
      field: 'name',
      headerName: '',
      flex: 1,
      hideSortIcons: true,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
  ];

  useEffect(() => {
    return () => {
      sectionParameterStore.sectionParams = [];
      sectionParameterStore.initSectionParameters = [];
      sectionsStore.setSelectedSectionRow(null);
      sectionsStore.setOpenParameters(false);
    };
  }, []);

  useEffect(() => {
    sectionParameterStore.getSpecSectionParameters();
  }, [sectionsStore.isOpenParameters]);

  const onSelectRowSection = (selectedRow: INestedSection) => {
    sectionsStore.setOpenParameters(true);

    if (!sectionParameterStore.isHasChanged && sectionsStore.selectedSectionRow !== selectedRow.id) {
      sectionsStore.setSelectedSectionRow(selectedRow.id);
      sectionParameterStore.getSpecSectionParameters();
    }

    if (sectionParameterStore.isHasChanged) {
      confirm
        .show(Translator.translate('specifications.specificationSections.confirmMessage.saveChanges'), Translator.translate('actions.save'), true)
        .then(() => {
          onSubmit();
        })
        .catch(() => {
          onCancel();
        });
    }
  };

  const onExceptionAction = () => {
    if (sectionParameterStore.isHasChanged) {
      confirm
        .show(Translator.translate('specifications.specificationSections.confirmMessage.saveChanges'), Translator.translate('actions.save'), true)
        .then(() => {
          onSubmit();
          sectionsStore.setOpenParameters(false);
        })
        .catch(() => {
          sectionsStore.setOpenParameters(false);
        });
    } else {
      sectionsStore.setOpenParameters(false);
    }
  };

  const onClickSearch = () => {
    console.log('1');
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    sectionParameterStore.setFilterName(event.target.value);
  };

  const onHeaderClick = (params: GridColumnHeaderParams, event: ChangeEvent<HTMLInputElement>) => {
    if (params.field !== '__check__') return;
    sectionParameterStore.sectionParams.forEach((_) => {
      _.setChecked(event.target.checked);
    });
  };

  const onCellClick = (params: GridCellParams) => {
    const { row }: { row: SectionParamsModel } = params;

    if (params.field !== columnsParameter[0].field || row.parameterIds === null) return;

    if (row.isGroup) {
      const selectedIDs = new Set(row.parameterIds);
      const selectedRows = sectionParameterStore.sectionParams.filter((row) => selectedIDs.has(row.id!));
      selectedRows.forEach((_) => {
        _.setChecked(!row.checked);
      });
    }

    const findParam = sectionParameterStore.sectionParams.find((parameter) => row.id === parameter.id);
    if (findParam) {
      findParam.setChecked(!findParam.checked);
    }

    const isAllParameterChecked = sectionParameterStore.sectionParams
      .filter((f) => f.groupId === row.groupId)
      .every((parameter) => parameter.checked);

    sectionParameterStore.sectionParams.find((f) => f.id === row.groupId)?.setChecked(isAllParameterChecked);
  };

  const onSubmit = () => {
    sectionParameterStore.saveParametersSpecSection();
  };

  const onCancel = () => {
    sectionParameterStore.getSpecSectionParameters();
  };

  return (
    <BindingParametersSectionTable
      sectionTableData={sectionsStore.nestedSections}
      columns={columns}
      selectedSectionRow={sectionsStore.selectedSectionRow}
      tableHeight={`calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight()}px)`}
      loading={sectionsStore.isLoading}
      isOpenExceptionParameter={sectionsStore.isOpenParameters}
      collapseBtnTitle={Translator.translate('sections.excludedParameters')}
      isShowActions={
        sectionParameterStore.sectionParamsFilteredDataGrid.length !== 0 ||
        (sectionParameterStore.sectionParamsFilteredDataGrid.length === 0 && sectionParameterStore.filterName !== '')
      }
      disabledActions={sectionParameterStore.isLoading}
      onChangeExceptionAction={onExceptionAction}
      onSectionSelectRow={onSelectRowSection}
      onSubmit={onSubmit}
      onCancel={onCancel}>
      <Collapse
        sx={{
          height: '100%',
          '.MuiCollapse-wrapperInner': {
            width: '100%',
          },
        }}
        collapsedSize="200px"
        orientation="horizontal"
        in={sectionsStore.isOpenParameters}>
        {sectionsStore.selectedSectionRow ? (
          <Stack gap={3}>
            {(sectionParameterStore.sectionParamsFilteredDataGrid.length !== 0 ||
              (sectionParameterStore.sectionParamsFilteredDataGrid.length === 0 && sectionParameterStore.filterName !== '')) && (
              <Stack flexDirection="row" alignItems="center">
                <InputSearch fullWidth value={sectionParameterStore.filterName} onSearch={onClickSearch} onChange={onSearch} />
              </Stack>
            )}

            <Box sx={{ height: `calc(100vh - ${HEIGHT_PARAMETER_CONTENT + Utils.projectHeight()}px)`, width: '100%' }}>
              <CustomDataGrid
                rows={sectionParameterStore.sectionParamsFilteredDataGrid}
                isRowSelectable={(params: GridRowParams) => params.row.parameterIds}
                columns={columnsParameter}
                disableColumnMenu
                onCellClick={onCellClick}
                onColumnHeaderClick={(params, event: any) => onHeaderClick(params, event as ChangeEvent<HTMLInputElement>)}
                selectionModel={sectionParameterStore.sectionParamsFilteredDataGrid.filter((_) => _.checked).map((_) => _.id!)}
                loading={sectionParameterStore.isLoading}
                hasFiltered={sectionParameterStore.filterName !== ''}
              />
            </Box>
          </Stack>
        ) : (
          <Stack alignItems="center" pt={22} height="100%">
            <img style={{ alignSelf: 'center' }} src={`${process.env.PUBLIC_URL}/img/not_selected_data.svg`} alt="" />
            <Typography variant="subtitle1" color="text.disabled">
              {Translator.translate('specifications.specificationSections.selectSection')}
            </Typography>
          </Stack>
        )}
      </Collapse>
    </BindingParametersSectionTable>
  );
};

export default observer(SpecificationSections);
