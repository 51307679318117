import { GridRowsProp } from '@mui/x-data-grid-pro';
import { makeAutoObservable, runInAction } from 'mobx';
import { GlobalSettingService, ParameterService } from 'services';
import { Translator, parameterStore, toastStore } from 'stores';
import { DocumentSettingType } from 'shared/enums';
import { IAutocompleteParamDocSetting, IFilteredParamSettingByType, IGetGlobalSettingDto, IParameterSettings } from 'shared/interfaces';
import { GlobalSettingModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

class GlobalSettingStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public isDialogOpen = false;

  public globalSettings: IGetGlobalSettingDto[] = [];

  public parameterSettings: IParameterSettings[] = [];

  public paramsIncludeCurrentType: IFilteredParamSettingByType[] = [];

  public selectedGlobalSetting = new GlobalSettingModel();

  public filterName = '';

  public get paramSettingsFilteredDataGrid(): GridRowsProp {
    const groupIds = this.parameterSettings
      .filter((parameter) => parameter.fullName.toLowerCase().includes(this.filterName.toLowerCase()))
      .map((data) => data.groupId!);

    const uniqueGroupIds = new Set(groupIds);

    return this.parameterSettings.filter(
      (parameter) => parameter.fullName.toLowerCase().includes(this.filterName.toLowerCase()) || uniqueGroupIds.has(parameter.id!)
    );
  }

  public get filterParamsForAutocomplete(): IAutocompleteParamDocSetting[] {
    return parameterStore.paramDocSetForAutocomplete.filter(
      (f) => !this.paramsIncludeCurrentType.find((param: IFilteredParamSettingByType) => param.id === f.id)
    );
  }

  public setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  public setOpenDialog(isDialogOpen: boolean) {
    this.isDialogOpen = isDialogOpen;
  }

  public setFilterName(value: string) {
    this.filterName = value;
  }

  public async updateValue(value: string) {
    if (this.selectedGlobalSetting.value !== Number(value)) {
      this.selectedGlobalSetting.setValue(value);
      await this.updateGlobalSetting();
    }
  }

  public async getGlobalSettings(): Promise<void> {
    try {
      this.setLoading(true);
      const result = await GlobalSettingService.getGlobalSettings();
      if (!result) return;

      runInAction(() => {
        this.globalSettings = Utils.sortByField(result, 'id');
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} GlobalSettings`);
    } finally {
      this.setLoading(false);
    }
  }

  public async getGlobalSettingById(id: number): Promise<void> {
    try {
      this.setLoading(true);
      const result = await GlobalSettingService.getGlobalSetting(id);

      if (!result || result.length === 0) return;

      runInAction(() => {
        this.selectedGlobalSetting = new GlobalSettingModel(result[0]);
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} GlobalSetting`);
    } finally {
      this.setLoading(false);
    }
  }

  public async updateGlobalSetting(): Promise<void> {
    if (!this.selectedGlobalSetting.id || !this.selectedGlobalSetting.putDto) return;

    try {
      this.setLoading(true);
      await GlobalSettingService.putGlobalSetting(this.selectedGlobalSetting.id, this.selectedGlobalSetting.putDto);
      this.selectedGlobalSetting.clear();
      this.getGlobalSettings();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} GlobalSetting`);
    } finally {
      this.setLoading(false);
    }
  }

  public async getParameterDocSettings(settingsType: DocumentSettingType): Promise<void> {
    this.isLoading = true;

    try {
      const result = await ParameterService.getParameterDocSettings(settingsType);

      if (!result) return;

      const res: IParameterSettings[] = [];

      this.paramsIncludeCurrentType = [];

      result.forEach((el) => {
        res.push({
          hierarchy: [el.fullName],
          id: el.id,
          name: el.fullName,
          fullName: el.fullName,
          isGroup: true,
          documentSettings: [],
        });

        el.parameters.forEach((item) => {
          res.push({
            hierarchy: [el.fullName, item.name],
            groupId: el.id,
            name: item.name,
            fullName: item.fullName,
            id: item.id,
            isGroup: false,
            documentSettings: item.documentSettings,
          });

          if (item.documentSettings.includes(settingsType)) {
            this.paramsIncludeCurrentType.push({
              fullName: item.name,
              id: item.id,
              documentSettings: item.documentSettings,
            });
          }
        });
      });

      runInAction(() => {
        this.parameterSettings = res;
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} ParametersSection`);
    } finally {
      this.isLoading = false;
    }
  }

  public async changeParameterDocSettings(id: number, settings: DocumentSettingType[], settingsType: DocumentSettingType): Promise<void> {
    try {
      this.isLoading = true;
      await ParameterService.patchParameterDocSettings(id, { settings: settings });
      this.getParameterDocSettings(settingsType);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} Stage`);
    } finally {
      this.isLoading = false;
    }
  }

  public clearGlobalSettings() {
    this.globalSettings = [];
  }

  public clearParameterSettings() {
    this.parameterSettings = [];
  }
}

export default new GlobalSettingStore();
