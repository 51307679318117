import React, { forwardRef } from 'react';
import Tree from 'rc-tree';
import { NodeMouseEventParams } from 'rc-tree/lib/contextTypes';
import { Key } from 'rc-tree/lib/interface';
import { Translator } from 'stores';
import { Box, Tooltip } from '@mui/material';

import s from './TreeData.module.scss';

const arrowPath = 'M7.50013 4.5L6.44263 5.5575L9.87763 9L6.44263 12.4425L7.50013 13.5L12.0001 9L7.50013 4.5Z';

interface ITreeProps {
  treeData: any[];
  expandedKeys?: Key[];
  checkedKeys?:
    | Key[]
    | {
        checked: Key[];
        halfChecked: Key[];
      };
  selectedKey?: Key[];
  height?: number;
  disabledPropName?: string;
  itemHeight?: number;
  isCheckStrictly: boolean;
  isExpandToolTip?: boolean;
  onExpand?: (expandedKeysValue: React.Key[]) => void;
  onSelect?: (keys: React.Key[], info?: any) => void;
  onCheck?: (checkedKeysValue: any, info: any) => void;
  onHover?: (key: string | null) => void;
}

const TreeData = forwardRef<any, ITreeProps>((props, ref) => {
  const {
    treeData,
    expandedKeys,
    checkedKeys,
    selectedKey,
    isCheckStrictly,
    isExpandToolTip = false,
    disabledPropName,
    onExpand,
    onSelect,
    onCheck,
    onHover,
  } = props;

  const getSvgIcon = (path: any, iStyle = {}, style = {}, isExpand: boolean, disabled?: boolean) => (
    <Tooltip
      enterDelay={0}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -6],
            },
          },
        ],
      }}
      arrow
      title={isExpandToolTip ? (isExpand ? Translator.translate('actions.collapse') : Translator.translate('actions.expand')) : ''}>
      <i style={iStyle} className="rc-tree-switcher-icon">
        <svg viewBox="0 0 18 18" width="18" height="18" fill="none" style={{ verticalAlign: '-.125em', ...style }}>
          <path d={path} fill="#0F0B1F" fillOpacity={disabled ? '0.12' : '0.87'} />
        </svg>
      </i>
    </Tooltip>
  );

  const switcherIcon = (obj: any) => {
    const disabled: boolean = disabledPropName && Object.prototype.hasOwnProperty.call(obj.data, disabledPropName) && obj.data[disabledPropName];

    if (!obj.isLeaf) {
      return getSvgIcon(
        arrowPath,
        { cursor: 'pointer', backgroundColor: 'unset' },
        { transform: `rotate(${obj.expanded ? 90 : 0}deg)` },
        obj.expanded,
        disabled
      );
    }
  };

  const isCheckable = checkedKeys !== undefined && onCheck !== undefined;
  const expandProps = expandedKeys && onExpand ? { expandedKeys: expandedKeys, onExpand: onExpand } : { defaultExpandAll: true };

  return (
    <Box
      sx={{
        height: '100%',
        '.rc-tree-treenode': {
          ':hover': {
            backgroundColor: 'action.hover',
            borderRadius: '4px',
          },
        },
      }}>
      <Tree
        className={s.treeWrapper}
        treeData={treeData}
        ref={ref}
        selectedKeys={selectedKey}
        checkedKeys={checkedKeys}
        icon={<span className={s.customizeIcon} />}
        switcherIcon={switcherIcon}
        onSelect={onSelect}
        onCheck={onCheck}
        checkable={isCheckable}
        height={props.height}
        itemHeight={props.itemHeight}
        checkStrictly={isCheckStrictly}
        onMouseEnter={onHover ? (info: NodeMouseEventParams<any, HTMLSpanElement>) => onHover(info.node.key) : undefined}
        onMouseLeave={onHover ? () => onHover(null) : undefined}
        {...expandProps}
      />
    </Box>
  );
});
TreeData.displayName = 'TreeData';

export default TreeData;
