import React from 'react';

const CatalogIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2.25C1.58579 2.25 1.25 2.58579 1.25 3V15C1.25 15.4142 1.58579 15.75 2 15.75H8C8.69036 15.75 9.25 16.3096 9.25 17C9.25 17.4142 9.58579 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17C10.75 16.3096 11.3096 15.75 12 15.75H18C18.4142 15.75 18.75 15.4142 18.75 15V3C18.75 2.58579 18.4142 2.25 18 2.25H13C11.7733 2.25 10.6842 2.83901 10 3.74964C9.31583 2.83901 8.2267 2.25 7 2.25H2ZM9.25 6C9.25 4.75736 8.24264 3.75 7 3.75H2.75V14.25H8C8.45011 14.25 8.87497 14.3581 9.25 14.5499V6ZM10.75 14.5499C11.125 14.3581 11.5499 14.25 12 14.25H17.25V3.75H13C11.7574 3.75 10.75 4.75736 10.75 6V14.5499Z"
      />
    </svg>
  );
};

export default CatalogIcon;
