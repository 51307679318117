import { BASE_PATH } from 'shared/constants/constants';

import {
  IPostApprovalRowSuggestionDto,
  IPutApprovalRowSuggestionDto,
  IPutRowSuggestionSetAcceptedDto,
  IPutRowSuggestionSetAllAcceptedDto,
} from 'shared/interfaces';
import HTTPService from './HttpService';

class ApprovalRowSuggestionService extends HTTPService {
  constructor() {
    super(BASE_PATH.APPROVAL_ROW_SUGGESTIONS);
  }

  public postApprovalRowSuggestion(body: IPostApprovalRowSuggestionDto): Promise<number> {
    return this.POST('', body);
  }

  public putApprovalRowSuggestion(id: number, body: IPutApprovalRowSuggestionDto) {
    return this.PUT(`${id}`, body);
  }

  public putRowSuggestionSetAccepted(body: IPutRowSuggestionSetAcceptedDto): Promise<void> {
    return this.PUT('set-accepted', body);
  }

  public putRowSuggestionSetAllAccepted(body: IPutRowSuggestionSetAllAcceptedDto): Promise<number[]> {
    return this.PUT('set-all-acceptance', body);
  }

  public deleteApprovalRowSuggestion(id: number): Promise<void> {
    return this.DELETE(`${id}`);
  }

  public putRowSuggestionDenyAll(approvalRowId: number): Promise<void> {
    return this.PUT('deny-all', { approvalRowId });
  }
}

export default new ApprovalRowSuggestionService();
