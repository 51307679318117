import { makeAutoObservable } from 'mobx';
import { DataType, MergeState } from 'shared/enums';
import { IParameterChangeDto } from 'shared/interfaces';

/** Изменение значений параметров */
class ParameterValueMergeTableModel {
  constructor(dto?: IParameterChangeDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.parameterName = dto.parameterName;
    this.itemName = dto.item.fullName ?? '';
    this.currentValue = dto.currentValue;
    this.newValue = dto.newValue;
    this.state = dto.state;
    this.dataType = dto.dataType;
  }

  public id: number | null = null;

  public parameterName = '';

  public itemName = '';

  public currentValue: string | null = null;

  public newValue: string | null = null;

  public state = MergeState.Added;

  public dataType = DataType.String;

  public checked = false;

  public setChecked(checked: boolean) {
    this.checked = checked;
  }

  public clearModel() {
    this.id = null;
    this.parameterName = '';
    this.checked = false;
    this.itemName = '';
    this.currentValue = null;
    this.newValue = null;
    this.state = MergeState.Added;
    this.dataType = DataType.String;
  }
}

export default ParameterValueMergeTableModel;
