import React from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, usersStore } from 'stores';
import { CustomDatePicker, IconButtonNew } from 'components';
import TrashIcon from 'components/Icons/TrashIcon';
import { Autocomplete, TextField, Stack } from '@mui/material';
import { ISelectOption } from 'shared/interfaces';
import { ApprovalUserModel } from 'shared/models/ApprovalModel';

interface IUserDateSelectProps {
  widthUserField?: string;
  value: ApprovalUserModel;
  label?: string;
  showDate?: boolean;
  onDelete?: () => void;
  deleteDisabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  users?: ISelectOption[];
  dateValidation?: (value: Date) => boolean; // Returns false if the date correct, true otherwise
  onBlurUser?: (e: any) => void;
  onChangeUser: (userId: number) => void;
  onBlurExpiration?: (value: Date | string) => void;
}

const UserDateSelect: React.FC<IUserDateSelectProps> = (props) => {
  const onChangeUser = (value: ISelectOption) => {
    props.onChangeUser(Number(value.id));
  };

  return (
    <Stack flexDirection="row" width="auto" alignItems="center" gap={4}>
      <Autocomplete
        size="small"
        options={props.users ?? usersStore.usersForOptions}
        openOnFocus
        disableClearable
        readOnly={props.readOnly}
        noOptionsText={Translator.translate('approvals.detailApprovalPage.userDateSelect.userNotFound')}
        getOptionLabel={(option) => option.name}
        value={(props.users ?? usersStore.usersForOptions).find((f) => f.id === props.value.userId)}
        onChange={(e, value) => onChangeUser(value!)}
        clearOnBlur={false}
        sx={{ width: !props.widthUserField ? '250px' : props.widthUserField }}
        onBlur={props.onBlurUser}
        renderInput={(params) => <TextField {...params} required={props.required} label={props.label} />}
      />

      {props.showDate && props.onBlurExpiration && (
        <CustomDatePicker
          value={props.value.expiration!}
          onSubmit={props.onBlurExpiration}
          submitOnBlur
          label={Translator.translate('approvals.detailApprovalPage.userDateSelect.expiration')}
          outline
          size="small"
          error={props.dateValidation ? props.dateValidation(props.value.expiration!) : false}
          readOnly={props.readOnly}
          sx={{ width: '160px' }}
        />
      )}

      {props.onDelete && (
        <IconButtonNew
          tooltip={Translator.translate('actions.delete')}
          onClick={props.onDelete}
          disabled={props.deleteDisabled}
          sx={(theme) => ({
            svg: {
              ':hover': {
                fillOpacity: 1,
                fill: theme.palette.primary.main,
              },
            },
          })}>
          <TrashIcon />
        </IconButtonNew>
      )}
    </Stack>
  );
};

export default observer(UserDateSelect);
