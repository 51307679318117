import { makeAutoObservable } from 'mobx';
import { DataType, DocumentSettingType } from 'shared/enums';
import { ICreateParameterCommandDto, IGetDocumentDto, IParameterByIdDto, IParameterDialog, IPutParameterDto } from 'shared/interfaces';

class ParameterModel {
  constructor(dto?: IParameterByIdDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.orderNum = dto.orderNum;
    this.name = dto.name;
    this.fullName = dto.fullName;
    this.description = dto.description;
    this.dataType = dto.dataType;
    this.isArchived = dto.isArchived;
    this.groupId = dto.groupId;
    this.measurementUnitId = dto.measurementUnit === null ? null : dto.measurementUnit.id;
    this.documentSettings = dto.documentSettings;
    this.documents = dto.documents;
  }

  public id: number | null = null;

  public name = '';

  public fullName = '';

  public groupId: number | null = null;

  public documents: IGetDocumentDto[] = [];

  public orderNum: number | null = null;

  public description: string | null = '';

  public dataType: DataType = DataType.String;

  public isArchived = false;

  public measurementUnitId: number | null = null;

  public documentSettings: DocumentSettingType[] = [];

  public measurementUnitName = '';

  public currentEditingColumn = '';

  public updateParameterDialogForm(data: IParameterDialog) {
    this.name = data.name;
    this.description = data.description;
    this.measurementUnitId = data.measurementUnitId;
    this.dataType = data.dataType;
  }

  public setId(id: number | null) {
    this.id = id;
  }

  public setName(name: string) {
    this.name = name;
  }

  public setFullName(name: string) {
    this.fullName = name;
  }

  public setGroupId(groupId: number | null) {
    this.groupId = groupId;
  }

  public setOrderNum(orderNum: number | null) {
    this.orderNum = orderNum;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public setDataType(dataType: DataType) {
    this.dataType = dataType;
  }

  public setMeasurementUnitId(measurementUnitId: number | null) {
    this.measurementUnitId = measurementUnitId;
  }

  public setDocSettings(docSettings: DocumentSettingType[]) {
    this.documentSettings = docSettings;
  }

  public setMeasurementUnitName(measurementUnitName: string) {
    this.measurementUnitName = measurementUnitName;
  }

  public setCurrentEditingColumn(currentEditingColumn: string) {
    this.currentEditingColumn = currentEditingColumn;
  }

  public get postDto(): ICreateParameterCommandDto | null {
    if (!this.groupId || this.name === '' || !this.dataType || !this.orderNum) return null;

    return {
      name: this.name ?? '',
      dataType: this.dataType,
      orderNum: this.orderNum,
      groupId: this.groupId,
      description: this.description ?? '',
      measurementUnitId: this.measurementUnitId ?? null,
    };
  }

  public get putDto(): IPutParameterDto | null {
    if (this.id === null || this.groupId === null || this.orderNum === null) return null;

    return {
      id: this.id,
      orderNum: this.orderNum,
      name: this.name?.trim(),
      dataType: this.dataType,
      description: this.description,
      groupId: this.groupId,
      measurementUnitId: this.measurementUnitId,
      documentSettings: this.documentSettings,
      parameterDocuments: this.documents.map((_) => {
        return {
          documentId: _.id,
          documentPage: _.documentPage,
        };
      }),
    };
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.fullName = '';
    this.documents = [];
    this.orderNum = 0;
    this.dataType = DataType.String;
    this.description = '';
    this.isArchived = false;
    this.groupId = null;
    this.measurementUnitId = null;
    this.measurementUnitName = '';
    this.currentEditingColumn = '';
    this.documentSettings = [];
  }
}

export default ParameterModel;
