import React from 'react';

const NotFoundFileTypeIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z" fill="#E9E9F4" />
      <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#A9ABB9" />
      <path d="M30 14L24 8H30V14Z" fill="#7B68EE" fillOpacity="0.08" />
    </svg>
  );
};

export default NotFoundFileTypeIcon;
