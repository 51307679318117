import { DependencyList, EffectCallback, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { XmlDocumentInstanceService } from 'services';
import { toastStore, Translator, xmlDocumentInstanceStore } from 'stores';
import { Select } from 'components';
import ArrowLeftIcon from 'components/Icons/ArrowLeftIcon';
import DownloadIcon from 'components/Icons/DownloadIcon';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import { Box, Button, Paper, SelectChangeEvent, Skeleton, Stack, Typography } from '@mui/material';
import { XMLDocumentType } from 'shared/enums';
import { Routes } from 'shared/enums/Routes';
import Utils from 'shared/utils/Utils';
import XMLDialog from './Components';

const watchParam = (id: string | undefined, request: (id: number) => void): [EffectCallback, DependencyList] => [
  () => {
    if (id) {
      request.call(xmlDocumentInstanceStore, Number(id));
    }
  },
  [id],
];

interface IXMLPageProps {
  mode: XMLDocumentType;
}

const XMLPage: React.FC<IXMLPageProps> = ({ mode }) => {
  const { xmlDocumentId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (mode === XMLDocumentType.DefinitionInstance) {
      xmlDocumentInstanceStore.initFilterState();
    }

    return () => {
      xmlDocumentInstanceStore.filterXmlDocuments.clear();
      xmlDocumentInstanceStore.selectedXMLDocument.clearModel();
    };
  }, []);

  useEffect(() => {
    if (xmlDocumentInstanceStore.documentForOptions.length > 0 && xmlDocumentInstanceStore.documentForOptions[0].id) {
      navigate(`${xmlDocumentInstanceStore.documentForOptions[0].id}`);
    }
  }, [xmlDocumentInstanceStore.documentForOptions]);

  useEffect(...watchParam(xmlDocumentId, xmlDocumentInstanceStore.getXmlDocument));

  const onChangeCurrentDocument = (e: SelectChangeEvent<number>) => {
    navigate(`${e.target.value}`);
  };

  const emptyData = (): JSX.Element => (
    <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
      <img style={{ alignSelf: 'center' }} src={`${process.env.PUBLIC_URL}/img/empty_data.svg`} alt="" />
      <Typography variant="subtitle1" color="text.disabled">
        {Translator.translate('system.emptyData')}
      </Typography>
    </Stack>
  );

  const onDownloadXMLFile = () => {
    if (!xmlDocumentId) return;

    XmlDocumentInstanceService.downloadXMLFile(Number(xmlDocumentId))
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp as any]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${xmlDocumentInstanceStore.selectedXMLDocument.name}.xml`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e: any) => {
        Utils.parseBlobError(e, `${Translator.translate('stores.getListErrorMessage')} downloadDocument`).then(toastStore.showError);
      });
  };

  return (
    <>
      {mode === XMLDocumentType.Template && (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" gap={3} alignItems="center">
              <Link style={{ display: 'flex', alignItems: 'center' }} to={`${Routes.CATALOG}/${Routes.XML_DOCUMENT}`}>
                <ArrowLeftIcon />
              </Link>

              <Stack flexDirection="row" justifyContent="flex-start" alignItems="center" gap={2}>
                {xmlDocumentInstanceStore.isLoading || xmlDocumentInstanceStore.isLoading ? (
                  <Box sx={{ width: 100 }}>
                    <Skeleton />
                  </Box>
                ) : (
                  <Typography variant="h5">{xmlDocumentInstanceStore.selectedXMLDocument?.name}</Typography>
                )}
              </Stack>
            </Stack>
          </Stack>

          <Outlet />
        </>
      )}

      {mode === XMLDocumentType.DefinitionInstance && (
        <Box height={`calc(100vh - ${Utils.projectHeight()}px)`}>
          <Paper elevation={0} sx={{ p: 6, borderRadius: '12px', height: '100%', width: '100%' }}>
            <Stack direction="row" gap={4} alignItems="center">
              <Select
                sx={{
                  backgroundColor: '#F2F2F9',
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  borderRadius: '6px',
                  minWidth: 320,
                  maxWidth: 320,
                }}
                disabled={!xmlDocumentId}
                hideNotSelectItem
                label={Translator.translate('xml.selectExportProjectLabel')}
                value={Number(xmlDocumentId) ?? ''}
                options={xmlDocumentInstanceStore.documentForOptions}
                onChange={onChangeCurrentDocument}
                size="small"
              />

              <Button
                disabled={xmlDocumentInstanceStore.documentForOptions.length !== 0}
                variant="text"
                sx={(theme) => ({
                  svg: {
                    fill: xmlDocumentInstanceStore.documentForOptions.length !== 0 ? theme.palette.action.disabled : theme.palette.primary.main,
                    fillOpacity: 1,
                  },
                })}
                startIcon={<PlusIconForButton />}
                onClick={() => xmlDocumentInstanceStore.setDialogOpen(true)}>
                {Translator.translate('actions.create')}
              </Button>

              <Button
                disabled={!xmlDocumentId}
                variant="text"
                sx={(theme) => ({
                  svg: {
                    fill: !xmlDocumentId ? theme.palette.action.disabled : theme.palette.primary.main,
                    fillOpacity: 1,
                  },
                })}
                startIcon={<DownloadIcon />}
                onClick={onDownloadXMLFile}>
                {Translator.translate('actions.download')}
              </Button>
            </Stack>

            {xmlDocumentId ? <Outlet /> : emptyData()}
          </Paper>
        </Box>
      )}

      {xmlDocumentInstanceStore.isDialogOpen && <XMLDialog />}
    </>
  );
};

export default observer(XMLPage);
