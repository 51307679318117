import { HEADER_HEIGHT } from 'shared/constants/constants';
import { theme } from 'theme';

const components = {
  MuiToolbar: {
    styleOverrides: {
      root: {
        '@media all': {
          minHeight: `${HEADER_HEIGHT}px`,
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {},
    },
  },

  MuiChip: {
    styleOverrides: {
      root: {},
      outlined: {
        border: 'none',
      },
      filledDefault: {
        backgroundColor: 'rgba(15, 11, 31, 0.08)',
      },
      filledWarning: {
        color: '#ffffff',
        backgroundColor: '#DCAC04',
      },
      filledError: {
        backgroundColor: '#EC3E59',
      },
      filledInfo: {
        backgroundColor: '#1C5CE4',
      },
      outlinedWarning: {
        backgroundColor: 'rgba(220, 172, 4, 0.04)',
      },
      outlinedSuccess: {
        backgroundColor: 'rgba(18, 164, 77, 0.12)',
      },
      outlinedError: {
        backgroundColor: 'rgba(203, 33, 59, 0.04)',
      },
      outlinedInfo: {
        backgroundColor: 'rgba(28, 92, 228, 0.04)',
        color: '#1C5CE4',
      },
      outlinedPrimary: {
        backgroundColor: 'rgba(123, 104, 238, 0.08)',
      },
      outlinedSecondary: {
        backgroundColor: 'rgba(15, 11, 31, 0.04)',
        color: 'rgba(15, 11, 31, 0.6)',
      },
      outlinedText: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        color: 'rgba(15, 11, 31, 0.87)',
      },
    },
  },

  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
      disableElevation: true,
    },
  },

  MuiCheckbox: {
    styleOverrides: {
      root: {
        ':hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      root: ({ ownerState }: any) => ({
        ...(ownerState.size === 'small' &&
          ownerState.variant === 'text' && {
            padding: '0 8px',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '20px',
          }),

        '&.Mui-disabled': {
          pointerEvents: 'unset',
          cursor: 'not-allowed',

          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      }),
    },
  },
};

export default components;
