import React, { useEffect, useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Translator, approvalStore, projectStore, specificationStore } from 'stores';
import { DialogWrapper, InputValidate } from 'components';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { Routes } from 'shared/enums/Routes';
import { IApprovalForm } from 'shared/interfaces';

const ApprovalDialog: React.FC = () => {
  const formRef = useRef<FormikProps<IApprovalForm>>(null);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      projectStore.projectFilters.clear();
      approvalStore.selectedApproval.clear();
      specificationStore.specificationFilters = { definitionId: null, name: '' };
      specificationStore.specifications = [];
    };
  }, []);

  const onSubmit = async (values: IApprovalForm) => {
    approvalStore.selectedApproval.updateApprovalDialogForm(values);

    const approvalId = await approvalStore.createApproval();
    approvalStore.selectedApproval.setId(Number(approvalId));
    approvalId && navigate(`${Routes.APPROVALS}/${approvalId}`);

    approvalStore.setOpenApprovalDialog(false);
  };

  const onClose = () => {
    approvalStore.selectedApproval.clear();
    approvalStore.setOpenApprovalDialog(false);
  };

  const onCancel = () => {
    approvalStore.selectedApproval.clear();
    approvalStore.setOpenApprovalDialog(false);
  };

  const approvalDialogForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        name: approvalStore.selectedApproval.name,
        definitionId: approvalStore.selectedApproval.definition?.id!,
        specificationId: approvalStore.selectedApproval.specification?.id!,

        definitionOpt: projectStore.projectsWithDefsForOptions,
        specificationOpt: [],
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required(Translator.translate('validationMessage.required')),
        definitionId: Yup.number().required(Translator.translate('validationMessage.required')).nullable(),
        specificationId: Yup.number().required(Translator.translate('validationMessage.required')).nullable(),
      })}>
      {({ handleChange, handleBlur, values, errors, touched, setFieldValue }) => {
        return (
          <Stack gap={6}>
            <InputValidate
              required
              label={Translator.translate('approvals.approvalDialog.dialogFormFields.name')}
              name="name"
              error={Boolean(touched.name && errors.name)}
              helperText={(touched.name as any) && errors.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {projectStore.projectsWithDefsForOptions.length !== 0 && (
              <>
                <Autocomplete
                  id="definitionId"
                  options={values.definitionOpt}
                  openOnFocus
                  noOptionsText={Translator.translate('approvals.approvalDialog.dialogFormFields.definitionNotFound')}
                  getOptionLabel={(option) => option.name}
                  onChange={async (e, value) => {
                    if (value) {
                      setFieldValue('definitionId', value.id);
                      specificationStore.setSpecificationFilters(value.id as number, '');
                      await specificationStore.getSpecifications();
                      setFieldValue('specificationOpt', specificationStore.specificationsForOptions);
                    } else {
                      setFieldValue('definitionId', null);
                      setFieldValue('specificationOpt', []);
                    }
                  }}
                  size="medium"
                  clearOnBlur={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.definitionId && errors.definitionId)}
                      helperText={(touched.definitionId as any) && errors.definitionId}
                      required
                      label={Translator.translate('approvals.approvalDialog.dialogFormFields.definition')}
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                />

                <Autocomplete
                  id="specificationId"
                  options={values.specificationOpt}
                  openOnFocus
                  noOptionsText={Translator.translate('approvals.approvalDialog.dialogFormFields.specificationNotFound')}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => {
                    setFieldValue('specificationId', value ? value.id : null);
                  }}
                  size="medium"
                  clearOnBlur={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.specificationId && errors.specificationId)}
                      helperText={(touched.specificationId as any) && errors.specificationId}
                      required
                      label={Translator.translate('approvals.approvalDialog.dialogFormFields.specification')}
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                />
              </>
            )}
          </Stack>
        );
      }}
    </Formik>
  );

  return (
    <DialogWrapper
      title={Translator.translate('approvals.approvalDialog.title')}
      maxWidth="md"
      open={approvalStore.isApprovalDialogOpen}
      onClose={onClose}
      onCancel={onCancel}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}>
      <>{approvalDialogForm}</>
    </DialogWrapper>
  );
};

export default observer(ApprovalDialog);
