import React from 'react';
import { observer } from 'mobx-react-lite';
import { itemStore, parameterGroupStore } from 'stores';
import InputSearch from 'components/UI/InputSearch';
import { ItemModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

interface IParameterGroupTreeSearchProps {
  isExpanded?: boolean;
  onScroll: (key: React.Key) => void;
  onCloseSearch?: () => void;
  onPrev: () => void;
  onNext: () => void;
}

const TreeItemSearch: React.FC<IParameterGroupTreeSearchProps> = (props) => {
  const { isExpanded, onScroll, onCloseSearch, onPrev, onNext } = props;

  const firstAndLastKey = (): { first: boolean; last: boolean } => {
    const first = itemStore.searchArrays[0].key;
    const last = itemStore.searchArrays[itemStore.searchArrays.length - 1].key;

    if (itemStore.selectItem.key === first) return { first: true, last: false };
    else if (itemStore.selectItem.key === last) return { first: false, last: true };

    return { first: false, last: false };
  };

  const onSearch = () => {
    const newExpandedKeys = [...itemStore.expandedKeys];

    if (itemStore.searchValue !== '') {
      const findNodes = Utils.findSearch(itemStore.treeTestItem, itemStore.searchValue);

      if (findNodes.length !== 0) {
        // Expand tree
        findNodes.forEach((find) => {
          const item = itemStore.listTransformItemDto.find((dto) => dto.key === find.key);
          if (item && item.parentId) {
            const parents = [item.parentId];
            for (const parentId of parents) {
              const parent = itemStore.listTransformItemDto.find((dto) => dto.id === parentId);
              if (parent) {
                if (!newExpandedKeys.includes(parent.key)) {
                  newExpandedKeys.push(parent.key);
                }
                if (parent.parentId) {
                  parents.push(parent.parentId);
                }
              }
            }
          }
        });
      }

      itemStore.setSearchArrays(findNodes);
      itemStore.setExpandedKeys(newExpandedKeys as React.Key[]);

      if (itemStore.searchArrays.length !== 0) {
        const findKey = itemStore.listTransformItemDto.find((item) => item.key === itemStore.searchArrays[0].key);
        itemStore.selectItem = new ItemModel(findKey);

        onScroll(itemStore.searchArrays[0].key);
      }
    } else {
      itemStore.setSearchValue('');
      itemStore.setSearchArrays([]);
      itemStore.selectItem = new ItemModel();
    }
  };

  const onSearchItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    itemStore.setSearchValue(event.target.value);

    onSearch();
  };

  const onClose = () => {
    parameterGroupStore.setSearchValue('');

    onCloseSearch && onCloseSearch();
  };

  return (
    <InputSearch
      isExpanded={isExpanded}
      value={itemStore.searchValue}
      isArrayResult={itemStore.searchArrays.length > 1}
      disabledPrev={itemStore.searchArrays.length <= 1 || firstAndLastKey().first}
      disabledNext={itemStore.searchArrays.length <= 1 || firstAndLastKey().last}
      onPrev={onPrev}
      onNext={onNext}
      onSearch={onSearch}
      onChange={onSearchItem}
      onClose={onClose}
    />
  );
};

export default observer(TreeItemSearch);
