import React from 'react';

const ApprovalIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.53033 2.46967C8.82322 2.76256 8.82322 3.23744 8.53033 3.53033L4.53033 7.53033C4.23744 7.82322 3.76256 7.82322 3.46967 7.53033L1.46967 5.53033C1.17678 5.23744 1.17678 4.76256 1.46967 4.46967C1.76256 4.17678 2.23744 4.17678 2.53033 4.46967L4 5.93934L7.46967 2.46967C7.76256 2.17678 8.23744 2.17678 8.53033 2.46967Z" />
      <path d="M1.46967 11.4697C1.76256 11.1768 2.23744 11.1768 2.53033 11.4697L4.5 13.4393L6.46967 11.4697C6.76256 11.1768 7.23744 11.1768 7.53033 11.4697C7.82322 11.7626 7.82322 12.2374 7.53033 12.5303L5.56066 14.5L7.53033 16.4697C7.82322 16.7626 7.82322 17.2374 7.53033 17.5303C7.23744 17.8232 6.76256 17.8232 6.46967 17.5303L4.5 15.5607L2.53033 17.5303C2.23744 17.8232 1.76256 17.8232 1.46967 17.5303C1.17678 17.2374 1.17678 16.7626 1.46967 16.4697L3.43934 14.5L1.46967 12.5303C1.17678 12.2374 1.17678 11.7626 1.46967 11.4697Z" />
      <path d="M11 5.25C10.5858 5.25 10.25 5.58579 10.25 6C10.25 6.41421 10.5858 6.75 11 6.75H18C18.4142 6.75 18.75 6.41421 18.75 6C18.75 5.58579 18.4142 5.25 18 5.25H11Z" />
      <path d="M11 13.25C10.5858 13.25 10.25 13.5858 10.25 14C10.25 14.4142 10.5858 14.75 11 14.75H18C18.4142 14.75 18.75 14.4142 18.75 14C18.75 13.5858 18.4142 13.25 18 13.25H11Z" />
    </svg>
  );
};

export default ApprovalIcon;
