import React from 'react';
import { Stack, Typography } from '@mui/material';

interface ITreeItemProps {
  title: string;
}

const TreeItemTitle: React.FC<ITreeItemProps> = (props) => {
  return (
    <Stack flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center">
      <Stack flexDirection="row" alignContent="center" alignItems="center" minWidth="0">
        <Typography variant="body2" alignItems="center" gap={1} sx={{ overflow: 'hidden' }}>
          <span>{props.title}</span>
        </Typography>
      </Stack>
    </Stack>
  );
};

TreeItemTitle.displayName = 'TreeDataItemTitle';

export default TreeItemTitle;
