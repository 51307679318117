import { makeAutoObservable } from 'mobx';
import { ClassificationDictionaries, DefinitionApprovalStatus, ProjectState, ProjectType } from 'shared/enums';
import { IClassDictionary, IDefinitionsGetDto, IProjectsGetDto } from 'shared/interfaces';

class DefinitionRecentlyListModel {
  constructor(dto?: IProjectsGetDto, definitionId?: number) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto || !definitionId) return;

    this.defId = definitionId;
    this.projId = dto.id;
    this.name = dto.name;
    this.type = dto.type;
    this.status = dto.definitions?.find((f) => f.id === definitionId)?.status ?? DefinitionApprovalStatus.Formed;
    this.definitions = dto.definitions?.find((f) => f.id === definitionId) ?? ({} as IDefinitionsGetDto);

    if (dto.projectClassificationDictionaryValues && dto.projectClassificationDictionaryValues?.length > 0) {
      this.region = dto.projectClassificationDictionaryValues?.find(
        (el) => el.classificationDictionaryValue.classificationDictionary.id === ClassificationDictionaries.Region
      )?.classificationDictionaryValue ?? { id: null, value: '' };
    }
  }

  public projId: number | null = null;

  public defId: number | null = null;

  public region: IClassDictionary = { id: null, value: '' };

  public name = '';

  public type = ProjectType.Standard;

  public status = DefinitionApprovalStatus.Formed;

  public definitions = {} as IDefinitionsGetDto;

  public isPin = false;

  public isHover = false;

  public setPin(isPin: boolean) {
    this.isPin = isPin;
  }

  public setHover(isHover: boolean) {
    this.isHover = isHover;
  }
}

export default DefinitionRecentlyListModel;
