import { makeAutoObservable, runInAction } from 'mobx';
import { StageService } from 'services';
import { Translator, toastStore } from 'stores';
import { ISelectOption, IStageFilterDto } from 'shared/interfaces';
import { StageModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

class StageStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isStageLoading = false;

  public isDialogOpen = false;

  public stages: StageModel[] = [];

  public selectedStage = new StageModel();

  public get stageForOptions(): ISelectOption[] {
    return this.stages.map((item) => item.getSelectOption()!);
  }

  public setDialogOpen(isOpen: boolean) {
    this.isDialogOpen = isOpen;
  }

  public setEditingMode(id: number) {
    this.selectedStage.setId(id);
    this.setDialogOpen(true);
  }

  public async getStages() {
    this.isStageLoading = true;

    try {
      const dtos = await StageService.getAllStages();

      runInAction(() => {
        const attr = dtos.map((stage) => new StageModel(stage));
        this.stages = Utils.sortByField(attr, 'orderNum');
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Stages`);
    } finally {
      this.isStageLoading = false;
    }
  }

  public async getStagesByParameter(filterName: string) {
    this.isStageLoading = true;

    try {
      const filter: IStageFilterDto = {
        name: filterName,
      };

      const dtos = await StageService.getStages(filter);

      runInAction(() => {
        const attr = dtos.map((stage) => new StageModel(stage));
        this.stages = Utils.sortByField(attr, 'orderNum');
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.filterErrorMessage')} Stages`);
    } finally {
      this.isStageLoading = false;
    }
  }

  public async getStageById(id: number): Promise<void> {
    if (!id) return;

    try {
      this.isStageLoading = true;
      const result = await StageService.getStage(id);

      if (!result) return;

      runInAction(() => {
        this.selectedStage = new StageModel(result[0]);
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} Stage`);
    } finally {
      this.isStageLoading = false;
    }
  }

  public async createStage(): Promise<void> {
    if (!this.selectedStage.postDto) return;

    try {
      this.isStageLoading = true;
      await StageService.postStage(this.selectedStage.postDto);
      this.getStages();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} Stage`);
    } finally {
      this.isStageLoading = false;
    }
  }

  public async updateStage(): Promise<void> {
    if (!this.selectedStage.id || !this.selectedStage.putDto) return;

    try {
      this.isStageLoading = true;
      await StageService.putStage(this.selectedStage.id, this.selectedStage.putDto);
      this.getStages();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} Stage`);
    } finally {
      this.isStageLoading = false;
    }
  }

  public async removeStage(id: number): Promise<void> {
    try {
      this.isStageLoading = true;
      await StageService.deleteStage(id);
      this.getStages();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} Stage`);
    } finally {
      this.isStageLoading = false;
    }
  }
}

export default new StageStore();
