import { makeAutoObservable } from 'mobx';
import { DocumentListModel } from '../DocumentModel';
import { IParameterListValuePutDto, IRecommendListValueCondition, IRecommendParamListValueDto, ISelectOption } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';

class ParameterListValueModel {
  constructor(dto?: IRecommendParamListValueDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.value = dto.value;
    this.description = dto.description;
    this.parameterId = dto.parameterId;
    this.orderNum = dto.orderNum;
    this.documents = dto.documents.map((dto: any) => new DocumentListModel(dto));
    this.isConditionIfExist = dto.isConditionIfExist ?? false;

    /* recommended */
    this.isRecommended = dto.isRecommended ?? false;
    this.conditions = dto.conditions ?? [];
  }

  public id: number | null = null;

  public value = '';

  public description = '';

  public parameterId: number | null = null;

  public orderNum: number | null = null;

  public documents: DocumentListModel[] = [];

  public isConditionIfExist = false;

  public isRecommended = false;

  public conditions: IRecommendListValueCondition[] = [];

  public get concatRowConditions() {
    return this.conditions.map((_) => _.name).join('\n');
  }

  public setValue(value: string) {
    this.value = value;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public setParameterId(parameterId: number) {
    this.parameterId = parameterId;
  }

  public setOrderNum(orderNum: number) {
    this.orderNum = orderNum;
  }

  public getSelectOption(): ISelectOption | undefined {
    if (!this.id) return;

    return { id: this.id, name: this.value, description: this.description };
  }

  public get putDto(): IParameterListValuePutDto {
    const listDocuments = this.documents.map((doc) => {
      return {
        documentId: doc.id!,
        documentPage: doc.documentPage,
      };
    });

    return {
      id: this.id ?? -1,
      value: this.value,
      description: this.description,
      parameterId: this.parameterId ?? -1,
      orderNum: this.orderNum ?? 1,
      documents: listDocuments,
    };
  }

  public clear() {
    this.id = null;
    this.value = '';
    this.description = '';
    this.parameterId = null;
    this.orderNum = null;
    this.documents = [];
    this.isConditionIfExist = false;
    this.isRecommended = false;
    this.conditions = [];
  }
}

export default ParameterListValueModel;
