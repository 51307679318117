import React from 'react';

const HistoryIcon: React.FC = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="rgba(15, 11, 31, 0.54)" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.66078 0.0775021C10.4148 -0.162408 12.2028 0.157951 13.7565 0.992925C15.3106 1.82811 16.5489 3.13476 17.2795 4.72077C18.0103 6.30729 18.1915 8.08384 17.7942 9.78116C17.3969 11.4782 16.4443 12.9985 15.0859 14.1159C13.728 15.233 12.0373 15.8879 10.2691 15.9869C8.50098 16.0858 6.7454 15.6237 5.26639 14.6667C3.78697 13.7094 2.6624 12.307 2.06798 10.6668C1.8798 10.1476 2.14818 9.57408 2.66742 9.3859C3.18666 9.19773 3.76013 9.46611 3.94831 9.98534C4.39139 11.208 5.23326 12.263 6.35294 12.9876C7.47303 13.7124 8.8084 14.0655 10.1574 13.99C11.5063 13.9145 12.7899 13.415 13.8154 12.5714C14.8404 11.7281 15.5513 10.5879 15.8468 9.32532C16.1423 8.06302 16.0082 6.74123 15.4629 5.55752C14.9174 4.37332 13.9882 3.38794 12.8098 2.75464C11.6309 2.12112 10.2696 1.87608 8.93181 2.05905C7.605 2.24053 6.37825 2.83246 5.43181 3.74139L3.39562 6H5C5.55228 6 6 6.44771 6 7C6 7.55228 5.55228 8 5 8H1C0.447715 8 0 7.55228 0 7V3C0 2.44771 0.447715 2 1 2C1.55228 2 2 2.44771 2 3V4.56116L3.96953 2.37649C3.98442 2.35997 3.99986 2.34395 4.01582 2.32847C5.27589 1.10555 6.90662 0.317429 8.66078 0.0775021Z" />
      <path d="M11 5C11 4.44772 10.5523 4 10 4C9.44771 4 9 4.44772 9 5V9C9 9.37877 9.214 9.72503 9.55279 9.89443L11.5528 10.8944C12.0468 11.1414 12.6474 10.9412 12.8944 10.4472C13.1414 9.95324 12.9412 9.35256 12.4472 9.10557L11 8.38197V5Z" />
    </svg>
  );
};

export default HistoryIcon;
