import React, { useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { Translator, sectionSetCollectionStore, sectionSetStore, stageStore } from 'stores';
import { Select, DialogWrapper, InputValidate, MultipleSelect } from 'components';
import { SelectChangeEvent, Stack } from '@mui/material';
import { ISectionSetForm } from 'shared/interfaces';

const SpecTemplateDialog: React.FC = () => {
  const formRef = useRef<FormikProps<ISectionSetForm>>(null);

  const dialogTitle = sectionSetStore.selectedSectionSet.id
    ? Translator.translate('catalogs.specTemplates.dialogs.editTemplateTitle')
    : Translator.translate('catalogs.specTemplates.dialogs.createTemplateTitle');

  useEffect(() => {
    stageStore.getStages();
    sectionSetCollectionStore.getSectionSetCollections();

    return () => {
      sectionSetStore.selectedSectionSet.clear();
    };
  }, []);

  const onSubmit = async (values: ISectionSetForm) => {
    sectionSetStore.selectedSectionSet.updateSectionSetDialogForm(values);

    if (sectionSetStore.selectedSectionSet.sourceId !== null) {
      await sectionSetStore.createBasingOnSectionSet();
    } else if (sectionSetStore.selectedSectionSet.id) {
      await sectionSetStore.updateSectionSet();
    } else {
      await sectionSetStore.createSectionSet();
    }

    onClose();
  };

  const onClose = () => {
    sectionSetStore.setDialogOpen(false);
  };

  const sectionSetForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        name: sectionSetStore.selectedSectionSet.name,
        stageIds: sectionSetStore.selectedSectionSet.stageIds,
        sectionSet: sectionSetStore.selectedSectionSet.sourceId,
        sectionSetCollection: sectionSetStore.selectedSectionSet.sectionSetCollectionId,

        stagesOpt: stageStore.stageForOptions,
        sectionSetOpt: sectionSetStore.sectionSetsForOptions,
        sectionSetCollectionOpt: sectionSetCollectionStore.sectionSetCollectionOptions,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required(Translator.translate('validationMessage.required')),
        stageIds: Yup.array()
          .min(1, Translator.translate('catalogs.specTemplates.dialogs.validationSchema.stageIds'))
          .required(Translator.translate('validationMessage.required')),
        sectionSetCollection: sectionSetStore.selectedSectionSet.id
          ? Yup.number().required(Translator.translate('validationMessage.required'))
          : Yup.number().nullable(),
      })}>
      {({ handleChange, handleBlur, values, errors, touched, setFieldValue }) => {
        return (
          <>
            <InputValidate
              label={Translator.translate('catalogs.specTemplates.dialogs.dialogFormFields.name')}
              name="name"
              required
              error={Boolean(touched.name && errors.name)}
              helperText={(touched.name as any) && errors.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <MultipleSelect
              label={`${Translator.translate('catalogs.specTemplates.dialogs.dialogFormFields.stage')} *`}
              name="stageIds"
              value={values.stageIds}
              options={values.stagesOpt}
              error={Boolean(touched.stageIds && errors.stageIds)}
              helperText={(touched.stageIds as any) && errors.stageIds}
              onBlur={handleBlur}
              onChange={(e: SelectChangeEvent<any>) => {
                setFieldValue('stageIds', e.target.value);
              }}
            />

            {!sectionSetStore.selectedSectionSet.id && (
              <Select
                name="sectionSet"
                label={Translator.translate('catalogs.specTemplates.dialogs.dialogFormFields.sectionSet')}
                value={values.sectionSet}
                options={values.sectionSetOpt}
                size="medium"
                onChange={handleChange}
              />
            )}

            <Select
              fullWidth
              name="sectionSetCollection"
              label={Translator.translate('catalogs.specTemplates.dialogs.dialogFormFields.sectionSetCollection')}
              required={sectionSetStore.selectedSectionSet.id !== null}
              error={Boolean(touched.sectionSetCollection && errors.sectionSetCollection)}
              helperText={(touched.sectionSetCollection as any) && (errors.sectionSetCollection as any)}
              value={values.sectionSetCollection ?? ''}
              options={values.sectionSetCollectionOpt}
              onChange={handleChange}
              onBlur={handleBlur}
              size="medium"
            />
          </>
        );
      }}
    </Formik>
  );

  return (
    <DialogWrapper
      title={dialogTitle}
      maxWidth="md"
      open={sectionSetStore.isDialogOpen}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}>
      {sectionSetStore.isLoading ? (
        <Stack height={56} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        sectionSetForm
      )}
    </DialogWrapper>
  );
};

export default observer(SpecTemplateDialog);
