import React, { ReactNode, ReactElement } from 'react';
import { Translator } from 'stores';
import DateValueIcon from 'components/Icons/DateValueIcon';
import FileValueIcon from 'components/Icons/FileValueIcon';
import LinkValueIcon from 'components/Icons/LinkValueIcon';
import ListIcon from 'components/Icons/ListIcon';
import LogicalValueIcon from 'components/Icons/LogicalValueIcon';
import NumberValueIcon from 'components/Icons/NumberValueIcon';
import RangeValueIcon from 'components/Icons/RangeValueIcon';
import TextValueIcon from 'components/Icons/TextValueIcon';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps,
  SxProps,
  Theme,
  Stack,
  Typography,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import { DataType } from 'shared/enums';
import { ISelectOption } from 'shared/interfaces';

import s from './Select.module.scss';

export interface ISelectProps extends Omit<SelectProps, 'onChange'> {
  sx?: SxProps<Theme>;
  hideNotSelectItem?: boolean;
  notSelectItemName?: string;
  readOnly?: boolean;
  required?: boolean;
  label?: string;
  value: any;
  disabled?: boolean;
  options: ISelectOption[];
  onChange: (e: SelectChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  autoFocus?: boolean;
  size?: any;
  helperText?: any;
  isShowIconType?: boolean;
  menuItems?: ReactElement[] | ReactElement;
  addActions?: ReactNode;
  isLoading?: boolean;
}

const Select: React.FC<ISelectProps> = (props) => {
  const {
    label,
    value,
    options,
    disabled,
    readOnly,
    required,
    hideNotSelectItem,
    isShowIconType,
    sx,
    helperText,
    placeholder,
    onChange,
    onBlur,
    autoFocus,
    size = 'small',
    error,
    addActions,
    menuItems,
    isLoading,
    ...rest
  } = props;

  const renderValue = (row: any): React.ReactNode => {
    const findEl = options.find((option) => option.id === row);

    const getValue = (): React.ReactNode => {
      if (findEl !== undefined) {
        if (isShowIconType) {
          return (
            <Stack direction="row" width="80%">
              <Stack alignItems="center" mr={1}>
                {iconValue(findEl)}
              </Stack>

              <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                {findEl.name}
              </Typography>
            </Stack>
          );
        } else
          return (
            <Typography variant="body2" sx={{ wordBreak: 'break-word', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {findEl.name}
            </Typography>
          );
      } else return '';
    };

    return (
      <Stack direction="row" justifyContent="space-between" width="100%">
        {getValue()}

        {isLoading && (
          <Stack justifyContent="flex-end">
            <CircularProgress size="1.5rem" />
          </Stack>
        )}
      </Stack>
    );
  };

  const iconValue = (option: ISelectOption): ReactNode => {
    switch (option.id as DataType) {
      case DataType.Number:
        return <NumberValueIcon />;

      case DataType.Range:
        return <RangeValueIcon />;

      case DataType.Boolean:
        return <LogicalValueIcon />;

      case DataType.String:
        return <TextValueIcon />;

      case DataType.Link:
        return <LinkValueIcon />;

      case DataType.List:
        return <ListIcon />;

      case DataType.DateTime:
        return <DateValueIcon />;

      case DataType.File:
        return <FileValueIcon />;

      default:
        return <TextValueIcon />;
    }
  };

  const placeHolder = (
    <Typography variant="body2" sx={{ opacity: 0.6, mt: 0.5 }}>
      {placeholder}
    </Typography>
  );

  return (
    <FormControl required={required} disabled={disabled} sx={sx} size={size} error={error}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        {...rest}
        inputProps={{
          readOnly: readOnly ?? false,
        }}
        className={s.select}
        value={value ?? ''}
        label={label}
        displayEmpty
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        renderValue={(selected) => (!!value ? renderValue(selected) : placeHolder)}
        input={<OutlinedInput label={label} autoFocus={autoFocus} />}>
        {menuItems && menuItems}

        {!menuItems && !hideNotSelectItem && (
          <MenuItem className={s.notSelectedMenuItem} disableRipple value="">
            {Translator.translate('system.unselected')}
          </MenuItem>
        )}

        {!menuItems &&
          options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.description ? (
                <div className={s.menuWrapper}>
                  <div>{option.name}</div>

                  <div className={s.toolTipDesc}>{option.description}</div>
                </div>
              ) : isShowIconType ? (
                <>
                  <Box sx={{ paddingRight: '8px' }}>{iconValue(option)}</Box>
                  <Box>{option.name}</Box>
                </>
              ) : (
                <div>{option.name}</div>
              )}
            </MenuItem>
          ))}

        {addActions && <Stack>{addActions}</Stack>}
      </MuiSelect>
      {error ? <FormHelperText error>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};

export default Select;
