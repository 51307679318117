import React, { useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { Translator, classStore } from 'stores';
import { DialogWrapper, InputValidate } from 'components';
import { IClassForm } from 'shared/interfaces';

const EditClassDialog = () => {
  const formRef = useRef<FormikProps<IClassForm>>(null);

  const onSubmit = async (values: IClassForm) => {
    classStore.classObject.updateClassDialogForm(values);

    await classStore.updateClass();
    classStore.classObject.clearModel();
  };

  const onClose = () => {
    classStore.setIsDialogEditOpen(false);
  };

  const classForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        className: classStore.classObject.name,
      }}
      validationSchema={Yup.object().shape({
        className: Yup.string().max(255).required(Translator.translate('validationMessage.required')),
      })}>
      {({ handleChange, handleBlur, values, errors, touched }) => {
        return (
          <InputValidate
            label={Translator.translate('classes.classDialog.dialogFormFields.editClassName')}
            name="className"
            error={Boolean(touched.className && errors.className)}
            helperText={(touched.className as any) && errors.className}
            value={values.className}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        );
      }}
    </Formik>
  );

  return (
    <DialogWrapper
      title={`${Translator.translate('classes.classDialog.editClassTitle')}: ${classStore.classById?.name}`}
      maxWidth="md"
      open={classStore.isDialogEditOpen}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}>
      {classForm}
    </DialogWrapper>
  );
};

export default observer(EditClassDialog);
