import { makeAutoObservable } from 'mobx';

class AppStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isCollapseSideBar = false;

  public isShowComments = false;

  public setCollapseSideBar(isCollapse: boolean) {
    this.isCollapseSideBar = isCollapse;
  }
}

export default new AppStore();
