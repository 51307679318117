import React from 'react';

const LogoIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 4H18L17 6H3V4Z" />
      <path d="M7 9H16L15 11H7V9Z" />
      <path d="M14 14H3V16H13L14 14Z" />
    </svg>
  );
};

export default LogoIcon;
