import React from 'react';

const UpIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.7071 2.29289C10.3166 1.90237 9.68342 1.90237 9.29289 2.29289L2.29289 9.29289C1.90237 9.68342 1.90237 10.3166 2.29289 10.7071C2.68342 11.0976 3.31658 11.0976 3.70711 10.7071L9 5.41421L9 17C9 17.5523 9.44772 18 10 18C10.5523 18 11 17.5523 11 17L11 5.41421L16.2929 10.7071C16.6834 11.0976 17.3166 11.0976 17.7071 10.7071C18.0976 10.3166 18.0976 9.68342 17.7071 9.29289L10.7071 2.29289Z" />
    </svg>
  );
};

export default UpIcon;
