import { useRef, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { observer } from 'mobx-react-lite';
import { Translator, approvalSuggestionRowStore, itemStore } from 'stores';
import { TreeData, TreeItemSearch } from 'components';
import { Chip, IconButton, Stack, Select as MuiSelect, Typography, Box } from '@mui/material';
import { ButtonItemTitle } from '../TreeData';
import { ItemModel } from 'shared/models';

const ItemFilter: React.FC = () => {
  const [currentNode, setCurrentNode] = useState<React.Key | null>();
  const itemRef = useRef<any>();

  const onExpand = (expandedKeysValue: React.Key[]) => {
    itemStore.setExpandedKeys(expandedKeysValue);
  };

  const onHover = (key: React.Key | null) => {
    setCurrentNode(key);
  };

  const mapItems = (data: ItemModel[]): any => {
    return data.map((item) => {
      const title = (
        <ButtonItemTitle title={item.title as string} searchValue={itemStore.searchValue} item={item} hovered={item.key === currentNode} />
      );

      if (item.children) {
        return {
          ...item,
          title: title,
          children: mapItems(item.children),
        };
      }

      return {
        ...item,
        title: title,
      };
    });
  };

  const onDeleteItem = (e: React.MouseEvent, data: number) => {
    approvalSuggestionRowStore.approvalSuggestionRow.setItemIds(approvalSuggestionRowStore.approvalSuggestionRow.itemIds.filter((f) => f !== data));
  };

  const renderValues = (): React.ReactNode => {
    return (
      <Stack flexWrap="wrap" flexDirection="row" gap={2}>
        {itemStore.itemsDto
          .filter((f) => approvalSuggestionRowStore.approvalSuggestionRow.itemIds.includes(f.id))
          .map((value) => (
            <Chip
              sx={{ width: 'fit-content' }}
              key={value.id}
              clickable
              label={value.name}
              deleteIcon={
                <IconButton
                  sx={{
                    padding: 0,
                    svg: {
                      fill: 'rgba(0, 0, 0, 0.26)',
                      width: '16px',
                      height: '16px',
                    },
                  }}
                  disableRipple
                  onMouseDown={(event: any) => event.stopPropagation()}>
                  <CancelIcon />
                </IconButton>
              }
              onDelete={(e) => onDeleteItem(e, value.id as number)}
              size="small"
            />
          ))}
      </Stack>
    );
  };

  const onUpSearch = () => {
    updateItem(-1);
  };

  const onDownSearch = () => {
    updateItem(1);
  };

  const updateItem = (searchDirection: 1 | -1) => {
    const findIndex = itemStore.searchArrays.findIndex((item) => item.key === itemStore.selectItem.key);

    const findKey = itemStore.listTransformItemDto.find((item) => item.key === itemStore.searchArrays[findIndex + searchDirection].key);
    itemStore.selectItem = new ItemModel(findKey);

    itemRef.current.scrollTo({ key: itemStore.searchArrays[findIndex + searchDirection].key });
  };

  const onScroll = (key: React.Key) => {
    itemRef.current.scrollTo({ key: key });
  };

  const onCloseSelect = () => {
    itemStore.setSearchArrays([]);
    itemStore.setSearchValue('');
    itemStore.selectItem = new ItemModel();
  };

  const onCheckItems = (checkedKeysValue: any, info: any) => {
    approvalSuggestionRowStore.approvalSuggestionRow.setItemIds(checkedKeysValue.checked.map((value: string) => Number(value)));
  };

  return (
    <MuiSelect
      fullWidth
      displayEmpty
      size="small"
      sx={{
        backgroundColor: '#F2F2F9',
        boxShadow: 'none',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
        borderRadius: '6px',
      }}
      onClose={onCloseSelect}
      renderValue={() =>
        approvalSuggestionRowStore.approvalSuggestionRow.itemIds.length > 0 ? (
          renderValues()
        ) : (
          <Typography variant="body2" sx={{ opacity: 0.6, mt: 0.5 }}>
            {Translator.translate('objectValues.objectsTitle')}
          </Typography>
        )
      }>
      <Stack gap={2} px={2} minHeight={350}>
        <TreeItemSearch onScroll={onScroll} onPrev={onUpSearch} onNext={onDownSearch} />

        <Box height={300}>
          <TreeData
            treeData={mapItems(itemStore.treeTestItem)}
            ref={itemRef}
            expandedKeys={itemStore.expandedKeys}
            selectedKey={[String(itemStore.selectItem.id)]}
            checkedKeys={approvalSuggestionRowStore.approvalSuggestionRow.itemIds.map((value: number) => String(value))}
            onCheck={onCheckItems}
            onExpand={onExpand}
            height={300}
            isCheckStrictly
            onHover={onHover}
          />
        </Box>
      </Stack>
    </MuiSelect>
  );
};

export default observer(ItemFilter);
