import { observer } from 'mobx-react-lite';
import { Translator, toastStore } from 'stores';
import { IconButtonNew } from 'components';
import CloseDialogIcon from 'components/Icons/CloseDialogIcon';
import { Alert, Snackbar } from '@mui/material';

const Toast = () => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={toastStore.isOpen}
      autoHideDuration={toastStore.duration}
      onClose={toastStore.onClose}>
      <Alert
        sx={{
          '& .MuiAlert-action': {
            p: '6px 0 0 16px',
          },
        }}
        variant="filled"
        onClose={toastStore.onClose}
        severity={toastStore.messageType}
        action={
          <IconButtonNew
            sx={(theme) => ({ svg: { width: '18px', height: '18px', fill: theme.palette.common.white } })}
            tooltip={Translator.translate('actions.close')}
            onClick={() => {
              toastStore.setOpen(false);
            }}>
            <CloseDialogIcon />
          </IconButtonNew>
        }>
        {toastStore.message}
      </Alert>
    </Snackbar>
  );
};

export default observer(Toast);
