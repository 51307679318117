import React, { useState } from 'react';
import { Translator } from 'stores';
import { IconButtonNew } from 'components';
import ExpandIcon from 'components/Icons/ExpandIcon';
import { Collapse, Stack, Table, TableBody, TableCell, TableRow, Box } from '@mui/material';
import { NestedLevel } from 'shared/enums';
import { ITableColumn } from 'shared/interfaces';

interface INestesRowProps<T> {
  row: T & { id: number; level: NestedLevel; children: T[]; createdAt?: string };
  nestedLevel: NestedLevel;
  columns: ITableColumn[];
  selectedRow?: number | null;
  onSelectRow?: (selectedRow: T) => void;
  onHover?: (rowId: number | null) => void;
  hoveredRowIds?: number[];
  isColorFirstLevel?: boolean;
  isActionsHover?: boolean;
  isExpandOnClickRow?: boolean;
  isExpandToolTip: boolean;
}

const NestedRow = <T,>(props: INestesRowProps<T>) => {
  const {
    row,
    nestedLevel,
    columns,
    selectedRow,
    onSelectRow,
    isColorFirstLevel,
    isActionsHover,
    hoveredRowIds,
    isExpandOnClickRow,
    isExpandToolTip,
    onHover,
  } = props;

  // isClose group row for second level approval table
  const [open, setOpen] = useState(row.createdAt && row.level === NestedLevel.Second ? false : true);
  const [hoveredRowId, setHoveredRowId] = useState(-1);

  const onClickRow = () => {
    if (row.children.length !== 0 && isExpandOnClickRow) {
      setOpen(!open);
    }
    onSelectRow && onSelectRow(row);
  };

  // TODO: isActionsHover сделать в связки с onSelectRow

  return (
    <>
      <TableRow
        hover
        sx={(theme) => ({
          backgroundColor:
            selectedRow === row.id || (isColorFirstLevel && nestedLevel === NestedLevel.First)
              ? theme.palette.background.default
              : theme.palette.common.white,
          '.MuiTableCell-body': {
            borderBottom: `1px solid ${theme.palette.background.default} !important`,
          },
          height: isActionsHover ? '41px' : 'auto',
          cursor: hoveredRowIds ? (hoveredRowIds.includes(row.id) ? 'pointer' : row.children.length > 0 ? 'pointer' : 'default') : 'pointer',
        })}
        onMouseEnter={() => {
          setHoveredRowId(row.id);

          if (!hoveredRowIds) {
            onHover && onHover(row.id);
          } else {
            onHover && hoveredRowIds.includes(row.id) && onHover(row.id);
          }
        }}
        onMouseLeave={() => {
          setHoveredRowId(-1);
          onHover && onHover(null);
        }}
        onClick={onClickRow}>
        {columns.map((column, index) => {
          const cellRender = () => {
            if (isActionsHover) {
              if (column.key === 'actions' && row.id === hoveredRowId) {
                return <>{column.cell ? column.cell(row) : (row as any)[column.key]}</>;
              } else {
                return <>{column.cell && column.key !== 'actions' ? column.cell(row) : (row as any)[column.key]}</>;
              }
            } else {
              return <Box sx={{ width: index === 1 ? 'fit-content' : 'auto' }}>{column.cell ? column.cell(row) : (row as any)[column.key]}</Box>;
            }
          };

          if (column.key === 'collapse') {
            return (
              <TableCell
                key={column.key}
                sx={{
                  p: 0,
                  width: column.calcWidth ? column.calcWidth(row) : column.width,
                  minWidth: column.calcWidth ? column.calcWidth(row) : column.width,
                  maxWidth: column.calcWidth ? column.calcWidth(row) : column.width,
                }}>
                {row.children.length !== 0 && (
                  <Stack justifyContent="flex-end" flexDirection="row">
                    <IconButtonNew
                      tooltip={isExpandToolTip ? (open ? Translator.translate('actions.collapse') : Translator.translate('actions.expand')) : ''}
                      sx={{ transform: `rotate(${open ? 0 : 270}deg)` }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen(!open);
                      }}>
                      <ExpandIcon />
                    </IconButtonNew>
                  </Stack>
                )}
              </TableCell>
            );
          }

          return (
            <TableCell
              key={column.key}
              sx={{
                px: 0,
                width: column.calcWidth ? column.calcWidth(row) : column.width,
                minWidth: column.calcWidth ? column.calcWidth(row) : column.minWidth,
                maxWidth: column.calcWidth ? column.calcWidth(row) : column.maxWidth,
              }}>
              {cellRender()}
            </TableCell>
          );
        })}
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={columns.length}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableBody
                sx={{
                  '.MuiTableCell-body': {
                    borderBottom: 'none !important',
                  },
                }}
                component="tbody">
                {row.children.map((children: any) => {
                  return (
                    <NestedRow
                      columns={columns}
                      key={children.id}
                      row={children}
                      nestedLevel={children.level}
                      selectedRow={selectedRow}
                      onSelectRow={onSelectRow}
                      onHover={onHover}
                      hoveredRowIds={hoveredRowIds}
                      isColorFirstLevel={isColorFirstLevel}
                      isActionsHover={isActionsHover}
                      isExpandOnClickRow={isExpandOnClickRow}
                      isExpandToolTip={isExpandToolTip}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default NestedRow;
