import React from 'react';

const EditPencilIcon: React.FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="rgba(15, 11, 31, 0.54)" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1458 2.70002C13.9943 2.70002 13.8442 2.72988 13.7042 2.78787C13.5642 2.84587 13.4369 2.93088 13.3298 3.03805L3.69467 12.6732L3.08262 14.9173L5.3268 14.3053L14.9619 4.67018C15.0691 4.56301 15.1541 4.43578 15.2121 4.29576C15.2701 4.15575 15.2999 4.00567 15.2999 3.85411C15.2999 3.70255 15.2701 3.55248 15.2121 3.41246C15.1541 3.27244 15.0691 3.14522 14.9619 3.03805C14.8547 2.93088 14.7275 2.84587 14.5875 2.78787C14.4475 2.72988 14.2974 2.70002 14.1458 2.70002ZM13.0154 1.12489C13.3738 0.976434 13.7579 0.900024 14.1458 0.900024C14.5338 0.900024 14.9179 0.976434 15.2763 1.12489C15.6347 1.27335 15.9604 1.49094 16.2347 1.76526C16.509 2.03957 16.7266 2.36522 16.8751 2.72363C17.0235 3.08204 17.0999 3.46618 17.0999 3.85411C17.0999 4.24205 17.0235 4.62619 16.8751 4.98459C16.7266 5.343 16.509 5.66866 16.2347 5.94297L6.43058 15.7471C6.31984 15.8578 6.1821 15.9378 6.03099 15.979L2.03673 17.0683C1.72514 17.1533 1.3919 17.0648 1.16353 16.8364C0.935152 16.608 0.846656 16.2748 0.931636 15.9632L2.02098 11.969C2.06219 11.8178 2.14212 11.6801 2.25287 11.5694L12.057 1.76526C12.3313 1.49094 12.6569 1.27335 13.0154 1.12489Z"
      />
    </svg>
  );
};

export default EditPencilIcon;
