import { makeAutoObservable } from 'mobx';
import { IXmlDocumentNodeAttributeDto } from 'shared/interfaces';

class XmlDocumentAttributeModel {
  constructor(dto?: IXmlDocumentNodeAttributeDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.type = dto.type;
    this.selector = dto.selector;
    this.value = dto.value;
    this.parameterIdBind = dto.parameterIdBind;
  }

  public id: number | null = null;

  public name = '';

  public type = '';

  public description = '';

  public selector: string | null = null;

  public value: string | null = null;

  public parameterIdBind: number | null = null;
}

export default XmlDocumentAttributeModel;
