import React, { useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { Translator, sectionSetCollectionStore } from 'stores';
import { DialogWrapper, InputValidate } from 'components';
import { Stack } from '@mui/material';
import { ISectionSetCollectionForm } from 'shared/interfaces/app/ISectionSetCollection';

const EditCollectionDialog: React.FC = () => {
  const formRef = useRef<FormikProps<ISectionSetCollectionForm>>(null);

  const dialogTitle = Translator.translate('catalogs.specTemplates.dialogs.editSetTitle');

  useEffect(() => {
    return () => {
      sectionSetCollectionStore.selectedSectionSetCollection.clear();
    };
  }, []);

  const onSubmit = (values: ISectionSetCollectionForm) => {
    sectionSetCollectionStore.selectedSectionSetCollection.updateSectionSetCollectionDialogForm(values);
    sectionSetCollectionStore.updateSectionSetCollections().then(() => onClose());
  };

  const onClose = () => {
    sectionSetCollectionStore.setDialogOpen(false);
  };

  const sectionSetCollectionForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        name: sectionSetCollectionStore.selectedSectionSetCollection.name,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required(Translator.translate('validationMessage.required')),
      })}>
      {({ handleChange, handleBlur, values, errors, touched }) => {
        return (
          <InputValidate
            label={Translator.translate('catalogs.specTemplates.dialogs.dialogFormFields.name')}
            name="name"
            required
            error={Boolean(touched.name && errors.name)}
            helperText={(touched.name as any) && errors.name}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        );
      }}
    </Formik>
  );

  return (
    <DialogWrapper
      title={dialogTitle}
      maxWidth="md"
      open={sectionSetCollectionStore.isDialogOpen}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}>
      {sectionSetCollectionStore.isLoading ? (
        <Stack height={56} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        sectionSetCollectionForm
      )}
    </DialogWrapper>
  );
};

export default observer(EditCollectionDialog);
