import { BASE_PATH } from 'shared/constants/constants';
import {
  IParameterValueFileDto,
  IParameterValueParams,
  IPostParameterValueDto,
  IGetParameterValueDto,
  IPutParameterValueDto,
  IPostCompactDto,
  IGetCompactDto,
} from 'shared/interfaces';
import HTTPService from './HttpService';

/* Сервис для получения данных Объектов и значений*/
class ParameterValueService extends HTTPService {
  constructor() {
    super(BASE_PATH.PARAMETER_VALUE);
  }

  public getParameterValue(id: number): Promise<IGetParameterValueDto> {
    return this.GET(`${id}`);
  }

  public getCompactListValue(body: IPostCompactDto): Promise<IGetCompactDto> {
    return this.POST('compact', body);
  }

  public postParameterValue(body: IPostParameterValueDto) {
    return this.POST('', body);
  }

  public putParameterValue(id: number, body: IPutParameterValueDto) {
    return this.PUT(`${id}`, body);
  }

  public deleteParameterValue(id: number) {
    return this.DELETE(`${id}`);
  }

  public postFile(body: FormData): Promise<IParameterValueFileDto> {
    return this.POST('file', body, { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  public downloadFile(id: number): Promise<void> {
    return this.GET(`${id}/file`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream',
      },
    });
  }
}

export default new ParameterValueService();
