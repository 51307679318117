import { BASE_PATH } from 'shared/constants/constants';

import { IGetCommentsDto, IPutCommentDto } from 'shared/interfaces';
import HTTPService from './HttpService';

class ApprovalRowsService extends HTTPService {
  constructor() {
    super(BASE_PATH.APPROVAL_ROWS);
  }

  public getApprovalRowComments(id: number): Promise<IGetCommentsDto[]> {
    return this.GET(`${id}/comments`);
  }

  public postApprovalRowSuggestion(id: number, body: IPutCommentDto): Promise<number> {
    return this.POST(`${id}/comments`, body);
  }
}

export default new ApprovalRowsService();
