import { BASE_PATH } from 'shared/constants/constants';
import { IGetCategoryDto } from 'shared/interfaces';
import HTTPService from './HttpService';

class CategoryService extends HTTPService {
  constructor() {
    super(BASE_PATH.CATEGORIES);
  }

  public getAllCategories(): Promise<IGetCategoryDto[]> {
    return this.GET('');
  }
}

export default new CategoryService();
