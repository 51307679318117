import React, { useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { Translator, classStore, xmlDocumentInstanceStore } from 'stores';
import { ClassFilter, DialogWrapper, InputValidate, Select } from 'components';
import { EditClassSectionDialog } from 'components/App/ClassFilter/Components';
import ParameterGroupEditField from 'components/App/ParameterGroupEditField';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { ObjectLayer } from 'shared/enums';
import { IClasses, IXmlNodeForm } from 'shared/interfaces';
import EditXmlParamDialog from './EditXmlParamDialog';

const XMLNodeDialog: React.FC = () => {
  const formRef = useRef<FormikProps<IXmlNodeForm>>(null);
  const classRef = useRef<null | HTMLDivElement>(null);
  const parameterRef = useRef<null | HTMLDivElement>(null);

  const dialogTitle = xmlDocumentInstanceStore.selectedXMLNode.isNewNode
    ? Translator.translate('xml.xmlNodeDialog.addTitle')
    : Translator.translate('xml.xmlNodeDialog.editTitle');

  const onSubmit = async (values: IXmlNodeForm) => {
    xmlDocumentInstanceStore.selectedXMLNode.updateXmlNodeForm(values);

    if (xmlDocumentInstanceStore.selectedXMLNode.isNewNode) {
      xmlDocumentInstanceStore.createXMLDocumentNode().then(() => onClose());
    } else {
      xmlDocumentInstanceStore.updateXMLDocumentNode().then(() => onClose());
    }
  };

  const onClose = () => {
    xmlDocumentInstanceStore.setDialogXMLNodeOpen(false);
    classStore.treeObject.clearModel();
    classStore.setSelectedLayer(ObjectLayer.Main);
  };

  const clear = () => {
    xmlDocumentInstanceStore.setEditClassDialogOpen(false);
    classStore.treeObject.clearModel();
    classStore.setSelectedLayer(ObjectLayer.Main);
  };

  const onSubmitEditClass = () => {
    xmlDocumentInstanceStore.selectedXMLNode.defaultClassConstraints = [...xmlDocumentInstanceStore.selectedXMLNode.classConstraints];
    clear();
  };

  const onCloseEditClass = () => {
    xmlDocumentInstanceStore.selectedXMLNode.classConstraints = [...xmlDocumentInstanceStore.selectedXMLNode.defaultClassConstraints];
    clear();
  };

  const onCheckClass = (checkedKeysValue: any, info: any) => {
    const keys = info.node.key.split('-');
    const secondParentClass = keys.length >= 3 ? classStore.allClass.find((f) => f.id === Number(keys[keys.length - 3])) : undefined;

    xmlDocumentInstanceStore.selectedXMLNode.setClassConstraints({
      className: info.node.name,
      parentName: info.node.parentName,
      secondParentName: secondParentClass && secondParentClass.name ? secondParentClass.name : '',
      classKey: info.node.key,
    });

    const filterSectionClass = classStore.allClass.filter(
      (f) =>
        xmlDocumentInstanceStore.selectedXMLNode.classConstraints.find((classConstraint) => f.key === classConstraint.classKey) &&
        f.layerId === classStore.classFilters.layerId
    );

    classStore.treeObject.setCheckedKeys(filterSectionClass.map((cl) => cl.key));
    classStore.treeObject.checkedNodesInfo = filterSectionClass;
  };

  const onDeleteClass = (e: React.MouseEvent, data: IClasses) => {
    e.preventDefault();

    xmlDocumentInstanceStore.selectedXMLNode.setClassConstraints({
      className: data.className,
      parentName: data.parentName,
      secondParentName: data.secondParentName,
      classKey: data.classKey,
    });

    const filterSectionClass = classStore.allClass.filter(
      (f) =>
        xmlDocumentInstanceStore.selectedXMLNode.classConstraints.find((classConstraint) => f.key === classConstraint.classKey) &&
        f.layerId === classStore.classFilters.layerId
    );

    classStore.treeObject.setCheckedKeys(filterSectionClass.map((cl) => cl.key));
    classStore.treeObject.checkedNodesInfo = filterSectionClass;
  };

  const onSubmitEditParameterBindings = () => {
    xmlDocumentInstanceStore.selectedXMLNode.defaultParameterBindings = [...xmlDocumentInstanceStore.selectedXMLNode.parameterBindings];
    xmlDocumentInstanceStore.setEditParamDialogOpen(false);

    formRef.current && formRef.current.setFieldValue('parameterBindings', [...xmlDocumentInstanceStore.selectedXMLNode.parameterBindings]);
  };

  const xmlAddForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        selector: xmlDocumentInstanceStore.selectedXMLNode.selector,
        value: xmlDocumentInstanceStore.selectedXMLNode.value,
        parameterBindings: xmlDocumentInstanceStore.selectedXMLNode.parameterBindings,
        classIdBind: xmlDocumentInstanceStore.selectedXMLNode.classIdBind,

        selectorOpt: xmlDocumentInstanceStore.selectorTypeForOptions,
      }}
      validationSchema={() =>
        Yup.lazy((values) => {
          return Yup.object().shape({
            selector:
              values.value === null
                ? Yup.string().max(255).required(Translator.translate('validationMessage.required')).nullable()
                : Yup.string().nullable(),
            value:
              values.selector === null
                ? Yup.string().max(255).required(Translator.translate('validationMessage.required')).nullable()
                : Yup.string().nullable(),
            parameterBindings:
              values.selector && values.selector !== ''
                ? Yup.array()
                    .min(1, Translator.translate('xml.xmlNodeDialog.validationSchema.parameterBindings'))
                    .required(Translator.translate('validationMessage.required'))
                : Yup.array(),
          });
        })
      }>
      {({ handleBlur, setFieldValue, values, errors, touched }) => {
        return (
          <Stack gap={4}>
            <Stack flexDirection="row" gap={4}>
              <Typography variant="caption">{Translator.translate('xml.xmlNodeDialog.dialogFormFields.nameType')}</Typography>

              <Typography color="text.primary" variant="body2">
                {`${xmlDocumentInstanceStore.selectedXMLNode.name}, ${xmlDocumentInstanceStore.selectedXMLNode.type}`}
              </Typography>
            </Stack>

            <Stack flexDirection="row" gap={4}>
              <Typography variant="caption">{Translator.translate('xml.xmlNodeDialog.dialogFormFields.description')}</Typography>

              <Typography color="text.primary" variant="body2">
                {xmlDocumentInstanceStore.selectedXMLNode.description}
              </Typography>
            </Stack>

            <Select
              name="selector"
              label={Translator.translate('xml.xmlNodeDialog.dialogFormFields.selector')}
              error={Boolean(touched.selector && errors.selector)}
              helperText={(touched.selector as any) && (errors.selector as any)}
              value={values.selector}
              options={values.selectorOpt}
              disabled={values.value !== null && values.selector === null}
              onChange={(e) => {
                setFieldValue('selector', e.target.value ? e.target.value : null);

                if (e.target.value !== '') {
                  setFieldValue('value', null);
                } else {
                  setFieldValue('parameterBindings', []);
                }
              }}
              onBlur={handleBlur}
              size="medium"
            />

            <InputValidate
              label={Translator.translate('xml.xmlNodeDialog.dialogFormFields.value')}
              name="value"
              disabled={values.selector !== null && values.value === null}
              error={Boolean(touched.value && errors.value)}
              helperText={(touched.value as any) && errors.value}
              value={values.value ?? ''}
              onChange={(e) => {
                setFieldValue('value', e.target.value ? e.target.value : null);

                if (e.target.value !== '') {
                  setFieldValue('selector', null);
                }
              }}
              onBlur={handleBlur}
            />

            <Box
              ref={classRef}
              sx={{
                height: xmlDocumentInstanceStore.selectedXMLNode.defaultClassConstraints.length > 3 ? '160px' : 'auto',
                overflow: 'hidden',
              }}>
              <ClassFilter
                classHeight={classRef.current?.offsetHeight}
                defaultClassConstraints={xmlDocumentInstanceStore.selectedXMLNode.defaultClassConstraints}
                onEditClass={() => {
                  xmlDocumentInstanceStore.setEditClassDialogOpen(true);
                }}
              />
            </Box>

            <Box
              ref={parameterRef}
              sx={{
                height: values.parameterBindings.length > 2 ? '180px' : 'auto',
                overflow: 'hidden',
              }}>
              <ParameterGroupEditField
                paramGroupHeight={parameterRef.current?.offsetHeight}
                defaultParameterBindings={values.parameterBindings}
                formikTouched={touched}
                formikErrors={errors}
                formikValues={values}
                onEdit={() => {
                  xmlDocumentInstanceStore.setEditParamDialogOpen(true);
                }}
              />
            </Box>
          </Stack>
        );
      }}
    </Formik>
  );

  return (
    <>
      <DialogWrapper
        title={dialogTitle}
        maxWidth="md"
        open={xmlDocumentInstanceStore.isDialogXMLNodeOpen}
        onClose={onClose}
        onCancel={onClose}
        onSubmit={() => {
          if (formRef.current) formRef.current.handleSubmit();
        }}
        titleSubmit={
          xmlDocumentInstanceStore.selectedXMLNode.isNewNode ? Translator.translate('actions.create') : Translator.translate('actions.save')
        }>
        {xmlDocumentInstanceStore.isDialogLoading ? (
          <Stack height={56} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          xmlAddForm
        )}
      </DialogWrapper>

      {xmlDocumentInstanceStore.isEditClassDialogOpen && (
        <EditClassSectionDialog
          isDialogOpen={xmlDocumentInstanceStore.isEditClassDialogOpen}
          classConstraints={xmlDocumentInstanceStore.selectedXMLNode.classConstraints}
          isEditXMLNode
          onSubmit={onSubmitEditClass}
          onClose={onCloseEditClass}
          onCheckClass={onCheckClass}
          onDeleteClass={onDeleteClass}
        />
      )}

      {xmlDocumentInstanceStore.isEditParamDialogOpen && (
        <EditXmlParamDialog isDialogOpen={xmlDocumentInstanceStore.isEditParamDialogOpen} onSubmit={onSubmitEditParameterBindings} />
      )}
    </>
  );
};

export default observer(XMLNodeDialog);
