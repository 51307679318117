import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, classStore, itemStore, parameterValueStore } from 'stores';
import { InputSearch, Input, DialogWrapper, Toggler, ClassTree, Switch } from 'components';
import { FormControlLabel, FormGroup, Checkbox, Box, CircularProgress, Divider, Stack } from '@mui/material';
import { ObjectLayer, ObjectLayerNames } from 'shared/enums';
import { ISearch } from 'shared/interfaces';
import { ItemModel } from 'shared/models';

import s from './AddItemDialog.module.scss';

const AddItemDialog: React.FC = () => {
  const objectRef = useRef<any>();

  useEffect(() => {
    let name = '';
    if (itemStore.selectItem.isMaster) {
      name = `${classStore.treeObject.selected?.name}-${Translator.translate('objectValues.item.itemDialog.master')}`;
    } else name = classStore.treeObject.selected?.name;

    itemStore.selectItem.setName(classStore.treeObject.selected?.name ? name : '');
  }, [classStore.class, classStore.treeObject.selectedKeys]);

  const layerFilter = [
    {
      value: ObjectLayer.Main,
      label: Translator.translate(ObjectLayerNames.get(ObjectLayer.Main)),
    },
    {
      value: ObjectLayer.Additional,
      label: Translator.translate(ObjectLayerNames.get(ObjectLayer.Additional)),
    },
  ];

  const onSubmit = async () => {
    await itemStore.createItem(itemStore.createItems.filter((el) => el.selected));
    itemStore.setIsDialogCreateOpen(false);
    classStore.treeObject.setSelectedKeys([]);
    classStore.setSelectedLayer(ObjectLayer.Main);
  };

  const onClose = () => {
    if (parameterValueStore.filterStateParamValues.itemId !== itemStore.selectItem.id) {
      const findKey = itemStore.listTransformItemDto.find((item) => item.id === parameterValueStore.filterStateParamValues.itemId);
      itemStore.selectItem = new ItemModel(findKey);
    }

    const find = itemStore.listTransformItemDto.find((item) => item.id === itemStore.selectItem.id);
    itemStore.selectItem.setName(find?.name!);
    classStore.treeObject.clearModel();
    itemStore.setIsDialogCreateOpen(false);
    classStore.setSelectedLayer(ObjectLayer.Main);
  };

  const onChangeName = (value: string) => {
    itemStore.selectItem.setName(value);
  };

  const onChangeMaster = (e: React.ChangeEvent<HTMLInputElement>) => {
    itemStore.selectItem.setMaster(e.target.checked);

    let name = '';
    if (!e.target.checked) {
      if (itemStore.selectItem.name.includes('-')) name = itemStore.selectItem.name.split('-')[0];
      else name = itemStore.selectItem.name;
    } else {
      name = `${itemStore.selectItem.name}-${Translator.translate('objectValues.item.itemDialog.master')}`;
    }

    itemStore.selectItem.setName(name);
  };

  const firstAndLastKey = (): { first: boolean; last: boolean } => {
    const first = classStore.treeObject.searchArrays[0].key;
    const last = classStore.treeObject.searchArrays[classStore.treeObject.searchArrays.length - 1].key;

    if (classStore.treeObject.selectedKeys[0] === first) return { first: true, last: false };
    else if (classStore.treeObject.selectedKeys[0] === last) return { first: false, last: true };

    return { first: false, last: false };
  };

  const onUpSearch = () => {
    const findIndex = classStore.treeObject.searchArrays.findIndex((item) => item.key === classStore.treeObject.selectedKeys[0]);

    const key = classStore.treeObject.searchArrays[findIndex - 1].key;
    const info = {
      selectedNodes: [classStore.allClass.find((f) => f.key === key)],
    };

    classStore.treeObject.setSelectedKeys([key], info);
    objectRef.current.scrollTo({ key: key });
  };

  const onDownSearch = () => {
    const findIndex = classStore.treeObject.searchArrays.findIndex((item) => item.key === classStore.treeObject.selectedKeys[0]);

    const key = classStore.treeObject.searchArrays[findIndex + 1].key;
    const info = {
      selectedNodes: [classStore.allClass.find((f) => f.key === key)],
    };

    classStore.treeObject.setSelectedKeys([key], info);
    objectRef.current.scrollTo({ key: key });
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    classStore.treeObject.setSearchValue(event.target.value);
    classStore.treeObject.setClickSearch(false);

    if (event.target.value === '') onClickSearch();
  };

  const onClickSearch = () => {
    const findNodes: ISearch[] = [];
    const newExpandedKeys = classStore.treeObject.expandedKeys;

    classStore.treeObject.getKeys(classStore.treeObject.data).map((item) => {
      if (item.title.toLowerCase().indexOf(classStore.treeObject.searchValue.toLowerCase()) > -1) {
        if (classStore.treeObject.searchValue !== '') {
          findNodes.push({ title: item.title, key: item.key });
          const parentsKeys = classStore.treeObject.getParentsKeys(item.key, classStore.treeObject.data);
          parentsKeys.forEach((parentKey) => {
            if (!newExpandedKeys.includes(parentKey)) newExpandedKeys.push(parentKey);
          });
        }
      }
    });

    classStore.treeObject.setClickSearch(true);
    classStore.treeObject.setSearchArrays(findNodes);
    classStore.treeObject.setExpandedKeys(newExpandedKeys as React.Key[]);

    if (classStore.treeObject.searchArrays.length !== 0) {
      const key = classStore.treeObject.searchArrays[0].key;
      const info = {
        selectedNodes: [classStore.allClass.find((f) => f.key === key)],
      };

      classStore.treeObject.setSelectedKeys([key], info);
      objectRef.current.scrollTo({ key: key });
    }
  };

  return (
    <DialogWrapper
      title={Translator.translate('objectValues.item.itemDialog.createTitle')}
      maxWidth="md"
      open={itemStore.isDialogCreateOpen}
      onClose={onClose}
      onCancel={onClose}
      disableSubmit={!itemStore.canDialogCreateSubmit}
      onSubmit={onSubmit}>
      {itemStore.selectItem.class?.layerId === ObjectLayer.Main && (
        <Toggler
          fullWidth
          value={classStore.classFilters.layerId}
          tabItems={layerFilter}
          onChangeTab={async (value) => {
            classStore.setSelectedLayer(value as ObjectLayer);
            await classStore.getClasses();
            classStore.createClassTree();
          }}
        />
      )}

      {!classStore.waiting && classStore.classFilters.layerId === ObjectLayer.Main ? (
        itemStore.createItems.length === 0 ? (
          <div className={s.infoWrapper}>{Translator.translate('objectValues.item.itemDialog.notFoundMessage.main')}</div>
        ) : (
          <FormGroup>
            {itemStore.createItems.map((el) => {
              const onChangeName = (value: string) => {
                el.setName(value);
              };

              const onChangeMaster = (e: React.ChangeEvent<HTMLInputElement>) => {
                el.setMaster(e.target.checked);

                let name = '';
                if (!e.target.checked) {
                  if (el.name.includes('-')) name = el.name.split('-')[0];
                  else name = el.name;
                } else {
                  name = `${el.name}-${Translator.translate('objectValues.item.itemDialog.master')}`;
                }

                el.setName(name);
              };

              const onSelectClass = (e: React.ChangeEvent<HTMLInputElement>) => {
                el.setSelected(e.target.checked);

                if (e.target.checked && el.class?.name) el.setName(el.class?.name);
                else {
                  el.setName('');
                  el.setMaster(false);
                }
              };

              return (
                <>
                  <Stack key={el.class?.id} flexDirection="row" p="4px" alignItems="center" gap={1}>
                    <FormControlLabel
                      sx={{ width: '40%', m: 0 }}
                      control={<Checkbox checked={el.selected} onChange={onSelectClass} />}
                      label={el.class?.name}
                      labelPlacement="end"
                    />

                    <Input
                      sx={{ width: '40%' }}
                      placeholder={Translator.translate('objectValues.item.itemDialog.dialogFormFields.name')}
                      size="small"
                      value={el.name}
                      onChange={onChangeName}
                    />

                    <Switch
                      disabled={!el.selected}
                      label={Translator.translate('objectValues.item.itemDialog.dialogFormFields.master')}
                      checked={el.isMaster}
                      onChange={onChangeMaster}
                    />
                  </Stack>
                  <Divider sx={{ my: '4px' }} />
                </>
              );
            })}
          </FormGroup>
        )
      ) : classStore.classFilters.layerId === ObjectLayer.Additional ? (
        itemStore.selectItem.class?.layerId ? (
          <div className={s.treeClass}>
            <Stack flexDirection="row" alignItems="center" gap={4}>
              <Input
                size="small"
                placeholder={Translator.translate('objectValues.item.itemDialog.dialogFormFields.name')}
                value={itemStore.selectItem.name}
                onChange={onChangeName}
              />

              <Switch
                label={Translator.translate('objectValues.item.itemDialog.dialogFormFields.master')}
                checked={itemStore.selectItem.isMaster}
                onChange={onChangeMaster}
              />
            </Stack>

            <div className={s.inputWrapper}>
              <InputSearch
                value={classStore.treeObject.searchValue}
                isArrayResult={classStore.treeObject.searchArrays.length > 1}
                disabledPrev={classStore.treeObject.searchArrays.length <= 1 || firstAndLastKey().first}
                disabledNext={classStore.treeObject.searchArrays.length <= 1 || firstAndLastKey().last}
                onPrev={onUpSearch}
                onNext={onDownSearch}
                onSearch={onClickSearch}
                onChange={onSearch}
              />
            </div>

            <ClassTree ref={objectRef} height={300} />
          </div>
        ) : itemStore.createItems.length === 0 ? (
          <div className={s.infoWrapper}>{Translator.translate('objectValues.item.itemDialog.notFoundMessage.additional')}</div>
        ) : (
          <FormGroup>
            {itemStore.createItems.map((el) => {
              const onChangeName = (value: string) => {
                el.setName(value);
              };

              const onChangeMaster = (e: React.ChangeEvent<HTMLInputElement>) => {
                el.setMaster(e.target.checked);

                let name = '';
                if (!e.target.checked) {
                  if (el.name.includes('-')) name = el.name.split('-')[0];
                  else name = el.name;
                } else {
                  name = `${el.name}-${Translator.translate('objectValues.item.itemDialog.master')}`;
                }

                el.setName(name);
              };

              const onSelectClass = (e: React.ChangeEvent<HTMLInputElement>) => {
                el.setSelected(e.target.checked);

                if (e.target.checked && el.class?.name) el.setName(el.class?.name);
                else el.setName('');
              };

              return (
                <>
                  <div key={el.class?.id} className={s.row}>
                    <FormControlLabel
                      sx={{ width: '40%' }}
                      control={<Checkbox checked={el.selected} onChange={onSelectClass} />}
                      label={el.class?.name}
                      labelPlacement="end"
                    />

                    <Input
                      placeholder={Translator.translate('objectValues.item.itemDialog.dialogFormFields.name')}
                      size="small"
                      value={el.name}
                      onChange={onChangeName}
                    />

                    <FormControlLabel
                      control={<Switch checked={el.isMaster} disabled={!el.selected} onChange={onChangeMaster} />}
                      label={Translator.translate('objectValues.item.itemDialog.dialogFormFields.master')}
                      className={s.switchWrapper}
                    />
                  </div>
                  <Divider sx={{ my: '4px' }} />
                </>
              );
            })}
          </FormGroup>
        )
      ) : (
        <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
    </DialogWrapper>
  );
};

export default observer(AddItemDialog);
