import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { HandlerProps, ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { useParams } from 'react-router';
import { Translator, definitionStore, itemStore, parameterGroupStore, parameterValueStore, sectionSetStore, sectionsStore, stageStore } from 'stores';
import { InputSearch, Select, Switch } from 'components';
import { SelectChangeEvent, Stack, Autocomplete, TextField, Paper } from '@mui/material';
import { ProjectType } from 'shared/enums';
import { useWindowDimensions } from 'shared/utils/hooks';
import Utils from 'shared/utils/Utils';
import { ItemTree, ObjectValueTable, ParamGroupFilterTree } from './Components';
import s from './ObjectValuePage.module.scss';

const CLASS_TREE_PANEL_HEIGHT = 140;
const PARAM_TREE_PANEL_HEIGHT = 110;
const SPLITTER = 8;

const ObjectValuePage: React.FC = () => {
  const { width } = useWindowDimensions();
  const filterRef = useRef<any>(null);
  const { currentDefinitionId } = useParams();
  const [filterValue, setFilterValue] = useState<string>('');
  const [itemHeight, setItemHeight] = useState(
    (document.documentElement.clientHeight - Utils.projectHeight() - SPLITTER) / 2 - CLASS_TREE_PANEL_HEIGHT
  );
  const [parameterGroupHeight, setParameterGroupHeight] = useState(
    (document.documentElement.clientHeight - Utils.projectHeight() - SPLITTER) / 2 - PARAM_TREE_PANEL_HEIGHT
  );
  const [inputValue, setInputValue] = React.useState(parameterValueStore.sectionDefaultValue ? parameterValueStore.sectionDefaultValue.name : '');

  useEffect(() => {
    definitionStore.setCurrentDefinitionId(currentDefinitionId ? Number(currentDefinitionId) : null);
    parameterValueStore.initFilterState();

    return () => {
      parameterGroupStore.resetParameterGroup();
      parameterValueStore.resetParameterValueList();
      parameterValueStore.filterStateParamValues.clear();
      itemStore.setExpandedKeys([]);
    };
  }, []);

  useEffect(() => {
    itemStore.setTreeItemWidth(300);
  }, [width]);

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);

    if (event.target.value === '') {
      parameterValueStore.filterStateParamValues.setSearchPattern('');
    }
  };

  const onChangeHide = () => {
    parameterValueStore.filterStateParamValues.setHideEmpty(!parameterValueStore.filterStateParamValues.isHideEmpty);
  };

  const onChangeStage = (e: SelectChangeEvent<number>) => {
    sectionSetStore.clearFilterStageIds();
    parameterValueStore.filterStateParamValues.setSectionSetId(null);
    setInputValue('');
    parameterValueStore.filterStateParamValues.setSectionId(null);

    parameterValueStore.filterStateParamValues.setStageId(e.target.value as number);

    sectionSetStore.setFilterStageId(e.target.value !== '' ? (e.target.value as number) : null);
    sectionSetStore.getSectionSets();

    /*  Делаем активной первую корневую группу параметров  */
    parameterGroupStore.getRootParameterGroupList().then(async () => {
      parameterGroupStore.setCurrentParameterGroup(
        parameterGroupStore.rootParameterGroup.length > 0 ? Number(parameterGroupStore.rootParameterGroup[0].id) : null
      );

      if (!parameterGroupStore.currentParameterGroup) {
        parameterValueStore.filterStateParamValues.setParameterGroupId(null);
      }

      await parameterGroupStore.getParameterGroups().then(() => {
        //раскрытие узла и выставление фильтра (первый элемент в дереве после элемента глобальной группы)
        parameterValueStore.defaultExpandSelectParamGroup();
      });
    });
  };

  const onChangeSectionSet = (e: SelectChangeEvent<number>) => {
    parameterValueStore.filterStateParamValues.setSectionId(null);
    setInputValue('');

    parameterValueStore.filterStateParamValues.setSectionSetId(e.target.value as number);

    sectionsStore.setFilterSectionSetId(parameterValueStore.filterStateParamValues.sectionSetId);
    sectionsStore.getSections();
  };

  const onChangeSection = (value: number | null) => {
    parameterValueStore.filterStateParamValues.setSectionId(value);
    /*  Делаем активной первую корневую группу параметров  */
    parameterGroupStore.getRootParameterGroupList().then(async () => {
      parameterGroupStore.setCurrentParameterGroup(
        parameterGroupStore.rootParameterGroup.length > 0 ? Number(parameterGroupStore.rootParameterGroup[0].id) : null
      );

      if (!parameterGroupStore.currentParameterGroup) {
        parameterValueStore.filterStateParamValues.setParameterGroupId(null);
      }

      await parameterGroupStore.getParameterGroups().then(() => {
        //раскрытие узла и выставление фильтра (первый элемент в дереве после элемента глобальной группы)
        parameterValueStore.defaultExpandSelectParamGroup();
      });
    });
  };

  return (
    <ReflexContainer orientation="vertical">
      <ReflexElement
        size={itemStore.treeItemWidth!}
        onStopResize={(args: HandlerProps) => itemStore.setTreeItemWidth((args.domElement as Element).clientWidth)}>
        <ReflexContainer orientation="horizontal" style={{ display: 'flex', gap: 2 }}>
          <ReflexElement
            className={s.itemWrapper}
            onStopResize={(args: HandlerProps) => setItemHeight((args.domElement as Element).clientHeight - CLASS_TREE_PANEL_HEIGHT)}>
            <ItemTree itemHeight={itemHeight} />
          </ReflexElement>

          <ReflexSplitter className={s.splitterHorizontal} />

          <ReflexElement
            className={s.itemWrapper}
            onStopResize={(args: HandlerProps) => setParameterGroupHeight((args.domElement as Element).clientHeight - PARAM_TREE_PANEL_HEIGHT)}>
            <ParamGroupFilterTree parameterGroupHeight={parameterGroupHeight} />
          </ReflexElement>
        </ReflexContainer>
      </ReflexElement>

      <ReflexSplitter className={s.splitterVertical} />

      <ReflexElement>
        <Paper elevation={0} sx={{ p: 6, borderRadius: '12px', width: '100%', height: '100%' }}>
          <Stack ref={filterRef} mb={2} flexDirection="row" flexWrap="wrap" justifyContent="space-between" gap={2}>
            <Stack height="38px" flexDirection="row" gap={5} justifyContent="space-between">
              <Select
                sx={{
                  width: '100%',
                  backgroundColor: '#F2F2F9',
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  borderRadius: '6px',
                  minWidth: 220,
                }}
                label={Translator.translate('objectValues.selectStageLabel')}
                readOnly={definitionStore.currentDefinition.projectType !== ProjectType.Standard}
                value={parameterValueStore.filterStateParamValues.stageId ?? ''}
                options={stageStore.stageForOptions}
                onChange={onChangeStage}
                size="small"
              />

              <Select
                sx={{
                  width: '100%',
                  backgroundColor: '#F2F2F9',
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  borderRadius: '6px',
                  minWidth: 220,
                  maxWidth: 220,
                }}
                label={Translator.translate('objectValues.selectSectionSetLabel')}
                disabled={!parameterValueStore.filterStateParamValues.stageId}
                value={parameterValueStore.filterStateParamValues.sectionSetId ?? ''}
                options={sectionSetStore.sectionSetsForOptions}
                onChange={onChangeSectionSet}
                size="small"
              />

              <Autocomplete
                sx={{
                  width: '100%',
                  backgroundColor: '#F2F2F9',
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  borderRadius: '6px',
                  minWidth: 220,
                }}
                size="small"
                options={parameterValueStore.sectionOptions}
                openOnFocus
                noOptionsText={Translator.translate('objectValues.sectionNotFound')}
                getOptionLabel={(option) => option.name}
                disabled={!parameterValueStore.filterStateParamValues.sectionSetId}
                inputValue={inputValue}
                onInputChange={(e, inputValue) => {
                  setInputValue(inputValue);
                }}
                value={parameterValueStore.sectionDefaultValue || null}
                onChange={(e, value) => {
                  onChangeSection(value ? (value?.id as number) : null);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                clearOnBlur={false}
                renderInput={(params) => <TextField {...params} label={Translator.translate('objectValues.selectSectionsLabel')} />}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
              />

              {filterRef.current?.offsetHeight > 42 && (
                <InputSearch
                  value={filterValue}
                  onSearch={() => parameterValueStore.filterStateParamValues.setSearchPattern(filterValue)}
                  onChange={onSearch}
                />
              )}
            </Stack>

            <Stack height="38px" flexDirection="row" gap={5}>
              <Switch
                label={Translator.translate('objectValues.hideEmpty')}
                labelPlacement="end"
                checked={parameterValueStore.filterStateParamValues.isHideEmpty}
                onChange={onChangeHide}
              />

              {/*<Switch
                label={Translator.translate('objectValues.availableMe')}
                labelPlacement="end"
                checked={}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  //
                }
              />*/}

              {filterRef.current?.offsetHeight < 84 && (
                <InputSearch
                  value={filterValue}
                  onSearch={() => parameterValueStore.filterStateParamValues.setSearchPattern(filterValue)}
                  onChange={onSearch}
                />
              )}
            </Stack>
          </Stack>

          {filterRef.current?.offsetHeight && <ObjectValueTable filterHeight={filterRef.current?.offsetHeight} />}
        </Paper>
      </ReflexElement>
    </ReflexContainer>
  );
};

export default observer(ObjectValuePage);
