import React, { useState } from 'react';
import ExpandIcon from 'components/Icons/ExpandIcon';
import IconButtonNew from 'components/UI/IconButtonNew';
import { Collapse, ListItemButton, Stack, Typography } from '@mui/material';
import { NestedLevel } from 'shared/enums';
import { IGroupSettingList } from 'shared/interfaces';

interface INestedItemRowProps {
  row: IGroupSettingList;
  onSelectRow: (selectedRow: IGroupSettingList) => void;
}

const NestedItemRow = (props: INestedItemRowProps) => {
  const { row, onSelectRow } = props;

  const [open, setOpen] = useState(row.isExpand);

  const onClickRow = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    if (row.children && row.children.length !== 0) {
      setOpen(!open);
    }

    onSelectRow(row);
  };

  const getPadding = (): number => {
    if (row.children && row.children.length !== 0) {
      if (row.nestedLevel === NestedLevel.First) return 0;
      else return 4;
    } else {
      if (row.nestedLevel === NestedLevel.First) return 8;
      else if (row.nestedLevel === NestedLevel.Second) return 12;
      else return 16;
    }
  };

  return (
    <>
      <ListItemButton
        sx={(theme) => ({
          borderRadius: location.pathname === row.key ? '6px' : '',
          backgroundColor: location.pathname === row.key ? 'rgba(123, 104, 238, 0.12)' : '',
          padding: '4px 0px',
          ':hover': {
            borderRadius: '6px',
            backgroundColor: `1px solid ${theme.palette.grey[200]}`,
          },
        })}
        key={row.key}
        selected={location.pathname === row.key}
        disableRipple
        onClick={onClickRow}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          gap={1}
          width="100%"
          minHeight="28px"
          flexWrap="nowrap"
          pl={getPadding()}>
          {row.children && (
            <IconButtonNew
              sx={{ p: 0, transform: `rotate(${open ? 0 : 270}deg)` }}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(!open);
              }}>
              <ExpandIcon />
            </IconButtonNew>
          )}

          <Typography
            variant="body2"
            sx={(theme) => ({
              color: location.pathname === row.key ? theme.palette.primary.dark : '',
            })}>
            {row.name}
          </Typography>
        </Stack>
      </ListItemButton>

      {row.children && row.children.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {row.children.map((group) => {
            return <NestedItemRow key={group.key} row={group} onSelectRow={onSelectRow} />;
          })}
        </Collapse>
      )}
    </>
  );
};

export default NestedItemRow;
