import { makeAutoObservable, runInAction } from 'mobx';
import { UsersService } from 'services';
import { Translator, toastStore } from 'stores';
import { IGetUserDto, IUserFilters, ISelectOption } from 'shared/interfaces';
import { UserListModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

class UsersStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isUsersLoading = false;

  public isDialogOpen = false;

  public userInfo: IGetUserDto = {} as IGetUserDto;

  public users: IGetUserDto[] = [];

  public filterName = '';

  public selectedUser = new UserListModel();

  public isShowAvailableInfo = false;

  public usersFilters: IUserFilters = { id: null };

  public get usersForOptions(): ISelectOption[] {
    const sortClasses = Utils.sortByField(this.users, 'name');

    return sortClasses.map((item) => {
      return {
        id: item.id,
        name: item.name,
      } as ISelectOption;
    });
  }

  public get userInitials(): string {
    if (this.userInfo) return Utils.getInitials(this.getUserData().name);
    else return '';
  }

  public get filterUsers(): IGetUserDto[] {
    return this.users.filter((user) => user.name.toLowerCase().includes(this.filterName.toLowerCase()));
  }

  public setFilterNameUser(value: string) {
    this.filterName = value;
  }

  public setLoading(loading: boolean) {
    this.isUsersLoading = loading;
  }

  public setUserFiltersId(id: number | null) {
    this.usersFilters.id = id;
  }

  public setDialogOpen(isOpen: boolean) {
    this.isDialogOpen = isOpen;
  }

  public setShowAvailableInfo(value: boolean) {
    this.isShowAvailableInfo = value;
  }

  public getUserData(): IGetUserDto {
    if (!localStorage.getItem('userData'))
      return {
        id: null,
        login: '',
        name: '',
        email: '',
        roles: [],
        regions: [],
        projects: [],
        informationBlocks: [],
      };
    else return JSON.parse(localStorage.getItem('userData')!);
  }

  public async getMe(): Promise<void> {
    try {
      const result = await UsersService.getMe();
      if (!result) return;

      const userData: IGetUserDto = {
        id: result.id,
        name: result.name,
        login: result.login,
        email: result.email,
        roles: result.roles,
        regions: result.regions,
        projects: result.projects,
        informationBlocks: result.informationBlocks,
      };

      localStorage.setItem('userData', JSON.stringify(userData));

      runInAction(() => {
        this.userInfo = result;
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Me`);
    } finally {
    }
  }

  public async updateUser(): Promise<void> {
    if (!this.selectedUser.id || !this.selectedUser.putDto) return;

    try {
      await UsersService.putUser(this.selectedUser.id, this.selectedUser.putDto);

      this.getUsersList();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} User`);
    } finally {
    }
  }

  public async getUsersList() {
    try {
      const result = await UsersService.getUsers(this.usersFilters);
      if (!result) return;

      runInAction(() => (this.users = result));
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Users`);
    } finally {
    }
  }
}

export default new UsersStore();
