import React from 'react';

const RuleIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 2C1 1.44772 1.44772 1 2 1H6C6.55228 1 7 1.44772 7 2V6C7 6.55228 6.55228 7 6 7H5V14C5 14.5523 5.44772 15 6 15H7.58579L7.29289 14.7071C6.90237 14.3166 6.90237 13.6834 7.29289 13.2929C7.68342 12.9024 8.31658 12.9024 8.70711 13.2929L10.7071 15.2929C11.0976 15.6834 11.0976 16.3166 10.7071 16.7071L8.70711 18.7071C8.31658 19.0976 7.68342 19.0976 7.29289 18.7071C6.90237 18.3166 6.90237 17.6834 7.29289 17.2929L7.58579 17H6C4.34315 17 3 15.6569 3 14V7H2C1.44772 7 1 6.55228 1 6V2ZM5 5H3V3H5V5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7071 6.70711C12.3166 7.09763 11.6834 7.09763 11.2929 6.70711L9.29289 4.70711C8.90237 4.31658 8.90237 3.68342 9.29289 3.29289L11.2929 1.29289C11.6834 0.902369 12.3166 0.902369 12.7071 1.29289C13.0976 1.68342 13.0976 2.31658 12.7071 2.70711L12.4142 3L14 3C15.6569 3 17 4.34315 17 6V13.1707C18.1652 13.5825 19 14.6938 19 16C19 17.6569 17.6569 19 16 19C14.3431 19 13 17.6569 13 16C13 14.6938 13.8348 13.5825 15 13.1707L15 6C15 5.44772 14.5523 5 14 5H12.4142L12.7071 5.29289C13.0976 5.68342 13.0976 6.31658 12.7071 6.70711ZM15 16C15 15.4477 15.4477 15 16 15C16.5523 15 17 15.4477 17 16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16Z"
      />
    </svg>
  );
};

export default RuleIcon;
