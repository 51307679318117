import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, auditStore, classStore } from 'stores';
import { ClassTree, InputSearch, Toggler } from 'components';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import HistoryIcon from 'components/Icons/HistoryIcon';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import TrashIcon from 'components/Icons/TrashIcon';
import { Box, Button, Paper, Stack } from '@mui/material';
import { AuditTables, DeleteClassMode, ObjectLayer, ObjectLayerNames } from 'shared/enums';
import { ISearch } from 'shared/interfaces';
import { ClassModel } from 'shared/models';
import Utils from 'shared/utils/Utils';
import { AddClassDialog, EditClassDialog, DeleteClassDialog } from './Components';

const HEIGHT_CONTENT = 132;
const HEIGHT_TAB = 45;

const ClassPage = () => {
  const objectRef = useRef<any>();

  const layerFilter = [
    {
      value: ObjectLayer.Main,
      label: Translator.translate(ObjectLayerNames.get(ObjectLayer.Main)),
    },
    {
      value: ObjectLayer.Additional,
      label: Translator.translate(ObjectLayerNames.get(ObjectLayer.Additional)),
    },
  ];

  useEffect(() => {
    const init = async () => {
      classStore.setSelectedLayer(ObjectLayer.Main);
      await classStore.getClasses();
      classStore.createClassTree();
    };
    init();

    return () => {
      classStore.treeObject.clearModel();
      classStore.classObject.clearModel();
    };
  }, []);

  useEffect(() => {
    classStore.treeObject.clearModel();
  }, [classStore.classFilters.layerId]);

  const onAdd = (value?: ClassModel) => {
    if (value) {
      classStore.treeObject.selectedKeys = [value.key];
      classStore.treeObject.selected = value;
    }

    classStore.setIsDialogCreateOpen(true);
  };

  const onCheck = (checkedKeysValue: any, info: any) => {
    classStore.treeObject.setCheckedKeys(checkedKeysValue.checked, info);
  };

  const deleteNode = async (radio: DeleteClassMode) => {
    //radio === DeleteClassMode.DeleteAll - удалить везде delete
    //radio === DeleteChecked - удалить выбранный put
    if (radio === DeleteClassMode.DeleteAll) await classStore.deleteAllClass();
    else await classStore.deleteCheckedClass();
  };

  const onSubmit = (radio: DeleteClassMode) => {
    deleteNode(radio);
    classStore.setIsDialogDeleteOpen(false);
  };

  const onClose = () => {
    classStore.setIsDialogDeleteOpen(false);
  };

  const onDelete = (value?: ClassModel) => {
    if (value) {
      classStore.treeObject.checkedKeys = [value.key];
      classStore.treeObject.checkedNodesInfo = [value];
    }

    classStore.setIsDialogDeleteOpen(true);
  };

  const onEdit = async (value?: ClassModel) => {
    if (value) {
      classStore.treeObject.selectedKeys = [value.key];
      classStore.treeObject.selected = value;
    }

    await classStore.getClassById();
    classStore.setIsDialogEditOpen(true);
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    classStore.treeObject.setSearchValue(event.target.value);
    classStore.treeObject.setClickSearch(false);

    if (event.target.value === '') onClickSearch();
  };

  const onClickSearch = () => {
    const findNodes: ISearch[] = [];
    const newExpandedKeys = classStore.treeObject.expandedKeys;

    classStore.treeObject.getKeys(classStore.treeObject.data).map((item) => {
      if (item.title.toLowerCase().indexOf(classStore.treeObject.searchValue.toLowerCase()) > -1) {
        if (classStore.treeObject.searchValue !== '') {
          findNodes.push({ title: item.title, key: item.key });
          const parentsKeys = classStore.treeObject.getParentsKeys(item.key, classStore.treeObject.data);
          parentsKeys.forEach((parentKey) => {
            if (!newExpandedKeys.includes(parentKey)) newExpandedKeys.push(parentKey);
          });
        }
      }
    });

    classStore.treeObject.setClickSearch(true);
    classStore.treeObject.setSearchArrays(findNodes);
    classStore.treeObject.setExpandedKeys(newExpandedKeys as React.Key[]);

    if (classStore.treeObject.searchArrays.length !== 0) {
      const key = classStore.treeObject.searchArrays[0].key;
      const info = {
        selectedNodes: [classStore.allClass.find((f) => f.key === key)],
      };

      classStore.treeObject.setSelectedKeys([key], info);
      objectRef.current.scrollTo({ key: key });
    }
  };

  const onChangeObjectLayer = async (value: string | number) => {
    classStore.treeObject.clearModel();
    classStore.classObject.clearModel();
    classStore.setSelectedLayer(value as ObjectLayer);
    await classStore.getClasses();
    classStore.createClassTree();
  };

  const firstAndLastKey = (): { first: boolean; last: boolean } => {
    const first = classStore.treeObject.searchArrays[0].key;
    const last = classStore.treeObject.searchArrays[classStore.treeObject.searchArrays.length - 1].key;

    if (classStore.treeObject.selectedKeys[0] === first) return { first: true, last: false };
    else if (classStore.treeObject.selectedKeys[0] === last) return { first: false, last: true };

    return { first: false, last: false };
  };

  const onUpSearch = () => {
    const findIndex = classStore.treeObject.searchArrays.findIndex((item) => item.key === classStore.treeObject.selectedKeys[0]);

    const key = classStore.treeObject.searchArrays[findIndex - 1].key;
    const info = {
      selectedNodes: [classStore.allClass.find((f) => f.key === key)],
    };

    classStore.treeObject.setSelectedKeys([key], info);
    objectRef.current.scrollTo({ key: key });
  };

  const onDownSearch = () => {
    const findIndex = classStore.treeObject.searchArrays.findIndex((item) => item.key === classStore.treeObject.selectedKeys[0]);

    const key = classStore.treeObject.searchArrays[findIndex + 1].key;
    const info = {
      selectedNodes: [classStore.allClass.find((f) => f.key === key)],
    };

    classStore.treeObject.setSelectedKeys([key], info);
    objectRef.current.scrollTo({ key: key });
  };

  const onHistoryView = (value?: ClassModel) => {
    if (value) {
      classStore.treeObject.selectedKeys = [value.key];
      classStore.treeObject.selected = value;
    }

    auditStore.setDrawerOpen(true);
    auditStore.setAuditFilters(AuditTables.Classes, classStore.treeObject.selected.id);
  };

  return (
    <Box height="100%">
      <Box sx={{ mb: 2 }}>
        <Toggler btnWidth="230px" value={classStore.classFilters.layerId!} tabItems={layerFilter} onChangeTab={onChangeObjectLayer} />
      </Box>

      <Paper elevation={0} sx={{ p: 6, borderRadius: '12px', height: `calc(100vh - ${HEIGHT_TAB + Utils.projectHeight()}px)` }} component={Stack}>
        <Stack justifyContent="space-between" flexDirection="row" mb={2}>
          <Stack gap={2} flexDirection="row">
            <Button
              variant="text"
              startIcon={
                <Box
                  sx={{
                    svg: {
                      fill:
                        classStore.treeObject.checkedKeys.length > 0 || classStore.treeObject.selectedKeys.length === 0
                          ? 'rgba(0, 0, 0, 0.26)'
                          : '#7B68EE',
                    },
                  }}>
                  <EditPencilIcon />
                </Box>
              }
              onClick={() => onEdit()}
              disabled={classStore.treeObject.checkedKeys.length > 0 || classStore.treeObject.selectedKeys.length === 0}>
              {Translator.translate('actions.edit')}
            </Button>

            <Button
              variant="text"
              sx={{
                svg: {
                  fill: classStore.treeObject.checkedKeys.length === 0 ? 'rgba(0, 0, 0, 0.26)' : '#7B68EE',
                },
              }}
              startIcon={<TrashIcon />}
              onClick={() => onDelete()}
              disabled={classStore.treeObject.checkedKeys.length === 0}>
              {Translator.translate('actions.delete')}
            </Button>

            <Button
              variant="text"
              sx={{
                svg: {
                  fill: classStore.treeObject.selectedKeys.length === 0 ? 'rgba(0, 0, 0, 0.26)' : '#7B68EE',
                },
              }}
              startIcon={<HistoryIcon />}
              onClick={() => onHistoryView()}
              disabled={classStore.treeObject.selectedKeys.length === 0}>
              {Translator.translate('actions.changeHistory')}
            </Button>
          </Stack>

          <Stack flexDirection="row" height="36px" gap={6}>
            <Box mb={1} minWidth={240}>
              <InputSearch
                value={classStore.treeObject.searchValue}
                isArrayResult={classStore.treeObject.searchArrays.length > 1}
                disabledPrev={classStore.treeObject.searchArrays.length <= 1 || firstAndLastKey().first}
                disabledNext={classStore.treeObject.searchArrays.length <= 1 || firstAndLastKey().last}
                onPrev={onUpSearch}
                onNext={onDownSearch}
                onSearch={onClickSearch}
                onChange={onSearch}
              />
            </Box>

            <Box minWidth={120}>
              <Button variant="contained" startIcon={<PlusIconForButton />} onClick={() => onAdd()}>
                {Translator.translate('classes.addClassButtonTitle')}
              </Button>
            </Box>
          </Stack>
        </Stack>

        {classStore.treeObject.data.length !== 0 && (
          <>
            <ClassTree
              ref={objectRef}
              height={document.documentElement.clientHeight - Utils.projectHeight() - HEIGHT_CONTENT}
              isChecked
              onCheck={onCheck}
              onAdd={onAdd}
              onDelete={onDelete}
              onEdit={onEdit}
              onHistory={onHistoryView}
            />
          </>
        )}
      </Paper>

      {classStore.isDialogCreateOpen && <AddClassDialog />}
      {classStore.isDialogEditOpen && <EditClassDialog />}
      {<DeleteClassDialog onSubmit={onSubmit} onClose={onClose} />}
    </Box>
  );
};

export default observer(ClassPage);
