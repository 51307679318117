import React, { useRef, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Translator, informationBlockStore, sectionSetStore, xmlDocumentInstanceStore } from 'stores';
import { InputSearch, Toggler } from 'components';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import { Box, Button, Paper, Stack } from '@mui/material';

import { Routes } from 'shared/enums/Routes';
import { ITabItem } from 'shared/interfaces';
import { useWindowDimensions } from 'shared/utils/hooks';
import s from './CatalogPage.module.scss';

const CatalogPage = () => {
  const location = useLocation();
  const filterRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { currentDefinitionId } = useParams();

  useEffect(() => {
    if (location.pathname === Routes.CATALOG || location.pathname === `/${currentDefinitionId}${Routes.CATALOG}`) {
      navigate(currentDefinitionId ? `/${currentDefinitionId}${Routes.CATALOG}/${Routes.DICTIONARIES}` : `${Routes.CATALOG}/${Routes.DICTIONARIES}`);
    }
  }, []);

  const getTemplateForList = (): string => {
    if (currentDefinitionId) {
      if (!sectionSetStore.currentSectionSet?.id) return `/${currentDefinitionId}${Routes.CATALOG}/${Routes.TEMPLATES}`;
      else return `/${currentDefinitionId}${Routes.CATALOG}/${Routes.TEMPLATES}/${sectionSetStore.currentSectionSet?.id}`;
    } else {
      if (!sectionSetStore.currentSectionSet?.id) return `${Routes.CATALOG}/${Routes.TEMPLATES}`;
      else return `${Routes.CATALOG}/${Routes.TEMPLATES}/${sectionSetStore.currentSectionSet?.id}`;
    }
  };

  const getXMLDocumentForList = (): string => {
    if (currentDefinitionId) {
      if (!xmlDocumentInstanceStore.selectedXMLDocument?.id) return `/${currentDefinitionId}${Routes.CATALOG}/${Routes.XML_DOCUMENT}`;
      else return `/${currentDefinitionId}${Routes.CATALOG}/${Routes.XML_DOCUMENT}/${xmlDocumentInstanceStore.selectedXMLDocument?.id}`;
    } else {
      if (!xmlDocumentInstanceStore.selectedXMLDocument?.id) return `${Routes.CATALOG}/${Routes.XML_DOCUMENT}`;
      else return `${Routes.CATALOG}/${Routes.XML_DOCUMENT}/${xmlDocumentInstanceStore.selectedXMLDocument?.id}`;
    }
  };

  const catalogList = (): ITabItem[] => {
    return [
      {
        value: currentDefinitionId ? `/${currentDefinitionId}${Routes.CATALOG}/${Routes.DICTIONARIES}` : `${Routes.CATALOG}/${Routes.DICTIONARIES}`,
        label: Translator.translate('catalogs.classifiers.title'),
      },
      {
        value: currentDefinitionId ? `/${currentDefinitionId}${Routes.CATALOG}/${Routes.STAGES}` : `${Routes.CATALOG}/${Routes.STAGES}`,
        label: Translator.translate('catalogs.stages.title'),
      },
      {
        value: getTemplateForList(),
        label: Translator.translate('catalogs.specTemplates.title'),
      },
      {
        value: currentDefinitionId ? `/${currentDefinitionId}${Routes.CATALOG}/${Routes.UNITS}` : `${Routes.CATALOG}/${Routes.UNITS}`,
        label: Translator.translate('catalogs.units.title'),
      },
      {
        value: currentDefinitionId ? `/${currentDefinitionId}${Routes.CATALOG}/${Routes.DOCUMENTS}` : `${Routes.CATALOG}/${Routes.DOCUMENTS}`,
        label: Translator.translate('catalogs.documents.title'),
      },
      {
        value: currentDefinitionId
          ? `/${currentDefinitionId}${Routes.CATALOG}/${Routes.INFORMATION_BLOCKS}`
          : `${Routes.CATALOG}/${Routes.INFORMATION_BLOCKS}`,
        label: Translator.translate('catalogs.infBlocks.title'),
      },
      {
        value: getXMLDocumentForList(),
        label: Translator.translate('catalogs.xmlDocuments.title'),
      },
    ];
  };

  useEffect(() => {
    if (location.pathname.includes(Routes.INFORMATION_BLOCKS)) {
      informationBlockStore.setFilterHeight(filterRef.current?.offsetHeight ? filterRef.current?.offsetHeight : null);
    } else if (location.pathname.includes(Routes.TEMPLATES)) {
      sectionSetStore.setFilterHeight(filterRef.current?.offsetHeight ? filterRef.current?.offsetHeight : null);
    }
  }, [width, filterRef.current?.offsetHeight]);

  const onChangeCatalogItem = (value: string) => {
    navigate(value);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    sectionSetStore.setFilterName(event.target.value);
    updateSectionSetCollection();
  };

  const updateSectionSetCollection = useCallback(
    debounce(() => {
      sectionSetStore.filteredSectionSetCollectionCollections();
    }, 350),
    []
  );

  const getTemplateRoute = (): string => {
    return currentDefinitionId ? `/${currentDefinitionId}${Routes.CATALOG}/${Routes.TEMPLATES}` : `${Routes.CATALOG}/${Routes.TEMPLATES}`;
  };

  const getInfBlockRoute = (): string => {
    return currentDefinitionId
      ? `/${currentDefinitionId}${Routes.CATALOG}/${Routes.INFORMATION_BLOCKS}`
      : `${Routes.CATALOG}/${Routes.INFORMATION_BLOCKS}`;
  };

  return (
    <Paper elevation={0} className={s.mainWrapper}>
      <Stack ref={filterRef} flexDirection="row" justifyContent="space-between" flexWrap="wrap" gap={4} mb={3}>
        <Toggler value={location.pathname} tabItems={catalogList()} onChangeTab={(value: any) => onChangeCatalogItem(value as string)} />

        {location.pathname === getTemplateRoute() && (
          <Stack direction="row" gap={5}>
            <Box width="200px">
              <InputSearch value={sectionSetStore.filterName} onSearch={() => console.log('1')} onChange={onChange} />
            </Box>

            <Button variant="contained" startIcon={<PlusIconForButton />} onClick={() => sectionSetStore.setDialogOpen(true)}>
              {Translator.translate('catalogs.specTemplates.createTitle')}
            </Button>
          </Stack>
        )}

        {location.pathname === getInfBlockRoute() && (
          <Stack direction="row" gap={5}>
            <Box width="200px">
              <InputSearch
                value={informationBlockStore.filterName}
                onSearch={() => console.log('1')}
                onChange={(e) => informationBlockStore.setFilterName(e.target.value)}
              />
            </Box>

            <Button variant="contained" startIcon={<PlusIconForButton />} onClick={() => informationBlockStore.setDialogOpen(true)}>
              {Translator.translate('actions.add')}
            </Button>
          </Stack>
        )}
      </Stack>

      <div>
        <Outlet />
      </div>
    </Paper>
  );
};

export default observer(CatalogPage);
