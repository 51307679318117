import React, { useEffect } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Translator, measurementUnitStore } from 'stores';
import { IconButtonNew, InputSearch, NestedTable } from 'components';
import { useConfirm } from 'components/App/Confirm';

import EditPencilIcon from 'components/Icons/EditPencilIcon';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import TrashIcon from 'components/Icons/TrashIcon';
import { Box, Button, Stack } from '@mui/material';
import { MeasurementUnitTypeNames } from 'shared/enums';
import { IMeasurementUnit, ITableColumn } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';
import MeasurementUnitDialog from './Components';

import s from './MeasurementUnits.module.scss';

const HEIGHT_CONTENT = 148;

const MeasurementUnits: React.FC = () => {
  const { isDialogOpen } = measurementUnitStore;
  const confirm = useConfirm();

  useEffect(() => {
    measurementUnitStore.getMeasurementUnits();

    return () => {
      runInAction(() => {
        measurementUnitStore.filterName = '';
      });
    };
  }, []);

  const columns: ITableColumn[] = [
    {
      key: 'name',
      label: Translator.translate('catalogs.units.columns.name'),
      align: 'left',
      width: '400px',
      minWidth: '150px',
    },
    {
      key: 'fullName',
      label: Translator.translate('catalogs.units.columns.fullName'),
      align: 'left',
      width: 'auto',
      minWidth: '400px',
      cell: (row: IMeasurementUnit) => {
        return <div>{row.fullName}</div>;
      },
    },
    {
      key: 'okei',
      label: Translator.translate('catalogs.units.columns.okei'),
      align: 'left',
      width: 'auto',
      minWidth: '400px',
      cell: (row: IMeasurementUnit) => {
        return <div>{row.okei}</div>;
      },
    },
    {
      key: 'type',
      label: Translator.translate('catalogs.units.columns.type'),
      align: 'left',
      width: 'auto',
      minWidth: '400px',
      cell: (row: IMeasurementUnit) => {
        return <div>{Translator.translate(MeasurementUnitTypeNames.get(row.type))}</div>;
      },
    },
    {
      key: 'actions',
      label: '',
      width: '200px',
      minWidth: '200px',
      align: 'right',
      cell: (row: IMeasurementUnit) => {
        const removeMeasurementUnit = () => {
          confirm.show(Translator.translate('catalogs.units.confirmMessage.deleteUnit'), Translator.translate('actions.delete')).then(() => {
            measurementUnitStore.removeMeasurementUnit(row.id!);
          });
        };

        return (
          <Stack
            mr={2}
            flexDirection="row"
            gap={2}
            justifyContent="flex-end"
            alignItems="center"
            sx={(theme) => ({
              'button svg': {
                ':hover': {
                  fill: theme.palette.primary.main,
                  fillOpacity: 1,
                },
              },
            })}>
            <IconButtonNew tooltip={Translator.translate('actions.edit')} onClick={() => measurementUnitStore.setEditingMode(row.id!)}>
              <EditPencilIcon />
            </IconButtonNew>

            <IconButtonNew tooltip={Translator.translate('actions.delete')} onClick={removeMeasurementUnit}>
              <TrashIcon />
            </IconButtonNew>
          </Stack>
        );
      },
    },
  ];

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    runInAction(() => {
      measurementUnitStore.filterName = event.target.value;
    });
  };

  const onOpenCreateDialog = () => {
    measurementUnitStore.setDialogOpen(true);
  };

  return (
    <>
      {
        <Box className={s.toolBarWrapper}>
          <Box className={s.searchWrapper}>
            <InputSearch value={measurementUnitStore.filterName} onSearch={() => console.log('1')} onChange={onChangeSearch} />
          </Box>

          <Button variant="contained" startIcon={<PlusIconForButton />} onClick={onOpenCreateDialog}>
            {Translator.translate('actions.add')}
          </Button>
        </Box>
      }

      <Box sx={{ height: `calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight()}px)`, width: '100%' }}>
        <NestedTable<IMeasurementUnit>
          columns={columns}
          tableData={measurementUnitStore.filterMeasurementUnits}
          isLoading={measurementUnitStore.isLoading && !isDialogOpen}
          hasFilteredData={measurementUnitStore.filterName !== ''}
          isActionsHover
          isExpandOnClickRow
        />
      </Box>

      {isDialogOpen && <MeasurementUnitDialog />}
    </>
  );
};

export default observer(MeasurementUnits);
