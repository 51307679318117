import { BASE_PATH } from 'shared/constants/constants';
import { IConditionListDto, IConditionParam, IDeleteConditionsBatchDto, IPostConditionDto } from 'shared/interfaces';

import HTTPService from './HttpService';

class ConditionService extends HTTPService {
  constructor() {
    super(BASE_PATH.CONDITION);
  }

  public getConditions(params?: IConditionParam): Promise<IConditionListDto[]> {
    return this.GET('', { params });
  }

  public postConditions(body: IPostConditionDto): Promise<void> {
    return this.POST('', body);
  }

  public deleteConditionsBatch(body: IDeleteConditionsBatchDto): Promise<number> {
    return this.DELETE('batch', body);
  }
}

export default new ConditionService();
