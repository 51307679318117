import React from 'react';

const SortingColumnDescIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 10.5858L12.2929 7.29289C12.6834 6.90237 13.3166 6.90237 13.7071 7.29289C14.0976 7.68342 14.0976 8.31658 13.7071 8.70711L8.70711 13.7071C8.31658 14.0976 7.68342 14.0976 7.29289 13.7071L2.29289 8.70711C1.90237 8.31658 1.90237 7.68342 2.29289 7.29289C2.68342 6.90237 3.31658 6.90237 3.70711 7.29289L7 10.5858L7 3C7 2.44772 7.44772 2 8 2C8.55229 2 9 2.44772 9 3L9 10.5858Z" />
    </svg>
  );
};

export default SortingColumnDescIcon;
