import React, { useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { Translator, measurementUnitStore, parameterStore } from 'stores';
import { Select, DialogWrapper, InputValidate } from 'components';
import { SelectChangeEvent } from '@mui/material';
import { DataType } from 'shared/enums';
import { IParameter, IParameterDialog } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';

const AddParameterDialog: React.FC = () => {
  const formRef = useRef<FormikProps<IParameterDialog>>(null);

  const onClose = () => {
    parameterStore.setOpenAddParameterDialog(false);
  };

  const onSubmit = async (values: IParameterDialog) => {
    parameterStore.selectedParameterTable.updateParameterDialogForm(values);

    if (parameterStore.selectedParameterTable.groupId === null) {
      parameterStore.selectedParameterTable.setGroupId(parameterStore.filterParameters.parameterGroupId);
    } else parameterStore.selectedParameterTable.setGroupId(parameterStore.selectedParameterTable.groupId);

    let orderNum!: number;

    const parameters = parameterStore.parameters.filter(
      (parameter) => (parameter as IParameter).groupId === parameterStore.selectedParameterTable.groupId
    );

    if (!parameterStore.selectedParameterTable.orderNum) {
      if (parameters.length === 0) orderNum = 1;
      else orderNum = (parameters[parameters.length - 1] as IParameter).orderNum! + 1;
    } else {
      orderNum = parameterStore.selectedParameterTable.orderNum + 1;
    }

    parameterStore.selectedParameterTable.setOrderNum(orderNum);

    await parameterStore.createParameter();

    onClose();
  };

  const parameterForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        name: parameterStore.selectedParameterTable.name ?? '',
        dataType: parameterStore.selectedParameterTable.dataType ?? DataType.String,
        measurementUnitId: parameterStore.selectedParameterTable.measurementUnitId,
        description: parameterStore.selectedParameterTable.description ?? '',

        dataTypeOpt: parameterStore.dataTypeForOptions,
        measurementUnitOpt: measurementUnitStore.measurementForOptions,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required(Translator.translate('validationMessage.required')),
      })}>
      {({ handleChange, handleBlur, values, isValid, errors, touched, setFieldValue }) => {
        return (
          <>
            <InputValidate
              sx={{ mb: 2 }}
              name="name"
              error={Boolean(touched.name && errors.name)}
              helperText={(touched.name as any) && errors.name}
              label={Translator.translate('params.parameter.columns.name')}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <Select
              name="dataType"
              fullWidth
              label={Translator.translate('params.parameter.columns.dataType')}
              size="medium"
              required
              value={values.dataType}
              isShowIconType
              options={values.dataTypeOpt}
              hideNotSelectItem
              onChange={handleChange}
            />

            <Select
              name="measurementUnitId"
              fullWidth
              label={Translator.translate('params.parameter.columns.measurementUnit')}
              size="medium"
              value={values.measurementUnitId}
              options={values.measurementUnitOpt}
              onChange={(e: SelectChangeEvent<any>) => {
                e.preventDefault();

                setFieldValue('measurementUnitId', e.target.value === '' ? null : e.target.value);
              }}
            />

            <InputValidate
              name="description"
              label={Translator.translate('params.parameter.columns.description')}
              value={values.description}
              onChange={handleChange}
            />
          </>
        );
      }}
    </Formik>
  );

  return (
    <DialogWrapper
      title={Translator.translate('params.parameter.addParameterDialogTitle')}
      maxWidth="md"
      open={parameterStore.isDialogAddParameter}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}>
      {parameterForm}
    </DialogWrapper>
  );
};

export default observer(AddParameterDialog);
