import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { MultipleSelect } from 'components';
import { SelectChangeEvent } from '@mui/material';
import { ISelectOption } from 'shared/interfaces';

export interface ICustomMultipleSelectProps {
  value: any;
  options: ISelectOption[];
  onChange: (value: string[]) => void;
}

const CustomMultipleSelect: React.FC<ICustomMultipleSelectProps> = (props) => {
  const { value, options, onChange } = props;
  const [open, setOpen] = useState(true);
  const [data, setData] = useState(value);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    onChange(data);
    setOpen(false);
  };

  const onSelectChange = (e: any) => {
    setData(e.target.value);
  };

  return (
    <MultipleSelect
      fullWidth
      sx={{ width: '100%' }}
      isSmallCheckbox
      open={open}
      onOpen={onOpen}
      size="small"
      value={data}
      options={options}
      onChange={onSelectChange}
      onClose={onClose}
    />
  );
};

export default observer(CustomMultipleSelect);
