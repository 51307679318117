import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, conditionStore, parameterListValueStore, parameterValueStore, toastStore } from 'stores';
import { DialogWrapper, TableView } from 'components';
import CheckCircleIcon from 'components/Icons/CheckCircleIcon';
import RuleIcon from 'components/Icons/RuleIcon';
import { Box, IconButton, Link, Stack, CircularProgress, Typography } from '@mui/material';
import { IEditableTableColumn, ITableColumn } from 'shared/interfaces';
import { DocumentListModel } from 'shared/models';
import { ParameterListValueModel } from 'shared/models/ParameterListValueModel';

import s from './EditListValueDialog.module.scss';

const EditListValueDialog: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    return () => {
      parameterValueStore.selectedParamValue.clear();
    };
  }, []);

  const getViewDocuments = (documents: DocumentListModel[]): JSX.Element => {
    return (
      <>
        {documents.map((document, index) => {
          if (document.url)
            return (
              <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                <Link target="_blank" onClick={(event: any) => event.stopPropagation()} href={document.url}>
                  {index === documents.length - 1 ? document.name : `${document.name} `}
                </Link>
                {document.documentPage && `, ${document.documentPage}`}
              </Typography>
            );
          else
            return (
              <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                {index === documents.length - 1 ? document.name : `${document.name} `}
                {document.documentPage && `, ${document.documentPage}`}
              </Typography>
            );
        })}
      </>
    );
  };

  const columns: IEditableTableColumn[] = [
    {
      key: 'value',
      label: Translator.translate('objectValues.objectValueTable.editListValueDialog.columns.value'),
      width: '40%',
      align: 'left',
      editable: false,
      cell: (row: ParameterListValueModel) => {
        return (
          <Stack flexDirection="row" justifyContent="space-between" sx={{ wordBreak: 'break-all' }}>
            <Typography variant="body2" className={s.valueContent}>
              {row.value}

              {row.isRecommended && (
                <Box sx={{ paddingLeft: '8px', display: 'flex', alignItems: 'center' }}>
                  <CheckCircleIcon />
                </Box>
              )}
            </Typography>

            {row.conditions.length > 0 && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  /* TODO: fix  rinInAction */
                  parameterListValueStore.selectedParameterListValue = row;
                  conditionStore.getConditionsList(undefined, row.id!);
                  setOpenDialog(true);
                }}>
                <RuleIcon />
              </IconButton>
            )}
          </Stack>
        );
      },
    },
    {
      key: 'description',
      label: Translator.translate('objectValues.objectValueTable.editListValueDialog.columns.description'),
      width: '40%',
      align: 'left',
      editable: false,
      cell: (row: ParameterListValueModel) => {
        return (
          <Typography variant="body2">
            <Stack sx={{ wordBreak: 'break-word' }}>
              {row.description} {row.concatRowConditions}
            </Stack>
          </Typography>
        );
      },
    },
    {
      key: 'documents',
      label: Translator.translate('objectValues.objectValueTable.editListValueDialog.columns.documents'),
      width: '20%',
      align: 'left',
      editable: false,
      cell: (row: ParameterListValueModel) => {
        return getViewDocuments(row.documents);
      },
    },
  ];

  const columnsCond: ITableColumn[] = [
    {
      key: 'parameter',
      label: Translator.translate('objectValues.objectValueTable.editListValueDialog.columns.parameter'),
      width: '400px',
      align: 'left',
    },
    {
      key: 'value',
      label: Translator.translate('objectValues.objectValueTable.editListValueDialog.columns.value'),
      width: '200px',
      align: 'left',
    },
  ];

  const onCheckParamListValues = (checkedIds: number[]) => {
    parameterValueStore.selectedParamValue.setParameterListValueIds(checkedIds);
  };

  const onClose = () => {
    setOpenDialog(false);
  };

  const onClick = (row: ParameterListValueModel) => {
    const listValuesIds = parameterValueStore.selectedParamValue.parameterListValueIds;
    if (listValuesIds.includes(row.id!)) {
      const idx = listValuesIds.findIndex((f) => f === row.id!);
      if (idx !== -1) {
        listValuesIds.splice(idx, 1);
      }
    } else {
      listValuesIds.push(row.id!);
    }

    parameterValueStore.selectedParamValue.setParameterListValueIds(listValuesIds);
  };

  const onCloseEditListValue = () => {
    parameterListValueStore.setOpenDialog(false);
  };

  const onEditListValue = () => {
    if (parameterValueStore.selectedParamValue.value === '') {
      parameterValueStore.createParameterValue();
    } else {
      if (parameterValueStore.selectedParamValue.parameterListValueIds.length > 0) {
        if (parameterValueStore.selectedParamValue.isInheritedValue) {
          parameterValueStore.createParameterValue();
        } else parameterValueStore.updateParameterValue();
      } else if (parameterValueStore.selectedParamValue.parameterListValueIds.length === 0) {
        parameterValueStore.deleteParameterValue();
      }
    }

    parameterListValueStore.setOpenDialog(false);
  };

  return (
    <DialogWrapper
      title={Translator.translate('objectValues.objectValueTable.editListValueDialog.title')}
      subTitle={`${Translator.translate('objectValues.objectValueTable.editListValueDialog.subTitle')}: ${
        parameterValueStore.selectedParamValue.paramName
      }`}
      open={parameterListValueStore.isDialogOpen}
      onClose={onCloseEditListValue}
      onSubmit={onEditListValue}
      onCancel={onCloseEditListValue}
      maxWidth="lg">
      {parameterListValueStore.isLoading ? (
        <Stack alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <TableView
          data={parameterListValueStore.parameterListValues}
          checkable={parameterValueStore.selectedParamValue.parameterListValueIds}
          isHideHeader
          hideSelectRowOnMount
          columns={columns}
          onChecked={onCheckParamListValues}
          onClick={onClick}
          className={s.wrapperTable}
        />
      )}

      {/* TODO: поправить units на measurement units */}
      {/* parameterValueStore.selectedParamValue.units ? ',' + parameterStore.selectedParameterTable.units : '' */}

      {openDialog && (
        <DialogWrapper
          title={`${parameterValueStore.selectedParamValue.paramName}
        = ${parameterListValueStore.selectedParameterListValue.value}`}
          open={openDialog}
          onClose={onClose}
          maxWidth="md">
          <TableView data={conditionStore.conditionsForTable} columns={columnsCond} />
        </DialogWrapper>
      )}
    </DialogWrapper>
  );
};

export default observer(EditListValueDialog);
