import React from 'react';

const LinkValueIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="rgba(169, 171, 185, 1)" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.1042 1.00016C10.0561 0.990551 9.05723 1.41623 8.32003 2.16737L8.30998 2.17776L7.3019 3.23506C6.9208 3.63478 6.93588 4.26776 7.3356 4.64887C7.73532 5.02998 8.3683 5.0149 8.74941 4.61518L9.75199 3.56364C10.1196 3.19151 10.5993 2.99561 11.0858 3.00008C11.5745 3.00455 12.0534 3.21098 12.4159 3.59343C12.7801 3.97762 12.9953 4.50801 12.9999 5.07288C13.0046 5.63501 12.8002 6.16731 12.4451 6.55819L10.6934 8.40617C10.4948 8.61572 10.2592 8.7743 10.0046 8.8745C9.75022 8.97461 9.4804 9.01518 9.2126 8.99494C8.94475 8.97471 8.68164 8.89383 8.44095 8.75511C8.20004 8.61625 7.98592 8.42184 7.81575 8.18184C7.49631 7.73131 6.87213 7.62504 6.4216 7.94449C5.97107 8.26393 5.86481 8.88811 6.18425 9.33864C6.51747 9.80861 6.9456 10.2016 7.44222 10.4879C7.93905 10.7743 8.4915 10.9462 9.06193 10.9893C9.63241 11.0324 10.204 10.9453 10.737 10.7356C11.2698 10.5259 11.7493 10.1995 12.1449 9.78207L13.9031 7.92717L13.915 7.91448C14.6242 7.13976 15.0086 6.11268 14.9999 5.05641C14.9912 4.00011 14.5899 2.97972 13.8675 2.21753C13.1433 1.45361 12.1522 1.00977 11.1042 1.00016Z" />
      <path d="M6.93807 5.01074C6.36759 4.96764 5.79603 5.05467 5.263 5.26443C4.73023 5.47409 4.2507 5.8005 3.85512 6.21793L2.09685 8.07283L2.08503 8.08552C1.37576 8.86024 0.991444 9.88732 1.00014 10.9436C1.00885 11.9999 1.41007 13.0203 2.13255 13.7825C2.85668 14.5464 3.84778 14.9902 4.89582 14.9998C5.94392 15.0094 6.94277 14.5838 7.67997 13.8326L7.69203 13.8201L8.69424 12.7628C9.07418 12.362 9.05726 11.7291 8.65643 11.3491C8.2556 10.9692 7.62267 10.9861 7.24272 11.3869L6.24708 12.4373C5.87962 12.8088 5.40034 13.0044 4.91415 12.9999C4.42548 12.9954 3.9466 12.789 3.58407 12.4066C3.21989 12.0224 3.00473 11.492 3.00008 10.9271C2.99545 10.365 3.19983 9.8327 3.55493 9.44181L5.30664 7.59383C5.5052 7.38428 5.74079 7.2257 5.99539 7.12551C6.24978 7.02539 6.5196 6.98482 6.7874 7.00506C7.05525 7.02529 7.31836 7.10617 7.55905 7.2449C7.79996 7.38375 8.01408 7.57816 8.18425 7.81816C8.50369 8.26869 9.12787 8.37496 9.5784 8.05551C10.0289 7.73607 10.1352 7.11189 9.81575 6.66136C9.48253 6.19139 9.0544 5.79835 8.55778 5.51211C8.06095 5.22575 7.5085 5.05384 6.93807 5.01074Z" />
    </svg>
  );
};

export default LinkValueIcon;
