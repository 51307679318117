import React from 'react';

const FilterIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00001 2C2.61199 2 2.259 2.22446 2.09442 2.57584C1.92984 2.92723 1.98339 3.3421 2.23179 3.64018L6.00001 8.16205V13C6.00001 13.5523 6.44773 14 7.00001 14H9.00001C9.5523 14 10 13.5523 10 13V8.16205L13.7682 3.64018C14.0166 3.3421 14.0702 2.92723 13.9056 2.57584C13.741 2.22446 13.388 2 13 2H3.00001ZM8.00001 7.43795L5.13505 4H10.865L8.00001 7.43795Z"
      />
    </svg>
  );
};

export default FilterIcon;
