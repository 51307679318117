import React, { useEffect } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup as MuiRadioGroup, Typography } from '@mui/material';
import { IRadioGroupItem } from 'shared/interfaces';

import s from './RadioGroup.module.scss';

interface IRadioGroupProps {
  name?: string;
  selectedRadioGroup: string | number | null;
  row?: boolean;
  radioGroupItems: IRadioGroupItem[];
  onChangeRadioGroup: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioGroup: React.FC<IRadioGroupProps> = (props) => {
  const { radioGroupItems, row, name, selectedRadioGroup, onChangeRadioGroup } = props;

  //! TODO: изменить на refs (anzhelika.samchuk)
  useEffect(() => {
    const el = document.getElementById(String(selectedRadioGroup));
    el?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [document.getElementById(String(selectedRadioGroup))]);

  return (
    <FormControl>
      <MuiRadioGroup name={name} row={row ? row : false} value={selectedRadioGroup} onChange={onChangeRadioGroup} className={s.radioStyle}>
        {radioGroupItems.map((radio) => {
          const fontSize = () => {
            switch (radio.id) {
              case 'h1':
                return 28;

              case 'h2':
                return 23;

              case 'h3':
                return 18;

              default:
                return 18;
            }
          };

          const label = radio.size ? <Typography variant={radio.id as 'h1' | 'h2' | 'h3'}>{radio.name}</Typography> : radio.name;

          return (
            <FormControlLabel
              id={String(radio.id)}
              key={radio.id}
              value={radio.id}
              className={s.formControlWrapper}
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize,
                    },
                  }}
                />
              }
              label={label}
            />
          );
        })}
      </MuiRadioGroup>
    </FormControl>
  );
};

export default RadioGroup;
