import React from 'react';
import { ButtonProps, IconButton, Paper, Tooltip } from '@mui/material';

import s from './IconButtonNew.module.scss';

interface IconButtonNew extends ButtonProps {
  tooltip?: string;
  isWrapper?: boolean;
}

const IconButtonNew: React.FC<IconButtonNew> = (props) => {
  const { children, ...rest } = props;
  return (
    <Tooltip
      enterDelay={0}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
      arrow
      disableInteractive
      title={props.tooltip && !props.disabled ? props.tooltip : ''}>
      {props.isWrapper ? (
        <Paper elevation={3}>
          <IconButton className={props.isWrapper ? s.iconButtonWrapper : s.iconButton} {...rest}>
            {children}
          </IconButton>
        </Paper>
      ) : (
        <IconButton className={props.isWrapper ? s.iconButtonWrapper : s.iconButton} {...rest}>
          {children}
        </IconButton>
      )}
    </Tooltip>
  );
};

export default IconButtonNew;
