import { useEffect } from 'react';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Translator, auditStore } from 'stores';
import { Switch } from 'components';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { ChangeType, ChangeTypeName, LangNames } from 'shared/enums';

import s from './HistoryContent.module.scss';

const HistoryContent: React.FC = () => {
  useEffect(() => {
    auditStore.getAuditData();

    return () => {
      auditStore.clear();
    };
  }, []);

  return (
    <>
      <div className={s.header}>
        <Typography variant="h3">{Translator.translate('common.historyContent.title')}</Typography>
        <Switch
          disabled={auditStore.isLoading}
          label={Translator.translate('common.historyContent.showMyHistory')}
          labelPlacement="end"
          checked={!!auditStore.auditFilters.userId}
          onChange={auditStore.setUserIdFilter}
        />
      </div>

      {auditStore.isLoading ? (
        <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Timeline sx={{ m: 0 }}>
          {auditStore.entityAuditData.map((el) => {
            return (
              <TimelineItem key={el.id}>
                <TimelineOppositeContent sx={{ flex: 'unset' }} color="text.secondary">
                  {dayjs(el.createdAt).format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY')}
                </TimelineOppositeContent>

                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                  <div className={s.status}>{Translator.translate(ChangeTypeName.get(el.changeType))}</div>
                  <div>{el.user.name}</div>
                  {Object.keys(el.changes).map((item, idx) => {
                    return (
                      <Stack className={s.change} key={idx}>
                        {el.changeType !== ChangeType.Added && (
                          <Stack className={s.oldValue} flexDirection="row">
                            <div className={s.key}>{item}:</div>
                            <div>
                              {el.changes[item].old && dayjs(el.changes[item].old, 'YYYY-MM-DD').isValid()
                                ? dayjs(el.changes[item].old).format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY')
                                : el.changes[item].old}
                            </div>
                          </Stack>
                        )}

                        {el.changeType !== ChangeType.Deleted && (
                          <Stack className={s.newValue} flexDirection="row">
                            <div className={s.key}>{item}:</div>
                            <div>
                              {el.changes[item].new && dayjs(el.changes[item].new, 'YYYY-MM-DD').isValid()
                                ? dayjs(el.changes[item].new).format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY')
                                : el.changes[item].new}
                            </div>
                          </Stack>
                        )}
                      </Stack>
                    );
                  })}
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      )}
    </>
  );
};

export default observer(HistoryContent);
