import React from 'react';

const FileXSDIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z" fill="#E9E9F4" />
      <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#A9ABB9" />
      <path d="M30 14L24 8H30V14Z" fill="#7B68EE" fillOpacity="0.08" />
      <path d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z" fill="#2196F3" />
      <path
        d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z"
        fill="url(#paint0_linear_15471_91)"
        fillOpacity="0.24"
      />
      <path
        d="M17.502 23.673C17.238 23.673 16.95 23.529 16.95 23.178V18.636C16.95 18.349 17.238 18.14 17.502 18.14H19.333C22.987 18.14 22.907 23.673 19.405 23.673H17.502ZM18.006 19.116V22.698H19.333C21.492 22.698 21.588 19.116 19.333 19.116H18.006Z"
        fill="white"
      />
      <path
        d="M8.3944 20.0868L9.7854 18.3438C10.2014 17.7998 11.0094 18.4948 10.5534 19.0148C10.0744 19.5828 9.5704 20.1988 9.0904 20.8298L10.7214 22.8608C11.1614 23.4608 10.2824 24.0358 9.8504 23.4438L8.3784 21.5568L6.9314 23.4848C6.5234 24.0678 5.6194 23.4128 6.0754 22.8688L7.6824 20.8298C7.1784 20.1978 6.6904 19.5828 6.2034 19.0148C5.7004 18.4148 6.6354 17.7998 7.0034 18.3608L8.3944 20.0868Z"
        fill="white"
      />
      <path
        d="M11.7728 19.5316C11.9568 18.0463 14.2988 17.7792 15.4028 18.5908C15.9468 19.0109 15.3708 19.677 14.8908 19.3402C14.2988 18.9813 12.9558 18.8128 12.7878 19.6072C12.5718 20.8628 16.0498 20.1441 15.9938 22.1796C15.9378 24.1234 12.9958 24.1683 11.8918 23.2965C11.6268 23.0898 11.6358 22.7539 11.7798 22.5462C11.9878 22.3481 12.2188 22.2792 12.4918 22.4936C13.1468 22.9213 14.8348 23.2429 14.9538 22.1557C14.8508 21.0245 11.5248 21.705 11.7728 19.5316Z"
        fill="white"
      />
      <path d="M25 27H6V28H25C25.55 28 26 27.55 26 27V26C26 26.55 25.55 27 25 27Z" fill="#7B68EE" fillOpacity="0.12" />
      <defs>
        <linearGradient id="paint0_linear_15471_91" x1="14" y1="15" x2="14" y2="27" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FileXSDIcon;
