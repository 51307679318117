import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { HandlerProps, ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { Translator, approvalSuggestionRowStore, partialApprovalStore } from 'stores';
import { IconButtonNew } from 'components';
import NavigationCloseIcon from 'components/Icons/NavigationCloseIcon';
import NavigationOpenIcon from 'components/Icons/NavigationOpenIcon';
import { Stack } from '@mui/material';
import { HIDE_NAVIGATION__APPROVAL_ROW_WIDTH } from 'shared/constants/constants';
import { PartialApprovalIteration } from 'shared/enums';
import { useWindowDimensions } from 'shared/utils/hooks';
import Utils from 'shared/utils/Utils';
import { ApprovalIterationRowsTable, ApprovalIterationRowsTree, NavigationAction } from './Components';

const PERCENT_NAVIGATION = 0.2;
const HEIGHT_CONTENT = 161;
const NAVIGATION_ICON_CONTENT = 38;

const ApprovalIterationRows: React.FC = () => {
  const [isOpenNavigation, setOpenNavigation] = useState(false);
  const { width } = useWindowDimensions();
  const heightContainer = `calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight()}px)`;
  const heightTree = document.documentElement.clientHeight - (HEIGHT_CONTENT + Utils.projectHeight() + NAVIGATION_ICON_CONTENT);

  useEffect(() => {
    return () => {
      approvalSuggestionRowStore.setSelectedTreeId(null);
    };
  }, []);

  useEffect(() => {
    approvalSuggestionRowStore.setNavigationWidth(width * PERCENT_NAVIGATION);
  }, [width]);

  const onClickNavigationIcon = () => {
    if (!approvalSuggestionRowStore.selectedTreeId && approvalSuggestionRowStore.filteredApprovalRowsTree.length > 0) {
      approvalSuggestionRowStore.setSelectedTreeId(approvalSuggestionRowStore.filteredApprovalRowsTree[0].id);
    }

    setOpenNavigation(!isOpenNavigation);
  };

  return (
    <ReflexContainer orientation="vertical" style={{ height: heightContainer, width: '100%' }}>
      <ReflexElement
        size={isOpenNavigation ? approvalSuggestionRowStore.navigationWidth : HIDE_NAVIGATION__APPROVAL_ROW_WIDTH}
        onStopResize={(args: HandlerProps) => approvalSuggestionRowStore.setNavigationWidth((args.domElement as Element).clientWidth)}
        style={{ marginRight: isOpenNavigation ? '12px' : '0px', position: 'unset' }}>
        <Stack gap={2}>
          <Stack flexDirection="row" justifyContent="space-between">
            <IconButtonNew onClick={onClickNavigationIcon} tooltip={Translator.translate('approvals.navigation')}>
              {isOpenNavigation ? <NavigationOpenIcon /> : <NavigationCloseIcon />}
            </IconButtonNew>

            {isOpenNavigation &&
              !Utils.canEditApproval(
                partialApprovalStore.selectedPartialApproval.iteration,
                partialApprovalStore.selectedPartialApproval.userRoles
              ) &&
              partialApprovalStore.selectedPartialApproval.iteration !== PartialApprovalIteration.Examination && <NavigationAction />}
          </Stack>

          {isOpenNavigation && <ApprovalIterationRowsTree heightTree={heightTree} />}
        </Stack>
      </ReflexElement>

      <ReflexSplitter
        propagate
        style={{ width: '6px', borderRadius: '4px', border: 'none', height: 'unset', display: isOpenNavigation ? 'inline' : 'none' }}
      />

      <ReflexElement style={{ paddingLeft: '12px' }}>
        <ApprovalIterationRowsTable />
      </ReflexElement>
    </ReflexContainer>
  );
};

export default observer(ApprovalIterationRows);
