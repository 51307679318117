import React, { useState, useRef, useEffect } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateValidation';
import { Translator } from 'stores';
import { TextField } from '@mui/material';

interface ICustomDatePickerProps {
  value: Date | string | null;
  onSubmit: (value: Date | string) => void;
  onCancel?: () => void;
  submitOnBlur?: boolean;
  label?: string;
  outline?: boolean;
  size?: string;
  readOnly?: boolean;
  sx?: any;
  error?: boolean;
}

const CustomDatePicker: React.FC<ICustomDatePickerProps> = (props) => {
  const { value, onSubmit, onCancel, label, outline, size, sx, readOnly, submitOnBlur, error } = props;

  const [date, setDate] = useState<Date | null | string>(value);
  const [isError, setError] = useState<boolean>(true);
  const inputRef = useRef<any>();

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [inputRef.current]);

  const onError = (error: DateValidationError) => {
    setError(error === null ? false : true);
  };

  const onChange = (newValue: any) => {
    setDate(newValue ? newValue.format() : null);
  };

  const onKeyEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter' && !isError) {
      //если ничего не введено, то пойдет на удаление
      onSubmit(date ? new Date(date) : '');
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={Translator.translate('common.datePicker.locale')}
      localeText={{ cancelButtonLabel: Translator.translate('actions.cancel'), okButtonLabel: Translator.translate('common.confirm.okText') }}>
      <DatePicker
        value={date}
        onChange={onChange}
        componentsProps={{
          actionBar: {
            actions: ['cancel', 'accept'],
          },
        }}
        closeOnSelect={false}
        label={label ?? Translator.translate('common.datePicker.label')}
        onError={onError}
        onAccept={(value: any) => {
          onSubmit(value ? value.format() : '');
        }}
        onClose={onCancel}
        readOnly={readOnly}
        renderInput={(params: any) => (
          <TextField
            inputProps={{
              onBlur: () => onSubmit(date ? new Date(date) : ''),
            }}
            size={size ?? 'small'}
            variant={outline ? 'outlined' : 'standard'}
            {...params}
            onBlur={submitOnBlur ? () => onSubmit(date ? date : '') : onCancel}
            onKeyPress={onKeyEnter}
            fullWidth
            error={error}
            sx={sx}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
