import React, { useState, useEffect, SyntheticEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useDebounce } from 'usehooks-ts';
import { Translator, globalSettingStore, parameterStore, toastStore } from 'stores';
import { DialogWrapper } from 'components';
import { Autocomplete, TextField } from '@mui/material';
import { DataType, DocumentSettingType } from 'shared/enums';
import { Routes } from 'shared/enums/Routes';
import { IAutocompleteParamDocSetting } from 'shared/interfaces';

const ParameterDocSettingDialog: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [parameter, setParameter] = useState<IAutocompleteParamDocSetting | null>(null);

  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebounce<string>(inputValue, 400);
  const currentType = location.pathname.includes(Routes.EXPORT_VIEW_LIST) ? DocumentSettingType.IsMultilineListValues : DocumentSettingType.HideName;

  useEffect(() => {
    parameterStore.allParameters = [];
    if (inputValue && open)
      parameterStore.getParametersAll(currentType === DocumentSettingType.IsMultilineListValues ? DataType.List : undefined, debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    if (open && debouncedValue) {
      parameterStore.allParameters = [];
      parameterStore.getParametersAll(currentType === DocumentSettingType.IsMultilineListValues ? DataType.List : undefined);
    }
  }, [open]);

  const onClose = () => {
    globalSettingStore.setOpenDialog(false);
  };

  const onSubmit = () => {
    if (!parameter?.id) {
      toastStore.showWarning(Translator.translate('catalogs.validationMessage'));
      return;
    }

    parameter.documentSettings.push(currentType);

    globalSettingStore.changeParameterDocSettings(parameter.id, parameter.documentSettings, currentType).then(() => onClose());
  };

  const onChangeParameterValue = (e: SyntheticEvent, value: IAutocompleteParamDocSetting | null) => {
    setParameter(value);
  };

  return (
    <DialogWrapper
      title={Translator.translate('params.parameter.addParameterDialogTitle')}
      maxWidth="md"
      open={globalSettingStore.isDialogOpen}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={onSubmit}>
      {
        <Autocomplete
          fullWidth
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          noOptionsText={
            inputValue
              ? Translator.translate('common.parameterAutocomplete.notFoundParameter')
              : Translator.translate('common.parameterAutocomplete.clue')
          }
          options={globalSettingStore.filterParamsForAutocomplete}
          loading={parameterStore.loadingParameters}
          value={parameter as IAutocompleteParamDocSetting}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.fullName}
          onChange={onChangeParameterValue}
          inputValue={inputValue}
          onInputChange={(e, inputValue) => {
            setInputValue(inputValue);
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.fullName}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              multiline
              maxRows={3}
              label={Translator.translate('common.parameterAutocomplete.parameterLabel')}
              placeholder={Translator.translate('common.parameterAutocomplete.parameterPlaceholder')}
              required
            />
          )}
        />
      }
    </DialogWrapper>
  );
};

export default observer(ParameterDocSettingDialog);
