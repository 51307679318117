import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
  htmlFontSize: 16,
  h1: {
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: '32px',
  },
  h2: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '28px',
  },
  h3: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '24px',
  },
  h4: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '20px',
  },

  //ниже с нового дизайна шрифты
  h5: {
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: '32px',
  },
  h6: {
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: '32px',
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '22.4px',
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '22px',
  },
  body1: {
    fontSize: '1rem',
    lineHeight: '24px',
    fontWeight: 400,
  },
  body2: {
    fontSize: '0.875rem',
    lineHeight: '20px',
    fontWeight: 400,
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '20px',
  },
  button: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '24px',
    textTransform: 'none',
  },
  overline: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '19px',
  },
};

export default typography;
