import React from 'react';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';
import useSwitch, { UseSwitchParameters } from '@mui/base/useSwitch';
import { styled } from '@mui/system';
import { FormControlLabel, Tooltip, Typography } from '@mui/material';

const Root = styled('span')(
  ({ theme }) => `
    font-size: 0;
    position: relative;
    display: inline-block;
    min-width: 30px;
    height: 16px;
    margin: 10px;
    cursor: pointer;
  
    &.${switchUnstyledClasses.disabled} {
      opacity: 0.4;
      cursor: not-allowed;
    }
  
    & .${switchUnstyledClasses.track} {
      background: rgba(15, 11, 31, 0.42);
      border-radius: 16px;
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  
    & .${switchUnstyledClasses.thumb} {
      display: block;
      width: 10px;
      height: 10px;
      top: 3px;
      left: 3px;
      border-radius: 16px;
      background-color: #fff;
      position: relative;
      
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 120ms;
    }
  
    &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
      background-color: #fff;
      box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
    }
  
    &.${switchUnstyledClasses.checked} {
      .${switchUnstyledClasses.thumb} {
        left: 18px;
        top: 3px;
        background-color: #fff;
      }
  
      .${switchUnstyledClasses.track} {
        background: ${theme.palette.primary.main};
      }
    }
  
    & .${switchUnstyledClasses.input} {
      cursor: inherit;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
      margin: 0;
    }
    `
);

interface ISwitchProps extends UseSwitchParameters {
  label?: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  tooltip?: React.ReactNode;
  secondaryColor?: boolean;
}

const Switch: React.FC<ISwitchProps> = (props) => {
  const { label, labelPlacement, tooltip, secondaryColor } = props;
  const { getInputProps } = useSwitch(props);

  return (
    <Tooltip
      enterDelay={0}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
      arrow
      disableInteractive
      title={tooltip ? tooltip : ''}>
      <FormControlLabel
        sx={{ mx: 0, '.MuiFormControlLabel-label': { width: '100%' } }}
        label={
          <Typography variant="body2" color={secondaryColor ? 'text.secondary' : 'text.primary'} sx={{ whiteSpace: 'nowrap' }}>
            {label}
          </Typography>
        }
        labelPlacement={labelPlacement}
        control={<SwitchUnstyled component={Root} {...getInputProps()} />}
      />
    </Tooltip>
  );
};

export default Switch;
