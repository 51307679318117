import { BASE_PATH } from '../shared/constants/constants';
import {
  IGetSectionDto,
  IParameterGroupShortParams,
  IPostPutParamSectionsDto,
  IPostSectionDto,
  IPutSectionDto,
  IPutSectionParameterGroupPropertiesDto,
  ISectionParameterGroupDto,
} from 'shared/interfaces';
import HTTPService from './HttpService';

class SectionsService extends HTTPService {
  constructor() {
    super(BASE_PATH.SECTIONS);
  }

  public getAllSections(sectionSetId: number | null): Promise<IGetSectionDto[]> {
    return this.GET('', { params: { sectionSetId } });
  }

  public getSection(id: number): Promise<IGetSectionDto[]> {
    return this.GET('', { params: { id } });
  }

  public postSection(body: IPostSectionDto): Promise<number> {
    return this.POST('', body);
  }

  public putSection(id: number, body: IPutSectionDto): Promise<IGetSectionDto> {
    return this.PUT(`${id}`, body);
  }

  public deleteSection(id: number): Promise<void> {
    return this.DELETE(`${id}`);
  }

  public putSectionParameterGroupProperties(body: IPutSectionParameterGroupPropertiesDto): Promise<number> {
    return this.PUT('parameter-group-properties', body);
  }

  public getSectionParameters(id: number, params: IParameterGroupShortParams): Promise<ISectionParameterGroupDto[]> {
    return this.GET(`${id}/parameters`, { params });
  }

  public postSectionParameters(id: number, body: IPostPutParamSectionsDto[]): Promise<number> {
    return this.POST(`${id}/parameters`, body);
  }

  public putSectionParameters(id: number, body: IPostPutParamSectionsDto[]): Promise<number> {
    return this.PUT(`${id}/parameters`, body);
  }

  public deleteSectionParameters(id: number, body: number[]): Promise<number> {
    return this.DELETE(`${id}/parameters`, body);
  }
}

export default new SectionsService();
