import React from 'react';
import { DataGridPro, gridClasses, DataGridProProps } from '@mui/x-data-grid-pro';
import { Translator } from 'stores';
import { Stack, Typography } from '@mui/material';
import CustomCellGrouping from '../CustomCellGrouping';
import CustomGridParameterRow from '../CustomGridParameterRow';

interface ICustomDataGridProps extends DataGridProProps {
  hasFiltered?: boolean;
  checkboxSelection?: boolean;
  onHoverRowId?: (hoveredRowId: number | null) => void;
}

const CustomDataGrid: React.FC<ICustomDataGridProps> = (props) => {
  const {
    columns,
    rows,
    loading,
    selectionModel,
    isRowSelectable,
    onCellClick,
    onColumnHeaderClick,
    hasFiltered,
    checkboxSelection = true,
    onHoverRowId,
  } = props;

  const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy;

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    width: 10,
    minWidth: 30,
    headerName: '',
    renderCell: (params) => <CustomCellGrouping isGroup={params.row.isGroup} {...params} />,
  };

  const noRows = (): JSX.Element => (
    <Stack alignItems="center" mt={8}>
      <img
        style={{ alignSelf: 'center' }}
        src={hasFiltered ? `${process.env.PUBLIC_URL}/img/not_found_data.svg` : `${process.env.PUBLIC_URL}/img/empty_data.svg`}
        alt=""
      />
      <Typography variant="subtitle1" color="text.disabled">
        {hasFiltered ? Translator.translate('system.notFoundData') : Translator.translate('system.emptyData')}
      </Typography>
    </Stack>
  );

  return (
    <DataGridPro
      sx={{
        border: 'none',
        '& .MuiDataGrid-row': {
          width: '100%',
          cursor: 'pointer',
          minHeight: '36px !important',
        },
        '& .MuiDataGrid-columnHeaders': {
          border: 'none',
        },
        '& .MuiDataGrid-cell': {
          border: 'none',
        },
        '& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: 'none',
        },
        '& .MuiDataGrid-row:not(:last-child)': {
          marginBottom: '2px',
        },
        '& .MuiCheckbox-root': {
          padding: '0 0 0 10px',
        },
      }}
      checkboxSelection={checkboxSelection}
      hideFooter
      getRowHeight={() => {
        return 'auto';
      }}
      headerHeight={36}
      treeData
      rows={rows}
      isRowSelectable={isRowSelectable}
      columns={columns}
      disableColumnMenu
      onCellClick={onCellClick}
      onColumnHeaderClick={onColumnHeaderClick}
      getTreeDataPath={getTreeDataPath}
      selectionModel={selectionModel}
      groupingColDef={groupingColDef}
      defaultGroupingExpansionDepth={1}
      experimentalFeatures={{ newEditingApi: true }}
      loading={loading}
      components={{
        Row: CustomGridParameterRow,
        NoRowsOverlay: noRows,
      }}
      componentsProps={{
        row: {
          onMouseEnter: (event: any) => {
            onHoverRowId && onHoverRowId(Number(event.currentTarget.dataset.id));
          },
          onMouseLeave: () => {
            onHoverRowId && onHoverRowId(null);
          },
        },
      }}
    />
  );
};

export default CustomDataGrid;
