import React from 'react';
import { SnackbarProvider } from 'notistack';
import { Route, Routes } from 'react-router-dom';
import { Toast } from 'components';
import {
  CatalogPage,
  Layout,
  MainPage,
  ClassPage,
  ObjectValuePage,
  ParamPage,
  SectionsPage,
  MergePage,
  SpecificationsPage,
  SettingsPage,
  XMLViewerPage,
  XMLPage,
} from 'pages';
import Approvals from 'pages/Approvals';
import ApprovalIterationRows from 'pages/Approvals/ApprovalIterationRows';
import ApprovalPage from 'pages/Approvals/ApprovalPage';
import ApprovalRowsPage from 'pages/Approvals/ApprovalRowsPage';
import DetailApprovalPage from 'pages/Approvals/DetailApprovalPage';
import {
  ClassificationDictionaries,
  Documents,
  MeasurementUnits,
  SpecificationTemplate,
  Stages,
  InformationBlocks,
  XMLDocuments,
} from 'pages/CatalogPage/Components';
import { Sections } from 'pages/CatalogPage/Components/SpecificationTemplate/Components';
import CommentPage from 'pages/CommentPage';
import { GlobalSettings, Roles, ParameterDocSettings } from 'pages/SettingsPage/Components';
import { SpecificationSections } from 'pages/SpecificationsPage/Components';
import createTheme from 'theme/theme';
import { CssBaseline } from '@mui/material';
import { SectionMode, XMLDocumentType } from 'shared/enums';
import { Routes as routesPath } from 'shared/enums/Routes';
import 'rc-tree/assets/index.css';
import 'react-reflex/styles.css';
import s from './App.module.scss';
import { ConfirmProvider } from './components/App/Confirm';

import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

const App: React.FC = () => {
  return (
    <MuiThemeProvider theme={createTheme()}>
      <StyledEngineProvider injectFirst>
        <ConfirmProvider>
          <SnackbarProvider>
            <div className={s.app}>
              <CssBaseline />

              <Routes>
                <Route element={<Layout />}>
                  <Route path={`${routesPath.MAIN}`} element={<MainPage />} />

                  <Route path=":currentDefinitionId" />
                  <Route path={`:currentDefinitionId${routesPath.OBJECT_VALUE}`} element={<ObjectValuePage />} />
                  <Route path={`:currentDefinitionId${routesPath.SPECIFICATIONS}`} element={<SpecificationsPage />} />
                  <Route path={`:currentDefinitionId${routesPath.SPECIFICATIONS}`} element={<SectionsPage mode={SectionMode.Specification} />}>
                    <Route path=":specificationId" element={<SpecificationSections />} />
                  </Route>
                  <Route path={`:currentDefinitionId${routesPath.COMMENTS}`} element={<CommentPage />} />
                  <Route path={`:currentDefinitionId${routesPath.MERGE}`} element={<MergePage />} />

                  <Route path={`:currentDefinitionId${routesPath.CLASSES}`} element={<ClassPage />} />
                  <Route path={`:currentDefinitionId${routesPath.PARAM}`} element={<ParamPage />} />
                  <Route path={`:currentDefinitionId${routesPath.CATALOG}`} element={<CatalogPage />}>
                    <Route path={routesPath.DICTIONARIES} element={<ClassificationDictionaries />} />
                    <Route path={routesPath.STAGES} element={<Stages />} />
                    <Route path={routesPath.DOCUMENTS} element={<Documents />} />
                    <Route path={routesPath.TEMPLATES} element={<SpecificationTemplate />} />
                    <Route path={`${routesPath.TEMPLATES}`} element={<SectionsPage mode={SectionMode.Template} />}>
                      <Route path=":sectionSetId" element={<Sections />} />
                    </Route>
                    <Route path={routesPath.UNITS} element={<MeasurementUnits />} />
                    <Route path={routesPath.INFORMATION_BLOCKS} element={<InformationBlocks />} />
                    <Route path={routesPath.XML_DOCUMENT} element={<XMLDocuments />} />
                    <Route path={`${routesPath.XML_DOCUMENT}`} element={<XMLPage mode={XMLDocumentType.Template} />}>
                      <Route path=":xmlDocumentId" element={<XMLViewerPage />} />
                    </Route>
                  </Route>

                  <Route path={`:currentDefinitionId${routesPath.SETTINGS}`} element={<SettingsPage />}>
                    <Route path={routesPath.ROLES} element={<Roles />} />
                    <Route path={routesPath.EXPORT_DOCUMENTS} element={<GlobalSettings />} />
                    <Route path={routesPath.EXPORT_VIEW_LIST} element={<ParameterDocSettings />} />
                    <Route path={routesPath.EXPORT_VIEW_UNTITLED} element={<ParameterDocSettings />} />
                  </Route>

                  <Route path={`:currentDefinitionId${routesPath.XML_VIEWER}`} element={<XMLPage mode={XMLDocumentType.DefinitionInstance} />}>
                    <Route path=":xmlDocumentId" element={<XMLViewerPage />} />
                  </Route>

                  <Route path={`:currentDefinitionId${routesPath.APPROVALS}`} element={<Approvals />}>
                    <Route path="" element={<ApprovalPage />} />
                    <Route path=":approvalId" element={<DetailApprovalPage />} />
                    <Route path={`:approvalId/${routesPath.PARTIAL_APPROVALS}`} element={<ApprovalRowsPage />}>
                      <Route path=":partialApprovalId" element={<ApprovalIterationRows />} />
                    </Route>
                    <Route path={`:approvalId/${routesPath.PARTIAL_APPROVALS_SETS}`} element={<ApprovalRowsPage />}>
                      <Route path=":partialApprovalId" element={<ApprovalIterationRows />} />
                    </Route>
                  </Route>

                  <Route path={routesPath.CLASSES} element={<ClassPage />} />
                  <Route path={routesPath.PARAM} element={<ParamPage />} />
                  <Route path={routesPath.CATALOG} element={<CatalogPage />}>
                    <Route path={routesPath.DICTIONARIES} element={<ClassificationDictionaries />} />
                    <Route path={routesPath.STAGES} element={<Stages />} />
                    <Route path={routesPath.DOCUMENTS} element={<Documents />} />
                    <Route path={routesPath.TEMPLATES} element={<SpecificationTemplate />} />
                    <Route path={`${routesPath.TEMPLATES}`} element={<SectionsPage mode={SectionMode.Template} />}>
                      <Route path=":sectionSetId" element={<Sections />} />
                    </Route>
                    <Route path={routesPath.UNITS} element={<MeasurementUnits />} />
                    <Route path={routesPath.INFORMATION_BLOCKS} element={<InformationBlocks />} />
                    <Route path={routesPath.XML_DOCUMENT} element={<XMLDocuments />} />
                    <Route path={`${routesPath.XML_DOCUMENT}`} element={<XMLPage mode={XMLDocumentType.Template} />}>
                      <Route path=":xmlDocumentId" element={<XMLViewerPage />} />
                    </Route>
                  </Route>

                  <Route path={routesPath.SETTINGS} element={<SettingsPage />}>
                    <Route path={routesPath.ROLES} element={<Roles />} />
                    <Route path={routesPath.EXPORT_DOCUMENTS} element={<GlobalSettings />} />
                    <Route path={routesPath.EXPORT_VIEW_LIST} element={<ParameterDocSettings />} />
                    <Route path={routesPath.EXPORT_VIEW_UNTITLED} element={<ParameterDocSettings />} />
                  </Route>

                  <Route path={routesPath.APPROVALS} element={<Approvals />}>
                    <Route path="" element={<ApprovalPage />} />
                    <Route path=":approvalId" element={<DetailApprovalPage />} />
                    <Route path={`:approvalId/${routesPath.PARTIAL_APPROVALS}`} element={<ApprovalRowsPage />}>
                      <Route path=":partialApprovalId" element={<ApprovalIterationRows />} />
                    </Route>
                    <Route path={`:approvalId/${routesPath.PARTIAL_APPROVALS_SETS}`} element={<ApprovalRowsPage />}>
                      <Route path=":partialApprovalId" element={<ApprovalIterationRows />} />
                    </Route>
                  </Route>
                </Route>
              </Routes>
              <Toast />
            </div>
          </SnackbarProvider>
        </ConfirmProvider>
      </StyledEngineProvider>
    </MuiThemeProvider>
  );
};

export default App;
