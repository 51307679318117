import { makeAutoObservable } from 'mobx';
import { XMLDocumentType } from 'shared/enums';
import { IGetXmlDocumentDto, IXmlDocumentForm, IXmlForm } from 'shared/interfaces';
import { IdNameDto } from 'shared/interfaces/Dto/BaseDto';

class XmlDocumentModel {
  constructor(dto?: IGetXmlDocumentDto) {
    makeAutoObservable(this, undefined, { autoBind: true });
    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.xmlSchemaTemplate = dto.xmlSchemaTemplate;
    this.type = dto.type;
    this.definition = dto.definition;
  }

  public id: number | null = null;

  public xsdFile: File | null = null;

  public name = '';

  public xmlSchemaTemplate: IdNameDto = { id: null, name: '' };

  public type: XMLDocumentType = XMLDocumentType.Template;

  public definition: IdNameDto = { id: null, name: '' };

  public templateId: number | null = null;

  public updateXmlDocumentForm(data: IXmlDocumentForm) {
    this.xsdFile = data.xsdFile;
    this.name = data.name;
  }

  public updateXmlForm(data: IXmlForm) {
    this.templateId = data.templateId;
  }

  public get postFileDto(): FormData | null {
    if (!this.xsdFile) return null;

    const payload = new FormData();
    payload.append('xsd', this.xsdFile);

    return payload;
  }

  public clearModel() {
    this.xsdFile = null;
    this.name = '';
    this.id = null;
    this.xmlSchemaTemplate = { id: null, name: '' };
    this.type = XMLDocumentType.Template;
    this.definition = { id: null, name: '' };

    this.templateId = null;
  }
}

export default XmlDocumentModel;
