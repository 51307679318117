import React from 'react';

const MergeIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.25 4C1.25 2.48122 2.48122 1.25 4 1.25H11C12.5188 1.25 13.75 2.48122 13.75 4C13.75 4.41421 13.4142 4.75 13 4.75C12.5858 4.75 12.25 4.41421 12.25 4C12.25 3.30964 11.6904 2.75 11 2.75H4C3.30964 2.75 2.75 3.30964 2.75 4V11C2.75 11.6904 3.30964 12.25 4 12.25C4.41421 12.25 4.75 12.5858 4.75 13C4.75 13.4142 4.41421 13.75 4 13.75C2.48122 13.75 1.25 12.5188 1.25 11V4Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 9C6.25 7.48122 7.48122 6.25 9 6.25H13C13.4142 6.25 13.75 6.58579 13.75 7V11C13.75 12.5188 12.5188 13.75 11 13.75H7C6.58579 13.75 6.25 13.4142 6.25 13V9ZM9 7.75C8.30964 7.75 7.75 8.30964 7.75 9V12.25H11C11.6904 12.25 12.25 11.6904 12.25 11V7.75H9Z"
      />
      <path d="M16 18.75C17.5188 18.75 18.75 17.5188 18.75 16V9C18.75 7.48122 17.5188 6.25 16 6.25C15.5858 6.25 15.25 6.58579 15.25 7C15.25 7.41421 15.5858 7.75 16 7.75C16.6904 7.75 17.25 8.30964 17.25 9V16C17.25 16.6904 16.6904 17.25 16 17.25H9C8.30964 17.25 7.75 16.6904 7.75 16C7.75 15.5858 7.41421 15.25 7 15.25C6.58579 15.25 6.25 15.5858 6.25 16C6.25 17.5188 7.48122 18.75 9 18.75H16Z" />
    </svg>
  );
};

export default MergeIcon;
