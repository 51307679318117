import React from 'react';
import { Translator } from 'stores';
import { IconButtonNew } from 'components';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { IClasses } from 'shared/interfaces';

interface IClassFilterProps {
  classHeight?: number;
  defaultClassConstraints: IClasses[];
  onEditClass: () => void;
}

const ClassFilter: React.FC<IClassFilterProps> = (props) => {
  const { classHeight, defaultClassConstraints, onEditClass } = props;

  const renderValues = (): React.ReactNode => {
    return (
      <Stack flexWrap="wrap" flexDirection="row" gap={2}>
        {defaultClassConstraints.map((value) => {
          const getLabel = (): React.ReactNode => {
            if (value.parentName !== '') {
              return (
                <Box component="span">
                  {value.className}
                  <Box component="span" color="text.secondary">
                    {value.secondParentName
                      ? `(${value.secondParentName}\u{2794}${value.parentName})`
                      : value.parentName
                      ? `(${value.parentName})`
                      : ''}
                  </Box>
                </Box>
              );
            } else {
              return value.className;
            }
          };

          return <Chip sx={{ width: 'fit-content' }} key={value.classKey} label={getLabel()} size="small" />;
        })}
      </Stack>
    );
  };

  return (
    <Stack gap={2}>
      <Typography variant="caption">{Translator.translate('common.classFilter.name')}</Typography>

      <Stack flexDirection="row" alignItems="center" gap={4} height="100%">
        <Stack
          sx={(theme) => ({
            width: '100%',
            height: classHeight ? `${classHeight - 28}px` : 'auto',
            overflow: 'auto',
            borderRadius: '4px',
            border: `1px solid ${theme.palette.grey[100]}`,
            padding: '16.5px 14px',
            ':hover': {
              border: `1px solid ${theme.palette.common.black}`,
            },
          })}>
          {defaultClassConstraints.length > 0 ? (
            renderValues()
          ) : (
            <Typography
              variant="body1"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}>
              {Translator.translate('common.classFilter.name')}
            </Typography>
          )}
        </Stack>

        <Box
          sx={(theme) => ({
            'button svg': {
              fill: theme.palette.primary.main,
              fillOpacity: 1,
            },
          })}>
          <IconButtonNew onClick={onEditClass} tooltip={Translator.translate('common.classFilter.editClassButtonTitle')}>
            <EditPencilIcon />
          </IconButtonNew>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ClassFilter;
