import React from 'react';

const LogicalValueIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="rgba(169, 171, 185, 1)" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 8C7 9.10457 6.10457 10 5 10C3.89543 10 3 9.10457 3 8C3 6.89543 3.89543 6 5 6C6.10457 6 7 6.89543 7 8Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.45455 3C2.54603 3 0 5.14006 0 8C0 10.8599 2.54603 13 5.45455 13H10.5455C13.454 13 16 10.8599 16 8C16 5.14006 13.454 3 10.5455 3H5.45455ZM2 8C2 6.44166 3.4427 5 5.45455 5H10.5455C12.5573 5 14 6.44166 14 8C14 9.55834 12.5573 11 10.5455 11H5.45455C3.4427 11 2 9.55834 2 8Z"
      />
    </svg>
  );
};

export default LogicalValueIcon;
