import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Translator, globalSettingStore, usersStore } from 'stores';
import { InputSearch, Switch } from 'components';
import { Box, Paper, Stack } from '@mui/material';

import { Routes } from 'shared/enums/Routes';
import Utils from 'shared/utils/Utils';
import { GroupsSetting } from './Components';

const SettingsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentDefinitionId } = useParams();

  useEffect(() => {
    if (location.pathname === Routes.SETTINGS || location.pathname === `/${currentDefinitionId}${Routes.SETTINGS}`) {
      navigate(currentDefinitionId ? `/${currentDefinitionId}${Routes.SETTINGS}/${Routes.ROLES}` : `${Routes.SETTINGS}/${Routes.ROLES}`);
    }
  }, []);

  const showMyProject = (checked: boolean) => {
    usersStore.setShowAvailableInfo(checked);

    if (checked) usersStore.setUserFiltersId(usersStore.getUserData().id);
    else usersStore.setUserFiltersId(null);

    usersStore.getUsersList();
  };

  const onChangeUserSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    usersStore.setFilterNameUser(event.target.value);
  };

  const getRolesRoute = (): string => {
    return currentDefinitionId ? `/${currentDefinitionId}${Routes.SETTINGS}/${Routes.ROLES}` : `${Routes.SETTINGS}/${Routes.ROLES}`;
  };

  const canFilterParamSettings = (): boolean => {
    return location.pathname.includes(Routes.EXPORT_VIEW_LIST) || location.pathname.includes(Routes.EXPORT_VIEW_UNTITLED);
  };

  const onChangeParamSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    globalSettingStore.setFilterName(event.target.value);
  };

  return (
    <Stack flexDirection="row" gap={4} height={`calc(100vh - ${Utils.projectHeight()}px)`}>
      <Paper elevation={0} sx={{ p: 6, borderRadius: '12px', height: '100%', minWidth: 250 }}>
        <GroupsSetting />
      </Paper>

      <Paper elevation={0} sx={{ p: 6, borderRadius: '12px', height: '100%', width: '100%' }}>
        {location.pathname === getRolesRoute() && (
          <Stack direction="row" gap={6} alignItems="center" justifyContent="flex-end" pb={3}>
            <Switch
              label={Translator.translate('userInfo.myRoles')}
              labelPlacement="end"
              checked={usersStore.isShowAvailableInfo}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => showMyProject(event.target.checked)}
            />

            <Box width="20vw">
              <InputSearch value={usersStore.filterName} onSearch={() => console.log('1')} onChange={onChangeUserSearch} />
            </Box>
          </Stack>
        )}

        {canFilterParamSettings() && (
          <Stack width="100%" direction="row" justifyContent="flex-end">
            <Box width="20vw">
              <InputSearch value={globalSettingStore.filterName} onSearch={() => console.log('1')} onChange={onChangeParamSearch} />
            </Box>
          </Stack>
        )}

        <Outlet />
      </Paper>
    </Stack>
  );
};

export default observer(SettingsPage);
