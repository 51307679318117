import React, { useState, useEffect } from 'react';
import { DataGridPro, DataGridProProps, GridColumns, GridCellParams, gridClasses, useGridApiRef, MuiEvent } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import {
  Translator,
  appStore,
  approvalStore,
  approvalSuggestionRowStore,
  commentStore,
  parameterListValueStore,
  parameterValueStore,
  partialApprovalStore,
} from 'stores';
import { CustomCellGrouping, CustomDatePicker, CustomGridParameterRow, IconButtonNew, Input, SuggestionRow, Switch } from 'components';
import { useConfirm } from 'components/App/Confirm';
import { RangeEditor } from 'components/App/EditableTable/editors';
import CommentExistIcon from 'components/Icons/CommentExistIcon';
import CommentIcon from 'components/Icons/CommentIcon';
import DateValueIcon from 'components/Icons/DateValueIcon';
import FileValueIcon from 'components/Icons/FileValueIcon';
import LinkValueIcon from 'components/Icons/LinkValueIcon';
import ListIcon from 'components/Icons/ListIcon';
import LogicalValueIcon from 'components/Icons/LogicalValueIcon';
import NumberValueIcon from 'components/Icons/NumberValueIcon';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import RangeValueIcon from 'components/Icons/RangeValueIcon';
import TextValueIcon from 'components/Icons/TextValueIcon';
import TrashIcon from 'components/Icons/TrashIcon';
import XXCurveIcon from 'components/Icons/XXCurveIcon';
import { Stack, Box, Typography, Button } from '@mui/material';
import { CommentMode, DataType, PartialApprovalIteration, StateBoolean, SuggestionType } from 'shared/enums';
import { IApprovalRow, IApprovalRowSuggestionDto } from 'shared/interfaces';
import { ApprovalRowSuggestionModel } from 'shared/models';
import Utils from 'shared/utils/Utils';
import { ApprovalRowsListValueDialog } from './Components';

interface ICurrentEditRow {
  rowId: number | null;
  isEdit: boolean;
}

const ApprovalIterationRowsTable: React.FC = () => {
  const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy;
  const apiApprovalRowsRef = useGridApiRef();
  const confirm = useConfirm();
  const [editRow, setEditRow] = useState<ICurrentEditRow>({ rowId: null, isEdit: false });
  const [booleanState, setBooleanState] = useState<StateBoolean>(StateBoolean.Null);

  useEffect(() => {
    if (apiApprovalRowsRef.current) {
      if (approvalSuggestionRowStore.selectedTreeId) {
        const initTopPosition = apiApprovalRowsRef.current.getScrollPosition().top;

        const rowIndex = apiApprovalRowsRef.current.getRowIndexRelativeToVisibleRows(approvalSuggestionRowStore.selectedTreeId);
        const colIndex = apiApprovalRowsRef.current.getColumnIndex('originalValue', false);
        const indexes = { rowIndex, colIndex };

        apiApprovalRowsRef.current.scrollToIndexes(indexes);

        setTimeout(() => {
          const top = apiApprovalRowsRef.current.getScrollPosition().top;

          apiApprovalRowsRef.current.scroll({ left: 0, top: top > initTopPosition ? top + 50 : top });
        }, 1000);
      }
    }
  }, [apiApprovalRowsRef, approvalSuggestionRowStore.selectedTreeId]);

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    width: 10,
    minWidth: 30,
    headerName: '',
    renderCell: (params) => <CustomCellGrouping isGroup={params.row.isGroup} {...params} />,
  };

  const iconValue = (row: IApprovalRow | IApprovalRowSuggestionDto): JSX.Element => {
    switch (row.parameter?.dataType) {
      case DataType.Number:
        return <NumberValueIcon />;

      case DataType.Range:
        return <RangeValueIcon />;

      case DataType.Boolean:
        return <LogicalValueIcon />;

      case DataType.String:
        return <TextValueIcon />;

      case DataType.Link:
        return <LinkValueIcon />;

      case DataType.List:
        return <ListIcon />;

      case DataType.DateTime:
        return <DateValueIcon />;

      case DataType.File:
        return <FileValueIcon />;

      default:
        return <TextValueIcon />;
    }
  };

  const columns: GridColumns = [
    {
      field: 'originalValue',
      headerName: Translator.translate('approvals.approvalIterationRows.columns.originalValue'),
      flex: 1,
      editable: false,
      resizable: true,
      sortable: false,
      disableReorder: true,
      renderCell: (params: GridCellParams) => {
        const { row }: { row: IApprovalRow } = params;

        return row.isGroup ? (
          row.originalValue && !Array.isArray(row.originalValue) && renderOriginalGroupCell(row.depth, row)
        ) : (
          <Typography
            component={Stack}
            height="100%"
            justifyContent="center"
            variant="body2"
            color={row.hasPartialApprovalId ? 'text.primary' : 'text.disabled'}>
            {renderOriginalValue(row.originalValue)}
          </Typography>
        );
      },
    },
    {
      field: 'suggestedValue',
      headerName: Translator.translate('approvals.approvalIterationRows.columns.suggestedValue'),
      flex: 1,
      sortable: false,
      resizable: false,
      disableReorder: true,
      renderCell: (params: GridCellParams) => {
        const { row }: { row: IApprovalRow } = params;

        return row.isGroup
          ? row.suggestedValue && !Array.isArray(row.suggestedValue) && renderSuggestedGroupCell(row.depth, row)
          : renderSuggestedValue(row);
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: 8,
      minWidth: 80,
      align: 'right',
      resizable: false,
      hideSortIcons: true,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (params: GridCellParams) => {
        const { row }: { row: IApprovalRow } = params;

        if (row.isGroup) return;
        else {
          return (
            <Stack
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              sx={{
                pt: 1,
                svg: {
                  width: 16,
                  height: 16,
                },
              }}>
              {partialApprovalStore.selectedPartialApproval.myIterations.includes(partialApprovalStore.selectedPartialApproval.iteration) &&
                partialApprovalStore.selectedPartialApproval.iteration !== PartialApprovalIteration.Recheck && (
                  <IconButtonNew
                    disabled={!row.hasPartialApprovalId}
                    tooltip={Translator.translate('approvals.approvalIterationRows.deleteParameter')}
                    onClick={() => onDelete(row.id!)}>
                    <TrashIcon />
                  </IconButtonNew>
                )}

              {partialApprovalStore.selectedPartialApproval.myIterations.includes(partialApprovalStore.selectedPartialApproval.iteration) &&
                partialApprovalStore.selectedPartialApproval.iteration === PartialApprovalIteration.Approval && (
                  <IconButtonNew
                    tooltip={Translator.translate('actions.rejectAll')}
                    onClick={() => approvalSuggestionRowStore.rowSuggestionDenyAll(row.id!)}>
                    <XXCurveIcon />
                  </IconButtonNew>
                )}

              {appStore.isShowComments &&
                (row.hasComments ? (
                  <IconButtonNew
                    sx={(theme) => ({
                      svg: {
                        fill: theme.palette.action.active,
                      },
                    })}
                    tooltip={Translator.translate('actions.comments')}
                    onClick={(e) => {
                      e.stopPropagation();
                      onCommentApprovalRow(row);
                    }}>
                    <CommentExistIcon />
                  </IconButtonNew>
                ) : (
                  <IconButtonNew
                    tooltip={Translator.translate('actions.comment')}
                    onClick={(e) => {
                      e.stopPropagation();
                      onCommentApprovalRow(row);
                    }}>
                    <CommentIcon />
                  </IconButtonNew>
                ))}
            </Stack>
          );
        }
      },
    },
  ];

  useEffect(() => {
    return () => {
      approvalStore.selectedApproval.clear();
      approvalSuggestionRowStore.approvalSuggestionRow.clear();
    };
  }, []);

  const onCommentApprovalRow = (row: IApprovalRow) => {
    commentStore.setMode(CommentMode.ApprovalRow);
    commentStore.setOpenModal(true);

    approvalSuggestionRowStore.approvalSuggestionRow.setApprovalRowId(row.id);
    approvalSuggestionRowStore.approvalSuggestionRow.setParameterName(row.parameter?.name!);
    commentStore.setFilters();
    commentStore.getApprovalRowComments();
  };

  const onCancelEditData = () => {
    setEditRow({ rowId: null, isEdit: false });
  };

  const editableCell = (row: IApprovalRow): JSX.Element => {
    const onChangeValue = (value: string) => {
      if (value !== '' && value !== approvalSuggestionRowStore.approvalSuggestionRow.value) {
        if (!approvalSuggestionRowStore.approvalSuggestionRow.value) {
          approvalSuggestionRowStore.approvalSuggestionRow.setValue(value);
          approvalSuggestionRowStore.createRowSuggestionApproval().then(() => setEditRow({ rowId: null, isEdit: false }));
        } else {
          approvalSuggestionRowStore.approvalSuggestionRow.setValue(value);
          row.mySuggestion && approvalSuggestionRowStore.approvalSuggestionRow.setApprovalRowSuggestionId(row.mySuggestion?.id);
          approvalSuggestionRowStore.updateRowSuggestionApproval().then(() => setEditRow({ rowId: null, isEdit: false }));
        }
      } else {
        setEditRow({ rowId: row.id, isEdit: false });
      }
    };

    switch (row.parameter?.dataType) {
      case DataType.Range:
        return <RangeEditor value={approvalSuggestionRowStore.approvalSuggestionRow.value ?? ''} onChange={onChangeValue} />;

      case DataType.Boolean:
        return (
          <Switch
            labelPlacement="end"
            checked={booleanState === StateBoolean.True}
            onChange={(e) => setBooleanState(e.target.checked ? StateBoolean.True : StateBoolean.False)}
            onBlur={() => onChangeValue(booleanState === StateBoolean.Null ? '' : booleanState)}
          />
        );

      case DataType.DateTime:
        return (
          <CustomDatePicker
            value={approvalSuggestionRowStore.approvalSuggestionRow.value ?? ''}
            outline
            label=""
            onSubmit={(value) => onChangeValue(value as string)}
            onCancel={onCancelEditData}
          />
        );

      default:
        return (
          <Input
            size="small"
            type={row.parameter?.dataType === DataType.Number ? 'number' : 'string'}
            variant="outlined"
            autoComplete="off"
            canFocus={true}
            value={approvalSuggestionRowStore.approvalSuggestionRow.value ?? ''}
            onChange={onChangeValue}
          />
        );
    }
  };

  const getMultilineText = (value: string[]): JSX.Element => {
    if (value && value.length > 0) {
      return (
        <Stack>
          {value.map((text) => {
            return <Box key={Utils.generateId()}>{text}</Box>;
          })}
        </Stack>
      );
    } else {
      return <></>;
    }
  };

  const renderOriginalValue = (value: string | string[] | null): JSX.Element | string => {
    if (value && !Array.isArray(value)) {
      return value;
    } else if (value && Array.isArray(value)) {
      return getMultilineText(value);
    }

    return '';
  };

  const renderSuggestedValue = (row: IApprovalRow): JSX.Element => {
    const canEdit =
      partialApprovalStore.selectedPartialApproval.iteration === PartialApprovalIteration.Recheck
        ? false
        : row.hasPartialApprovalId &&
          !Utils.canEditApproval(partialApprovalStore.selectedPartialApproval.iteration, partialApprovalStore.selectedPartialApproval.userRoles);

    return (
      <Stack flexDirection="row" gap={2} width="100%" height="100%" alignItems="flex-start">
        {row.parameter && <Stack pt={2}>{iconValue(row)}</Stack>}

        <Stack flexDirection="column" width="100%" sx={{ wordBreak: 'break-all' }}>
          {row.approvalRowSuggestions.length > 0 &&
            row.approvalRowSuggestions.map((suggestion) => {
              const onSelectRow = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                event.stopPropagation();

                if (
                  (!partialApprovalStore.selectedPartialApproval.myIterations.includes(partialApprovalStore.selectedPartialApproval.iteration) ||
                    partialApprovalStore.selectedPartialApproval.iteration === PartialApprovalIteration.Recheck) &&
                  row.parameter?.dataType !== DataType.List
                )
                  return;

                approvalSuggestionRowStore.approvalSuggestionRow = new ApprovalRowSuggestionModel();
                approvalSuggestionRowStore.approvalSuggestionRow.setApprovalRowId(row.id);
                approvalSuggestionRowStore.approvalSuggestionRow.setSuggestionType(SuggestionType.Update);

                if (row.approvalRowSuggestions.length > 0) {
                  approvalSuggestionRowStore.approvalSuggestionRow.setValue(row.mySuggestion ? row.mySuggestion.value : null);
                }

                if (row.parameter?.dataType === DataType.List) {
                  const isEditingSuggestion = row.mySuggestion ? row.mySuggestion?.user.id === suggestion.user.id : true;
                  approvalSuggestionRowStore.approvalSuggestionRow.setCanEditList(canEdit && isEditingSuggestion);

                  approvalStore.setOpenApprovalDialog(true);
                  setEditRow({ rowId: row.id, isEdit: false });

                  parameterListValueStore.getParameterListValues(row.parameter?.id);
                  approvalSuggestionRowStore.approvalSuggestionRow.setParameterName(row.parameter?.name);

                  if (row.mySuggestion) {
                    approvalSuggestionRowStore.approvalSuggestionRow.setApprovalRowSuggestionId(row.mySuggestion.id);
                    approvalSuggestionRowStore.approvalSuggestionRow.setDefaultParameterListValueIds(row.mySuggestion.parameterListValues);

                    suggestion.parameterListValues &&
                      suggestion.parameterListValues.map((parameterListValue) => {
                        approvalSuggestionRowStore.approvalSuggestionRow.setParameterListValues(parameterListValue);
                      });
                  } else {
                    parameterValueStore.selectedParamValue.setValueId(row.parameterValueId);
                    await parameterValueStore.getParameterValueById();

                    parameterValueStore.selectedParamValue.parameterListValues.map((parameterListValue) => {
                      approvalSuggestionRowStore.approvalSuggestionRow.setParameterListValues(parameterListValue.id!);
                    });
                  }
                } else {
                  setEditRow({ rowId: row.id, isEdit: true });
                }
              };

              if (row.id === editRow?.rowId && editRow.isEdit && row.mySuggestion?.user.id === suggestion.user.id) {
                return (
                  <Box key={suggestion.id} width="100%">
                    {row.id === editRow?.rowId && editRow.isEdit && <Box width="100%">{editableCell(row)}</Box>}
                  </Box>
                );
              }

              return (
                <Stack key={suggestion.id} flexDirection="row" gap={2} width="100%" height="100%">
                  {suggestion.parameter && <Stack pt={2}>{iconValue(suggestion)}</Stack>}

                  <SuggestionRow
                    isUserAccept={suggestion.isUserAccept}
                    isEvaluationAccept={suggestion.isEvaluationAccept}
                    iteration={partialApprovalStore.selectedPartialApproval.iteration}
                    suggestionText={suggestion.text}
                    suggestionMultilineText={suggestion.multilineText}
                    suggestionType={suggestion.suggestionType}
                    isGroup={row.isGroup!}
                    user={suggestion.user}
                    suggestionRowId={suggestion.id}
                    userRoles={approvalStore.selectedApproval.userRoles.concat(partialApprovalStore.selectedPartialApproval.userRoles)}
                    onSelectSuggestionRow={onSelectRow}
                  />
                </Stack>
              );
            })}

          {(partialApprovalStore.selectedPartialApproval.iteration === PartialApprovalIteration.Execution ||
            partialApprovalStore.selectedPartialApproval.iteration === PartialApprovalIteration.Approval) &&
            row.parameter?.dataType === DataType.List &&
            row.approvalRowSuggestions.length > 0 &&
            !row.mySuggestion &&
            canEdit && (
              <Button
                variant="text"
                sx={{ justifyContent: 'flex-start' }}
                startIcon={
                  <Box
                    sx={(theme) => ({
                      svg: {
                        fill: theme.palette.primary.main,
                      },
                    })}>
                    <PlusIconForButton />
                  </Box>
                }>
                {Translator.translate('approvals.approvalRowsPage.addSuggestionButtonTitle')}
              </Button>
            )}

          {row.id === editRow?.rowId && editRow.isEdit && (row.approvalRowSuggestions.length === 0 || !row.mySuggestion) && (
            <Box width="100%">{row.id === editRow?.rowId && editRow.isEdit && <Box width="100%">{editableCell(row)}</Box>}</Box>
          )}
        </Stack>
      </Stack>
    );
  };

  const renderOriginalGroupCell = (depth: number, row: IApprovalRow): JSX.Element => {
    return (
      <Stack flexDirection="row" alignItems="center" gap={3} height="100%">
        {new Array(depth).fill(0).map((el, index) => {
          return (
            <Box color="text.disabled" key={index}>
              &bull;
            </Box>
          );
        })}

        <Typography variant="subtitle1" color={row.isNotHeaderPartial ? 'text.disabled' : 'text.primary'}>
          {row.originalValue}
        </Typography>
      </Stack>
    );
  };

  const renderSuggestedGroupCell = (depth: number, row: IApprovalRow): JSX.Element => {
    return (
      <Stack
        sx={(theme) => ({
          height: '100%',
          ml: '34px',
          borderLeft: '4px solid',
          borderColor: theme.palette.info.main,
          borderRadius: '4px 0 0 4px',
        })}
        flexDirection="row"
        alignItems="center"
        gap={3}>
        <Stack pl={2.5} flexDirection="row" alignItems="center" gap={3} height="100%">
          {new Array(depth).fill(0).map((el, index) => {
            return (
              <Box color="text.disabled" key={index}>
                &bull;
              </Box>
            );
          })}
        </Stack>

        <Typography variant="subtitle1" color={row.isNotHeaderPartial ? 'text.disabled' : 'text.primary'}>
          {row.suggestedValue}
        </Typography>
      </Stack>
    );
  };

  const onDelete = (rowId: number) => {
    confirm
      .show(Translator.translate('approvals.approvalIterationRows.confirmMessage.deleteSuggestedParameter'), Translator.translate('actions.delete'))
      .then(() => {
        approvalSuggestionRowStore.approvalSuggestionRow = new ApprovalRowSuggestionModel();

        approvalSuggestionRowStore.approvalSuggestionRow.setApprovalRowId(rowId);
        approvalSuggestionRowStore.approvalSuggestionRow.setSuggestionType(SuggestionType.Delete);

        approvalSuggestionRowStore.createRowSuggestionApproval();
      });
  };

  const onSelectedRow = async (params: GridCellParams) => {
    const { row }: { row: IApprovalRow } = params;

    if (
      (!partialApprovalStore.selectedPartialApproval.myIterations.includes(partialApprovalStore.selectedPartialApproval.iteration) ||
        partialApprovalStore.selectedPartialApproval.iteration === PartialApprovalIteration.Recheck) &&
      row.parameter?.dataType !== DataType.List
    )
      return;

    if (row.isGroup) {
      apiApprovalRowsRef.current.setRowChildrenExpansion(row.id!, !(params.rowNode.childrenExpanded ?? false));
      setEditRow({ rowId: null, isEdit: false });
      return;
    }

    if (!row.hasPartialApprovalId && row.parameter?.dataType !== DataType.List) {
      return;
    }

    if (editRow.isEdit && params.field !== 'suggestedValue') {
      setEditRow({ rowId: row.id, isEdit: false });
      return;
    }

    if (params.field !== 'suggestedValue') return;

    approvalSuggestionRowStore.approvalSuggestionRow = new ApprovalRowSuggestionModel();
    approvalSuggestionRowStore.approvalSuggestionRow.setApprovalRowId(row.id);
    approvalSuggestionRowStore.approvalSuggestionRow.setSuggestionType(SuggestionType.Update);

    //есть предлагаемые изменения
    if (row.approvalRowSuggestions.length > 0) {
      approvalSuggestionRowStore.approvalSuggestionRow.setValue(row.mySuggestion ? row.mySuggestion.value : null);
    }

    if (row.parameter?.dataType === DataType.List) {
      approvalStore.setOpenApprovalDialog(true);
      setEditRow({ rowId: row.id, isEdit: false });

      parameterListValueStore.getParameterListValues(row.parameter?.id);

      approvalSuggestionRowStore.approvalSuggestionRow.setParameterName(row.parameter?.name);

      approvalSuggestionRowStore.approvalSuggestionRow.setCanEditList(
        partialApprovalStore.selectedPartialApproval.iteration === PartialApprovalIteration.Recheck
          ? false
          : row.hasPartialApprovalId &&
              !Utils.canEditApproval(partialApprovalStore.selectedPartialApproval.iteration, partialApprovalStore.selectedPartialApproval.userRoles)
      );

      parameterValueStore.selectedParamValue.setValueId(row.parameterValueId);
      await parameterValueStore.getParameterValueById();

      if (row.mySuggestion) {
        approvalSuggestionRowStore.approvalSuggestionRow.setApprovalRowSuggestionId(row.mySuggestion.id);
        approvalSuggestionRowStore.approvalSuggestionRow.setDefaultParameterListValueIds(row.mySuggestion.parameterListValues);

        row.mySuggestion.parameterListValues &&
          row.mySuggestion.parameterListValues.map((id) => {
            approvalSuggestionRowStore.approvalSuggestionRow.setParameterListValues(id);
          });
      } else {
        parameterValueStore.selectedParamValue.parameterListValues.map((parameterListValue) => {
          approvalSuggestionRowStore.approvalSuggestionRow.setParameterListValues(parameterListValue.id!);
        });
      }
    } else {
      setEditRow({ rowId: row.id, isEdit: true });
    }
  };

  const onKeyDown = (params: GridCellParams, event: MuiEvent<React.KeyboardEvent<HTMLElement>>) => {
    if (event.code === 'Space') {
      event.stopPropagation();
    } else if (event.code === 'Escape') {
      setEditRow({ rowId: null, isEdit: false });
    }
  };

  const noRows = (): JSX.Element => (
    <Stack alignItems="center" mt={8}>
      <img style={{ alignSelf: 'center' }} src={`${process.env.PUBLIC_URL}/img/empty_data.svg`} alt="" />
      <Typography variant="subtitle1" color="text.disabled">
        {Translator.translate('system.emptyData')}
      </Typography>
    </Stack>
  );

  return (
    <>
      <DataGridPro
        sx={{
          border: 'none',
          '& .MuiDataGrid-row': {
            width: '100%',
            cursor: 'pointer',
            minHeight: '38px !important',
          },
          '& .MuiDataGrid-virtualScroller': {
            scrollBehavior: 'smooth',
          },
          '& .MuiDataGrid-columnHeaders': {
            border: 0,
          },
          '& .MuiDataGrid-cell': {
            border: 'none',
            alignItems: 'flex-start',
          },
          '& .MuiDataGrid-columnHeader:first-child .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeader:nth-last-of-type(2) .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-row:not(:last-child)': {
            marginBottom: '2px',
          },
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none',
          },
        }}
        apiRef={apiApprovalRowsRef}
        hideFooter
        getRowHeight={() => {
          return 'auto';
        }}
        headerHeight={36}
        treeData
        rows={approvalSuggestionRowStore.filteredApprovalIterationRows}
        isCellEditable={(params: GridCellParams) => {
          return !params.row.isGroup;
        }}
        onCellKeyDown={onKeyDown}
        loading={approvalStore.isLoading}
        onCellClick={onSelectedRow}
        columns={columns}
        disableColumnMenu
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
        defaultGroupingExpansionDepth={1}
        experimentalFeatures={{ newEditingApi: true }}
        disableSelectionOnClick
        components={{
          Row: CustomGridParameterRow,
          NoRowsOverlay: noRows,
        }}
      />

      {approvalStore.isApprovalDialogOpen && <ApprovalRowsListValueDialog />}
    </>
  );
};

export default observer(ApprovalIterationRowsTable);
