import React, { ChangeEvent, useState } from 'react';
import { DataGridPro, GridColumns, GridSelectionModel } from '@mui/x-data-grid-pro';
import { Translator } from 'stores';
import { InputSearch, DialogWrapper } from 'components';
import { Box } from '@mui/material';
import Utils from 'shared/utils/Utils';

interface IFindTableDataDialogProps {
  isOpen: boolean;
  title: string;
  selectAllText: string;
  notFoundText: string;
  data: any[];
  columns: GridColumns;
  isChecked?: boolean;
  onSave: (data: any[]) => void;
  onClose: () => void;
}

const FindTableDataDialog: React.FC<IFindTableDataDialogProps> = (props) => {
  const { data, title, columns, selectAllText, isChecked, notFoundText, isOpen, onSave, onClose } = props;

  const [filterName, setFilterName] = useState('');
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [tableData, setTableData] = useState<any[]>(data);

  const onChangeModel = (ids: GridSelectionModel) => {
    setSelectedIds(ids as number[]);
  };

  const onSearch = () => {
    //
  };

  const onChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFilterName(value);
    setTableData(data.filter((f) => Utils.includes(f.name, value)));
  };

  const onSaveData = () => {
    const ids = new Set(selectedIds);
    onSave(data.filter((row) => ids.has(row.id)));
  };

  return (
    <DialogWrapper
      title={title}
      titleSubmit={Translator.translate('actions.add')}
      open={isOpen}
      maxWidth="lg"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={onSaveData}>
      <InputSearch value={filterName} onSearch={onSearch} onChange={onChangeFilter} />

      <Box sx={{ height: '50vh', width: '100%' }}>
        {tableData.length === 0 ? (
          <Box sx={{ textAlign: 'center' }}>{data.length === 0 ? selectAllText : notFoundText}</Box>
        ) : (
          <DataGridPro
            sx={{
              border: 0,
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '1px solid rgba(242, 242, 249, 1) !important',
                color: 'text.secondary',
              },
              '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-cell': {
                border: 'none',
              },
              '& .MuiDataGrid-row': {
                minHeight: '36px !important',
                cursor: 'pointer',
                borderBottom: '1px solid rgba(242, 242, 249, 1) !important',
                borderRadius: '6px',
              },
              '& .MuiDataGrid-row:not(:last-child)': {
                marginBottom: '2px',
              },
              '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                outline: 'none !important',
              },
              '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
                outline: 'none !important',
              },
            }}
            hideFooter
            headerHeight={36}
            getRowHeight={() => {
              return 'auto';
            }}
            onSelectionModelChange={onChangeModel}
            checkboxSelection={isChecked ?? true}
            rows={tableData}
            columns={columns}
            disableColumnMenu
          />
        )}
      </Box>
    </DialogWrapper>
  );
};

export default FindTableDataDialog;
