import { makeAutoObservable } from 'mobx';
import { ClassificationDictionaries, ProjectState, ProjectType } from 'shared/enums';
import { IClassDictionary, IGetStageDto, IProjectCopyDto, IProjectForm, IProjectPostPutDto, IProjectsGetDto, ISelectOption } from 'shared/interfaces';
import { DefinitionModel } from 'shared/models/DefinitionModel';

class ProjectListModel {
  constructor(dto?: IProjectsGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.type = dto.type;
    this.createdAt = dto.createdAt;
    this.state = dto.state;
    this.address = dto.address;

    this.definitions = dto.definitions?.map((def) => new DefinitionModel(def)) ?? [];

    if (dto.type === ProjectType.Local) {
      dto.definitions
        ?.map((el) => el.stage!)
        .forEach((stage) => {
          if (!this.stages.find((f) => f.id === stage.id)) this.stages.push(stage);
        });
    }

    if (dto.projectClassificationDictionaryValues && dto.projectClassificationDictionaryValues?.length > 0) {
      if (dto.type === ProjectType.Local) {
        this.region = dto.projectClassificationDictionaryValues?.find(
          (el) => el.classificationDictionaryValue.classificationDictionary.id === ClassificationDictionaries.Region
        )?.classificationDictionaryValue ?? { id: null, value: '' };
      }

      if (dto.type === ProjectType.Standard) {
        this.regions = dto.projectClassificationDictionaryValues
          ?.filter((el) => el.classificationDictionaryValue.classificationDictionary.id === ClassificationDictionaries.Region)
          ?.map((el) => el.classificationDictionaryValue);

        this.regionIds = dto.projectClassificationDictionaryValues
          ?.filter((el) => el.classificationDictionaryValue.classificationDictionary.id === ClassificationDictionaries.Region)
          ?.map((el) => el.classificationDictionaryValue.id!);
      }

      this.buildingType = dto.projectClassificationDictionaryValues?.find(
        (el) => el.classificationDictionaryValue.classificationDictionary.id === ClassificationDictionaries.BuildingType
      )?.classificationDictionaryValue ?? { id: null, value: '' };

      this.buildingClass = dto.projectClassificationDictionaryValues
        ?.filter((el) => el.classificationDictionaryValue.classificationDictionary.id === ClassificationDictionaries.BuildingClass)
        .map((el) => el.classificationDictionaryValue);

      this.buildingClassIds = this.buildingClass.map((_) => _.id!);
    }
  }

  public id: number | null = null;

  public name = '';

  public type: ProjectType = ProjectType.Standard;

  public state = ProjectState.Proposed;

  public createdAt = '';

  public address = '';

  public projectId: number | null = null;

  public stageId: number | null = null;

  public externalId: number | null = null;

  public externalName = '';

  public definitions: DefinitionModel[] = [];

  public stages: IGetStageDto[] = [];

  public region: IClassDictionary = { id: null, value: '' };

  public regions: IClassDictionary[] = [];

  public regionIds: number[] = [];

  public buildingType: IClassDictionary = { id: null, value: '' };

  public buildingClass: IClassDictionary[] = [];

  public buildingClassIds: number[] = [];

  public isCollapseRow = false;

  public isHover = false;

  public getSelectOption(): ISelectOption | undefined {
    if (!this.id) return;

    return { id: this.id, name: this.name };
  }

  public get postPutStandardDto(): IProjectPostPutDto | null {
    const getClassDictionary = () => {
      const res = [];
      if (this.buildingType.id) res.push(this.buildingType.id);
      if (this.regionIds.length > 0) this.regionIds.forEach((_) => res.push(_));
      if (this.buildingClassIds.length > 0) this.buildingClassIds.forEach((_) => res.push(_));

      return res;
    };

    return {
      name: this.name,
      type: this.type,
      classificationDictionaryValueIds: getClassDictionary(),
    };
  }

  public get postPutLocalDto(): IProjectPostPutDto | null {
    if (!this.buildingType.id || !this.region.id || this.buildingClassIds.length === 0 || !this.address) return null;

    return {
      name: this.name,
      type: this.type,
      address: this.address,
      classificationDictionaryValueIds: [this.buildingType.id, this.region.id, ...this.buildingClassIds],
    };
  }

  public get copyDto(): IProjectCopyDto | null {
    if (this.type === ProjectType.Local) {
      if (!this.buildingType.id || !this.region.id || this.buildingClassIds.length === 0 || !this.address || !this.stageId) return null;
    } else {
      if (this.regionIds.length === 0 || !this.name) return null;
    }

    const getClassDictionary = () => {
      const res = [];
      if (this.buildingType.id) res.push(this.buildingType.id);
      if (this.region.id && this.type === ProjectType.Local) res.push(this.region.id);
      if (this.regionIds.length > 0 && this.type === ProjectType.Standard) this.regionIds.forEach((_) => res.push(_));
      if (this.buildingClassIds.length > 0) this.buildingClassIds.forEach((_) => res.push(_));

      return res;
    };

    return {
      name: this.externalId !== null ? this.externalName : this.name,
      type: this.type,
      projectId: this.projectId!,
      externalId: this.externalId,
      address: this.address,
      classificationDictionaryValueIds: getClassDictionary(),
      stageId: this.stageId!,
    };
  }

  public updateProjectDialogForm(data: IProjectForm) {
    this.name = data.name;
    this.region.id = data.region;
    this.regionIds = data.regionIds;
    this.projectId = data.standard;
    this.externalId = data.externalId;
    this.externalName = data.externalName;
    this.address = data.address;
    this.buildingType.id = data.buildingType;
    this.buildingClassIds = data.buildingClasses;
    this.stageId = data.stage;
  }

  public setHover(isHover: boolean) {
    this.isHover = isHover;
  }

  public setCollapseRow(isColLapse: boolean) {
    this.isCollapseRow = isColLapse;
  }

  public setType(type: ProjectType) {
    this.type = type;
  }

  public setProjectId(id: number | null) {
    this.projectId = id;
  }

  public setStageId(stageId: number) {
    this.stageId = stageId;
  }

  public setBuildingType(id: number) {
    this.buildingType.id = id;
  }

  public setRegion(id: number) {
    this.region.id = id;
  }

  public setExternalId(id: number | null) {
    this.externalId = id;
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.state = ProjectState.Proposed;
    this.type = ProjectType.Standard;
    this.projectId = null;
    this.externalId = null;
    this.stageId = null;
    this.externalName = '';
    this.buildingType = { id: null, value: '' };
    this.region = { id: null, value: '' };
    this.createdAt = '';
    this.address = '';
    this.buildingClass = [];
    this.buildingClassIds = [];
    this.regionIds = [];
    this.regions = [];
    this.stages = [];
    this.definitions = [];
    this.isCollapseRow = false;
    this.isHover = false;
  }
}

export default ProjectListModel;
