import { makeAutoObservable } from 'mobx';
import { definitionStore, parameterGroupStore } from 'stores';
import CollapseTableModel from '../CollapseTableModel';
import { ParameterGroupModel } from '../ParameterGroupModel';
import { MergeGroups, MergeState, MergeTabState } from 'shared/enums';
import { IPostMergeDto } from 'shared/interfaces';
import { IShortParameterGroupList } from 'shared/interfaces/app/IParameterGroup';
import MergeTableModel from './MergeTableModel';
import ParameterValueMergeTableModel from './ParameterValueMergeModel';

/** Слияние */

class MergeModel {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public currentGroupMerge: MergeGroups = MergeGroups.Items;

  public currentMergeDefinition: number | null = null;

  public heightMergeTables: number | null = null;

  public mergeChangeItems: MergeTableModel[] = [];

  public mergeAddDelItems: MergeTableModel[] = [];

  public mergeParamValues: CollapseTableModel<ParameterValueMergeTableModel>[] = [];

  public selectedTab: MergeTabState = MergeTabState.AddDel;

  public checkedIds = new Set<number>();

  public get allCheckedIds(): number[] {
    return Array.from(this.checkedIds);
  }

  public getAllCheckedIdsCollapse(mergeGroup: MergeGroups): number[] {
    const checkedMergeParamValues: number[] = [];

    switch (mergeGroup) {
      case MergeGroups.ParameterValues: {
        this.mergeParamValues.forEach((group) => {
          if (group.groupItems.length !== 0) {
            group.groupItems.forEach((parameter) => {
              if (parameter.checked) checkedMergeParamValues.push(parameter.id!);
            });
          }
        });

        return checkedMergeParamValues;
      }

      default:
        return [];
    }
  }

  public get halfCheckedGroupParameters(): number[] {
    return this.mergeParamValues.filter((value) => value.groupItems.some((el: any) => el.checked === true)).map((_) => _.id!);
  }

  public get totalCount(): number {
    return (
      this.mergeAddDelItems.length +
      this.mergeChangeItems.length +
      this.mergeParamValues.map((value) => value.groupItems.length).reduce((acc, num) => acc + num, 0)
    );
  }

  public get totalCountChecked(): number {
    return (
      this.mergeAddDelItems.filter((f) => f.checked).map((value) => value.id!).length +
      this.mergeChangeItems.filter((f) => f.checked).map((value) => value.id!).length +
      this.getAllCheckedIdsCollapse(MergeGroups.ParameterValues).length
    );
  }

  public get filteredParamValues(): CollapseTableModel<any>[] {
    const selectedGroups: IShortParameterGroupList[] = [];

    const loop = (parentId: number) => {
      const selectedDataWithParent = parameterGroupStore.listTransformParamGroupDto.filter((f) => f.id === parentId || f.parentId === parentId);

      if (selectedDataWithParent.length > 0) {
        selectedDataWithParent.forEach((el) => {
          if (selectedGroups.length === 0 || !selectedGroups.find((f) => f.id === el.id)) {
            selectedGroups.push(el);
            loop(el.id);
          }
        });
      }
    };

    parameterGroupStore.selectedParamGroup.id && loop(parameterGroupStore.selectedParamGroup.id);

    return this.mergeParamValues.filter((f) =>
      selectedGroups
        .filter((f) => f.isPresented)
        .map((_) => _.id)
        .includes(f.id!)
    );
  }

  public get mergeData(): MergeTableModel[] | CollapseTableModel<any>[] {
    switch (this.currentGroupMerge) {
      case MergeGroups.Items: {
        if (this.selectedTab === MergeTabState.AddDel) return this.mergeAddDelItems;
        else if (this.selectedTab === MergeTabState.Changed) return this.mergeChangeItems;
        else return [];
      }

      case MergeGroups.ParameterValues:
        return this.filteredParamValues;

      default:
        return [];
    }
  }

  public get postDto(): IPostMergeDto | null {
    if (!definitionStore.currentDefinition.id || !this.currentMergeDefinition) return null;

    const addedItem = this.mergeAddDelItems.filter((f) => f.checked && f.icon === MergeState.Added).map((value) => value.id!);
    const deletedItem = this.mergeAddDelItems.filter((f) => f.checked && f.icon === MergeState.Deleted).map((value) => value.id!);

    return {
      sourceDefinitionId: this.currentMergeDefinition,
      targetDefinitionId: definitionStore.currentDefinition.id,
      selectedChanges: {
        Item: {
          Added: addedItem,
          Deleted: deletedItem,
          Updated: this.mergeChangeItems.filter((f) => f.checked).map((value) => value.id!),
        },
        ParameterValue: {
          Added: this.getCheckedDependsState(this.mergeParamValues, MergeState.Added),
          Deleted: this.getCheckedDependsState(this.mergeParamValues, MergeState.Deleted),
          Updated: this.getCheckedDependsState(this.mergeParamValues, MergeState.Updated),
        },
      },
    };
  }

  public updateCheckedIds(id: number, checked: boolean) {
    if (checked) {
      !this.checkedIds.has(id) && this.checkedIds.add(id);
    } else {
      this.checkedIds.has(id) && this.checkedIds.delete(id);
    }
  }

  public setCheckedGroupParameters(checkedKeysValue: any, info: any) {
    const groupWithChildrenIds: number[] = [];
    const loop = (data: any) => {
      this.updateCheckedIds(data.id, info.checked);
      !groupWithChildrenIds.includes(data.id) && groupWithChildrenIds.push(data.id);

      if (data.children.length > 0) {
        data.children.forEach((el: any) => {
          this.updateCheckedIds(el.id, info.checked);
          !groupWithChildrenIds.includes(el.id) && groupWithChildrenIds.push(el.id);

          loop(el);
        });
      }
    };

    loop(info.node);

    this.mergeParamValues.forEach((group) => {
      if (group.id && groupWithChildrenIds.includes(group.id)) {
        group.setChecked(this.checkedIds.has(group.id));

        if (group.groupItems.length !== 0) {
          group.groupItems.forEach((groupItem: any) => {
            groupItem.setChecked(group.id && this.checkedIds.has(group.id));
          });
        }
      }
    });
  }

  public getCheckedDependsState(data: CollapseTableModel<any>[], state: MergeState): number[] {
    const checked: number[] = [];

    data.forEach((group) => group.groupItems.filter((f) => f.checked && f.state === state).forEach((parameter) => checked.push(parameter.id!)));

    return checked;
  }

  public getMergeCheckedLength(data: MergeTableModel[]): number {
    return data.filter((f) => f.checked).map((value) => value.id!).length;
  }

  public setCurrentMergeDefinition(value: number | null) {
    this.currentMergeDefinition = value;
  }

  public setHeightMergeTables(heightMerge: number) {
    this.heightMergeTables = heightMerge;
  }

  public setSelectedTab(value: MergeTabState) {
    this.selectedTab = value;
  }

  public setCurrentGroupMerge(value: MergeGroups) {
    this.currentGroupMerge = value;
  }

  public clear() {
    this.currentMergeDefinition = null;
    this.mergeChangeItems = [];
    this.mergeAddDelItems = [];
    this.mergeParamValues = [];
    this.checkedIds = new Set<number>();
    this.selectedTab = MergeTabState.AddDel;
  }
}

export default MergeModel;
