import { makeAutoObservable } from 'mobx';
import {
  IClassificationDictionaryValueGetDto,
  IDictionaryValueFormDto,
  IPostClassificationDictionaryValueDto,
  IPutClassificationDictionaryValueDto,
} from 'shared/interfaces';

class DictionaryValueModel {
  constructor(dto?: IClassificationDictionaryValueGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.classificationDictionaryId = dto.classificationDictionaryId;
    this.value = dto.value;
  }

  public id: number | null = null;

  public classificationDictionaryId: number | null = null;

  public value = '';

  public updateDictionaryValueDialogForm(data: IDictionaryValueFormDto) {
    this.value = data.value;
  }

  public postDto(classificationDictionaryId: number): IPostClassificationDictionaryValueDto | null {
    if (this.value === '') return null;

    return {
      classificationDictionaryId,
      value: this.value,
    };
  }

  public get putDto(): IPutClassificationDictionaryValueDto | null {
    if (this.value === '') return null;

    return {
      value: this.value,
    };
  }

  public clear() {
    this.id = null;
    this.classificationDictionaryId = null;
    this.value = '';
  }
}

export default DictionaryValueModel;
