import React from 'react';

const DateValueIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="rgba(169, 171, 185, 1)" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C12 1.44772 11.5523 1 11 1C10.4477 1 10 1.44772 10 2V3H6V2C6 1.44772 5.55228 1 5 1C4.44772 1 4 1.44772 4 2V3H3.11111C2.04689 3 1 3.79893 1 5V13C1 14.2011 2.04689 15 3.11111 15H12.8889C13.9531 15 15 14.2011 15 13V5C15 3.79893 13.9531 3 12.8889 3H12V2ZM3.11111 5H12.8889C12.9456 5 12.9823 5.01831 13 5.0316V7H3V5.0316C3.01766 5.01831 3.05439 5 3.11111 5ZM3 9V12.9684C3.01766 12.9817 3.05439 13 3.11111 13H12.8889C12.9456 13 12.9823 12.9817 13 12.9684V9H3Z"
      />
    </svg>
  );
};

export default DateValueIcon;
