import React, { ReactNode } from 'react';
import { Translator } from 'stores';
import CloseDialogIcon from 'components/Icons/CloseDialogIcon';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Typography } from '@mui/material';

interface IDialogWrapperProps extends DialogProps {
  fullHeight?: boolean;
  title?: string;
  subTitle?: string | JSX.Element;
  titleSubmit?: string;
  children: ReactNode;
  isCustom?: boolean;
  isHideClose?: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onSubmit?: () => void;
  disableSubmit?: boolean;
  hideActions?: boolean;
}

const DialogWrapper: React.FC<IDialogWrapperProps> = (props) => {
  const {
    title,
    subTitle,
    isCustom,
    titleSubmit,
    isHideClose,
    fullHeight,
    children,
    onClose,
    onCancel,
    onSubmit,
    disableSubmit = false,
    hideActions = false,
  } = props;

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': { borderRadius: '12px', py: '40px', px: '20px' },
        '& .MuiDialogTitle-root': { p: 0, pb: 6, px: 5 },
        '& .MuiDialogContent-root': { p: 0, pb: 8, px: 5 },
        '& .MuiDialogActions-root': { p: 0, px: 5 },
        '& .MuiDialog-paper': { height: fullHeight ? '100%' : 'unset' },
      }}
      fullWidth
      {...props}>
      {!isHideClose && (
        <IconButton
          sx={{
            position: 'absolute',
            right: 6,
            top: 6,
          }}
          onClick={onClose}>
          <CloseDialogIcon />
        </IconButton>
      )}
      {isCustom ? (
        <>{children}</>
      ) : (
        <>
          <DialogTitle variant="h6" sx={{ display: 'flex', flexDirection: 'column', '& .MuiDialogTitle-root': { p: 0 } }} color="primary">
            {title}
            {subTitle &&
              (typeof subTitle === 'string' ? (
                <Typography color="text.primary" variant="caption">
                  {subTitle}
                </Typography>
              ) : (
                subTitle
              ))}
          </DialogTitle>

          <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 6, mb: 4, pr: 2 }}>{children}</DialogContent>

          {!hideActions && (
            <DialogActions disableSpacing sx={{ p: 0, gap: 4 }}>
              <Button variant="outlined" onClick={onCancel}>
                {Translator.translate('actions.cancel')}
              </Button>
              <Button variant="contained" onClick={onSubmit} disabled={disableSubmit}>
                {titleSubmit ? titleSubmit : Translator.translate('actions.save')}
              </Button>
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
};

export default DialogWrapper;
