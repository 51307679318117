import { makeAutoObservable } from 'mobx';
import { ParameterMode } from 'shared/enums';

class FilterParameterModel {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public modeParameter = ParameterMode.Parameter;

  public parameterGroupId: number | null = null;

  public includeRoot = true;

  public searchPattern = '';

  public stageId: number | null = null;

  public setSearchPattern(value: string) {
    this.searchPattern = value;
  }

  public setParameterGroupId(id: number | null) {
    this.parameterGroupId = id;
  }

  public setModeParameter(value: ParameterMode) {
    this.modeParameter = value;
  }

  public setIncludeRoot(value: boolean) {
    this.includeRoot = value;
  }

  public setStageId(stageId: number | null) {
    this.stageId = stageId;
  }

  public clear() {
    this.modeParameter = ParameterMode.Parameter;
    this.parameterGroupId = null;
    this.includeRoot = true;
    this.searchPattern = '';
    this.stageId = null;
  }
}

export default FilterParameterModel;
