import React, { DependencyList, EffectCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { Translator, approvalStore, informationBlockStore, partialApprovalStore, specificationStore, usersStore } from 'stores';
import { DownloadSpecificationButton, InputValidate, PopupMenu, TabsContent, TextWrapper } from 'components';
import { useConfirm } from 'components/App/Confirm';
import ArrowLeftIcon from 'components/Icons/ArrowLeftIcon';
import CheckIcon from 'components/Icons/CheckIcon';
import DownloadIcon from 'components/Icons/DownloadIcon';
import RevertIcon from 'components/Icons/RevertIcon';
import SmallCheckIcon from 'components/Icons/SmallCheckIcon';
import { Box, Button, Chip, Divider, Stack, Typography, Link, MenuItem } from '@mui/material';
import {
  ApprovalAction,
  ApprovalDialogMode,
  ApprovalStatus,
  ApprovalStatusNames,
  ApprovalUserRole,
  LangNames,
  PartialApprovalStatus,
} from 'shared/enums';
import { Routes } from 'shared/enums/Routes';
import { ITabItem } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';
import { ApprovedDialog, PartialApprovalDetail, UserDateSelect } from './Components';

const watchParam = (id: string | undefined, request: (id: number) => void): [EffectCallback, DependencyList] => [
  () => {
    if (id) {
      request.call(approvalStore, Number(id));
    }
  },
  [id],
];

export const HEIGHT_CONTENT = 103;

const DetailApprovalPage: React.FC = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();

  const approvalInfRef = useRef<any>(null);
  const { approvalId } = useParams();
  const [isChangedName, setChangedName] = useState(false);

  const title =
    approvalStore.approvalDialogMode === ApprovalDialogMode.Create
      ? Translator.translate('approvals.detailApprovalPage.title')
      : approvalStore.selectedApproval.name;

  const heightContent = approvalInfRef.current?.offsetHeight + HEIGHT_CONTENT;

  useEffect(() => {
    usersStore.getUsersList();
    informationBlockStore.getInformationBlocks();

    return () => {
      partialApprovalStore.selectedPartialApproval.clear();

      approvalStore.selectedApproval.clear();
      approvalStore.setApprovalDialogMode(ApprovalDialogMode.Watch);
      approvalStore.selectedApproval.clear();
    };
  }, []);

  useEffect(...watchParam(approvalId, approvalStore.getApprovalById));

  useEffect(() => {
    if (approvalStore.selectedApproval.id && approvalStore.selectedApproval.partialApprovals.length !== 0) {
      partialApprovalStore.getPartialApprovalById(approvalStore.selectedApproval.partialApprovals[0].id);
    }
  }, [approvalStore.selectedApproval.id]);

  const getTabItems = (): ITabItem[] => {
    return approvalStore.selectedApproval.partialApprovalsForTabs.map((el) => {
      return {
        ...el,
        label: (
          <Stack flexDirection="row" alignItems="center" justifyContent="center">
            {el.status === PartialApprovalStatus.PendingApproval && partialApprovalStore.selectedPartialApproval.id !== el.value && (
              <Stack mr={2}>
                <SmallCheckIcon />
              </Stack>
            )}

            <Box
              sx={{
                color:
                  el.status === PartialApprovalStatus.Canceled && partialApprovalStore.selectedPartialApproval.id !== el.value
                    ? 'text.secondary'
                    : '',
              }}>
              {el.label}
            </Box>
          </Stack>
        ),
        content: <PartialApprovalDetail />,
      };
    });
  };

  const onDeleteApproval = () => {
    confirm
      .show(Translator.translate('approvals.detailApprovalPage.confirmMessage.deleteApproval'), Translator.translate('actions.delete'))
      .then(() => {
        approvalStore.removeApproval(approvalStore.selectedApproval.id!).then(() => navigate(`${Routes.APPROVALS}`));
      });
  };

  const onCanceledApproval = () => {
    confirm
      .show(Translator.translate('approvals.detailApprovalPage.confirmMessage.canceledApproval'), Translator.translate('actions.revoke'))
      .then(() => {
        approvalStore.updateApprovalStatus(ApprovalAction.Cancel);
      });
  };

  const onApprovedApproval = () => {
    approvalStore.setOpenApprovalDialog(true);
  };

  const onCreatePartialApproval = async () => {
    partialApprovalStore.selectedPartialApproval.clear();

    const createId = approvalStore.selectedApproval.setPartialApprovals();

    // set default attr for post Partial Approval
    partialApprovalStore.selectedPartialApproval.setId(createId);
    partialApprovalStore.selectedPartialApproval.setInformationBlockId(informationBlockStore.informationBlocks[0].id);
    partialApprovalStore.selectedPartialApproval.setName(
      approvalStore.selectedApproval.partialApprovals[approvalStore.selectedApproval.partialApprovals.length - 1].name
    );

    const fetchCreateId = await partialApprovalStore.createPartialApproval();

    // change createId in tabs array to fetchCreateId from API
    approvalStore.selectedApproval.partialApprovals.find((el) => el.id === createId)!.id = fetchCreateId as number;
    partialApprovalStore.selectedPartialApproval.setId(fetchCreateId as number);
  };

  const paint = (status: ApprovalStatus) => {
    switch (status) {
      case ApprovalStatus.Approved:
        return 'success';

      case ApprovalStatus.InProgress:
        return 'primary';

      case ApprovalStatus.Canceled:
        return 'secondary';

      case ApprovalStatus.Draft:
        return 'default';

      default:
        return 'secondary';
    }
  };

  const onDeletePartialApproval = () => {
    if (approvalStore.approvalDialogMode !== ApprovalDialogMode.Create && approvalStore.selectedApproval.status !== ApprovalStatus.Canceled) {
      confirm
        .show(Translator.translate('approvals.detailApprovalPage.confirmMessage.canceledPartialApproval'), Translator.translate('actions.revoke'))
        .then(() => {
          partialApprovalStore.updateCancelPartialApproval().then(() => {
            approvalStore.getApprovalById(approvalStore.selectedApproval.id!).then(() => {
              if (approvalStore.selectedApproval.partialApprovals.every((data) => data.status === PartialApprovalStatus.Canceled)) {
                approvalStore.updateApprovalStatus(ApprovalAction.Cancel);
              }
            });
          });
        });
    } else {
      confirm
        .show(Translator.translate('approvals.detailApprovalPage.confirmMessage.deletePartialApproval'), Translator.translate('actions.delete'))
        .then(() => {
          partialApprovalStore.removePartialApproval();
        });
    }
  };

  return (
    <Stack id="approvalWrapper" gap={6}>
      <Stack ref={approvalInfRef} gap={6}>
        <Stack id="approvalHeader" direction="row" justifyContent="space-between" alignItems="center">
          <Stack id="approvalTitle" direction="row" gap={3}>
            <Link style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => navigate(-1)}>
              <ArrowLeftIcon />
            </Link>

            <Typography variant="subtitle1">{title}</Typography>

            <Chip
              size="small"
              variant="outlined"
              color={paint(approvalStore.selectedApproval.status)}
              label={Translator.translate(ApprovalStatusNames.get(approvalStore.selectedApproval.status)!)}
            />
          </Stack>

          <Stack direction="row" gap={3}>
            <DownloadSpecificationButton
              specificationId={approvalStore.selectedApproval.specification.id}
              name={approvalStore.selectedApproval.specification.name}
            />

            {approvalStore.approvalDialogMode === ApprovalDialogMode.Create && (
              <Stack id="approvalActions" direction="row" gap={3}>
                <Button variant="text" onClick={onDeleteApproval}>
                  {Translator.translate('actions.delete')}
                </Button>

                <Button
                  variant="contained"
                  onClick={() =>
                    approvalStore
                      .updateApprovalStatus(ApprovalAction.Start)
                      .then(() => partialApprovalStore.getPartialApprovalById(partialApprovalStore.selectedPartialApproval.id!))
                  }>
                  {Translator.translate('approvals.detailApprovalPage.launchApprovalButtonTitle')}
                </Button>
              </Stack>
            )}

            {approvalStore.approvalDialogMode === ApprovalDialogMode.Edit &&
              approvalStore.selectedApproval.status !== ApprovalStatus.Canceled &&
              approvalStore.selectedApproval.status !== ApprovalStatus.Approved && (
                <Stack id="approvalActions" direction="row" gap={3}>
                  <Button
                    variant="text"
                    onClick={onCanceledApproval}
                    sx={{ color: 'rgba(15, 11, 31, 0.54)' }}
                    startIcon={
                      <Box
                        sx={{
                          svg: {
                            fill: 'rgba(15, 11, 31, 0.54)',
                          },
                        }}>
                        <RevertIcon />
                      </Box>
                    }>
                    {Translator.translate('actions.revoke')}
                  </Button>

                  {approvalStore.selectedApproval.userRoles.includes(ApprovalUserRole.Approver) && (
                    <Button
                      variant="text"
                      onClick={onApprovedApproval}
                      sx={{ color: 'rgba(1, 182, 74, 1)' }}
                      startIcon={
                        <Box
                          sx={{
                            svg: {
                              fill: 'rgba(1, 182, 74, 1)',
                            },
                          }}>
                          <CheckIcon />
                        </Box>
                      }>
                      {Translator.translate('actions.approve')}
                    </Button>
                  )}
                </Stack>
              )}
          </Stack>
        </Stack>

        <Stack id="approvalForm" gap={6}>
          <Stack id="approvalFields" direction="row" alignItems="center" gap={6}>
            {approvalStore.approvalDialogMode === ApprovalDialogMode.Create && (
              <InputValidate
                name="name"
                sx={{
                  width: '100%',
                  maxWidth: '340px',
                }}
                size="small"
                label={Translator.translate('approvals.detailApprovalPage.approvalFields.name')}
                required
                value={approvalStore.selectedApproval.name}
                onChange={(e: any) => {
                  if (approvalStore.selectedApproval.name !== e.target.value) setChangedName(true);
                  approvalStore.selectedApproval.setName(e.target.value);
                }}
                onBlur={async (e: any) => {
                  if (isChangedName) {
                    await approvalStore.updateApproval();
                    setChangedName(false);
                  }
                }}
              />
            )}

            {approvalStore.approvalDialogMode !== ApprovalDialogMode.Create && (
              <TextWrapper
                name={Translator.translate('approvals.detailApprovalPage.approvalFields.name')}
                description={`${approvalStore.selectedApproval.project.name} / ${approvalStore.selectedApproval.definition.name}`}
              />
            )}

            <TextWrapper
              name={Translator.translate('approvals.detailApprovalPage.approvalFields.definition')}
              description={`${approvalStore.selectedApproval.project.name} / ${approvalStore.selectedApproval.definition.name}`}
            />

            <TextWrapper
              name={Translator.translate('approvals.detailApprovalPage.approvalFields.specification')}
              description={approvalStore.selectedApproval.specification.name}
            />
          </Stack>

          <Divider
            sx={(theme) => ({
              borderRightWidth: 2,
              borderColor: theme.palette.background.default,
            })}
            orientation="horizontal"
          />

          <Stack id="approvalMembers" direction="row" gap={6}>
            <TextWrapper
              name={Translator.translate('approvals.approvalUserRoleNames.initiator')}
              description={approvalStore.selectedApproval.initiator.userName}
            />

            <Stack id="createDate">
              <TextWrapper
                name={Translator.translate('approvals.detailApprovalPage.approvalFields.createDate')}
                description={dayjs(approvalStore.selectedApproval.createdAt).format(
                  Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY'
                )}
              />
            </Stack>

            {approvalStore.approvalDialogMode !== ApprovalDialogMode.Watch &&
            approvalStore.selectedApproval.userRoles.includes(ApprovalUserRole.Initiator) &&
            approvalStore.selectedApproval.createdAt !== '' ? (
              <UserDateSelect
                required
                label={Translator.translate('approvals.approvalUserRoleNames.approver')}
                value={approvalStore.selectedApproval.approver}
                showDate
                onChangeUser={(userId) => {
                  if (approvalStore.selectedApproval.approver.id !== userId) {
                    approvalStore.selectedApproval.approver.setChanged(true);
                    approvalStore.selectedApproval.approver.setUserId(userId);
                  }
                }}
                onBlurUser={async () => {
                  if (approvalStore.selectedApproval.approver.isChanged) {
                    await approvalStore.updateApproval();
                    approvalStore.selectedApproval.approver.setChanged(false);
                  }
                }}
                onBlurExpiration={(value: Date | string) => {
                  const dateVal = value instanceof Date ? value : new Date(value);
                  if (
                    dayjs(approvalStore.selectedApproval.approver.expiration)?.format(
                      Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY'
                    ) !== dayjs(dateVal).format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY')
                  ) {
                    approvalStore.selectedApproval.approver.setExpiration(dateVal);
                    approvalStore.selectedApproval.approver.id && approvalStore.updateApproval();
                  }
                }}
              />
            ) : (
              <>
                <Stack id="approverName">
                  <TextWrapper
                    name={Translator.translate('approvals.approvalUserRoleNames.approver')}
                    description={approvalStore.selectedApproval.approver.userName}
                  />
                </Stack>

                <Stack id="deadLine">
                  <TextWrapper
                    name={Translator.translate('approvals.detailApprovalPage.userDateSelect.expiration')}
                    description={dayjs(approvalStore.selectedApproval.approver.expiration).format(
                      Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY'
                    )}
                  />
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Box sx={{ width: '100%', height: `calc(100vh - ${heightContent + Utils.projectHeight()}px)` }}>
        <TabsContent
          scrollButtons
          selectedTab={partialApprovalStore.selectedPartialApproval.id!}
          tabItems={getTabItems()}
          onAddTab={
            approvalStore.selectedApproval.status === ApprovalStatus.Draft && approvalStore.approvalDialogMode !== ApprovalDialogMode.Watch
              ? onCreatePartialApproval
              : undefined
          }
          onDeleteTab={approvalStore.approvalDialogMode !== ApprovalDialogMode.Watch ? onDeletePartialApproval : undefined}
          onChangeTab={async (e, value) => {
            partialApprovalStore.selectedPartialApproval.clear();
            await partialApprovalStore.getPartialApprovalById(value);
          }}
        />
      </Box>

      {approvalStore.isApprovalDialogOpen && <ApprovedDialog />}
    </Stack>
  );
};

export default observer(DetailApprovalPage);
4;
