import { GridRowsProp } from '@mui/x-data-grid-pro';
import { makeAutoObservable, runInAction } from 'mobx';
import { ParameterGroupService, ParameterValueService } from 'services';
import {
  Translator,
  definitionStore,
  itemStore,
  parameterGroupStore,
  parameterListValueStore,
  parameterStore,
  parameterValueStore,
  sectionSetStore,
  sectionsStore,
  stageStore,
  toastStore,
} from 'stores';
import { ProjectType } from 'shared/enums';
import { IGetParameterGroupValuesDto, IGetParameterValueDto, IParameterValueTable, ISelectOption } from 'shared/interfaces';
import { FilterParameterValueModel, ParameterValueModel } from 'shared/models';

class ParameterValueStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoadingParameterValue = false;

  public selectedParamValue = new ParameterValueModel();

  public filterStateParamValues = new FilterParameterValueModel();

  public paramValueTableData: IParameterValueTable[] = [];

  public get filteredParamValueTableData(): GridRowsProp {
    let parameters: IParameterValueTable[] = [];

    if (this.filterStateParamValues.isHideEmpty) {
      parameters = this.paramValueTableData.filter((f) => f.value !== '' && !f.isInheritedValue);
    } else parameters = this.paramValueTableData;

    if (!this.filterStateParamValues.searchPattern) {
      return parameters;
    }

    const data = parameters.filter(
      (parameter) =>
        parameter.parameterName?.toLowerCase().includes(this.filterStateParamValues.searchPattern.toLowerCase()) ||
        parameter.hierarchy[0].toLowerCase().includes(this.filterStateParamValues.searchPattern.toLowerCase())
    );

    return data;
  }

  public get sectionOptions(): ISelectOption[] {
    const options = sectionsStore.sections.map((item) => item.getSelectOption()!);

    return options;
  }

  public get sectionDefaultValue(): ISelectOption | undefined {
    const options = sectionsStore.sections.map((item) => item.getSelectOption()!);

    return options.find((f: any) => f.id === this.filterStateParamValues.sectionId);
  }

  public initFilterState() {
    itemStore.setWaitingItem(true);
    itemStore.getAllItems().then(() => {
      if (itemStore.treeTestItem.length !== 0) {
        const findFilterItemId = itemStore.listTransformItemDto.find((item) => item.id === this.filterStateParamValues.itemId);
        if (!findFilterItemId) {
          this.filterStateParamValues.setItemId(itemStore.treeTestItem[0].id);
        }
      } else {
        this.filterStateParamValues.setItemId(null);
      }
    });
    itemStore.setWaitingItem(false);

    stageStore.getStages();
    sectionSetStore.getSectionSets();
    sectionsStore.getSections();

    /*  Если тип проекта - ИСП устанавливаем фильтр по его стадии проектирования */
    if (definitionStore.currentDefinition.id && definitionStore.currentDefinition.projectType !== ProjectType.Standard) {
      this.filterStateParamValues.setStageId(definitionStore.currentDefinition.stages[0].id);
    }

    !definitionStore.currentDefinition.id &&
      definitionStore.currentDefinitionId &&
      definitionStore.getDefinitionById(definitionStore.currentDefinitionId).then(() => {
        if (definitionStore.currentDefinition.projectType !== ProjectType.Standard) {
          this.filterStateParamValues.setStageId(definitionStore.selectedDefinition.stageId);
        }
      });

    /*  Делаем активной первую корневую группу параметров  */
    parameterGroupStore.getRootParameterGroupList().then(async () => {
      if (!parameterGroupStore.currentParameterGroup)
        parameterGroupStore.setCurrentParameterGroup(Number(parameterGroupStore.rootParameterGroup[0].id));

      await parameterGroupStore.getParameterGroups();

      if (parameterGroupStore.parameterGroupTree.length > 0 && !parameterValueStore.filterStateParamValues.parameterGroupId) {
        //раскрытие узла и выставление фильтра (первый элемент в дереве после элемента глобальной группы)
        this.defaultExpandSelectParamGroup();
      }
    });
  }

  public loadParameterValue() {
    this.getParameterGroupValues().then(() => {
      //выделение строки параметра при переходе со стр. комментарий
      if (parameterStore.selectedParameterTable.id) {
        const findEl = this.paramValueTableData.find((f) => f.id === parameterStore.selectedParameterTable.id);
        if (findEl) {
          this.setSelectedParamValue(findEl as any);
        }
      }
    });
  }

  public defaultExpandSelectParamGroup() {
    //раскрытие узла и выставление фильтра (первый элемент в дереве после элемента глобальной группы)
    if (parameterGroupStore.parameterGroupTree.length !== 0) {
      const expandedKey =
        parameterGroupStore.parameterGroupTree.length > 0
          ? parameterGroupStore.parameterGroupTree[0].children[0]['key']
          : parameterGroupStore.parameterGroupTree[0]['key'];

      const currentParameterGroup =
        parameterGroupStore.parameterGroupTree.length > 0
          ? parameterGroupStore.parameterGroupTree[0].children[0]['id']
          : parameterGroupStore.parameterGroupTree[0]['id'];

      parameterGroupStore.expandedKeys.push(expandedKey);
      parameterValueStore.filterStateParamValues.setParameterGroupId(currentParameterGroup);
    }

    itemStore.getAllItemsHasValues();
  }

  public setSelectedParamValue(row: IGetParameterValueDto) {
    this.selectedParamValue = new ParameterValueModel(row);
    this.selectedParamValue.setValue(row.value);
  }

  public getParamValueTableData(dto: IGetParameterGroupValuesDto[]): IParameterValueTable[] {
    const tableData: IParameterValueTable[] = [];

    dto.forEach((el) => {
      el.parameters.forEach((parameter) => {
        const unit = parameter.measurementUnit ? parameter.measurementUnit.name : '';
        const parameterName = unit ? String(parameter.name + `, ${unit}`) : parameter.name;

        tableData.push({
          hierarchy: [el.fullName, parameter.name],
          id: parameter.id,
          valueId: parameter.value?.id ?? null,
          parameterName,
          value: parameter.value ? parameter.value.value : '',
          isInheritedValue: parameter.value ? parameter.value.isInheritedValue : false,
          description: parameter.description ?? null,
          documents: parameter.documents,
          hasComments: parameter.hasComments,

          dataType: parameter.dataType,
          parameterListValues: parameter.value ? parameter.value.parameterListValues : [],
        });
      });
    });

    return tableData;
  }

  public async setFileValueEditMode(isInheritedValue: boolean) {
    parameterListValueStore.setFilesDialogOpen(true);
    await this.getParameterValueById();
    parameterValueStore.selectedParamValue.setInheritedValue(isInheritedValue);
  }

  public async getParameterGroupValues(): Promise<void> {
    if (!this.filterStateParamValues.parameterGroupId || !this.filterStateParamValues.itemId) return;

    try {
      this.isLoadingParameterValue = true;

      const result = await ParameterGroupService.getAllParameterGroupValues({
        parentId: this.filterStateParamValues.parameterGroupId,
        itemId: this.filterStateParamValues.itemId,
        stageId: this.filterStateParamValues.stageId,
        sectionSetId: this.filterStateParamValues.sectionSetId,
        sectionId: this.filterStateParamValues.sectionId,
        includeRoot: this.filterStateParamValues.includeRoot,
      });

      if (!result) return;

      this.paramValueTableData = this.getParamValueTableData(result);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} ParameterGroupValues`);
    } finally {
      this.isLoadingParameterValue = false;
    }
  }

  public updateValue(): Promise<void> {
    if (this.selectedParamValue.value !== '') {
      if (this.selectedParamValue.valueId !== null && !this.selectedParamValue.isInheritedValue) return this.updateParameterValue();
      else return this.createParameterValue();
    } else {
      return this.deleteParameterValue();
    }

    this.selectedParamValue.clear();
  }

  public async getParameterValueById(): Promise<void> {
    if (!this.selectedParamValue.valueId) return;

    try {
      const result = await ParameterValueService.getParameterValue(this.selectedParamValue.valueId);

      runInAction(() => {
        this.selectedParamValue = new ParameterValueModel(result);
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} ParameterValue`);
    } finally {
    }
  }

  public async createParameterValue(): Promise<void> {
    const payload = this.filterStateParamValues.itemId && this.selectedParamValue.postDto(this.filterStateParamValues.itemId);
    if (!payload) return;

    try {
      await ParameterValueService.postParameterValue(payload);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} ParameterValue`);
    } finally {
      itemStore.getAllItemsHasValues();
      this.loadParameterValue();
    }
  }

  public async updateParameterValue(): Promise<void> {
    if (!this.selectedParamValue.putDto || !this.selectedParamValue.valueId) return;

    try {
      await ParameterValueService.putParameterValue(this.selectedParamValue.valueId, this.selectedParamValue.putDto);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} ParameterValue`);
    } finally {
      this.loadParameterValue();
    }
  }

  public async deleteParameterValue(): Promise<void> {
    if (!this.selectedParamValue.valueId) return;

    try {
      await ParameterValueService.deleteParameterValue(this.selectedParamValue.valueId);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} ParameterValue`);
    } finally {
      itemStore.getAllItemsHasValues();
      this.loadParameterValue();
    }
  }

  public async createParameterValueFile(): Promise<void> {
    if (!this.selectedParamValue.file.postDto) return;

    try {
      const result = await ParameterValueService.postFile(this.selectedParamValue.file.postDto);

      if (!result) return;

      this.selectedParamValue.setValue(result.name);
      this.selectedParamValue.file.setId(result.id);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} ParameterValueFile`);
    }
  }

  public resetParameterValueList() {
    this.paramValueTableData = [];
  }
}

export default new ParameterValueStore();
