import React from 'react';

const PdfIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z" fill="#E9E9F4" />
      <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#A9ABB9" />
      <path d="M30 14L24 8H30V14Z" fill="#7B68EE" fillOpacity="0.08" />
      <path d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z" fill="#C62828" />
      <path
        d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z"
        fill="url(#paint0_linear_12607_183096)"
        fillOpacity="0.24"
      />
      <path
        d="M6.35907 18.947C6.35907 18.683 6.56707 18.395 6.90207 18.395H8.74907C9.78907 18.395 10.7251 19.091 10.7251 20.425C10.7251 21.689 9.78907 22.393 8.74907 22.393H7.41407V23.449C7.41407 23.801 7.19007 24 6.90207 24C6.63807 24 6.35907 23.801 6.35907 23.449V18.947ZM7.41407 19.402V21.394H8.74907C9.28507 21.394 9.70907 20.921 9.70907 20.425C9.70907 19.866 9.28507 19.402 8.74907 19.402H7.41407Z"
        fill="white"
      />
      <path
        d="M12.291 24C12.027 24 11.739 23.856 11.739 23.505V18.963C11.739 18.676 12.027 18.467 12.291 18.467H14.122C17.776 18.467 17.696 24 14.194 24H12.291ZM12.795 19.443V23.025H14.122C16.281 23.025 16.377 19.443 14.122 19.443H12.795Z"
        fill="white"
      />
      <path
        d="M18.9921 19.5071V20.7781H21.0311C21.3191 20.7781 21.6071 21.0661 21.6071 21.3451C21.6071 21.6091 21.3191 21.8251 21.0311 21.8251H18.9921V23.5041C18.9921 23.7841 18.7931 23.9991 18.5131 23.9991C18.1611 23.9991 17.9461 23.7841 17.9461 23.5041V18.9621C17.9461 18.6751 18.1621 18.4661 18.5131 18.4661H21.3201C21.6721 18.4661 21.8801 18.6751 21.8801 18.9621C21.8801 19.2181 21.6721 19.5061 21.3201 19.5061H18.9921V19.5071Z"
        fill="white"
      />
      <path d="M25 27H6V28H25C25.55 28 26 27.55 26 27V26C26 26.55 25.55 27 25 27Z" fill="#7B68EE" fillOpacity="0.12" />
      <defs>
        <linearGradient id="paint0_linear_12607_183096" x1="14" y1="15" x2="14" y2="27" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PdfIcon;
