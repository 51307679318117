import _ from 'lodash';
import { makeAutoObservable } from 'mobx';
import { ApprovalStatus } from 'shared/enums';
import { IApprovalParams } from 'shared/interfaces';

class ApprovalFiltersModel {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public filterName = '';

  public statuses: ApprovalStatus[] = [];

  public isMine = false;

  public isExpired = false;

  public get paramsDto(): IApprovalParams {
    return {
      isMine: this.isMine,
      isExpired: this.isExpired,
      statuses: this.statuses,
    };
  }

  public get hasFilter() {
    return this.filterName !== '' || this.statuses.length > 0 || this.isMine || this.isExpired;
  }

  public setFilterName(filterName: string) {
    this.filterName = filterName;
  }

  public setIsMine(isMine: boolean) {
    this.isMine = isMine;
  }

  public setIsExpired(isExpired: boolean) {
    this.isExpired = isExpired;
  }

  public setStatuses(status: ApprovalStatus[]) {
    this.statuses = status;
  }

  public clear() {
    this.statuses = [];
    this.isExpired = false;
    this.filterName = '';
  }
}

export default ApprovalFiltersModel;
