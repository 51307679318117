import React from 'react';
import { Translator, appStore } from 'stores';
import { IconButtonNew, PopupMenu } from 'components';
import CommentExistIcon from 'components/Icons/CommentExistIcon';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import HistoryIcon from 'components/Icons/HistoryIcon';
import InfoIcon from 'components/Icons/InfoIcon';
import MoreVerticalIcon from 'components/Icons/MoreVerticalIcon';
import PlusIcon from 'components/Icons/PlusIcon';
import TrashIcon from 'components/Icons/TrashIcon';
import { Divider, Box, Stack, Typography, Tooltip } from '@mui/material';

import s from './ButtonItemTitle.module.scss';

interface ITreeItemProps {
  item: any;
  title: string;
  searchValue?: string | null;
  hovered?: boolean;
  compact?: boolean;
  hasComments?: boolean;
  onComment?: (value: any) => void;
  onAdd?: (value: any) => void;
  onEdit?: (value: any) => void;
  onHistory?: (value: any) => void;
  onDelete?: (value: any) => void;
  isMaster?: boolean;
  isIndicationNavigation?: boolean;
  valuesInGroup?: number[];
  valuesInSubGroup?: number[];
  parentsItemInGroup?: number[];
  disabled?: boolean;
}

const ButtonItemTitle: React.FC<ITreeItemProps> = (props) => {
  const getButtons = (): JSX.Element[] => {
    const buttons: JSX.Element[] = [];
    if (props.onAdd) {
      buttons.push(
        <IconButtonNew
          key={0}
          tooltip={Translator.translate('actions.add')}
          onClick={(e) => {
            e.stopPropagation();
            props.onAdd!(props.item);
          }}>
          <Box className={s.buttonIcon}>
            <PlusIcon />
          </Box>
        </IconButtonNew>
      );
    }

    if (props.onEdit) {
      buttons.push(
        <IconButtonNew
          key={1}
          tooltip={Translator.translate('actions.edit')}
          onClick={(e) => {
            e.stopPropagation();
            props.onEdit!(props.item);
          }}>
          <Box className={s.buttonIcon}>
            <EditPencilIcon />
          </Box>
        </IconButtonNew>
      );
    }

    if (props.onHistory) {
      buttons.push(
        <IconButtonNew
          key={2}
          tooltip={Translator.translate('actions.changeHistory')}
          onClick={(e) => {
            e.stopPropagation();
            props.onHistory!(props.item);
          }}>
          <Box className={s.buttonIcon}>
            <HistoryIcon />
          </Box>
        </IconButtonNew>
      );
    }

    if (props.onDelete) {
      buttons.push(
        <Divider key={3} sx={{ m: 1, borderRightWidth: 2, height: 20 }} orientation="vertical" />,
        <IconButtonNew
          key={4}
          tooltip={Translator.translate('actions.delete')}
          onClick={(e) => {
            e.stopPropagation();
            props.onDelete!(props.item);
          }}>
          <Box className={s.buttonIcon}>
            <TrashIcon />
          </Box>
        </IconButtonNew>
      );
    }

    return buttons;
  };

  const getColor = (theme: any): string => {
    if (props.valuesInGroup?.includes(props.item.id) || props.parentsItemInGroup?.includes(props.item.id)) {
      return theme.palette.primary.main;
    } else if (props.isIndicationNavigation) {
      return theme.palette.primary.main;
    } else if (props.valuesInSubGroup && props.valuesInSubGroup.includes(props.item.id)) {
      return theme.palette.action.focus;
    } else if (props.disabled) {
      return theme.palette.text.disabled;
    } else {
      return '';
    }
  };

  const getTitle = (): JSX.Element => {
    if (props.searchValue) {
      const strTitle = String(props.title).toLowerCase();
      const index = strTitle.toString().indexOf(props.searchValue.toLowerCase());

      if (index > -1) {
        const beforeTitle = String(props.title);
        const beforeStr = beforeTitle.toString().substring(0, index);
        const afterStr = beforeTitle.toString().slice(index + props.searchValue.length);

        let highlightValue = '';
        const value = beforeTitle.toString().slice(index, index + props.searchValue.length);
        if (value.toLowerCase() === props.searchValue.toLowerCase()) {
          highlightValue = value;
        }

        return (
          <Stack flexDirection="row" alignItems="center">
            {props.isIndicationNavigation && (
              <IconButtonNew
                sx={(theme) => ({ svg: { fill: theme.palette.error.main, fillOpacity: 1 } })}
                tooltip={Translator.translate('actions.hasConflicts')}>
                {<InfoIcon />}
              </IconButtonNew>
            )}
            <Box
              component="span"
              sx={(theme: any) => ({
                color: getColor(theme),
              })}
              className={props.isMaster ? s.masterValue : ''}>
              {beforeStr}
              <span className={s.searchValue}>{highlightValue}</span>
              {afterStr}
              {props.valuesInGroup && props.valuesInGroup.includes(props.item.id) && ' •'}
            </Box>
          </Stack>
        );
      }
    }

    return (
      <Stack flexDirection="row" alignItems="center">
        {props.isIndicationNavigation && (
          <IconButtonNew
            sx={(theme) => ({ svg: { fill: theme.palette.error.main, fillOpacity: 1 } })}
            tooltip={Translator.translate('actions.hasConflicts')}>
            {<InfoIcon />}
          </IconButtonNew>
        )}
        <Box
          component="span"
          sx={(theme: any) => ({
            color: getColor(theme),
          })}
          className={props.isMaster ? s.masterValue : ''}>
          {props.title} {props.valuesInGroup && props.valuesInGroup.includes(props.item.id) && ' •'}
        </Box>
      </Stack>
    );
  };

  return (
    <Stack flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center">
      <Stack flexDirection="row" alignContent="center" alignItems="center" minWidth="0">
        <Typography variant="body2" alignItems="center" gap={1} className={s.titleWrapper}>
          {getTitle()}
        </Typography>

        {props.hasComments && appStore.isShowComments && props.onComment && (
          <IconButtonNew
            tooltip={Translator.translate('actions.comments')}
            onClick={(e) => {
              e.stopPropagation();
              props.onComment!(props.item);
            }}>
            <Stack alignItems="center">
              <CommentExistIcon />
            </Stack>
          </IconButtonNew>
        )}
      </Stack>

      {props.hovered &&
        (props.compact ? (
          <PopupMenu
            isFlexDirectionRow
            button={
              <IconButtonNew>
                <MoreVerticalIcon color="#7B68EE" />
              </IconButtonNew>
            }
            menuItems={getButtons()}
          />
        ) : (
          <Stack flexDirection="row" alignItems="center">
            {getButtons()}
          </Stack>
        ))}
    </Stack>
  );
};

ButtonItemTitle.displayName = 'TreeDataButtonItem';

export default ButtonItemTitle;
