import React from 'react';

const LanguageIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75ZM8.37414 2.93302C5.38969 3.61682 3.10769 6.14254 2.78832 9.25H6.28413C6.44959 6.98325 7.17191 4.80526 8.37414 2.93302ZM11.6259 2.93301C12.8281 4.80526 13.5504 6.98325 13.7159 9.25H17.2117C16.8923 6.14254 14.6103 3.61682 11.6259 2.93301ZM12.2114 9.25C12.0309 7.04696 11.2607 4.95075 10 3.21065C8.73934 4.95075 7.96912 7.04696 7.7886 9.25H12.2114ZM7.7886 10.75H12.2114C12.0309 12.953 11.2607 15.0493 10 16.7894C8.73934 15.0493 7.96912 12.953 7.7886 10.75ZM6.28413 10.75H2.78832C3.10769 13.8575 5.38969 16.3832 8.37414 17.067C7.17191 15.1947 6.44959 13.0167 6.28413 10.75ZM11.6259 17.067C12.8281 15.1947 13.5504 13.0167 13.7159 10.75H17.2117C16.8923 13.8575 14.6103 16.3832 11.6259 17.067Z"
      />
    </svg>
  );
};

export default LanguageIcon;
