import React from 'react';

const CheckIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="rgba(15, 11, 31, 0.54)" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7806 3.23989C15.0796 3.5529 15.072 4.05271 14.7637 4.35624L6.20811 12.7773C5.90642 13.0742 5.42691 13.0742 5.12522 12.7773L1.23633 8.94954C0.927954 8.64601 0.920379 8.1462 1.21941 7.83319C1.51844 7.52017 2.01085 7.51248 2.31923 7.81601L5.66667 11.1108L13.6808 3.22271C13.9892 2.91918 14.4816 2.92687 14.7806 3.23989Z"
      />
    </svg>
  );
};

export default CheckIcon;
