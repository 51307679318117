import { makeAutoObservable, runInAction } from 'mobx';
import { DocumentService } from 'services';
import { Translator, categoryStore, toastStore } from 'stores';
import { ICollapseTable, IGetDocumentDto, ISelectOption } from 'shared/interfaces';
import { CollapseTableModel, DocumentListModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

class DocumentStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isDocumentLoading = false;

  public isDialogOpen = false;

  public originalDocumentsDto: IGetDocumentDto[] = [];

  public filterDocument = '';

  public documents: DocumentListModel[] = [];

  public selectedDocument = new DocumentListModel();

  public get documentsForOptions(): ISelectOption[] {
    return this.documents.map((item) => item.getSelectOption()!);
  }

  public get documentCategories(): CollapseTableModel<IGetDocumentDto>[] {
    if (this.originalDocumentsDto.length === 0) return [];

    const documentCategories = categoryStore.categories.map((category: any) => {
      const documents = this.originalDocumentsDto.filter((p) => p.categoryId === category.id);

      const group: ICollapseTable = {
        id: category.id,
        fullName: category.group,
      };

      const model = new CollapseTableModel<IGetDocumentDto>(group);
      model.groupItems = documents;
      return model;
    });

    if (!this.filterDocument) {
      return documentCategories;
    }

    return documentCategories
      .map((category: any) => {
        const categoryValue = category.groupItems!.filter((p: IGetDocumentDto) => Utils.includes(p.name, this.filterDocument));
        const group: ICollapseTable = {
          id: category.id,
          fullName: category.group,
        };
        const model = new CollapseTableModel<IGetDocumentDto>(group);
        model.groupItems = categoryValue;
        return model;
      })
      .filter((category) => Utils.includes(category.group, this.filterDocument) || Boolean(category.groupItems.length))
      .map((category) => {
        if (!category.groupItems.length) {
          return documentCategories.find((p) => p.id === category.id) ?? category;
        }

        return category;
      });
  }

  public setDialogOpen(isOpen: boolean) {
    this.isDialogOpen = isOpen;
  }

  public setEditingMode(id: number) {
    this.selectedDocument.setId(id);
    this.setDialogOpen(true);
  }

  public setFilterDocument(value: string) {
    this.filterDocument = value;
  }

  public async getDocuments(): Promise<void> {
    try {
      this.isDocumentLoading = true;
      const result = await DocumentService.getAllDocuments();

      result.sort((a, b) => Utils.ascComp(a.name.trim(), b.name.trim()));
      this.originalDocumentsDto = result;

      runInAction(() => {
        this.documents = result.map((document) => new DocumentListModel(document));
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Documents`);
    } finally {
      this.isDocumentLoading = false;
    }
  }

  public async getDocumentById(id: number): Promise<void> {
    if (!id) return;

    try {
      this.isDocumentLoading = true;
      const result = await DocumentService.getDocument(id);

      if (!result) return;

      runInAction(() => {
        this.selectedDocument = new DocumentListModel(result[0]);
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} Document`);
    } finally {
      this.isDocumentLoading = false;
    }
  }

  public async createDocument(): Promise<void> {
    if (!this.selectedDocument.postPutDto) return;

    try {
      this.isDocumentLoading = true;
      await DocumentService.postDocument(this.selectedDocument.postPutDto);
      this.getDocuments();
    } catch (e: any) {
      toastStore.showError(e.detail ?? `${Translator.translate('stores.createErrorMessage')} Document`);
    } finally {
      this.isDocumentLoading = false;
    }
  }

  public async updateDocument(): Promise<void> {
    if (!this.selectedDocument.id || !this.selectedDocument.postPutDto) return;

    try {
      this.isDocumentLoading = true;
      await DocumentService.putDocument(this.selectedDocument.id, this.selectedDocument.postPutDto);
      this.getDocuments();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} Document`);
    } finally {
      this.isDocumentLoading = false;
    }
  }

  public async removeDocument(id: number): Promise<void> {
    try {
      this.isDocumentLoading = true;
      await DocumentService.deleteDocument(id);
      this.getDocuments();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} Document`);
    } finally {
      this.isDocumentLoading = false;
    }
  }
}

export default new DocumentStore();
