import React, { useRef, useEffect } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { Translator, informationBlockStore } from 'stores';
import { DialogWrapper, InputValidate } from 'components';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import { CircularProgress, Box, Stack, Button } from '@mui/material';
import { IInformationBlockFormDto } from 'shared/interfaces';
import { EditInfBlockParamGroupTable } from './Components';

interface IInformBlockDialogProps {
  readOnly?: boolean;
  onSubmit: (values: IInformationBlockFormDto) => void;
}

const InformBlockDialog: React.FC<IInformBlockDialogProps> = (props) => {
  const { readOnly, onSubmit } = props;
  const { isDialogOpen } = informationBlockStore;

  useEffect(() => {
    return () => {
      informationBlockStore.clearInformationBlock();
    };
  }, []);

  const formRef = useRef<FormikProps<IInformationBlockFormDto>>(null);

  const dialogTitle = informationBlockStore.selectedInformationBlock.id
    ? readOnly
      ? Translator.translate('common.informBlockDialog.title')
      : Translator.translate('common.informBlockDialog.editTitle')
    : Translator.translate('common.informBlockDialog.addTitle');

  const dictionaryValueForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        name: informationBlockStore.selectedInformationBlock.name,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required(Translator.translate('validationMessage.required')),
      })}>
      {({ handleChange, handleBlur, values, errors, touched }) => {
        return (
          <Stack gap={4} justifyContent="flex-start" height="100%">
            {!readOnly && (
              <InputValidate
                label={Translator.translate('common.informBlockDialog.formFields.name')}
                name="name"
                required
                size="small"
                error={Boolean(touched.name && errors.name)}
                helperText={(touched.name as any) && errors.name}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}

            <EditInfBlockParamGroupTable readOnly={readOnly} />

            {!readOnly && (
              <Button
                sx={{ width: 'fit-content' }}
                variant="text"
                onClick={onAddParameterGroup}
                startIcon={
                  <Box
                    sx={(theme) => ({
                      svg: {
                        fill: theme.palette.primary.main,
                      },
                    })}>
                    <PlusIconForButton />
                  </Box>
                }>
                {Translator.translate('common.informBlockDialog.actionAddTitle')}
              </Button>
            )}
          </Stack>
        );
      }}
    </Formik>
  );

  const onClose = () => {
    informationBlockStore.setDialogOpen(false);
  };

  const onAddParameterGroup = () => {
    informationBlockStore.setDialogParameterGroupOpen(true);
  };

  return (
    <DialogWrapper
      sx={{
        '& .MuiDialog-paper': { height: '600px', maxHeight: '600px', borderRadius: '12px', p: '40px 20px' },
      }}
      maxWidth="md"
      title={dialogTitle}
      subTitle={`${Translator.translate('common.informBlockDialog.subtitle')}: ${informationBlockStore.selectedInformationBlock.name}`}
      open={isDialogOpen}
      onClose={onClose}
      onCancel={onClose}
      hideActions={readOnly}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}>
      {informationBlockStore.isLoading ? (
        <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        dictionaryValueForm
      )}
    </DialogWrapper>
  );
};

export default observer(InformBlockDialog);
