import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import { observer } from 'mobx-react-lite';
import { Translator, parameterListValueStore } from 'stores';
import { DocumentParamDialog, IconButtonNew } from 'components';
import { EditableTable, EditorBlurEvent } from 'components/App/EditableTable';
import DocumentExistIcon from 'components/Icons/DocumentExistIcon';
import DownIcon from 'components/Icons/DownIcon';
import PlusIcon from 'components/Icons/PlusIcon';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import RuleIcon from 'components/Icons/RuleIcon';
import TrashIcon from 'components/Icons/TrashIcon';
import UpIcon from 'components/Icons/UpIcon';
import { Box, CircularProgress, Button, Stack, Typography } from '@mui/material';
import RuleEditDialog from '../RuleEditDialog';
import { IEditableTableColumn } from 'shared/interfaces';
import { ParameterListValueModel } from 'shared/models/ParameterListValueModel';

const ListValuesParamTable: React.FC = () => {
  const columns: IEditableTableColumn[] = [
    {
      key: 'value',
      label: Translator.translate('params.parameter.listValuesParamDialog.columns.value'),
      width: '35%',
      align: 'left',
      required: true,
      handleChange: async ({ value, row, index }) => {
        if (value !== '') {
          row.setValue(value);
        }
        parameterListValueStore.selectedParameterListValue = row;
        await parameterListValueStore.updateParameterListValue();
      },
      cell: (row: ParameterListValueModel) => {
        return <div style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>{row.value}</div>;
      },
    },
    {
      key: 'description',
      label: Translator.translate('params.parameter.listValuesParamDialog.columns.description'),
      width: '35%',
      align: 'left',
      handleChange: async ({ value, row, index }) => {
        row.setDescription(value);
        parameterListValueStore.selectedParameterListValue = row;
        await parameterListValueStore.updateParameterListValue();
      },
      cell: (row: ParameterListValueModel) => {
        return <div style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>{row.description}</div>;
      },
    },
    {
      key: 'documents',
      label: Translator.translate('params.parameter.listValuesParamDialog.columns.document'),
      align: 'left',
      width: '10%',
      editable: false,
      cell: (row: ParameterListValueModel) => {
        return (
          <Stack direction="row" alignItems="center">
            <IconButtonNew
              sx={(theme) => ({
                svg: {
                  width: 16,
                  height: 16,
                  fill: theme.palette.primary.main,
                  fillOpacity: 1,
                },
              })}
              onClick={() => parameterListValueStore.setDocumentDialogOpen(true)}
              tooltip={
                row.documents.length > 0
                  ? Translator.translate('params.parameter.columns.document')
                  : Translator.translate('params.parameter.addDocumentTooltip')
              }>
              {row.documents.length > 0 ? <DocumentExistIcon /> : <PlusIcon />}
            </IconButtonNew>

            {row.documents.length > 0 && <Typography variant="body2" color="primary.main">{`(${row.documents.length})`}</Typography>}
          </Stack>
        );
      },
    },
    {
      key: 'conditions',
      label: Translator.translate('params.parameter.listValuesParamDialog.columns.conditions'),
      align: 'left',
      width: '10%',
      editable: false,
      cell: (row: ParameterListValueModel) => {
        return (
          <>
            <IconButtonNew
              sx={(theme) => ({
                svg: {
                  width: 16,
                  height: 16,
                  fill: theme.palette.primary.main,
                  fillOpacity: 1,
                },
              })}
              onClick={() => parameterListValueStore.setRuleDialogOpen(true)}
              tooltip={
                row.isConditionIfExist
                  ? Translator.translate('params.parameter.listValuesParamDialog.columns.conditions')
                  : Translator.translate('params.parameter.listValuesParamDialog.addRuleTooltip')
              }>
              {row.isConditionIfExist ? <RuleIcon /> : <PlusIcon />}
            </IconButtonNew>
          </>
        );
      },
    },
    {
      key: 'actions',
      label: '',
      align: 'left',
      width: '10%',
      editable: false,
      cell: (row: ParameterListValueModel) => {
        return (
          <Stack
            flexDirection="row"
            gap={1}
            justifyContent="flex-end"
            sx={(theme) => ({
              mr: 2,
              svg: {
                width: 16,
                height: 16,
                ':hover': {
                  fill: theme.palette.primary.main,
                  fillOpacity: 1,
                },
              },
            })}>
            <IconButtonNew
              tooltip={Translator.translate('actions.up')}
              onClick={() => changeOrderParameterList(row, -1)}
              disabled={isDisabled(row) || disabledUp(row)}>
              {parameterListValueStore.isLoading && parameterListValueStore.selectedParameterListValue.id === row.id ? (
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress size="1.5rem" />
                </Box>
              ) : (
                <UpIcon />
              )}
            </IconButtonNew>

            <IconButtonNew
              tooltip={Translator.translate('actions.down')}
              onClick={() => changeOrderParameterList(row, 1)}
              disabled={isDisabled(row) || disabledDown(row)}>
              {parameterListValueStore.isLoading && parameterListValueStore.selectedParameterListValue.id === row.id ? (
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress size="1.5rem" />
                </Box>
              ) : (
                <DownIcon />
              )}
            </IconButtonNew>

            <Divider orientation="vertical" sx={{ height: '20px', borderColor: 'rgba(15, 11, 31, 0.08)' }} />

            <IconButtonNew tooltip={Translator.translate('actions.delete')} onClick={() => onDelete(row.id!)} disabled={isDisabled(row)}>
              <TrashIcon />
            </IconButtonNew>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    if (!parameterListValueStore.parameterListValues.length) {
      parameterListValueStore.appendEmptyItem();
      focusOnFirstInput();
    }
  }, []);

  const isDisabled = (row: ParameterListValueModel): boolean => {
    return parameterListValueStore.isLoading || row.id === null;
  };

  const disabledDown = (row: ParameterListValueModel): boolean => {
    if (parameterListValueStore.originalParameterListValues.length < 2) return true;
    const lastValue = parameterListValueStore.originalParameterListValues.length - 1;
    if (lastValue < parameterListValueStore.parameterListValues.length && row.id === parameterListValueStore.parameterListValues[lastValue].id) {
      return true;
    }
    return false;
  };

  const disabledUp = (row: ParameterListValueModel): boolean => {
    if (parameterListValueStore.originalParameterListValues.length < 2) return true;
    if (row.id === parameterListValueStore.parameterListValues[0].id) return true;
    return false;
  };

  const focusOnFirstInput = () => {
    setTimeout(() => {
      const firstInput: HTMLInputElement | null = document.querySelector('.editable-list-table .MuiInput-input');

      if (firstInput) {
        firstInput.focus();
      }
    }, 100);
  };

  const onAddParameterValue = () => {
    parameterListValueStore.appendEmptyItem();
    focusOnFirstInput();
  };

  const onDelete = (id: number) => {
    parameterListValueStore.deleteItem(id);
  };

  const changeOrderParameterList = async (row: ParameterListValueModel, order: 1 | -1) => {
    if (row.orderNum === 0) order = 1;

    parameterListValueStore.selectedParameterListValue = row;
    row.setOrderNum(row.orderNum! + order);

    await parameterListValueStore.updateParameterListValue();
  };

  const onClick = async (row: any, column: any) => {
    parameterListValueStore.selectedParameterListValue = row;
  };

  const onEditorBlur = (event: EditorBlurEvent) => {
    // Удаляем новый элемент если не ввели значение
    if (event.column.key === 'value' && event.row.id === null && event.row.value === '') {
      parameterListValueStore.removeItemByIndex(event.rowIndex);
    }
  };

  const onSubmitDocument = async () => {
    parameterListValueStore.updateParameterListValue();
    parameterListValueStore.setDocumentDialogOpen(false);
    parameterListValueStore.setOpenDialog(true);
  };

  return (
    <div className="editable-list-table">
      <EditableTable
        isActionsHover
        data={parameterListValueStore.parameterListValues}
        columns={columns}
        currentRow={parameterListValueStore.selectedParameterListValue.id}
        isSelectedById
        onClick={onClick}
        onEditorBlur={onEditorBlur}
      />

      <Button
        sx={{ mt: 4 }}
        variant="text"
        onClick={onAddParameterValue}
        disabled={parameterListValueStore.isLoading}
        startIcon={
          <Box
            sx={(theme) => ({
              svg: {
                fill: parameterListValueStore.isLoading ? theme.palette.text.disabled : theme.palette.primary.main,
              },
            })}>
            <PlusIconForButton />
          </Box>
        }>
        {Translator.translate('params.parameter.listValuesParamDialog.addValueButtonTitle')}
      </Button>

      {parameterListValueStore.isDocumentDialogOpen && (
        <DocumentParamDialog
          open={parameterListValueStore.isDocumentDialogOpen}
          tableData={parameterListValueStore.selectedParameterListValue.documents}
          onDelete={(id: number) => {
            parameterListValueStore.deleteDocumentFromList(id);
          }}
          onSelect={(documents) => parameterListValueStore.addDocumentInList(documents)}
          onSubmit={onSubmitDocument}
          onClose={() => parameterListValueStore.setDocumentDialogOpen(false)}
        />
      )}

      {parameterListValueStore.isRuleDialogOpen && <RuleEditDialog />}
    </div>
  );
};

export default observer(ListValuesParamTable);
