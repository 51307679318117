import { makeAutoObservable, runInAction } from 'mobx';
import { CategoryService } from 'services';
import { Translator, toastStore } from 'stores';
import { IGetCategoryDto, IGetDocumentDto, ISelectOption } from 'shared/interfaces';
import { CollapseTableModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

class CategoryStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isCategoryLoading = false;

  public categories: CollapseTableModel<IGetDocumentDto>[] = [];

  public originalCategoriesDto: IGetCategoryDto[] = [];

  public get categoriesForOptions(): ISelectOption[] {
    return this.originalCategoriesDto.map((item) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
  }

  public async getCategoryList(): Promise<void> {
    try {
      this.isCategoryLoading = true;
      const dtos = await CategoryService.getAllCategories();

      const sortingCategories = Utils.sortByField(dtos, 'orderNum');

      this.originalCategoriesDto = sortingCategories;

      const arr = sortingCategories.map((dtos) => {
        return { id: dtos.id, orderNum: dtos.orderNum, fullName: dtos.name };
      });

      runInAction(() => {
        this.categories = arr.map((dto) => new CollapseTableModel(dto));
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Categories`);
    } finally {
      this.isCategoryLoading = false;
    }
  }
}

export default new CategoryStore();
