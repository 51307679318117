import React from 'react';

const TrashIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="rgba(15, 11, 31, 0.54)" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 8C8.55228 8 9 8.44772 9 9V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V9C7 8.44772 7.44772 8 8 8Z" />
      <path d="M13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V9Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4V3.6C6 2.8873 6.30397 2.219 6.81979 1.73757C7.33327 1.25832 8.01582 1 8.71429 1H11.2857C11.9842 1 12.6667 1.25832 13.1802 1.73757C13.696 2.219 14 2.8873 14 3.6V4H17C17.5523 4 18 4.44772 18 5C18 5.55228 17.5523 6 17 6V16.4C17 17.1127 16.696 17.781 16.1802 18.2624C15.6667 18.7417 14.9842 19 14.2857 19H5.71429C5.01582 19 4.33327 18.7417 3.81978 18.2624C3.30397 17.781 3 17.1127 3 16.4V6C2.44772 6 2 5.55228 2 5C2 4.44772 2.44772 4 3 4H6ZM8.18442 3.19968C8.31392 3.07882 8.50344 3 8.71429 3H11.2857C11.4966 3 11.6861 3.07882 11.8156 3.19968C11.9427 3.31838 12 3.46401 12 3.6V4H8V3.6C8 3.46401 8.05725 3.31838 8.18442 3.19968ZM5 6V16.4C5 16.536 5.05725 16.6816 5.18442 16.8003C5.31392 16.9212 5.50344 17 5.71429 17H14.2857C14.4966 17 14.6861 16.9212 14.8156 16.8003C14.9427 16.6816 15 16.536 15 16.4V6H5Z"
      />
    </svg>
  );
};

export default TrashIcon;
