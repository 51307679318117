import { makeAutoObservable } from 'mobx';
import { ISelectOption, IDefinitionsGetDto } from 'shared/interfaces';

class DefinitionListModel {
  constructor(dto?: IDefinitionsGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.isMain = dto.isMain;
    this.version = dto.version;
    this.projectId = dto.projectId;
    this.stageName = dto.stage ? dto.stage.name : '';
    this.stageId = dto.stage ? dto.stage.id : null;
  }

  public id: number | null = null;

  public name = '';

  public stageName = '';

  public stageId: number | null = null;

  public isMain = false;

  public version: number | null = null;

  public projectId: number | null = null;

  public getSelectOption(): ISelectOption | undefined {
    if (!this.id) return;

    return { id: this.id, name: this.name };
  }
}

export default DefinitionListModel;
