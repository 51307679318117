import React, { SyntheticEvent, useEffect, useState, forwardRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useDebounce } from 'usehooks-ts';
import { Translator, parameterStore } from 'stores';
import { IconButtonNew, Select } from 'components';
import TrashIcon from 'components/Icons/TrashIcon';
import { Stack, TextField, Autocomplete, Box } from '@mui/material';
import { DataType } from 'shared/enums';
import { IAutocompleteParameter, IParamCondition, ISelectOption } from 'shared/interfaces';

interface IOperationRowProps {
  listValue: number;
  parameter: IParamCondition;
  options: ISelectOption[];
  onChangeParameter: (value: IAutocompleteParameter) => void;
  onChangeValues: (e: any) => void;
  onDelete: () => void;
}

const OperationRow = forwardRef<any, IOperationRowProps>((props, ref) => {
  const { listValue, parameter, options, onChangeParameter, onChangeValues, onDelete } = props;

  const [open, setOpen] = useState(false);

  const [inputValue, setInputValue] = useState(parameter.fullName);
  const debouncedValue = useDebounce<string>(inputValue, 400);

  useEffect(() => {
    parameterStore.allParameters = [];
    if (inputValue && open) parameterStore.getParametersAll(DataType.List, debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    if (open && debouncedValue) {
      parameterStore.allParameters = [];
      parameterStore.getParametersAll(DataType.List, debouncedValue);
    }
  }, [open]);

  const onChangeParameterValue = (e: SyntheticEvent, value: IAutocompleteParameter | null) => {
    if (!value) return;
    onChangeParameter(value);
  };

  return (
    <Stack flexDirection="row" justifyContent="space-between" gap={4} alignItems="center" ref={ref}>
      {/* TODO:mikhail.broch: Вынести компонент в UI, унифицировать логику взаимодействия */}
      <Box sx={{ width: '70%' }}>
        <Autocomplete
          fullWidth
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          noOptionsText={
            inputValue
              ? Translator.translate('common.parameterAutocomplete.notFoundParameter')
              : Translator.translate('common.parameterAutocomplete.clue')
          }
          options={parameterStore.parametersForAutocomplete}
          loading={parameterStore.loadingParameters}
          value={parameter as IAutocompleteParameter}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.fullName}
          onChange={onChangeParameterValue}
          inputValue={inputValue}
          onInputChange={(e, inputValue) => {
            setInputValue(inputValue);
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.fullName}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              multiline
              maxRows={3}
              label={Translator.translate('common.parameterAutocomplete.parameterLabel')}
              placeholder={Translator.translate('common.parameterAutocomplete.parameterPlaceholder')}
              required
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
      </Box>

      <Select
        sx={{ width: '30%' }}
        label={Translator.translate('common.operationRows.operationRow.valueLabel')}
        required
        hideNotSelectItem
        value={listValue}
        options={options}
        onChange={onChangeValues}
      />

      <Box
        sx={(theme) => ({
          mr: 2,
          svg: {
            ':hover': {
              fill: theme.palette.primary.main,
              fillOpacity: 1,
            },
          },
        })}>
        <IconButtonNew tooltip={Translator.translate('actions.delete')} onClick={onDelete}>
          <TrashIcon />
        </IconButtonNew>
      </Box>
    </Stack>
  );
});

OperationRow.displayName = 'OperationRow';

export default observer(OperationRow);
