import React, { ReactElement, useState } from 'react';
import { IconButtonNew } from 'components';
import MoreVerticalIcon from 'components/Icons/MoreVerticalIcon';
import { Box, Menu, PopoverOrigin, Stack, SxProps } from '@mui/material';
import { PopupPosition } from 'shared/enums';

interface IPopupMenuProps {
  button?: JSX.Element;
  iconButton?: JSX.Element;
  menuItems: ReactElement[] | null;
  colorIcon?: string | undefined;
  isCloseOnSelect?: boolean;
  isFlexDirectionRow?: boolean;
  position?: PopupPosition;
  disabled?: boolean;
  sx?: SxProps;
}

interface IPopupPositions {
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
}

const getPositionPopup = (position: PopupPosition): IPopupPositions => {
  switch (position) {
    case PopupPosition.Bottom:
      return {
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      };

    case PopupPosition.Right:
      return {
        anchorOrigin: { vertical: 'center', horizontal: 'right' },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'left',
        },
      };
  }
};

const PopupMenu: React.FC<IPopupMenuProps> = (props) => {
  const { menuItems, button, colorIcon, isCloseOnSelect, isFlexDirectionRow, position = PopupPosition.Right, disabled, sx } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!disabled) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const closeOnClick = () => {
    isCloseOnSelect && setAnchorEl(null);
  };

  const btn = button ?? (
    <IconButtonNew>
      <MoreVerticalIcon color={colorIcon} />
    </IconButtonNew>
  );

  return (
    <>
      <Box
        sx={sx ? sx : { cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        id="popup-button"
        aria-controls={Boolean(anchorEl) ? 'popup-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
        onClick={handleClick}>
        {btn}
      </Box>
      <Menu
        PaperProps={{
          sx: {
            borderRadius: '6px',
            padding: '0 4px',
          },
        }}
        elevation={8}
        id="popup-menu"
        anchorEl={anchorEl}
        anchorOrigin={getPositionPopup(position).anchorOrigin}
        transformOrigin={getPositionPopup(position).transformOrigin}
        open={open}
        onClose={handleClose}
        onClick={closeOnClick}>
        <Stack sx={{ display: 'flex', flexDirection: isFlexDirectionRow ? 'row' : 'column', gap: 1 }}>
          {menuItems?.map((element) => {
            return element;
          })}
        </Stack>
      </Menu>
    </>
  );
};

export default PopupMenu;
