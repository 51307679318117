import React, { useEffect, useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { Translator, definitionStore, projectStore, stageStore } from 'stores';
import { Select, Input, DialogWrapper, InputValidate } from 'components';
import { FormControlLabel, Checkbox, Stack, Box, CircularProgress } from '@mui/material';
import { ProjectType } from 'shared/enums';
import { IDefinitionForm } from 'shared/interfaces';

interface IDefinitionDialogProps {
  open: boolean;
  onClose: () => void;
}

const DefinitionDialog: React.FC<IDefinitionDialogProps> = (props) => {
  const { open, onClose } = props;

  const formRef = useRef<FormikProps<IDefinitionForm>>(null);

  useEffect(() => {
    stageStore.getStages().then(() => {
      if (stageStore.stages.length > 0 && !definitionStore.selectedDefinition.stageId)
        definitionStore.selectedDefinition.setStageId(stageStore.stages[0].id);
    });

    return () => {
      definitionStore.selectedDefinition.clear();
    };
  }, []);

  const onCloseDialog = () => {
    definitionStore.selectedDefinition.clear();
    projectStore.selectedProject.clear();
    onClose();
  };

  const onSubmit = async (values: IDefinitionForm) => {
    definitionStore.selectedDefinition.updateDefinitionDialogForm(values);

    if (!definitionStore.selectedDefinition.id) {
      definitionStore.selectedDefinition.sourceDefinitionId !== null
        ? await definitionStore.copyDefinition()
        : await definitionStore.createDefinition();
    } else {
      await definitionStore.updateDefinition();
    }

    onClose();
  };

  const dialogTitle = definitionStore.selectedDefinition.id
    ? Translator.translate('projects.definitionDialog.title.editDefinition')
    : Translator.translate('projects.definitionDialog.title.addDefinition');

  const definitionForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        name: definitionStore.selectedDefinition.name,
        version: definitionStore.selectedDefinition.version,
        stageId: definitionStore.selectedDefinition.stageId,
        sourceDefinitionId: definitionStore.selectedDefinition.sourceDefinitionId,
        isMain: definitionStore.selectedDefinition.isMain,

        stageOpt: stageStore.stageForOptions,
        sourceDefinitionOpt: definitionStore.definitionsForOptions,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required(Translator.translate('validationMessage.required')),
        version: Yup.string().max(255).required(Translator.translate('validationMessage.required')),
        stageId:
          definitionStore.selectedDefinition.projectType === ProjectType.Local
            ? Yup.number().required(Translator.translate('validationMessage.required'))
            : Yup.number().nullable(),
      })}>
      {({ handleChange, handleBlur, values, errors, touched, setFieldValue }) => {
        return (
          <>
            <Stack flexDirection="row" gap={2}>
              <InputValidate
                label={Translator.translate('projects.columns.name')}
                name="name"
                required
                error={Boolean(touched.name && errors.name)}
                helperText={(touched.name as any) && errors.name}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <Input
                type="number"
                required
                name="version"
                error={Boolean(touched.version && errors.version)}
                helperText={(touched.version as any) && errors.version}
                label={Translator.translate('projects.dialogFormFields.version')}
                inputProps={{
                  min: 0.1,
                }}
                value={values.version}
                onChange={(value: string) => setFieldValue('version', value)}
              />
            </Stack>

            {definitionStore.selectedDefinition.projectType === ProjectType.Local && (
              <Select
                name="stageId"
                required
                label={Translator.translate('projects.dialogFormFields.designStage')}
                error={Boolean(touched.stageId && errors.stageId)}
                helperText={(touched.stageId as any) && (errors.stageId as any)}
                hideNotSelectItem
                value={values.stageId}
                options={values.stageOpt}
                onChange={handleChange}
                onBlur={handleBlur}
                size="medium"
              />
            )}

            {!definitionStore.selectedDefinition.id && (
              <Select
                name="sourceDefinitionId"
                label={Translator.translate('projects.dialogFormFields.sourceDefinition')}
                value={values.sourceDefinitionId}
                options={values.sourceDefinitionOpt}
                onChange={handleChange}
                onBlur={handleBlur}
                size="medium"
              />
            )}

            <FormControlLabel
              name="isMain"
              sx={{ ml: '-9px' }}
              control={<Checkbox checked={values.isMain} onChange={handleChange} />}
              label={Translator.translate('projects.dialogFormFields.isMain')}
            />
          </>
        );
      }}
    </Formik>
  );

  return (
    <DialogWrapper
      title={dialogTitle}
      maxWidth="md"
      open={open}
      onClose={onCloseDialog}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}
      onCancel={onCloseDialog}>
      {stageStore.isStageLoading ? (
        <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        definitionForm
      )}
    </DialogWrapper>
  );
};

export default observer(DefinitionDialog);
