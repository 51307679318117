import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Translator, auditStore, classificationDictionaryValueStore, definitionStore, projectStore } from 'stores';
import { IconButtonNew, PopupMenu } from 'components';
import { useConfirm } from 'components/App/Confirm';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import ExpandIcon from 'components/Icons/ExpandIcon';
import FilterIcon from 'components/Icons/FilterIcon';
import HistoryIcon from 'components/Icons/HistoryIcon';
import PlusIcon from 'components/Icons/PlusIcon';
import SortingColumnAscIcon from 'components/Icons/SortingColumnAscIcon';
import SortingColumnDescIcon from 'components/Icons/SortingColumnDescIcon';
import TrashIcon from 'components/Icons/TrashIcon';
import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  AuditTables,
  ClassificationDictionaries,
  DefinitionApprovalStatus,
  DefinitionApprovalStatusNames,
  LangNames,
  ProjectState,
  ProjectStateNames,
  ProjectType,
} from 'shared/enums';
import { Routes } from 'shared/enums/Routes';
import { ISelectOption } from 'shared/interfaces';
import { DefinitionModel } from 'shared/models/DefinitionModel';
import { ProjectListModel } from 'shared/models/ProjectModel';
import Utils from 'shared/utils/Utils';
import s from './MainTable.module.scss';

export interface ITableColumn {
  key: string | number;
  label: string;
  width?: string | number;
  maxWidth?: string | number;
  minWidth?: string | number;
  visible?: boolean;
  filters?: ISelectOption[];
  sorting?: boolean;
  hasFilters?: boolean;
  align: 'left' | 'right' | 'center';
  cell?: (row: any) => JSX.Element | null;
}

const MainTable: React.FC = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [isShowIconSorting, setShowIconSorting] = useState('');
  const [filterData, setFilterData] = useState<ProjectListModel[]>(projectStore.filterProjects);

  useEffect(() => {
    let filteredData = projectStore.filterProjects;
    if (projectStore.currentSort.key !== '') {
      filteredData = sortData(
        projectStore.currentSort.ascending,
        projectStore.filterProjects,
        projectStore.currentSort.key as keyof ProjectListModel
      );
    }
    setFilterData(filteredData);
  }, [projectStore.filterProjects, projectStore.currentSort.key, projectStore.currentSort.ascending]);

  const renderRegions = (row: ProjectListModel & DefinitionModel): JSX.Element => {
    if (projectStore.projectFilters.type === ProjectType.Local && row.region.id !== null) {
      return <Typography variant="body2">{row.region.value}</Typography>;
    }

    if (projectStore.projectFilters.type === ProjectType.Standard && row.regions.length !== 0) {
      return (
        <>
          {row.regions.map((region, index) => {
            return (
              <Typography key={region.id} variant="body2" sx={{ wordBreak: 'break-word' }}>
                {index === row.regions.length - 1 ? region.value : `${region.value} `}
              </Typography>
            );
          })}
        </>
      );
    }

    return <></>;
  };

  const columns = (type: ProjectType): ITableColumn[] => {
    const columns: ITableColumn[] = [
      {
        key: 'name',
        label: Translator.translate('projects.columns.name'),
        minWidth: type === ProjectType.Local ? 240 : 320,
        width: type === ProjectType.Local ? 240 : 320,
        align: 'left',
        visible: true,
        sorting: true,
        cell: (row: ProjectListModel & DefinitionModel) => {
          // definition name
          if (!row.buildingType) {
            return (
              <Stack direction="row" alignItems="center">
                <Typography sx={{ minWidth: '20px' }} variant="body2">
                  {row.version}
                </Typography>

                <Typography ml={4} variant={row.isMain ? 'subtitle2' : 'body2'}>
                  {row.name}
                </Typography>
              </Stack>
            );
            // project name
          } else
            return (
              <Typography ml={3} variant="subtitle1">
                {row.name}
              </Typography>
            );
        },
      },
      {
        key: ClassificationDictionaries.BuildingType,
        label: Translator.translate('projects.columns.buildingType'),
        width: 185,
        minWidth: 185,
        align: 'center',
        filters: classificationDictionaryValueStore.buildingTypes,
        hasFilters: projectStore.projectFilters.classificationDictionaryChecked.some((id) =>
          classificationDictionaryValueStore.buildingTypes.map((_) => _.id).includes(id)
        ),
        visible: type === ProjectType.Local,
        cell: (row: ProjectListModel & DefinitionModel) => {
          return <>{row.version === undefined && row.buildingType.id !== null && <Box>{row.buildingType.value}</Box>}</>;
        },
      },
      /* {
        key: 'state',
        label: 'Статус',
        width: 130,
        align: 'center',
        visible: true,
        cell: (row: ProjectListModel & DefinitionModel) =>
          row.state ? (
            <Chip variant="outlined" size="small" color={paintProjState(row.state)} label={ProjectStateNames.get(row.state)} />
          ) : (
            <Chip variant="outlined" size="small" color={paintDefApprovalStatus(row.status)} label={DefinitionApprovalStatusNames.get(row.status)} />
          ),
      }, */
      {
        key: 'stageName',
        label: Translator.translate('projects.columns.stageName'),
        width: 140,
        align: 'center',
        visible: type === ProjectType.Local,
        cell: (row: ProjectListModel & DefinitionModel) => {
          return <Box>{row.stages?.map((el) => el.name).join(',')}</Box>;
        },
      },
      {
        key: ClassificationDictionaries.Region,
        label: Translator.translate('projects.columns.region'),
        width: 140,
        align: 'center',
        visible: true,
        filters: classificationDictionaryValueStore.regions,
        hasFilters: projectStore.projectFilters.classificationDictionaryChecked.some((id) =>
          classificationDictionaryValueStore.regions.map((_) => _.id).includes(id)
        ),
        cell: (row: ProjectListModel & DefinitionModel) => {
          return <>{row.version === undefined && renderRegions(row)}</>;
        },
      },
      {
        key: 'address',
        label: Translator.translate('projects.columns.address'),
        width: 135,
        maxWidth: 135,
        align: 'center',
        visible: type === ProjectType.Local,
      },
      {
        key: ClassificationDictionaries.BuildingClass,
        label: Translator.translate('projects.columns.buildingClass'),
        width: 140,
        align: 'center',
        visible: type === ProjectType.Local,
        filters: classificationDictionaryValueStore.buildingClasses,
        hasFilters: projectStore.projectFilters.classificationDictionaryChecked.some((id) =>
          classificationDictionaryValueStore.buildingClasses.map((_) => _.id).includes(id)
        ),
        cell: (row: ProjectListModel & DefinitionModel) => {
          if (row.version === undefined && row.buildingClass.length > 0)
            return (
              <>
                {row.buildingClass.map((el) => (
                  <Box key={el.id}>{el.value}</Box>
                ))}
              </>
            );
          else return null;
        },
      },
      {
        key: 'createdAt',
        label: Translator.translate('projects.columns.date'),
        minWidth: type === ProjectType.Local ? 150 : 200,
        width: type === ProjectType.Local ? 150 : 200,
        align: 'center',
        sorting: true,
        visible: true,
        cell: (row: ProjectListModel & DefinitionModel) =>
          row.createdAt ? (
            <div>
              {dayjs(row.createdAt).format(
                Translator.selectedLocalization === LangNames.Ru
                  ? Translator.selectedLocalization === LangNames.Ru
                    ? 'DD.MM.YYYY HH:mm'
                    : 'MM/DD/YYYY h:mm A'
                  : 'MM/DD/YYYY h:mm A'
              )}
            </div>
          ) : null,
      },
      {
        key: 'actions',
        label: '',
        width: 90,
        maxWidth: 90,
        minWidth: 90,
        align: 'right',
        visible: true,
        cell: (row: ProjectListModel & DefinitionModel) => {
          const isDefinitionRow = !row.buildingType;

          const onCreateDefinition = (e: any) => {
            e.stopPropagation();
            definitionStore.selectedDefinition.clear();
            if (row.buildingType) {
              projectStore.setEditingMode(row.id).then(() => {
                definitionStore.getDefinitionList(projectStore.selectedProject.id!);
                definitionStore.selectedDefinition.setProjectId(projectStore.selectedProject.id!);
                definitionStore.selectedDefinition.setProjectType(projectStore.selectedProject.type);
              });
            }

            definitionStore.setDialogOpen(true);
          };

          const onEditProject = (e: any) => {
            e.stopPropagation();
            projectStore.setEditingMode(row.id);
            projectStore.setOpenDialog(true);
          };

          const onEditDefinition = async (e: any) => {
            e.stopPropagation();
            row.id && (await definitionStore.setEditingMode(row.id));
            definitionStore.setDialogOpen(true);
          };

          const onDeleteProject = (e: any) => {
            e.stopPropagation();
            projectStore.setEditingMode(row.id);
            confirm
              .show(Translator.translate('projects.message.deleteProjectMessage'), Translator.translate('actions.delete'))
              .then(() => {
                projectStore.removeProject();
              })
              .catch(() => projectStore.selectedProject.clear());
          };

          const onDeleteDefinition = async (e: any) => {
            e.stopPropagation();
            row.id && (await definitionStore.setEditingMode(row.id));
            confirm
              .show(Translator.translate('projects.message.deleteHPFMessage'), Translator.translate('actions.delete'))
              .then(() => {
                definitionStore.removeDefinition();
              })
              .catch(() => projectStore.selectedProject.clear());
          };

          const onHistoryView = (e: any) => {
            e.stopPropagation();
            auditStore.setDrawerOpen(true);
            auditStore.setAuditFilters(AuditTables.Definitions, Number(row.id));
          };

          return (
            <>
              {row.isHover && (
                <Stack width="100%" justifyContent="center" flexDirection="row" alignItems="center">
                  {!isDefinitionRow && (
                    <IconButtonNew
                      sx={(theme) => ({
                        svg: {
                          ':hover': {
                            fill: theme.palette.primary.main,
                            fillOpacity: 1,
                          },
                        },
                      })}
                      tooltip={Translator.translate('projects.addHPFButtonTitle')}
                      onClick={onCreateDefinition}>
                      <PlusIcon />
                    </IconButtonNew>
                  )}

                  {isDefinitionRow && (
                    <IconButtonNew
                      sx={(theme) => ({
                        svg: {
                          ':hover': {
                            fill: theme.palette.primary.main,
                            fillOpacity: 1,
                          },
                        },
                      })}
                      tooltip={Translator.translate('actions.changeHistory')}
                      onClick={onHistoryView}>
                      <HistoryIcon />
                    </IconButtonNew>
                  )}

                  <IconButtonNew
                    sx={(theme) => ({
                      svg: {
                        ':hover': {
                          fill: theme.palette.primary.main,
                          fillOpacity: 1,
                        },
                      },
                    })}
                    tooltip={Translator.translate('actions.edit')}
                    onClick={isDefinitionRow ? onEditDefinition : onEditProject}>
                    <EditPencilIcon />
                  </IconButtonNew>

                  <Divider orientation="vertical" sx={{ height: '20px', borderColor: 'rgba(15, 11, 31, 0.08)' }} />

                  <IconButtonNew
                    sx={(theme) => ({
                      svg: {
                        ':hover': {
                          fill: theme.palette.primary.main,
                          fillOpacity: 1,
                        },
                      },
                    })}
                    tooltip={Translator.translate('actions.delete')}
                    onClick={isDefinitionRow ? onDeleteDefinition : onDeleteProject}>
                    <TrashIcon />
                  </IconButtonNew>
                </Stack>
              )}
            </>
          );
        },
      },
    ];

    if (type === ProjectType.Standard) return columns.filter((el) => el.visible);
    else return columns;
  };

  const paintProjState = (status: ProjectState) => {
    switch (status) {
      case ProjectState.Canceled:
        return 'error';

      case ProjectState.Proposed:
        return 'warning';

      case ProjectState.Approved:
        return 'success';

      case ProjectState.InProgress:
        return 'info';

      default:
        return 'secondary';
    }
  };

  const paintDefApprovalStatus = (status: DefinitionApprovalStatus) => {
    switch (status) {
      case DefinitionApprovalStatus.Formed:
        return 'warning';

      case DefinitionApprovalStatus.PartiallyApproved:
        return 'info';

      case DefinitionApprovalStatus.Approved:
        return 'success';

      case DefinitionApprovalStatus.InProgress:
        return 'primary';
    }
  };

  const sortData = (isAscending: boolean, list: ProjectListModel[], propertyName: keyof ProjectListModel): ProjectListModel[] => {
    if (propertyName === 'createdAt') {
      return isAscending
        ? list.slice().sort((a, b) => Utils.ascComp(new Date(a[propertyName]), new Date(b[propertyName])))
        : list.slice().sort((a, b) => Utils.descComp(new Date(a[propertyName]), new Date(b[propertyName])));
    }

    return isAscending
      ? list.slice().sort((a, b) => Utils.ascComp(a[propertyName]?.toString().toLocaleLowerCase(), b[propertyName]?.toString().toLocaleLowerCase()))
      : list.slice().sort((a, b) => Utils.descComp(a[propertyName]?.toString().toLocaleLowerCase(), b[propertyName]?.toString().toLocaleLowerCase()));
  };

  const renderHeader = (columns: ITableColumn[]): JSX.Element => {
    return (
      <TableRow>
        {columns.map((column) => {
          const filterOptions = (): Array<JSX.Element> => {
            if (!column.filters) return [];

            return column.filters.map((el) => (
              <MenuItem sx={{ minWidth: '200px' }} key={el.id} component="li">
                <FormControlLabel
                  sx={{ ml: 0, mr: 4, width: '100%' }}
                  control={
                    <Checkbox
                      checked={projectStore.projectFilters.classificationDictionaryChecked.includes(el.id as number)}
                      onChange={() => {
                        projectStore.projectFilters.setClassificationDictionaryIds(el.id as number, column.key as ClassificationDictionaries);
                        projectStore.getProjectList();
                      }}
                    />
                  }
                  label={el.name}
                />
              </MenuItem>
            ));
          };

          const handleSort = () => {
            if (column.key !== projectStore.currentSort.key) {
              projectStore.setCurrentSort({ key: column.key, ascending: true });
            } else if (projectStore.currentSort.ascending) {
              projectStore.setCurrentSort({ key: column.key, ascending: !projectStore.currentSort.ascending });
            } else {
              projectStore.setCurrentSort({ key: '', ascending: true });
            }
          };

          const getClassName = (): string => {
            if (projectStore.currentSort.key === column.key) {
              return '';
            }

            if (isShowIconSorting === column.key) {
              return s.sortIconShow;
            }

            return s.sortIconHide;
          };

          const getIcon = (): JSX.Element => {
            if (projectStore.currentSort.key !== column.key || projectStore.currentSort.ascending) {
              return <SortingColumnAscIcon />;
            }

            return <SortingColumnDescIcon />;
          };

          return (
            <>
              <TableCell
                component="th"
                sx={{ py: 0, px: 1, height: 40 }}
                key={column.key}
                align={column.align}
                style={{
                  fontWeight: 500,
                  maxWidth: column.maxWidth,
                  minWidth: column.minWidth,
                  width: column.width,
                }}>
                <Stack
                  position="relative"
                  direction="row"
                  alignItems="center"
                  px="38px"
                  justifyContent={column.align === 'right' ? 'flex-end' : column.align === 'center' ? 'center' : 'space-between'}
                  onMouseEnter={() => setShowIconSorting(column.key as string)}
                  onMouseLeave={() => setShowIconSorting('')}>
                  {column.label}
                  {column.sorting && (
                    <Stack
                      position="absolute"
                      alignItems="center"
                      direction="row"
                      sx={{ height: '100%', top: 0, right: '8px' }}
                      className={getClassName()}>
                      <IconButtonNew onClick={handleSort}>{getIcon()}</IconButtonNew>
                    </Stack>
                  )}

                  {column.key !== 'actions' && (
                    <Stack
                      position="absolute"
                      alignItems="center"
                      direction="row"
                      sx={{
                        right: 0,
                        svg: {
                          fill: column.hasFilters ? '#7B68EE' : 'unset',
                        },
                      }}>
                      {filterOptions().length > 0 && (
                        <PopupMenu
                          key={column.key}
                          button={
                            <IconButtonNew>
                              <FilterIcon />
                            </IconButtonNew>
                          }
                          menuItems={filterOptions()}
                        />
                      )}

                      <Divider sx={{ ml: 2, borderRightWidth: 2, height: 14 }} orientation="vertical" />
                    </Stack>
                  )}
                </Stack>
              </TableCell>
            </>
          );
        })}
      </TableRow>
    );
  };

  const nestedRow = (row: any, columns: ITableColumn[], isCollapse?: boolean): JSX.Element => {
    return (
      <>
        {/*  ячейки строки проектов и ОПО */}
        {columns.map((column, idx) => {
          return (
            <TableCell
              key={column.key}
              variant="body"
              align={column.align}
              sx={{ py: 0, px: 1, height: 35 }}
              style={{
                maxWidth: column.maxWidth,
                minWidth: column.minWidth,
                width: column.width,
                borderBottom: !isCollapse ? 'none' : '1px solid rgba(224, 224, 224, 1)',
              }}>
              {/*  collapse на первой ячейке строки проекта */}
              {isCollapse && idx === 0 ? (
                <Stack direction="row">
                  <IconButtonNew
                    sx={{ p: 1, transform: `rotate(${row.isCollapseRow ? 0 : 270}deg)` }}
                    onClick={(e) => {
                      e.stopPropagation();
                      row.setCollapseRow(!row.isCollapseRow);
                    }}>
                    <ExpandIcon />
                  </IconButtonNew>

                  {column.cell ? column.cell(row) : row[column.key]}
                </Stack>
              ) : (
                <Box sx={{ pl: idx === 0 ? 9 : 0 }}>{column.cell ? column.cell(row) : row[column.key]}</Box>
              )}
            </TableCell>
          );
        })}
      </>
    );
  };

  const renderTableBody = (): JSX.Element => {
    // empty data
    if (projectStore.projectsByType.length === 0) {
      return (
        <TableCell sx={{ borderBottom: 'none' }} colSpan={columns(projectStore.projectFilters.type!).length}>
          <Stack alignItems="center" mt={8}>
            <img style={{ alignSelf: 'center' }} src={`${process.env.PUBLIC_URL}/img/empty_data.svg`} alt="" />
            <Typography variant="subtitle1" color="text.disabled">
              {Translator.translate('system.emptyData')}
            </Typography>
          </Stack>
        </TableCell>
      );
      // data table
    } else if (filterData.length > 0) {
      return (
        <>
          {filterData.map((proj) => {
            const onProjectRowClick = async () => {
              proj.setCollapseRow(!proj.isCollapseRow);
            };

            return (
              <>
                {/*  строки Проекты */}
                <TableRow
                  onClick={onProjectRowClick}
                  key={proj.id}
                  sx={{
                    '&.Mui-selected': {
                      bgcolor: 'rgba(242, 242, 249, 0.5)',
                    },
                    ':hover': {
                      bgcolor: 'rgba(102, 50, 253, 0.08)',
                    },
                  }}
                  hover
                  onMouseEnter={() => proj.setHover(true)}
                  onMouseLeave={() => proj.setHover(false)}
                  selected={Number(projectStore.currentProjectId) === proj.id}>
                  {nestedRow(proj, columns(projectStore.projectFilters.type!), true)}
                </TableRow>

                <TableRow>
                  <TableCell style={{ padding: 0, border: 'none', fontWeight: 500 }} colSpan={columns(projectStore.projectFilters.type!).length}>
                    <Collapse in={proj.isCollapseRow} timeout="auto" unmountOnExit>
                      <Table size="small" sx={{ borderCollapse: 'unset' }}>
                        <TableBody>
                          {/*  строки ОПО */}
                          {proj.definitions.map((def) => {
                            const onDefinitionRowClick = () => {
                              definitionStore.getCurrentDefinition(def.id!).then((data) => {
                                if (data) {
                                  definitionStore.addRecentlyDefinition();
                                  navigate(`${definitionStore.currentDefinition.id}${Routes.OBJECT_VALUE}`);
                                } else {
                                  projectStore.getProjectList();
                                  definitionStore.getRecentlyDefinitions();
                                }
                              });
                            };
                            return (
                              <TableRow
                                key={def.id}
                                onClick={onDefinitionRowClick}
                                sx={{
                                  cursor: 'pointer',
                                  '&.Mui-selected': {
                                    bgcolor: 'rgba(102, 50, 253, 0.08)',
                                  },
                                }}
                                hover
                                onMouseEnter={() => def.setHover(true)}
                                onMouseLeave={() => def.setHover(false)}
                                selected={Number(definitionStore.currentDefinition.id) === def.id}>
                                {nestedRow(def, columns(projectStore.projectFilters.type!))}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            );
          })}
        </>
      );
      // not found data
    } else {
      return (
        <TableCell sx={{ borderBottom: 'none' }} colSpan={columns(projectStore.projectFilters.type!).length}>
          <Stack alignItems="center" mt={4}>
            <img style={{ alignSelf: 'center' }} src={`${process.env.PUBLIC_URL}/img/not_found_data.svg`} alt="" />
            <Typography variant="subtitle1" color="text.disabled">
              {Translator.translate('system.notFoundData')}
            </Typography>
          </Stack>
        </TableCell>
      );
    }
  };

  return (
    <TableContainer sx={{ height: definitionStore.recentlyDefinitions.length > 0 ? 'calc(100vh - 370px)' : '100%', overflow: 'auto' }}>
      <Table size="small" stickyHeader>
        <TableHead
          sx={(theme) => ({
            '.MuiTableCell-head': {
              backgroundColor: theme.palette.common.white,
            },
          })}>
          {renderHeader(columns(projectStore.projectFilters.type!))}
        </TableHead>

        <TableBody>{renderTableBody()}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default observer(MainTable);
