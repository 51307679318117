import React from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, approvalSuggestionRowStore, partialApprovalStore } from 'stores';
import { IconButtonNew } from 'components';
import CheckIcon from 'components/Icons/CheckIcon';
import CloseDialogIcon from 'components/Icons/CloseDialogIcon';
import { Stack, Button, Box } from '@mui/material';
import { MIN_NAVIGATION__APPROVAL } from 'shared/constants/constants';
import { PartialApprovalIteration } from 'shared/enums';

const NavigationAction = () => {
  return approvalSuggestionRowStore.navigationWidth < MIN_NAVIGATION__APPROVAL ? (
    <Stack flexDirection="row" gap={2} alignItems="center">
      <IconButtonNew
        sx={(theme) => ({
          svg: {
            fill: theme.palette.success.main,
          },
        })}
        disabled={approvalSuggestionRowStore.allAcceptedTreeIds.length === 0}
        tooltip={
          partialApprovalStore.selectedPartialApproval.iteration === PartialApprovalIteration.Approval
            ? Translator.translate('actions.approve')
            : Translator.translate('actions.acceptAll')
        }
        onClick={() => approvalSuggestionRowStore.rowSuggestionSetAllAccepted(true)}
        disableRipple>
        <CheckIcon />
      </IconButtonNew>

      <IconButtonNew
        sx={(theme) => ({
          svg: {
            fill: theme.palette.error.main,
            width: 16,
            height: 16,
          },
        })}
        disabled={approvalSuggestionRowStore.allAcceptedTreeIds.length === 0}
        tooltip={Translator.translate('actions.rejectAll')}
        onClick={() => approvalSuggestionRowStore.rowSuggestionSetAllAccepted(false)}
        disableRipple>
        <CloseDialogIcon />
      </IconButtonNew>
    </Stack>
  ) : (
    <Stack flexDirection="row">
      <Button
        variant="text"
        size="small"
        disabled={approvalSuggestionRowStore.allAcceptedTreeIds.length === 0}
        sx={(theme) => ({
          color: theme.palette.success.main,
        })}
        startIcon={
          <Box
            sx={(theme) => ({
              svg: {
                fill: approvalSuggestionRowStore.allAcceptedTreeIds.length === 0 ? theme.palette.text.disabled : theme.palette.success.main,
              },
            })}>
            <CheckIcon />
          </Box>
        }
        onClick={() => approvalSuggestionRowStore.rowSuggestionSetAllAccepted(true)}>
        {partialApprovalStore.selectedPartialApproval.iteration === PartialApprovalIteration.Approval
          ? Translator.translate('actions.approve')
          : Translator.translate('actions.acceptAll')}
      </Button>

      <Button
        variant="text"
        size="small"
        disabled={approvalSuggestionRowStore.allAcceptedTreeIds.length === 0}
        sx={(theme) => ({
          color: theme.palette.error.main,
        })}
        startIcon={
          <Box
            sx={(theme) => ({
              svg: {
                fill: approvalSuggestionRowStore.allAcceptedTreeIds.length === 0 ? theme.palette.text.disabled : theme.palette.error.main,
                width: 16,
                height: 16,
              },
            })}>
            <CloseDialogIcon />
          </Box>
        }
        onClick={() => approvalSuggestionRowStore.rowSuggestionSetAllAccepted(false)}>
        {Translator.translate('actions.rejectAll')}
      </Button>
    </Stack>
  );
};

export default observer(NavigationAction);
