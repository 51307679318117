import React, { useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { Translator, classStore, sectionsStore } from 'stores';
import { ClassFilter, DialogWrapper, InputValidate, RadioGroup } from 'components';
import { EditClassSectionDialog } from 'components/App/ClassFilter/Components';
import { CircularProgress, Box, Typography, FormControlLabel, Checkbox, Stack } from '@mui/material';
import { ObjectLayer, SectionStructure, SectionStructureNames } from 'shared/enums';
import { IClasses, IRadioGroupItem, ISectionForm } from 'shared/interfaces';

const SectionDialog: React.FC = () => {
  const { selectedSection, isDialogOpen } = sectionsStore;

  const formRef = useRef<FormikProps<ISectionForm>>(null);
  const classRef = useRef<null | HTMLDivElement>(null);

  const dialogTitle = selectedSection.id
    ? Translator.translate('catalogs.specTemplates.sections.sectionDialog.editSectionTitle')
    : selectedSection.parentId
    ? Translator.translate('catalogs.specTemplates.sections.sectionDialog.addSubsection')
    : Translator.translate('catalogs.specTemplates.sections.sectionDialog.addSection');

  const structureSection: IRadioGroupItem[] = [
    {
      id: SectionStructure.ItemsGroups,
      name: Translator.translate(SectionStructureNames.get(SectionStructure.ItemsGroups)),
    },
    {
      id: SectionStructure.GroupsItems,
      name: Translator.translate(SectionStructureNames.get(SectionStructure.GroupsItems)),
    },
  ];

  const onSubmit = async (values: ISectionForm) => {
    selectedSection.updateSectionDialogForm(values);

    if (!selectedSection.id) {
      await sectionsStore.createSection();
      onClose();
    } else {
      sectionsStore.updateSection().then(() => onClose());
    }
  };

  const onClose = () => {
    sectionsStore.setDialogOpen(false);
    selectedSection.clear();
    classStore.treeObject.clearModel();
    classStore.setSelectedLayer(ObjectLayer.Main);
  };

  const clear = () => {
    sectionsStore.setEditObjectDialogOpen(false);
    classStore.treeObject.clearModel();
    classStore.setSelectedLayer(ObjectLayer.Main);
  };

  const onSubmitEditClass = () => {
    selectedSection.defaultClassConstraints = [...selectedSection.classConstraints];
    clear();
  };

  const onCloseEditClass = () => {
    selectedSection.classConstraints = [...selectedSection.defaultClassConstraints];
    clear();
  };

  const onCheckClass = (checkedKeysValue: any, info: any) => {
    const keys = info.node.key.split('-');
    const secondParentClass = keys.length >= 3 ? classStore.allClass.find((f) => f.id === Number(keys[keys.length - 3])) : undefined;

    selectedSection.setClassConstraints({
      className: info.node.name,
      parentName: info.node.parentName,
      secondParentName: secondParentClass && secondParentClass.name ? secondParentClass.name : '',
      classKey: info.node.key,
    });

    const filterSectionClass = classStore.allClass.filter(
      (f) =>
        selectedSection.classConstraints.find((classConstraint) => f.key === classConstraint.classKey) &&
        f.layerId === classStore.classFilters.layerId
    );

    classStore.treeObject.setCheckedKeys(filterSectionClass.map((cl) => cl.key));
    classStore.treeObject.checkedNodesInfo = filterSectionClass;
  };

  const onDeleteClass = (e: React.MouseEvent, data: IClasses) => {
    e.preventDefault();

    selectedSection.setClassConstraints({
      className: data.className,
      parentName: data.parentName,
      secondParentName: data.secondParentName,
      classKey: data.classKey,
    });

    const filterSectionClass = classStore.allClass.filter(
      (f) =>
        selectedSection.classConstraints.find((classConstraint) => f.key === classConstraint.classKey) &&
        f.layerId === classStore.classFilters.layerId
    );

    classStore.treeObject.setCheckedKeys(filterSectionClass.map((cl) => cl.key));
    classStore.treeObject.checkedNodesInfo = filterSectionClass;
  };

  const sectionForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        name: selectedSection.name,
        shortName: selectedSection.shortName,
        structure: selectedSection.structure,
        isNewLineParameters: selectedSection.isNewLineParameters,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required(Translator.translate('validationMessage.required')),
        shortName: Yup.string().max(255).required(Translator.translate('validationMessage.required')),
      })}>
      {({ handleChange, handleBlur, values, errors, touched, setFieldValue }) => {
        return (
          <>
            <InputValidate
              label={Translator.translate('catalogs.specTemplates.sections.sectionDialog.dialogFormFields.name')}
              name="name"
              required
              error={Boolean(touched.name && errors.name)}
              helperText={(touched.name as any) && errors.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <InputValidate
              label={Translator.translate('catalogs.specTemplates.sections.sectionDialog.dialogFormFields.shortName')}
              name="shortName"
              required
              error={Boolean(touched.shortName && errors.shortName)}
              helperText={(touched.shortName as any) && errors.shortName}
              value={values.shortName}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {selectedSection.id && (
              <Box ref={classRef} sx={{ height: 'auto', overflow: 'hidden' }}>
                <ClassFilter
                  classHeight={classRef.current?.offsetHeight}
                  defaultClassConstraints={selectedSection.defaultClassConstraints}
                  onEditClass={() => {
                    sectionsStore.setEditObjectDialogOpen(true);
                  }}
                />
              </Box>
            )}

            <Stack>
              <Typography variant="caption">
                {Translator.translate('catalogs.specTemplates.sections.sectionDialog.dialogFormFields.structure')}
              </Typography>
              <RadioGroup
                name="structure"
                radioGroupItems={structureSection}
                selectedRadioGroup={values.structure}
                row
                onChangeRadioGroup={(e) => setFieldValue('structure', e.target.value)}
              />
            </Stack>

            <FormControlLabel
              name="isNewLineParameters"
              label={Translator.translate('catalogs.specTemplates.sections.sectionDialog.dialogFormFields.isNewLineParameters')}
              control={
                <Checkbox
                  checked={values.isNewLineParameters}
                  disableRipple
                  onChange={(e, checked) => {
                    setFieldValue('isNewLineParameters', checked);
                  }}
                />
              }
            />
          </>
        );
      }}
    </Formik>
  );

  return (
    <>
      <DialogWrapper
        title={dialogTitle}
        maxWidth="md"
        open={isDialogOpen}
        onClose={onClose}
        onSubmit={() => {
          if (formRef.current) formRef.current.handleSubmit();
        }}
        onCancel={onClose}>
        {sectionsStore.isDialogLoading ? (
          <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          sectionForm
        )}
      </DialogWrapper>

      {sectionsStore.isEditObjectDialogOpen && (
        <EditClassSectionDialog
          isDialogOpen={sectionsStore.isEditObjectDialogOpen}
          classConstraints={selectedSection.classConstraints}
          isEditXMLNode={false}
          onSubmit={onSubmitEditClass}
          onClose={onCloseEditClass}
          onCheckClass={onCheckClass}
          onDeleteClass={onDeleteClass}
        />
      )}
    </>
  );
};

export default observer(SectionDialog);
