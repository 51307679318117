import React from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, approvalStore } from 'stores';
import { DialogWrapper } from 'components';
import { ApprovalAction } from 'shared/enums';

const ApprovedDialog: React.FC = () => {
  const onSubmit = () => {
    approvalStore.updateApprovalStatus(ApprovalAction.Approve);
  };

  const onClose = () => {
    approvalStore.setOpenApprovalDialog(false);
  };

  return (
    <DialogWrapper
      title={Translator.translate('approvals.detailApprovalPage.approvedDialog.title')}
      maxWidth="md"
      open={approvalStore.isApprovalDialogOpen}
      titleSubmit={Translator.translate('actions.approve')}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={onSubmit}>
      <>
        {Translator.translate('approvals.detailApprovalPage.approvedDialog.content.firstPart')}
        <br /> {Translator.translate('approvals.detailApprovalPage.approvedDialog.content.secondPart')}
      </>
    </DialogWrapper>
  );
};

export default observer(ApprovedDialog);
