import React from 'react';

const IconSearch: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16C10.2105 16 11.7873 15.4274 13.0491 14.4633L17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L14.4633 13.0491C15.4274 11.7873 16 10.2105 16 8.5C16 4.35786 12.6421 1 8.5 1ZM3 8.5C3 5.46243 5.46243 3 8.5 3C11.5376 3 14 5.46243 14 8.5C14 11.5376 11.5376 14 8.5 14C5.46243 14 3 11.5376 3 8.5Z"
        fill="#0F0B1F"
        fillOpacity="0.54"
      />
    </svg>
  );
};

export default IconSearch;
