import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, classStore } from 'stores';
import { RadioGroup, Confirm } from 'components';
import { DeleteClassMode } from 'shared/enums';

import s from './DeleteClassDialog.module.scss';

interface DeleteProps {
  onSubmit: (radio: DeleteClassMode) => void;
  onClose: () => void;
}

export const DeleteClassDialog = (props: DeleteProps) => {
  const { onSubmit, onClose } = props;

  const [radio, setRadio] = useState(DeleteClassMode.DeleteChecked);

  //Получение родительских имен для диалога на удаление
  const getParentName = (): string[] => {
    const array: string[] = [];

    classStore.treeObject.checkedNodesInfo.forEach((checkedKeyInfo) => {
      if (checkedKeyInfo.parentName) array.push(checkedKeyInfo.parentName);
    });

    return array;
  };

  const optionsRadio = [
    {
      id: DeleteClassMode.DeleteChecked,
      name:
        classStore.treeObject.checkedKeys.length === 1
          ? `${Translator.translate('classes.classDialog.deleteOptions.deleteFromParent')}: ${getParentName()}`
          : `${Translator.translate('classes.classDialog.deleteOptions.deleteFromParents')}: ${getParentName()!.join(', ')}`,
    },
    {
      id: DeleteClassMode.DeleteAll,
      name: Translator.translate('classes.classDialog.deleteOptions.deleteFromAllParents'),
    },
  ];

  const onChangeRadioGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadio(Number(event.target.value));
  };

  return (
    <Confirm
      content={
        classStore.isMoreParentsInCheckedNode() ? (
          <>
            <div>
              {classStore.treeObject.checkedKeys.length === 1
                ? Translator.translate('classes.classDialog.confirmMessage.classHasParents')
                : Translator.translate('classes.classDialog.confirmMessage.classesHasParents')}
            </div>

            <RadioGroup radioGroupItems={optionsRadio} selectedRadioGroup={radio} row={true} onChangeRadioGroup={onChangeRadioGroup} />
          </>
        ) : (
          <p>
            {classStore.treeObject.checkedKeys.length === 1
              ? Translator.translate('classes.classDialog.confirmMessage.deleteClass')
              : Translator.translate('classes.classDialog.confirmMessage.deleteAllClasses')}
          </p>
        )
      }
      okText={Translator.translate('actions.delete')}
      visible={classStore.isDialogDeleteOpen}
      onOk={() => onSubmit(classStore.isMoreParentsInCheckedNode() ? radio : DeleteClassMode.DeleteAll)}
      onCancel={onClose}
      isSuccess={false}
    />
  );
};

export default observer(DeleteClassDialog);
