import { makeAutoObservable } from 'mobx';
import { IConditionListDto, IIdFullNameDto, IIdNameDto, ISelectOption } from 'shared/interfaces';

class ConditionListModel {
  constructor(dto?: IConditionListDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.description = dto.description;
    this.parameterListValueIf = dto.parameterListValueIf;
    this.parameterIf = dto.parameterIf;
    this.parameterListValueThen = dto.parameterListValueThen;
    this.measurementUnitIf = dto.measurementUnitIf;
    this.measurementUnitThen = dto.measurementUnitThen;
    this.parameterThen = { id: dto.parameterThen.id, fullName: dto.parameterThen.name };
  }

  public id: number | null = null;

  public description = '';

  public parameterListValueIf = <IIdNameDto>{ id: null, name: '' };

  public parameterIf = <IIdNameDto>{ id: null, name: '' };

  public parameterListValueThen = <IIdNameDto>{ id: null, name: '' };

  public measurementUnitIf = <IIdNameDto>{ id: null, name: '' };

  public measurementUnitThen = <IIdNameDto>{ id: null, name: '' };

  public parameterThen = <IIdFullNameDto>{ id: null, fullName: '' };

  public selectValueOptions: ISelectOption[] = [];

  public setId(id: number) {
    this.id = id;
  }

  public setSelectValueOptions(arr: ISelectOption[]) {
    this.selectValueOptions = arr;
  }

  public setParameterThen(data: IIdFullNameDto) {
    this.parameterThen = data;
  }

  public setParameterListValueThen(id: number) {
    this.parameterListValueThen.id = id;
  }
}

export default ConditionListModel;
