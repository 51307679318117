import { BASE_PATH } from 'shared/constants/constants';
import {
  IDeleteParameterListValue,
  IParameterListValueDto,
  IParameterListValuePostDto,
  IParameterListValuePutDto,
  IRecommendParamListValueDto,
  IRecommendParamDto,
} from 'shared/interfaces';
import HTTPService from './HttpService';

class ParameterListValueService extends HTTPService {
  constructor() {
    super(BASE_PATH.PARAMETER_LIST_VALUE);
  }

  public getAllParameterListValue(parameterId: number): Promise<IParameterListValueDto[]> {
    const params = {
      parameterId: parameterId,
    };
    return this.GET('', { params });
  }

  public getRecommendParamListValue(params: IRecommendParamDto): Promise<IRecommendParamListValueDto[]> {
    return this.GET('recommendations', { params });
  }

  public postParameterListValue(body: IParameterListValuePostDto) {
    return this.POST('', body);
  }

  public putParameterListValue(id: number, body: IParameterListValuePutDto) {
    return this.PUT(`${id}`, body);
  }

  public deleteParameterListValue(body: IDeleteParameterListValue) {
    return this.DELETE('', body);
  }
}

export default new ParameterListValueService();
