import React, { MouseEvent, ReactElement, useMemo } from 'react';
import { SpecificationsService } from 'services';
import { Translator, toastStore } from 'stores';
import { IconButtonNew, PopupMenu } from 'components';
import ArchiveIcon from 'components/Icons/ArchiveIcon';
import DocumentExistIcon from 'components/Icons/DocumentExistIcon';
import DownloadIcon from 'components/Icons/DownloadIcon';
import ExpandIcon from 'components/Icons/ExpandIcon';
import { Button, MenuItem, Box, Theme } from '@mui/material';
import { PopupPosition } from 'shared/enums';
import Utils from 'shared/utils/Utils';

interface IDownloadSpecificationButtonProps {
  specificationId: number | null;
  name: string;
  isIconOnly?: boolean;
}

const DownloadSpecificationButton: React.FC<IDownloadSpecificationButtonProps> = (props) => {
  const { specificationId, name, isIconOnly } = props;

  const onDownloadFile = (e: MouseEvent) => {
    e.stopPropagation();
    if (!specificationId) return;

    SpecificationsService.downloadDocument(specificationId)
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp as any]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}.docx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e: any) => {
        Utils.parseBlobError(e, `${Translator.translate('stores.getListErrorMessage')} downloadDocument`).then(toastStore.showError);
      });
  };

  const onDownloadAttachment = (e: MouseEvent) => {
    e.stopPropagation();
    if (!specificationId) return;

    SpecificationsService.downloadDocumentApplication(specificationId)
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp as any]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}.zip`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e: any) => {
        Utils.parseBlobError(e, `${Translator.translate('stores.getListErrorMessage')} downloadDocument`).then(toastStore.showError);
      });
  };

  const downLoadButtons: ReactElement[] = useMemo(() => {
    return [
      <MenuItem sx={{ padding: '6px 12px', borderRadius: '6px', gap: 2 }} key={'download-file'} component="li" onClick={onDownloadFile}>
        <DocumentExistIcon />
        {Translator.translate('common.downloadSpecificationType.document')}
      </MenuItem>,
      <MenuItem sx={{ padding: '6px 12px', borderRadius: '6px', gap: 2 }} key={'download-attachment'} component="li" onClick={onDownloadAttachment}>
        <ArchiveIcon />
        {Translator.translate('common.downloadSpecificationType.archive')}
      </MenuItem>,
    ];
  }, [onDownloadFile, onDownloadAttachment]);

  const getSvgStyles = (theme: Theme, specificationId: number | null, mailColor: string, isIconOnly: boolean) => ({
    fill: specificationId === null ? theme.palette.action.disabled : mailColor,
    fillOpacity: 1,
    ...(isIconOnly && { width: '18px', height: '18px' }),
  });

  const getIconOnlyHoverStyles = (isIconOnly: boolean) =>
    isIconOnly
      ? {
          '&:hover': {
            backgroundColor: 'transparent', // убираем изменение фона
            boxShadow: 'none', // убираем тень
          },
        }
      : {};

  return (
    <PopupMenu
      position={PopupPosition.Bottom}
      button={
        <Box width={isIconOnly ? 'auto' : '134px'}>
          <Button
            fullWidth
            disabled={specificationId === null}
            variant="text"
            sx={(theme) => {
              const mailColor = isIconOnly ? theme.palette.action.active : theme.palette.primary.main;
              return {
                minWidth: '12px',
                ...(isIconOnly && { padding: '0' }),
                svg: getSvgStyles(theme, specificationId, mailColor, !!isIconOnly),
                ...getIconOnlyHoverStyles(!!isIconOnly),
              };
            }}
            startIcon={isIconOnly ? null : <DownloadIcon />}
            endIcon={isIconOnly ? null : <ExpandIcon />}>
            {isIconOnly ? (
              <IconButtonNew tooltip={Translator.translate('actions.download')}>
                <DownloadIcon />
              </IconButtonNew>
            ) : (
              Translator.translate('actions.download')
            )}
          </Button>
        </Box>
      }
      isCloseOnSelect
      disabled={specificationId === null}
      menuItems={downLoadButtons}
    />
  );
};

export default DownloadSpecificationButton;
