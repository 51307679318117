import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useParams } from 'react-router';
import { definitionStore, projectStore, appStore, usersStore } from 'stores';
import { PageTitle } from 'components';
import LogoIcon from 'components/Icons/LogoIcon';
import { AppBar, Box, Stack, ToggleButton, Toolbar } from '@mui/material';
import { Routes } from 'shared/enums/Routes';
import Utils from 'shared/utils/Utils';
import CurrentProject from './Components/CurrentProject';

import s from './Header.module.scss';

const Header: React.FC = () => {
  const location = useLocation();
  const { currentDefinitionId } = useParams();

  useEffect(() => {
    definitionStore.setCurrentDefinitionId(currentDefinitionId ? Number(currentDefinitionId) : null);

    if (currentDefinitionId && !definitionStore.currentDefinition.id) {
      definitionStore.getCurrentDefinition(Number(currentDefinitionId)).then(() => {
        projectStore.getProjectById(Number(currentDefinitionId));
      });
    }
  }, []);

  useEffect(() => {
    if (location.pathname === Routes.MAIN || location.pathname.includes(Routes.OBJECT_VALUE)) {
      usersStore.getMe().then(() => {
        projectStore.setAvailableProjects(usersStore.getUserData().projects.map((project) => project.id));
      });
    }
  }, [location.pathname]);

  const getPageTitle = (): JSX.Element | null => {
    if (!Utils.isShowBreadcrumbs(location.pathname) || location.pathname.includes(Routes.PARTIAL_APPROVALS)) {
      return (
        <Box sx={{ color: 'text.primary', pl: 2 }}>
          <PageTitle />
        </Box>
      );
    } else return null;
  };

  const getCurrentProject = (): JSX.Element | null => {
    if (Utils.isShowBreadcrumbs(location.pathname) && !location.pathname.includes(Routes.PARTIAL_APPROVALS)) {
      return <CurrentProject />;
    } else return null;
  };

  return (
    <AppBar elevation={0} className={s.header} position="sticky">
      <Toolbar className={s.toolbar}>
        <Stack direction="row" alignItems="center" gap={5}>
          <ToggleButton
            sx={(theme) => ({
              border: 'none',
              // selected states
              '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: theme.palette.grey[200],
              },
              ':hover': {
                borderRadius: '6px',
                backgroundColor: theme.palette.grey[200],
              },
              svg: {
                fill: appStore.isCollapseSideBar ? theme.palette.primary.dark : theme.palette.primary.main,
              },
            })}
            disableRipple
            value="check"
            selected={appStore.isCollapseSideBar}
            onChange={() => {
              appStore.setCollapseSideBar(!appStore.isCollapseSideBar);
            }}>
            <LogoIcon />
          </ToggleButton>

          {getPageTitle()}

          {getCurrentProject()}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default observer(Header);
