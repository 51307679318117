import { GridRowsProp } from '@mui/x-data-grid-pro';
import { makeAutoObservable, runInAction } from 'mobx';
import { arrayToTree } from 'performant-array-to-tree';
import xml2js from 'xml-js';
import { ParameterGroupService, XmlDocumentInstanceService, XmlSchemaTemplateService } from 'services';
import { Translator, classStore, definitionStore, toastStore } from 'stores';

import { SelectorType, SelectorTypeNames, XMLDocumentType } from 'shared/enums';
import { IGetXmlDocumentDto, IGetXmlDocumentNodeDto, ISelectOption, IXMLParameter, IXmlSchemaTemplateElement } from 'shared/interfaces';
import { FilterXMLDocumentModel, ParameterGroupModel, XMLParamsModel, XmlDocumentModel, XmlDocumentNodeModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

export interface IXmlSchema {
  getXmlSchemaNodes(): IXmlSchemaTemplateElement[];
  updateExpandedTreeNodeIds(id: number): void;
}

class XmlDocumentInstanceStore implements IXmlSchema {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public isDialogOpen = false;

  public isDialogLoading = false;

  public isDialogXMLNodeOpen = false;

  public isEditClassDialogOpen = false;

  public isEditParamDialogOpen = false;

  public filterXmlDocuments = new FilterXMLDocumentModel();

  public xmlDocuments: IGetXmlDocumentDto[] = [];

  public selectedXMLDocument = new XmlDocumentModel();

  public xmlDocumentTree = new XmlDocumentNodeModel(this);

  public selectedXMLNode = new XmlDocumentNodeModel(this);

  public filterName = '';

  public paramGroupFilter = new ParameterGroupModel();

  public xmlParams: XMLParamsModel[] = [];

  public expandedTreeNodeIds = new Set<number>();

  public listXmlDocumentDto: IGetXmlDocumentNodeDto[] = [];

  public xmlSchemaNodes: IXmlSchemaTemplateElement[] = [];

  public documentForDefInstance: ISelectOption[] = [];

  public get expandedNodeIds(): number[] {
    return Array.from(this.expandedTreeNodeIds.values());
  }

  public get documentForOptions(): ISelectOption[] {
    return this.xmlDocuments
      .filter((f) => f.definition && f.definition.id === definitionStore.currentDefinitionId)
      .map((item) => {
        return { id: item.id, name: item.name };
      });
  }

  public get selectorTypeForOptions(): ISelectOption[] {
    return Object.values(SelectorType).map((item) => {
      return {
        id: item,
        name: Translator.translate(SelectorTypeNames.get(item)),
      };
    });
  }

  public get xmlParamsFilteredDataGrid(): GridRowsProp {
    const groupIds = this.xmlParams
      .filter((parameter) => parameter.fullName.toLowerCase().includes(this.filterName.toLowerCase()))
      .map((data) => data.groupId!);

    const uniqueGroupIds = new Set(groupIds);

    return this.xmlParams.filter(
      (parameter) => parameter.fullName.toLowerCase().includes(this.filterName.toLowerCase()) || uniqueGroupIds.has(parameter.id!)
    );
  }

  public async initFilterState() {
    this.filterXmlDocuments.setType(XMLDocumentType.DefinitionInstance);
    await this.getXmlDocuments();
  }

  public setEditClassDialogOpen(isOpen: boolean) {
    this.isEditClassDialogOpen = isOpen;
  }

  public setEditParamDialogOpen(isOpen: boolean) {
    this.isEditParamDialogOpen = isOpen;
  }

  public setDialogOpen(isOpen: boolean) {
    this.isDialogOpen = isOpen;
  }

  public setDialogXMLNodeOpen(isDialogXMLNodeOpen: boolean) {
    this.isDialogXMLNodeOpen = isDialogXMLNodeOpen;
  }

  public setFilterName(filterName: string) {
    this.filterName = filterName;
  }

  public async onEditXMLNode(node: any) {
    this.setDialogXMLNodeOpen(true);
    this.isDialogLoading = true;

    this.selectedXMLNode = node.data;

    classStore.setSelectedLayer();
    await classStore.getClasses();

    this.isDialogLoading = false;
  }

  public updateExpandedTreeNodeIds(id: number) {
    if (this.expandedTreeNodeIds.has(id)) {
      this.expandedTreeNodeIds.delete(id);
    } else {
      this.expandedTreeNodeIds.add(id);
    }
  }

  public createXmlDocumentTree(dto: IGetXmlDocumentNodeDto[], isDefaultNode: boolean): XmlDocumentNodeModel[] {
    const xmlDocumentsDto: IGetXmlDocumentNodeDto[] = [];

    dto.forEach((value: IGetXmlDocumentNodeDto) => {
      if (value.attributes.length > 0 && !isDefaultNode) {
        value.attributes.forEach((attribute) => {
          xmlDocumentsDto.push({ ...attribute, parentId: value.id, isAttribute: true } as IGetXmlDocumentNodeDto);
        });
      }

      xmlDocumentsDto.push({ ...value, isAttribute: false } as IGetXmlDocumentNodeDto);
    });

    if (!isDefaultNode) {
      dto.forEach((value: IGetXmlDocumentNodeDto) => {
        const newNode = Utils.findDefaultXMLSchemaNodes(this.xmlSchemaNodes, value.type, value.name);
        if (newNode.attributes.length > 0) {
          newNode.attributes.forEach((newAttribute) => {
            if (!xmlDocumentsDto.find((f) => f.name === newAttribute.name && f.type === newAttribute.type)) {
              xmlDocumentsDto.push({ ...newAttribute, parentId: value.id, isAttribute: true } as IGetXmlDocumentNodeDto);
            }
          });
        }

        if (newNode.nextNewNodes && newNode.nextNewNodes.length > 0) {
          newNode.nextNewNodes.forEach((newNode) => {
            if (!xmlDocumentsDto.find((f) => f.name === newNode.name && f.type === newNode.type)) {
              xmlDocumentsDto.push({ ...newNode, parentId: value.id, isAttribute: false } as IGetXmlDocumentNodeDto);
            }
          });
        }
      });
    }

    this.listXmlDocumentDto = xmlDocumentsDto;
    return arrayToTree(xmlDocumentsDto, { dataField: null }).map((item) => new XmlDocumentNodeModel(this, item));
  }

  public getXmlSchemaNodes(): IXmlSchemaTemplateElement[] {
    return this.xmlSchemaNodes;
  }

  public async getXmlDocuments(): Promise<void> {
    try {
      this.isLoading = true;

      const result = await XmlDocumentInstanceService.getAllXmlDocument(this.filterXmlDocuments.paramsDto);
      if (!result) return;

      runInAction(() => {
        this.xmlDocuments = result;
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} XmlDocuments`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getDocumentsForOptions(): Promise<void> {
    try {
      this.isLoading = true;

      const result = await XmlDocumentInstanceService.getAllXmlDocument({ type: XMLDocumentType.Template });
      if (!result) return;

      runInAction(() => {
        this.documentForDefInstance = result
          .filter((f) => !f.definition)
          .map((item) => {
            return { id: item.id, name: item.name };
          });
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} XmlDocuments`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getXmlDocument(xmlDocumentId: number): Promise<void> {
    try {
      this.isLoading = true;

      const result = await XmlDocumentInstanceService.getXmlDocumentById(xmlDocumentId);
      if (result.length === 0) return;

      runInAction(() => {
        this.selectedXMLDocument = new XmlDocumentModel(result[0]);
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} XmlDocument`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getXMLSchemaTemplateById() {
    if (!this.selectedXMLDocument.xmlSchemaTemplate.id) {
      return;
    }

    try {
      const result = await XmlSchemaTemplateService.getXmlSchemaTemplateById(this.selectedXMLDocument.xmlSchemaTemplate.id);
      if (!result) return;

      const json = xml2js.xml2json(result.xsd, { compact: false, spaces: 4 });

      runInAction(() => {
        this.xmlSchemaNodes = JSON.parse(json).elements as IXmlSchemaTemplateElement[];
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} XMLSchemaTemplateById`);
    } finally {
    }
  }

  public async createTemplatesFileWithDocument() {
    if (!this.selectedXMLDocument.postFileDto) {
      return;
    }

    try {
      const result = await XmlSchemaTemplateService.postXmlSchemaTemplate(this.selectedXMLDocument.postFileDto);
      if (!result) return;

      await XmlDocumentInstanceService.postXmlDocument({ name: this.selectedXMLDocument.name, xmlSchemaTemplateId: result });
      this.getXmlDocuments();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} createTemplatesFileWithDocument`);
    } finally {
    }
  }

  public async createXML() {
    if (!this.selectedXMLDocument.templateId || !definitionStore.currentDefinitionId) {
      return;
    }

    try {
      const result = await XmlDocumentInstanceService.postDefinitionInstance({
        templateId: this.selectedXMLDocument.templateId,
        definitionId: definitionStore.currentDefinitionId,
      });
      if (!result) return;

      this.getXmlDocuments();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} createXML`);
    } finally {
    }
  }

  public async getXMLDocumentNodesById() {
    if (!this.selectedXMLDocument.id) {
      return;
    }

    try {
      this.isLoading = true;
      const result = await XmlDocumentInstanceService.getDocumentNodesById(this.selectedXMLDocument.id);

      if (!result) return;

      if (result.length > 0) {
        const tree = this.createXmlDocumentTree(result, false);

        if (tree.length > 0) {
          runInAction(() => {
            this.xmlDocumentTree = tree[0];
          });
        }
      } else {
        const newNode = Utils.findDefaultXMLSchemaNodes(this.xmlSchemaNodes, 'tDocument', 'Document');

        const tree = this.createXmlDocumentTree([newNode], true);

        if (tree.length > 0) {
          runInAction(() => {
            this.xmlDocumentTree = tree[0];
          });
        }
      }
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} XMLDocumentNodesById`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createXMLDocumentNode() {
    if (!this.selectedXMLDocument.id) {
      return;
    }

    try {
      this.isLoading = true;
      await XmlDocumentInstanceService.postXMLDocumentNode({
        ...this.selectedXMLNode.postNodeDto,
        xmlDocumentInstanceId: this.selectedXMLDocument.id,
      });

      this.getXMLDocumentNodesById();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} createXMLDocumentNode`);
    } finally {
      this.isLoading = false;
    }
  }

  public async updateXMLDocumentNode() {
    if (!this.selectedXMLNode.putNodeDto) {
      return;
    }

    try {
      this.isLoading = true;
      await XmlDocumentInstanceService.putXMLDocumentNode(this.selectedXMLNode.putNodeDto);

      this.getXMLDocumentNodesById();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} updateParameterGroup`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getParameterGroupParameters() {
    if (!this.paramGroupFilter.id) return;

    this.isLoading = true;

    try {
      const result = await ParameterGroupService.getAllParameterGroupParameters({
        parentId: this.paramGroupFilter.id,
        includeRoot: true,
      });

      if (!result) return;

      const res: IXMLParameter[] = [];

      result.forEach((el) => {
        const checkedParams = el.parameters.filter((f) => this.selectedXMLNode.parameterBindingIds.includes(f.id));

        res.push({
          hierarchy: [el.fullName],
          id: el.id,
          name: el.fullName,
          fullName: el.fullName,
          isGroup: true,
          parameterIds: el.parameters.length === 0 ? null : el.parameters.map((parameter) => parameter.id),
          checked: checkedParams.length === el.parameters.length && el.parameters.length !== 0 ? true : false,
        });

        el.parameters.forEach((item) => {
          res.push({
            hierarchy: [el.fullName, item.name],
            groupId: el.id,
            name: item.name,
            fullName: item.fullName,
            id: item.id,
            checked: this.selectedXMLNode.parameterBindingIds.includes(item.id),
            isGroup: false,
            parameterIds: [],
          });
        });
      });

      runInAction(() => {
        this.xmlParams = res.map((data) => new XMLParamsModel(data));
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} ParameterGroupParameters`);
    } finally {
      this.isLoading = false;
    }
  }

  public clear() {
    this.xmlSchemaNodes = [];
    this.xmlDocumentTree = new XmlDocumentNodeModel(this);
    this.expandedTreeNodeIds = new Set<number>();
  }

  public clearOptions() {
    this.documentForDefInstance = [];
  }
}

export default new XmlDocumentInstanceStore();
