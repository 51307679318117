import React from 'react';

const CloseIcon: React.FC = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="rgba(15, 11, 31, 0.54)" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.53033 3.53033C9.82322 3.23744 9.82322 2.76256 9.53033 2.46967C9.23744 2.17678 8.76256 2.17678 8.46967 2.46967L6 4.93934L3.53033 2.46967C3.23744 2.17678 2.76256 2.17678 2.46967 2.46967C2.17678 2.76256 2.17678 3.23744 2.46967 3.53033L4.93934 6L2.46967 8.46967C2.17678 8.76256 2.17678 9.23744 2.46967 9.53033C2.76256 9.82322 3.23744 9.82322 3.53033 9.53033L6 7.06066L8.46967 9.53033C8.76256 9.82322 9.23744 9.82322 9.53033 9.53033C9.82322 9.23744 9.82322 8.76256 9.53033 8.46967L7.06066 6L9.53033 3.53033Z" />
    </svg>
  );
};

export default CloseIcon;
