import { makeAutoObservable } from 'mobx';
import { DataNode } from 'rc-tree/lib/interface';
import { classStore } from 'stores';
import { IClassForm, ICreateClassesCommandDto, IUpdateClassDto } from 'shared/interfaces';

/** Классификатор объектов */
export class ClassModel {
  constructor(dto?: any) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.key = dto.key;
    this.title = dto.name;
    this.children = dto.children;
    this.name = dto.name;
    this.id = dto.id;
    this.parentName = dto.parentName;
    this.parentId = dto.parentId;
    this.parents = dto.parents;
  }

  public key: string | number = 0;

  public title: React.ReactNode | ((data: DataNode) => React.ReactNode) = '';

  public name = '';

  public parentName = '';

  public children: ClassModel[] | undefined = undefined;

  public id: number | null = null;

  public parentsCopy: number[] = [];

  public parentId: number | null = null;

  public copyClassId: number | null = null;

  public parents: number[] = [];

  public updateClassDialogForm(data: IClassForm) {
    this.name = data.className;

    if (data.copyClassId) {
      const parents = classStore.classesDto.find((f) => f.id === data.copyClassId)?.parents;

      const parentNode = classStore.treeObject.selected.id;
      if (parents && !parents.includes(parentNode)) parents.push(parentNode);

      this.copyClassId = data.copyClassId;
      this.parentsCopy = parents ?? [];
    }
  }

  private trim(name: string) {
    return name.trim();
  }

  public postDto(): ICreateClassesCommandDto | null {
    if (this.name === '') return null;
    const parentId = classStore.treeObject.selectedKeys.length !== 0 ? classStore.treeObject.selected.id : 0;

    if (parentId !== 0) {
      return {
        classes: [
          {
            name: this.trim(this.name),
            layerId: classStore.classFilters.layerId!,
            parentId: Number(parentId),
          },
        ],
      };
    } else {
      return {
        classes: [
          {
            name: this.trim(this.name),
            layerId: classStore.classFilters.layerId!,
          },
        ],
      };
    }
  }

  public putDto(): IUpdateClassDto | null {
    const id = Number(classStore.treeObject.selected.id);
    if (id === null) return null;

    return {
      id: id,
      name: this.trim(this.name),
      parents: this.parents,
    };
  }

  public putCopyDto(): IUpdateClassDto | null {
    if (!this.copyClassId) return null;

    return {
      id: this.copyClassId,
      name: this.trim(this.name),
      parents: this.parentsCopy,
    };
  }

  public clearModel() {
    this.name = '';
    this.parentName = '';
    this.id = null;
    this.parentsCopy = [];
    this.parentId = null;
    this.parents = [];
    this.copyClassId = null;
  }
}
