import qs from 'qs';
import { BASE_PATH } from '../shared/constants/constants';
import { ICopySectionSetDto, IGetSectionSetDto, IPostPutSectionSetDto } from 'shared/interfaces';
import HTTPService from './HttpService';

class SectionSetService extends HTTPService {
  constructor() {
    super(BASE_PATH.SECTION_SETS);
  }

  public getAllSectionSets(stageIds?: number[]): Promise<IGetSectionSetDto[]> {
    return this.GET('', {
      params: { stageIds },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  }

  public getSectionSet(id: number): Promise<IGetSectionSetDto[]> {
    return this.GET('', { params: { id } });
  }

  public postSectionSet(body: IPostPutSectionSetDto): Promise<number> {
    return this.POST('', body);
  }

  public copySectionSet(body: ICopySectionSetDto): Promise<number> {
    return this.POST('copy', body);
  }

  public putSectionSet(id: number, body: IPostPutSectionSetDto): Promise<IGetSectionSetDto> {
    return this.PUT(`${id}`, body);
  }

  public deleteSectionSet(id: number): Promise<void> {
    return this.DELETE(`${id}`);
  }
}

export default new SectionSetService();
