import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { HandlerProps, ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import {
  documentStore,
  measurementUnitStore,
  parameterGroupStore,
  parameterStore,
  sectionsStore,
  stageStore,
  parameterValueStore,
  sectionSetStore,
} from 'stores';
import { Stack, Box, Paper } from '@mui/material';
import { ParameterModel } from 'shared/models';
import Utils from 'shared/utils/Utils';
import ParamGroupTree from './ParamGroupTree';
import s from './ParamPage.module.scss';
import ParamTable from './ParamTable';

const ParamPage = () => {
  useEffect(() => {
    measurementUnitStore.getMeasurementUnits();
    documentStore.getDocuments();

    //привязка секций
    stageStore.getStages().then(() => {
      parameterStore.filterParameters.setStageId(stageStore.stages[0].id);
      sectionSetStore.setFilterStageId(parameterStore.filterParameters.stageId);
      sectionSetStore.getSectionSets();
    });

    sectionsStore.getSections();

    return () => {
      parameterGroupStore.resetParameterGroup();
      parameterStore.filterParameters.clear();
      parameterStore.setCopyMoveParameterIds([]);
      parameterStore.selectedParameterTable = new ParameterModel();
      parameterStore.setCoordinates(null);
      sectionSetStore.clearFilterStageIds();
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      await parameterGroupStore.getRootParameterGroupList();
      if (parameterGroupStore.rootParameterGroup.length > 0) {
        parameterGroupStore.setCurrentParameterGroup(parameterGroupStore.rootParameterGroup[0].id);
        parameterGroupStore.initFilterStateParamGroup();
      }
    };
    init();

    return () => {
      parameterValueStore.filterStateParamValues.clear();
      parameterGroupStore.selectedParamGroup.clearModel();
    };
  }, []);

  return (
    <Stack>
      <Box height={`calc(100vh - ${Utils.projectHeight()}px)`}>
        <ReflexContainer orientation="vertical">
          <ReflexElement
            size={parameterGroupStore.treeParameterGroupWidth}
            className={s.reflexWrapper}
            onStopResize={(args: HandlerProps) => parameterGroupStore.setParameterGroupWidth((args.domElement as Element).clientWidth)}>
            <Paper elevation={0} sx={{ p: 6, borderRadius: '12px', height: '100%' }}>
              <ParamGroupTree />
            </Paper>
          </ReflexElement>

          <ReflexSplitter propagate className={s.splitter} />

          <ReflexElement>
            <Paper elevation={0} className={s.wrapper} sx={{ p: 6, borderRadius: '12px' }}>
              <ParamTable />
            </Paper>
          </ReflexElement>
        </ReflexContainer>
      </Box>
    </Stack>
  );
};

export default observer(ParamPage);
