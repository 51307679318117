import React from 'react';
import { Translator } from 'stores';
import FilterIcon from 'components/Icons/FilterIcon';
import IconButtonNew from 'components/UI/IconButtonNew';
import PopupMenu from 'components/UI/PopupMenu';
import { Box, CircularProgress, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { NestedLevel } from 'shared/enums';
import { ITableColumn } from 'shared/interfaces';
import NestedRow from './Components';

interface INestedTableProps<T> {
  columns: ITableColumn[];
  tableData: Array<T & { id: number; level: NestedLevel; children: T[]; createdAt?: string }>;
  isLoading: boolean;
  selectedRow?: number | null;
  onSelectRow?: (selectedRow: T) => void;
  onHover?: (rowId: number | null) => void;
  isColorFirstLevel?: boolean;
  isActionsHover?: boolean;
  //TODO:anzhelika.samchuk:hoveredRowIds - ids, с которыми можно производить какие-либо действия (на них появляется coursor poiner). Подумать, как можно обработать логику coursor сверху
  hoveredRowIds?: number[];
  hasFilteredData?: boolean;
  isExpandOnClickRow?: boolean;
  isExpandToolTip?: boolean;
}

const NestedTable = <T,>(props: INestedTableProps<T>) => {
  const {
    columns,
    tableData,
    isLoading,
    onSelectRow,
    selectedRow,
    isColorFirstLevel,
    isActionsHover,
    hoveredRowIds,
    hasFilteredData,
    isExpandOnClickRow,
    isExpandToolTip = false,
    onHover,
  } = props;

  const renderHeader = (cols: ITableColumn[]) => {
    return cols.map((column) => {
      return (
        <TableCell width={column.width} key={column.key} component="th" sx={{ px: 0 }} align={column.align}>
          <Stack
            position="relative"
            direction="row"
            alignItems="center"
            justifyContent={column.align === 'right' ? 'flex-end' : column.align === 'center' ? 'center' : 'flex-start'}>
            <Typography variant="body2" color="text.secondary">
              {column.label}
            </Typography>

            {column.filterOptions && column.filterOptions().length > 0 && (
              <PopupMenu
                button={
                  <IconButtonNew
                    sx={(theme) => ({
                      svg: {
                        fill: column.hasFilters ? theme.palette.primary.main : 'unset',
                        fillOpacity: column.hasFilters ? 1 : 0.54,
                      },
                    })}>
                    <FilterIcon />
                  </IconButtonNew>
                }
                menuItems={column.filterOptions()}
              />
            )}
          </Stack>
        </TableCell>
      );
    });
  };

  const renderTableBody = () => {
    if (tableData.length === 0 && !isLoading) {
      return (
        <TableRow>
          <TableCell sx={{ borderBottom: 'none' }} colSpan={columns.length}>
            <Stack alignItems="center">
              <img
                style={{ alignSelf: 'center' }}
                src={hasFilteredData ? `${process.env.PUBLIC_URL}/img/not_found_data.svg` : `${process.env.PUBLIC_URL}/img/empty_data.svg`}
                alt=""
              />
              <Typography variant="subtitle1" color="text.disabled">
                {hasFilteredData ? Translator.translate('system.notFoundData') : Translator.translate('system.emptyData')}
              </Typography>
            </Stack>
          </TableCell>
        </TableRow>
      );
    } else if (isLoading) {
      return (
        <TableRow>
          <TableCell sx={{ borderBottom: 'none' }} colSpan={columns.length}>
            <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          </TableCell>
        </TableRow>
      );
    } else {
      return (
        <>
          {tableData.map((data) => (
            <NestedRow
              columns={columns}
              key={data.id}
              row={data}
              nestedLevel={data.level}
              selectedRow={selectedRow}
              onSelectRow={onSelectRow}
              onHover={onHover}
              hoveredRowIds={hoveredRowIds}
              isColorFirstLevel={isColorFirstLevel}
              isActionsHover={isActionsHover}
              isExpandOnClickRow={isExpandOnClickRow}
              isExpandToolTip={isExpandToolTip}
            />
          ))}
        </>
      );
    }
  };

  return (
    <TableContainer sx={{ height: '100%' }}>
      <Table size="small" stickyHeader>
        <TableHead
          sx={(theme) => ({
            '.MuiTableCell-head': {
              borderBottom: isColorFirstLevel ? 'none' : `1px solid ${theme.palette.background.default}`,
              backgroundColor: theme.palette.common.white,
              '&:first-of-type': {
                borderTopLeftRadius: '6px',
                borderBottomLeftRadius: '6px',
              },
              '&:last-child': {
                borderTopRightRadius: '6px',
                borderBottomRightRadius: '6px',
              },
            },
          })}>
          <TableRow>{renderHeader(columns)}</TableRow>
        </TableHead>

        <TableBody
          sx={(theme) => ({
            '.MuiTableCell-body': {
              overflow: 'auto',
              borderBottom: `1px solid ${theme.palette.background.default}`,
              '&:first-of-type': {
                borderTopLeftRadius: '6px',
                borderBottomLeftRadius: '6px',
              },
              '&:last-child': {
                borderTopRightRadius: '6px',
                borderBottomRightRadius: '6px',
              },
            },
          })}>
          {renderTableBody()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NestedTable;
