import React from 'react';

const CommentExistIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#7B68EE" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 13C11.3137 13 14 10.5376 14 7.5C14 4.46243 11.3137 2 8 2C4.68629 2 2 4.46243 2 7.5C2 8.65773 2.39022 9.73191 3.05668 10.618L2.09971 13.0315C1.75917 13.577 2.3551 14.2248 2.91997 13.9233L6.10855 12.7211C6.70319 12.9021 7.33912 13 8 13Z" />
    </svg>
  );
};

export default CommentExistIcon;
