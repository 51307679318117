import { makeAutoObservable } from 'mobx';
import { IGetDocumentDto, IPostPutDocumentDto, ISelectOption } from 'shared/interfaces';

class DocumentListModel {
  constructor(dto?: IGetDocumentDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.url = dto.url;
    this.documentPage = dto.documentPage;
    this.description = dto.description;
    this.categoryId = dto.categoryId;
    this.orderNum = dto.orderNum;
  }

  public id: number | null = null;

  public categoryId: number | null = null;

  public name = '';

  public url = '';

  public description = '';

  public documentPage = '';

  public orderNum: number | null = null;

  public get postPutDto(): IPostPutDocumentDto | null {
    if (!this.name || !this.categoryId) return null;

    return {
      name: this.name,
      url: this.url,
      description: this.description,
      categoryId: this.categoryId,
    };
  }

  public setId(id: number) {
    this.id = id;
  }

  public setName(name: string) {
    this.name = name;
  }

  public setURL(url: string) {
    this.url = url;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public setCategoryId(categoryId: number | null) {
    this.categoryId = categoryId;
  }

  public setDocumentPage(documentPage: string) {
    this.documentPage = documentPage;
  }

  public getSelectOption(): ISelectOption | null {
    if (!this.id) return null;

    return { id: this.id, name: this.name };
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.url = '';
    this.description = '';
    this.documentPage = '';
  }
}

export default DocumentListModel;
