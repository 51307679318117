import { makeAutoObservable } from 'mobx';
import { ClassificationDictionaryService } from 'services';
import { Translator, toastStore } from 'stores';
import { IClassificationDictionaryGetDto, ISelectOption } from 'shared/interfaces';

class ClassificationDictionaryStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public dictionaries: IClassificationDictionaryGetDto[] = [];

  public currentDictionary: number | null = null;

  public get dictionaryForOptions(): ISelectOption[] {
    return this.dictionaries.map((dictionary) => {
      return {
        id: dictionary.id,
        name: dictionary.name,
      };
    });
  }

  public setCurrentDictionary(currentDictionaryId: number) {
    this.currentDictionary = currentDictionaryId;
  }

  public async getDictionaryList(): Promise<void> {
    try {
      this.isLoading = true;
      const result = await ClassificationDictionaryService.getClassificationDictionaries();
      if (!result) return;

      this.dictionaries = result;
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Dictionaries`);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new ClassificationDictionaryStore();
