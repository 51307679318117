import React from 'react';
import TreeStateFalseIcon from 'components/Icons/TreeStateFalseIcon';
import TreeStateTrueIcon from 'components/Icons/TreeStateTrueIcon';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { StateBoolean } from 'shared/enums';

import s from './TogglerTreeState.module.scss';

interface ITogglerTreeStateProps {
  isInheritedValue?: boolean;
  selectedTab: string;
  onChangeTab?: (value: string) => void;
}

const TogglerTreeState: React.FC<ITogglerTreeStateProps> = (props) => {
  const { selectedTab, onChangeTab, isInheritedValue } = props;

  const onChange = (event: React.MouseEvent<HTMLElement>, value: StateBoolean) => {
    if (value !== null) {
      onChangeTab && onChangeTab(value);
    }
  };

  const stateBoolean = [
    {
      value: StateBoolean.False,
    },
    {
      value: StateBoolean.Null,
    },
    {
      value: StateBoolean.True,
    },
  ];

  const getWrapperContentClassName = (): string | undefined => {
    if (selectedTab === StateBoolean.False) {
      if (isInheritedValue) return s.wrapperContentFalseInherited;
      else return s.wrapperContentFalseActive;
    }

    if (selectedTab === StateBoolean.True) {
      if (isInheritedValue) return s.wrapperContentTrueInherited;
      else return s.wrapperContentTrueActive;
    }

    return s.wrapperContentFalseInherited;
  };

  const getStateClassName = (value: StateBoolean): string | undefined => {
    if (value === selectedTab && selectedTab === StateBoolean.False) {
      return s.toggleButtonFalse;
    }

    if (value === selectedTab && selectedTab === StateBoolean.True) {
      if (isInheritedValue) return s.toggleButtonInherited;
      else return s.toggleButtonActive;
    }

    if (value === selectedTab && selectedTab === StateBoolean.Null) {
      return s.toggleButtonNull;
    }

    if (value !== selectedTab) {
      return s.toggleButton;
    }
  };

  return (
    <Box className={s.wrapper}>
      <Box className={getWrapperContentClassName()}>
        <ToggleButtonGroup className={s.toggleButtonGroup} exclusive size="small" value={selectedTab} onChange={onChange}>
          {stateBoolean.map((tab) => (
            <ToggleButton className={getStateClassName(tab.value)} key={tab.value} value={tab.value} disableRipple>
              <Box className={s.innerIconWrapper}>
                {tab.value === selectedTab && selectedTab === StateBoolean.False && (
                  <TreeStateFalseIcon opacity={isInheritedValue ? '0.38' : '0.6'} />
                )}

                {tab.value === selectedTab && selectedTab === StateBoolean.True && <TreeStateTrueIcon />}
              </Box>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default TogglerTreeState;
