export enum Routes {
  MAIN = '/',
  DEFINITION = '/definitions',
  CLASSES = '/classes',
  PARAM = '/param',
  OBJECT_VALUE = '/object-value',
  SPECIFICATIONS = '/specifications',
  COMMENTS = '/discussions',
  MERGE = '/merge',
  APPROVALS = '/approvals',
  PARTIAL_APPROVALS = 'partial-approvals',
  PARTIAL_APPROVALS_SETS = 'partial-approvals-sets',

  CATALOG = '/catalog',
  STAGES = 'stages',
  DICTIONARIES = 'dictionaries',
  TEMPLATES = 'templates',
  UNITS = 'units',
  DOCUMENTS = 'documents',
  INFORMATION_BLOCKS = 'information-blocks',
  XML_DOCUMENT = 'xml-document',

  SETTINGS = '/settings',
  ROLES = 'roles',
  EXPORT_DOCUMENTS = 'export-documents',
  EXPORT_VIEW_LIST = 'export-view-list',
  EXPORT_VIEW_UNTITLED = 'export-view-untitled',
  XML_VIEWER = '/xml-viewer',
}

export const Pages = new Map([
  [Routes.MAIN, 'projects.pageTitle'],
  [Routes.OBJECT_VALUE, 'objectValues.pageTitle'],
  [Routes.CLASSES, 'classes.pageTitle'],
  [Routes.SPECIFICATIONS, 'specifications.pageTitle'],
  [Routes.PARAM, 'params.pageTitle'],
  [Routes.COMMENTS, 'comments.pageTitle'],
  [Routes.APPROVALS, 'approvals.pageTitle'],
  [Routes.CATALOG, 'catalogs.pageTitle'],
  [Routes.MERGE, 'merge.pageTitle'],
  [Routes.SETTINGS, 'settings.pageTitle'],
  [Routes.XML_VIEWER, 'xml.pageTitle'],
]);
