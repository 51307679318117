import React from 'react';
import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps, Typography } from '@mui/material';
import { ITabItem } from 'shared/interfaces';

import s from './Toggler.module.scss';

interface ITogglerProps extends ToggleButtonGroupProps {
  tabItems: ITabItem[];
  onChangeTab: (value: string | number) => void;
  btnWidth?: string;
}

const Toggler: React.FC<ITogglerProps> = (props) => {
  const { tabItems, btnWidth, fullWidth, onChangeTab } = props;

  const onChange = (event: React.MouseEvent<HTMLElement>, value: string | number) => {
    if (value !== null) {
      onChangeTab(value);
    }
  };

  return (
    <ToggleButtonGroup
      className={s.toggleButtonGroup}
      sx={{ width: fullWidth ? '100%' : 'fit-content' }}
      exclusive
      value={props.value}
      fullWidth={props.fullWidth}
      onChange={onChange}>
      {tabItems.map((tab) => (
        <ToggleButton
          sx={{
            width: btnWidth,
            padding: '0 20px',
          }}
          className={tab.value === props.value ? s.toggleButtonActive : s.toggleButton}
          key={tab.value}
          value={tab.value}>
          {typeof tab.label === 'string' ? (
            <Typography variant="button" className={tab.value === props.value ? s.labelActive : s.label}>
              {tab.label}
            </Typography>
          ) : (
            <>{tab.label}</>
          )}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default Toggler;
