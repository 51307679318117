export enum Operator {
  Eq = 'Eq',
  Ne = 'Ne',
  Gt = 'Gt',
  Ge = 'Ge',
  Lt = 'Lt',
  Le = 'Le',
  In = 'In',
  Range = 'Range',
}
