import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet, useLocation } from 'react-router-dom';
import { Translator, appStore, approvalSuggestionRowStore, auditStore, commentStore, definitionStore, itemStore, parameterValueStore } from 'stores';
import { CommentDialog, Header, DrawerWrapper, HistoryContent, SideBar, PageTitle } from 'components';
import { CurrentProject } from 'components/Layouts/Header/Components';
import { Box, Stack } from '@mui/material';
import { LAYOUT_PADDING, PAGE_TITLE_PADDING } from 'shared/constants/constants';
import { CommentMode, CommentModeNames } from 'shared/enums';
import { Routes } from 'shared/enums/Routes';
import Utils from 'shared/utils/Utils';

const Layout: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    Translator.init();
  }, []);

  const getSubTitle = (): string => {
    switch (commentStore.mode) {
      case CommentMode.Parameter:
        return `${Translator.translate(CommentModeNames.get(CommentMode.Parameter))}: ${parameterValueStore.selectedParamValue.paramName}`;

      case CommentMode.Item:
        return `${Translator.translate(CommentModeNames.get(CommentMode.Item))}: ${itemStore.selectItem.name}`;

      case CommentMode.ApprovalRow:
        return `${Translator.translate(CommentModeNames.get(CommentMode.ApprovalRow))}: ${
          approvalSuggestionRowStore.approvalSuggestionRow.parameterName
        }`;
    }
  };

  return (
    <Stack component="section" height="100%" sx={{ background: 'initial' }}>
      <Header />

      <SideBar />

      <Stack component="main" sx={{ padding: `0px 24px ${LAYOUT_PADDING}px 64px` }} flex={1} overflow="auto">
        {Utils.isShowBreadcrumbs(location.pathname) && (
          <Box sx={{ pl: 6, paddingBottom: `${PAGE_TITLE_PADDING}px` }}>
            {location.pathname.includes(Routes.PARTIAL_APPROVALS) ? <CurrentProject /> : <PageTitle />}
          </Box>
        )}
        <Outlet />
      </Stack>

      {auditStore.isDrawerOpen && (
        <DrawerWrapper open={auditStore.isDrawerOpen} onClose={() => auditStore.setDrawerOpen(false)}>
          <HistoryContent />
        </DrawerWrapper>
      )}

      {commentStore.isOpenedDialog && appStore.isShowComments && <CommentDialog isOpenDialog={commentStore.isOpenedDialog} title={getSubTitle()} />}
    </Stack>
  );
};

export default observer(Layout);
