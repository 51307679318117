import React from 'react';
import { Typography, Stack } from '@mui/material';

interface ITextWrapper {
  name: string;
  description: string | JSX.Element;
}

const TextWrapper: React.FC<ITextWrapper> = (props) => {
  const { name, description } = props;

  return (
    <Stack>
      <Typography variant="caption" color="text.secondary">
        {name}
      </Typography>

      <Typography color="text.primary" variant="body2">
        {description}
      </Typography>
    </Stack>
  );
};

export default TextWrapper;
