import React, { useRef, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { SpecificationsService } from 'services';
import { Translator, specificationStore, toastStore } from 'stores';
import { DialogWrapper, InputValidate } from 'components';
import DocIcon from 'components/Icons/DocIcon';
import UploadIcon from 'components/Icons/UploadIcon';
import { Box, InputLabel, Link, List, ListItem, Stack, Typography } from '@mui/material';
import { LangNames } from 'shared/enums';
import { ISpecificationForm } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';

const SpecificationDialog: React.FC = () => {
  const formRef = useRef<FormikProps<ISpecificationForm>>(null);
  const [isHover, setHover] = useState(false);
  const dialogTitle = Translator.translate('specifications.specificationDialog.title');

  useEffect(() => {
    return () => {
      specificationStore.selectedSpecification.clear();
    };
  }, []);

  const onSubmit = async (values: ISpecificationForm) => {
    specificationStore.selectedSpecification.updateSpecificationForm(values);

    await specificationStore.updateSpecification();
    await specificationStore.updateSpecificationTemplateFile();

    specificationStore.getSpecifications();
    onClose();
  };

  const onClose = () => {
    specificationStore.setDialogOpen(false);
  };

  const specificationAddForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        name: specificationStore.selectedSpecification.name,
        comment: specificationStore.selectedSpecification.comment,
        templateFile: specificationStore.selectedSpecification.templateFile,
        fileId: specificationStore.selectedSpecification.fileTemplate?.id,
        fileName: specificationStore.selectedSpecification.fileTemplate?.name,
        fileCreatedAt: specificationStore.selectedSpecification.fileTemplate.createdAt,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required(Translator.translate('validationMessage.required')),
      })}>
      {({ handleChange, handleBlur, values, errors, touched, setFieldValue }) => {
        const onDownloadTemplateFile = () => {
          SpecificationsService.downloadTemplateFile(values.fileId)
            .then((resp) => {
              const url = window.URL.createObjectURL(new Blob([resp as any]));

              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${values.fileName}`);
              document.body.appendChild(link);
              link.click();
            })
            .catch((e: any) => {
              Utils.parseBlobError(e, `${Translator.translate('stores.getListErrorMessage')} downloadDocument`).then(toastStore.showError);
            });
        };

        return (
          <Stack gap={6} height="320px">
            <InputValidate
              required
              label={Translator.translate('specifications.specificationDialog.dialogFormFields.name')}
              name="name"
              error={Boolean(touched.name && errors.name)}
              helperText={(touched.name as any) && errors.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <List sx={{ p: 0, display: 'flex', flexDirection: 'column', gap: 4 }}>
              <ListItem
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  height: 42,
                  p: 0,
                  borderRadius: '8px',
                  ':hover': {
                    backgroundColor: 'action.hover',
                  },
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <Stack px={1} flexDirection="row" gap={2} alignItems="center">
                  <DocIcon />

                  <Stack>
                    <Link
                      sx={{
                        cursor: 'pointer',
                        ':hover': {
                          color: 'text.primary',
                        },
                      }}
                      color="text.primary"
                      variant="body2"
                      onClick={onDownloadTemplateFile}>
                      {values.fileName}
                    </Link>

                    <Typography color="text.secondary" variant="caption">
                      {dayjs(values.fileCreatedAt).format(
                        Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY HH:mm' : 'MM/DD/YYYY h:mm A'
                      )}
                    </Typography>
                  </Stack>
                </Stack>

                {isHover && (
                  <InputLabel
                    sx={{
                      mt: 2,
                      mr: 4,
                      cursor: 'pointer',
                    }}
                    htmlFor="files">
                    <Box
                      sx={(theme) => ({
                        svg: {
                          ':hover': {
                            fill: theme.palette.primary.main,
                            fillOpacity: 1,
                          },
                        },
                      })}>
                      <UploadIcon />
                    </Box>
                    <input
                      id="files"
                      type="file"
                      hidden
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('templateFile', e.target.files?.length !== 0 && e.target.files?.length && (e.target.files[0] as File));
                        setFieldValue('fileName', e.target.files?.length !== 0 && e.target.files?.length && e.target.files[0].name);
                        setFieldValue('fileCreatedAt', dayjs());
                      }}
                    />
                  </InputLabel>
                )}
              </ListItem>
            </List>

            <InputValidate
              multiline
              name="comment"
              label={Translator.translate('specifications.specificationDialog.dialogFormFields.comment')}
              value={values.comment}
              onChange={handleChange}
            />
          </Stack>
        );
      }}
    </Formik>
  );

  return (
    <DialogWrapper
      title={dialogTitle}
      maxWidth="md"
      open={specificationStore.isDialogOpen}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}
      titleSubmit={Translator.translate('actions.save')}>
      {specificationStore.isLoading ? (
        <Stack height={56} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        specificationAddForm
      )}
    </DialogWrapper>
  );
};

export default observer(SpecificationDialog);
