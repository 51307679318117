import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Translator, approvalStore } from 'stores';
import { DialogWrapper } from 'components';
import { Box, DialogContent, DialogTitle, List, ListItemButton } from '@mui/material';
import { Routes } from 'shared/enums/Routes';

const ApprovalSetsDialog: React.FC = () => {
  const navigate = useNavigate();

  const onCancel = () => {
    approvalStore.setOpenSetsApprovalDialog(false);
  };

  const handlePartialApprovalRedirect = (el: any) => {
    navigate(`${Routes.APPROVALS}/${approvalStore.preSelectedApprovalId.approvalId}/${Routes.PARTIAL_APPROVALS_SETS}/${el.id}`);
  };

  useEffect(() => {
    return () => {
      approvalStore.approvalSets = [];
      approvalStore.partialApprovalSets = [];
      approvalStore.setOpenSetsApprovalDialog(false);
    };
  }, []);

  return (
    <DialogWrapper isCustom open={approvalStore.isApprovalSetsDialogOpen} onClose={onCancel}>
      <DialogTitle>{Translator.translate('approvals.approvalSetDialog.title')}</DialogTitle>
      <DialogContent>
        <List>
          {approvalStore.approvalSets.map((el, index) => (
            <ListItemButton
              sx={{
                ':hover': {
                  color: 'primary.main',
                },
              }}
              key={index}>
              {el.map((_) => {
                return (
                  <Box key={_.id} onClick={() => handlePartialApprovalRedirect(_)}>
                    {_.name} &nbsp;
                  </Box>
                );
              })}
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
    </DialogWrapper>
  );
};

export default observer(ApprovalSetsDialog);
