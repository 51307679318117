import { makeAutoObservable } from 'mobx';
import { DefinitionApprovalStatus } from 'shared/enums';

import { IDefinitionCopyDto, IDefinitionForm, IDefinitionGetDto, IDefinitionPostPutDto, IGetStageDto } from 'shared/interfaces';
import { ProjectType } from './../../enums/common';

class DefinitionModel {
  constructor(dto?: IDefinitionGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.isMain = dto.isMain;
    this.version = String(dto.version) ?? '';
    this.projectId = dto.projectId;
    this.stageId = dto.stage ? dto.stage.id : null;
    this.stages = dto.stage ? [dto.stage] : [];
    this.createdAt = dto.createdAt;
    this.status = dto.status;
    this.projectName = dto.project ? dto.project.name : '';
    this.projectType = dto.project ? dto.project.type : ProjectType.Standard;
  }

  public id: number | null = null;

  public name = '';

  public isMain = true;

  public version = '';

  public projectId: number | null = null;

  public projectName = '';

  public projectType = ProjectType.Standard;

  public stageId: number | null = null;

  public stages: IGetStageDto[] = [];

  public createdAt = '';

  public status = DefinitionApprovalStatus.Formed;

  public sourceDefinitionId: number | null = null;

  public isHover = false;

  public get postPutDto(): IDefinitionPostPutDto | null {
    if (!this.version) return null;

    return {
      name: this.name,
      isMain: this.isMain,
      version: Number(this.version.replaceAll(',', '.')),
      projectId: this.projectId,
      stageId: this.stageId,
    };
  }

  public get copyDto(): IDefinitionCopyDto | null {
    if (this.sourceDefinitionId === null) return null;

    return {
      name: this.name,
      sourceDefinitionId: this.sourceDefinitionId,
      projectId: this.projectId,
      version: Number(this.version.replaceAll(',', '.')) ?? undefined,
      stageId: this.stageId,
      isMain: this.isMain,
    };
  }

  public updateDefinitionDialogForm(data: IDefinitionForm) {
    this.name = data.name;
    this.version = data.version;
    this.stageId = data.stageId;
    this.isMain = data.isMain;
    this.sourceDefinitionId = data.sourceDefinitionId;
  }

  public setHover(isHover: boolean) {
    this.isHover = isHover;
  }

  public setStageId(stageId: number | null) {
    this.stageId = stageId;
  }

  public setProjectId(id: number) {
    this.projectId = id;
  }

  public setProjectType(projectType: ProjectType) {
    this.projectType = projectType;
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.isMain = true;
    this.version = '';
    this.projectId = null;
    this.stageId = null;
    this.sourceDefinitionId = null;
    this.stages = [];
  }
}

export default DefinitionModel;
