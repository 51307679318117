import React from 'react';

interface IEditProps {
  opacity?: string;
}

const TreeStateFalseIcon: React.FC<IEditProps> = (props) => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="#0F0B1F" fillOpacity={props.opacity ?? '0.6'} xmlns="http://www.w3.org/2000/svg">
      <path d="M6.80716 2.26423C7.10285 1.96854 7.10285 1.48913 6.80716 1.19345C6.51148 0.897758 6.03207 0.897758 5.73638 1.19345L4.00031 2.92952L2.26423 1.19345C1.96854 0.897758 1.48913 0.897758 1.19345 1.19345C0.897758 1.48913 0.897758 1.96854 1.19345 2.26423L2.92952 4.00031L1.19345 5.73638C0.897758 6.03207 0.897758 6.51148 1.19345 6.80716C1.48913 7.10285 1.96854 7.10285 2.26423 6.80716L4.00031 5.07109L5.73638 6.80716C6.03207 7.10285 6.51148 7.10285 6.80716 6.80716C7.10285 6.51148 7.10285 6.03207 6.80716 5.73638L5.07109 4.00031L6.80716 2.26423Z" />
    </svg>
  );
};

export default TreeStateFalseIcon;
