import { useEffect, useState } from 'react';
import { GridColumns } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { Translator, projectStore, usersStore } from 'stores';
import { DialogWrapper, FindTableDataDialog, IconButtonNew, TableView } from 'components';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import TrashIcon from 'components/Icons/TrashIcon';
import { Button, Stack, Typography } from '@mui/material';
import { Roles, RolesNames } from 'shared/enums';
import { IGetDocumentDto, IProject, ITableColumn } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';

const RoleDialog: React.FC = () => {
  const [isOpenDialog, setOpenDialog] = useState(false);

  const columns: ITableColumn[] = [
    {
      key: 'name',
      label: Translator.translate('roles.roleDialog.columns.name'),
      width: '95%',
      align: 'left',
    },
    {
      key: 'actions',
      label: '',
      width: '5%',
      align: 'left',
      cell: (row: IGetDocumentDto) => {
        const onDelete = (id: number) => {
          usersStore.selectedUser.deleteProject(id);
        };

        return (
          <Stack
            flexDirection="row"
            gap={2}
            justifyContent="flex-end"
            sx={(theme) => ({
              mr: 2,
              svg: {
                ':hover': {
                  fill: theme.palette.primary.main,
                  fillOpacity: 1,
                },
              },
            })}>
            <IconButtonNew tooltip={Translator.translate('roles.roleDialog.deleteProjectButtonTitle')} onClick={() => onDelete(row.id)}>
              <TrashIcon />
            </IconButtonNew>
          </Stack>
        );
      },
    },
  ];

  const columnsSearch: GridColumns = [
    {
      field: 'name',
      headerName: Translator.translate('roles.roleDialog.columns.name'),
      flex: 1,
      editable: false,
      filterable: false,
      sortable: false,
      disableReorder: true,
    },
  ];

  useEffect(() => {
    projectStore.getProjectList();

    return () => {
      usersStore.selectedUser.clear();
    };
  }, []);

  const onSubmit = async () => {
    const findUser = usersStore.filterUsers.find((f) => f.id === usersStore.selectedUser.id);
    if (findUser && Utils.isChangeArray(findUser.projects, usersStore.selectedUser.projects)) {
      await usersStore.updateUser();
    }

    usersStore.setDialogOpen(false);
  };

  const onSaveProjects = (projects: IProject[]) => {
    usersStore.selectedUser.addProject(projects);
    setOpenDialog(false);
  };

  return (
    <DialogWrapper
      title={Translator.translate('roles.roleDialog.title')}
      subTitle={
        <Stack direction="column" gap={1}>
          <Typography variant="caption" color="text.primary">
            {usersStore.selectedUser.name}
          </Typography>

          <Typography variant="caption" color="text.primary">
            {usersStore.selectedUser.roles.map((role: Roles) => Translator.translate(RolesNames.get(role))).join(', ')}
          </Typography>
        </Stack>
      }
      open={usersStore.isDialogOpen}
      onClose={() => usersStore.setDialogOpen(false)}
      maxWidth="md"
      onCancel={() => usersStore.setDialogOpen(false)}
      onSubmit={onSubmit}>
      <TableView maxHeight="50vh" isActionsHover data={usersStore.selectedUser.projects} columns={columns} isHideHeader isSelectedById />

      <Button
        variant="text"
        onClick={() => setOpenDialog(true)}
        sx={(theme) => ({ width: 'fit-content', svg: { fill: theme.palette.primary.main } })}
        startIcon={<PlusIconForButton />}>
        {Translator.translate('roles.roleDialog.addProjectButtonTitle')}
      </Button>

      {isOpenDialog && (
        <FindTableDataDialog
          title={Translator.translate('roles.roleDialog.findProjectTableDialog.title')}
          selectAllText={Translator.translate('roles.roleDialog.findProjectTableDialog.selectAllProjectText')}
          notFoundText={Translator.translate('roles.roleDialog.findProjectTableDialog.notFoundProjectText')}
          data={projectStore.projectsForFindDialog}
          onSave={onSaveProjects}
          columns={columnsSearch}
          isOpen={isOpenDialog}
          onClose={() => setOpenDialog(false)}
          isChecked={false}
        />
      )}
    </DialogWrapper>
  );
};

export default observer(RoleDialog);
