import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, Link } from 'react-router-dom';

import { Translator, approvalStore, definitionStore, projectStore } from 'stores';
import ChevronRight from 'components/Icons/ChevronRight';
import { Breadcrumbs, Chip, Tooltip, Typography } from '@mui/material';
import { ProjectType, ProjectTypeNames } from 'shared/enums';
import { Routes } from 'shared/enums/Routes';

const CurrentProject: React.FC = () => {
  const location = useLocation();

  const setProjectFilter = () => {
    if (definitionStore.currentDefinition.projectType === ProjectType.Standard) projectStore.projectFilters.setType(ProjectType.Standard);
    else projectStore.projectFilters.setType(ProjectType.Local);
  };

  const breadcrumbs = [
    <Link key="type" to={Routes.MAIN} onClick={setProjectFilter}>
      <Typography
        variant="body2"
        color="text.primary"
        sx={(theme) => ({
          ':hover': {
            color: theme.palette.primary.main,
          },
        })}>
        {Translator.translate(ProjectTypeNames.get(definitionStore.currentDefinition.projectType))}
      </Typography>
    </Link>,

    <Link key="project" to={Routes.MAIN} onClick={setProjectFilter}>
      <Typography
        variant="body2"
        color="text.primary"
        sx={(theme) => ({
          ':hover': {
            color: theme.palette.primary.main,
          },
        })}>
        {definitionStore.currentDefinition.projectName}
      </Typography>
    </Link>,

    <Typography key="definition" variant="body2" color="text.secondary">
      {definitionStore.currentDefinition.name}
    </Typography>,
  ];

  const breadcrumbsApproval = [
    <Tooltip
      key="project"
      enterDelay={0}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -6],
            },
          },
        ],
      }}
      arrow
      title={Translator.translate('projects.title')}>
      <Typography variant="body2" color="text.secondary">
        {approvalStore.selectedApproval.project.name}
      </Typography>
    </Tooltip>,

    <Tooltip
      key="definition"
      enterDelay={0}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -6],
            },
          },
        ],
      }}
      arrow
      title={Translator.translate('projects.projectTypeNames.hpf')}>
      <Typography key="2" variant="body2" color="text.secondary">
        {approvalStore.selectedApproval.definition.name}
      </Typography>
    </Tooltip>,

    <Tooltip
      key="specification"
      enterDelay={0}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -6],
            },
          },
        ],
      }}
      arrow
      title={Translator.translate('specifications.abbreviation')}>
      <Typography variant="body2" color="text.secondary">
        {approvalStore.selectedApproval.specification.name}
      </Typography>
    </Tooltip>,
  ];

  return (
    <Breadcrumbs separator={location.pathname.includes(Routes.APPROVALS) ? '/' : <ChevronRight />}>
      {location.pathname.includes(Routes.APPROVALS) ? breadcrumbsApproval : breadcrumbs}

      {definitionStore.currentDefinition.projectType === ProjectType.Local && !location.pathname.includes(Routes.APPROVALS) && (
        <Tooltip title={Translator.translate('catalogs.stages.title')}>
          <Chip size="small" label={definitionStore.currentDefinition.stages[0].name} />
        </Tooltip>
      )}
    </Breadcrumbs>
  );
};

export default observer(CurrentProject);
