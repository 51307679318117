import { useEffect, useState } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Translator, approvalStore, approvalSuggestionRowStore, itemStore, parameterGroupStore } from 'stores';
import { DialogWrapper, IconButtonNew, InputSearch, ParameterGroupFilter } from 'components';
import ItemFilter from 'components/App/ItemFilter';
import IconSearch from 'components/Icons/IconSearch';

import { Box, Fade, Stack } from '@mui/material';
import { ItemModel, ParameterGroupModel } from 'shared/models';
import { SuggestionValueTable } from './Components';

const SuggestionDialog: React.FC = () => {
  const [isOpenSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    approvalSuggestionRowStore.approvalSuggestionRow.clear();

    approvalStore.selectedApproval.definition.id &&
      itemStore.getItemList(approvalStore.selectedApproval.definition.id).then(() => {
        runInAction(() => {
          itemStore.treeTestItem = itemStore.createItemTreeData(itemStore.itemsDto).map((item) => new ItemModel(item));
        });
      });

    if (!approvalSuggestionRowStore.filterParameters.parameterGroupId) {
      parameterGroupStore.getRootParameterGroupList().then(async () => {
        if (!parameterGroupStore.currentParameterGroup) {
          parameterGroupStore.setCurrentParameterGroup(Number(parameterGroupStore.rootParameterGroup[0].id));

          parameterGroupStore.getParameterGroups().then(async () => {
            parameterGroupStore.defaultExpandSelectParamGroup();

            approvalSuggestionRowStore.filterParameters.setParameterGroupId(parameterGroupStore.selectedParamGroup.id);
            await approvalSuggestionRowStore.getParameterSuggestion();
          });
        }
      });
    }

    return () => {
      parameterGroupStore.resetParameterGroup();
      approvalSuggestionRowStore.filterParameters.clear();
      approvalSuggestionRowStore.approvalSuggestionRow.clearValues();
      approvalSuggestionRowStore.clearParametersSuggestion();
    };
  }, []);

  const onClose = () => {
    approvalSuggestionRowStore.setSuggestionDialogDialogOpen(false);
  };

  const onClickSearch = () => {
    console.log('1');
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    approvalSuggestionRowStore.filterParameters.setSearchPattern(event.target.value);
  };

  const onChangeParamGroup = async (value: ParameterGroupModel) => {
    approvalSuggestionRowStore.approvalSuggestionRow.clearValues();

    approvalSuggestionRowStore.filterParameters.setParameterGroupId(value.id);
    approvalSuggestionRowStore.getParameterSuggestion();
  };

  return (
    <DialogWrapper
      title={Translator.translate('approvals.approvalRowsPage.suggestionDialog.title')}
      maxWidth="lg"
      open={approvalSuggestionRowStore.isSuggestionDialogOpen}
      onClose={onClose}
      onCancel={onClose}
      titleSubmit={Translator.translate('actions.add')}
      disableSubmit={
        approvalSuggestionRowStore.approvalSuggestionRow.itemIds.length === 0 || !approvalSuggestionRowStore.filterParameters.parameterGroupId
      }
      onSubmit={() => approvalSuggestionRowStore.createRowSuggestionApproval()}>
      <>
        <Stack direction="row" gap={4}>
          <Box width="50%" maxHeight="40px">
            <ItemFilter />
          </Box>

          <Stack flexDirection="row" alignItems="center" width="50%" maxWidth="50%">
            {!isOpenSearch && (
              <Box width="100%">
                <ParameterGroupFilter value={approvalSuggestionRowStore.filterParameters.parameterGroupId} onChangeParamGroup={onChangeParamGroup} />
              </Box>
            )}

            {!isOpenSearch && (
              <IconButtonNew sx={{ mx: 2 }} onClick={() => setOpenSearch(true)}>
                <IconSearch />
              </IconButtonNew>
            )}

            {isOpenSearch && (
              <Box sx={{ width: '100%' }}>
                <Fade timeout={100} in={isOpenSearch}>
                  <div>
                    {isOpenSearch && (
                      <InputSearch
                        fullWidth
                        isExpanded
                        value={approvalSuggestionRowStore.filterParameters.searchPattern}
                        onSearch={onClickSearch}
                        onChange={onSearch}
                        onClose={() => {
                          approvalSuggestionRowStore.filterParameters.setSearchPattern('');
                          setOpenSearch(false);
                        }}
                      />
                    )}
                  </div>
                </Fade>
              </Box>
            )}
          </Stack>
        </Stack>

        <SuggestionValueTable />
      </>
    </DialogWrapper>
  );
};

export default observer(SuggestionDialog);
