import React from 'react';

const TxtIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z" fill="#E9E9F4" />
      <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#A9ABB9" />
      <path d="M30 14L24 8H30V14Z" fill="#7B68EE" fillOpacity="0.08" />
      <path d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z" fill="#2044D5" />
      <path
        d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z"
        fill="url(#paint0_linear_12607_183097)"
        fillOpacity="0.24"
      />
      <path
        d="M8.29893 19.4686H6.89993C6.20393 19.4686 6.20393 18.4456 6.89993 18.4456H10.6819C11.3939 18.4456 11.3939 19.4686 10.6819 19.4686H9.35393V23.5056C9.35393 24.2006 8.29793 24.2176 8.29793 23.5056V19.4686H8.29893Z"
        fill="white"
      />
      <path
        d="M14.026 20.3859L15.418 18.6429C15.834 18.0999 16.641 18.7949 16.186 19.3149C15.706 19.8829 15.203 20.4989 14.722 21.1289L16.354 23.1599C16.793 23.7599 15.914 24.3359 15.482 23.7439L14.011 21.8569L12.564 23.7839C12.156 24.3669 11.252 23.7119 11.709 23.1679L13.315 21.1289C12.812 20.4979 12.324 19.8829 11.836 19.3149C11.332 18.7149 12.268 18.0999 12.635 18.6599L14.026 20.3859Z"
        fill="white"
      />
      <path
        d="M18.6429 19.4668H17.2449C16.5489 19.4668 16.5489 18.4438 17.2449 18.4438H21.0259C21.7379 18.4438 21.7379 19.4668 21.0259 19.4668H19.6989V23.5038C19.6989 24.1988 18.6429 24.2158 18.6429 23.5038V19.4668Z"
        fill="white"
      />
      <path d="M25 27H6V28H25C25.55 28 26 27.55 26 27V26C26 26.55 25.55 27 25 27Z" fill="#7B68EE" fillOpacity="0.12" />
      <defs>
        <linearGradient id="paint0_linear_12607_183097" x1="14" y1="15" x2="14" y2="27" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TxtIcon;
