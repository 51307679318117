import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { Translator } from 'stores';
import ExpandIcon from 'components/Icons/ExpandIcon';
import { Button, Stack, Box } from '@mui/material';
import NestedTable from '../NestedTable';
import { Routes } from 'shared/enums/Routes';
import { INestedSection, ITableColumn } from 'shared/interfaces';
import { useWindowDimensions } from 'shared/utils/hooks';
import { LangNames } from 'shared/enums';

interface IBindingParametersSectionTableProps {
  sectionTableData: INestedSection[];
  columns: ITableColumn[];
  selectedSectionRow: number | null;

  tableHeight: string;
  loading: boolean;

  onSectionSelectRow: (selectedRow: INestedSection) => void;

  // collapsible parameters table
  children: ReactNode;

  isOpenExceptionParameter: boolean;
  onChangeExceptionAction: () => void;

  collapseBtnTitle: string;
  disabledActions?: boolean;
  isShowActions?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const PERCENT_PARAM_TABLE = 0.3;

const BindingParametersSectionTable: React.FC<IBindingParametersSectionTableProps> = (props) => {
  const {
    sectionTableData,
    tableHeight,
    columns,
    loading,
    selectedSectionRow,
    onSectionSelectRow,

    isOpenExceptionParameter,
    collapseBtnTitle,

    children,

    disabledActions,
    isShowActions = true,
    onChangeExceptionAction,
    onSubmit,
    onCancel,
  } = props;

  const { width } = useWindowDimensions();

  return (
    <ReflexContainer orientation="vertical">
      <ReflexElement style={{ paddingRight: '12px' }}>
        <Box sx={{ height: tableHeight }}>
          <NestedTable<INestedSection>
            columns={columns}
            tableData={sectionTableData}
            isLoading={loading}
            onSelectRow={(selectedRow) => onSectionSelectRow(selectedRow as INestedSection)}
            selectedRow={selectedSectionRow}
            isColorFirstLevel={false}
            isActionsHover
          />
        </Box>

        {isOpenExceptionParameter && (
          <Button
            sx={(theme) => ({
              position: 'absolute',
              top: '50%',
              zIndex: 1,
              right: window.location.pathname.includes(Routes.CATALOG)
                ? '-40px'
                : Translator.selectedLocalization === LangNames.Ru
                ? '-88px'
                : '-70px',
              transform: 'rotate(-90deg)',
              svg: { fill: theme.palette.common.white, fillOpacity: 1 },
              borderRadius: '6px 6px 0px 0px',
            })}
            variant="contained"
            size="small"
            color="primary"
            onClick={onChangeExceptionAction}
            startIcon={<ExpandIcon />}>
            {collapseBtnTitle}
          </Button>
        )}
      </ReflexElement>

      {isOpenExceptionParameter && <ReflexSplitter propagate style={{ width: '6px', borderRadius: '4px', border: 'none', height: 'unset' }} />}

      {isOpenExceptionParameter && (
        <ReflexElement minSize={width * PERCENT_PARAM_TABLE} size={width * PERCENT_PARAM_TABLE} style={{ paddingLeft: '12px' }}>
          <Stack gap={3} height="100%" justifyContent="flex-end">
            {children}

            {isShowActions && (
              <Stack justifyContent="flex-end" flexDirection="row" gap={4}>
                <Button disabled={disabledActions} variant="outlined" onClick={onCancel}>
                  {Translator.translate('actions.cancel')}
                </Button>

                <Button disabled={disabledActions} variant="contained" onClick={onSubmit}>
                  {Translator.translate('actions.save')}
                </Button>
              </Stack>
            )}
          </Stack>
        </ReflexElement>
      )}
    </ReflexContainer>
  );
};

export default observer(BindingParametersSectionTable);
