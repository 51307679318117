import React, { forwardRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldDataNode } from 'rc-tree/lib/interface';
import { classStore } from 'stores';
import { TreeData, ButtonItemTitle } from 'components/App/TreeData';
import { ClassModel } from 'shared/models';

interface IClassTreeItemProps {
  title: React.ReactNode;
  name: string;
  id: number | null;
  key: string | number;
}

declare type IClassTreeItem = FieldDataNode<IClassTreeItemProps>;

interface IClassTreeProps {
  height?: number;
  isChecked?: boolean;
  onCheck?: (checkedKeysValue: any, info: any) => void;
  onAdd?: (value: ClassModel) => void;
  onDelete?: (value: ClassModel) => void;
  onEdit?: (value: ClassModel) => void;
  onHistory?: (value: ClassModel) => void;
}

const ClassTree = forwardRef<IClassTreeProps, any>((props, ref) => {
  const { height, isChecked = false, onCheck, onAdd, onDelete, onEdit, onHistory } = props;

  const [currentNode, setCurrentNode] = useState<React.Key | null>();

  const onExpand = (expandedKeysValue: React.Key[]) => {
    classStore.treeObject.setExpandedKeys(expandedKeysValue);
  };

  const onSelect = (selectedKeysValue: React.Key[], info: any) => {
    classStore.treeObject.setSelectedKeys(selectedKeysValue, info);
  };

  const onHover = (key: React.Key | null) => {
    setCurrentNode(key);
  };

  const mapItems = (data: ClassModel[]): IClassTreeItem[] => {
    return data.map((item) => {
      const title = (
        <ButtonItemTitle
          title={item.title as string}
          item={item}
          searchValue={classStore.treeObject.clickSearch ? classStore.treeObject.searchValue : null}
          hovered={item.key === currentNode}
          onAdd={props.onAdd}
          onEdit={props.onEdit}
          onHistory={props.onHistory}
          onDelete={props.onDelete}
        />
      );

      if (item.children) {
        return {
          id: item.id,
          name: item.name,
          key: item.key,
          title: title,
          parentName: item.parentName,
          parentId: item.parentId,
          parents: item.parents,
          children: mapItems(item.children),
        };
      }

      return {
        id: item.id,
        name: item.name,
        key: item.key,
        title: title,
        parentName: item.parentName,
        parentId: item.parentId,
        parents: item.parents,
      };
    });
  };

  return (
    <TreeData
      treeData={mapItems(classStore.class)}
      ref={ref}
      expandedKeys={classStore.treeObject.expandedKeys}
      selectedKey={classStore.treeObject.selectedKeys}
      checkedKeys={classStore.treeObject.checkedKeys}
      onCheck={isChecked && onCheck ? onCheck : undefined}
      onSelect={onSelect}
      onExpand={onExpand}
      height={height}
      isCheckStrictly={true}
      onHover={onHover}
    />
  );
});

ClassTree.displayName = 'ClassTree';

export default observer(ClassTree);
