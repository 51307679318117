import React, { useEffect, useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { Translator, xmlDocumentInstanceStore } from 'stores';
import { DialogWrapper, Select } from 'components';
import { CircularProgress, Stack } from '@mui/material';
import { IXmlForm } from 'shared/interfaces';

const XMLDialog: React.FC = () => {
  const formRef = useRef<FormikProps<IXmlForm>>(null);
  const dialogTitle = Translator.translate('xml.addXMLTitleDialog');

  useEffect(() => {
    xmlDocumentInstanceStore.getDocumentsForOptions();

    return () => {
      xmlDocumentInstanceStore.selectedXMLDocument.clearModel();
      xmlDocumentInstanceStore.clearOptions();
    };
  }, []);

  const onSubmit = async (values: IXmlForm) => {
    xmlDocumentInstanceStore.selectedXMLDocument.updateXmlForm(values);

    await xmlDocumentInstanceStore.createXML();
    onClose();
  };

  const onClose = () => {
    xmlDocumentInstanceStore.setDialogOpen(false);
  };

  const xmlAddForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        templateId: xmlDocumentInstanceStore.selectedXMLDocument.templateId,

        templateOpt: xmlDocumentInstanceStore.documentForDefInstance,
      }}
      validationSchema={Yup.object().shape({
        templateId: Yup.number().required(Translator.translate('validationMessage.required')).nullable(),
      })}>
      {({ handleChange, handleBlur, values, errors, touched }) => {
        return (
          <Stack>
            <Select
              name="templateId"
              required
              label={Translator.translate('xml.dialogFormFields.templateId')}
              error={Boolean(touched.templateId && errors.templateId)}
              helperText={(touched.templateId as any) && (errors.templateId as any)}
              hideNotSelectItem
              value={values.templateId}
              options={values.templateOpt}
              onChange={handleChange}
              onBlur={handleBlur}
              size="medium"
            />
          </Stack>
        );
      }}
    </Formik>
  );

  return (
    <DialogWrapper
      title={dialogTitle}
      maxWidth="md"
      open={xmlDocumentInstanceStore.isDialogOpen}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}
      titleSubmit={Translator.translate('actions.save')}>
      {xmlDocumentInstanceStore.isLoading ? (
        <Stack height={56} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        xmlAddForm
      )}
    </DialogWrapper>
  );
};

export default observer(XMLDialog);
