import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldDataNode } from 'rc-tree';
import { parameterGroupStore } from 'stores';
import { TreeData } from 'components';
import { ButtonItemTitle, ParameterGroupTreeSearch } from 'components/App/TreeData';
import { Stack } from '@mui/material';
import { ParameterGroupModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

interface IParamTreeItemProps {
  title: React.ReactNode;
  name: string;
  id: number | null;
  key: string | number;
}

declare type IParamTreeItem = FieldDataNode<IParamTreeItemProps>;

const HEIGHT_CONTENT_WITH_SEARCH = 160;

interface IGroupsMergeProps {
  checkedKeys?: {
    checked: number[];
    halfChecked: number[];
  };
  onCheck?: (checkedKeysValue: any, info: any) => void;
}

const GroupsMerge: React.FC<IGroupsMergeProps> = (props) => {
  const { checkedKeys, onCheck } = props;

  const groupHeight = document.documentElement.clientHeight - HEIGHT_CONTENT_WITH_SEARCH + Utils.projectHeight();
  const [currentNode, setCurrentNode] = useState<number | null>();
  const parameterGroupRef = useRef<any>();
  const height = `calc(100vh - ${HEIGHT_CONTENT_WITH_SEARCH + Utils.projectHeight()}px)`;

  useEffect(() => {
    if (parameterGroupRef.current && parameterGroupStore.selectedParamGroup.id) {
      parameterGroupRef.current.scrollTo({ key: parameterGroupStore.selectedParamGroup.id });
    }
  }, [parameterGroupStore.selectedParamGroup.id]);

  const onExpand = (expandedKeysValue: React.Key[]) => {
    parameterGroupStore.setExpandedKeys(expandedKeysValue);
  };

  const onSelect = (selectedKey: React.Key[], info?: any) => {
    if (info.selectedNodes.length > 0) parameterGroupStore.selectedParamGroup = new ParameterGroupModel(info.selectedNodes[0]);
  };

  const onHover = (key: React.Key | null) => {
    setCurrentNode(key as number);
  };

  const mapItems = (data: ParameterGroupModel[]): IParamTreeItem[] => {
    return data.map((item) => {
      const title = (
        <ButtonItemTitle
          title={item.title as string}
          item={item}
          searchValue={parameterGroupStore.searchValue}
          hovered={Number(item.key) === currentNode}
          disabled={!item.isPresented}
        />
      );

      if (item.children) {
        return {
          id: item.id,
          name: item.name,
          isPresented: item.isPresented,
          key: item.key,
          title: title,
          children: mapItems(item.children),
        };
      }

      return {
        id: item.id,
        name: item.name,
        isPresented: item.isPresented,
        key: item.key,
        title: title,
      };
    });
  };

  const onScroll = (key: React.Key) => {
    parameterGroupRef.current.scrollTo({ key: key });
  };

  return (
    <Stack gap={4} height={height}>
      <ParameterGroupTreeSearch onScroll={onScroll} />

      <TreeData
        ref={parameterGroupRef}
        treeData={mapItems(parameterGroupStore.parameterGroupTree)}
        selectedKey={[parameterGroupStore.selectedParamGroup.id!]}
        onSelect={onSelect}
        expandedKeys={parameterGroupStore.expandedKeys}
        onExpand={onExpand}
        checkedKeys={checkedKeys}
        onCheck={onCheck}
        height={groupHeight}
        isCheckStrictly={true}
        onHover={onHover}
      />
    </Stack>
  );
};

export default observer(GroupsMerge);
