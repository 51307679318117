import React from 'react';

const ListIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="rgba(169, 171, 185, 1)" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.70711 9.70711L14.7071 3.70711C15.0976 3.31658 15.0976 2.68342 14.7071 2.29289C14.3166 1.90237 13.6834 1.90237 13.2929 2.29289L8 7.58579L6.70711 6.29289C6.31658 5.90237 5.68342 5.90237 5.29289 6.29289C4.90237 6.68342 4.90237 7.31658 5.29289 7.70711L7.29289 9.70711C7.68342 10.0976 8.31658 10.0976 8.70711 9.70711Z" />
      <path d="M4.03254 4.03254C4.05338 4.01171 4.08164 4 4.11111 4H9C9.55228 4 10 3.55228 10 3C10 2.44772 9.55228 2 9 2H4.11111C3.55121 2 3.01424 2.22242 2.61833 2.61833C2.22242 3.01424 2 3.55121 2 4.11111V11.8889C2 12.4488 2.22242 12.9858 2.61833 13.3817C3.01424 13.7776 3.55121 14 4.11111 14H11.8889C12.4488 14 12.9858 13.7776 13.3817 13.3817C13.7776 12.9858 14 12.4488 14 11.8889V9C14 8.44772 13.5523 8 13 8C12.4477 8 12 8.44772 12 9V11.8889C12 11.9184 11.9883 11.9466 11.9675 11.9675C11.9466 11.9883 11.9184 12 11.8889 12H4.11111C4.08164 12 4.05338 11.9883 4.03254 11.9675C4.01171 11.9466 4 11.9184 4 11.8889V4.11111C4 4.08164 4.01171 4.05338 4.03254 4.03254Z" />
    </svg>
  );
};

export default ListIcon;
