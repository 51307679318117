import { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldDataNode } from 'rc-tree';
import { Translator, parameterGroupStore } from 'stores';
import { IconButtonNew, TreeData } from 'components';
import { ButtonItemTitle, ParameterGroupTreeSearch, ParameterGroupTreeSelect } from 'components/App/TreeData';
import IconSearch from 'components/Icons/IconSearch';
import { Stack, Select as MuiSelect, Box, Fade } from '@mui/material';
import { ParameterGroupModel } from 'shared/models';

declare type IParamTreeItem = FieldDataNode<IParamTreeItemProps>;

interface IParamTreeItemProps {
  title: React.ReactNode;
  name: string;
  id: number | null;
  key: string | number;
}

interface IParameterGroupFilterProps {
  onChangeParamGroup?: (value: ParameterGroupModel) => void;
  value: number | null;
}

const ParameterGroupFilter: React.FC<IParameterGroupFilterProps> = (props) => {
  const { onChangeParamGroup, value } = props;

  const [isOpenSearch, setOpenSearch] = useState(false);
  const [openSelectParameterGroup, setOpenSelectParameterGroup] = useState(false);
  const parameterGroupRef = useRef<any>();

  const mapItems = (data: ParameterGroupModel[]): IParamTreeItem[] => {
    return data.map((item) => {
      const title = <ButtonItemTitle title={item.title as string} item={item} searchValue={parameterGroupStore.searchValue} />;

      if (item.children) {
        return {
          id: item.id,
          name: item.name,
          key: item.key,
          fullName: item.fullName,
          title: title,
          children: mapItems(item.children),
        };
      }

      return {
        id: item.id,
        name: item.name,
        key: item.key,
        fullName: item.fullName,
        title: title,
      };
    });
  };

  const onScroll = (key: React.Key) => {
    parameterGroupRef.current.scrollTo({ key: key });
  };

  const onSelect = (expandedKeysValue: React.Key[], info?: any) => {
    if (expandedKeysValue.length === 0) return;
    parameterGroupStore.selectedParamGroup = new ParameterGroupModel(info.selectedNodes[0]);
    onChangeParamGroup && onChangeParamGroup(parameterGroupStore.selectedParamGroup);

    if (value) setOpenSelectParameterGroup(false);
  };

  const onExpand = (expandedKeysValue: React.Key[]) => {
    parameterGroupStore.setExpandedKeys(expandedKeysValue);
  };

  return (
    <MuiSelect
      fullWidth
      displayEmpty
      size="small"
      sx={{
        backgroundColor: '#F2F2F9',
        boxShadow: 'none',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
        borderRadius: '6px',
      }}
      placeholder={Translator.translate('params.parameterGroup.title')}
      value={value}
      renderValue={() => parameterGroupStore.selectedParamGroup.fullName ?? Translator.translate('params.parameterGroup.title')}
      open={openSelectParameterGroup}
      onOpen={() => {
        if (parameterGroupRef.current && value) {
          parameterGroupRef.current.scrollTo({ key: value });
        }

        setOpenSelectParameterGroup(true);
      }}
      onClose={() => {
        setOpenSelectParameterGroup(false);
      }}>
      <Stack gap={2} padding={'0 8px'}>
        {!isOpenSearch && (
          <Stack height="38px" flexDirection="row" justifyContent="space-between" alignItems="center">
            <ParameterGroupTreeSelect onScroll={onScroll} onChangeParamGroup={onChangeParamGroup} />

            <IconButtonNew sx={{ mx: 2 }} onClick={() => setOpenSearch(true)}>
              <IconSearch />
            </IconButtonNew>
          </Stack>
        )}

        {isOpenSearch && (
          <Box height="38px">
            <Fade timeout={100} in={isOpenSearch}>
              <div>
                {isOpenSearch && (
                  <ParameterGroupTreeSearch
                    isExpanded
                    onScroll={onScroll}
                    onChangeParamGroup={onChangeParamGroup}
                    onCloseSearch={() => setOpenSearch(false)}
                  />
                )}
              </div>
            </Fade>
          </Box>
        )}

        <Box height="100%">
          {parameterGroupStore.parameterGroupTree.length !== 0 && (
            <TreeData
              height={300}
              ref={parameterGroupRef}
              isCheckStrictly={false}
              selectedKey={[parameterGroupStore.selectedParamGroup.id!]}
              onSelect={onSelect}
              onExpand={onExpand}
              expandedKeys={parameterGroupStore.expandedKeys}
              treeData={mapItems(parameterGroupStore.parameterGroupTree)}
            />
          )}
        </Box>
      </Stack>
    </MuiSelect>
  );
};

export default observer(ParameterGroupFilter);
