import React from 'react';
import { observer } from 'mobx-react-lite';
import { mergeStore } from 'stores';
import { MergeGroups } from 'shared/enums';
import { ItemMergeTable, ParameterValueMergeTable } from './Components';

const MergeTable: React.FC = () => {
  const getMergeData = (): JSX.Element => {
    switch (mergeStore.merge.currentGroupMerge) {
      case MergeGroups.Items: {
        return <ItemMergeTable />;
      }

      case MergeGroups.ParameterValues:
        return <ParameterValueMergeTable />;

      default:
        return <></>;
    }
  };

  return getMergeData();
};

export default observer(MergeTable);
