import { BASE_PATH } from '../shared/constants/constants';
import { IGetSectionSetCollectionDto, IPutSectionSetCollectionDto } from 'shared/interfaces';
import HTTPService from './HttpService';

class SectionSetCollectionService extends HTTPService {
  constructor() {
    super(BASE_PATH.SECTION_SET_COLLECTIONS);
  }

  public getAllSectionSetCollections(): Promise<IGetSectionSetCollectionDto[]> {
    return this.GET<IGetSectionSetCollectionDto[]>('');
  }

  public getSectionSetCollection(id: number): Promise<IGetSectionSetCollectionDto[]> {
    return this.GET('', { params: { id } });
  }

  public putSectionSetCollection(id: number, body: IPutSectionSetCollectionDto): Promise<IGetSectionSetCollectionDto> {
    return this.PUT(`${id}`, body);
  }
}

export default new SectionSetCollectionService();
