import { BASE_PATH } from 'shared/constants/constants';
import { IGetUserDto, IPutUserDto, IUserFilters } from 'shared/interfaces';
import HTTPService from './HttpService';

class UsersService extends HTTPService {
  constructor() {
    super(BASE_PATH.USERS);
  }

  public getUsers(params: IUserFilters): Promise<IGetUserDto[]> {
    return this.GET('', { params });
  }

  public getMe(): Promise<IGetUserDto> {
    return this.GET('me');
  }

  public putUser(id: number, body: IPutUserDto): Promise<void> {
    return this.PUT(`${id}`, body);
  }
}

export default new UsersService();
