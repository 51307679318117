import * as React from 'react';
import Box from '@mui/material/Box';
import composeClasses from '@mui/utils/composeClasses';
import {
  GridRenderCellParams,
  getDataGridUtilityClass,
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { DataGridProProcessedProps } from '@mui/x-data-grid-pro/models/dataGridProProps';
import { observer } from 'mobx-react-lite';

import { Translator } from 'stores';
import { IconButtonNew } from 'components';
import ExpandIcon from 'components/Icons/ExpandIcon';

interface OwnerState {
  classes: DataGridProProcessedProps['classes'];
}

const useUtilityClasses = (ownerState: OwnerState) => {
  const { classes } = ownerState;

  const slots = {
    root: ['treeDataGroupingCell'],
    toggle: ['treeDataGroupingCellToggle'],
  };

  return composeClasses(slots, getDataGridUtilityClass, classes);
};

interface CustomRowGroupingProps extends GridRenderCellParams<string, any> {
  hideDescendantCount?: boolean;
  children: React.ReactNode;
  isExpandToolTip?: boolean;
}

const CustomRowGrouping: React.FC<CustomRowGroupingProps> = (props): JSX.Element => {
  const { id, field, rowNode, children, isExpandToolTip = false } = props;

  const rootProps = useGridRootProps();
  const apiRef = useGridApiContext();
  const ownerState: OwnerState = { classes: rootProps.classes };
  const classes = useUtilityClasses(ownerState);
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);

  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick = () => {
    apiRef.current.setRowChildrenExpansion(id, !(rowNode.childrenExpanded ?? false));
    apiRef.current.setCellFocus(id, field);
  };

  return (
    <Box sx={{ cursor: rowNode.children ? 'pointer' : 'default' }} className={classes.root} onClick={handleClick}>
      {filteredDescendantCount > 0 && (
        <IconButtonNew
          disabled={!rowNode.children}
          tooltip={
            isExpandToolTip ? (rowNode.childrenExpanded ? Translator.translate('actions.collapse') : Translator.translate('actions.expand')) : ''
          }
          onClick={handleClick}
          style={{ transform: `rotate(${rowNode.childrenExpanded ? (!rowNode.children ? 270 : 0) : 270}deg)` }}>
          {<ExpandIcon />}
        </IconButtonNew>
      )}

      <Box component="span">
        {children ? children : rowNode.groupingKey}
        {filteredDescendantCount > 0 && rowNode.children!.filter((f) => typeof f === 'number').length > 0 ? ` (${filteredDescendantCount})` : ''}
      </Box>
    </Box>
  );
};

export default observer(CustomRowGrouping);
