import React from 'react';

const FileValueIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#A9ABB9" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1C2.89543 1 2 1.89543 2 3V13C2 14.1046 2.89543 15 4 15H12C13.1046 15 14 14.1046 14 13V6.41421C14 5.88378 13.7893 5.37507 13.4142 5L10 1.58579C9.62493 1.21071 9.11622 1 8.58579 1H4ZM8 3H4V13H12V7H10C8.89543 7 8 6.10457 8 5V3ZM10.5858 5L10 4.41421V5H10.5858Z"
      />
    </svg>
  );
};

export default FileValueIcon;
