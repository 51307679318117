import { BASE_PATH } from 'shared/constants/constants';
import {
  IPutSpecificationDto,
  IGetSpecificationDto,
  ISpecificationParamsDto,
  IGetFilesDto,
  IParameterGroupBlackListDto,
  IPostSpecificationCollectionDto,
} from 'shared/interfaces';
import HTTPService from './HttpService';

class SpecificationsService extends HTTPService {
  constructor() {
    super(BASE_PATH.SPECIFICATIONS);
  }

  public getSpecifications(params: ISpecificationParamsDto): Promise<IGetSpecificationDto[]> {
    return this.GET('', { params });
  }

  public getSpecification(id: number): Promise<IGetSpecificationDto[]> {
    return this.GET('', { params: { id } });
  }

  public postSpecificationCollection(body: IPostSpecificationCollectionDto): Promise<number> {
    return this.POST('collections', body);
  }

  public putSpecification(id: number, body: IPutSpecificationDto) {
    return this.PUT(`${id}`, body);
  }

  public deleteSpecificationCollection(id: number) {
    return this.DELETE(`collections/${id}`);
  }

  public downloadTemplateFile(id: number): Promise<void> {
    return this.GET(`templates/${id}`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream',
      },
    });
  }

  public postTemplateFile(id: number, body: FormData): Promise<number> {
    return this.POST(`${id}/templates`, body, { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  public getFiles(id: number): Promise<IGetFilesDto[]> {
    return this.GET(`${id}/files`, { params: { id } });
  }

  public downloadFile(id: number, specId: number): Promise<void> {
    return this.GET(`${specId}/files/${id}`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream',
      },
    });
  }

  public postFile(id: number, body: FormData): Promise<number> {
    return this.POST(`${id}/files`, body, { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  public deleteFile(id: number, specId: number): Promise<void> {
    return this.DELETE(`${specId}/files/${id}`);
  }

  public downloadDocument(id: number): Promise<void> {
    return this.GET(`${id}/document`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream',
      },
    });
  }

  public downloadDocumentApplication(id: number): Promise<void> {
    return this.GET(`${id}/document-with-applications`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream',
      },
    });
  }

  public getSpecificationParameters(id: number, sectionId: number): Promise<IParameterGroupBlackListDto[]> {
    return this.GET(`${id}/section/${sectionId}`);
  }

  public postSpecificationParameter(id: number, sectionId: number, parameterIds: number[]): Promise<void> {
    return this.POST(`${id}/section/${sectionId}`, parameterIds);
  }
}

export default new SpecificationsService();
