import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Select } from 'components';
import { SelectChangeEvent } from '@mui/material';
import { ISelectOption } from 'shared/interfaces';

export interface ICustomSelectProps {
  value: any;
  field: any;
  isLoading?: boolean;
  options: ISelectOption[];
  onChange: (e: SelectChangeEvent<any>) => void;
}

const CustomSelect: React.FC<ICustomSelectProps> = (props) => {
  const { value, field, isLoading, options, onChange } = props;
  const [open, setOpen] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Select
      fullWidth
      sx={{ width: '100%' }}
      size="small"
      value={value}
      isShowIconType={field === 'dataType'}
      options={options}
      open={open}
      isLoading={isLoading}
      onOpen={handleOpen}
      onClose={handleClose}
      hideNotSelectItem={field !== 'measurementUnitId'}
      onChange={onChange}
    />
  );
};

export default observer(CustomSelect);
