import React, { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Translator, parameterListValueStore, parameterStore, sectionSetStore, stageStore } from 'stores';
import { DocumentParamDialog, IconButtonNew, InputSearch, Select, Toggler } from 'components';
import { useConfirm } from 'components/App/Confirm';
import CopyIcon from 'components/Icons/CopyIcon';
import CutIcon from 'components/Icons/CutIcon';
import DownIcon from 'components/Icons/DownIcon';
import InsertIcon from 'components/Icons/InsertIcon';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import TrashIcon from 'components/Icons/TrashIcon';
import UpIcon from 'components/Icons/UpIcon';
import { Box, Button, Stack } from '@mui/material';
import { ActionTableMode, ParameterMode, ParameterModeNames } from 'shared/enums';
import { ParameterModel } from 'shared/models';
import { AddParameterDialog, ListValuesParamDialog, ParameterTable } from './Components';

const ParamTable: React.FC = () => {
  const confirm = useConfirm();

  useEffect(() => {
    parameterStore.parameters = [];
    parameterStore.checkedParameters = [];
    parameterStore.selectedParameterTable.clear();
    parameterStore.setLoadingParameters(true);
    parameterStore.getParameterGroupParameters().finally(() => parameterStore.setLoadingParameters(false));
  }, [parameterStore.filterParameters.parameterGroupId, parameterStore.filterParameters.stageId]);

  const mode = [
    {
      value: ParameterMode.Parameter,
      label: Translator.translate(ParameterModeNames.get(ParameterMode.Parameter)),
    },
    {
      value: ParameterMode.RelationSection,
      label: Translator.translate(ParameterModeNames.get(ParameterMode.RelationSection)),
    },
  ];

  const onChangeMode = (value: ParameterMode) => {
    parameterStore.filterParameters.setModeParameter(value);
  };

  const onClickSearch = () => {
    console.log('1');
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    parameterStore.filterParameters.setSearchPattern(event.target.value);
    parameterStore.selectedParameterTable.clear();
  };

  const onAdd = () => {
    parameterStore.setOpenAddParameterDialog(true);

    const paramGroupId = parameterStore.selectedParameterTable.groupId;
    const orderNum = parameterStore.selectedParameterTable.orderNum;

    parameterStore.selectedParameterTable = new ParameterModel();
    parameterStore.selectedParameterTable.setOrderNum(orderNum);
    parameterStore.selectedParameterTable.setGroupId(paramGroupId);
  };

  const onDelete = () => {
    const title =
      parameterStore.checkedParameters.length > 1
        ? Translator.translate('params.parameter.confirmMessage.deleteAllParameters')
        : Translator.translate('params.parameter.confirmMessage.deleteParameter');

    confirm.show(title, Translator.translate('actions.delete')).then(() => {
      parameterStore.deleteParameter();
    });
  };

  const updateParameter = useCallback(
    debounce(() => parameterStore.updateParam(), 350),
    []
  );

  const changeOrder = (order: 1 | -1) => {
    parameterStore.changeOrder(order);
    updateParameter();
  };

  const onCopy = () => {
    parameterStore.setCopyMoveParameterIds(parameterStore.getParameterIds);
    parameterStore.setActionParameterMode(ActionTableMode.Copy);
  };

  const canDisabledPaste = (): boolean => {
    // TODO: fix cut
    //при 'копировании' - вставить значение в эту же группу можно, при 'вырезать' - вставить значение в эту же группу нельзя
    return (
      !parameterStore.copyMoveParameterIds ||
      parameterStore.copyMoveParameterIds.length === 0 ||
      !parameterStore.filterParameters.parameterGroupId ||
      (parameterStore.actionParameterMode === ActionTableMode.Move &&
        parameterStore.filterParameters.parameterGroupId === parameterStore.currentGroupForChecked)
    );
  };

  const onPaste = () => {
    if (parameterStore.actionParameterMode === ActionTableMode.Copy) parameterStore.copyParameter();
    else parameterStore.moveParameter();
  };

  const onCut = () => {
    parameterStore.setCopyMoveParameterIds(parameterStore.getParameterIds);
    parameterStore.setActionParameterMode(ActionTableMode.Move);
  };

  const onChangeStage = (value: number) => {
    parameterStore.filterParameters.setStageId(value);
    sectionSetStore.setFilterStageId(parameterStore.filterParameters.stageId);
    sectionSetStore.getSectionSets();
  };

  const onSubmitDocument = async () => {
    await parameterStore.updateParameterDocument();
    parameterStore.selectDocumentByParameter.clear();
    parameterStore.setDocumentDialogOpen(false);
  };

  return (
    <>
      <Stack
        mb={parameterStore.filterParameters.modeParameter === ParameterMode.RelationSection ? 3 : 0}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap={5}>
        <Stack gap={5} flexDirection="row">
          <Toggler
            value={parameterStore.filterParameters.modeParameter}
            tabItems={mode}
            onChangeTab={(value: string | number) => onChangeMode(value as ParameterMode)}
          />

          {parameterStore.filterParameters.modeParameter === ParameterMode.RelationSection && (
            <Select
              sx={{
                maxWidth: '220px',
                width: '220px',
                backgroundColor: '#F2F2F9',
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                borderRadius: '6px',
              }}
              label={Translator.translate('params.selectStageLabel')}
              value={parameterStore.filterParameters.stageId}
              options={stageStore.stageForOptions}
              onChange={(e) => onChangeStage(e.target.value)}
              size="small"
              hideNotSelectItem
            />
          )}
        </Stack>

        <Box width={226}>
          <InputSearch value={parameterStore.filterParameters.searchPattern} onSearch={onClickSearch} onChange={onSearch} />
        </Box>
      </Stack>

      {parameterStore.filterParameters.modeParameter === ParameterMode.Parameter && (
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={2}
          my={3}
          sx={{
            svg: {
              width: 16,
              height: 16,
            },
          }}>
          <Button
            variant="text"
            size="small"
            onClick={onAdd}
            startIcon={
              <Stack
                sx={(theme) => ({
                  svg: {
                    fill: theme.palette.primary.main,
                  },
                })}>
                <PlusIconForButton />
              </Stack>
            }>
            {Translator.translate('actions.add')}
          </Button>

          <IconButtonNew onClick={onCopy} tooltip={Translator.translate('actions.copy')} disabled={parameterStore.checkedParameters.length === 0}>
            <CopyIcon />
          </IconButtonNew>

          <IconButtonNew onClick={onCut} tooltip={Translator.translate('actions.cut')} disabled={parameterStore.checkedParameters.length === 0}>
            <CutIcon />
          </IconButtonNew>

          <IconButtonNew onClick={onPaste} tooltip={Translator.translate('actions.past')} disabled={canDisabledPaste()}>
            <InsertIcon />
          </IconButtonNew>

          <IconButtonNew
            sx={(theme) => ({
              svg: {
                fill: theme.palette.primary.main,
                fillOpacity: 1,
              },
            })}
            tooltip={Translator.translate('actions.delete')}
            onClick={onDelete}
            disabled={parameterStore.checkedParameters.length < 1 || !parameterStore.filterParameters.parameterGroupId}>
            <TrashIcon />
          </IconButtonNew>

          <IconButtonNew
            sx={(theme) => ({
              svg: {
                fill: theme.palette.primary.main,
                fillOpacity: 1,
              },
            })}
            onClick={() => changeOrder(-1)}
            tooltip={Translator.translate('actions.up')}
            disabled={
              !parameterStore.selectedParameterTable.id || parameterStore.isSelectedParameterFirst || parameterStore.checkedParameters.length > 0
            }>
            <UpIcon />
          </IconButtonNew>

          <IconButtonNew
            sx={(theme) => ({
              svg: {
                fill: theme.palette.primary.main,
                fillOpacity: 1,
              },
            })}
            onClick={() => changeOrder(1)}
            tooltip={Translator.translate('actions.down')}
            disabled={
              !parameterStore.selectedParameterTable.id || parameterStore.isSelectedParameterLast || parameterStore.checkedParameters.length > 0
            }>
            <DownIcon />
          </IconButtonNew>
        </Stack>
      )}

      <ParameterTable />

      {parameterStore.selectedParameterTable.id && parameterListValueStore.isDialogOpen && <ListValuesParamDialog />}

      {parameterStore.isDocumentDialogOpen && (
        <DocumentParamDialog
          open={parameterStore.isDocumentDialogOpen}
          tableData={parameterStore.documentsByParameter}
          onDelete={(id: number) => {
            parameterStore.deleteDocument(id);
          }}
          onSelect={(documents) => parameterStore.addDocument(documents)}
          onSubmit={onSubmitDocument}
          onClose={() => {
            parameterStore.selectDocumentByParameter.clear();
            parameterStore.setDocumentDialogOpen(false);
          }}
        />
      )}
      {parameterStore.isDialogAddParameter && <AddParameterDialog />}
    </>
  );
};

export default observer(ParamTable);
