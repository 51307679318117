import React from 'react';

const UnPinIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#204999" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.1026 2.29031C11.7156 1.90323 11.088 1.90323 10.7009 2.29031C10.3138 2.6774 10.3138 3.30499 10.7009 3.69208L10 4.39296C9.61291 4.78004 9.61291 5.40763 10 5.79472C10.3871 6.1818 11.0147 6.1818 11.4018 5.79472L12.1026 5.09384L14.9062 7.89736L14.2053 8.59824C13.8182 8.98532 13.8182 9.61291 14.2053 10C14.5924 10.3871 15.22 10.3871 15.607 10L16.3079 9.29912C16.695 9.6862 17.3226 9.6862 17.7097 9.29912C18.0968 8.91203 18.0968 8.28444 17.7097 7.89736L12.1026 2.29031Z" />
      <path d="M5.79472 2.99119C5.40763 2.60411 4.78004 2.60411 4.39296 2.99119C4.00587 3.37828 4.00587 4.00587 4.39296 4.39296L15.607 15.607C15.9941 15.9941 16.6217 15.9941 17.0088 15.607C17.3959 15.22 17.3959 14.5924 17.0088 14.2053L5.79472 2.99119Z" />
      <path d="M4.39296 7.19648C4.78004 6.80939 5.40763 6.80939 5.79472 7.19648L12.8035 14.2053C13.1906 14.5924 13.1906 15.22 12.8035 15.607C12.4164 15.9941 11.7888 15.9941 11.4018 15.607L8.59824 12.8035L3.69208 17.7097C3.30499 18.0968 2.6774 18.0968 2.29031 17.7097C1.90323 17.3226 1.90323 16.695 2.29031 16.3079L7.19648 11.4018L4.39296 8.59824C4.00587 8.21115 4.00587 7.58356 4.39296 7.19648Z" />
    </svg>
  );
};

export default UnPinIcon;
