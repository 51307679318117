import i18n from 'i18next';
import { makeAutoObservable } from 'mobx';
import { initReactI18next } from 'react-i18next';
import translationEn from '../locales/en/translation.json';
import translationRu from '../locales/ru/translation.json';
import { LangNames } from 'shared/enums';

const resources = {
  [LangNames.Ru]: {
    translation: translationRu,
  },
  [LangNames.En]: {
    translation: translationEn,
  },
};

class Translator {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.translator.use(initReactI18next).init({
      supportedLngs: [LangNames.Ru, LangNames.En],
      fallbackLng: false,
      resources,
      lng: LangNames.Ru,
      keySeparator: '.',
    });
  }

  private translator = i18n;

  public selectedLocalization = LangNames.Ru;

  public init() {
    const selectedLang = JSON.parse(localStorage.getItem('localization')!);

    if (selectedLang) {
      this.onChangeLocalization(selectedLang as LangNames);
    } else {
      localStorage.setItem('localization', JSON.stringify(LangNames.Ru));
    }
  }

  public async onChangeLocalization(localization: LangNames) {
    localStorage.setItem('localization', JSON.stringify(localization));
    this.selectedLocalization = localization;
    await this.translator.changeLanguage(localization);
  }

  public translate(str?: string) {
    return this.selectedLocalization && str && this.translator.exists(str) ? this.translator.t(str) : this._getMissingKeys();
  }

  private _getMissingKeys() {
    return this.translator.t('system.notFoundTranslate');
  }
}

export default new Translator();
