import React, { useEffect, useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { Translator, parameterGroupStore, parameterStore } from 'stores';
import { DialogWrapper, InputValidate } from 'components';
import { IParamGroupForm } from 'shared/interfaces';
import { ParameterGroupModel } from 'shared/models';

const ParameterGroupDialog = () => {
  const formRef = useRef<FormikProps<IParamGroupForm>>(null);

  useEffect(() => {
    return () => {
      parameterGroupStore.selectedParamGroup.clearModel();
    };
  }, []);

  const onSubmit = async (values: IParamGroupForm) => {
    if (!parameterGroupStore.selectedParamGroup.name) {
      parameterGroupStore.selectedParamGroup.updateParameterGroupDialogForm(values);

      if (parameterGroupStore.isEditRootParameterGroup) {
        await parameterGroupStore.createRootParamGroup();
        parameterGroupStore.initFilterStateParamGroup();
      } else {
        await parameterGroupStore.createParameterGroup();
        await parameterGroupStore.getParameterGroups();
      }

      reset();
    } else {
      parameterGroupStore.selectedParamGroup.updateParameterGroupDialogForm(values);

      await parameterGroupStore.updateParameterGroup();
      if (parameterGroupStore.selectedParamGroup.parentId === null) await parameterGroupStore.getRootParameterGroupList();

      await parameterGroupStore.getParameterGroups();

      //Сохранение селекта в дереве после редактирования
      const findKey = parameterGroupStore.listTransformParamGroupDto.find((item) => item.id === parameterGroupStore.selectedParamGroup.id);
      parameterGroupStore.selectedParamGroup = new ParameterGroupModel(findKey);

      reset();
    }
  };

  const onClose = () => {
    const findKey = parameterGroupStore.listTransformParamGroupDto.find((item) => item.id === parameterStore.filterParameters.parameterGroupId);
    parameterGroupStore.selectedParamGroup = new ParameterGroupModel(findKey);

    reset();
  };

  const reset = () => {
    parameterGroupStore.setEditRootParameterGroup(false);
    parameterGroupStore.setIsDialogOpen(false);
  };

  const dialogTitle = parameterGroupStore.selectedParamGroup.name
    ? `${Translator.translate('params.parameterGroup.parameterGroupDialog.editTitle')}: ${parameterGroupStore.parameterGroupById?.name}`
    : Translator.translate('params.parameterGroup.parameterGroupDialog.createTitle');

  const parameterGroupForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        name: parameterGroupStore.selectedParamGroup.name ? parameterGroupStore.selectedParamGroup.name : '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required(Translator.translate('validationMessage.required')),
      })}>
      {({ handleChange, handleBlur, values, errors, touched }) => {
        return (
          <InputValidate
            label={Translator.translate('params.parameterGroup.parameterGroupDialog.dialogFormFields.name')}
            name="name"
            required
            autoFocus
            error={Boolean(touched.name && errors.name)}
            helperText={(touched.name as any) && errors.name}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        );
      }}
    </Formik>
  );

  return (
    <DialogWrapper
      title={dialogTitle}
      maxWidth="md"
      open={parameterGroupStore.isDialogOpen}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}
      onCancel={onClose}
      onClose={onClose}>
      {parameterGroupForm}
    </DialogWrapper>
  );
};

export default observer(ParameterGroupDialog);
