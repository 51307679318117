import React, { forwardRef, useEffect, useImperativeHandle, useState, MouseEvent } from 'react';
import { useLocation } from 'react-router-dom';
import DateValueIcon from 'components/Icons/DateValueIcon';
import NumberValueIcon from 'components/Icons/NumberValueIcon';
import RangeValueIcon from 'components/Icons/RangeValueIcon';
import TextValueIcon from 'components/Icons/TextValueIcon';
import Input from 'components/UI/Input';
import { Stack } from '@mui/material';
import { TableView, CustomDatePicker } from '../../index';
import { DataType } from 'shared/enums';
import { Routes } from 'shared/enums/Routes';
import { IEditableTableColumn, ITableColumn } from 'shared/interfaces';
import { RangeEditor, SelectEditor } from './editors';

import { getColumnDataType, isColumnEditable } from './utils';

// TODO: добавить тип
export const EditableTable = forwardRef((props: any, ref) => {
  const [page, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editable, setEditable] = useState<boolean>(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [selectedColumn, setSelectedColumn] = useState<string | null>(null);
  const [columns, setColumns] = useState<IEditableTableColumn[]>([]);

  const location = useLocation();

  const RenderComponent = props.tableComponent ?? TableView;

  useEffect(() => {
    window.addEventListener('keyup', handleKeyEsc);

    return () => {
      window.removeEventListener('keyup', handleKeyEsc);
    };
  }, []);

  useEffect(() => {
    updateColumns();
  }, [props.columns, editable, selectedRowIndex, selectedColumn]);

  useImperativeHandle(
    ref,
    () => {
      return {
        startEditCell: (row: number, column: string) => {
          setSelectedRowIndex(row);
          setSelectedColumn(column);
          setEditable(true);
        },
        cancelEditing,
      };
    },
    []
  );

  const handleKeyEsc = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      setEditable(false);
    }
  };

  function cancelEditing() {
    setTimeout(() => {
      setEditable(false);
      setSelectedColumn(null);
    });
  }

  function updateColumns() {
    // TODO: добавить тип
    const columns: ITableColumn[] = props.columns.map((col: any) => {
      return {
        ...col,
        cell: (row: any, index: number) => {
          const isCurrentRow = props.isSelectedById ? selectedRowIndex === row.id : selectedRowIndex === index;
          const isCurrentCell = isCurrentRow && selectedColumn === col.key;
          const isEditMode = isColumnEditable(col, row) && ((editable && isCurrentCell) || !row.id);

          const colDataType = getColumnDataType(col, row);
          const colValue = col.getValue ? col.getValue(row) : row[col.key];

          const handleChange = (value: any) => {
            if (col.required && !value) {
              onEditorBlur();
            }

            if (value !== colValue) {
              col.handleChange && col.handleChange({ value, row, index });
              cancelEditing();
            } else cancelEditing();
          };

          const onEditorBlur = () => {
            cancelEditing();

            if (props.onEditorBlur) {
              props.onEditorBlur({ row, column: col, rowIndex: index });
            }
          };

          if (isEditMode) {
            if (col.customEditor && Boolean(col.customEditor(row))) {
              return col.customEditor(row);
            }

            if (colDataType === DataType.List) {
              return (
                <SelectEditor
                  value={colValue}
                  isShowIconType={true}
                  options={col.listOptions ?? []}
                  label={col.label}
                  onChange={handleChange}
                  onBlur={onEditorBlur}
                  onClose={cancelEditing}
                />
              );
            }

            if (colDataType === DataType.Number) {
              return (
                <Stack flexDirection="row" gap={1} alignItems="center">
                  {location.pathname === Routes.OBJECT_VALUE && <NumberValueIcon />}
                  <Input
                    type="number"
                    variant="standard"
                    autoComplete="off"
                    canFocus={isCurrentCell}
                    value={colValue}
                    onChange={handleChange}
                    onBlur={onEditorBlur}
                    required={Boolean(col.required)}
                    inputProps={{ step: '.1' }}
                  />
                </Stack>
              );
            }

            if (colDataType === DataType.Range) {
              return (
                <Stack flexDirection="row" gap={1} alignItems="center">
                  <RangeValueIcon />
                  <RangeEditor value={colValue} onChange={handleChange} />
                </Stack>
              );
            }

            if (colDataType === DataType.DateTime) {
              return (
                <Stack flexDirection="row" gap={1} alignItems="center">
                  <DateValueIcon />
                  <CustomDatePicker value={colValue} submitOnBlur onSubmit={handleChange} onCancel={onEditorBlur} />
                </Stack>
              );
            }

            return (
              <Stack flexDirection="row" gap={1} alignItems="flex-start">
                {location.pathname === Routes.OBJECT_VALUE && <TextValueIcon />}
                <Input
                  variant="standard"
                  multiline
                  canFocus={isCurrentCell}
                  autoComplete="off"
                  value={colValue}
                  onChange={handleChange}
                  onBlur={onEditorBlur}
                  required={Boolean(col.required)}
                />
              </Stack>
            );
          }

          return col?.cell ? col?.cell(row, index) : row[col.key];
        },
      };
    });

    setColumns(columns);
  }

  const onDoubleClick = (row: any, column: IEditableTableColumn, index: number) => {
    props.onDoubleClick && props.onDoubleClick(row, column, index);
  };

  const onClick = (row: any, column: IEditableTableColumn, rowIndex: number, e?: MouseEvent<HTMLTableCellElement>) => {
    if (isColumnEditable(column, row)) {
      if (row.groupId !== null) setEditable(true);
    }

    setSelectedRowIndex(props.isSelectedById ? row.id : rowIndex);
    setSelectedColumn(column.key);
    props.onClick && props.onClick(row, column, rowIndex, e);
  };

  const onChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setNewPage(0);

    props.onChangeRowsPerPage && props.onChangeRowsPerPage(value);
  };

  return (
    <RenderComponent
      {...props}
      columns={columns}
      page={page}
      rowsPerPage={rowsPerPage}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      setPage={setNewPage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  );
});

EditableTable.displayName = 'EditableTable';
