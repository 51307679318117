import { BASE_PATH } from 'shared/constants/constants';
import { IDefinitionCopyDto, IDefinitionGetDto, IDefinitionPostPutDto, IDefinitionsGetDto, IMergeDto, IPostMergeDto } from 'shared/interfaces';
import HTTPService from './HttpService';

class DefinitionService extends HTTPService {
  constructor() {
    super(BASE_PATH.DEFINITIONS);
  }

  public getAllDefinitions(projectId?: number): Promise<IDefinitionsGetDto[]> {
    const params = {
      projectId: projectId ?? '',
    };

    return this.GET<IDefinitionsGetDto[]>('', { params });
  }

  public getDefinition(id: number): Promise<IDefinitionGetDto> {
    return this.GET<IDefinitionGetDto>(`${id}`);
  }

  public postDefinition(body: IDefinitionPostPutDto): Promise<number> {
    return this.POST('', body);
  }

  public copyDefinition(body: IDefinitionCopyDto): Promise<number> {
    return this.POST('copy', body);
  }

  public putDefinition(id: number, body: IDefinitionPostPutDto) {
    return this.PUT(`${id}`, body);
  }

  public deleteDefinition(id: number) {
    return this.DELETE(`${id}`);
  }

  public getAllMerges(targetDefinitionId: number, sourceDefinitionId: number): Promise<IMergeDto> {
    const params = {
      sourceDefinitionId,
      targetDefinitionId,
    };

    return this.GET<IMergeDto>('merge', { params });
  }

  public postMerge(body: IPostMergeDto): Promise<void> {
    return this.POST('merge', body);
  }
}

export default new DefinitionService();
