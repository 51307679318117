import React from 'react';

const XXCurveIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5303 1.53033C10.8232 1.23744 10.8232 0.762563 10.5303 0.46967C10.2374 0.176777 9.76256 0.176777 9.46967 0.46967L7 2.93934L4.53033 0.46967C4.23744 0.176777 3.76256 0.176777 3.46967 0.46967C3.17678 0.762563 3.17678 1.23744 3.46967 1.53033L5.93934 4L3.46967 6.46967C3.17678 6.76256 3.17678 7.23744 3.46967 7.53033C3.76256 7.82322 4.23744 7.82322 4.53033 7.53033L7 5.06066L9.46967 7.53033C9.76256 7.82322 10.2374 7.82322 10.5303 7.53033C10.8232 7.23744 10.8232 6.76256 10.5303 6.46967L8.06066 4L10.5303 1.53033Z" />
      <path d="M13.5303 9.53033C13.8232 9.23744 13.8232 8.76256 13.5303 8.46967C13.2374 8.17678 12.7626 8.17678 12.4697 8.46967L10 10.9393L7.53033 8.46967C7.23744 8.17678 6.76256 8.17678 6.46967 8.46967C6.17678 8.76256 6.17678 9.23744 6.46967 9.53033L8.93934 12L6.46967 14.4697C6.17678 14.7626 6.17678 15.2374 6.46967 15.5303C6.76256 15.8232 7.23744 15.8232 7.53033 15.5303L10 13.0607L12.4697 15.5303C12.7626 15.8232 13.2374 15.8232 13.5303 15.5303C13.8232 15.2374 13.8232 14.7626 13.5303 14.4697L11.0607 12L13.5303 9.53033Z" />
    </svg>
  );
};

export default XXCurveIcon;
