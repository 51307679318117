import { BASE_PATH } from 'shared/constants/constants';
import { IClassDto, IClassParamsDto, ICreateClassesCommandDto, IUpdateClassDto } from 'shared/interfaces';
import HTTPService from './HttpService';

class ClassService extends HTTPService {
  constructor() {
    super(BASE_PATH.OBJECT);
  }

  public getAllClasses(params: IClassParamsDto): Promise<IClassDto[]> | undefined {
    return this.GET<IClassDto[]>('', { params });
  }

  public getClassChildren(params: IClassParamsDto): Promise<IClassDto[]> {
    return this.GET<IClassDto[]>('children', { params });
  }

  public getClassById(id: number): Promise<IClassDto> {
    return this.GET<IClassDto>(`${id}`);
  }

  public postClass(body: ICreateClassesCommandDto): Promise<number[]> {
    return this.POST('', body);
  }

  public putClass(id: number, body: IUpdateClassDto) {
    return this.PUT(`${id}`, body);
  }

  public deleteClass(id: number) {
    return this.DELETE(`${id}`);
  }
}

export default new ClassService();
