import { makeAutoObservable, runInAction } from 'mobx';
import { arrayToTree } from 'performant-array-to-tree';
import { SectionsService } from 'services';
import { Translator, classStore, sectionParameterStore, sectionsStore, toastStore } from 'stores';
import { Routes } from 'shared/enums/Routes';
import { IGetSectionDto, INestedSection } from 'shared/interfaces';
import { SectionModel } from 'shared/models';
import Utils from 'shared/utils/Utils';
class SectionsStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public isDialogLoading = false;

  public isDialogOpen = false;

  public isEditObjectDialogOpen = false;

  public sections: SectionModel[] = [];

  public nestedSections: INestedSection[] = [];

  public selectedSectionRow: number | null = null;

  public sectionsTree: any[] = [];

  public selectedSection = new SectionModel();

  public filterSectionSetId: number | null = null;

  public isOpenParameters = false;

  public setSelectedSectionRow(id: number | null) {
    this.selectedSectionRow = id;
  }

  public setOpenParameters(isOpen: boolean) {
    this.isOpenParameters = isOpen;
  }

  public setDialogOpen(isOpen: boolean) {
    this.isDialogOpen = isOpen;
  }

  public setEditObjectDialogOpen(isOpen: boolean) {
    this.isEditObjectDialogOpen = isOpen;
  }

  public async setEditingMode(id: number) {
    this.setDialogOpen(true);
    this.isDialogLoading = true;

    await this.getSectionById(id);
    classStore.setSelectedLayer();
    await classStore.getClasses();

    this.isDialogLoading = false;
  }

  public setCreatingSubsectionMode(id: number) {
    this.setDialogOpen(true);
    this.selectedSection.setParentId(id);
  }

  public setFilterSectionSetId(sectionSetId: number | null) {
    this.filterSectionSetId = sectionSetId;
  }

  //возвращает плоский список, отсортированный по orderNum и в порядке = узлам дерева
  public sortSectionsByTreeNode(sections: INestedSection[]): IGetSectionDto[] {
    const sortSection: IGetSectionDto[] = [];

    const loop = (data: any[]) =>
      data.forEach((item) => {
        if (item.children.length !== 0) {
          sortSection.push(item);
          loop(item.children);
        } else sortSection.push(item);
      });

    loop(sections);

    return sortSection;
  }

  public createTreeData(dto: IGetSectionDto[]): any[] {
    const transformDto: any[] = [];

    dto.forEach((value) => {
      const newItem: any = {
        ...value,
      };

      transformDto.push(newItem);
    });

    const treeData = arrayToTree(transformDto, { dataField: null });

    const loop = (data: any[]): any[] =>
      data.map((item: any) => {
        if (item.children) {
          item.children = Utils.sortByField(item.children, 'orderNum');
          return {
            ...item,
            children: loop(item.children),
          };
        }

        return {
          ...item,
        };
      });

    return loop(treeData);
  }

  public async getSections(): Promise<void> {
    try {
      this.isLoading = true;

      const result = await SectionsService.getAllSections(this.filterSectionSetId);
      if (!result) return;

      const treeData = arrayToTree(result, { dataField: null, childrenField: 'children' }) as INestedSection[];

      const sortData = Utils.sortTreeByOrderNum(treeData, 'children');

      this.sectionsTree = this.createTreeData(result);

      this.nestedSections = sortData as INestedSection[];
      //добавление уровней
      this.nestedSections.forEach((item) => {
        item.level = 1;
        if (item.children.length !== 0) {
          item.children.forEach((secondLevelItem) => {
            secondLevelItem.level = 2;
            if (secondLevelItem.children!.length !== 0) {
              secondLevelItem.children!.forEach((thirdLevelItem) => {
                thirdLevelItem.level = 3;
              });
            }
          });
        }
      });

      if (window.location.pathname.includes(Routes.CATALOG)) {
        sectionsStore.setSelectedSectionRow(sectionsStore.nestedSections.length > 0 ? sectionsStore.nestedSections[0].id : null);
        sectionsStore.selectedSectionRow && sectionParameterStore.getParametersSection();
      }

      runInAction(() => {
        this.sections = this.sortSectionsByTreeNode(sortData as INestedSection[]).map((section) => new SectionModel(section));
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Sections`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getSectionById(id: number): Promise<void> {
    if (!id) return;

    try {
      const result = await SectionsService.getSection(id);
      if (!result) return;

      runInAction(() => {
        this.selectedSection = new SectionModel(result[0]);
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} Section`);
    } finally {
    }
  }

  public async createSection(): Promise<void> {
    try {
      await SectionsService.postSection(this.selectedSection.postDto(this.filterSectionSetId!)!);
      this.getSections();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} Section`);
    }
  }

  public async updateSection(): Promise<void> {
    if (!this.selectedSection.id || !this.selectedSection.putDto()) return;

    try {
      await SectionsService.putSection(this.selectedSection.id, this.selectedSection.putDto()!);
      this.getSections();
      this.selectedSection = new SectionModel();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} Section`);
    }
  }

  public async removeSection(id: number): Promise<void> {
    try {
      await SectionsService.deleteSection(id);
      this.getSections();
      this.setSelectedSectionRow(null);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} Section`);
    }
  }
}

export default new SectionsStore();
