import React, { useEffect, useState, useRef } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { observer } from 'mobx-react-lite';
import { Translator, classStore } from 'stores';
import { ClassTree, DialogWrapper, IconButtonNew, InputSearch, Toggler } from 'components';
import IconSearch from 'components/Icons/IconSearch';
import { Box, Stack, Fade, IconButton, Chip, Typography } from '@mui/material';
import { ObjectLayer, ObjectLayerNames } from 'shared/enums';
import { IClasses, ISearch } from 'shared/interfaces';

interface IEditClassSectionProps {
  isDialogOpen: boolean;
  classConstraints: IClasses[];
  isEditXMLNode: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onCheckClass: (checkedKeysValue: any, info: any) => void;
  onDeleteClass: (e: React.MouseEvent, data: IClasses) => void;
}

const EditClassSectionDialog: React.FC<IEditClassSectionProps> = (props) => {
  const { isDialogOpen, classConstraints, isEditXMLNode, onSubmit, onClose, onCheckClass, onDeleteClass } = props;

  const dialogTitle = Translator.translate('common.classFilter.editClassSectionDialogTitle');

  const classRef = useRef<any>();
  const [isOpenSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    classStore.setSelectedLayer(ObjectLayer.Main);
    isEditXMLNode ? classStore.filterClassForXMLNode() : classStore.filterClassForSection();
  }, []);

  const layerFilter = [
    {
      value: ObjectLayer.Main,
      label: Translator.translate(ObjectLayerNames.get(ObjectLayer.Main)),
    },
    {
      value: ObjectLayer.Additional,
      label: Translator.translate(ObjectLayerNames.get(ObjectLayer.Additional)),
    },
  ];

  const renderValues = (): React.ReactNode => {
    return (
      <Stack flexWrap="wrap" flexDirection="row" gap={2} overflow="auto">
        {classConstraints.map((value) => {
          const getLabel = (): React.ReactNode => {
            if (value.parentName !== '') {
              return (
                <Box component="span">
                  {value.className}
                  <Box component="span" color="text.secondary">
                    {value.secondParentName
                      ? `(${value.secondParentName}\u{2794}${value.parentName})`
                      : value.parentName
                      ? `(${value.parentName})`
                      : ''}
                  </Box>
                </Box>
              );
            } else {
              return value.className;
            }
          };

          return (
            <Chip
              sx={{ width: 'fit-content' }}
              key={value.classKey}
              clickable
              label={getLabel()}
              deleteIcon={
                <IconButton
                  sx={{
                    padding: 0,
                    svg: {
                      fill: 'rgba(0, 0, 0, 0.26)',
                      width: '16px',
                      height: '16px',
                    },
                  }}
                  disableRipple
                  onMouseDown={(event: any) => event.stopPropagation()}>
                  <CancelIcon />
                </IconButton>
              }
              onDelete={(e) => onDeleteClass(e, value)}
              size="small"
            />
          );
        })}
      </Stack>
    );
  };

  const onChangeLayer = (value: ObjectLayer) => {
    classStore.treeObject.clearModel();
    classStore.setSelectedLayer(value);
    classStore.filterClassForSection();

    if (classStore.treeObject.searchValue !== '') onClickSearch();
  };

  const onClickSearch = () => {
    const findNodes: ISearch[] = [];
    const newExpandedKeys = classStore.treeObject.expandedKeys;

    classStore.treeObject.getKeys(classStore.treeObject.data).map((item) => {
      if (item.title.toLowerCase().indexOf(classStore.treeObject.searchValue.toLowerCase()) > -1) {
        if (classStore.treeObject.searchValue !== '') {
          findNodes.push({ title: item.title, key: item.key });
          const parentsKeys = classStore.treeObject.getParentsKeys(item.key, classStore.treeObject.data);
          parentsKeys.forEach((parentKey) => {
            if (!newExpandedKeys.includes(parentKey)) newExpandedKeys.push(parentKey);
          });
        }
      }
    });

    classStore.treeObject.setClickSearch(true);
    classStore.treeObject.setSearchArrays(findNodes);
    classStore.treeObject.setExpandedKeys(newExpandedKeys as React.Key[]);

    if (classStore.treeObject.searchArrays.length !== 0) {
      const key = classStore.treeObject.searchArrays[0].key;
      const info = {
        selectedNodes: [classStore.allClass.find((f) => f.key === key)],
      };

      classStore.treeObject.setSelectedKeys([key], info);
      classRef.current.scrollTo({ key: key });
    }
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    classStore.treeObject.setSearchValue(event.target.value);
    classStore.treeObject.setClickSearch(false);

    if (event.target.value === '') onClickSearch();
  };

  const firstAndLastKey = (): { first: boolean; last: boolean } => {
    const first = classStore.treeObject.searchArrays[0].key;
    const last = classStore.treeObject.searchArrays[classStore.treeObject.searchArrays.length - 1].key;

    if (classStore.treeObject.selectedKeys[0] === first) return { first: true, last: false };
    else if (classStore.treeObject.selectedKeys[0] === last) return { first: false, last: true };

    return { first: false, last: false };
  };

  const onUpSearch = () => {
    const findIndex = classStore.treeObject.searchArrays.findIndex((item) => item.key === classStore.treeObject.selectedKeys[0]);

    const key = classStore.treeObject.searchArrays[findIndex - 1].key;
    const info = {
      selectedNodes: [classStore.allClass.find((f) => f.key === key)],
    };

    classStore.treeObject.setSelectedKeys([key], info);
    classRef.current.scrollTo({ key: key });
  };

  const onDownSearch = () => {
    const findIndex = classStore.treeObject.searchArrays.findIndex((item) => item.key === classStore.treeObject.selectedKeys[0]);

    const key = classStore.treeObject.searchArrays[findIndex + 1].key;
    const info = {
      selectedNodes: [classStore.allClass.find((f) => f.key === key)],
    };

    classStore.treeObject.setSelectedKeys([key], info);
    classRef.current.scrollTo({ key: key });
  };

  return (
    <DialogWrapper title={dialogTitle} maxWidth="md" open={isDialogOpen} onClose={onClose} onSubmit={onSubmit} onCancel={onClose}>
      <Stack
        sx={{
          width: '100%',
          height: 'auto',
          overflow: 'hidden',
        }}>
        {classConstraints.length > 0 ? (
          renderValues()
        ) : (
          <Typography
            variant="body1"
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}>
            {Translator.translate('common.classFilter.name')}
          </Typography>
        )}
      </Stack>

      <Stack gap={2} padding={'0 8px'}>
        {!isOpenSearch && (
          <Stack height="38px" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Toggler
              fullWidth
              value={classStore.classFilters.layerId}
              tabItems={layerFilter}
              onChangeTab={(value) => onChangeLayer(value as ObjectLayer)}
            />

            <IconButtonNew sx={{ mx: 2 }} onClick={() => setOpenSearch(true)}>
              <IconSearch />
            </IconButtonNew>
          </Stack>
        )}

        {isOpenSearch && (
          <Box height="38px">
            <Fade timeout={100} in={isOpenSearch}>
              <div>
                {isOpenSearch && (
                  <InputSearch
                    isExpanded
                    value={classStore.treeObject.searchValue}
                    isArrayResult={classStore.treeObject.searchArrays.length > 1}
                    disabledPrev={classStore.treeObject.searchArrays.length <= 1 || firstAndLastKey().first}
                    disabledNext={classStore.treeObject.searchArrays.length <= 1 || firstAndLastKey().last}
                    onPrev={onUpSearch}
                    onNext={onDownSearch}
                    onSearch={onClickSearch}
                    onChange={onSearch}
                    onClose={() => setOpenSearch(false)}
                  />
                )}
              </div>
            </Fade>
          </Box>
        )}

        <ClassTree ref={classRef} height={380} isChecked onCheck={onCheckClass} />
      </Stack>
    </DialogWrapper>
  );
};

export default observer(EditClassSectionDialog);
