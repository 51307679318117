import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, stageStore, toastStore } from 'stores';
import { Input, DialogWrapper } from 'components';
import { CircularProgress, Box } from '@mui/material';

const StageDialog: React.FC = () => {
  const { selectedStage, isStageLoading, isDialogOpen } = stageStore;

  useEffect(() => {
    if (selectedStage.id) {
      stageStore.getStageById(selectedStage.id);
    }

    return () => {
      selectedStage.clear();
    };
  }, []);

  const dialogTitle = selectedStage.id
    ? Translator.translate('catalogs.stages.editTitleDialog')
    : Translator.translate('catalogs.stages.addTitleDialog');

  const onSubmit = () => {
    if (selectedStage.name === '') {
      toastStore.showWarning(Translator.translate('catalogs.validationMessage'));
      return;
    }

    if (!selectedStage.id) {
      stageStore.createStage().then(() => onClose());
    } else {
      stageStore.updateStage().then(() => onClose());
    }
  };

  const onChangeName = (value: string) => {
    selectedStage.setName(value);
  };

  const onClose = () => {
    stageStore.setDialogOpen(false);
    selectedStage.clear();
  };

  return (
    //TODO: Add formik
    <DialogWrapper title={dialogTitle} maxWidth="md" open={isDialogOpen} onClose={onClose} onSubmit={onSubmit} onCancel={onClose}>
      <>
        {isStageLoading ? (
          <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Input label={Translator.translate('catalogs.stages.columns.name')} required value={selectedStage.name} onChange={onChangeName} />
          </>
        )}
      </>
    </DialogWrapper>
  );
};

export default observer(StageDialog);
