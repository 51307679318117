import React from 'react';
import { IGroupSettingList } from 'shared/interfaces';

import NestedItemRow from './Components';

interface INestedItemsProps {
  data: IGroupSettingList[];
  onSelectRow: (selectedRow: IGroupSettingList) => void;
}

const NestedItems = (props: INestedItemsProps) => {
  const { data, onSelectRow } = props;

  return (
    <>
      {data.map((data) => (
        <NestedItemRow key={data.key} row={data} onSelectRow={onSelectRow} />
      ))}
    </>
  );
};

export default NestedItems;
