import * as React from 'react';
import type { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { Translator } from 'stores';
import { IconButtonNew } from 'components';
import ExpandIcon from 'components/Icons/ExpandIcon';
import { Stack } from '@mui/material';

interface ICustomCellGroupingProps extends GridRenderCellParams {
  isGroup: boolean;
  isExpandToolTip?: boolean;
}

const CustomCellGrouping: React.FC<ICustomCellGroupingProps> = (props) => {
  const { id, rowNode, isGroup, isExpandToolTip = false } = props;
  const apiRef = useGridApiContext();

  const onClickExpand = () => {
    apiRef.current.setRowChildrenExpansion(id, !(rowNode.childrenExpanded ?? false));
  };

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      {isGroup && (
        <IconButtonNew
          disabled={!rowNode.children}
          tooltip={
            isExpandToolTip ? (rowNode.childrenExpanded ? Translator.translate('actions.collapse') : Translator.translate('actions.expand')) : ''
          }
          onClick={(e) => {
            e.stopPropagation();
            onClickExpand();
          }}
          style={{ transform: `rotate(${rowNode.childrenExpanded ? (!rowNode.children ? 270 : 0) : 270}deg)` }}>
          {<ExpandIcon />}
        </IconButtonNew>
      )}
    </Stack>
  );
};

export default CustomCellGrouping;
