import React, { useState } from 'react';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { Group } from '@visx/group';
import { hierarchy, Tree } from '@visx/hierarchy';
import { LinkHorizontalStep } from '@visx/shape';
import { Zoom } from '@visx/zoom';
import IconButtonNew from 'components/UI/IconButtonNew';
import { useTheme, Box, Stack } from '@mui/material';
import Node from './Components/Node';

interface IHierarchyViewerProps {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  data: any;
  expandedTreeNodeIds: number[];
  onEditNode: (node: any) => void;
}

const HierarchyViewer: React.FC<IHierarchyViewerProps> = (props) => {
  const { width, height, margin = { top: 0, left: 40, right: 50, bottom: 0 }, data, expandedTreeNodeIds, onEditNode } = props;

  const theme = useTheme();
  const [expandedNodeIds, setExpandedNodeIds] = useState<number[]>(expandedTreeNodeIds);

  /*const yMax = 1200 - margin.top - margin.bottom;
  const xMax = 2000 - margin.left - margin.right;*/

  const yMax = height - margin.top - margin.bottom;
  const xMax = width - margin.left - margin.right;

  const initialTransform = {
    scaleX: 1,
    scaleY: 1,
    translateX: margin.left,
    translateY: yMax / 2,
    skewX: 0,
    skewY: 0,
  };

  return (
    <Box position="relative">
      <Zoom width={width} height={height} scaleXMin={1 / 4} scaleXMax={4} scaleYMin={1 / 4} scaleYMax={4} initialTransformMatrix={initialTransform}>
        {(zoom: any) => (
          <>
            <Stack
              position="absolute"
              direction="row"
              top={16}
              right={16}
              bgcolor={theme.palette.background.default}
              borderRadius={24}
              p={2}
              gap={2}
              sx={(theme) => ({
                svg: {
                  ':hover': {
                    fill: theme.palette.primary.main,
                    fillOpacity: 1,
                  },
                },
              })}>
              <IconButtonNew onClick={() => zoom.scale({ scaleX: 1.2, scaleY: 1.2 })}>
                <ZoomInIcon />
              </IconButtonNew>

              <IconButtonNew onClick={() => zoom.scale({ scaleX: 1 / 1.2, scaleY: 1 / 1.2 })}>
                <ZoomOutIcon />
              </IconButtonNew>

              <IconButtonNew onClick={zoom.center}>
                <CenterFocusStrongIcon />
              </IconButtonNew>

              <IconButtonNew onClick={zoom.clear}>
                <ZoomOutMapIcon />
              </IconButtonNew>
            </Stack>

            <svg width={width} height={height} style={{ cursor: zoom.isDragging ? 'grabbing' : 'grab', touchAction: 'none' }} ref={zoom.containerRef}>
              <Tree<any>
                root={hierarchy(data, (d) => {
                  return expandedNodeIds.includes(d.id) ? d.children : null;
                })}
                size={[yMax, xMax]}
                nodeSize={[50, 250]}>
                {(tree) => (
                  <>
                    <rect
                      width={width}
                      height={height}
                      fill="transparent"
                      onWheel={(event) => {
                        zoom.handleWheel(event);
                      }}
                      onMouseDown={zoom.dragStart}
                      onMouseMove={zoom.dragMove}
                      onMouseUp={zoom.dragEnd}
                      onMouseLeave={() => {
                        if (!zoom.isDragging) return;
                        zoom.dragEnd();
                      }}
                    />
                    <Group transform={zoom.toString()}>
                      {tree.links().map((link, i) => (
                        <LinkHorizontalStep
                          key={`link-${i}`}
                          data={link}
                          percent={0.55}
                          stroke={theme.palette.primary.main}
                          strokeWidth="1"
                          fill="none"
                        />
                      ))}
                      {tree.descendants().map((node, i) => (
                        <Node
                          key={`node-${i}`}
                          node={node}
                          onClick={() => {
                            if (node.data.children.length > 0) {
                              const isExpanded = expandedNodeIds.includes(node.data.id);

                              if (isExpanded) {
                                setExpandedNodeIds((prevState) => prevState.filter((id) => id !== node.data.id));
                              } else {
                                setExpandedNodeIds((prevState) => [...prevState, node.data.id]);
                              }
                            }
                          }}
                          onEdit={() => onEditNode(node)}
                        />
                      ))}
                    </Group>
                  </>
                )}
              </Tree>
            </svg>
          </>
        )}
      </Zoom>
    </Box>
  );
};

export default HierarchyViewer;
