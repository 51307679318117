import React from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';
import { Paper } from '@mui/material';

const Approvals: React.FC = () => {
  return (
    <>
      <Paper elevation={0} sx={{ p: 6, flex: 1, borderRadius: '12px' }}>
        <Outlet />
      </Paper>
    </>
  );
};

export default observer(Approvals);
