import React from 'react';

const RejectedSuggestionIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#EC3E59" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM11.2803 4.71967C11.5732 5.01256 11.5732 5.48744 11.2803 5.78033L9.06066 8L11.2803 10.2197C11.5732 10.5126 11.5732 10.9874 11.2803 11.2803C10.9874 11.5732 10.5126 11.5732 10.2197 11.2803L8 9.06066L5.78033 11.2803C5.48744 11.5732 5.01256 11.5732 4.71967 11.2803C4.42678 10.9874 4.42678 10.5126 4.71967 10.2197L6.93934 8L4.71967 5.78033C4.42678 5.48744 4.42678 5.01256 4.71967 4.71967C5.01256 4.42678 5.48744 4.42678 5.78033 4.71967L8 6.93934L10.2197 4.71967C10.5126 4.42678 10.9874 4.42678 11.2803 4.71967Z"
      />
    </svg>
  );
};

export default RejectedSuggestionIcon;
