import React, { useState } from 'react';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { Translator, documentStore, parameterStore } from 'stores';
import { DialogWrapper, FindTableDataDialog, IconButtonNew } from 'components';
import { EditableTable } from 'components/App/EditableTable';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import TrashIcon from 'components/Icons/TrashIcon';
import { Box, Button, Typography } from '@mui/material';
import { IEditableTableColumn, IGetDocumentDto } from 'shared/interfaces';
import { DocumentListModel } from 'shared/models';

export interface IDocumentParamDialogProps {
  open: boolean;
  tableData: DocumentListModel[];
  onClose: () => void;
  onDelete: (id: number) => void;
  onSelect: (documents: DocumentListModel[]) => void;
  onSubmit: () => void;
}

const DocumentParamDialog: React.FC<IDocumentParamDialogProps> = (props) => {
  const { open, tableData, onSelect, onSubmit, onDelete, onClose } = props;

  const [isFindDialogOpen, setFindDialogOpen] = useState(false);
  const [selectRow, setSelectRow] = React.useState(0);
  const [selectColumn, setSelectColumn] = React.useState('');

  const columnsSearch: GridColumns = [
    {
      field: 'name',
      headerName: Translator.translate('common.docParamDialog.columnsSearch.name'),
      flex: 1,
      editable: false,
      filterable: false,
      sortable: false,
      disableReorder: true,
    },
    {
      field: 'description',
      headerName: Translator.translate('common.docParamDialog.columnsSearch.description'),
      flex: 2,
      editable: false,
      filterable: false,
      sortable: false,
      disableReorder: true,
      renderCell: (params: GridRenderCellParams) => <Typography variant="body2">{(params.row as DocumentListModel).description}</Typography>,
    },
  ];

  const columns: IEditableTableColumn[] = [
    {
      key: 'name',
      label: Translator.translate('common.docParamDialog.columns.name'),
      width: '35%',
      align: 'left',
      editable: false,
      cell: (row: DocumentListModel) => {
        if (row.url)
          return (
            <a href={row.url} target="_blank" rel="noopener noreferrer">
              {row.name}
            </a>
          );
        else return <div>{row.name}</div>;
      },
    },
    {
      key: 'description',
      label: Translator.translate('common.docParamDialog.columns.description'),
      width: '45%',
      align: 'left',
      editable: false,
      cell: (row: DocumentListModel) => {
        return <div>{row.description}</div>;
      },
    },
    {
      key: 'documentPage',
      label: Translator.translate('common.docParamDialog.columns.documentPage'),
      width: '15%',
      align: 'left',
      editable: true,
      handleChange: ({ value, row, index }) => {
        row.setDocumentPage(value);
      },
      cell: (row: DocumentListModel) => {
        return <div>{row.documentPage}</div>;
      },
    },
    {
      key: 'actions',
      label: '',
      width: '5%',
      align: 'left',
      editable: false,
      cell: (row: IGetDocumentDto) => {
        return (
          <IconButtonNew
            sx={(theme) => ({
              svg: {
                width: 16,
                height: 16,
              },
            })}
            tooltip={Translator.translate('actions.delete')}
            onClick={() => onDelete(row.id)}>
            <TrashIcon />
          </IconButtonNew>
        );
      },
    },
  ];

  const onClick = (row: any, column: any) => {
    if (selectRow !== row.id || selectColumn !== column.key) {
      setSelectRow(0);
      setSelectColumn('');
    }
  };

  const onSaveDocuments = (documents: DocumentListModel[]) => {
    setFindDialogOpen(false);
    onSelect(documents);
  };

  return (
    <DialogWrapper
      maxWidth="xl"
      title={Translator.translate('common.docParamDialog.title')}
      subTitle={`${Translator.translate('common.docParamDialog.subTitle')}: ${parameterStore.selectedParameterTable.fullName}.${
        parameterStore.selectedParameterTable.name
      }`}
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={onSubmit}>
      <>
        <Box sx={{ maxHeight: '50vh', overflow: 'auto' }}>
          <EditableTable isActionsHover data={tableData} columns={columns} onClick={onClick} isSelectedById />
        </Box>

        <Box mt={4}>
          <Button
            variant="text"
            onClick={() => setFindDialogOpen(true)}
            startIcon={
              <Box
                sx={(theme) => ({
                  svg: {
                    fill: theme.palette.primary.main,
                  },
                })}>
                <PlusIconForButton />
              </Box>
            }>
            {Translator.translate('common.docParamDialog.addDocumentButtonTitle')}
          </Button>
        </Box>

        {isFindDialogOpen && (
          <FindTableDataDialog
            isOpen={isFindDialogOpen}
            title={Translator.translate('common.docParamDialog.findDocumentTableDialog.title')}
            selectAllText={Translator.translate('common.docParamDialog.findDocumentTableDialog.selectAllDocText')}
            notFoundText={Translator.translate('common.docParamDialog.findDocumentTableDialog.notFoundDocText')}
            data={documentStore.documents.filter(
              (document) => parameterStore.documentsByParameter.find((doc) => doc.id === document.id) === undefined
            )}
            onSave={onSaveDocuments}
            columns={columnsSearch}
            onClose={() => setFindDialogOpen(false)}
          />
        )}
      </>
    </DialogWrapper>
  );
};

export default observer(DocumentParamDialog);
