import React from 'react';

const ArchiveIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.09091 2.75C2.9023 2.75 2.75 2.90259 2.75 3.09054V4.25H13.25V3.09054C13.25 2.90259 13.0977 2.75 12.9091 2.75H3.09091ZM12.25 5.75H3.75V13.0008C3.75 13.1336 3.79047 13.1821 3.80408 13.1957C3.81778 13.2094 3.86676 13.25 4 13.25H12C12.1332 13.25 12.1822 13.2094 12.1959 13.1957C12.2095 13.1821 12.25 13.1336 12.25 13.0008V5.75ZM2.25 5.75H2C1.58579 5.75 1.25 5.41421 1.25 5V3.09054C1.25 2.07351 2.07453 1.25 3.09091 1.25H12.9091C13.9255 1.25 14.75 2.07351 14.75 3.09054V5C14.75 5.41421 14.4142 5.75 14 5.75H13.75V13.0008C13.75 13.4706 13.5917 13.9216 13.2559 14.257C12.9203 14.5923 12.4693 14.75 12 14.75H4C3.53075 14.75 3.07973 14.5923 2.74406 14.257C2.40828 13.9216 2.25 13.4706 2.25 13.0008V5.75ZM5.25 8C5.25 7.58579 5.58579 7.25 6 7.25H10C10.4142 7.25 10.75 7.58579 10.75 8C10.75 8.41421 10.4142 8.75 10 8.75H6C5.58579 8.75 5.25 8.41421 5.25 8Z"
      />
    </svg>
  );
};

export default ArchiveIcon;
