import React from 'react';

const XlsIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z" fill="#E9E9F4" />
      <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#A9ABB9" />
      <path d="M30 14L24 8H30V14Z" fill="#7B68EE" fillOpacity="0.08" />
      <path d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z" fill="#00A352" />
      <path
        d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z"
        fill="url(#paint0_linear_12607_183091)"
        fillOpacity="0.24"
      />
      <path
        d="M9.02099 20.3867L10.412 18.6437C10.828 18.0997 11.636 18.7947 11.18 19.3147C10.701 19.8827 10.197 20.4987 9.71699 21.1297L11.348 23.1607C11.788 23.7607 10.909 24.3357 10.477 23.7437L9.00499 21.8567L7.55799 23.7847C7.14999 24.3677 6.24599 23.7127 6.70199 23.1687L8.30899 21.1297C7.80499 20.4977 7.31699 19.8827 6.82999 19.3147C6.32699 18.7147 7.26199 18.0997 7.62999 18.6607L9.02099 20.3867Z"
        fill="white"
      />
      <path
        d="M12.335 18.947C12.335 18.683 12.559 18.459 12.839 18.459C13.095 18.459 13.311 18.684 13.311 18.947V22.953H15.486C16.269 22.953 16.286 24 15.486 24H12.84C12.56 24 12.336 23.801 12.336 23.513V18.947H12.335Z"
        fill="white"
      />
      <path
        d="M17.002 19.6669C17.186 18.1149 19.528 17.8359 20.6319 18.6839C21.1759 19.1229 20.5999 19.8189 20.12 19.4669C19.528 19.0919 18.185 18.9159 18.017 19.7459C17.801 21.0579 21.2789 20.3069 21.2229 22.4339C21.1669 24.4649 18.225 24.5119 17.121 23.6009C16.856 23.3849 16.865 23.0339 17.009 22.8169C17.217 22.6099 17.448 22.5379 17.721 22.7619C18.376 23.2089 20.064 23.5449 20.183 22.4089C20.08 21.2269 16.754 21.9379 17.002 19.6669Z"
        fill="white"
      />
      <path d="M25 27H6V28H25C25.55 28 26 27.55 26 27V26C26 26.55 25.55 27 25 27Z" fill="#7B68EE" fillOpacity="0.12" />
      <defs>
        <linearGradient id="paint0_linear_12607_183091" x1="14" y1="15" x2="14" y2="27" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default XlsIcon;
