import { BASE_PATH } from 'shared/constants/constants';
import { IAuditParams, IGetAuditDto } from 'shared/interfaces';

import HTTPService from './HttpService';

class AuditService extends HTTPService {
  constructor() {
    super(BASE_PATH.AUDIT);
  }

  public getAuditByEntity(params: IAuditParams): Promise<IGetAuditDto[]> {
    return this.GET('', { params });
  }
}

export default new AuditService();
