import { makeAutoObservable } from 'mobx';
import { ICollapseTable } from 'shared/interfaces';

class CollapseTableModel<T> {
  constructor(dto?: ICollapseTable) {
    makeAutoObservable(this, undefined, { autoBind: true });
    if (!dto) return;

    this.id = dto.id;
    this.subName = dto.subName;
    this.group = dto.fullName;
  }

  public id: number | null = null;

  public subName?: string;

  public group = '';

  public groupItems: T[] = [];

  public collapse = true;

  public checked = false;

  public setChecked(checked: boolean) {
    this.checked = checked;
  }

  public setCollapse(collapse: boolean) {
    this.collapse = collapse;
  }
}

export default CollapseTableModel;
