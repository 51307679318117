import React from 'react';

const DocumentExistIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 1.25C3.72922 1.25 3.22392 1.44832 2.84672 1.81043C2.46847 2.17355 2.25 2.67272 2.25 3.2V12.8C2.25 13.3273 2.46847 13.8265 2.84672 14.1896C3.22392 14.5517 3.72922 14.75 4.25 14.75H11.75C12.2708 14.75 12.7761 14.5517 13.1533 14.1896C13.5315 13.8265 13.75 13.3273 13.75 12.8V6C13.75 5.80109 13.671 5.61032 13.5303 5.46967L9.53033 1.46967C9.38968 1.32902 9.19891 1.25 9 1.25H4.25ZM3.88552 2.89251C3.97715 2.80454 4.10774 2.75 4.25 2.75H8.25V6C8.25 6.41421 8.58579 6.75 9 6.75H12.25V12.8C12.25 12.9092 12.2051 13.0205 12.1145 13.1075C12.0228 13.1955 11.8923 13.25 11.75 13.25H4.25C4.10774 13.25 3.97715 13.1955 3.88552 13.1075C3.79492 13.0205 3.75 12.9092 3.75 12.8V3.2C3.75 3.09076 3.79492 2.97948 3.88552 2.89251ZM11.1893 5.25L9.75 3.81066V5.25H11.1893Z"
      />
    </svg>
  );
};

export default DocumentExistIcon;
