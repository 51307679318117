import React, { ReactNode, useEffect } from 'react';
import {
  DataGridPro,
  DataGridProProps,
  GridColumns,
  GridRenderCellParams,
  GridCellParams,
  useGridApiRef,
  GridRowModel,
  gridClasses,
} from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { parameterValueStore, parameterGroupStore, auditStore, commentStore, parameterListValueStore, Translator, appStore } from 'stores';
import { IconButtonNew, TogglerTreeState } from 'components';
import CommentExistIcon from 'components/Icons/CommentExistIcon';
import CommentIcon from 'components/Icons/CommentIcon';
import DateValueIcon from 'components/Icons/DateValueIcon';
import FileValueIcon from 'components/Icons/FileValueIcon';
import HistoryIcon from 'components/Icons/HistoryIcon';
import LinkValueIcon from 'components/Icons/LinkValueIcon';
import ListIcon from 'components/Icons/ListIcon';
import LogicalValueIcon from 'components/Icons/LogicalValueIcon';
import NumberValueIcon from 'components/Icons/NumberValueIcon';
import RangeValueIcon from 'components/Icons/RangeValueIcon';
import TextValueIcon from 'components/Icons/TextValueIcon';
import { Box, Typography, Stack, Link, TableContainer, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { AuditTables, CommentMode, DataType, LangNames } from 'shared/enums';
import { IGetDocumentDto, IParameterGroupListValue, IParameterValueTable } from 'shared/interfaces';
import { ParameterValueModel } from 'shared/models';
import Utils from 'shared/utils/Utils';
import { CustomGridRow, EditListValueDialog, FileDialog, RenderEditableCell } from './Components';

import CustomCellGrouping from './Components/CustomCellGrouping';
import s from './ObjectValueTable.module.scss';

export const SEPARATOR_HEADER_WIDTH = 30;
//export const PADDING_CONTAINERS = 140;
export const PADDING_CONTAINERS = 180;

interface IObjectValueTableProps {
  filterHeight: number | null;
}

const ObjectValueTable: React.FC<IObjectValueTableProps> = (props) => {
  const { filterHeight } = props;
  // const [hoverRowId, setHoverRowId] = useState<number | null>(null);
  const apiParameterValueRef = useGridApiRef();
  const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy;

  useEffect(() => {
    return () => {
      parameterValueStore.selectedParamValue.clear();
    };
  }, []);

  useEffect(() => {
    parameterValueStore.resetParameterValueList();
    parameterValueStore.loadParameterValue();
  }, [
    parameterValueStore.filterStateParamValues.itemId,
    parameterValueStore.filterStateParamValues.parameterGroupId,
    parameterValueStore.filterStateParamValues.stageId,
    parameterValueStore.filterStateParamValues.sectionSetId,
    parameterValueStore.filterStateParamValues.sectionId,
  ]);

  const columns: GridColumns = [
    {
      field: 'parameterName',
      headerName: Translator.translate('objectValues.objectValueTable.columns.parameterName'),
      minWidth: 150,
      width: 300,
      editable: false,
      resizable: true,
      sortable: false,
      disableReorder: true,
      colSpan: (params) => (params.rowNode.children ? columns.length : 1),
      renderCell: (params: GridRenderCellParams<string, any>) => {
        return <CustomCellGrouping {...params}>{params.value}</CustomCellGrouping>;
      },
    },
    {
      field: 'value',
      headerName: Translator.translate('objectValues.objectValueTable.columns.value'),
      flex: 2,
      minWidth: 100,
      sortable: false,
      editable: true,
      resizable: false,
      disableReorder: true,
      colSpan: (params: GridCellParams) => {
        const { row }: { row: IParameterValueTable } = params;

        if (row.dataType === DataType.List && row.parameterListValues?.length !== 0) return 3;
        else return 1;
      },
      renderCell: (row: GridRenderCellParams) => {
        const { row: rowModel }: { row: IParameterValueTable } = row;

        const IconValue = (): ReactNode => {
          switch (rowModel.dataType) {
            case DataType.Number:
              return <NumberValueIcon />;

            case DataType.Range:
              return <RangeValueIcon />;

            case DataType.Boolean:
              return <LogicalValueIcon />;

            case DataType.String:
              return <TextValueIcon />;

            case DataType.Link:
              return <LinkValueIcon />;

            case DataType.List:
              return <ListIcon />;

            case DataType.DateTime:
              return <DateValueIcon />;

            case DataType.File:
              return <FileValueIcon />;

            default:
              return <TextValueIcon />;
          }
        };

        const isEmpty = (parameterListValues: any[]): boolean => {
          if (parameterListValues.length === 0) return true;
          return parameterListValues.every((listValue: any) => !listValue.description && listValue.documents.length === 0);
        };

        const lists = (parameterListValues: any[]): any[] => {
          return parameterListValues.map((listValue) => {
            return (
              <Typography variant="body2" key={listValue.id} sx={{ color: rowModel.isInheritedValue ? '#A1A1A1' : '' }}>
                {Utils.deleteInnerWrap(listValue.value)}
              </Typography>
            );
          });
        };

        const onChangeBooleanState = async (state: string) => {
          const value = state;
          parameterValueStore.selectedParamValue = new ParameterValueModel();
          parameterValueStore.selectedParamValue.setId(rowModel.id);
          rowModel.valueId && parameterValueStore.selectedParamValue.setValueId(rowModel.valueId);

          runInAction(() => {
            parameterValueStore.selectedParamValue.isInheritedValue = rowModel.isInheritedValue ?? false;
          });

          if (row.value !== value) {
            parameterValueStore.selectedParamValue.setValue(value);
            parameterValueStore.updateValue();
          }
        };

        const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.files?.length !== 0 && e.target.files?.length) parameterValueStore.selectedParamValue.file.setFile(e.target.files[0] as File);
          await parameterValueStore.createParameterValueFile();
          await parameterValueStore.createParameterValue();
        };

        switch (rowModel.dataType) {
          case DataType.Boolean: {
            return (
              <Stack flexDirection="row" gap={1} alignItems="center">
                {IconValue()}
                <TogglerTreeState isInheritedValue={rowModel.isInheritedValue} selectedTab={rowModel.value!} onChangeTab={onChangeBooleanState} />
              </Stack>
            );
          }

          case DataType.Link: {
            return (
              <Typography variant="body2">
                <Stack flexDirection="row" gap={1} alignItems="center">
                  {IconValue()}
                  <Link href={row.value} className={rowModel.isInheritedValue ? s.linkWrapperInherited : s.linkWrapper} target="_blank">
                    {row.value}
                  </Link>
                </Stack>
              </Typography>
            );
          }

          case DataType.DateTime: {
            return (
              <Stack flexDirection="row" gap={1} alignItems="center">
                {IconValue()}
                <Typography variant="body2" sx={{ color: rowModel.isInheritedValue ? '#A1A1A1' : '', display: 'flex' }}>
                  {dayjs(row.value, 'YYYY-MM-DD').isValid()
                    ? dayjs(row.value).format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY')
                    : ''}
                </Typography>
              </Stack>
            );
          }

          case DataType.List: {
            return (
              <>
                {rowModel.parameterListValues?.length === 0 && <Box sx={{ display: 'flex' }}>{IconValue()}</Box>}

                {isEmpty(rowModel.parameterListValues!) && /* !rowModel.isDocumentExists && */ !rowModel.description ? (
                  <Stack direction="row" padding="4px 4px 4px 0" gap={1}>
                    <Box>{!rowModel.parameterListValues?.every((list: any) => !list.value) && <ListIcon />}</Box>
                    <Box style={{ color: rowModel.isInheritedValue ? '#A1A1A1' : '' }}>
                      {rowModel.value && rowModel.value !== '' ? rowModel.value : lists(rowModel.parameterListValues!)}
                    </Box>
                  </Stack>
                ) : (
                  <TableContainer style={{ maxHeight: '500px', overflow: 'auto' }}>
                    <Table width="100%">
                      <TableBody>
                        {rowModel.parameterListValues?.map((list: IParameterGroupListValue, index: number) => {
                          return (
                            <TableRow
                              key={list.id}
                              sx={{ borderBottom: index === rowModel.parameterListValues!.length - 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)' }}>
                              <TableCell
                                style={{
                                  width: '50%',
                                  minWidth: 80,
                                  padding: '4px 10px 4px 0px',
                                  borderBottom: 'none',
                                  color: rowModel.isInheritedValue ? '#A1A1A1' : '',
                                  wordBreak: 'break-word',
                                  height: 1,
                                }}>
                                <Stack direction="row" alignItems="flex-start" height={index === 0 ? '100%' : 'auto'} gap={1}>
                                  {index === 0 && (
                                    <Box>
                                      <ListIcon />
                                    </Box>
                                  )}
                                  <Typography variant="body2" marginLeft={index !== 0 ? 5 : 0}>
                                    {Utils.deleteInnerWrap(list.value)}
                                  </Typography>
                                </Stack>
                              </TableCell>

                              <TableCell
                                style={{
                                  width: '25%',
                                  minWidth: 80,
                                  padding: '4px 10px 4px 6px',
                                  borderBottom: 'none',
                                  color: rowModel.isInheritedValue ? '#A1A1A1' : '',
                                  wordBreak: 'break-word',
                                }}>
                                {rowModel.description}
                                {rowModel.description && <br />}
                                {<Typography variant="body2">{Utils.deleteInnerWrap(list.description)}</Typography>}
                              </TableCell>

                              <TableCell
                                style={{
                                  width: '25%',
                                  minWidth: 80,
                                  padding: '4px 0 4px 15px',
                                  borderBottom: 'none',
                                  wordBreak: 'break-all',
                                  color: rowModel.isInheritedValue ? '#A1A1A1' : '',
                                }}>
                                {rowModel.documents!.length > 0 && (
                                  <div className={s.documentWrapper}>{getViewDocuments(rowModel, rowModel.documents!)}</div>
                                )}
                                {list.documents.length > 0 && <div className={s.documentWrapper}>{getViewDocuments(rowModel, list.documents)}</div>}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </>
            );
          }

          case DataType.Number: {
            return (
              <Stack flexDirection="row" gap={1} sx={{ wordBreak: 'break-all' }}>
                <Box sx={{ display: 'flex' }}>{IconValue()}</Box>
                <Typography variant="body2">
                  {row.value !== '' ? Number(row.value).toLocaleString('ru-RU', { maximumFractionDigits: 20 }) : ''}
                </Typography>
              </Stack>
            );
          }

          case DataType.File: {
            return (
              <Stack direction="row" alignItems="center" gap={1}>
                {IconValue()}

                {row.value ? (
                  <Typography
                    variant="body2"
                    sx={{
                      color: rowModel.isInheritedValue ? '#A1A1A1' : '',
                      display: 'flex',
                    }}>
                    {Utils.deleteInnerWrap(row.value)}
                  </Typography>
                ) : (
                  <input name={String(row.id)} id={String(row.id)} type="file" hidden onChange={handleUpload} />
                )}
              </Stack>
            );
          }

          default: {
            return (
              <Stack
                flexDirection="row"
                gap={1}
                alignItems={rowModel.dataType !== DataType.String ? 'center' : 'flex-start'}
                sx={{ wordBreak: 'break-all' }}>
                <Box sx={{ display: 'flex' }}>{IconValue()}</Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: rowModel.isInheritedValue ? '#A1A1A1' : '',
                    whiteSpace: rowModel.dataType === DataType.String ? 'pre-line' : 'normal',
                    display: 'flex',
                  }}>
                  {Utils.deleteInnerWrap(row.value)}
                </Typography>
              </Stack>
            );
          }
        }
      },
      renderEditCell: RenderEditableCell,
    },
    {
      field: 'description',
      headerName: Translator.translate('objectValues.objectValueTable.columns.description'),
      flex: 1,
      minWidth: 100,
      disableReorder: true,
      sortable: false,
      editable: false,
      resizable: false,
    },
    {
      field: 'documents',
      headerName: Translator.translate('objectValues.objectValueTable.columns.documents'),
      flex: 1,
      minWidth: 100,
      disableReorder: true,
      sortable: false,
      editable: false,
      resizable: false,
      renderCell: (row: GridRenderCellParams<any>) => {
        return (
          <div className={s.documentWrapper}>
            {row.row.hasOwnProperty('documents') && row.row.documents.length !== 0 ? getViewDocuments(row.row, row.row.documents) : ''}
          </div>
        );
      },
    },
    {
      field: 'action',
      headerName: '',
      width: 80,
      align: 'right',
      sortable: false,
      editable: false,
      resizable: false,
      disableReorder: true,
      renderCell: (row: GridRenderCellParams) => {
        const { row: rowModel }: { row: IParameterValueTable } = row;

        return (
          <>
            <Stack flexDirection="row" justifyContent="flex-end">
              {appStore.isShowComments &&
                (rowModel.hasComments ? (
                  <IconButtonNew
                    tooltip={Translator.translate('actions.comments')}
                    onClick={(e) => {
                      e.stopPropagation();
                      onCommentParameter(rowModel);
                    }}>
                    <CommentExistIcon />
                  </IconButtonNew>
                ) : (
                  <IconButtonNew
                    tooltip={Translator.translate('actions.comment')}
                    onClick={(e) => {
                      e.stopPropagation();
                      onCommentParameter(rowModel);
                    }}>
                    <CommentIcon />
                  </IconButtonNew>
                ))}

              <IconButtonNew
                disabled={!rowModel.valueId}
                sx={{
                  svg: {
                    width: 16,
                    height: 16,
                  },
                }}
                tooltip={Translator.translate('actions.changeHistory')}
                onClick={(e) => {
                  e.stopPropagation();
                  auditStore.setDrawerOpen(true);
                  if (rowModel.valueId) auditStore.setAuditFilters(AuditTables.ParameterValues, rowModel.valueId);
                }}>
                <HistoryIcon />
              </IconButtonNew>
            </Stack>
          </>
        );
      },
    },
  ];

  const getViewDocuments = (row: any, documents: IGetDocumentDto[]): ReactNode => {
    return documents.map((document, index) => {
      if (document.url)
        return (
          <Typography variant="body2" sx={{ color: row.isInheritedValue ? '#A1A1A1' : '' }}>
            <Link
              target="_blank"
              onClick={(event) => event.stopPropagation()}
              href={document.url}
              className={row.isInheritedValue ? s.linkWrapperInherited : s.linkWrapper}>
              {index === documents.length - 1 ? document.name : `${document.name} `}
            </Link>
            {document.documentPage && `, ${document.documentPage}`}
          </Typography>
        );
      else
        return (
          <Typography variant="body2" sx={{ color: row.isInheritedValue ? '#A1A1A1' : '' }}>
            {index === documents.length - 1 ? document.name : `${document.name} `}
            {document.documentPage && `, ${document.documentPage}`}
          </Typography>
        );
    });
  };

  const onCommentParameter = (row: IParameterValueTable) => {
    parameterValueStore.selectedParamValue.setParamName(row.parameterName!);
    commentStore.setMode(CommentMode.Parameter);
    commentStore.setFilters(parameterValueStore.filterStateParamValues.itemId!, row.id);
    commentStore.setOpenModal(true);
    commentStore.getAllComments();
  };

  const onSelectedRow = async (params: GridCellParams, e: any) => {
    const { row }: { row: IParameterValueTable } = params;

    if (params.field !== 'value') return;
    if (row.dataType === DataType.Boolean) return;

    parameterValueStore.selectedParamValue.setId(row.id);
    parameterValueStore.selectedParamValue.setValueId(row.valueId!);
    parameterValueStore.selectedParamValue.setParamName(row.parameterName!);

    if (row.dataType !== DataType.File && parameterValueStore.selectedParamValue.valueId && params.cellMode !== 'edit') {
      await parameterValueStore.getParameterValueById();
      parameterValueStore.selectedParamValue.setInheritedValue(row.isInheritedValue);
    }

    if (row.dataType === DataType.List) {
      parameterListValueStore.setOpenDialog(true);

      parameterValueStore.filterStateParamValues.itemId &&
        (await parameterListValueStore.getRecommendParameterListValues(row.id, parameterValueStore.filterStateParamValues.itemId));

      parameterValueStore.selectedParamValue.setParameterListValueIds(
        row.parameterListValues!.length > 0 ? row.parameterListValues!.map((value) => value.id) : []
      );
    } else if (row.dataType === DataType.File) {
      if (row.value !== '') {
        parameterValueStore.setFileValueEditMode(row.isInheritedValue);
      } else {
        parameterValueStore.selectedParamValue.setId(row.id);
        const tmp = document.getElementById(String(row.id));
        tmp?.click();
      }
    } else {
      apiParameterValueRef.current.startCellEditMode({ id: params.id, field: params.field });
    }
  };

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };

    if (!(oldRow.value === newRow.value)) {
      parameterValueStore.selectedParamValue.setValue(newRow.value);
      parameterValueStore.updateValue();
    }

    return updatedRow;
  };

  return (
    <>
      {parameterValueStore.filterStateParamValues.itemId && parameterValueStore.filterStateParamValues.parameterGroupId ? (
        <Box
          sx={{
            width: '100%',
            height: `calc(100vh - ${PADDING_CONTAINERS + filterHeight!}px)`,
          }}>
          <DataGridPro
            sx={{
              border: 'none',
              '& .MuiDataGrid-row': {
                width: '100%',
                minHeight: '36px !important',
              },
              '& .MuiDataGrid-columnHeaders': {
                border: 0,
              },
              '& .MuiDataGrid-cell': {
                border: 'none',
              },
              '& .MuiDataGrid-cell--editable[data-field="value"]': {
                cursor: 'pointer',
              },
              '& .MuiDataGrid-columnHeader:nth-last-of-type(2) .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                outline: 'none',
              },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                outline: 'none',
              },
            }}
            /* componentsProps={{
              row: {
                onMouseEnter: (event: any) => setHoverRowId(event.currentTarget.dataset.id),
                onMouseLeave: () => setHoverRowId(null),
              },
            }} */
            apiRef={apiParameterValueRef}
            hideFooter
            getRowHeight={() => {
              return 'auto';
            }}
            headerHeight={36}
            treeData
            rows={parameterValueStore.filteredParamValueTableData}
            onCellClick={onSelectedRow}
            columns={columns}
            disableColumnMenu
            getTreeDataPath={getTreeDataPath}
            groupingColDef={{
              hide: true,
            }}
            defaultGroupingExpansionDepth={1}
            experimentalFeatures={{ newEditingApi: true }}
            isRowSelectable={() => false}
            processRowUpdate={processRowUpdate}
            onCellDoubleClick={(params) => {
              if (params.row.dataType === DataType.Boolean) {
                apiParameterValueRef.current.stopCellEditMode({ id: params.row.id!, field: params.field });
              }
            }}
            onCellKeyDown={(params, event) => {
              if (event.key === 'Enter' && params.cellMode === 'edit') {
                event.stopPropagation();
              }
            }}
            components={{
              Row: CustomGridRow,
              NoRowsOverlay: () => {
                return (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    {Translator.translate('objectValues.objectValueTable.emptyParameter')}
                  </Stack>
                );
              },
            }}
          />
        </Box>
      ) : (
        parameterGroupStore.currentParameterGroup && (
          <div className={s.infoWrapper}>
            <Typography>{Translator.translate('objectValues.objectValueTable.selectObjectAndParamGroupMessage')}</Typography>
          </div>
        )
      )}

      {parameterListValueStore.isDialogOpen && <EditListValueDialog />}
      {parameterListValueStore.isDialogFilesOpen && <FileDialog />}
    </>
  );
};

export default observer(ObjectValueTable);
