import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps,
  SxProps,
  Theme,
  Chip,
  Stack,
  IconButton,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import { ISelectOption } from 'shared/interfaces';

import s from './MultipleSelect.module.scss';

interface IMultipleSelectProps extends Omit<SelectProps, 'onChange'> {
  sx?: SxProps<Theme>;
  label?: string;
  value: (string | number)[];
  disabled?: boolean;
  options: ISelectOption[];
  defaultOpen?: boolean;
  helperText?: any;
  isSmallCheckbox?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  onChange: (e: SelectChangeEvent<any>) => void;
}

const ITEM_HEIGHT = 54;

const MultipleSelect: React.FC<IMultipleSelectProps> = (props) => {
  const {
    label,
    value,
    options,
    disabled,
    sx,
    onChange,
    placeholder,
    helperText,
    error,
    defaultOpen,
    isSmallCheckbox = false,
    onClose,
    onOpen,
    ...rest
  } = props;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4,
      },
    },
  };

  const renderValue = (row: (string | number)[]): ISelectOption[] => {
    const array: ISelectOption[] = [];
    row.forEach((row) => {
      const findEl = options.find((option) => option.id === row);
      if (findEl !== undefined) array.push({ id: findEl.id, name: findEl.name });
    });

    return array;
  };

  const isEmptyDescription = (): boolean => {
    return options.every((item) => item.description === null);
  };

  const onDeleteClass = (e: React.MouseEvent, data: number) => {
    e.preventDefault();
    //onChange(value.filter((f: number | string) => f !== data) as number[]);
  };

  const placeHolder = (
    <Typography variant="body2" sx={{ opacity: 0.6, mt: 0.5 }}>
      {placeholder}
    </Typography>
  );

  const renderValues = (selected: any): React.ReactNode => {
    return (
      <Stack flexWrap="wrap" flexDirection="row" gap={2} sx={{ p: 0 }}>
        {renderValue(selected).map((value) => (
          <Chip
            sx={{ width: 'fit-content', height: '22px' }}
            key={value.id}
            label={value.name}
            deleteIcon={
              <IconButton
                sx={{
                  padding: 0,
                  svg: {
                    fill: 'rgba(0, 0, 0, 0.26)',
                    width: '16px',
                    height: '16px',
                  },
                }}
                disableRipple
                onMouseDown={(event: any) => event.stopPropagation()}>
                <CancelIcon />
              </IconButton>
            }
            //onDelete={(e) => onDeleteClass(e, value.id as number)}
          />
        ))}
      </Stack>
    );
  };

  return (
    <FormControl disabled={disabled} sx={sx} error={error}>
      <InputLabel>{label}</InputLabel>

      <MuiSelect
        {...rest}
        multiple
        value={value}
        label={label}
        error={error}
        onClose={onClose}
        onOpen={onOpen}
        onChange={onChange}
        renderValue={(selected: any) => (!!value ? renderValues(selected) : placeHolder)}
        MenuProps={MenuProps}>
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox
              sx={{ '& .MuiSvgIcon-root': { fontSize: isSmallCheckbox ? '1rem' : '1.5rem' }, padding: isSmallCheckbox ? '4px' : '9px' }}
              checked={value.indexOf(option.id) > -1}
            />
            {option.description !== undefined && !isEmptyDescription() ? (
              <div className={s.menuWrapper}>
                <div className={s.name}>{option.name}</div>
                <div className={s.desc}>{option.description}</div>
              </div>
            ) : (
              <div className={s.onlyName}>{option.name}</div>
            )}
          </MenuItem>
        ))}
      </MuiSelect>
      {error ? <FormHelperText error>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};

export default MultipleSelect;
