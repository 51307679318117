import { makeAutoObservable } from 'mobx';
import { IRootParameterGroupParams } from 'shared/interfaces';

class FilterParameterValueModel {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public itemId: number | null = null;

  public parameterGroupId: number | null = null;

  public stageId: number | null = null;

  public sectionSetId: number | null = null;

  public sectionId: number | null = null;

  public isHideEmpty = false;

  public searchPattern = '';

  public includeRoot = true;

  public get paramsDto(): IRootParameterGroupParams {
    return {
      stageId: this.stageId ?? undefined,
      sectionId: this.sectionId ?? undefined,
    };
  }

  public setSearchPattern(value: string) {
    this.searchPattern = value;
  }

  public setHideEmpty(isHide: boolean) {
    this.isHideEmpty = isHide;
  }

  public setItemId(id: number | null) {
    this.itemId = id;
  }

  public setParameterGroupId(id: number | null) {
    this.parameterGroupId = id;
  }

  public setStageId(id: number | null) {
    this.stageId = id;
  }

  public setSectionSetId(id: number | null) {
    this.sectionSetId = id;
  }

  public setSectionId(id: number | null) {
    this.sectionId = id;
  }

  public clear() {
    this.stageId = null;
    this.sectionId = null;
    this.isHideEmpty = false;
    this.searchPattern = '';
    this.parameterGroupId = null;
    this.sectionSetId = null;
  }
}

export default FilterParameterValueModel;
