import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useDebounce } from 'usehooks-ts';
import { Translator, informationBlockStore } from 'stores';
import { IconButtonNew, InformBlockDialog, TableView } from 'components';
import { useConfirm } from 'components/App/Confirm';
import DownIcon from 'components/Icons/DownIcon';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import TrashIcon from 'components/Icons/TrashIcon';
import UpIcon from 'components/Icons/UpIcon';
import { Box, CircularProgress, Stack } from '@mui/material';
import { Reorder } from 'shared/enums';
import { ITableColumn } from 'shared/interfaces';
import { IGetInformationBlockDto, IInformationBlockFormDto } from 'shared/interfaces/Dto/IInformationBlockDto';
import { InformationBlockModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

const HEIGHT_CONTENT = 60;

const InformationBlocks: React.FC = () => {
  const confirm = useConfirm();
  const [isLoading, setIsLoading] = useState(false);
  const [currentOrderNum, setCurrentOrderNum] = useState<number | null>(null);
  const debouncedValue = useDebounce<number | null>(currentOrderNum, 350);

  useEffect(() => {
    if (debouncedValue && currentOrderNum) {
      informationBlockStore.selectedInformationBlock.setOrderNum(informationBlockStore.selectedInformationBlock.orderNum! + currentOrderNum);
      informationBlockStore.updateInformationBlock().then(() => {
        informationBlockStore.clearInformationBlock();
        setCurrentOrderNum(null);
      });
    }
  }, [debouncedValue]);

  const columns: ITableColumn[] = [
    {
      key: 'name',
      label: Translator.translate('catalogs.infBlocks.columns.name'),
      width: '400px',
      align: 'left',
    },
    {
      key: 'actions',
      label: '',
      width: '50px',
      align: 'right',
      cell: (row: IGetInformationBlockDto) => {
        const changeOrderStage = (order: 1 | -1) => {
          if (!informationBlockStore.selectedInformationBlock.id) {
            informationBlockStore.selectedInformationBlock = new InformationBlockModel(row);
          }

          setCurrentOrderNum(currentOrderNum ? currentOrderNum + order : order);
        };

        const canReorder = (reorder: Reorder): boolean => {
          const findEl = informationBlockStore.informationBlocks.find((f) => f.orderNum === row.orderNum);
          if (!findEl) return false;

          if (
            row.orderNum ===
            informationBlockStore.informationBlocks[reorder === Reorder.Up ? 0 : informationBlockStore.informationBlocks.length - 1].orderNum
          )
            return true;
          else return false;
        };

        return (
          <Stack
            flexDirection="row"
            gap={2}
            justifyContent="flex-end"
            sx={(theme) => ({
              'button svg': {
                ':hover': {
                  fill: theme.palette.primary.main,
                  fillOpacity: 1,
                },
              },
            })}>
            <IconButtonNew onClick={() => onEditInformationBlock(row.id)} tooltip={Translator.translate('actions.edit')}>
              <EditPencilIcon />
            </IconButtonNew>
            <IconButtonNew onClick={() => onRemoveInformationBlock(row.id)} tooltip={Translator.translate('actions.delete')}>
              <TrashIcon />
            </IconButtonNew>
            <IconButtonNew onClick={() => changeOrderStage(-1)} disabled={canReorder(Reorder.Up)} tooltip={Translator.translate('actions.up')}>
              <UpIcon />
            </IconButtonNew>
            <IconButtonNew onClick={() => changeOrderStage(1)} disabled={canReorder(Reorder.Down)} tooltip={Translator.translate('actions.down')}>
              <DownIcon />
            </IconButtonNew>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    informationBlockStore.getInformationBlocks().then(() => setIsLoading(false));
  }, []);

  const onSubmitInformBlockDialog = (values: IInformationBlockFormDto) => {
    informationBlockStore.selectedInformationBlock.updateInformationBlockForm(values);

    if (!informationBlockStore.selectedInformationBlock.id) {
      informationBlockStore.createInformationBlock().then(() => {
        informationBlockStore.setDialogOpen(false);
        informationBlockStore.clearInformationBlock();
      });
    } else {
      informationBlockStore.updateInformationBlock().then(() => {
        informationBlockStore.setDialogOpen(false);
        informationBlockStore.clearInformationBlock();
      });
    }
  };

  const onRemoveInformationBlock = (blockId: number) => {
    confirm
      .show(Translator.translate('catalogs.infBlocks.confirmMessage.deleteInfBlock'), Translator.translate('actions.delete'))
      .then(() => informationBlockStore.removeInformationBlock(blockId));
  };

  const onEditInformationBlock = (blockId: number) => {
    informationBlockStore.setDialogOpen(true);
    informationBlockStore.getInformationBlockById(blockId);
  };

  return (
    <>
      {isLoading ? (
        <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableView
          data={informationBlockStore.filterInformationBlocks}
          isActionsHover
          columns={columns}
          hideSelectRowOnMount
          height={`calc(100vh - ${HEIGHT_CONTENT + informationBlockStore.filterHeight! + Utils.projectHeight()}px)`}
        />
      )}

      {informationBlockStore.isDialogOpen && <InformBlockDialog onSubmit={onSubmitInformBlockDialog} /*onClose={clearInfBlock}*/ />}
    </>
  );
};

export default observer(InformationBlocks);
