import { ReactNode } from 'react';
import { Drawer, DrawerProps } from '@mui/material';

interface IDrawerWrapperProps extends DrawerProps {
  children: ReactNode;
}
const DrawerWrapper: React.FC<IDrawerWrapperProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Drawer
      PaperProps={{
        sx: { width: '450px' },
      }}
      anchor="right"
      {...rest}>
      {children}
    </Drawer>
  );
};

export default DrawerWrapper;
