import { makeAutoObservable } from 'mobx';
import { ISectionParameter } from 'shared/interfaces';

class SectionParamsModel {
  constructor(dto?: ISectionParameter) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.groupId = dto.groupId ?? null;
    this.fullName = dto.fullName;
    this.checked = dto.checked ?? false;
    this.isVisible = dto.isVisible ?? false;
    this.isTitle = dto.isTitle ?? false;
    this.propertyId = dto.propertyId ?? null;
    this.hierarchy = dto.hierarchy ?? [];
    this.parameterIds = dto.parameterIds ?? null;
    this.isGroup = dto.isGroup ?? false;
  }

  public id: number | null = null;

  public name = '';

  public groupId: number | null = null;

  public hierarchy: string[] = [];

  public parameterIds: number[] | null = null;

  public fullName = '';

  public checked = false;

  public isVisible = false;

  public isTitle = false;

  public propertyId: number | null = null;

  public isGroup = false;

  public setChecked(checked: boolean) {
    this.checked = checked;
  }

  public setVisible(visible: boolean) {
    this.isVisible = visible;
  }

  public setTitle(isTitle: boolean) {
    this.isTitle = isTitle;
  }
}

export default SectionParamsModel;
