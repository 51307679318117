import React from 'react';

const XMLIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1809_9942)">
        <path d="M1 1.7C1 1.3134 1.3134 1 1.7 1H18.3C18.6866 1 19 1.3134 19 1.7C19 2.0866 18.6866 2.4 18.3 2.4H1.7C1.3134 2.4 1 2.0866 1 1.7Z" />
        <path d="M1 18.3C1 17.9134 1.3134 17.6 1.7 17.6H18.3C18.6866 17.6 19 17.9134 19 18.3C19 18.6866 18.6866 19 18.3 19H1.7C1.3134 19 1 18.6866 1 18.3Z" />
        <path d="M1.93875 9.82857L0.293015 6.93311C0.0551162 6.51455 0.364485 6 0.854032 6C1.08842 6 1.30417 6.12497 1.41687 6.32601L2.77811 8.75429L4.16464 6.31365C4.27462 6.12006 4.48324 6 4.70964 6C5.18586 6 5.48629 6.50114 5.25381 6.90772L3.59674 9.80571L5.43594 13.0424C5.68004 13.472 5.36252 14 4.86013 14C4.61806 14 4.39548 13.8701 4.28046 13.6618L2.75738 10.9029L1.18662 13.6808C1.07526 13.8778 0.863341 14 0.633272 14C0.15039 14 -0.154309 13.4919 0.0812841 13.0796L1.93875 9.82857Z" />
        <path d="M7.01001 7C7.01001 6.44772 7.46768 6 8.03224 6H8.17523C8.60741 6 8.9929 6.26588 9.13807 6.6641L10.5125 10.4343H10.5332L11.9077 6.6641C12.0528 6.26588 12.4383 6 12.8705 6H12.9306C13.4951 6 13.9528 6.44772 13.9528 7V13.3918C13.9528 13.7277 13.6745 14 13.3311 14C12.9877 14 12.7093 13.7277 12.7093 13.3918V7.54286H12.6886L11.3123 11.3129C11.1869 11.6564 10.8543 11.8857 10.4814 11.8857C10.1086 11.8857 9.77596 11.6564 9.65056 11.3129L8.27422 7.54286H8.2535V13.3918C8.2535 13.7277 7.97514 14 7.63176 14C7.28838 14 7.01001 13.7277 7.01001 13.3918V7Z" />
        <path d="M15.5442 6.60822C15.5442 6.27231 15.8225 6 16.1659 6C16.5093 6 16.7877 6.27231 16.7877 6.60822V12.7429H19.3575C19.7123 12.7429 20 13.0243 20 13.3714C20 13.7186 19.7123 14 19.3575 14H16.5664C16.0018 14 15.5442 13.5523 15.5442 13V6.60822Z" />
      </g>
      <defs>
        <clipPath id="clip0_1809_9942">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default XMLIcon;
