import React, { useEffect, useMemo, useState } from 'react';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { Translator, globalSettingStore } from 'stores';
import { CustomDataGrid, IconButtonNew } from 'components';
import { useConfirm } from 'components/App/Confirm';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import TrashIcon from 'components/Icons/TrashIcon';
import { Stack, Button, Box } from '@mui/material';
import { DocumentSettingType } from 'shared/enums';
import { Routes } from 'shared/enums/Routes';
import { IParameterSettings } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';
import ParameterDocSettingDialog from './Components';

const HEIGHT_CONTENT = 145;

const ParameterDocSettings: React.FC = () => {
  const location = useLocation();
  const confirm = useConfirm();
  const currentType = location.pathname.includes(Routes.EXPORT_VIEW_LIST) ? DocumentSettingType.IsMultilineListValues : DocumentSettingType.HideName;
  const [hoverRowId, setHoverRowId] = useState<number | null>(null);

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: Translator.translate('params.parameter.columns.name'),
      flex: 1,
      hideSortIcons: true,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
    {
      field: 'action',
      headerName: '',
      width: 80,
      align: 'right',
      sortable: false,
      editable: false,
      resizable: false,
      disableReorder: true,
      renderCell: (row: GridRenderCellParams<IParameterSettings>) => {
        const { row: data }: { row: IParameterSettings } = row;

        const removeParameterSettings = () => {
          confirm
            .show(
              Translator.translate('settings.parameterDocSettings.confirmMessage.deleteParameterDocSettings'),
              Translator.translate('actions.delete')
            )
            .then(() => {
              globalSettingStore.changeParameterDocSettings(
                data.id,
                data.documentSettings.filter((type) => type !== currentType),
                currentType
              );
            });
        };

        return (
          !data.isGroup &&
          data.id === hoverRowId && (
            <Box
              sx={(theme) => ({
                'button svg': {
                  ':hover': {
                    fill: theme.palette.primary.main,
                    fillOpacity: 1,
                  },
                },
              })}>
              <IconButtonNew tooltip={Translator.translate('actions.delete')} onClick={removeParameterSettings}>
                <TrashIcon />
              </IconButtonNew>
            </Box>
          )
        );
      },
    },
  ];

  useEffect(() => {
    globalSettingStore.getParameterDocSettings(currentType);

    return () => {
      globalSettingStore.clearParameterSettings();
    };
  }, []);

  useEffect(() => {
    globalSettingStore.getParameterDocSettings(currentType);
  }, [location]);

  const onAdd = () => {
    globalSettingStore.setOpenDialog(true);
  };

  const onHoverRowId = (hoveredRowId: number | null) => {
    setHoverRowId(hoveredRowId);
  };

  return (
    <Stack gap={2} alignItems="flex-start">
      <Button
        variant="text"
        onClick={onAdd}
        startIcon={
          <Stack
            sx={(theme) => ({
              svg: {
                fill: theme.palette.primary.main,
              },
            })}>
            <PlusIconForButton />
          </Stack>
        }>
        {Translator.translate('actions.add')}
      </Button>

      <Box sx={{ height: `calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight()}px)`, width: '100%' }}>
        <CustomDataGrid
          rows={globalSettingStore.paramSettingsFilteredDataGrid}
          columns={columns}
          isRowSelectable={() => false}
          checkboxSelection={false}
          disableColumnMenu
          loading={globalSettingStore.isLoading}
          onHoverRowId={onHoverRowId}
        />
      </Box>

      {globalSettingStore.isDialogOpen && <ParameterDocSettingDialog />}
    </Stack>
  );
};

export default observer(ParameterDocSettings);
