import React from 'react';

const MergeChangedValueIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289C9.90237 4.68342 9.90237 5.31658 10.2929 5.70711L11.5858 7H2C1.44772 7 1 7.44772 1 8C1 8.55228 1.44772 9 2 9H11.5858L10.2929 10.2929C9.90237 10.6834 9.90237 11.3166 10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071L14.7071 8.70711C15.0976 8.31658 15.0976 7.68342 14.7071 7.29289L11.7071 4.29289Z" />
    </svg>
  );
};

export default MergeChangedValueIcon;
