import React, { useRef, useEffect } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FieldDataNode } from 'rc-tree';
import { Translator, informationBlockStore, parameterGroupStore } from 'stores';
import { DialogWrapper } from 'components';
import TreeData, { ButtonItemTitle, ParameterGroupTreeSearch, ParameterGroupTreeSelect } from 'components/App/TreeData';
import { Box, Stack } from '@mui/material';
import { IInfBlockParameterGroup } from 'shared/interfaces';
import { ParameterGroupModel } from 'shared/models';

interface IParamTreeItemProps {
  title: React.ReactNode;
  name: string;
  id: number | null;
  key: string | number;
}

declare type IParamTreeItem = FieldDataNode<IParamTreeItemProps>;

const InfBlockParamGroupDialog = () => {
  const parameterGroupRef = useRef<any>();

  const mapItems = (data: ParameterGroupModel[]): IParamTreeItem[] => {
    return data.map((item) => {
      const title = <ButtonItemTitle title={item.title as string} item={item} searchValue={parameterGroupStore.searchValue} />;

      if (item.children) {
        return {
          id: item.id,
          name: item.name,
          fullName: item.fullName,
          key: item.key,
          title: title,
          children: mapItems(item.children),
        };
      }

      return {
        id: item.id,
        name: item.name,
        fullName: item.fullName,
        key: item.key,
        title: title,
      };
    });
  };

  useEffect(() => {
    runInAction(() => {
      informationBlockStore.checkedParamGroup = informationBlockStore.selectedInformationBlock.parameterGroupConfigurations.slice();
    });

    parameterGroupStore.getRootParameterGroupList().then(async () => {
      if (!parameterGroupStore.currentParameterGroup)
        parameterGroupStore.setCurrentParameterGroup(Number(parameterGroupStore.rootParameterGroup[0].id));
      await parameterGroupStore.getParameterGroups();
    });

    return () => {
      parameterGroupStore.resetParameterGroup();
    };
  }, []);

  const onClose = () => {
    runInAction(() => {
      informationBlockStore.checkedParamGroup = [];
    });

    informationBlockStore.setDialogParameterGroupOpen(false);
  };

  const onSaveInformationBlock = () => {
    informationBlockStore.selectedInformationBlock.addParamGroupConfigurations(informationBlockStore.checkedParamGroup);
    onClose();
  };

  const onExpand = (expandedKeysValue: React.Key[]) => {
    parameterGroupStore.setExpandedKeys(expandedKeysValue);
  };

  const onCheck = (checkedKeysValue: any, info: any) => {
    const group: IInfBlockParameterGroup = {
      id: info.node.key,
      name: info.node.fullName,
    };

    informationBlockStore.setCheckedParamGroup(group, info.checked);
  };

  const onScroll = (key: React.Key) => {
    parameterGroupRef.current.scrollTo({ key: key });
  };

  return (
    <DialogWrapper
      sx={{
        '& .MuiDialog-paper': { height: '600px', maxHeight: '600px', borderRadius: '12px', p: '40px 20px' },
      }}
      title={Translator.translate('common.informBlockDialog.infBlockParamGroupDialog.title')}
      open={informationBlockStore.isDialogParameterGroupOpen}
      maxWidth="md"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={onSaveInformationBlock}>
      <>
        <Stack height="36px" flexDirection="row" justifyContent="space-between" alignItems="center">
          <ParameterGroupTreeSelect width="40%" onScroll={onScroll} />

          <Box
            sx={{
              width: '40%',
            }}>
            <ParameterGroupTreeSearch onScroll={onScroll} />
          </Box>
        </Stack>

        {parameterGroupStore.currentParameterGroup && parameterGroupStore.parameterGroupTree.length !== 0 && (
          <TreeData
            height={315}
            ref={parameterGroupRef}
            selectedKey={[parameterGroupStore.selectedParamGroup.id!]}
            checkedKeys={informationBlockStore.checkedParamGroup.map((paramGroup) => paramGroup.id)}
            onCheck={onCheck}
            onExpand={onExpand}
            expandedKeys={parameterGroupStore.expandedKeys}
            treeData={mapItems(parameterGroupStore.parameterGroupTree)}
            isCheckStrictly={true}
          />
        )}
      </>
    </DialogWrapper>
  );
};

export default observer(InfBlockParamGroupDialog);
