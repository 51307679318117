import { useState } from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Translator, approvalStore, informationBlockStore, partialApprovalStore } from 'stores';
import { IconButtonNew, InformBlockDialog, InputValidate, Select, TextWrapper } from 'components';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import { Box, Button, Chip, Divider, Stack, Typography } from '@mui/material';
import UserDateSelect from '../UserDateSelect';
import {
  ApprovalDialogMode,
  ApprovalStatus,
  ApprovalUserRole,
  LangNames,
  PartialApprovalIteration,
  PartialApprovalIterationNames,
  PartialApprovalStatus,
  PartialApprovalStatusNames,
} from 'shared/enums';
import { Routes } from 'shared/enums/Routes';
import { IInformationBlockFormDto } from 'shared/interfaces';

interface IUserInfoWrapper {
  userName?: string;
  actionDate?: Date | null;
  iteration?: PartialApprovalIteration | null;
}

const UserInfoWrapper: React.FC<IUserInfoWrapper> = (props) => {
  const { userName, actionDate, iteration } = props;

  return (
    <Stack gap={1}>
      {userName && (
        <Typography variant="body2" color="text.primary">
          {userName}
        </Typography>
      )}

      {actionDate !== undefined && (
        <Typography color="text.secondary" variant="caption">
          {actionDate
            ? dayjs(actionDate).format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY')
            : Translator.translate('approvals.detailApprovalPage.notDone')}
        </Typography>
      )}

      {iteration && (
        <Typography color="text.secondary" variant="caption">
          {Translator.translate(PartialApprovalIterationNames.get(iteration))}
        </Typography>
      )}
    </Stack>
  );
};

const PartialApprovalDetail: React.FC = () => {
  const [isChangedName, setChangedName] = useState(false);
  const navigate = useNavigate();

  const paint = (status: PartialApprovalStatus) => {
    switch (status) {
      case PartialApprovalStatus.PendingApproval:
        return 'success';

      case PartialApprovalStatus.InProgress:
        return 'primary';

      case PartialApprovalStatus.Canceled:
        return 'secondary';

      case PartialApprovalStatus.Draft:
        return 'default';

      default:
        return 'secondary';
    }
  };

  const onSubmitInformBlock = (values: IInformationBlockFormDto) => {
    informationBlockStore.selectedInformationBlock.updateInformationBlockForm(values);
    partialApprovalStore.updateInfBlockPartialApproval().then(() => informationBlockStore.setDialogOpen(false));
  };

  return (
    <Stack p={8} gap={6}>
      {partialApprovalStore.selectedPartialApproval.status !== PartialApprovalStatus.Draft && (
        <Chip
          sx={{ width: 'fit-content' }}
          size="small"
          variant="outlined"
          label={Translator.translate(PartialApprovalStatusNames.get(partialApprovalStore.selectedPartialApproval.status)!)}
          color={paint(partialApprovalStore.selectedPartialApproval.status)}
        />
      )}

      {approvalStore.approvalDialogMode !== ApprovalDialogMode.Watch ? (
        <Stack direction="row" gap={6} alignItems="center">
          <InputValidate
            name="name"
            sx={{
              width: '100%',
              maxWidth: '250px',
            }}
            size="small"
            label={Translator.translate('approvals.detailApprovalPage.approvalFields.name')}
            required
            value={partialApprovalStore.selectedPartialApproval.name}
            onChange={(e: any) => {
              if (partialApprovalStore.selectedPartialApproval.name !== e.target.value) setChangedName(true);
              partialApprovalStore.selectedPartialApproval.setName(e.target.value);
            }}
            onBlur={async (e: any) => {
              if (isChangedName) {
                await partialApprovalStore.updatePartialApproval();
                setChangedName(false);
              }
            }}
          />

          <Select
            sx={{
              width: '100%',
              maxWidth: '250px',
            }}
            hideNotSelectItem
            label={Translator.translate('approvals.detailApprovalPage.approvalFields.infBlock')}
            value={partialApprovalStore.selectedPartialApproval.informationBlock?.id ?? ''}
            options={informationBlockStore.informationBlocksForOption}
            onChange={(e: any) => {
              partialApprovalStore.selectedPartialApproval.setInformationBlockId(e.target.value);
              partialApprovalStore.updateInfBlockPartialApproval();
            }}
            size="small"
          />

          <IconButtonNew
            tooltip={Translator.translate('actions.edit')}
            onClick={(e) => {
              informationBlockStore.setDialogOpen(true);
              partialApprovalStore.selectedPartialApproval.informationBlock.id &&
                informationBlockStore.getInformationBlockById(partialApprovalStore.selectedPartialApproval.informationBlock.id);
            }}>
            <EditPencilIcon />
          </IconButtonNew>

          {approvalStore.selectedApproval.status !== ApprovalStatus.Draft && (
            <Button
              variant="contained"
              onClick={() =>
                navigate(
                  `${Routes.APPROVALS}/${approvalStore.selectedApproval.id}/${Routes.PARTIAL_APPROVALS}/${partialApprovalStore.selectedPartialApproval.id}`
                )
              }>
              {Translator.translate('actions.open')}
            </Button>
          )}
        </Stack>
      ) : (
        <Stack direction="row" gap={10} alignItems="center">
          <TextWrapper
            name={Translator.translate('approvals.detailApprovalPage.approvalFields.name')}
            description={partialApprovalStore.selectedPartialApproval.name}
          />

          <TextWrapper
            name={Translator.translate('approvals.detailApprovalPage.approvalFields.infBlock')}
            description={
              <Stack direction="row" gap={4} alignItems="center">
                {partialApprovalStore.selectedPartialApproval.informationBlock.name}

                <Button
                  variant="text"
                  size="small"
                  onClick={(e) => {
                    informationBlockStore.setDialogOpen(true);
                    partialApprovalStore.selectedPartialApproval.informationBlock.id &&
                      informationBlockStore.getInformationBlockById(partialApprovalStore.selectedPartialApproval.informationBlock.id);
                  }}>
                  {Translator.translate('actions.moreInfo')}
                </Button>
              </Stack>
            }
          />

          <Button
            variant="contained"
            sx={{ alignItems: 'center' }}
            onClick={() =>
              navigate(
                `${Routes.APPROVALS}/${approvalStore.selectedApproval.id}/${Routes.PARTIAL_APPROVALS}/${partialApprovalStore.selectedPartialApproval.id}`
              )
            }>
            {Translator.translate('actions.open')}
          </Button>
        </Stack>
      )}

      <Divider
        sx={(theme) => ({
          borderRightWidth: 2,
          borderColor: theme.palette.background.default,
        })}
        orientation="horizontal"
      />

      <Stack id="users" direction="row">
        {approvalStore.approvalDialogMode !== ApprovalDialogMode.Watch ? (
          <Stack id="examiners" alignItems="flex-start" gap={6}>
            {partialApprovalStore.selectedPartialApproval.examiners.length > 0 && (
              <Stack sx={{ pt: 1 }} gap={4}>
                {partialApprovalStore.selectedPartialApproval.examiners.map((examiner, idx) => {
                  return (
                    <Stack gap={1} key={examiner.id}>
                      <UserDateSelect
                        required
                        label={Translator.translate('approvals.approvalUserRoleNames.examiner')}
                        users={partialApprovalStore.usersFilteredOptions(examiner)}
                        value={examiner}
                        showDate
                        onChangeUser={(userId) => {
                          if (examiner.id !== userId) {
                            examiner.setChanged(true);
                            examiner.setUserId(userId);
                          }
                        }}
                        onDelete={() => {
                          if (partialApprovalStore.selectedPartialApproval.examinersSnapshot.includes(examiner.id!)) {
                            partialApprovalStore.removeUserPartialApproval(examiner, idx);
                          } else {
                            partialApprovalStore.selectedPartialApproval.deleteExaminer(idx);
                          }
                        }}
                        onBlurUser={() => {
                          if (examiner.isChanged) {
                            if (partialApprovalStore.selectedPartialApproval.examinersSnapshot.includes(examiner.id!)) {
                              examiner.putDto && partialApprovalStore.updateUserPartialApproval(examiner.putDto);
                            } else {
                              examiner.postDto && partialApprovalStore.createUserPartialApproval(examiner.postDto, idx);
                            }
                            examiner.setChanged(false);
                          }
                        }}
                        onBlurExpiration={(value: Date | string) => {
                          const dateVal = value instanceof Date ? value : new Date(value);
                          if (
                            dayjs(examiner.expiration)?.format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY') !==
                            dayjs(dateVal).format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY')
                          ) {
                            examiner.setExpiration(dateVal);
                            examiner.putDto && partialApprovalStore.updateUserPartialApproval(examiner.putDto);
                          }
                        }}
                      />

                      <UserInfoWrapper actionDate={examiner.actionDate} iteration={examiner.iteration} />
                    </Stack>
                  );
                })}
              </Stack>
            )}

            <Button
              variant="text"
              startIcon={
                <Box
                  sx={(theme) => ({
                    svg: {
                      fill: theme.palette.primary.main,
                    },
                  })}>
                  <PlusIconForButton />
                </Box>
              }
              onClick={partialApprovalStore.selectedPartialApproval.addExaminer}>
              {Translator.translate('approvals.detailApprovalPage.addExaminerButtonTitle')}
            </Button>
          </Stack>
        ) : (
          <Stack id="examiners" alignItems="flex-start" gap={2}>
            <Typography color="text.secondary" variant="caption">
              {Translator.translate('approvals.approvalUserRoleNames.examiners')}
            </Typography>

            {partialApprovalStore.selectedPartialApproval.examiners.map((examiner) => {
              return (
                <UserInfoWrapper key={examiner.id} userName={examiner.userName} actionDate={examiner.actionDate} iteration={examiner.iteration} />
              );
            })}
          </Stack>
        )}

        <Divider
          sx={(theme) => ({
            mx: approvalStore.approvalDialogMode !== ApprovalDialogMode.Watch ? 6 : 8,
            height: 'auto',
            borderRightWidth: 2,
            borderColor: theme.palette.background.default,
          })}
          orientation="vertical"
        />

        {approvalStore.approvalDialogMode !== ApprovalDialogMode.Watch ? (
          <Stack id="observers" alignItems="flex-start" gap={6}>
            {partialApprovalStore.selectedPartialApproval.observers.map((observer, idx) => {
              return (
                <UserDateSelect
                  key={observer.id}
                  required
                  label={Translator.translate('approvals.approvalUserRoleNames.observer')}
                  users={partialApprovalStore.usersFilteredOptions(observer)}
                  value={observer}
                  onChangeUser={(userId) => {
                    if (observer.id !== userId) {
                      observer.setChanged(true);
                      observer.setUserId(userId);
                    }
                  }}
                  onDelete={() => {
                    if (partialApprovalStore.selectedPartialApproval.observersSnapshot.includes(observer.id!)) {
                      partialApprovalStore.removeUserPartialApproval(observer, idx);
                    } else {
                      partialApprovalStore.selectedPartialApproval.deleteObserver(idx);
                    }
                  }}
                  onBlurUser={() => {
                    if (observer.isChanged) {
                      if (partialApprovalStore.selectedPartialApproval.observersSnapshot.includes(observer.id!)) {
                        observer.putDto && partialApprovalStore.updateUserPartialApproval(observer.putDto);
                      } else {
                        observer.postDto && partialApprovalStore.createUserPartialApproval(observer.postDto, idx);
                      }
                      observer.setChanged(false);
                    }
                  }}
                />
              );
            })}

            <Button
              variant="text"
              startIcon={
                <Box
                  sx={(theme) => ({
                    svg: {
                      fill: theme.palette.primary.main,
                    },
                  })}>
                  <PlusIconForButton />
                </Box>
              }
              onClick={partialApprovalStore.selectedPartialApproval.addObserver}>
              {Translator.translate('approvals.detailApprovalPage.addObserverButtonTitle')}
            </Button>
          </Stack>
        ) : (
          <Stack id="observers" alignItems="flex-start" gap={2}>
            <Typography color="text.secondary" variant="caption">
              {Translator.translate('approvals.approvalUserRoleNames.observers')}
            </Typography>

            {partialApprovalStore.selectedPartialApproval.observers.map((observers) => {
              return <UserInfoWrapper key={observers.id} userName={observers.userName} />;
            })}
          </Stack>
        )}

        <Divider
          sx={(theme) => ({
            height: 'auto',
            mx: approvalStore.approvalDialogMode !== ApprovalDialogMode.Watch ? 6 : 8,
            borderRightWidth: 2,
            borderColor: theme.palette.background.default,
          })}
          orientation="vertical"
        />

        {approvalStore.approvalDialogMode !== ApprovalDialogMode.Watch ? (
          <Stack id="executor" gap={1}>
            <UserDateSelect
              widthUserField="300px"
              required
              label={Translator.translate('approvals.approvalUserRoleNames.executor')}
              value={partialApprovalStore.selectedPartialApproval.executor}
              showDate
              onChangeUser={(userId) => {
                if (partialApprovalStore.selectedPartialApproval.executor.id !== userId) {
                  partialApprovalStore.selectedPartialApproval.executor.setChanged(true);
                  partialApprovalStore.selectedPartialApproval.executor.setUserId(userId);
                }
              }}
              onBlurUser={() => {
                if (partialApprovalStore.selectedPartialApproval.executor.isChanged) {
                  partialApprovalStore.selectedPartialApproval.executor.setRole(ApprovalUserRole.Executor);
                  if (partialApprovalStore.selectedPartialApproval.executor.id && partialApprovalStore.selectedPartialApproval.executor.id !== 3.14) {
                    partialApprovalStore.selectedPartialApproval.executor.putDto &&
                      partialApprovalStore.updateUserPartialApproval(partialApprovalStore.selectedPartialApproval.executor.putDto);
                  } else {
                    partialApprovalStore.selectedPartialApproval.executor.postDto &&
                      partialApprovalStore.createUserPartialApproval(partialApprovalStore.selectedPartialApproval.executor.postDto);
                  }

                  partialApprovalStore.selectedPartialApproval.executor.setChanged(false);
                }
              }}
              onBlurExpiration={(value: Date | string) => {
                const dateVal = value instanceof Date ? value : new Date(value);
                if (
                  dayjs(partialApprovalStore.selectedPartialApproval.executor.expiration)?.format(
                    Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY'
                  ) !== dayjs(dateVal).format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY')
                ) {
                  partialApprovalStore.selectedPartialApproval.executor.setExpiration(dateVal);
                  partialApprovalStore.selectedPartialApproval.executor.putDto &&
                    partialApprovalStore.updateUserPartialApproval(partialApprovalStore.selectedPartialApproval.executor.putDto);
                }
              }}
            />

            <UserInfoWrapper
              actionDate={partialApprovalStore.selectedPartialApproval.executor.actionDate}
              iteration={partialApprovalStore.selectedPartialApproval.executor.iteration}
            />
          </Stack>
        ) : (
          <Stack id="observers" alignItems="flex-start" gap={2}>
            <Typography color="text.secondary" variant="caption">
              {Translator.translate('approvals.approvalUserRoleNames.executor')}
            </Typography>
            <UserInfoWrapper
              userName={partialApprovalStore.selectedPartialApproval.executor.userName}
              actionDate={partialApprovalStore.selectedPartialApproval.executor.actionDate}
              iteration={partialApprovalStore.selectedPartialApproval.executor.iteration}
            />
          </Stack>
        )}
      </Stack>

      {informationBlockStore.isDialogOpen && (
        <InformBlockDialog readOnly={approvalStore.approvalDialogMode === ApprovalDialogMode.Watch} onSubmit={onSubmitInformBlock} />
      )}
    </Stack>
  );
};

export default observer(PartialApprovalDetail);
