import { makeAutoObservable } from 'mobx';
import { IXmlSchema } from 'stores/XmlDocumentInstanceStore';
import {
  IClasses,
  IGetXmlDocumentNodeDto,
  IPostNodeDto,
  IPutXmlDocumentNodeDto,
  IXmlDocumentNodeAttributeDto,
  IXmlNodeForm,
} from 'shared/interfaces';
import { IdNameDto } from 'shared/interfaces/Dto/BaseDto';
import Utils from 'shared/utils/Utils';
import XmlDocumentAttributeModel from './XmlDocumentAttributeModel';

/** Узлы документа xml */
export class XmlDocumentNodeModel {
  constructor(private xmlSchema: IXmlSchema, dto?: any) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id ?? Utils.generateId();
    this.name = dto.name;
    this.type = dto.type;
    this.description = dto.description;
    this.selector = dto.selector ?? null;
    this.value = dto.value ?? null;
    this.parameterBindings = dto.parameterBindings ?? [];
    this.defaultParameterBindings = [...this.parameterBindings];
    this.classIdBind = dto.classIdBind !== null ? [dto.classIdBind] : [];
    this.parentId = dto.parentId;
    this.attributes = !dto.hasOwnProperty('attributes')
      ? []
      : dto.attributes.map((attribute: IXmlDocumentNodeAttributeDto) => new XmlDocumentAttributeModel(attribute));

    this.required = dto.hasOwnProperty('required') ? dto.required : false;
    this.isNewNode = !dto.id;
    this.isAttribute = dto.isAttribute;

    xmlSchema.updateExpandedTreeNodeIds(dto.id);

    this.classConstraints = [];

    /*this.classConstraints = dto.classes.map((sectionClass: IClassesPath) => {
      const key = sectionClass.path.map((value) => value.id).join('-');

      return {
        className: sectionClass.path[sectionClass.path.length - 1].name,
        parentName: sectionClass.path.length >= 2 ? sectionClass.path[sectionClass.path.length - 2].name : '',
        secondParentName: sectionClass.path.length >= 3 ? sectionClass.path[sectionClass.path.length - 3].name : '',
        classKey: key,
      };
    });*/

    this.defaultClassConstraints = [...this.classConstraints];

    if (dto.children.length > 0) {
      this.children = dto.children.map((value: IGetXmlDocumentNodeDto) => new XmlDocumentNodeModel(xmlSchema, value));
    }
  }

  public id: number | null = null;

  public name = '';

  public type = '';

  public description = '';

  public selector: string | null = null;

  public value: string | null = null;

  public parameterBindings: IdNameDto[] = [];

  public defaultParameterBindings: IdNameDto[] = [];

  public classIdBind: number[] = [];

  public parentId: number | null = null;

  public attributes: XmlDocumentAttributeModel[] = [];

  public required = false;

  public isNewNode = false;

  public isAttribute = false;

  public children: XmlDocumentNodeModel[] = [];

  public defaultClassConstraints: IClasses[] = [];

  public classConstraints: IClasses[] = [];

  public get parameterBindingIds(): number[] {
    return (this.parameterBindings as IdNameDto[]).map((data) => data.id) as number[];
  }

  public setClassConstraints(classConstraints: IClasses) {
    const idx = this.classConstraints.findIndex((f) => f.classKey === classConstraints.classKey);
    if (idx !== -1) {
      this.classConstraints.splice(idx, 1);
    } else {
      this.classConstraints.push(classConstraints);
    }
  }

  public setParameterBindings(parameterBinding: IdNameDto, isAdd: boolean) {
    const idx = this.parameterBindings.findIndex((f) => f.id === parameterBinding.id);

    if (isAdd) {
      if (idx === -1) this.parameterBindings.push(parameterBinding);
    } else {
      if (idx !== -1) this.parameterBindings.splice(idx, 1);
    }
  }

  public updateXmlNodeForm(data: IXmlNodeForm) {
    this.selector = data.selector;
    this.value = data.value;
    this.parameterBindings = data.parameterBindings;
    this.classIdBind = data.classIdBind;
  }

  public get postNodeDto(): IPostNodeDto {
    return {
      name: this.name,
      description: this.description,
      type: this.type,
      selector: this.selector,
      value: this.value,
      parameterBindings: this.parameterBindingIds,
      classIdBind: this.classIdBind.length > 0 ? this.classIdBind[0] : null,
      parentId: this.parentId,
      attributes: [],
    };
  }

  public get putNodeDto(): IPutXmlDocumentNodeDto | null {
    if (!this.id) return null;

    return {
      id: this.id,
      selector: this.selector,
      value: this.value,
      parameterBindings: this.parameterBindingIds,
      classIdBind: this.classIdBind.length > 0 ? this.classIdBind[0] : null,
      attributes: [],
    };
  }

  public clearModel() {
    this.id = null;
    this.name = '';
    this.type = '';
    this.description = '';
    this.selector = null;
    this.value = null;
    this.parameterBindings = [];
    this.classIdBind = [];
    this.parentId = null;
    this.attributes = [];
    this.children = [];
    this.classConstraints = [];
  }
}

export default XmlDocumentNodeModel;
