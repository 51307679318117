import { makeAutoObservable } from 'mobx';
import { SectionStructure } from 'shared/enums';
import { IClasses, IGetSectionDto, IPostSectionDto, IPutSectionDto, IClassesPath, ISectionForm, ISelectOption } from 'shared/interfaces';

class SectionModel {
  constructor(dto?: IGetSectionDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.orderNum = dto.orderNum;
    this.shortName = dto.shortName;
    this.parentId = dto.parentId;
    this.sectionSetId = dto.sectionSet.id;
    this.structure = dto.structure;
    this.isNewLineParameters = dto.isNewLineParameters;
    this.classConstraints = dto.classes.map((sectionClass: IClassesPath) => {
      const key = sectionClass.path.map((value) => value.id).join('-');

      return {
        className: sectionClass.path[sectionClass.path.length - 1].name,
        parentName: sectionClass.path.length >= 2 ? sectionClass.path[sectionClass.path.length - 2].name : '',
        secondParentName: sectionClass.path.length >= 3 ? sectionClass.path[sectionClass.path.length - 3].name : '',
        classKey: key,
      };
    });
    this.defaultClassConstraints = [...this.classConstraints];
  }

  public id: number | null = null;

  public name = '';

  public shortName = '';

  public structure: SectionStructure = SectionStructure.ItemsGroups;

  public orderNum: number | null = null;

  public parentId: number | null = null;

  public sectionSetId: number | null = null;

  public isNewLineParameters = false;

  public defaultClassConstraints: IClasses[] = [];

  public classConstraints: IClasses[] = [];

  public collapsed = true;

  public updateSectionDialogForm(value: ISectionForm) {
    this.name = value.name;
    this.shortName = value.shortName;
    this.isNewLineParameters = value.isNewLineParameters;
    this.structure = value.structure;
  }

  public postDto(sectionSetId?: number): IPostSectionDto | null {
    if (this.name === '') return null;

    return {
      name: this.name,
      shortName: this.shortName,
      sectionSetId: sectionSetId ? sectionSetId : this.sectionSetId!,
      parentId: this.parentId,
      structure: this.structure,
      isNewLineParameters: this.isNewLineParameters,
    };
  }

  public putDto(sectionSetId?: number): IPutSectionDto | null {
    if (this.name === '' || this.orderNum === null || this.orderNum === -1) return null;

    const classes: Array<number[]> = [];
    this.classConstraints.forEach((classConstraint: IClasses) => {
      const classPath = classConstraint.classKey.split('-').map((value) => Number(value));
      classes.push(classPath);
    });

    return {
      name: this.name,
      orderNum: this.orderNum,
      shortName: this.shortName,
      sectionSetId: sectionSetId ? sectionSetId : this.sectionSetId!,
      parentId: this.parentId,
      classConstraints: classes,
      structure: this.structure,
      isNewLineParameters: this.isNewLineParameters,
    };
  }

  public setId(id: number) {
    this.id = id;
  }

  public setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed;
  }

  public setOrderNum(orderNum: number) {
    this.orderNum = orderNum;
  }

  public setParentId(id: number) {
    this.parentId = id;
  }

  public setClassConstraints(classConstraints: IClasses) {
    const idx = this.classConstraints.findIndex((f) => f.classKey === classConstraints.classKey);
    if (idx !== -1) {
      this.classConstraints.splice(idx, 1);
    } else {
      this.classConstraints.push(classConstraints);
    }
  }

  public getSelectOption(): ISelectOption | undefined {
    if (!this.id) return;

    return { id: this.id, name: this.shortName };
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.orderNum = null;
    this.shortName = '';
    this.parentId = null;
    this.sectionSetId = null;
    this.classConstraints = [];
    this.structure = SectionStructure.ItemsGroups;
  }
}

export default SectionModel;
