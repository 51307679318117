import React from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, informationBlockStore } from 'stores';
import { IconButtonNew, TableView } from 'components';
import TrashIcon from 'components/Icons/TrashIcon';
import { Box, Stack, Typography } from '@mui/material';
import { IInfBlockParameterGroup, ITableColumn } from 'shared/interfaces';
import InfBlockParamGroupDialog from './InfBlockParamGroupDialog';

interface IEditInfBlockParamGroupTableProps {
  readOnly?: boolean;
}

const EditInfBlockParamGroupTable: React.FC<IEditInfBlockParamGroupTableProps> = (props) => {
  const { readOnly } = props;
  const { selectedInformationBlock } = informationBlockStore;

  const columns: ITableColumn[] = [
    {
      key: 'name',
      label: Translator.translate('common.informBlockDialog.editInfBlockParamGroupTable.columns.name'),
      width: '400px',
      align: 'left',
      cell: (row: IInfBlockParameterGroup) => {
        return (
          <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
            {row.name}
          </Typography>
        );
      },
    },
    {
      key: 'actions',
      label: '',
      width: '50px',
      align: 'right',
      cell: (row: IInfBlockParameterGroup, idx: number | undefined) => {
        return (
          <Stack sx={{ mr: 2 }} flexDirection="row" gap={2} justifyContent="flex-end">
            <IconButtonNew
              onClick={() => {
                onRemoveInformationBlock(row, idx!);
              }}
              tooltip={Translator.translate('actions.delete')}>
              <TrashIcon />
            </IconButtonNew>
          </Stack>
        );
      },
    },
  ];

  const readColumns: ITableColumn[] = [
    {
      key: 'name',
      label: 'Включённые группы параметров',
      width: '100%',
      align: 'left',
      cell: (row: IInfBlockParameterGroup) => {
        return (
          <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
            {row.name}
          </Typography>
        );
      },
    },
  ];

  const onRemoveInformationBlock = (group: IInfBlockParameterGroup, idx: number) => {
    informationBlockStore.selectedInformationBlock.deleteParamGroupConfigurations(idx);
  };

  return (
    <>
      {selectedInformationBlock.parameterGroupConfigurations.length > 0 && (
        <Box sx={{ height: readOnly ? '420px' : '290px', maxHeight: readOnly ? '420px' : '290px', overflow: 'auto' }}>
          <TableView
            data={selectedInformationBlock.parameterGroupConfigurations}
            isActionsHover={!readOnly}
            readOnly={readOnly}
            columns={readOnly ? readColumns : columns}
            hideSelectRowOnMount
          />
        </Box>
      )}

      {informationBlockStore.isDialogParameterGroupOpen && <InfBlockParamGroupDialog />}
    </>
  );
};

export default observer(EditInfBlockParamGroupTable);
