import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Translator, commentStore } from 'stores';
import { DialogWrapper, InputSearch, IconButtonNew, PopupMenu } from 'components';
import CloseDialogIcon from 'components/Icons/CloseDialogIcon';
import CommentSendIcon from 'components/Icons/CommentSendIcon';
import MoreVerticalIcon from 'components/Icons/MoreVerticalIcon';
import Input from 'components/UI/Input';
import { DialogActions, DialogContent, DialogTitle, Box, Stack, CircularProgress, Typography, MenuItem } from '@mui/material';
import { useConfirm } from '../Confirm';
import { CommentMode, LangNames } from 'shared/enums';
import { IComment } from 'shared/interfaces';

interface ICommentDialogProps {
  isOpenDialog: boolean;
  title: string;
}

const CommentDialog: React.FC<ICommentDialogProps> = (props) => {
  const { title, isOpenDialog } = props;

  const confirm = useConfirm();

  useEffect(() => {
    return () => {
      commentStore.clearSelectedComment();
      commentStore.setCommentText('');
      commentStore.comments = [];
    };
  }, []);

  const onEditComment = (item: IComment) => {
    commentStore.selectedComment = { id: item.id, text: item.text };
    commentStore.setCommentText(item.text);
  };

  const onDeleteComment = (item: IComment) => {
    confirm.show(Translator.translate('common.commentDialog.confirmDeleteMessage'), Translator.translate('actions.delete')).then(() => {
      if (commentStore.mode !== CommentMode.ApprovalRow && item.id) commentStore.deleteComment(item.id);
    });
  };

  const getButtons = (item: IComment): JSX.Element[] => {
    return [
      <MenuItem sx={{ width: '150px' }} key={1} component="li" onClick={() => onEditComment(item)}>
        {Translator.translate('actions.edit')}
      </MenuItem>,
      <MenuItem sx={{ width: '150px' }} key={2} component="li" onClick={() => onDeleteComment(item)}>
        {Translator.translate('actions.delete')}
      </MenuItem>,
    ];
  };

  const onCloseEditComment = () => {
    commentStore.clearSelectedComment();
    commentStore.setCommentText('');
  };

  return (
    <DialogWrapper isCustom open={isOpenDialog} maxWidth="md" onClose={() => commentStore.setOpenModal(false)}>
      <DialogTitle>
        <Typography variant="h6" color="primary.dark">
          {Translator.translate('common.commentDialog.title')}
        </Typography>

        <Typography variant="caption" color="text.secondary">
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ height: '50vh', maxHeight: '50vh', display: 'flex', flexDirection: 'column', gap: 4 }}>
        <InputSearch
          value={commentStore.filterPattern}
          onSearch={() => console.log('1')}
          onChange={(e) => commentStore.setFilterPattern(e.target.value)}
        />

        {commentStore.isLoading && (
          <Stack justifyContent="center" alignItems="center" sx={{ p: '0px 20px', minHeight: '200px', maxHeight: '200px' }}>
            <CircularProgress />
          </Stack>
        )}

        {!commentStore.isLoading &&
          (commentStore.filterComments.length > 0 ? (
            <Stack
              gap={4}
              sx={{
                width: '100%',
                p: 0,
                height: 'calc(100%-56px)',
                maxHeight: 'calc(100%-56px)',
                overflow: 'auto',
              }}>
              {commentStore.filterComments.map((item) => {
                return (
                  <Stack gap={1} key={item.id}>
                    <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                      <Stack flexDirection="row" alignItems="center">
                        <Typography variant="subtitle2" color="text.primary" sx={{ marginRight: '8px' }}>
                          {item.user.name}
                        </Typography>

                        <Typography variant="caption" color="text.disabled">
                          {item.modifiedAt && commentStore.mode !== CommentMode.ApprovalRow
                            ? `${Translator.translate('common.commentDialog.changed')} ${dayjs(item.modifiedAt).format(
                                Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY HH:mm' : 'MM/DD/YYYY h:mm A'
                              )}`
                            : dayjs(item.createdAt).format(
                                Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY HH:mm' : 'MM/DD/YYYY h:mm A'
                              )}
                        </Typography>
                      </Stack>

                      {commentStore.mode !== CommentMode.ApprovalRow && item.isMyComment && !item.isArchived && (
                        <PopupMenu
                          button={
                            <IconButtonNew>
                              <Box
                                sx={{
                                  svg: {
                                    rotate: '90deg',
                                  },
                                }}>
                                <MoreVerticalIcon />
                              </Box>
                            </IconButtonNew>
                          }
                          isCloseOnSelect
                          menuItems={getButtons(item as any)}
                        />
                      )}
                    </Stack>

                    <Typography variant="body2" color="text.primary" sx={{ fontStyle: item.isArchived ? 'italic' : 'normal' }}>
                      {item.text}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          ) : (
            <Typography variant="body2" color="text.primary">
              {Translator.translate('common.commentDialog.emptyComments')}
            </Typography>
          ))}
      </DialogContent>

      <DialogActions sx={{ px: 5 }}>
        <Stack width="100%" gap={3}>
          {commentStore.selectedComment.id && (
            <Stack gap={1}>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography variant="caption" color="text.disabled">
                  {Translator.translate('actions.edit')}
                </Typography>

                <IconButtonNew onClick={onCloseEditComment}>
                  <CloseDialogIcon />
                </IconButtonNew>
              </Stack>

              <Typography variant="body2" color="text.primary">
                {commentStore.selectedComment.text}
              </Typography>
            </Stack>
          )}

          <Stack width="100%" gap={3} flexDirection="row" alignItems="flex-end">
            <Input
              size="small"
              variant="outlined"
              value={commentStore.commentText}
              sx={{ width: '100%' }}
              multiline
              autoFocus
              label={Translator.translate('common.commentDialog.inputLabel')}
              onChange={(value) => commentStore.setCommentText(value)}
              inputProps={{ maxLength: '256' }}
            />

            <IconButtonNew
              sx={(theme) => ({
                mb: 1.5,
                svg: {
                  fill: theme.palette.primary.main,
                  fillOpacity: 1,
                },
              })}
              onClick={() => commentStore.sendMessage()}
              tooltip={Translator.translate('actions.send')}>
              <CommentSendIcon />
            </IconButtonNew>
          </Stack>
        </Stack>
      </DialogActions>
    </DialogWrapper>
  );
};

export default observer(CommentDialog);
