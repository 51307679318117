import React from 'react';

const CommentIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.76809 10.3043C3.08497 9.15181 2.84602 7.78957 3.0961 6.47335C3.34619 5.15714 4.06811 3.97746 5.12633 3.1558C6.18455 2.33413 7.5063 1.92698 8.84344 2.01078C10.1806 2.09457 11.4412 2.66356 12.3885 3.61092C13.3359 4.55827 13.9049 5.81885 13.9886 7.15599C14.0724 8.49313 13.6653 9.81488 12.8436 10.8731C12.022 11.9313 10.8423 12.6532 9.52607 12.9033C8.20986 13.1534 6.84762 12.9145 5.69509 12.2313L2.86444 13.6183C2.78643 13.6411 2.70372 13.6426 2.62497 13.6224C2.54623 13.6023 2.47436 13.5613 2.41688 13.5038C2.35941 13.4464 2.31846 13.3745 2.29831 13.2957C2.27817 13.217 2.27958 13.1343 2.3024 13.0563L3.76809 10.3043Z"
        stroke="#0F0B1F"
        strokeOpacity="0.54"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CommentIcon;
