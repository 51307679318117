import React, { forwardRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldDataNode } from 'rc-tree/lib/interface';
import { parameterGroupStore, parameterStore } from 'stores';
import { TreeData, ButtonItemTitle } from 'components/App/TreeData';
import { ParameterGroupModel } from 'shared/models';

const COMPACT_WIDTH_TREE = 375;

interface IParamTreeItemProps {
  title: React.ReactNode;
  name: string;
  id: number | null;
  key: string | number;
}

declare type IParamTreeItem = FieldDataNode<IParamTreeItemProps>;

interface IParamTreeProps {
  onAdd: (value: ParameterGroupModel) => void;
  onDelete: (value: ParameterGroupModel) => void;
  onEdit: (value: ParameterGroupModel) => void;
  contentHeight: number;
}

const ParamTree = forwardRef<any, IParamTreeProps>((props, ref) => {
  const [currentNode, setCurrentNode] = useState<React.Key | null>();

  const onCheck = (checkedKeysValue: any, info: any) => {
    const ids: string[] = info.checkedNodes.map((checkedNode: any) => {
      return checkedNode.key;
    });
    parameterGroupStore.setCheckedParameterGroup(ids);
  };

  const onExpand = (expandedKeysValue: React.Key[]) => {
    parameterGroupStore.setExpandedKeys(expandedKeysValue);
  };

  const onHover = (key: React.Key | null) => {
    setCurrentNode(key);
  };

  const mapItems = (data: ParameterGroupModel[]): IParamTreeItem[] => {
    return data.map((item) => {
      const title = (
        <ButtonItemTitle
          title={item.title as string}
          item={item}
          searchValue={parameterGroupStore.searchValue}
          hovered={item.key === currentNode}
          onAdd={props.onAdd}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
          compact={item.key === currentNode && parameterGroupStore.treeParameterGroupWidth < COMPACT_WIDTH_TREE}
        />
      );

      if (item.children) {
        return {
          ...item,
          title: title,
          children: mapItems(item.children),
        };
      }

      return {
        ...item,
        title: title,
      };
    });
  };

  return (
    <TreeData
      treeData={mapItems(parameterGroupStore.parameterGroupTree)}
      ref={ref}
      expandedKeys={parameterGroupStore.expandedKeys}
      checkedKeys={parameterGroupStore.checkedKeys}
      selectedKey={[parameterStore.filterParameters.parameterGroupId!]}
      onCheck={onCheck}
      onSelect={parameterGroupStore.setSelectParameterGroup}
      onExpand={onExpand}
      height={document.documentElement.clientHeight - props.contentHeight}
      // itemHeight={28} // Uncomment to enable virtual scroll
      isCheckStrictly={true}
      onHover={onHover}
    />
  );
});

ParamTree.displayName = 'ParamTree';

export default observer(ParamTree);
