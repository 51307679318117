import { BASE_PATH } from 'shared/constants/constants';
import { IGetCommentsDto, IPostCommentDto, IPutCommentDto, ICommentParams, IGetCommentsSummaryDto } from 'shared/interfaces';
import HTTPService from './HttpService';

class CommentService extends HTTPService {
  constructor() {
    super(BASE_PATH.COMMENTS);
  }

  public getComments(params: ICommentParams): Promise<IGetCommentsDto[]> {
    return this.GET('', { params });
  }

  public getCommentsSummary(definitionId: number): Promise<IGetCommentsSummaryDto> {
    return this.GET('summary', { params: { definitionId } });
  }

  public postComment(body: IPostCommentDto): Promise<number> {
    return this.POST('', body);
  }

  public putComment(id: number, body: IPutCommentDto): Promise<void> {
    return this.PUT(`${id}`, body);
  }

  public deleteComment(id: number): Promise<void> {
    return this.DELETE(`${id}`);
  }
}

export default new CommentService();
