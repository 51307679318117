import { makeAutoObservable } from 'mobx';
import { DataNode } from 'rc-tree/lib/interface';
import { ISearch } from 'shared/interfaces';

class TreeModel {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  //TODO: разобраться с типом treeData (rc-tree)
  public data: any[] = [];

  public expandedKeys: React.Key[] = this.getKeys(this.data).map((item) => item.key);

  public checkedKeys: React.Key[] = [];

  public selectedKeys: React.Key[] = [];

  //для каждого дерева свои поля
  public selected: any | null = null;

  //для каждого дерева свои поля
  public checkedNodesInfo: any[] = [];

  public autoExpandParent = false;

  public searchValue = '';

  public clickSearch = false;

  public searchArrays: ISearch[] = [];

  //TODO: FIX ME
  public setTreeData(data: any) {
    this.data = data;
  }

  public updateTreeData(data: any) {
    this.setTreeData([...data]);
  }

  public setExpandedKeys(expandedKeys: React.Key[]) {
    this.expandedKeys = expandedKeys;
  }

  public setCheckedKeys(checkedKeys: React.Key[], info?: any) {
    this.checkedKeys = checkedKeys;

    if (info !== undefined) this.checkedNodesInfo = info.checkedNodes;
  }

  public setSelectedKeys(selectedKeys: React.Key[], info?: any) {
    this.selectedKeys = selectedKeys;
    if (info !== undefined) this.selected = info.selectedNodes[0];
  }

  public setAutoExpandParent(expandParent: boolean) {
    this.autoExpandParent = expandParent;
  }

  public setSearchValue(searchValue: string) {
    this.searchValue = searchValue;
  }

  public setClickSearch(clickSearch: boolean) {
    this.clickSearch = clickSearch;
  }

  public setSearchArrays(searchArrays: ISearch[]) {
    this.searchArrays = searchArrays;
  }

  public getKeys(treeObject: any): { key: React.Key; title: string }[] {
    const dataList: { key: React.Key; title: string }[] = [];

    const loop = (treeObject: DataNode[]) => {
      for (let i = 0; i < treeObject.length; i++) {
        const node = treeObject[i];
        const title = node.title?.toString();
        const key = node.key;
        if (title) dataList.push({ key, title });
        if (node.children) {
          loop(node.children);
        }
      }
    };

    loop(treeObject);
    return dataList;
  }

  public getParentKey(key: React.Key, tree: DataNode[]): React.Key {
    let parentKey: React.Key = '';
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (this.getParentKey(key, node.children)) {
          parentKey = this.getParentKey(key, node.children);
        }
      }
    }

    return parentKey;
  }

  //Все родители нужны при поиске в дереве, чтобы раскрыть родительские узлы для найденного элемента
  public getParentsKeys(key: React.Key, tree: DataNode[]): React.Key[] {
    const parentsKeys: React.Key[] = [];

    const loop = (key: React.Key) => {
      let parentKey: React.Key = '';

      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          if (node.children.some((item) => item.key === key)) {
            parentKey = node.key;
          } else if (this.getParentKey(key, node.children)) {
            parentKey = this.getParentKey(key, node.children);
          }
        }

        if (parentKey !== '') {
          if (!parentsKeys.includes(parentKey)) parentsKeys.push(parentKey);
          loop(parentKey);
        }
      }
    };

    loop(key);

    return parentsKeys;
  }

  public findNodeIntoTree = (data: any[], key: React.Key, callback: (node: any, i: number, data: any[]) => void) => {
    for (let i = 0; i < data.length; i++) {
      let dataKey: string | number;
      if (String(data[i].key).includes('.')) dataKey = String(data[i].key).split('.')[0];
      else dataKey = data[i].key;

      if (dataKey === key) {
        return callback(data[i], i, data);
      }

      if (data[i].children) {
        this.findNodeIntoTree(data[i].children!, key, callback);
      }
    }
  };

  public resetCheckedSelectedNodes() {
    this.checkedKeys = [];
    this.selectedKeys = [];
  }

  public clearModel() {
    this.data = [];
    this.expandedKeys = [];
    this.checkedKeys = [];
    this.selectedKeys = [];
    this.selected = null;
    this.checkedNodesInfo = [];
    this.autoExpandParent = false;
    this.searchValue = '';
    this.clickSearch = false;
    this.searchArrays = [];
  }
}

export default TreeModel;
