import React, { useState, ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink, useLocation } from 'react-router-dom';
import { Translator, appStore, definitionStore, usersStore } from 'stores';
import ApprovalIcon from 'components/Icons/ApprovalIcon';
import CatalogIcon from 'components/Icons/CatalogIcon';
import DiscussionIcon from 'components/Icons/DiscussionIcon';
import LanguageIcon from 'components/Icons/LanguageIcon';
import MergeIcon from 'components/Icons/MergeIcon';
import ObjectClassifierIcon from 'components/Icons/ObjectClassifierIcon';
import ObjectValueIcon from 'components/Icons/ObjectValueIcon';
import ParamsClassifierIcon from 'components/Icons/ParamsClassifierIcon';
import ProjectIcon from 'components/Icons/ProjectIcon';
import SettingsPageIcon from 'components/Icons/SettingsPageIcon';
import TaskIcon from 'components/Icons/TaskIcon';
import XMLIcon from 'components/Icons/XMLIcon';
import PopupMenu from 'components/UI/PopupMenu';
import { Avatar, Divider, Drawer, List, ListItemButton, Stack, Tooltip, Typography, MenuItem } from '@mui/material';
import { LangFullNames, LangNames, PopupPosition, RolesNames } from 'shared/enums';
import { Routes, Pages } from 'shared/enums/Routes';

import { IRouteObject } from 'shared/interfaces';
import UserInfo from './Components';
import s from './SideBar.module.scss';

const MAX_DRAWER = 260;
const MIN_DRAWER = 64;

const SideBar: React.FC = () => {
  const location = useLocation();

  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const projectPages = (): IRouteObject[] => {
    let pagesArr = [
      {
        value: Routes.OBJECT_VALUE,
        url: `${definitionStore.currentDefinition.id}${Routes.OBJECT_VALUE}`,
        icon: <ObjectValueIcon />,
        label: Translator.translate(Pages.get(Routes.OBJECT_VALUE)),
        disabled: !definitionStore.currentDefinition.id,
      },
      {
        value: Routes.SPECIFICATIONS,
        url: `${definitionStore.currentDefinition.id}${Routes.SPECIFICATIONS}`,
        icon: <TaskIcon />,
        label: Translator.translate(Pages.get(Routes.SPECIFICATIONS)),
        disabled: !definitionStore.currentDefinition.id,
      },
      {
        value: Routes.COMMENTS,
        url: `${definitionStore.currentDefinition.id}${Routes.COMMENTS}`,
        icon: <DiscussionIcon />,
        label: Translator.translate(Pages.get(Routes.COMMENTS)),
        disabled: false,
      },
      {
        value: Routes.MERGE,
        url: `${definitionStore.currentDefinition.id}${Routes.MERGE}`,
        icon: <MergeIcon />,
        label: Translator.translate(Pages.get(Routes.MERGE)),
        disabled: !definitionStore.currentDefinition.id,
      },
      {
        value: Routes.XML_VIEWER,
        url: `${definitionStore.currentDefinition.id}${Routes.XML_VIEWER}`,
        icon: <XMLIcon />,
        label: Translator.translate(Pages.get(Routes.XML_VIEWER)),
        disabled: !definitionStore.currentDefinition.id,
      },
    ];

    if (!appStore.isShowComments) {
      pagesArr = pagesArr.filter((f) => f.value !== Routes.COMMENTS);
    }

    if (definitionStore.currentDefinition.id) {
      pagesArr.unshift({
        value: Routes.MAIN,
        url: Routes.MAIN,
        icon: <ProjectIcon />,
        label: Translator.translate(Pages.get(Routes.MAIN)),
        disabled: false,
      });
    }

    return pagesArr;
  };

  const commonPages = (): IRouteObject[] => {
    const pagesArr = [
      {
        value: Routes.CLASSES,
        url: definitionStore.currentDefinition.id ? `${definitionStore.currentDefinition.id}${Routes.CLASSES}` : Routes.CLASSES,
        icon: <ObjectClassifierIcon />,
        label: Translator.translate(Pages.get(Routes.CLASSES)),
        disabled: false,
      },
      {
        value: Routes.APPROVALS,
        url: definitionStore.currentDefinition.id ? `${definitionStore.currentDefinition.id}${Routes.APPROVALS}` : Routes.APPROVALS,
        icon: <ApprovalIcon />,
        label: Translator.translate(Pages.get(Routes.APPROVALS)),
        disabled: false,
      },
      {
        value: Routes.PARAM,
        url: definitionStore.currentDefinition.id ? `${definitionStore.currentDefinition.id}${Routes.PARAM}` : Routes.PARAM,
        icon: <ParamsClassifierIcon />,
        label: Translator.translate(Pages.get(Routes.PARAM)),
        disabled: false,
      },
      {
        value: Routes.CATALOG,
        url: definitionStore.currentDefinition.id ? `${definitionStore.currentDefinition.id}${Routes.CATALOG}` : Routes.CATALOG,
        icon: <CatalogIcon />,
        label: Translator.translate(Pages.get(Routes.CATALOG)),
        disabled: false,
      },
      {
        value: Routes.SETTINGS,
        url: definitionStore.currentDefinition.id ? `${definitionStore.currentDefinition.id}${Routes.SETTINGS}` : Routes.SETTINGS,
        icon: <SettingsPageIcon />,
        label: Translator.translate(Pages.get(Routes.SETTINGS)),
        disabled: false,
      },
    ];

    if (!definitionStore.currentDefinition.id) {
      pagesArr.unshift({
        value: Routes.MAIN,
        url: Routes.MAIN,
        icon: <ProjectIcon />,
        label: Translator.translate(Pages.get(Routes.MAIN)),
        disabled: false,
      });
    }

    return pagesArr;
  };

  const checkCurrentRoute = (route: IRouteObject) => {
    return route.value !== Routes.MAIN ? location.pathname.includes(route.url) : location.pathname === route.url;
  };

  const getRouteNavigate = (route: IRouteObject) => {
    switch (route.value) {
      case Routes.CATALOG:
        return `${route.url}/${Routes.DICTIONARIES}`;

      case Routes.SETTINGS:
        return `${route.url}/${Routes.ROLES}`;

      default:
        return route.url;
    }
  };

  const sideBarContent = (routes: IRouteObject[]) => {
    return (
      <>
        {routes.map((route) => (
          <ListItemButton className={s.sideBar__item} component="li" key={route.value} disabled={route.disabled} disableRipple>
            <NavLink to={getRouteNavigate(route)} className={s.sideBar__link} onClick={() => appStore.setCollapseSideBar(false)}>
              <Stack
                sx={(theme) => ({
                  color: checkCurrentRoute(route) ? theme.palette.primary.dark : '',
                  background: checkCurrentRoute(route) ? theme.palette.grey[200] : '',
                  svg: {
                    fill: checkCurrentRoute(route) ? theme.palette.primary.dark : '',
                    fillOpacity: checkCurrentRoute(route) ? 1 : '',
                  },
                  ':hover': {
                    borderRadius: '6px',
                    backgroundColor: theme.palette.grey[200],
                  },
                })}
                direction="row"
                alignItems="center"
                borderRadius="6px">
                <Tooltip
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  }}
                  arrow
                  title={!appStore.isCollapseSideBar && route.label ? route.label : ''}
                  placement="right">
                  <span className={s.sideBar__btn}>{route.icon}</span>
                </Tooltip>

                {appStore.isCollapseSideBar && <Typography variant="body1">{route.label}</Typography>}
              </Stack>
            </NavLink>
          </ListItemButton>
        ))}
      </>
    );
  };

  const onChangeLocaleRu = () => {
    Translator.onChangeLocalization(LangNames.Ru);
    appStore.setCollapseSideBar(false);
  };

  const onChangeLocaleEn = () => {
    Translator.onChangeLocalization(LangNames.En);
    appStore.setCollapseSideBar(false);
  };

  const localeOptions: ReactElement[] = [
    <MenuItem
      sx={(theme) => ({
        ':hover': {
          borderRadius: '6px',
          backgroundColor: theme.palette.grey[200],
        },
        color: Translator.selectedLocalization === LangNames.Ru ? theme.palette.primary.main : 'inherit',
        background: Translator.selectedLocalization === LangNames.Ru ? theme.palette.grey[200] : 'inherit',
        padding: '6px 12px',
        borderRadius: '6px',
      })}
      key={'ru'}
      component="li"
      onClick={onChangeLocaleRu}>
      {Translator.translate(LangFullNames.get(LangNames.Ru))}
    </MenuItem>,
    <MenuItem
      sx={(theme) => ({
        ':hover': {
          borderRadius: '6px',
          backgroundColor: theme.palette.grey[200],
        },
        color: Translator.selectedLocalization === LangNames.En ? theme.palette.primary.main : 'inherit',
        background: Translator.selectedLocalization === LangNames.En ? theme.palette.grey[200] : 'inherit',
        padding: '6px 12px',
        borderRadius: '6px',
      })}
      key={'en'}
      component="li"
      onClick={onChangeLocaleEn}>
      {Translator.translate(LangFullNames.get(LangNames.En))}
    </MenuItem>,
  ];

  return (
    <Drawer
      transitionDuration={0}
      open={appStore.isCollapseSideBar}
      className={s.drawer}
      variant={appStore.isCollapseSideBar ? 'temporary' : 'permanent'}
      onClose={() => appStore.setCollapseSideBar(false)}
      BackdropProps={{ invisible: true }}
      PaperProps={{
        sx: {
          mt: '50px',
        },
      }}
      elevation={0}
      sx={{
        width: appStore.isCollapseSideBar ? MAX_DRAWER : MIN_DRAWER,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: appStore.isCollapseSideBar ? MAX_DRAWER : MIN_DRAWER,
          boxSizing: 'border-box',
          borderRight: 'none',
        },
      }}>
      <Stack alignItems={appStore.isCollapseSideBar ? 'unset' : 'center'} pb={'64px'} justifyContent="space-between" height="100%" bgcolor="#f2f2f9">
        <nav>
          <List
            className={s.sideBar__list}
            sx={{
              // hover states
              '& .MuiListItemButton-root:hover': {
                bgcolor: 'unset',
                '&, & .MuiListItemIcon-root': {
                  color: 'unset',
                },
              },
            }}>
            {definitionStore.currentDefinition.id && sideBarContent(projectPages())}
            {definitionStore.currentDefinition.id && <Divider sx={{ mx: 5 }} />}
            {sideBarContent(commonPages())}
          </List>
        </nav>

        <Stack
          ml={appStore.isCollapseSideBar ? 2.5 : 0}
          mr={appStore.isCollapseSideBar ? 2.5 : 0}
          flexDirection="column"
          gap={4}
          alignItems="flex-start">
          <PopupMenu
            position={PopupPosition.Bottom}
            sx={(theme: any) => ({
              ':hover': {
                borderRadius: '6px',
                backgroundColor: theme.palette.grey[200],
              },
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'flex-start !important',
              justifyContent: 'flex-start !important',
              width: '100%',
            })}
            button={
              <Stack direction="row" alignItems="center" borderRadius="6px">
                <Tooltip
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  }}
                  arrow
                  title={!appStore.isCollapseSideBar ? Translator.translate(LangFullNames.get(Translator.selectedLocalization)) : ''}
                  placement="right">
                  <span className={s.sideBar__btn}>
                    <LanguageIcon />
                  </span>
                </Tooltip>

                {appStore.isCollapseSideBar && (
                  <Typography variant="body1">{Translator.translate(LangFullNames.get(Translator.selectedLocalization))}</Typography>
                )}
              </Stack>
            }
            isCloseOnSelect
            menuItems={localeOptions}
          />

          <Stack
            flexDirection="row"
            gap={3}
            pl={appStore.isCollapseSideBar ? 1.5 : 0}
            justifyContent={appStore.isCollapseSideBar ? 'flex-start' : 'center'}
            alignItems="center"
            width="100%"
            height="44px">
            <Avatar
              sx={{
                width: 32,
                height: 32,
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '20px',
                cursor: 'pointer',
                background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0) 100%), #7448DD',
              }}
              onClick={(e) => setUserAnchorEl(e.currentTarget)}>
              {usersStore.userInitials}
            </Avatar>

            {appStore.isCollapseSideBar && (
              <Stack justifyContent="flex-end">
                <Typography variant="subtitle1">{usersStore.getUserData().name}</Typography>
                <Tooltip
                  onClose={handleClose}
                  onOpen={handleOpen}
                  open={usersStore.getUserData().roles.length > 1 && open}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  }}
                  arrow
                  title={usersStore
                    .getUserData()
                    .roles?.map((_) => Translator.translate(RolesNames.get(_)))
                    .join(', ')}
                  placement="right">
                  <Typography width="200px" noWrap overflow="hidden" textOverflow="ellipsis" variant="caption">
                    {usersStore.getUserData().roles
                      ? usersStore
                          .getUserData()
                          .roles?.map((_) => Translator.translate(RolesNames.get(_)))
                          .join(', ')
                      : '—'}
                  </Typography>
                </Tooltip>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>

      <UserInfo userAnchorEl={userAnchorEl} onClose={() => setUserAnchorEl(null)} />
    </Drawer>
  );
};

export default observer(SideBar);
