import React, { KeyboardEvent } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Translator } from 'stores';
import CloseDialogIcon from 'components/Icons/CloseDialogIcon';
import IconSearch from 'components/Icons/IconSearch';
import { IconButton, OutlinedInput, OutlinedInputProps, Stack, Tooltip } from '@mui/material';

import s from './InputSearch.module.scss';

interface IInputSearchProps extends OutlinedInputProps {
  isArrayResult?: boolean;
  disabledPrev?: boolean;
  disabledNext?: boolean;
  bgColor?: string;
  isExpanded?: boolean;
  onNext?: () => void;
  onPrev?: () => void;
  onSearch: () => void;
  onClose?: () => void;
}

const InputSearch: React.FC<IInputSearchProps> = (props) => {
  const { value, isArrayResult, isExpanded, disabledPrev, bgColor, disabledNext, onPrev, onNext, onSearch, onClose } = props;

  const onPressEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && value && onSearch) onSearch();
  };

  return (
    <OutlinedInput
      sx={{
        borderRadius: '6px',
        bgcolor: bgColor ?? '#F2F2F9',
        '& .MuiOutlinedInput-notchedOutline': {
          border: bgColor ? '1px solid rgba(0, 0, 0, 0.23)' : 'none',
        },
      }}
      onKeyDown={onPressEnter}
      className={s.searchInput}
      endAdornment={
        <>
          {onPrev && onNext && isArrayResult && (
            <Stack flexDirection="row">
              <Tooltip title={Translator.translate('ui.inputSearch.previous')}>
                <IconButton className={s.icon} onClick={onPrev} disabled={disabledPrev}>
                  <ExpandLessIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={Translator.translate('ui.inputSearch.next')}>
                <IconButton className={s.icon} onClick={onNext} disabled={disabledNext}>
                  <ExpandMoreIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )}

          {!isExpanded ? (
            <Tooltip
              title={Translator.translate('ui.inputSearch.tooltipSearch')}
              arrow
              disableInteractive
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              }}>
              <IconButton className={s.iconSearch} size="small" onClick={onSearch}>
                <IconSearch />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title={Translator.translate('actions.close')}
              arrow
              disableInteractive
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              }}>
              <IconButton className={s.iconSearch} size="small" onClick={onClose}>
                <CloseDialogIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      }
      startAdornment={
        <>
          {isExpanded && (
            <IconButton className={s.iconSearch} size="small" onClick={onSearch}>
              <IconSearch />
            </IconButton>
          )}
        </>
      }
      placeholder={Translator.translate('ui.inputSearch.placeholderSearch')}
      {...props}
    />
  );
};

export default InputSearch;
