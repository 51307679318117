import { makeAutoObservable } from 'mobx';
import { ISelectOption, IGetStageDto, IPostStageDto, IPutStageDto } from 'shared/interfaces';

class StageModel {
  constructor(dto?: IGetStageDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.orderNum = dto.orderNum ?? null;
  }

  public id: number | null = null;

  public name = '';

  public orderNum: number | null = null;

  public get postDto(): IPostStageDto | null {
    if (this.name === '') return null;

    return {
      name: this.name,
    };
  }

  public get putDto(): IPutStageDto | null {
    if (this.name === '' || this.orderNum === null) return null;

    return {
      name: this.name,
      orderNum: this.orderNum,
    };
  }

  public setId(id: number) {
    this.id = id;
  }

  public setName(name: string) {
    this.name = name;
  }

  public setOrderNum(orderNum: number) {
    this.orderNum = orderNum;
  }

  public getSelectOption(): ISelectOption | null {
    if (this.id === null) return null;

    return { id: this.id, name: this.name };
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.orderNum = null;
  }
}

export default StageModel;
