import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, classificationDictionaryStore, classificationDictionaryValueStore } from 'stores';
import { IconButtonNew, Select, TableView } from 'components';
import { useConfirm } from 'components/App/Confirm';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import TrashIcon from 'components/Icons/TrashIcon';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { IClassificationDictionaryValueGetDto, ITableColumn } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';
import DictionaryValueDialog from './Components';

export const HEIGHT_CONTENT = 148;

const ClassificationDictionaries: React.FC = () => {
  const confirm = useConfirm();

  const columns: ITableColumn[] = [
    {
      key: 'value',
      label: Translator.translate('catalogs.classifiers.columns.value'),
      width: '400px',
      align: 'left',
    },
    {
      key: 'actions',
      label: '',
      width: '50px',
      align: 'right',
      cell: (row: IClassificationDictionaryValueGetDto) => {
        const removeStage = () => {
          confirm
            .show(Translator.translate('catalogs.classifiers.confirmMessage.deleteSuggestedParameter'), Translator.translate('actions.delete'))
            .then(async () => {
              await classificationDictionaryValueStore.removeDictionaryValue(row.id!);
              classificationDictionaryValueStore.getDictionaryValues(classificationDictionaryStore.currentDictionary!);
            });
        };

        return (
          <Stack
            flexDirection="row"
            gap={2}
            justifyContent="flex-end"
            sx={(theme) => ({
              'button svg': {
                ':hover': {
                  fill: theme.palette.primary.main,
                  fillOpacity: 1,
                },
              },
            })}>
            <IconButtonNew
              onClick={async () => {
                classificationDictionaryValueStore.setEditingMode(row.id!);
              }}
              tooltip={Translator.translate('actions.edit')}>
              <EditPencilIcon />
            </IconButtonNew>
            <IconButtonNew onClick={removeStage} tooltip={Translator.translate('actions.delete')}>
              <TrashIcon />
            </IconButtonNew>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    classificationDictionaryStore.getDictionaryList().then(() => {
      classificationDictionaryStore.setCurrentDictionary(classificationDictionaryStore.dictionaries[0].id);
      classificationDictionaryStore.currentDictionary &&
        classificationDictionaryValueStore.getDictionaryValues(classificationDictionaryStore.currentDictionary);
    });
  }, []);

  const onOpenCreateDialog = () => {
    classificationDictionaryValueStore.setDialogOpen(true);
  };

  const onChangeCurrentDictionary = (value: number) => {
    classificationDictionaryStore.setCurrentDictionary(value);
    classificationDictionaryValueStore.getDictionaryValues(value);
  };

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between" mb={3}>
        <Box width="20%">
          <Select
            sx={{
              width: '100%',
              backgroundColor: '#F2F2F9',
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
              borderRadius: '6px',
            }}
            label={Translator.translate('catalogs.classifiers.selectLabel')}
            hideNotSelectItem
            value={classificationDictionaryStore.currentDictionary ?? ''}
            options={classificationDictionaryStore.dictionaryForOptions}
            onChange={(e) => onChangeCurrentDictionary(e.target.value as number)}
            size="small"
          />
        </Box>

        <Button variant="contained" startIcon={<PlusIconForButton />} onClick={onOpenCreateDialog}>
          {Translator.translate('actions.add')}
        </Button>
      </Stack>

      {classificationDictionaryValueStore.isLoading && !classificationDictionaryValueStore.isDialogOpen ? (
        <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableView
          data={classificationDictionaryValueStore.dictionaryValues}
          columns={columns}
          isActionsHover
          hideSelectRowOnMount
          height={`calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight()}px)`}
        />
      )}

      {classificationDictionaryValueStore.isDialogOpen && <DictionaryValueDialog />}
    </>
  );
};

export default observer(ClassificationDictionaries);
