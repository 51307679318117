import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, categoryStore, documentStore, toastStore } from 'stores';
import { Select, Input, DialogWrapper } from 'components';
import { CircularProgress, Box, SelectChangeEvent } from '@mui/material';

enum DocumentDialogMode {
  Add,
  AddToCategory,
  Edit,
}

const DocumentDialog: React.FC = () => {
  const { selectedDocument, isDialogOpen, isDocumentLoading } = documentStore;
  const [dialogMode, setDialogMode] = useState(DocumentDialogMode.Add);

  useEffect(() => {
    if (selectedDocument.id) {
      documentStore.getDocumentById(selectedDocument.id);
      setDialogMode(DocumentDialogMode.Edit);
    } else if (!selectedDocument.categoryId) {
      setDialogMode(DocumentDialogMode.Add);
    } else {
      setDialogMode(DocumentDialogMode.AddToCategory);
    }

    return () => {
      selectedDocument.clear();
    };
  }, []);

  const dialogTitle = selectedDocument.id
    ? Translator.translate('catalogs.documents.editTitleDialog')
    : Translator.translate('catalogs.documents.addTitleDialog');

  const onSubmit = () => {
    if (selectedDocument.name === '' || !selectedDocument.categoryId) {
      toastStore.showWarning(Translator.translate('catalogs.validationMessage'));
      return;
    }

    if (dialogMode === DocumentDialogMode.Edit) {
      documentStore.updateDocument().then(() => onClose());
    } else {
      documentStore.createDocument().then(() => onClose());
    }
  };

  const onChangeName = (value: string) => {
    selectedDocument.setName(value);
  };

  const onChangeDescription = (value: string) => {
    selectedDocument.setDescription(value);
  };

  const onChangeURL = (value: string) => {
    selectedDocument.setURL(value);
  };

  const onChangeCategory = (e: SelectChangeEvent<number>) => {
    selectedDocument.setCategoryId(e.target.value as number);
  };

  const onClose = () => {
    documentStore.setDialogOpen(false);
    selectedDocument.setCategoryId(null);
    selectedDocument.clear();
  };

  return (
    //TODO: Add formik
    <DialogWrapper title={dialogTitle} maxWidth="md" open={isDialogOpen} onClose={onClose} onCancel={onClose} onSubmit={onSubmit}>
      <>
        {isDocumentLoading ? (
          <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Input label={Translator.translate('catalogs.documents.columns.name')} required value={selectedDocument.name} onChange={onChangeName} />

            <Input label={Translator.translate('catalogs.documents.columns.url')} value={selectedDocument.url} onChange={onChangeURL} />

            <Input
              label={Translator.translate('catalogs.documents.columns.description')}
              value={selectedDocument.description}
              onChange={onChangeDescription}
            />

            {dialogMode !== DocumentDialogMode.AddToCategory ? (
              <Select
                label={Translator.translate('catalogs.documents.columns.category')}
                required
                hideNotSelectItem
                value={selectedDocument.categoryId!}
                options={categoryStore.categoriesForOptions}
                onChange={onChangeCategory}
                size="medium"
              />
            ) : (
              <></>
            )}
          </>
        )}
      </>
    </DialogWrapper>
  );
};

export default observer(DocumentDialog);
