import React from 'react';

const ParamsClassifierIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9561 1.9231C15.8251 1.10529 17.1866 1.12591 18.0303 1.96967C18.8741 2.81343 18.8947 4.17493 18.0769 5.04385L11.0462 12.514C10.9535 12.6124 10.836 12.684 10.706 12.7211L7.20604 13.7211C6.94415 13.796 6.66227 13.7229 6.46967 13.5303C6.27707 13.3377 6.20403 13.0559 6.27886 12.794L7.27886 9.29396C7.31599 9.164 7.38755 9.04649 7.48598 8.95385L14.9561 1.9231ZM15.9842 3.0154C16.2627 2.75324 16.6992 2.75985 16.9697 3.03033C17.2401 3.30081 17.2468 3.73726 16.9846 4.01581L10.0954 11.3356L8.09202 11.908L8.66441 9.90461L15.9842 3.0154Z"
      />
      <path d="M4 3.25C2.48122 3.25 1.25 4.48122 1.25 6V16C1.25 17.5188 2.48122 18.75 4 18.75H14C15.5188 18.75 16.75 17.5188 16.75 16V11C16.75 10.5858 16.4142 10.25 16 10.25C15.5858 10.25 15.25 10.5858 15.25 11V16C15.25 16.6904 14.6904 17.25 14 17.25H4C3.30964 17.25 2.75 16.6904 2.75 16V6C2.75 5.30964 3.30964 4.75 4 4.75H9C9.41421 4.75 9.75 4.41421 9.75 4C9.75 3.58579 9.41421 3.25 9 3.25H4Z" />
    </svg>
  );
};

export default ParamsClassifierIcon;
