import { makeAutoObservable } from 'mobx';
import { parameterGroupStore } from 'stores';
import { ICreateParameterGroupCommandDto, IParamGroupForm, IUpdateParameterGroupDto } from 'shared/interfaces';

/** Группы параметров */
export class ParameterGroupModel {
  constructor(dto?: any) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.key = dto.key;
    this.title = dto.title;
    this.children = dto.children;

    this.isPresented = dto.isPresented ?? false;

    this.id = dto.id;
    this.orderNum = dto.orderNum ?? null;
    this.parentId = dto.parentId;
    this.fullName = dto.fullName ?? null;
    this.name = dto.name;
  }

  public key = '';

  public title = '';

  public children = [];

  public fullName = '';

  public name = '';

  public id: number | null = null;

  public orderNum: number | null = null;

  public parentId: number | null = null;

  public isPresented = false;

  private trim(name: string) {
    return name.trim();
  }

  public setId(id: number) {
    this.id = id;
  }

  public setTitle(name: string) {
    this.name = name;
  }

  public setOrderNum(orderNum: number) {
    this.orderNum = orderNum;
  }

  public updateParameterGroupDialogForm(values: IParamGroupForm) {
    this.name = values.name;
  }

  public get postDto(): ICreateParameterGroupCommandDto | null {
    if (this.id === null || this.trim(this.name) === '') return null;

    return {
      name: this.trim(this.name),
      parentId: this.id,
    };
  }

  public get postRootDto(): ICreateParameterGroupCommandDto | null {
    if (this.trim(this.name) === '') return null;

    return {
      name: this.trim(this.name),
    };
  }

  public get putDto(): IUpdateParameterGroupDto {
    return {
      id: parameterGroupStore.isEditRootParameterGroup ? parameterGroupStore.currentParameterGroup! : this.id!,
      name: this.trim(this.name),
      orderNum: this.orderNum!,
      parentId: this.parentId,
    };
  }

  public clearModel() {
    this.title = '';
    this.orderNum = null;
    this.id = null;
    this.key = '';
    this.fullName = '';
    this.name = '';
    this.parentId = null;
    this.isPresented = false;
  }
}

export default ParameterGroupModel;
