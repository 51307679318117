import { makeAutoObservable } from 'mobx';
import { XMLDocumentType } from 'shared/enums';
import { IXmlDocumentParamsDto } from 'shared/interfaces';

class FilterXMLDocumentModel {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public name = '';

  public type: XMLDocumentType = XMLDocumentType.Template;

  public setName(name: string) {
    this.name = name;
  }

  public setType(type: XMLDocumentType) {
    this.type = type;
  }

  public get paramsDto(): IXmlDocumentParamsDto {
    return {
      name: this.name,
      type: this.type,
    };
  }

  public clear() {
    this.name = '';
    this.type = XMLDocumentType.Template;
  }
}

export default FilterXMLDocumentModel;
