import { BASE_PATH } from 'shared/constants/constants';
import { IGetInformationBlockDto, IPostInformationBlockDto, IPutInformationBlockDto } from 'shared/interfaces';
import HTTPService from './HttpService';

class InformationBlockService extends HTTPService {
  constructor() {
    super(BASE_PATH.INFORMATION_BLOCKS);
  }

  public getInformationBlocks(): Promise<IGetInformationBlockDto[]> {
    return this.GET<IGetInformationBlockDto[]>('');
  }

  public getInformationBlock(id: number): Promise<IGetInformationBlockDto> {
    return this.GET<IGetInformationBlockDto>(`${id}`);
  }

  public postInformationBlock(body: IPostInformationBlockDto): Promise<number> {
    return this.POST('', body);
  }

  public putInformationBlock(id: number, body: IPutInformationBlockDto) {
    return this.PUT(`${id}`, body);
  }

  public deleteInformationBlock(id: number) {
    return this.DELETE(`${id}`);
  }
}

export default new InformationBlockService();
