import React from 'react';

const TreeStateTrueIcon = () => {
  return (
    <svg width="8" height="6" viewBox="0 0 8 6" fill="white" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29312 0.725759C7.55613 1.01355 7.54192 1.46558 7.26137 1.73539L3.54784 5.30681C3.28 5.5644 2.86323 5.5644 2.59539 5.30681L0.738625 3.5211C0.458082 3.25129 0.443868 2.79927 0.706877 2.51147C0.969886 2.22368 1.41052 2.2091 1.69107 2.4789L3.07162 3.80662L6.30893 0.69319C6.58948 0.423383 7.03011 0.437964 7.29312 0.725759Z"
      />
    </svg>
  );
};

export default TreeStateTrueIcon;
