import { makeAutoObservable, runInAction } from 'mobx';
import { ClassificationDictionaryValueService } from 'services';
import { Translator, classificationDictionaryStore, toastStore } from 'stores';
import { ClassificationDictionaries } from 'shared/enums/ClassificationDictionaries';
import { IClassificationDictionaryValueGetDto, ISelectOption } from 'shared/interfaces';
import { DictionaryValueModel } from 'shared/models';

class ClassificationDictionaryValueStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public dictionaryValues: IClassificationDictionaryValueGetDto[] = [];

  public buildingTypes: ISelectOption[] = [];

  public buildingClasses: ISelectOption[] = [];

  public regions: ISelectOption[] = [];

  public isDialogOpen = false;

  public selectedDictionary = new DictionaryValueModel();

  public setDialogOpen(isOpen: boolean) {
    this.isDialogOpen = isOpen;
  }

  public async setEditingMode(id: number) {
    this.setDialogOpen(true);
    await this.getDictionaryValueById(id);
  }

  public async getDictionaryValueList(classificationDictionaryIds: number[]): Promise<void> {
    try {
      this.isLoading = true;
      const promises = classificationDictionaryIds.map((id) => {
        return ClassificationDictionaryValueService.getClassificationDictionaryValues({ classificationDictionaryId: id });
      });

      await Promise.all(promises).then((res) => {
        res.forEach((dictionary: IClassificationDictionaryValueGetDto[]) => {
          switch (dictionary[0].classificationDictionaryId) {
            case ClassificationDictionaries.BuildingType:
              this.buildingTypes = dictionary.map(({ id, value }) => {
                return { id, name: value };
              });
              break;

            case ClassificationDictionaries.Region:
              this.regions = dictionary.map(({ id, value }) => {
                return { id, name: value };
              });
              break;

            case ClassificationDictionaries.BuildingClass:
              this.buildingClasses = dictionary.map(({ id, value }) => {
                return { id, name: value };
              });
              break;
          }
        });
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} DictionaryValueList`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getDictionaryValues(id: number): Promise<void> {
    try {
      this.isLoading = true;

      const result = await ClassificationDictionaryValueService.getClassificationDictionaryValues({ classificationDictionaryId: id });
      if (!result) return;

      this.dictionaryValues = result;
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} DictionaryValues`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getDictionaryValueById(id: number): Promise<void> {
    try {
      this.isLoading = true;

      const result = await ClassificationDictionaryValueService.getClassificationDictionaryValues({ id: id });
      if (!result) return;

      runInAction(() => {
        this.selectedDictionary = new DictionaryValueModel(result[0]);
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} DictionaryValue`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createDictionaryValue(): Promise<void> {
    if (!classificationDictionaryStore.currentDictionary || !this.selectedDictionary.postDto(classificationDictionaryStore.currentDictionary!))
      return;

    try {
      this.isLoading = true;

      await ClassificationDictionaryValueService.postClassificationDictionaryValue(
        this.selectedDictionary.postDto(classificationDictionaryStore.currentDictionary!)!
      );

      this.getDictionaryValues(classificationDictionaryStore.currentDictionary!);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} DictionaryValue`);
    } finally {
      this.isLoading = false;
    }
  }

  public async updateDictionaryValue(): Promise<void> {
    if (!this.selectedDictionary.id || !this.selectedDictionary.putDto) return;

    try {
      this.isLoading = true;

      await ClassificationDictionaryValueService.putClassificationDictionaryValue(this.selectedDictionary.id, this.selectedDictionary.putDto);

      this.getDictionaryValues(classificationDictionaryStore.currentDictionary!);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} DictionaryValue`);
    } finally {
      this.isLoading = false;
    }
  }

  public async removeDictionaryValue(id: number): Promise<void> {
    try {
      this.isLoading = true;

      await ClassificationDictionaryValueService.deleteClassificationDictionaryValue(id);

      this.getDictionaryValues(classificationDictionaryStore.currentDictionary!);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} DictionaryValue`);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new ClassificationDictionaryValueStore();
