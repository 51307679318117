import { makeAutoObservable } from 'mobx';
import { FileType } from 'shared/enums';
import { IGetFilesDto } from 'shared/interfaces';

class SpecificationFilesModel {
  constructor(dto?: IGetFilesDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.createdAt = dto.createdAt;
    this.extension = dto.extension;
    this.fileType = this.getFileType(dto.extension);
  }

  public getFileType(type: string): FileType {
    switch (type) {
      case '.docx':
      case '.doc':
        return FileType.DOC;

      case '.txt':
        return FileType.TXT;

      case '.xls':
      case '.xlsx':
        return FileType.XLS;

      case '.jpg':
      case '.png':
        return FileType.JPG;

      case '.ppt':
      case '.pptx':
        return FileType.PPT;

      case '.pdf':
        return FileType.PDF;

      default:
        return FileType.NotFound;
    }
  }

  public id: number | null = null;

  public name = '';

  public extension = '';

  public createdAt = '';

  public fileType: FileType = FileType.TXT;

  public file: File | null = null;

  public setId(id: number) {
    this.id = id;
  }

  public setFile(file: File) {
    this.file = file;
  }

  public get postDto(): FormData | null {
    if (!this.file) return null;

    const payload = new FormData();
    payload.append('file', this.file);

    return payload;
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.extension = '';
    this.createdAt = '';
    this.fileType = FileType.TXT;
  }
}

export default SpecificationFilesModel;
