import { useMemo } from 'react';
import type { HTMLAttributes } from 'react';
import { GridRenderCellParams, GridRow, GridRowProps } from '@mui/x-data-grid-pro';
import { Typography, Stack } from '@mui/material';

import Utils from 'shared/utils/Utils';
import s from './CustomGridParameterRow.module.scss';

interface CustomGridRowProps extends HTMLAttributes<HTMLDivElement>, GridRowProps {}

const CustomGridParameterRow: React.FC<CustomGridRowProps> = ({ renderedColumns, ...props }): JSX.Element => {
  const isGroup = (row: any): boolean => {
    if (row.hasOwnProperty('isGroup')) {
      if (row.isGroup) {
        return true;
      }
    } else {
      if (row.hasOwnProperty('parameterIds')) return true;
    }

    return false;
  };

  const parsedRenderedColumns = useMemo(() => {
    return renderedColumns.map((column) => {
      if (column.field === 'name') {
        return {
          ...column,
          renderCell: (params: GridRenderCellParams) => {
            const { row }: { row: any } = params;

            return (
              <Stack flexDirection="row" alignItems="center" width="100%" justifyContent="space-between">
                <Typography variant="body2" sx={{ wordBreak: 'break-word', fontWeight: isGroup(row) ? 500 : 400 }}>
                  {isGroup(row) ? row.fullName : Utils.deleteInnerWrap(row.name)}
                  {isGroup(row) && ` (${params.rowNode.children ? params.rowNode.children.length : 0})`}
                </Typography>
              </Stack>
            );
          },
        };
      }

      return column;
    });
  }, [renderedColumns]);

  return <GridRow {...props} className={isGroup(props.row) ? s.mainRow : s.row} renderedColumns={parsedRenderedColumns} />;
};

export default CustomGridParameterRow;
