import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Translator, auditStore, definitionStore, projectStore } from 'stores';
import { IconButtonNew } from 'components';
import { useConfirm } from 'components/App/Confirm';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import HistoryIcon from 'components/Icons/HistoryIcon';
import MapIcon from 'components/Icons/MapIcon';
import PinIcon from 'components/Icons/PinIcon';
import TrashIcon from 'components/Icons/TrashIcon';
import UnPinIcon from 'components/Icons/UnPinIcon';
import { Box, Card, CardActionArea, CardContent, Chip, Stack, Typography, Divider } from '@mui/material';
import DefinitionDialog from '../DefinitionDialog';
import { AuditTables, DefinitionApprovalStatus, DefinitionApprovalStatusNames, ProjectTypeNames } from 'shared/enums';
import { Routes } from 'shared/enums/Routes';

import s from './RecentlyDefinitions.module.scss';

const RecentlyDefinitions: React.FC = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [isDialogOpen, setDialogOpen] = useState(false);

  const paint = (status: DefinitionApprovalStatus) => {
    switch (status) {
      case DefinitionApprovalStatus.Formed:
        return 'warning';

      case DefinitionApprovalStatus.PartiallyApproved:
        return 'info';

      case DefinitionApprovalStatus.Approved:
        return 'success';

      case DefinitionApprovalStatus.InProgress:
        return 'primary';
    }
  };

  const onClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Box className={s.wrapper}>
        <Stack direction="row" gap={6} justifyContent="flex-start">
          {definitionStore.recentlyDefinitions.map((project) => {
            const onDefinitionRowClick = () => {
              definitionStore.getCurrentDefinition(project.defId!).then((data) => {
                if (data) {
                  definitionStore.addRecentlyDefinition();
                  navigate(`${definitionStore.currentDefinition.id}${Routes.OBJECT_VALUE}`);
                } else {
                  projectStore.getProjectList();
                  definitionStore.getRecentlyDefinitions();
                }
              });
            };

            return (
              <Card
                key={project.defId}
                sx={(theme) => ({
                  maxWidth: '440px',
                  width: '100%',
                  borderRadius: '12px',
                  border: 'none',
                  boxShadow: `0px 1px 5px ${theme.palette.grey[200]}`,
                  textDecoration: 'none',
                })}
                variant="outlined"
                onClick={onDefinitionRowClick}
                onMouseOver={() => project.setHover(true)}
                onMouseOut={() => project.setHover(false)}>
                <CardActionArea disableTouchRipple sx={{ height: '100%' }}>
                  <CardContent
                    sx={(theme) => ({
                      background: project.isPin ? theme.palette.primary.main : theme.palette.common.white,
                      color: project.isPin ? theme.palette.common.white : 'unset',
                    })}
                    className={s.definitionContent}>
                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: '13px' }}>
                      <Box>
                        <Typography color={(theme) => (project.isPin ? theme.palette.common.white : theme.palette.primary.main)} variant="overline">
                          {Translator.translate(ProjectTypeNames.get(project.type))}
                        </Typography>
                      </Box>

                      {/* <Chip
                        size="small"
                        variant={project.isPin ? 'filled' : 'outlined'}
                        color={paint(project.status)}
                        label={DefinitionApprovalStatusNames.get(project.status)!}
                      /> */}
                    </Stack>

                    <Typography
                      variant="subtitle1"
                      color={(theme) => (project.isPin ? theme.palette.common.white : theme.palette.text.primary)}
                      className={s.projectNameWrapper}>{`${project.name}`}</Typography>

                    <Box className={s.definitionNameWrapper}>
                      <Typography variant="caption" color={(theme) => (project.isPin ? theme.palette.common.white : theme.palette.text.secondary)}>
                        {project.definitions.name}
                      </Typography>
                    </Box>

                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                      <Stack flexDirection="row" alignItems="center">
                        {project.definitions.stage?.name && (
                          <Typography
                            variant="caption"
                            sx={{ marginRight: '16px' }}
                            color={(theme) => (project.isPin ? theme.palette.common.white : theme.palette.text.secondary)}>
                            {project.definitions.stage?.name}
                          </Typography>
                        )}

                        {project.region.value && <MapIcon />}

                        <Typography
                          variant="caption"
                          sx={{ marginLeft: '6px' }}
                          className={s.regionWrapper}
                          color={(theme) => (project.isPin ? theme.palette.common.white : theme.palette.text.secondary)}>
                          {project.region.value}
                        </Typography>
                      </Stack>

                      <Stack justifyContent="center" height={'28px'}>
                        {project.isPin && !project.isHover && (
                          <Box
                            sx={(theme) => ({
                              svg: {
                                fill: project.isPin ? theme.palette.common.white : theme.palette.primary.main,
                                fillOpacity: 1,
                              },
                            })}>
                            <PinIcon />
                          </Box>
                        )}

                        {project.isHover && (
                          <Stack
                            justifyContent="center"
                            flexDirection="row"
                            gap={1}
                            alignItems="center"
                            sx={(theme) => ({
                              mr: -1,
                              'button svg': {
                                fill: project.isPin ? 'rgba(255, 255, 255, 0.56)' : theme.palette.action,

                                ':hover': {
                                  fill: project.isPin ? theme.palette.common.white : theme.palette.primary.main,
                                  fillOpacity: 1,
                                },
                              },
                            })}>
                            <IconButtonNew
                              tooltip={Translator.translate('actions.edit')}
                              onClick={async (e) => {
                                e.stopPropagation();
                                setDialogOpen(true);
                                project.defId && (await definitionStore.setEditingMode(project.defId));
                              }}>
                              <EditPencilIcon />
                            </IconButtonNew>

                            <IconButtonNew
                              tooltip={Translator.translate('actions.delete')}
                              onClick={async (e) => {
                                e.stopPropagation();
                                project.defId && (await definitionStore.setEditingMode(project.defId));
                                confirm
                                  .show(Translator.translate('projects.message.deleteHPFMessage'), Translator.translate('actions.delete'))
                                  .then(() => {
                                    definitionStore.removeDefinition();
                                  });
                              }}>
                              <TrashIcon />
                            </IconButtonNew>

                            <Divider orientation="vertical" className={s.dividerWrapper} />

                            <IconButtonNew
                              tooltip={Translator.translate('actions.changeHistory')}
                              onClick={(e) => {
                                e.stopPropagation();
                                auditStore.setDrawerOpen(true);
                                auditStore.setAuditFilters(AuditTables.Definitions, Number(project.defId));
                              }}>
                              <HistoryIcon />
                            </IconButtonNew>

                            <IconButtonNew
                              tooltip={
                                project.isPin
                                  ? Translator.translate('projects.recentlyDefinitions.unfavorite')
                                  : Translator.translate('projects.recentlyDefinitions.favorite')
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                definitionStore.onSetPinInLocalStorage(project);
                              }}>
                              {project.isPin ? <UnPinIcon /> : <PinIcon />}
                            </IconButtonNew>
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            );
          })}
        </Stack>
      </Box>

      {isDialogOpen && <DefinitionDialog open={isDialogOpen} onClose={onClose} />}
    </>
  );
};

export default observer(RecentlyDefinitions);
