import { makeAutoObservable } from 'mobx';
import { ApprovalStatus, ApprovalUserRole, PartialApprovalStatus } from 'shared/enums';
import {
  IGetApprovalDto,
  IGetApprovalUserDto,
  IPutApprovalDto,
  IApprovalForm,
  IPostApprovalDto,
  ITabItem,
  IShortPartialApprovalDto,
  IPostApprovalUserDto,
  IPostApprovalRowSuggestionDto,
} from 'shared/interfaces';
import { IdNameDto } from 'shared/interfaces/Dto/BaseDto';
import ApprovalUserModel from './ApprovalUser';

class ApprovalModel {
  constructor(dto?: IGetApprovalDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name !== null ? dto.name : '';
    this.status = dto.status;
    this.definition = dto.definition;
    this.project = dto.project;
    this.specification = dto.specification;
    this.deadLine = dto.deadLine ?? '';
    this.createdAt = dto.createdAt;
    this.partialApprovals = dto.partialApprovals;
    this.userRoles = dto.userRoles;

    dto.users.forEach((userDto: IGetApprovalUserDto) => {
      switch (userDto.role) {
        case ApprovalUserRole.Initiator:
          this.initiator = new ApprovalUserModel(userDto);
          break;
        case ApprovalUserRole.Approver:
          this.approver = new ApprovalUserModel(userDto);
          break;
      }
    });
  }

  public id: number | null = null;

  public name = '';

  public status = ApprovalStatus.InProgress;

  public definition: IdNameDto = {
    id: null,
    name: '',
  };

  public project: IdNameDto = {
    id: null,
    name: '',
  };

  public specification: IdNameDto = {
    id: null,
    name: '',
  };

  public initiator = new ApprovalUserModel();

  public approver = new ApprovalUserModel();

  public createdAt = '';

  public deadLine = '';

  public partialApprovals: IShortPartialApprovalDto[] = [];

  public userRoles: ApprovalUserRole[] = [];

  public get hasCurrentApprovals(): boolean {
    return this.project.name !== '' && this.definition.name !== '' && this.specification.name !== '';
  }

  public get partialApprovalsForTabs(): Omit<ITabItem, 'content'>[] {
    return this.partialApprovals.map((el) => {
      return {
        value: el.id,
        label: el.name,
        status: el.status,
      };
    });
  }

  public get putDto(): IPutApprovalDto {
    return {
      name: this.name,
      specificationId: this.specification?.id,
      approver: this.approver.userId ? (this.approver.postDto as Omit<IPostApprovalUserDto, 'role'>) : null,
    };
  }

  public get postDto(): IPostApprovalDto | null {
    if (this.name === '' || !this.specification?.id) return null;

    return {
      name: this.name,
      specificationId: this.specification?.id,
    };
  }

  /**
   * Add new partialApproval element (component tabs)
   * @return {number} - id of the element added to the end
   */
  public setPartialApprovals(): number {
    const createId = this.partialApprovals.length !== 0 ? Math.max(...this.partialApprovals.map((_) => _.id)) + 1 : 1;

    this.partialApprovals.push({
      id: createId,
      name: `Частичное согласование ${this.partialApprovals.length + 1}`,
      status: PartialApprovalStatus.Draft,
      iteration: null,
      userRoles: [],
      informationBlock: {} as IdNameDto,
    });

    return this.partialApprovals[this.partialApprovals.length - 1].id;
  }

  public updateApprovalDialogForm(values: IApprovalForm) {
    this.name = values.name;
    this.specification.id = values.specificationId;
  }

  public setId(id: number) {
    this.id = id;
  }

  public setName(name: string) {
    this.name = name;
  }

  public setDefinition(definition: IdNameDto) {
    this.definition = definition;
  }

  public setSpecification(specification: IdNameDto) {
    this.specification = specification;
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.createdAt = '';
    this.approver = new ApprovalUserModel();
    this.initiator = new ApprovalUserModel();
    this.definition = {
      id: null,
      name: '',
    };

    this.specification = {
      id: null,
      name: '',
    };
    this.userRoles = [];
  }
}

export default ApprovalModel;
