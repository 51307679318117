import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, categoryStore, documentStore } from 'stores';
import { IconButtonNew, InputSearch, TableViewCollapse } from 'components';
import { useConfirm } from 'components/App/Confirm';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import TrashIcon from 'components/Icons/TrashIcon';
import { Box, Button, Link, Stack } from '@mui/material';
import { IGetDocumentDto, ITableColumn } from 'shared/interfaces';
import { DocumentListModel } from 'shared/models';
import Utils from 'shared/utils/Utils';
import DocumentDialog from './Components';

import s from './Documents.module.scss';

export const HEIGHT_CONTENT = 148;

const Documents: React.FC = () => {
  const [searchValue, setSearchValue] = useState('');

  const confirm = useConfirm();
  const { documentCategories, isDialogOpen } = documentStore;

  useEffect(() => {
    categoryStore.getCategoryList().then(() => {
      documentStore.getDocuments();
    });

    return () => {
      documentStore.setFilterDocument('');
    };
  }, []);

  const columns: ITableColumn[] = [
    {
      key: 'name',
      label: Translator.translate('catalogs.documents.columns.name'),
      width: '20%',
      maxWidth: '20%',
      align: 'left',
      cell: (row: IGetDocumentDto) => {
        return <div style={{ wordBreak: 'break-all' }}>{row.name}</div>;
      },
    },
    {
      key: 'description',
      label: Translator.translate('catalogs.documents.columns.description'),
      width: '40%',
      maxWidth: '40%',
      align: 'left',
      cell: (row: IGetDocumentDto) => {
        return <div style={{ wordBreak: 'break-all' }}>{row.description}</div>;
      },
    },
    {
      key: 'url',
      label: Translator.translate('catalogs.documents.columns.url'),
      width: '35%',
      maxWidth: '35%',
      align: 'left',
      cell: (row: IGetDocumentDto) => {
        return (
          <Link target="_blank" href={row.url} underline="always" style={{ wordBreak: 'break-all' }}>
            {row.url}
          </Link>
        );
      },
    },
    {
      key: 'actions',
      label: '',
      width: '5%',
      maxWidth: '5%',
      align: 'right',
      cell: (row: DocumentListModel) => {
        const removeDocument = () => {
          confirm.show(Translator.translate('catalogs.documents.confirmMessage.deleteDocument'), Translator.translate('actions.delete')).then(() => {
            documentStore.removeDocument(row.id!);
          });
        };

        return (
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={(theme) => ({
              'button svg': {
                ':hover': {
                  fill: theme.palette.primary.main,
                  fillOpacity: 1,
                },
              },
            })}>
            <IconButtonNew tooltip={Translator.translate('actions.edit')} onClick={() => documentStore.setEditingMode(row.id!)}>
              <EditPencilIcon />
            </IconButtonNew>

            <IconButtonNew tooltip={Translator.translate('actions.delete')} onClick={removeDocument}>
              <TrashIcon />
            </IconButtonNew>
          </Stack>
        );
      },
    },
  ];

  const onAddDocumentIntoCategory = (row: any) => {
    documentStore.selectedDocument.setCategoryId(row.id);
    documentStore.setDialogOpen(true);
  };

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    if (event.target.value === '') {
      documentStore.setFilterDocument(event.target.value);
    }
  };

  const onClickSearch = () => {
    documentStore.setFilterDocument(searchValue);
  };

  const onOpenCreateDialog = () => {
    documentStore.setDialogOpen(true);
  };

  return (
    <>
      {
        <Box className={s.toolBarWrapper}>
          <Box className={s.searchWrapper}>
            <InputSearch value={searchValue} onSearch={onClickSearch} onChange={onChangeSearch} />
          </Box>

          <Button variant="contained" startIcon={<PlusIconForButton />} onClick={onOpenCreateDialog}>
            {Translator.translate('actions.add')}
          </Button>
        </Box>
      }

      <TableViewCollapse
        data={documentCategories}
        columns={columns}
        isHideHeader
        height={`calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight()}px)`}
        onAddGroup={onAddDocumentIntoCategory}
      />

      {isDialogOpen && <DocumentDialog />}
    </>
  );
};

export default observer(Documents);
