import React from 'react';

const PptIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z" fill="#E9E9F4" />
      <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#A9ABB9" />
      <path d="M30 14L24 8H30V14Z" fill="#7B68EE" fillOpacity="0.08" />
      <path d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z" fill="#F56B00" />
      <path
        d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z"
        fill="url(#paint0_linear_12607_183094)"
        fillOpacity="0.24"
      />
      <path
        d="M6.59109 18.947C6.59109 18.683 6.7991 18.395 7.1341 18.395H8.9811C10.0211 18.395 10.9571 19.091 10.9571 20.425C10.9571 21.689 10.0211 22.393 8.9811 22.393H7.6461V23.449C7.6461 23.801 7.4231 24 7.1351 24C6.8711 24 6.59209 23.801 6.59209 23.449V18.947H6.59109ZM7.6461 19.402V21.394H8.9811C9.5171 21.394 9.9411 20.921 9.9411 20.425C9.9411 19.866 9.5171 19.402 8.9811 19.402H7.6461Z"
        fill="white"
      />
      <path
        d="M11.976 18.947C11.976 18.683 12.184 18.395 12.52 18.395H14.367C15.407 18.395 16.343 19.091 16.343 20.425C16.343 21.689 15.407 22.393 14.367 22.393H13.032V23.449C13.032 23.801 12.808 24 12.52 24C12.256 24 11.976 23.801 11.976 23.449V18.947ZM13.031 19.402V21.394H14.366C14.902 21.394 15.326 20.921 15.326 20.425C15.326 19.866 14.902 19.402 14.366 19.402H13.031Z"
        fill="white"
      />
      <path
        d="M18.8551 19.4671H17.4571C16.7612 19.4671 16.7612 18.4441 17.4571 18.4441H21.2381C21.9501 18.4441 21.9501 19.4671 21.2381 19.4671H19.9111V23.5051C19.9111 24.2001 18.8551 24.2171 18.8551 23.5051V19.4671Z"
        fill="white"
      />
      <path d="M25 27H6V28H25C25.55 28 26 27.55 26 27V26C26 26.55 25.55 27 25 27Z" fill="#7B68EE" fillOpacity="0.12" />
      <defs>
        <linearGradient id="paint0_linear_12607_183094" x1="14" y1="15" x2="14" y2="27" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PptIcon;
