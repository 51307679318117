import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { HandlerProps, ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { Translator, definitionStore, mergeStore, parameterGroupStore, projectStore } from 'stores';
import { Select, Toggler } from 'components';
import { Button, Chip, CircularProgress, Paper, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { MergeGroups, MergeGroupsNames, MergeTabState, MergeTabStateNames } from 'shared/enums';
import { ITabItem } from 'shared/interfaces';

import Utils from 'shared/utils/Utils';
import GroupsMerge from './GroupsMerge';
import MergeTable from './MergeTable';

const HEIGHT_CONTENT = 104;
const HEIGHT_ITEMS = 52;

const MergePage: React.FC = () => {
  const itemsActionHeight: number =
    mergeStore.merge.currentGroupMerge === MergeGroups.Items &&
    (mergeStore.merge.mergeAddDelItems.length !== 0 || mergeStore.merge.mergeChangeItems.length !== 0)
      ? HEIGHT_ITEMS
      : 0;

  const generalActionHeight = `calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight() + itemsActionHeight}px)`;

  useEffect(() => {
    projectStore.getProjectList();

    return () => {
      mergeStore.merge.clear();
      parameterGroupStore.resetParameterGroup();
      projectStore.projectFilters.clear();
    };
  }, []);

  useEffect(() => {
    parameterGroupStore.resetParameterGroup();

    mergeStore.getMerge().then(() => {
      if (mergeStore.merge.currentGroupMerge === MergeGroups.Items) updateItemsTab();
    });
  }, [mergeStore.merge.currentMergeDefinition]);

  useEffect(() => {
    if (mergeStore.merge.currentGroupMerge === MergeGroups.Items) updateItemsTab();
  }, [mergeStore.merge.currentGroupMerge]);

  const updateItemsTab = () => {
    if (mergeStore.merge.mergeAddDelItems.length === 0) {
      mergeStore.merge.setSelectedTab(MergeTabState.Changed);
    } else if (mergeStore.merge.mergeChangeItems.length === 0) {
      mergeStore.merge.setSelectedTab(MergeTabState.AddDel);
    }
  };

  const onChangeDefinitions = (e: SelectChangeEvent<any>) => {
    mergeStore.merge.clear();

    e.target.value !== '' && mergeStore.merge.setCurrentMergeDefinition(e.target.value);
  };

  const customLabel = (title: string, value: MergeGroups | MergeTabState, isGroup: boolean, count?: string): JSX.Element => {
    const isActive = isGroup ? mergeStore.merge.currentGroupMerge === value : mergeStore.merge.selectedTab === value;

    return (
      <Stack flexDirection="row" alignItems="center" whiteSpace="nowrap">
        <Typography
          component="span"
          variant="button"
          sx={(theme) => ({
            paddingRight: count ? '8px' : '',
            color: isActive ? theme.palette.common.white : theme.palette.primary.main,
          })}>
          {title}
        </Typography>
        {count && (
          <Chip
            label={count}
            color="warning"
            size="small"
            sx={(theme) => ({
              maxHeight: '18px',
              backgroundColor: theme.palette.warning.main,
            })}
          />
        )}
      </Stack>
    );
  };

  const listGroupTabs = (): ITabItem[] => {
    return [
      {
        value: MergeGroups.Items,
        label: customLabel(
          Translator.translate(MergeGroupsNames.get(MergeGroups.Items)),
          MergeGroups.Items,
          true,
          mergeStore.merge.mergeAddDelItems.length > 0
            ? `${
                mergeStore.merge.mergeAddDelItems.filter((f) => f.checked).length + mergeStore.merge.mergeChangeItems.filter((f) => f.checked).length
              }/${mergeStore.merge.mergeAddDelItems.length + mergeStore.merge.mergeChangeItems.length}`
            : undefined
        ),
      },
      {
        value: MergeGroups.ParameterValues,
        label: customLabel(
          Translator.translate(MergeGroupsNames.get(MergeGroups.ParameterValues)),
          MergeGroups.ParameterValues,
          true,
          mergeStore.merge.mergeParamValues.length > 0
            ? `${mergeStore.merge.getAllCheckedIdsCollapse(MergeGroups.ParameterValues).length}/${mergeStore.merge.mergeParamValues
                .map((value) => value.groupItems.length)
                .reduce((acc, num) => acc + num, 0)}`
            : undefined
        ),
      },
    ];
  };

  const listItemsTabs = (): ITabItem[] => {
    switch (mergeStore.merge.currentGroupMerge) {
      case MergeGroups.Items:
        if (mergeStore.merge.mergeAddDelItems.length === 0 && mergeStore.merge.mergeChangeItems.length === 0) {
          return [];
        }

        return [
          {
            value: MergeTabState.AddDel,
            label: customLabel(
              Translator.translate(MergeTabStateNames.get(MergeTabState.AddDel)),
              MergeTabState.AddDel,
              false,
              mergeStore.merge.mergeAddDelItems.length > 0
                ? `${mergeStore.merge.getMergeCheckedLength(mergeStore.merge.mergeAddDelItems)}/${mergeStore.merge.mergeAddDelItems.length}`
                : undefined
            ),
          },
          {
            value: MergeTabState.Changed,
            label: customLabel(
              Translator.translate(MergeTabStateNames.get(MergeTabState.Changed)),
              MergeTabState.Changed,
              false,
              mergeStore.merge.mergeChangeItems.length > 0
                ? `${mergeStore.merge.getMergeCheckedLength(mergeStore.merge.mergeChangeItems)}/${mergeStore.merge.mergeChangeItems.length}`
                : undefined
            ),
          },
        ];

      default:
        return [];
    }
  };

  const onSubmit = () => {
    mergeStore.createMerge();
  };

  return (
    <Paper elevation={0} sx={{ p: 6, borderRadius: '12px', height: '100%', gap: '16px', display: 'flex', flexDirection: 'column' }}>
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack flexDirection="row" gap={5}>
          <Select
            fullWidth
            sx={{
              minWidth: '300px',
              backgroundColor: '#F2F2F9',
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
              borderRadius: '6px',
            }}
            placeholder={Translator.translate('merge.sourceOfChange')}
            value={mergeStore.merge.currentMergeDefinition}
            options={projectStore.projectsWithDefsForOptions.filter((f) => f.id !== definitionStore.currentDefinition.id)}
            onChange={onChangeDefinitions}
            size="small"
          />

          <Toggler
            value={mergeStore.merge.currentGroupMerge}
            tabItems={listGroupTabs()}
            onChangeTab={(value) => {
              mergeStore.merge.setCurrentGroupMerge(value as MergeGroups);
            }}
          />
        </Stack>

        <Button variant="contained" disabled={mergeStore.merge.totalCountChecked === 0} onClick={onSubmit}>
          {mergeStore.merge.totalCountChecked !== 0 || mergeStore.merge.totalCount !== 0
            ? `${Translator.translate('merge.applyChanges')} ${mergeStore.merge.totalCountChecked}/${mergeStore.merge.totalCount}`
            : Translator.translate('merge.applyChanges')}
        </Button>
      </Stack>

      {mergeStore.merge.currentGroupMerge === MergeGroups.Items && listItemsTabs().length > 0 && (
        <Toggler
          value={mergeStore.merge.selectedTab}
          tabItems={listItemsTabs()}
          onChangeTab={(value) => mergeStore.merge.setSelectedTab(value as MergeTabState)}
        />
      )}

      <Stack sx={{ height: generalActionHeight }}>
        {mergeStore.isLoading ? (
          <Stack justifyContent="center" alignItems="center" flexGrow={1}>
            <CircularProgress />
          </Stack>
        ) : mergeStore.merge.mergeData.length > 0 ? (
          mergeStore.merge.currentGroupMerge === MergeGroups.Items ? (
            <MergeTable />
          ) : (
            <ReflexContainer orientation="vertical">
              <ReflexElement
                style={{ paddingRight: '4px', marginRight: '4px' }}
                size={mergeStore.groupsMergeWidth}
                onStopResize={(args: HandlerProps) => mergeStore.setGroupsMergeWidth((args.domElement as Element).clientWidth)}>
                <GroupsMerge
                  onCheck={mergeStore.merge.setCheckedGroupParameters}
                  checkedKeys={{
                    checked: mergeStore.merge.allCheckedIds,
                    halfChecked: mergeStore.merge.halfCheckedGroupParameters,
                  }}
                />
              </ReflexElement>

              <ReflexSplitter propagate style={{ width: '6px', borderRadius: '4px', border: 'none', height: 'unset' }} />

              <ReflexElement style={{ paddingLeft: '12px' }}>
                <MergeTable />
              </ReflexElement>
            </ReflexContainer>
          )
        ) : (
          <Stack justifyContent="center" flexGrow={1}>
            <Stack sx={{ alignItems: 'center' }}>
              <img
                style={{ alignSelf: 'center' }}
                src={
                  mergeStore.merge.currentMergeDefinition === null || mergeStore.merge.currentGroupMerge === null
                    ? `${process.env.PUBLIC_URL}/img/not_selected_data.svg`
                    : `${process.env.PUBLIC_URL}/img/not_found_data.svg`
                }
                alt=""
              />
              <Typography variant="subtitle1" color="text.disabled">
                {mergeStore.merge.currentMergeDefinition === null || mergeStore.merge.currentGroupMerge === null
                  ? Translator.translate('merge.emptyMessage.notSelected')
                  : Translator.translate('merge.emptyMessage.noDifferences')}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

export default observer(MergePage);
