import React from 'react';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { Translator } from 'stores';
import { IconButtonNew } from 'components';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { IXmlNodeForm } from 'shared/interfaces';
import { IdNameDto } from 'shared/interfaces/Dto/BaseDto';

interface IParameterGroupEditFieldProps {
  paramGroupHeight?: number;
  defaultParameterBindings: IdNameDto[];
  formikTouched: FormikTouched<IXmlNodeForm>;
  formikErrors: FormikErrors<IXmlNodeForm>;
  formikValues: FormikValues;
  onEdit: () => void;
}

const ParameterGroupEditField: React.FC<IParameterGroupEditFieldProps> = (props) => {
  const { paramGroupHeight, defaultParameterBindings, formikTouched, formikErrors, formikValues, onEdit } = props;

  const renderValues = (): React.ReactNode => {
    return (
      <Stack flexWrap="wrap" flexDirection="row" gap={2}>
        {defaultParameterBindings.map((value) => {
          return <Chip sx={{ width: 'fit-content' }} key={value.id} label={value.name} size="small" />;
        })}
      </Stack>
    );
  };

  return (
    <Stack gap={2}>
      <Typography variant="caption">{Translator.translate('common.parameterGroupEditField.name')}</Typography>

      <Stack flexDirection="row" alignItems="center" gap={4} height="100%">
        <Stack sx={{ width: '100%' }}>
          <Stack flexDirection="row" alignItems="center" gap={4} height="100%">
            <Stack
              sx={(theme) => ({
                height: paramGroupHeight && defaultParameterBindings.length > 0 ? `${paramGroupHeight - 28}px` : 'auto',
                width: '100%',
                overflow: 'auto',
                borderRadius: '4px',
                border: `1px solid ${
                  Boolean(formikTouched.parameterBindings && formikTouched.parameterBindings) && defaultParameterBindings.length === 0
                    ? theme.palette.error.main
                    : theme.palette.grey[100]
                }`,
                padding: '16.5px 14px',
                ':hover': {
                  border: `1px solid ${
                    Boolean(formikTouched.parameterBindings && formikTouched.parameterBindings) && defaultParameterBindings.length === 0
                      ? theme.palette.error.main
                      : formikValues.value !== null
                      ? theme.palette.grey[100]
                      : theme.palette.common.black
                  }`,
                },
              })}>
              {defaultParameterBindings.length > 0 ? (
                renderValues()
              ) : (
                <Typography
                  variant="body1"
                  sx={(theme) => ({
                    color: Boolean(formikTouched.parameterBindings && formikTouched.parameterBindings)
                      ? theme.palette.error.main
                      : theme.palette.text.secondary,
                  })}>
                  {Translator.translate('common.parameterGroupEditField.name')}
                </Typography>
              )}
            </Stack>

            <Box
              sx={(theme) => ({
                'button svg': {
                  fill: theme.palette.primary.main,
                  fillOpacity: 1,
                },
              })}>
              <IconButtonNew
                onClick={onEdit}
                disabled={formikValues.selector === null || formikValues.value !== null}
                tooltip={Translator.translate('common.parameterGroupEditField.editParameterButtonTitle')}>
                <EditPencilIcon />
              </IconButtonNew>
            </Box>
          </Stack>

          {(formikTouched.parameterBindings as any) && (formikErrors.parameterBindings as any) && (
            <Typography
              variant="caption"
              sx={(theme) => ({
                color: theme.palette.error.main,
                margin: '3px 14px 0 14px',
              })}>
              {(formikTouched.parameterBindings as any) && (formikErrors.parameterBindings as any)}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ParameterGroupEditField;
