import React, { ReactNode } from 'react';
import { GridColDef, useGridApiContext } from '@mui/x-data-grid-pro';
import { CustomDatePicker, Input } from 'components';
import { RangeEditor } from 'components/App/EditableTable/editors';
import DateValueIcon from 'components/Icons/DateValueIcon';
import FileValueIcon from 'components/Icons/FileValueIcon';
import LinkValueIcon from 'components/Icons/LinkValueIcon';
import ListIcon from 'components/Icons/ListIcon';
import LogicalValueIcon from 'components/Icons/LogicalValueIcon';
import NumberValueIcon from 'components/Icons/NumberValueIcon';
import RangeValueIcon from 'components/Icons/RangeValueIcon';
import TextValueIcon from 'components/Icons/TextValueIcon';
import { Stack } from '@mui/material';
import { DataType } from 'shared/enums';

const RenderEditableCell: GridColDef['renderCell'] = (params: any) => {
  const { id, value, field, row } = params;
  const apiRef = useGridApiContext();

  const iconValue = (): ReactNode => {
    switch (row.dataType) {
      case DataType.Number:
        return <NumberValueIcon />;

      case DataType.Range:
        return <RangeValueIcon />;

      case DataType.Boolean:
        return <LogicalValueIcon />;

      case DataType.String:
        return <TextValueIcon />;

      case DataType.Link:
        return <LinkValueIcon />;

      case DataType.List:
        return <ListIcon />;

      case DataType.DateTime:
        return <DateValueIcon />;

      case DataType.File:
        return <FileValueIcon />;

      default:
        return <TextValueIcon />;
    }
  };

  const onChangeValue = (value: string | Date) => {
    apiRef.current.setEditCellValue({ id, field, value: value });
    apiRef.current.stopCellEditMode({ id, field });
  };

  const onCancelEditData = () => {
    apiRef.current.stopCellEditMode({ id, field });
  };

  const editableCell = (): ReactNode => {
    switch (row.dataType) {
      case DataType.Range:
        return <RangeEditor value={value} onChange={onChangeValue} />;

      case DataType.DateTime:
        return (
          <CustomDatePicker
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiOutlinedInput-input': {
                padding: 0,
              },
            }}
            value={value}
            outline
            submitOnBlur
            label=""
            onSubmit={onChangeValue}
            onCancel={onCancelEditData}
          />
        );

      default:
        return (
          <Input
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiOutlinedInput-input': {
                padding: 0,
              },
              '& .MuiOutlinedInput-root': {
                padding: 0,
              },
            }}
            multiline={row.dataType === DataType.String || row.dataType === DataType.Link}
            type={row.dataType === DataType.Number ? 'number' : 'string'}
            variant="outlined"
            autoComplete="off"
            canFocus={true}
            value={value}
            onChange={onChangeValue}
          />
        );
    }
  };

  return (
    <Stack sx={{ width: '100%', padding: '0 10px' }} flexDirection="row" gap={1} alignItems="center">
      {iconValue()}

      {editableCell()}
    </Stack>
  );
};

export default RenderEditableCell;
