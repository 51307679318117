import React from 'react';

const ProjectIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8135 3.68508C10.3454 3.28385 9.65462 3.28385 9.18651 3.68508L4.18651 7.9708C3.90945 8.20828 3.75 8.55496 3.75 8.91987V16C3.75 16.6904 4.30964 17.25 5 17.25H7.25V13C7.25 12.0335 8.0335 11.25 9 11.25H11C11.9665 11.25 12.75 12.0335 12.75 13V17.25H15C15.6904 17.25 16.25 16.6904 16.25 16V8.91987C16.25 8.55496 16.0905 8.20828 15.8135 7.9708L10.8135 3.68508ZM11.25 17.25V13C11.25 12.8619 11.1381 12.75 11 12.75H9C8.86193 12.75 8.75 12.8619 8.75 13V17.25H11.25ZM8.21032 2.5462C9.24017 1.66347 10.7598 1.66347 11.7897 2.5462L16.7897 6.83191C17.3992 7.35436 17.75 8.11708 17.75 8.91987V16C17.75 17.5188 16.5188 18.75 15 18.75H5C3.48122 18.75 2.25 17.5188 2.25 16V8.91987C2.25 8.11708 2.6008 7.35436 3.21032 6.83191L8.21032 2.5462Z"
      />
    </svg>
  );
};

export default ProjectIcon;
