import React from 'react';

const NavigationOpenIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5998 3.20001C1.15798 3.20001 0.799805 3.55818 0.799805 4.00001C0.799805 4.44184 1.15798 4.80001 1.5998 4.80001H14.3998C14.8416 4.80001 15.1998 4.44184 15.1998 4.00001C15.1998 3.55818 14.8416 3.20001 14.3998 3.20001H1.5998Z" />
      <path d="M1.5998 7.20001C1.15798 7.20001 0.799805 7.55818 0.799805 8.00001C0.799805 8.44184 1.15798 8.80001 1.5998 8.80001H8.7998C9.24163 8.80001 9.5998 8.44184 9.5998 8.00001C9.5998 7.55818 9.24163 7.20001 8.7998 7.20001H1.5998Z" />
      <path d="M1.5998 11.2C1.15798 11.2 0.799805 11.5582 0.799805 12C0.799805 12.4418 1.15798 12.8 1.5998 12.8H8.7998C9.24163 12.8 9.5998 12.4418 9.5998 12C9.5998 11.5582 9.24163 11.2 8.7998 11.2H1.5998Z" />
      <path d="M13.7924 14.1206C14.0799 14.4561 14.585 14.495 14.9204 14.2074C15.2559 13.9199 15.2947 13.4148 15.0072 13.0794L13.0535 10.8L15.0072 8.52065C15.2947 8.18518 15.2559 7.68014 14.9204 7.39261C14.585 7.10507 14.0799 7.14392 13.7924 7.47938L11.3924 10.2794C11.1356 10.579 11.1356 11.0211 11.3924 11.3206L13.7924 14.1206Z" />
    </svg>
  );
};

export default NavigationOpenIcon;
