import dayjs from 'dayjs';
import { HEADER_HEIGHT, LAYOUT_PADDING, PAGE_TITLE_HEIGHT, PAGE_TITLE_PADDING } from 'shared/constants/constants';
import { ApprovalUserRole, PartialApprovalIteration } from 'shared/enums';
import { Routes } from 'shared/enums/Routes';
import { IGetXmlDocumentNodeDto, ISearch, ITreeNode, IXmlDocumentNodeAttributeDto, IXmlSchemaTemplateElement } from 'shared/interfaces';

export default class Utils {
  /**
   * Обобщенный метод сортировки
   * @param isAscending - направление сортировки
   * @param list - сортируемый список
   * @param propertyName - критерий сортировки, поле класса Т
   */
  public static sort = <T>(isAscending: boolean, list: T[], propertyName: keyof T): T[] => {
    return isAscending
      ? list.slice().sort((a, b) => Utils.ascComparator(a, b, propertyName))
      : list.slice().sort((a, b) => Utils.descComparator(a, b, propertyName));
  };

  /**
   * Компоратор для сортировки по возрастанию
   * @param leftItem - левый операнд
   * @param rightItem - правый операнд
   * @param propertyName - поле объекта для сравнения
   */
  public static ascComparator = <T>(leftItem: T, rightItem: T, propertyName: keyof T): number => {
    // if (!leftItem[propertyName]) return -1;

    if (leftItem[propertyName] < rightItem[propertyName]) {
      return -1;
    }
    if (leftItem[propertyName] > rightItem[propertyName]) {
      return 1;
    }
    return 0;
  };

  public static ascComp = <T>(leftItem: T, rightItem: T): number => {
    if (leftItem < rightItem) {
      return -1;
    }
    if (leftItem > rightItem) {
      return 1;
    }
    return 0;
  };

  public static descComp = <T>(leftItem: T, rightItem: T): number => {
    if (leftItem > rightItem) {
      return -1;
    }
    if (leftItem < rightItem) {
      return 1;
    }
    return 0;
  };

  /**
   * Компоратор для сортировки по убыванию
   * @param leftItem - левый операнд
   * @param rightItem - правый операнд
   * @param propertyName - поле объекта для сравнения
   */
  public static descComparator = <T>(leftItem: T, rightItem: T, propertyName: keyof T): number => {
    if (leftItem[propertyName] > rightItem[propertyName]) {
      return -1;
    }
    if (leftItem[propertyName] < rightItem[propertyName]) {
      return 1;
    }
    return 0;
  };

  /**
   * Компоратор для сортировки даты по возрастанию
   * @param leftItem - левый операнд
   * @param rightItem - правый операнд
   * @param propertyName - поле объекта для сравнения
   */
  public static ascDateComparator = (leftItem: string, rightItem: string): number => {
    if (new Date(leftItem) < new Date(rightItem)) {
      return -1;
    }
    if (new Date(leftItem) > new Date(rightItem)) {
      return 1;
    }
    return 0;
  };

  /**
   * Компоратор для сортировки даты по убыванию
   * @param leftItem - левый операнд
   * @param rightItem - правый операнд
   * @param propertyName - поле объекта для сравнения
   */
  public static descDateComparator = (leftItem: string, rightItem: string): number => {
    if (new Date(leftItem) > new Date(rightItem)) {
      return -1;
    }
    if (new Date(leftItem) < new Date(rightItem)) {
      return 1;
    }
    return 0;
  };

  public static sortTreeByOrderNum<T>(treeData: any[], propertyName: keyof T): T[] {
    const loop = (data: any[]): any[] => {
      const sortData = Utils.sortByField(data, 'orderNum');
      return sortData.map((section: any) => {
        if (section[propertyName].length > 0) {
          const sorting = Utils.sortByField(section[propertyName], 'orderNum');

          return {
            ...section,
            [propertyName]: loop(sorting),
          };
        } else return section;
      });
    };

    const data = loop(treeData);

    return data;
  }

  public static sortByField<T>(items: T[], propertyName: keyof T): T[] {
    if (propertyName === 'orderNum' || propertyName === 'parentId')
      return items.slice().sort((left: T, right: T) => {
        if (left[propertyName] < right[propertyName]) {
          return -1;
        } else if (left[propertyName] > right[propertyName]) {
          return 1;
        }
        return 0;
      });
    else
      return items.slice().sort((left: T, right: T) => {
        if (String(left[propertyName]).toLocaleLowerCase() < String(right[propertyName]).toLocaleLowerCase()) {
          return -1;
        } else if (String(left[propertyName]).toLocaleLowerCase() > String(right[propertyName]).toLocaleLowerCase()) {
          return 1;
        }
        return 0;
      });
  }

  public static customSortMergeColumn<T>(items: T[], propertyName: keyof T, targetArrays: any[]): T[] {
    return items.slice().sort((left: T, right: T) => {
      const i1 = targetArrays.indexOf(left[propertyName]);
      const i2 = targetArrays.indexOf(right[propertyName]);
      return i1 < 0 ? 1 : i2 < 0 ? -1 : i1 - i2;
    });
  }

  public static includes(target = '', searchValue = ''): boolean {
    return target.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0;
  }

  public static deleteInnerWrap(value: string | null): string {
    if (!value) return '';

    const arr = value.split('\n').filter((f) => f !== '');
    return arr.join('\r\n');
  }

  public static findSearch(treeData: any[], searchValue: string): ISearch[] {
    const findNodes: ISearch[] = [];

    const loop = (data: any[]) => {
      data.map((data: any) => {
        if (data.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) findNodes.push({ title: data.name, key: data.key });

        if (data.children) {
          loop(data.children!);
        }
      });
    };

    loop(treeData);

    return findNodes;
  }

  public static today(): Date {
    return new Date(dayjs().format('YYYY-MM-DD'));
  }

  /**
   * Возвращает сет идентификаторов узлов отфильтрованный по максимальной глубине
   */
  public static filterNodesIds(nodes: ITreeNode[], maxLevel = 1): Set<number> {
    const ids: Set<number> = new Set();
    nodes.forEach((node: ITreeNode) => {
      if (node.parentId === null) {
        ids.add(node.id);
      }
    });

    for (let i = 0; i < maxLevel; ++i) {
      const newIds: number[] = [];
      nodes.forEach((node: ITreeNode) => {
        if (node.parentId !== null && ids.has(node.parentId)) {
          newIds.push(node.id);
        }
      });

      for (const id of newIds) {
        ids.add(id);
      }
    }

    return ids;
  }

  public static getInitials(fullName: string): string {
    if (fullName === '' || !fullName) return '';
    const nameArr = fullName.split(' ');
    const nameLetter = nameArr[0][0];
    const surnameLetter = nameArr.length > 1 ? nameArr[1][0] : '';
    return `${nameLetter.toUpperCase()}${surnameLetter.toUpperCase()}`;
  }

  public static projectHeight(): number {
    if (this.isShowBreadcrumbs(window.location.pathname)) {
      return HEADER_HEIGHT + LAYOUT_PADDING + PAGE_TITLE_HEIGHT + PAGE_TITLE_PADDING;
    } else {
      return HEADER_HEIGHT + LAYOUT_PADDING;
    }
  }

  public static isShowBreadcrumbs(location: string): boolean {
    return (
      location.includes(Routes.OBJECT_VALUE) ||
      location.includes(Routes.SPECIFICATIONS) ||
      location.includes(Routes.COMMENTS) ||
      location.includes(Routes.MERGE) ||
      location.includes(Routes.PARTIAL_APPROVALS) ||
      location.includes(Routes.XML_VIEWER)
    );
  }

  public static isChangeArray(prevValues: any[], currentValues: any[]): boolean {
    const oldValuesIds = new Set(prevValues.map((data) => data.id));
    const isEqualValues = currentValues.every((data) => oldValuesIds.has(data.id));

    return (prevValues.length === currentValues.length && !isEqualValues) || prevValues.length !== currentValues.length;
  }

  public static getParentNodeIds(id: number, initArray: any[]): number[] {
    const parents: number[] = [];

    const loop = (node: any) => {
      if (node.parentId) {
        parents.push(node.parentId);
      }

      const parent = initArray.find((value) => node.parentId !== null && value.id === node.parentId);

      parent && loop(parent);
    };

    const node = initArray.find((value) => value.id === id);
    node && loop(node);

    return parents;
  }

  public static generateId(): number {
    return new Date().getTime() + Math.random();
  }

  public static canEditApproval(
    currentPartialApprovalIteration: PartialApprovalIteration,
    currentPartialApprovalUserRoles: ApprovalUserRole[]
  ): boolean {
    switch (currentPartialApprovalIteration) {
      case PartialApprovalIteration.Examination:
        if (currentPartialApprovalUserRoles.includes(ApprovalUserRole.Examiner)) return false;
        else return true;

      case PartialApprovalIteration.Execution:
        if (currentPartialApprovalUserRoles.includes(ApprovalUserRole.Executor)) return false;
        else return true;

      case PartialApprovalIteration.Recheck:
        if (currentPartialApprovalUserRoles.includes(ApprovalUserRole.Examiner)) return false;
        else return true;

      case PartialApprovalIteration.Approval:
        if (currentPartialApprovalUserRoles.includes(ApprovalUserRole.Approver)) return false;
        else return true;

      default:
        return false;
    }
  }

  public static checkArrayIntersection(
    currentPartialApprovalIteration: PartialApprovalIteration,
    currentPartialApprovalUserRoles: ApprovalUserRole[]
  ): boolean {
    switch (currentPartialApprovalIteration) {
      case PartialApprovalIteration.Examination:
        if (currentPartialApprovalUserRoles.includes(ApprovalUserRole.Examiner)) return false;
        else return true;

      case PartialApprovalIteration.Execution:
        if (currentPartialApprovalUserRoles.includes(ApprovalUserRole.Executor)) return false;
        else return true;

      case PartialApprovalIteration.Recheck:
        if (currentPartialApprovalUserRoles.includes(ApprovalUserRole.Examiner)) return false;
        else return true;

      case PartialApprovalIteration.Approval:
        if (currentPartialApprovalUserRoles.includes(ApprovalUserRole.Approver)) return false;
        else return true;

      default:
        return false;
    }
  }

  public static findAttributes(schemaNodes: IXmlSchemaTemplateElement[]): IXmlDocumentNodeAttributeDto[] {
    const attribute: IXmlDocumentNodeAttributeDto[] = [];

    const loop = (data: IXmlSchemaTemplateElement[]) => {
      data.forEach((data: IXmlSchemaTemplateElement) => {
        if (data.name === 'xs:attribute' && data.attributes) {
          attribute.push({
            id: null,
            name: data.attributes.name ?? '',
            type: data.attributes.type ?? '',
            required: data.attributes.use && data.attributes.use === 'required' ? true : false,
            value: data.attributes.fixed ?? null,
            description:
              data.elements && data.elements.length > 0 && data.elements[0].name === 'xs:annotation' ? this.findDescription(data.elements) : '',
            selector: null,
            parameterIdBind: null,
            classIdBind: null,

            attributes: [],
            parameterBindings: [],
          });
        }

        if (data.elements && data.elements.length > 0) {
          loop(data.elements);
        }
      });
    };

    loop(schemaNodes);

    return attribute;
  }

  public static findNextNewNodes(schemaNodes: IXmlSchemaTemplateElement[]): IGetXmlDocumentNodeDto[] {
    const nextNewNodes: IGetXmlDocumentNodeDto[] = [];

    const loop = (data: IXmlSchemaTemplateElement[]) => {
      data.forEach((data: IXmlSchemaTemplateElement) => {
        if (data.name === 'xs:sequence') {
          if (data.elements && data.elements.length > 0) {
            data.elements.forEach((value) => {
              if (value.name === 'xs:element' && value.attributes) {
                nextNewNodes.push({
                  id: null,
                  name: value.attributes.name ?? '',
                  type: value.attributes.type ?? '',
                  required: value.attributes.use && value.attributes.use === 'required' ? true : false,
                  value: value.attributes.fixed ?? null,
                  description:
                    value.elements && value.elements.length > 0 && value.elements[0].name === 'xs:annotation'
                      ? this.findDescription([value.elements[0]])
                      : '',
                  selector: null,
                  classIdBind: null,
                  parentId: null,

                  attributes: [],
                  parameterBindings: [],
                });
              }
            });

            return;
          }
        }

        if (data.elements && data.elements.length > 0) {
          loop(data.elements);
        }
      });
    };

    loop(schemaNodes);

    return nextNewNodes;
  }

  public static findDescription(schemaNodes: IXmlSchemaTemplateElement[]): string {
    let description = '';

    const loop = (data: IXmlSchemaTemplateElement[]) => {
      data.forEach((data: IXmlSchemaTemplateElement) => {
        if (data.name === 'xs:documentation') {
          description = data.elements && data.elements.length > 0 ? data.elements[0].text ?? '' : '';
          return;
        }

        if (data.elements && data.elements.length > 0) {
          loop(data.elements);
        }
      });
    };

    loop(schemaNodes);

    return description;
  }

  public static findDefaultXMLSchemaNodes(schemaNodes: IXmlSchemaTemplateElement[], searchType: string, searchName: string): IGetXmlDocumentNodeDto {
    const findNodes: IGetXmlDocumentNodeDto = {
      id: null,
      name: searchName,
      type: searchType,
      description: '',
      selector: null,
      attributes: [],
      required: false,
      value: null,
      parameterBindings: [],
      classIdBind: null,
      parentId: null,
      nextNewNodes: [],
    };

    const loop = (values: IXmlSchemaTemplateElement[]) => {
      values.forEach((data: IXmlSchemaTemplateElement) => {
        if (
          data.name === 'xs:element' &&
          data.attributes &&
          ((data.attributes.hasOwnProperty('type') && data.attributes.type === findNodes.type && data.attributes.name === findNodes.name) ||
            (!data.attributes.hasOwnProperty('type') && data.attributes.name === findNodes.name))
        ) {
          findNodes.name = data.attributes.name ?? '';
          findNodes.description =
            data.elements && data.elements.length > 0 && data.elements[0].name === 'xs:annotation' ? this.findDescription(data.elements) : '';

          if (
            !data.attributes.hasOwnProperty('type') &&
            data.name === 'xs:element' &&
            data.attributes.name === findNodes.name &&
            data.attributes.name !== 'Document' &&
            data.type === 'element'
          ) {
            const findEl = data.elements?.find((f) => f.name === 'xs:complexType' || f.name === 'xs:simpleType');

            if (findEl) {
              findNodes.required =
                findEl.hasOwnProperty('attributes') && findEl.attributes && findEl.attributes.use && findEl.attributes.use === 'required'
                  ? true
                  : false;
              findNodes.value = findEl.hasOwnProperty('attributes') && findEl.attributes && findEl.attributes.fixed ? findEl.attributes.fixed : null;
              findNodes.attributes = findEl.elements && findEl.elements.length > 0 ? this.findAttributes(findEl.elements) : [];
              findNodes.nextNewNodes = findEl.elements && findEl.elements.length > 0 ? this.findNextNewNodes(findEl.elements) : [];
            }
          }

          const newFindNode = this.findSimpleOrComplexType(schemaNodes, findNodes);
          return newFindNode;
        }

        if (data.hasOwnProperty('elements') && data.elements && data.elements.length > 0) {
          loop(data.elements);
        }
      });
    };

    loop(schemaNodes);

    return findNodes;
  }

  public static findSimpleOrComplexType(schemaNodes: IXmlSchemaTemplateElement[], findNodes: IGetXmlDocumentNodeDto): IGetXmlDocumentNodeDto {
    const loop = (values: IXmlSchemaTemplateElement[]) => {
      values.forEach((data: IXmlSchemaTemplateElement) => {
        if ((data.name === 'xs:simpleType' || data.name === 'xs:complexType') && data.attributes && data.attributes.name === findNodes.type) {
          findNodes.required = data.attributes.use && data.attributes.use === 'required' ? true : false;
          findNodes.value = data.attributes.fixed ?? null;
          findNodes.attributes = data.elements && data.elements.length > 0 ? this.findAttributes(data.elements) : [];
          findNodes.nextNewNodes = data.elements && data.elements.length > 0 ? this.findNextNewNodes(data.elements) : [];
          return findNodes;
        }

        if (data.hasOwnProperty('elements') && data.elements && data.elements.length > 0) {
          loop(data.elements);
        }
      });
    };

    loop(schemaNodes);

    return findNodes;
  }

  public static async parseBlobError(e: any, defaultMessage: string): Promise<string> {
    const errorBlob = e.data;
    const errorText = await errorBlob.text();
    let errorMessage = defaultMessage;

    try {
      const errorJson = JSON.parse(errorText);
      errorMessage = errorJson.message || defaultMessage;
    } catch {
      errorMessage = errorText || defaultMessage;
    }

    return errorMessage;
  }
}
