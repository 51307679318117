import React, { useState } from 'react';
import { DataGridPro, DataGridProProps, GridColumns, gridClasses, GridCellParams, useGridApiRef, MuiEvent } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Translator, approvalStore, approvalSuggestionRowStore, parameterListValueStore } from 'stores';
import { CustomCellGrouping, CustomGridParameterRow } from 'components';
import DateValueIcon from 'components/Icons/DateValueIcon';
import FileValueIcon from 'components/Icons/FileValueIcon';
import LinkValueIcon from 'components/Icons/LinkValueIcon';
import ListIcon from 'components/Icons/ListIcon';
import LogicalValueIcon from 'components/Icons/LogicalValueIcon';
import NumberValueIcon from 'components/Icons/NumberValueIcon';
import RangeValueIcon from 'components/Icons/RangeValueIcon';
import TextValueIcon from 'components/Icons/TextValueIcon';
import { Box, Stack, Typography } from '@mui/material';
import { DataType, LangNames, StateBoolean, SuggestionType } from 'shared/enums';
import { IParameterSuggestion } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';
import { CreateSuggestionValueDialog } from './Components';

const SuggestionValueTable: React.FC = () => {
  const apiRef = useGridApiRef();

  const [row, setRow] = useState<IParameterSuggestion | null>(null);

  const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy;

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: Translator.translate('approvals.approvalRowsPage.suggestionDialog.columns.name'),
      flex: 1,
      minWidth: 150,
      resizable: true,
      hideSortIcons: true,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      colSpan: (param: GridCellParams) => {
        const { row }: { row: IParameterSuggestion } = param;

        return !row.isGroup ? 1 : 2;
      },
    },
    {
      field: 'value',
      headerName: Translator.translate('approvals.approvalRowsPage.suggestionDialog.columns.value'),
      flex: 1,
      minWidth: 150,
      resizable: true,
      hideSortIcons: true,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (params: GridCellParams) => {
        const { row }: { row: IParameterSuggestion } = params;

        const IconValue = (): JSX.Element => {
          switch (row.dataType) {
            case DataType.Number:
              return <NumberValueIcon />;

            case DataType.Range:
              return <RangeValueIcon />;

            case DataType.Boolean:
              return <LogicalValueIcon />;

            case DataType.String:
              return <TextValueIcon />;

            case DataType.Link:
              return <LinkValueIcon />;

            case DataType.List:
              return <ListIcon />;

            case DataType.DateTime:
              return <DateValueIcon />;

            case DataType.File:
              return <FileValueIcon />;

            default:
              return <TextValueIcon />;
          }
        };

        const lists = (): any[] => {
          return row.parameterListValues!.map((listValue) => {
            return (
              <Typography variant="body2" key={listValue.id}>
                {Utils.deleteInnerWrap(listValue.value)}
              </Typography>
            );
          });
        };

        const booleanView = (): string => {
          switch (row.value) {
            case StateBoolean.False:
              return Translator.translate('system.false');

            case StateBoolean.True:
              return Translator.translate('system.true');

            default:
              return '';
          }
        };

        const renderValue = (): JSX.Element => {
          switch (row.dataType) {
            case DataType.List:
              return <Box>{row.value !== '' ? row.value : lists()}</Box>;

            case DataType.Boolean:
              return <Typography variant="body2">{booleanView()}</Typography>;

            case DataType.DateTime:
              return (
                <Typography variant="body2">
                  {dayjs(row.value, 'YYYY-MM-DD').isValid()
                    ? dayjs(row.value).format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY' : 'MM/DD/YYYY')
                    : ''}
                </Typography>
              );

            default:
              return (
                <Typography
                  variant="body2"
                  sx={{
                    display: 'flex',
                  }}>
                  {Utils.deleteInnerWrap(row.value!)}
                </Typography>
              );
          }
        };

        return (
          <Stack flexDirection="row" gap={1} sx={{ wordBreak: 'break-all' }}>
            <Box sx={{ display: 'flex' }}>{IconValue()}</Box>

            {renderValue()}
          </Stack>
        );
      },
    },
  ];

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    width: 10,
    minWidth: 30,
    headerName: '',
    renderCell: (params) => <CustomCellGrouping isGroup={params.row.isGroup} {...params} />,
  };

  const onSelectedRow = async (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    const { row }: { row: IParameterSuggestion } = params;

    if (row.isGroup) {
      apiRef.current.setRowChildrenExpansion(row.id!, !(params.rowNode.childrenExpanded ?? false));
      return;
    }

    approvalSuggestionRowStore.setCreateValueDialogOpen(true);
    setRow(row);

    approvalSuggestionRowStore.approvalSuggestionRow.setSuggestionType(SuggestionType.Create);
    approvalSuggestionRowStore.approvalSuggestionRow.setApprovalId(approvalStore.selectedApproval.id!);

    if (row.dataType === DataType.List) {
      await parameterListValueStore.getParameterListValues(row.id);
    }
  };

  const noRows = (): JSX.Element => (
    <Stack alignItems="center" mt={8}>
      <img
        style={{ alignSelf: 'center' }}
        src={
          approvalSuggestionRowStore.filterParameters.searchPattern === ''
            ? approvalSuggestionRowStore.filterParameters.parameterGroupId
              ? `${process.env.PUBLIC_URL}/img/empty_data.svg`
              : `${process.env.PUBLIC_URL}/img/not_selected_data.svg`
            : `${process.env.PUBLIC_URL}/img/not_found_data.svg`
        }
        alt=""
      />
      <Typography variant="subtitle1" color="text.disabled">
        {approvalSuggestionRowStore.filterParameters.searchPattern === ''
          ? approvalSuggestionRowStore.filterParameters.parameterGroupId
            ? Translator.translate('system.emptyData')
            : Translator.translate('approvals.approvalRowsPage.suggestionDialog.groupNotFound')
          : Translator.translate('system.notFoundData')}
      </Typography>
    </Stack>
  );

  return (
    <Box sx={{ height: '80vh' }}>
      <DataGridPro
        sx={{
          border: 'none',
          '& .MuiDataGrid-row': {
            width: '100%',
            minHeight: '36px !important',
          },
          '& .MuiDataGrid-columnHeaders': {
            border: 0,
          },
          '& .MuiDataGrid-cell': {
            border: 'none',
          },
          '& .MuiDataGrid-cell[data-field="value"]': {
            cursor: 'pointer',
          },
          '& .MuiDataGrid-columnHeader:first-child .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-row:not(:last-child)': {
            marginBottom: '2px',
          },
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none',
          },
        }}
        apiRef={apiRef}
        hideFooter
        getRowHeight={() => {
          return 'auto';
        }}
        isRowSelectable={(params: any) => params.row.parameterIds !== null}
        headerHeight={36}
        treeData
        rows={approvalSuggestionRowStore.filteredParamSuggestionTableData}
        columns={columns}
        disableColumnMenu
        onCellClick={onSelectedRow}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
        defaultGroupingExpansionDepth={1}
        experimentalFeatures={{ newEditingApi: true }}
        disableSelectionOnClick
        loading={approvalSuggestionRowStore.isLoadingParameter}
        components={{
          Row: CustomGridParameterRow,
          NoRowsOverlay: noRows,
        }}
      />

      {approvalSuggestionRowStore.isCreateValueDialogOpen && row && <CreateSuggestionValueDialog parameterRow={row} />}
    </Box>
  );
};

export default observer(SuggestionValueTable);
