import { makeAutoObservable, runInAction } from 'mobx';
import { ParameterListValueService } from 'services';
import { toastStore, parameterStore, Translator } from 'stores';
import { ListValueMode } from 'shared/enums';
import { IParameterGroupListValue, IParameterListValueDto, ISelectOption } from 'shared/interfaces';
import { DocumentListModel } from 'shared/models';
import { ParameterListValueModel } from 'shared/models/ParameterListValueModel';
import Utils from 'shared/utils/Utils';

class ParameterListValueStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public isDialogOpen = false;

  public isDialogFilesOpen = false;

  public isDocumentDialogOpen = false;

  public isRuleDialogOpen = false;

  public parameterListValues: ParameterListValueModel[] = [];

  public parameterListValuesRules: ParameterListValueModel[] = [];

  public selectedParameterListValue = new ParameterListValueModel();

  public originalParameterListValues: IParameterListValueDto[] = [];

  public listEditorValues: number[] = [];

  public modeListValue = ListValueMode.WatchList;

  public currentListValues: IParameterGroupListValue[] | null = null;

  public get paramListValueForOptions(): ISelectOption[] {
    return this.parameterListValues.map((item) => item.getSelectOption()!);
  }

  public setOpenDialog(isOpen: boolean) {
    this.isDialogOpen = isOpen;
  }

  public setRuleDialogOpen(isOpen: boolean) {
    this.isRuleDialogOpen = isOpen;
  }

  public setDocumentDialogOpen(isOpen: boolean) {
    this.isDocumentDialogOpen = isOpen;
  }

  public setFilesDialogOpen(isOpen: boolean) {
    this.isDialogFilesOpen = isOpen;
  }

  public setModeListValue(modeListValue: ListValueMode) {
    this.modeListValue = modeListValue;
  }

  public setCurrentListValues(currentListValues: IParameterGroupListValue[] | null) {
    this.currentListValues = currentListValues;
  }

  public setListEditorValues(arr: number[]) {
    this.listEditorValues = arr;
  }

  public setSelectedParameterListValue(id: number) {
    this.selectedParameterListValue = this.parameterListValues.find((_) => _.id === id) ?? new ParameterListValueModel();
  }

  public addDocumentInList(documents: DocumentListModel[]) {
    documents.forEach((document) => this.selectedParameterListValue.documents.push(document));
  }

  public removeItemByIndex(rowIndex: number) {
    this.parameterListValues = this.parameterListValues.filter((item, index) => index !== rowIndex);
  }

  public appendEmptyItem() {
    if (this.parameterListValues.length > 0 && this.parameterListValues[this.parameterListValues.length - 1].id) {
      this.parameterListValues = this.parameterListValues.concat(new ParameterListValueModel());
    } else {
      this.parameterListValues.push(new ParameterListValueModel());
    }
  }

  public deleteDocumentFromList(id: number) {
    const findDocumentIndex = this.selectedParameterListValue.documents.findIndex((doc) => doc.id === id);
    this.selectedParameterListValue.documents.splice(findDocumentIndex, 1);
  }

  public async getParameterListValues(parameterId: number, owner?: string): Promise<void> {
    try {
      this.isLoading = true;
      const result = await ParameterListValueService.getAllParameterListValue(parameterId);
      if (!result) return;

      const sortingParameterListValues = Utils.sortByField(result, 'orderNum');

      this.originalParameterListValues = sortingParameterListValues;

      if (owner !== 'condition') {
        runInAction(() => {
          this.parameterListValues = sortingParameterListValues.map((dto) => new ParameterListValueModel(dto));
        });
      } else
        runInAction(() => {
          this.parameterListValuesRules = sortingParameterListValues.map((dto) => new ParameterListValueModel(dto));
        });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} ParameterListValues`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getRecommendParameterListValues(parameterId: number, itemId: number): Promise<void> {
    try {
      this.isLoading = true;
      const result = await ParameterListValueService.getRecommendParamListValue({ parameterId, itemId });
      if (!result) return;

      this.originalParameterListValues = Utils.sortByField(result, 'orderNum');

      runInAction(() => {
        this.parameterListValues = Utils.sortByField(result, 'orderNum').map((dto) => new ParameterListValueModel(dto));
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} RecommendParameterListValues`);
    } finally {
      this.isLoading = false;
    }
  }

  public async deleteItem(id: number) {
    await ParameterListValueService.deleteParameterListValue({
      parameterId: parameterStore.selectedParameterTable.id!,
      ids: [id],
    });
    await this.getParameterListValues(parameterStore.selectedParameterTable.id!);
  }

  public async updateParameterListValue(): Promise<void> {
    try {
      this.isLoading = true;
      if (this.selectedParameterListValue.id) {
        await ParameterListValueService.putParameterListValue(this.selectedParameterListValue.putDto.id, this.selectedParameterListValue.putDto);
      } else if (this.selectedParameterListValue.putDto.value !== '') {
        await ParameterListValueService.postParameterListValue({
          parameterId: parameterStore.selectedParameterTable.id!,
          values: [this.selectedParameterListValue.putDto],
        });
      }

      await this.getParameterListValues(parameterStore.selectedParameterTable.id!);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} ParameterListValue`);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new ParameterListValueStore();
