import { makeAutoObservable, toJS } from 'mobx';
import { ClassificationDictionaries, ProjectType } from 'shared/enums';

import { IProjectParamsDto, IProjectsFiltersDto } from 'shared/interfaces';

class ProjectFiltersModel {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public id: number | null = null;

  public name = '';

  public type?: ProjectType;

  public definitionIds: number[] = [];

  public buildingType: number[] = [];

  public region: number[] = [];

  public buildingClass: number[] = [];

  public get projParamsDto(): IProjectsFiltersDto {
    return {
      id: this.id ?? undefined,
      name: this.name !== '' ? this.name : undefined,
      type: this.type ?? undefined,
      classificationDictionaryIds: this.classificationDictionaryIds,
    };
  }

  public get defParamsDto(): IProjectParamsDto {
    return {
      definitionIds: this.definitionIds,
    };
  }

  public get classificationDictionaryChecked(): number[] {
    return [...this.buildingType, ...this.region, ...this.buildingClass];
  }

  public get classificationDictionaryIds(): Array<number[]> {
    return [this.buildingType, this.region, this.buildingClass];
  }

  public setDefinitionIds(ids: number[]) {
    this.definitionIds = ids;
  }

  public addArrayItem(item: number, arr: number[]) {
    const idx = arr.indexOf(item);
    if (idx !== -1) {
      arr.splice(idx, 1);
    } else {
      arr.push(item);
    }
  }

  public setClassificationDictionaryIds(id: number, dictionary: ClassificationDictionaries) {
    switch (dictionary) {
      case ClassificationDictionaries.BuildingType:
        this.addArrayItem(id, this.buildingType);
        break;

      case ClassificationDictionaries.Region:
        this.addArrayItem(id, this.region);
        break;

      case ClassificationDictionaries.BuildingClass:
        this.addArrayItem(id, this.buildingClass);
        break;
    }
  }

  public setType(type: ProjectType) {
    this.type = type;
  }

  public clear() {
    this.id = null;
    this.type = undefined;
    this.definitionIds = [];
  }

  public clearColumnFilters() {
    this.buildingClass = [];
    this.buildingType = [];
    this.region = [];
  }
}

export default ProjectFiltersModel;
