import React, { useEffect, useState, ChangeEvent } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  GridCellCheckboxRenderer,
  GridCellParams,
  GridColumnHeaderParams,
  GridColumns,
  GridHeaderCheckbox,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { Translator, parameterGroupStore, xmlDocumentInstanceStore } from 'stores';
import { CustomDataGrid, DialogWrapper, IconButtonNew, InputSearch, ParameterGroupFilter } from 'components';
import IconSearch from 'components/Icons/IconSearch';
import { Box, Stack, Fade, IconButton, Chip, Typography } from '@mui/material';
import { IdNameDto } from 'shared/interfaces/Dto/BaseDto';
import { ParameterGroupModel, XMLParamsModel } from 'shared/models';

interface IEditClassSectionProps {
  isDialogOpen: boolean;
  onSubmit: () => void;
}

const EditXmlParamDialog: React.FC<IEditClassSectionProps> = (props) => {
  const { isDialogOpen, onSubmit } = props;

  const [isOpenSearch, setOpenSearch] = useState(false);

  const dialogTitle = Translator.translate('common.parameterGroupEditField.editParameterDialogTitle');

  const columnsParameter: GridColumns = [
    {
      field: '__check__',
      type: 'checkboxSelection',
      width: 10,
      minWidth: 35,
      resizable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderHeader: (params) =>
        xmlDocumentInstanceStore.xmlParamsFilteredDataGrid.length > 0 && (
          <Stack justifyContent="center">
            <GridHeaderCheckbox {...params} />
            <Typography variant="button" style={{ position: 'absolute', left: '40px', fontSize: '13px' }}>
              {Translator.translate('catalogs.specTemplates.sections.selectAll')}
            </Typography>
          </Stack>
        ),
      renderCell: (params) => <GridCellCheckboxRenderer {...params} />,
    },
    {
      field: 'name',
      headerName: '',
      flex: 1,
      hideSortIcons: true,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
  ];

  useEffect(() => {
    if (!xmlDocumentInstanceStore.paramGroupFilter.id) {
      parameterGroupStore.getRootParameterGroupList().then(async () => {
        if (!parameterGroupStore.currentParameterGroup)
          parameterGroupStore.setCurrentParameterGroup(Number(parameterGroupStore.rootParameterGroup[0].id));

        parameterGroupStore.getParameterGroups().then(async () => {
          if (!xmlDocumentInstanceStore.paramGroupFilter.id) {
            parameterGroupStore.defaultExpandSelectXMLParamGroup();
          }
        });
      });
    }

    return () => {
      parameterGroupStore.resetParameterGroup();
      xmlDocumentInstanceStore.paramGroupFilter.clearModel();
      xmlDocumentInstanceStore.xmlParams = [];
    };
  }, []);

  const onChangeParamGroup = async (value: ParameterGroupModel) => {
    xmlDocumentInstanceStore.paramGroupFilter = new ParameterGroupModel(value);
    await xmlDocumentInstanceStore.getParameterGroupParameters();
  };

  const onClose = () => {
    xmlDocumentInstanceStore.selectedXMLNode.parameterBindings = [...xmlDocumentInstanceStore.selectedXMLNode.defaultParameterBindings];
    xmlDocumentInstanceStore.setEditParamDialogOpen(false);
  };

  const onClickSearch = () => {
    console.log('1');
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    xmlDocumentInstanceStore.setFilterName(event.target.value);
  };

  const onDeleteParameter = (e: React.MouseEvent, data: IdNameDto) => {
    xmlDocumentInstanceStore.selectedXMLNode.setParameterBindings(data, false);

    const findEl = xmlDocumentInstanceStore.xmlParams.find((f) => f.id === data.id);
    if (findEl) {
      findEl.setChecked(false);
    }
  };

  const renderValues = (): React.ReactNode => {
    return (
      <Stack flexWrap="wrap" flexDirection="row" gap={2} overflow="auto">
        {xmlDocumentInstanceStore.selectedXMLNode.parameterBindings.map((value) => {
          return (
            <Chip
              sx={{ width: 'fit-content' }}
              key={value.id}
              clickable
              label={value.name}
              deleteIcon={
                <IconButton
                  sx={{
                    padding: 0,
                    svg: {
                      fill: 'rgba(0, 0, 0, 0.26)',
                      width: '16px',
                      height: '16px',
                    },
                  }}
                  disableRipple
                  onMouseDown={(event: any) => event.stopPropagation()}>
                  <CancelIcon />
                </IconButton>
              }
              onDelete={(e) => onDeleteParameter(e, value)}
              size="small"
            />
          );
        })}
      </Stack>
    );
  };

  const onHeaderClick = (params: GridColumnHeaderParams, event: ChangeEvent<HTMLInputElement>) => {
    if (params.field !== '__check__') return;

    xmlDocumentInstanceStore.xmlParams.forEach((_) => {
      _.setChecked(event.target.checked);
      !_.isGroup && xmlDocumentInstanceStore.selectedXMLNode.setParameterBindings({ id: _.id, name: _.fullName }, event.target.checked);
    });
  };

  const onCellClick = (params: GridCellParams) => {
    const { row }: { row: XMLParamsModel } = params;

    if (params.field === columnsParameter[1].field || row.parameterIds === null) return;

    if (row.isGroup) {
      const selectedIDs = new Set(row.parameterIds);
      const selectedRows = xmlDocumentInstanceStore.xmlParams.filter((row) => selectedIDs.has(row.id!));

      selectedRows.forEach((_) => {
        !_.isGroup && xmlDocumentInstanceStore.selectedXMLNode.setParameterBindings({ id: _.id, name: _.fullName }, !row.checked);
        _.setChecked(!row.checked);
      });
    }

    const findParam = xmlDocumentInstanceStore.xmlParams.find((parameter) => row.id === parameter.id);
    if (findParam) {
      !findParam.isGroup &&
        xmlDocumentInstanceStore.selectedXMLNode.setParameterBindings({ id: findParam.id, name: findParam.fullName }, !findParam.checked);
      findParam.setChecked(!findParam.checked);
    }

    const isAllParameterChecked = xmlDocumentInstanceStore.xmlParams.filter((f) => f.groupId === row.groupId).every((parameter) => parameter.checked);

    xmlDocumentInstanceStore.xmlParams.find((f) => f.id === row.groupId)?.setChecked(isAllParameterChecked);
  };

  return (
    <DialogWrapper title={dialogTitle} maxWidth="lg" open={isDialogOpen} onClose={onClose} onSubmit={onSubmit} onCancel={onClose}>
      <Stack
        sx={{
          width: '100%',
          height: 'auto',
          overflow: 'hidden',
        }}>
        {xmlDocumentInstanceStore.selectedXMLNode.parameterBindings.length > 0 ? (
          renderValues()
        ) : (
          <Typography
            variant="body1"
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}>
            {Translator.translate('common.parameterGroupEditField.name')}
          </Typography>
        )}
      </Stack>

      <Stack gap={3}>
        {(xmlDocumentInstanceStore.xmlParamsFilteredDataGrid.length !== 0 ||
          (xmlDocumentInstanceStore.xmlParamsFilteredDataGrid.length === 0 && xmlDocumentInstanceStore.filterName !== '')) && (
          <Stack flexDirection="row" alignItems="center">
            {!isOpenSearch && (
              <Box width="100%">
                <ParameterGroupFilter value={xmlDocumentInstanceStore.paramGroupFilter.id} onChangeParamGroup={onChangeParamGroup} />
              </Box>
            )}

            {!isOpenSearch && (
              <IconButtonNew sx={{ mx: 2 }} onClick={() => setOpenSearch(true)}>
                <IconSearch />
              </IconButtonNew>
            )}

            {isOpenSearch && (
              <Box height="38px" sx={{ width: '100%' }}>
                <Fade timeout={100} in={isOpenSearch}>
                  <div>
                    {isOpenSearch && (
                      <InputSearch
                        fullWidth
                        isExpanded
                        value={xmlDocumentInstanceStore.filterName}
                        onSearch={onClickSearch}
                        onChange={onSearch}
                        onClose={() => {
                          xmlDocumentInstanceStore.setFilterName('');
                          setOpenSearch(false);
                        }}
                      />
                    )}
                  </div>
                </Fade>
              </Box>
            )}
          </Stack>
        )}

        <Box
          sx={{
            height: '400px',
            width: '100%',
          }}>
          <CustomDataGrid
            rows={xmlDocumentInstanceStore.xmlParamsFilteredDataGrid}
            isRowSelectable={(params: GridRowParams) => params.row.parameterIds}
            columns={columnsParameter}
            disableColumnMenu
            onCellClick={onCellClick}
            onColumnHeaderClick={(params, event: any) => onHeaderClick(params, event as ChangeEvent<HTMLInputElement>)}
            selectionModel={xmlDocumentInstanceStore.xmlParamsFilteredDataGrid.filter((_) => _.checked).map((_) => _.id!)}
            loading={xmlDocumentInstanceStore.isLoading}
            hasFiltered={xmlDocumentInstanceStore.filterName !== ''}
          />
        </Box>
      </Stack>
    </DialogWrapper>
  );
};

export default observer(EditXmlParamDialog);
