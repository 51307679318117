import React, { useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { Translator, classificationDictionaryValueStore } from 'stores';
import { DialogWrapper, InputValidate } from 'components';
import { CircularProgress, Box } from '@mui/material';
import { IDictionaryValueFormDto } from 'shared/interfaces';

const DictionaryValueDialog: React.FC = () => {
  const { selectedDictionary, isDialogOpen } = classificationDictionaryValueStore;

  const formRef = useRef<FormikProps<IDictionaryValueFormDto>>(null);

  const dialogTitle = selectedDictionary.id
    ? Translator.translate('catalogs.classifiers.editTitleDialog')
    : Translator.translate('catalogs.classifiers.addTitleDialog');

  const onSubmit = (values: IDictionaryValueFormDto) => {
    selectedDictionary.updateDictionaryValueDialogForm(values);

    if (!selectedDictionary.id) {
      classificationDictionaryValueStore.createDictionaryValue().then(() => onClose());
    } else {
      classificationDictionaryValueStore.updateDictionaryValue().then(() => onClose());
    }
  };

  const onClose = () => {
    classificationDictionaryValueStore.setDialogOpen(false);
    selectedDictionary.clear();
  };

  const dictionaryValueForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        value: selectedDictionary.value,
      }}
      validationSchema={Yup.object().shape({
        value: Yup.string().max(255).required(Translator.translate('validationMessage.required')),
      })}>
      {({ handleChange, handleBlur, values, errors, touched }) => {
        return (
          <InputValidate
            label={Translator.translate('catalogs.classifiers.columns.value')}
            name="value"
            error={Boolean(touched.value && errors.value)}
            helperText={(touched.value as any) && errors.value}
            value={values.value}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        );
      }}
    </Formik>
  );

  return (
    <DialogWrapper
      title={dialogTitle}
      maxWidth="md"
      open={isDialogOpen}
      onClose={onClose}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}
      onCancel={onClose}>
      {classificationDictionaryValueStore.isLoading ? (
        <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        dictionaryValueForm
      )}
    </DialogWrapper>
  );
};

export default observer(DictionaryValueDialog);
