import { makeAutoObservable } from 'mobx';
import { ConditionService } from 'services';
import { toastStore, parameterListValueStore, Translator } from 'stores';
import { IConditionListDto, IDeleteConditionsBatchDto, IPostConditionDto } from 'shared/interfaces';
import ConditionListModel from 'shared/models/ConditionListModel';

class ConditionStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public isLoadingParameters = false;

  public conditionsIf: ConditionListModel[] = [];

  public conditionsThen: ConditionListModel[] = [];

  public conditionListDto: IConditionListDto[] = [];

  public get conditionsForTable() {
    return this.conditionsThen.map((el) => {
      return {
        id: el.id,
        parameter: el.parameterIf.name,
        value: el.parameterListValueIf.name,
      };
    });
  }

  public addNewConditionRow() {
    const row = new ConditionListModel();
    row.setId(Math.random());
    this.conditionsIf.push(row);
  }

  public deleteConditionRow(conditionId: number) {
    const idx = this.conditionsIf.findIndex((_) => _.id === conditionId);
    if (idx !== -1) this.conditionsIf.splice(idx, 1);
  }

  public async getConditionsList(parameterListValueIfId?: number, parameterListValueThenId?: number): Promise<void> {
    try {
      this.isLoading = true;
      const result = await ConditionService.getConditions({
        parameterListValueIfId,
        parameterListValueThenId,
      });
      if (!result) return;

      this.conditionListDto = result;

      if (parameterListValueIfId) {
        this.conditionsIf = result.map((dto) => new ConditionListModel(dto));
      } else {
        this.conditionsThen = result.map((dto) => new ConditionListModel(dto));
      }
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Conditions`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createCondition(listValueThenIds: number[]): Promise<void> {
    const payload: IPostConditionDto = {
      parameterListValueIfId: parameterListValueStore.selectedParameterListValue.id!,
      parameterListValueThenIds: listValueThenIds,
    };

    try {
      this.isLoading = true;
      await ConditionService.postConditions(payload);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} Condition`);
    } finally {
      this.isLoading = false;
    }
  }

  public async removeCondition(delConditions: number[]): Promise<void> {
    const deleteRules: IDeleteConditionsBatchDto = {
      conditionIds: delConditions,
    };

    try {
      this.isLoading = true;
      await ConditionService.deleteConditionsBatch(deleteRules);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} Condition`);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new ConditionStore();
