import React, { ReactElement, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, auditStore, parameterGroupStore, parameterStore } from 'stores';
import { IconButtonNew } from 'components';
import { useConfirm } from 'components/App/Confirm';
import { ParameterGroupTreeSearch, ParameterGroupTreeSelect } from 'components/App/TreeData';
import CopyIcon from 'components/Icons/CopyIcon';
import DownIcon from 'components/Icons/DownIcon';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import HistoryIcon from 'components/Icons/HistoryIcon';
import IconSearch from 'components/Icons/IconSearch';
import InsertIcon from 'components/Icons/InsertIcon';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import TrashIcon from 'components/Icons/TrashIcon';
import UpIcon from 'components/Icons/UpIcon';
import { Box, CircularProgress, Stack, Fade, MenuItem, Button, Divider } from '@mui/material';
import { AuditTables } from 'shared/enums';
import { ParameterGroupModel } from 'shared/models';
import Utils from 'shared/utils/Utils';
import { ParamTree, ParameterGroupDialog } from './Components';

const HEIGHT_CONTENT = 144;

const ParamGroupTree: React.FC = () => {
  const parameterGroupRef = useRef<any>();
  const confirm = useConfirm();
  const [isOpenSearch, setOpenSearch] = useState(false);
  const [hoveredRootGroupId, setHoveredRootGroupId] = useState(-1);

  const renderMenuItems = (): ReactElement[] => {
    return parameterGroupStore.rootGroupForOptions.map((rootGroup) => (
      <MenuItem
        key={rootGroup.id}
        value={rootGroup.id}
        onMouseEnter={() => setHoveredRootGroupId(rootGroup.id as number)}
        onMouseLeave={() => setHoveredRootGroupId(-1)}>
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between" width="250px" minHeight="28px" gap={1}>
          <Box width="150px" whiteSpace="pre-line">
            {rootGroup.name}
          </Box>

          {hoveredRootGroupId === rootGroup.id && (
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={(theme) => ({
                svg: {
                  width: '16px',
                  height: '16px',
                  ':hover': {
                    fill: theme.palette.primary.main,
                    fillOpacity: 1,
                  },
                },
              })}>
              {/*<IconButtonNew
                tooltip="Переместить вверх"
                disabled={parameterGroupStore.isSelectedRootGroupFirst(rootGroup.id as number)}
                onClick={(e) => {
                  e.stopPropagation();
                  onChangeRootParamGroup(rootGroup.id as number);
                  changeOrderRoot(-1);
                }}>
                <UpIcon />
              </IconButtonNew>

              <IconButtonNew
                tooltip="Переместить вниз"
                disabled={parameterGroupStore.isSelectedRootGroupLast(rootGroup.id as number)}
                onClick={(e) => {
                  e.stopPropagation();
                  onChangeRootParamGroup(rootGroup.id as number);
                  changeOrderRoot(1);
                }}>
                <DownIcon />
              </IconButtonNew>*/}

              <IconButtonNew
                tooltip={Translator.translate('actions.edit')}
                onClick={(e) => {
                  e.stopPropagation();
                  parameterGroupStore.setEditRootParameterGroup(true);
                  onChangeRootParamGroup(rootGroup.id as number);
                  onEditRootParameterGroup();
                }}>
                <EditPencilIcon />
              </IconButtonNew>

              <IconButtonNew
                tooltip={Translator.translate('actions.changeHistory')}
                onClick={(e) => {
                  e.stopPropagation();
                  onChangeRootParamGroup(rootGroup.id as number);
                  onHistoryView();
                }}>
                <HistoryIcon />
              </IconButtonNew>

              <Divider sx={{ m: 0.5, borderRightWidth: 2, height: 16 }} orientation="vertical" />

              <IconButtonNew
                tooltip={Translator.translate('actions.delete')}
                onClick={(e) => {
                  e.stopPropagation();
                  parameterGroupStore.setEditRootParameterGroup(true);
                  onChangeRootParamGroup(rootGroup.id as number);
                  onDeleteRootParameterGroup();
                }}>
                <TrashIcon />
              </IconButtonNew>
            </Stack>
          )}
        </Stack>
      </MenuItem>
    ));
  };

  const renderAddActions = (): JSX.Element => {
    return (
      <Button
        sx={(theme) => ({
          svg: {
            fill: theme.palette.primary.main,
          },
        })}
        variant="text"
        startIcon={<PlusIconForButton />}
        onClick={onAddRootParameterGroup}>
        {Translator.translate('params.parameterGroup.addParameterGroupButton')}
      </Button>
    );
  };

  const onAddRootParameterGroup = () => {
    parameterGroupStore.setEditRootParameterGroup(true);
    parameterGroupStore.setIsDialogOpen(true);
  };

  const onHistoryView = () => {
    auditStore.setDrawerOpen(true);
    auditStore.setAuditFilters(AuditTables.ParameterGroups, Number(parameterGroupStore.currentParameterGroup));
  };

  const onEditRootParameterGroup = async () => {
    parameterGroupStore.setIsDialogOpen(true);
    await parameterGroupStore.getParameterGroupById(parameterStore.filterParameters.parameterGroupId!);
  };

  const onDeleteRootParameterGroup = () => {
    parameterGroupStore.setEditRootParameterGroup(true);
    confirm
      .show(Translator.translate('params.parameterGroup.confirmMessage.deleteParameterGroup'), Translator.translate('actions.delete'))
      .then(() => parameterGroupStore.removeParameterGroups())
      .finally(() => parameterGroupStore.setEditRootParameterGroup(false));
  };

  const onDelete = async (value?: ParameterGroupModel) => {
    const title =
      parameterGroupStore.checkedKeys.length > 1
        ? Translator.translate('params.parameterGroup.confirmMessage.deleteAllParameterGroups')
        : Translator.translate('params.parameterGroup.confirmMessage.deleteParameterGroup');

    confirm.show(title, Translator.translate('actions.delete')).then(() => {
      if (value) parameterGroupStore.selectedParamGroup.setId(value.id!);

      parameterGroupStore.removeParameterGroups();
    });
  };

  const onEdit = async (value?: ParameterGroupModel) => {
    parameterGroupStore.setIsDialogOpen(true);
    await parameterGroupStore.getParameterGroupById(value ? value?.id! : parameterStore.filterParameters.parameterGroupId!);
  };

  const updateSearchParameterGroup = () => {
    parameterStore.filterParameters.setParameterGroupId(parameterGroupStore.selectedParamGroup.id);
  };

  const changeOrderRoot = async (order: 1 | -1) => {
    parameterGroupStore.setEditRootParameterGroup(true);
    await parameterGroupStore.getParameterGroupById();
    parameterGroupStore.changeOrder(order);
    await parameterGroupStore.updateParameterGroup();
    parameterGroupStore.selectedParamGroup.clearModel();
    parameterGroupStore.setEditRootParameterGroup(false);
  };

  const changeOrder = async (order: 1 | -1) => {
    await parameterGroupStore.getParameterGroupById(parameterStore.filterParameters.parameterGroupId!);
    parameterGroupStore.selectedParamGroup.setOrderNum(parameterGroupStore.selectedParamGroup.orderNum! + order);
    await parameterGroupStore.updateParameterGroup();
    await parameterGroupStore.getParameterGroups();

    const findKey = parameterGroupStore.listTransformParamGroupDto.find((item) => item.id === parameterGroupStore.selectedParamGroup.id);
    parameterGroupStore.selectedParamGroup = new ParameterGroupModel(findKey);
  };

  const onChangeRootParamGroup = async (value: number) => {
    parameterGroupStore.selectedParamGroup.clearModel();
    parameterGroupStore.resetParameterGroup();

    parameterGroupStore.resetCheckedNodes();
    parameterGroupStore.setCurrentParameterGroup(value);

    parameterGroupStore.initFilterStateParamGroup();
  };

  const onCopyParamGroup = () => {
    parameterGroupStore.setCopyParamGroupId(parameterStore.filterParameters.parameterGroupId);
  };

  const onPasteParamGroup = () => {
    parameterGroupStore.copyParameterGroup();
  };

  const onScroll = (key: React.Key) => {
    parameterGroupRef.current.scrollTo({ key: key });
  };

  return (
    <>
      <Stack mb={1} height="36px" flexDirection="row" alignItems="center">
        {!isOpenSearch && (
          <ParameterGroupTreeSelect
            onChangeParamGroup={updateSearchParameterGroup}
            onChangeRootParamGroup={onChangeRootParamGroup}
            onScroll={onScroll}
            menuItems={renderMenuItems()}
            addActions={renderAddActions()}
          />
        )}

        {!isOpenSearch && (
          <IconButtonNew sx={{ ml: 2, mr: 1 }} onClick={() => setOpenSearch(true)}>
            <IconSearch />
          </IconButtonNew>
        )}

        {isOpenSearch && (
          <Box height="38px" width="100%">
            <Fade timeout={100} in={isOpenSearch}>
              <div>
                {isOpenSearch && (
                  <ParameterGroupTreeSearch
                    isExpanded
                    onScroll={onScroll}
                    onChangeParamGroup={updateSearchParameterGroup}
                    onCloseSearch={() => setOpenSearch(false)}
                  />
                )}
              </div>
            </Fade>
          </Box>
        )}
      </Stack>

      <Stack
        sx={{
          svg: {
            width: 16,
            height: 16,
          },
        }}
        my={4}
        flexDirection="row"
        gap={2}>
        <Button
          sx={(theme) => ({
            svg: {
              fill:
                parameterGroupStore.checkedKeys.length > 0 ||
                !parameterStore.filterParameters.parameterGroupId ||
                !parameterGroupStore.currentParameterGroup
                  ? theme.palette.action.disabled
                  : theme.palette.primary.main,
            },
          })}
          variant="text"
          size="small"
          disabled={
            parameterGroupStore.checkedKeys.length > 0 ||
            !parameterStore.filterParameters.parameterGroupId ||
            !parameterGroupStore.currentParameterGroup
          }
          onClick={() => {
            parameterStore.filterParameters.parameterGroupId &&
              parameterGroupStore.selectedParamGroup.setId(parameterStore.filterParameters.parameterGroupId);
            parameterGroupStore.setIsDialogOpen(true);
          }}
          startIcon={<PlusIconForButton />}>
          {Translator.translate('actions.add')}
        </Button>

        <IconButtonNew
          sx={(theme) => ({
            svg: {
              fill: theme.palette.primary.main,
            },
          })}
          tooltip={Translator.translate('actions.edit')}
          onClick={() => onEdit()}
          disabled={parameterGroupStore.checkedKeys.length > 0 || !parameterStore.filterParameters.parameterGroupId}>
          <EditPencilIcon />
        </IconButtonNew>

        <IconButtonNew
          onClick={onCopyParamGroup}
          tooltip={Translator.translate('actions.copy')}
          disabled={!parameterStore.filterParameters.parameterGroupId || parameterGroupStore.checkedKeys.length > 0}>
          <CopyIcon />
        </IconButtonNew>

        <IconButtonNew
          onClick={onPasteParamGroup}
          tooltip={Translator.translate('actions.past')}
          disabled={!parameterGroupStore.copyParamGroupId || !parameterStore.filterParameters.parameterGroupId}>
          <InsertIcon />
        </IconButtonNew>

        <IconButtonNew
          sx={(theme) => ({
            svg: {
              fill: theme.palette.primary.main,
              fillOpacity: 1,
            },
          })}
          onClick={() => onDelete()}
          tooltip={Translator.translate('actions.delete')}
          disabled={parameterGroupStore.checkedKeys.length === 0}>
          <TrashIcon />
        </IconButtonNew>

        <IconButtonNew
          sx={(theme) => ({
            svg: {
              fill: theme.palette.primary.main,
              fillOpacity: 1,
            },
          })}
          onClick={() => changeOrder(-1)}
          tooltip={Translator.translate('actions.up')}
          disabled={
            parameterGroupStore.updateLoading
              ? true
              : !parameterStore.filterParameters.parameterGroupId || parameterGroupStore.isSelectedTreeGroupFirst
          }>
          {parameterGroupStore.updateLoading ? (
            <Box sx={{ display: 'flex' }}>
              <CircularProgress size="1.5rem" />
            </Box>
          ) : (
            <UpIcon />
          )}
        </IconButtonNew>

        <IconButtonNew
          sx={(theme) => ({
            svg: {
              fill: theme.palette.primary.main,
              fillOpacity: 1,
            },
          })}
          onClick={() => changeOrder(1)}
          tooltip={Translator.translate('actions.down')}
          disabled={
            parameterGroupStore.updateLoading
              ? true
              : !parameterStore.filterParameters.parameterGroupId || parameterGroupStore.isSelectedTreeGroupLast
          }>
          {parameterGroupStore.updateLoading ? (
            <Box sx={{ display: 'flex' }}>
              <CircularProgress size="1.5rem" />
            </Box>
          ) : (
            <DownIcon />
          )}
        </IconButtonNew>
      </Stack>

      {parameterGroupStore.waitingParameterGroup ? (
        <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        parameterGroupStore.parameterGroupTree.length !== 0 && (
          <Box height={`calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight()}px)`}>
            <ParamTree
              ref={parameterGroupRef}
              contentHeight={HEIGHT_CONTENT + Utils.projectHeight()}
              onAdd={(value) => {
                value.id && parameterGroupStore.selectedParamGroup.setId(value.id);
                parameterGroupStore.setIsDialogOpen(true);
              }}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          </Box>
        )
      )}

      {parameterGroupStore.isDialogOpen && <ParameterGroupDialog />}
    </>
  );
};

export default observer(ParamGroupTree);
