import { BASE_PATH } from 'shared/constants/constants';
import {
  IGetPartialApprovalDto,
  IPostApprovalUserDto,
  IPostPartialApprovalDto,
  IPutApprovalUserDto,
  IPutInfBlockPartialApprovalDto,
  IPutIterationPartialApprovalDto,
  IPutPartialApprovalDto,
} from 'shared/interfaces';
import HTTPService from './HttpService';

class PartialApprovalService extends HTTPService {
  constructor() {
    super(BASE_PATH.PARTIAL_APPROVALS);
  }

  public getPartialApproval(id: number): Promise<IGetPartialApprovalDto> {
    return this.GET(`${id}`);
  }

  public postPartialApproval(body: IPostPartialApprovalDto): Promise<number> {
    return this.POST('', body);
  }

  public putPartialApproval(id: number, body: IPutPartialApprovalDto): Promise<number> {
    return this.PUT(`${id}`, body);
  }

  public deletePartialApproval(id: number): Promise<void> {
    return this.DELETE(`${id}`);
  }

  public postUserPartialApproval(id: number, body: IPostApprovalUserDto): Promise<number> {
    return this.POST(`${id}/users`, body);
  }

  public putUserPartialApproval(id: number, body: IPutApprovalUserDto): Promise<void> {
    return this.PUT(`${id}/users`, body);
  }

  public putCancelPartialApproval(id: number): Promise<void> {
    return this.PUT(`${id}/cancel`);
  }

  public deleteUserPartialApproval(id: number, userId: number) {
    return this.DELETE(`${id}/users/${userId}`);
  }

  public putInfBlockPartialApproval(id: number, body: IPutInfBlockPartialApprovalDto): Promise<void> {
    return this.PUT(`${id}/information-block`, body);
  }

  public putIterationPartialApproval(body: IPutIterationPartialApprovalDto): Promise<void> {
    return this.PUT('iteration', body);
  }
}

export default new PartialApprovalService();
