import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, itemStore, parameterValueStore, toastStore } from 'stores';
import { Input, DialogWrapper, Switch } from 'components';
import { Box } from '@mui/material';
import { ItemModel } from 'shared/models';

import s from './EditItemDialog.module.scss';

const EditItemDialog = () => {
  const [name, setName] = useState(itemStore.selectItem.name);
  const [master, setMaster] = useState(itemStore.selectItem.isMaster);

  const onSubmit = async () => {
    if (name !== '') {
      itemStore.selectItem.setName(name);
      itemStore.selectItem.setMaster(master);
      await itemStore.updateItem();
      onClose();
    } else {
      toastStore.showWarning(Translator.translate('objectValues.item.itemDialog.dialogFormFields.classNameLabel'));
    }
  };

  const onClose = () => {
    if (parameterValueStore.filterStateParamValues.itemId !== itemStore.selectItem.id) {
      const findKey = itemStore.listTransformItemDto.find((item) => item.id === parameterValueStore.filterStateParamValues.itemId);
      itemStore.selectItem = new ItemModel(findKey);
    }

    itemStore.setIsDialogEditOpen(false);
  };

  const onChangeName = (value: string) => {
    setName(value);
  };

  const onChangeMaster = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMaster(e.target.checked);

    let name = '';
    if (!e.target.checked) {
      if (itemStore.selectItem.name.includes('-')) name = itemStore.selectItem.name.split('-')[0];
      else name = itemStore.selectItem.name;
    } else {
      if (!itemStore.selectItem.name.includes('-'))
        name = `${itemStore.selectItem.name}-${Translator.translate('objectValues.item.itemDialog.master')}`;
      else name = itemStore.selectItem.name;
    }

    setName(name);
  };

  return (
    //TODO: Add formik
    <DialogWrapper
      title={Translator.translate('objectValues.item.itemDialog.editTitle')}
      subTitle={`${Translator.translate('objectValues.item.itemDialog.editSubtitle')}: ${itemStore.selectItem.name}`}
      maxWidth="md"
      open={itemStore.isDialogEditOpen}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={onSubmit}>
      <>
        <Switch checked={master} onChange={onChangeMaster} label={Translator.translate('objectValues.item.itemDialog.dialogFormFields.master')} />
        <Input label={Translator.translate('objectValues.item.itemDialog.dialogFormFields.name')} value={name} onChange={onChangeName} />
      </>
    </DialogWrapper>
  );
};

export default observer(EditItemDialog);
