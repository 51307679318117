import { makeAutoObservable } from 'mobx';
import { IXMLParameter } from 'shared/interfaces';

class XMLParamsModel {
  constructor(dto?: IXMLParameter) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.groupId = dto.groupId ?? null;
    this.fullName = dto.fullName;
    this.checked = dto.checked ?? false;
    this.hierarchy = dto.hierarchy ?? [];
    this.parameterIds = dto.parameterIds ?? null;
    this.isGroup = dto.isGroup ?? false;
  }

  public id: number | null = null;

  public name = '';

  public groupId: number | null = null;

  public hierarchy: string[] = [];

  public parameterIds: number[] | null = null;

  public fullName = '';

  public checked = false;

  public isGroup = false;

  public setChecked(checked: boolean) {
    this.checked = checked;
  }
}

export default XMLParamsModel;
