import { BASE_PATH } from 'shared/constants/constants';
import { ICreateItemCommandDto, IItemByIdDto, IItemCopyDto, IItemDto, IItemHasValuesDto, IItemParamsDto, IPutItemDto } from 'shared/interfaces';

import HTTPService from './HttpService';

class ItemService extends HTTPService {
  constructor() {
    super(BASE_PATH.ITEM);
  }

  public getItems(params: IItemParamsDto): Promise<IItemDto[]> {
    return this.GET<IItemDto[]>('', { params });
  }

  public getItemsHasValues(groupId: number, definitionId: number): Promise<IItemHasValuesDto> {
    return this.GET<IItemHasValuesDto>('has-values', { params: { groupId, definitionId } });
  }

  public getItemById(id: number): Promise<IItemByIdDto> {
    return this.GET<IItemByIdDto>(`${id}`);
  }

  public postItem(body: ICreateItemCommandDto): Promise<number> {
    return this.POST('', body);
  }

  public putItem(id: number, body: IPutItemDto) {
    return this.PUT(`${id}`, body);
  }

  public postCopyItem(id: number, body: IItemCopyDto): Promise<number> {
    return this.POST(`${id}/copy`, body);
  }

  public deleteItem(id: number) {
    return this.DELETE(`${id}`);
  }
}

export default new ItemService();
