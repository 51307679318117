import React, { useEffect, useState, ChangeEvent } from 'react';
import {
  GridCellCheckboxRenderer,
  GridColumns,
  GridHeaderCheckbox,
  GridRenderCellParams,
  GridRowParams,
  GridCellParams,
  GridColumnHeaderParams,
} from '@mui/x-data-grid-pro';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Translator, parameterGroupStore, sectionParameterStore, sectionsStore } from 'stores';
import { CustomDataGrid, BindingParametersSectionTable, IconButtonNew, InputSearch, ParameterGroupFilter, Switch } from 'components';
import { useConfirm } from 'components/App/Confirm';
import DownIcon from 'components/Icons/DownIcon';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import IconSearch from 'components/Icons/IconSearch';
import PlusIcon from 'components/Icons/PlusIcon';
import TrashIcon from 'components/Icons/TrashIcon';
import UpIcon from 'components/Icons/UpIcon';
import { Box, Collapse, Fade, Stack, Typography } from '@mui/material';
import { NestedLevel } from 'shared/enums';
import { IGetSectionDto, INestedSection, IClassesPath, ITableColumn } from 'shared/interfaces';
import { ParameterGroupModel, SectionModel, SectionParamsModel } from 'shared/models';
import Utils from 'shared/utils/Utils';
import { SectionDialog } from './Components';

const HEIGHT_CONTENT = 148;
const HEIGHT_PARAMETER_CONTENT = 248;

const Sections: React.FC = () => {
  const confirm = useConfirm();
  const [isOpenSearch, setOpenSearch] = useState(false);

  const columns: ITableColumn[] = [
    {
      key: 'collapse',
      label: '',
      align: 'left',
      calcWidth: (row: INestedSection) => (row.level === NestedLevel.First ? '30px' : row.level === NestedLevel.Second ? '40px' : '50px'),
    },
    {
      key: 'name',
      label: Translator.translate('catalogs.specTemplates.sections.columns.name'),
      align: 'left',
      calcWidth: (row: INestedSection) => (row.level === NestedLevel.First ? '400px' : row.level === NestedLevel.Second ? '390px' : '380px'),
    },
    {
      key: 'shortName',
      label: Translator.translate('catalogs.specTemplates.sections.columns.shortName'),
      align: 'left',
      width: '200px',
      minWidth: '150px',
    },
    {
      key: 'class',
      label: Translator.translate('catalogs.specTemplates.sections.columns.class'),
      align: 'left',
      width: 'auto',
      minWidth: '400px',
      cell: (row: INestedSection) => {
        if (row.classes.length > 0) {
          return (
            <Box sx={{ wordBreak: 'break-word' }}>
              {row.classes.map((f: IClassesPath, idx) => {
                const key = f.path.map((value) => value.id).join('-');
                const parentName = f.path.length >= 2 ? f.path[f.path.length - 2].name : '';
                const secondParentName = f.path.length >= 3 ? f.path[f.path.length - 3].name : '';

                if (parentName) {
                  return (
                    <Box component="span" key={key}>
                      {f.path[f.path.length - 1].name}
                      <Box component="span" color="text.secondary">
                        {secondParentName ? `(${secondParentName}\u{2794}${parentName})` : `(${parentName})`}
                      </Box>
                      {row.classes.length - 1 !== idx && ', '}
                    </Box>
                  );
                } else
                  return (
                    <Box component="span" key={key}>
                      {f.path[f.path.length - 1].name}
                    </Box>
                  );
              })}
            </Box>
          );
          return null;
        } else {
          return null;
        }
      },
    },
    {
      key: 'actions',
      label: '',
      align: 'right',
      width: '200px',
      minWidth: '200px',
      cell: (row: INestedSection) => {
        const removeSection = () => {
          confirm
            .show(
              `${Translator.translate('catalogs.specTemplates.sections.confirmMessage.deleteSection')} '${row.name}'?`,
              Translator.translate('actions.delete')
            )
            .then(() => {
              sectionsStore.removeSection(row.id!);
            });
        };

        const changeOrderHightLevel = async (order: 1 | -1) => {
          runInAction(() => {
            sectionsStore.selectedSection = new SectionModel(row);
          });

          sectionsStore.selectedSection.setOrderNum(sectionsStore.selectedSection.orderNum! + order);
          await sectionsStore.updateSection();
        };

        const isHightLevelFirst = (row: INestedSection | IGetSectionDto): boolean => {
          const data = sectionsStore.sections.filter((f) => f.parentId === row.parentId);

          if (data.length !== 0 && row.orderNum === data[0].orderNum) return true;
          else return false;
        };

        const isHightLevelLast = (row: INestedSection | IGetSectionDto): boolean => {
          const data = sectionsStore.sections.filter((f) => f.parentId === row.parentId);

          if (data.length !== 0 && row.orderNum === data[data.length - 1].orderNum) return true;
          else return false;
        };

        return (
          <Stack
            mr={6}
            flexDirection="row"
            gap={2}
            justifyContent="flex-end"
            sx={(theme) => ({
              'button svg': {
                ':hover': {
                  fill: theme.palette.primary.main,
                  fillOpacity: 1,
                },
              },
            })}>
            {row.level !== NestedLevel.Third && (
              <IconButtonNew
                tooltip={Translator.translate('catalogs.specTemplates.sections.addSubsectionTitle')}
                onClick={(e) => {
                  e.stopPropagation();
                  sectionsStore.setCreatingSubsectionMode(row.id!);
                }}>
                <PlusIcon />
              </IconButtonNew>
            )}

            <IconButtonNew
              onClick={(e) => {
                e.stopPropagation();
                sectionsStore.setEditingMode(row.id!);
              }}
              tooltip={Translator.translate('actions.edit')}>
              <EditPencilIcon />
            </IconButtonNew>

            <IconButtonNew
              onClick={(e) => {
                e.stopPropagation();
                removeSection();
              }}
              tooltip={Translator.translate('actions.delete')}>
              <TrashIcon />
            </IconButtonNew>

            <IconButtonNew
              onClick={(e) => {
                e.stopPropagation();
                changeOrderHightLevel(-1);
              }}
              disabled={isHightLevelFirst(row)}
              tooltip={Translator.translate('actions.up')}>
              <UpIcon />
            </IconButtonNew>

            <IconButtonNew
              onClick={(e) => {
                e.stopPropagation();
                changeOrderHightLevel(1);
              }}
              disabled={isHightLevelLast(row)}
              tooltip={Translator.translate('actions.down')}>
              <DownIcon />
            </IconButtonNew>
          </Stack>
        );
      },
    },
  ];

  const columnsParameter: GridColumns = [
    {
      field: '__check__',
      type: 'checkboxSelection',
      width: 10,
      minWidth: 35,
      resizable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderHeader: (params) =>
        sectionParameterStore.sectionParamsFilteredDataGrid.length > 0 && (
          <Stack justifyContent="center">
            <GridHeaderCheckbox {...params} />
            <Typography variant="button" style={{ position: 'absolute', left: '40px', fontSize: '13px' }}>
              {Translator.translate('catalogs.specTemplates.sections.selectAll')}
            </Typography>
          </Stack>
        ),
      renderCell: (params) => <GridCellCheckboxRenderer {...params} />,
    },
    {
      field: 'name',
      headerName: '',
      flex: 1,
      hideSortIcons: true,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
    {
      field: 'isVisible',
      headerName: '',
      width: 10,
      minWidth: 60,
      hideSortIcons: true,
      renderCell: (params: GridRenderCellParams) => {
        const { row }: { row: SectionParamsModel } = params;
        if (row.isGroup) {
          return (
            <Switch
              tooltip={<Box maxWidth={94}>{Translator.translate('catalogs.specTemplates.sections.generateTitle')}</Box>}
              disabled={!row.propertyId}
              checked={row.isTitle}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                row.setTitle(event.target.checked);
                sectionParameterStore.updateSectionParams(sectionParameterStore.sectionParams);
              }}
            />
          );
        } else {
          const onSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
            row.setVisible(event.target.checked);
            sectionParameterStore.updateSectionParams(sectionParameterStore.sectionParams);
          };

          return (
            <Switch
              disabled={!row.checked}
              checked={row.isVisible}
              tooltip={<Box maxWidth={94}>{Translator.translate('catalogs.specTemplates.sections.displayTitle')}</Box>}
              onChange={onSwitch}
            />
          );
        }
      },
    },
  ];

  useEffect(() => {
    if (!sectionParameterStore.paramGroupFilter.id) {
      parameterGroupStore.getRootParameterGroupList().then(async () => {
        if (!parameterGroupStore.currentParameterGroup)
          parameterGroupStore.setCurrentParameterGroup(Number(parameterGroupStore.rootParameterGroup[0].id));

        parameterGroupStore.getParameterGroups().then(async () => {
          if (!sectionParameterStore.paramGroupFilter.id) {
            parameterGroupStore.defaultExpandSelectParamGroup();
          }
        });
      });
    }

    return () => {
      parameterGroupStore.resetParameterGroup();
      sectionParameterStore.paramGroupFilter.clearModel();
      sectionParameterStore.sectionParams = [];
      sectionParameterStore.initSectionParameters = [];
      sectionsStore.setSelectedSectionRow(null);
      sectionsStore.setOpenParameters(false);
    };
  }, []);

  const onSubmit = () => {
    sectionParameterStore.saveParametersSection();
  };

  const onCancel = () => {
    sectionParameterStore.getParametersSection();
  };

  const onSelectRowSection = (selectedRow: INestedSection) => {
    if (!sectionParameterStore.isHasChanged && sectionsStore.selectedSectionRow !== selectedRow.id) {
      sectionsStore.setSelectedSectionRow(selectedRow.id);
      sectionParameterStore.getParametersSection();
    }

    if (sectionParameterStore.isHasChanged) {
      confirm
        .show(Translator.translate('catalogs.specTemplates.sections.confirmMessage.saveChanges'), Translator.translate('actions.save'), true)
        .then(() => {
          onSubmit();
        })
        .catch(() => {
          onCancel();
        });
    }
  };

  const onExceptionAction = () => {
    if (sectionParameterStore.isHasChanged) {
      confirm
        .show(Translator.translate('catalogs.specTemplates.sections.confirmMessage.saveChanges'), Translator.translate('actions.save'), true)
        .then(() => {
          onSubmit();
          sectionsStore.setOpenParameters(false);
        })
        .catch(() => {
          sectionParameterStore.getParametersSection();
          sectionsStore.setOpenParameters(false);
        });
    } else {
      sectionsStore.setOpenParameters(false);
    }
  };

  const onClickSearch = () => {
    console.log('1');
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    sectionParameterStore.setFilterName(event.target.value);
  };

  const onChangeParamGroup = async (value: ParameterGroupModel) => {
    if (sectionParameterStore.isHasChanged) {
      confirm
        .show(Translator.translate('catalogs.specTemplates.sections.confirmMessage.saveChanges'), Translator.translate('actions.save'), true)
        .then(() => {
          sectionParameterStore.saveParametersSection();
          changeParamGroup(value);
        })
        .catch(() => {
          changeParamGroup(value);
        });
    } else {
      changeParamGroup(value);
    }
  };

  const changeParamGroup = async (value: ParameterGroupModel) => {
    sectionParameterStore.paramGroupFilter = new ParameterGroupModel(value);
    await sectionParameterStore.getParametersSection();
  };

  const onHeaderClick = (params: GridColumnHeaderParams, event: ChangeEvent<HTMLInputElement>) => {
    if (params.field !== '__check__') return;
    sectionParameterStore.sectionParams.forEach((_) => {
      _.setChecked(event.target.checked);
      _.setVisible(event.target.checked);
    });
  };

  const onCellClick = (params: GridCellParams) => {
    const { row }: { row: SectionParamsModel } = params;

    if (params.field === columnsParameter[2].field || row.parameterIds === null) return;

    if (row.isGroup) {
      const selectedIDs = new Set(row.parameterIds);
      const selectedRows = sectionParameterStore.sectionParams.filter((row) => selectedIDs.has(row.id!));
      selectedRows.forEach((_) => {
        _.setChecked(!row.checked);
        row.checked ? _.setVisible(false) : _.setVisible(true);
      });
    }

    const findParam = sectionParameterStore.sectionParams.find((parameter) => row.id === parameter.id);
    if (findParam) {
      findParam.setChecked(!findParam.checked);
      findParam.setVisible(!findParam.isVisible);
    }

    const isAllParameterChecked = sectionParameterStore.sectionParams
      .filter((f) => f.groupId === row.groupId)
      .every((parameter) => parameter.checked);

    sectionParameterStore.sectionParams.find((f) => f.id === row.groupId)?.setChecked(isAllParameterChecked);
  };

  return (
    <>
      <BindingParametersSectionTable
        sectionTableData={sectionsStore.nestedSections}
        columns={columns}
        selectedSectionRow={sectionsStore.selectedSectionRow}
        tableHeight={`calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight()}px)`}
        loading={sectionsStore.isLoading}
        isOpenExceptionParameter={sectionsStore.isOpenParameters}
        disabledActions={sectionParameterStore.isLoading}
        collapseBtnTitle={Translator.translate('catalogs.specTemplates.sections.collapseBtnTitle')}
        isShowActions={
          sectionParameterStore.sectionParamsFilteredDataGrid.length !== 0 ||
          (sectionParameterStore.sectionParamsFilteredDataGrid.length === 0 && sectionParameterStore.filterName !== '')
        }
        onChangeExceptionAction={onExceptionAction}
        onSectionSelectRow={onSelectRowSection}
        onSubmit={onSubmit}
        onCancel={onCancel}>
        <Collapse
          sx={{
            height: '100%',
            '.MuiCollapse-wrapperInner': {
              width: '100%',
            },
          }}
          collapsedSize="200px"
          orientation="horizontal"
          in={sectionsStore.isOpenParameters}>
          {sectionsStore.selectedSectionRow ? (
            <Stack gap={3}>
              {(sectionParameterStore.sectionParamsFilteredDataGrid.length !== 0 ||
                (sectionParameterStore.sectionParamsFilteredDataGrid.length === 0 && sectionParameterStore.filterName !== '')) && (
                <Stack flexDirection="row" alignItems="center">
                  {!isOpenSearch && (
                    <Box width="100%">
                      <ParameterGroupFilter value={sectionParameterStore.paramGroupFilter.id} onChangeParamGroup={onChangeParamGroup} />
                    </Box>
                  )}

                  {!isOpenSearch && (
                    <IconButtonNew sx={{ mx: 2 }} onClick={() => setOpenSearch(true)}>
                      <IconSearch />
                    </IconButtonNew>
                  )}

                  {isOpenSearch && (
                    <Box height="38px" sx={{ width: '100%' }}>
                      <Fade timeout={100} in={isOpenSearch}>
                        <div>
                          {isOpenSearch && (
                            <InputSearch
                              fullWidth
                              isExpanded
                              value={sectionParameterStore.filterName}
                              onSearch={onClickSearch}
                              onChange={onSearch}
                              onClose={() => {
                                sectionParameterStore.setFilterName('');
                                setOpenSearch(false);
                              }}
                            />
                          )}
                        </div>
                      </Fade>
                    </Box>
                  )}
                </Stack>
              )}

              <Box sx={{ height: `calc(100vh - ${HEIGHT_PARAMETER_CONTENT + Utils.projectHeight()}px)`, width: '100%' }}>
                <CustomDataGrid
                  rows={sectionParameterStore.sectionParamsFilteredDataGrid}
                  isRowSelectable={(params: GridRowParams) => params.row.parameterIds}
                  columns={columnsParameter}
                  disableColumnMenu
                  onCellClick={onCellClick}
                  onColumnHeaderClick={(params, event: any) => onHeaderClick(params, event as ChangeEvent<HTMLInputElement>)}
                  selectionModel={sectionParameterStore.sectionParamsFilteredDataGrid.filter((_) => _.checked).map((_) => _.id!)}
                  loading={sectionParameterStore.isLoading}
                  hasFiltered={sectionParameterStore.filterName !== ''}
                />
              </Box>
            </Stack>
          ) : (
            <Stack alignItems="center" mt={22} height="100%">
              <img style={{ alignSelf: 'center' }} src={`${process.env.PUBLIC_URL}/img/not_selected_data.svg`} alt="" />
              <Typography variant="subtitle1" color="text.disabled">
                {Translator.translate('catalogs.specTemplates.sections.selectSection')}
              </Typography>
            </Stack>
          )}
        </Collapse>
      </BindingParametersSectionTable>

      {sectionsStore.isDialogOpen && <SectionDialog />}
    </>
  );
};

export default observer(Sections);
