import React from 'react';

const UploadIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.17626 1.25328C6.94298 1.22635 7.70706 1.36536 8.41153 1.661C9.11609 1.95668 9.7443 2.40206 10.2474 2.96618C10.6193 3.38329 10.9156 3.85724 11.1241 4.36813H11.2748H11.2752C12.0388 4.36861 12.7843 4.61123 13.3941 5.06337C14.0044 5.51594 14.4459 6.1546 14.6404 6.88295C14.835 7.61188 14.769 8.38175 14.4547 9.06873C14.2911 9.42638 14.0655 9.74985 13.7915 10.0272C13.5004 10.3218 13.0255 10.3246 12.7309 10.0335C12.4363 9.74235 12.4334 9.26748 12.7246 8.97285C12.8779 8.81767 13.0017 8.6391 13.0906 8.44472C13.2607 8.0729 13.2954 7.66038 13.1911 7.26992C13.0867 6.87888 12.8467 6.52491 12.5006 6.26826C12.1541 6.01128 11.7228 5.86851 11.2748 5.86813H11.2746L11.2743 5.86813L10.5876 5.86813C10.249 5.86813 9.95235 5.64125 9.86372 5.31445C9.72965 4.82013 9.479 4.35828 9.12787 3.96454C8.77661 3.57064 8.33377 3.2551 7.83108 3.04414C7.3283 2.83314 6.78036 2.73299 6.22891 2.75236C5.67746 2.77173 5.13924 2.91002 4.65492 3.1553C4.17071 3.40053 3.75435 3.74557 3.43521 4.16205C3.11618 4.57836 2.90221 5.0556 2.80678 5.5574C2.71136 6.0591 2.73641 6.57483 2.88042 7.06665C3.02448 7.55861 3.2845 8.01603 3.64373 8.40352C3.92533 8.70728 3.90737 9.18181 3.60361 9.46342C3.29985 9.74502 2.82531 9.72706 2.54371 9.4233C2.02946 8.86859 1.65145 8.20731 1.44087 7.48818C1.23025 6.76891 1.19331 6.01267 1.33319 5.27715C1.47305 4.54173 1.78555 3.84871 2.24459 3.24967C2.7035 2.6508 3.29656 2.16185 3.9772 1.81713C4.65775 1.47246 5.40953 1.28021 6.17626 1.25328ZM8.75 9.8105L10.1365 11.197C10.4294 11.4899 10.9043 11.4899 11.1972 11.197C11.4901 10.9041 11.4901 10.4292 11.1972 10.1363L8.53049 7.46967C8.2376 7.17678 7.76273 7.17678 7.46983 7.46967L4.80317 10.1363C4.51027 10.4292 4.51027 10.9041 4.80317 11.197C5.09606 11.4899 5.57093 11.4899 5.86383 11.197L7.25 9.81082V14C7.25 14.4142 7.58579 14.75 8 14.75C8.41421 14.75 8.75 14.4142 8.75 14V9.8105Z"
      />
    </svg>
  );
};

export default UploadIcon;
