import React, { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  DataGridPro,
  DataGridProProps,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  gridClasses,
  GridCellParams,
  useGridApiRef,
  GridColDef,
  MuiEvent,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-pro';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Translator, measurementUnitStore, parameterListValueStore, parameterStore, sectionSetStore, sectionsStore } from 'stores';
import { CustomCellGrouping, CustomGridParameterRow, IconButtonNew, Input, RadioGroup } from 'components';
import { useConfirm } from 'components/App/Confirm';
import DateValueIcon from 'components/Icons/DateValueIcon';
import DocumentExistIcon from 'components/Icons/DocumentExistIcon';
import DownIcon from 'components/Icons/DownIcon';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import FileValueIcon from 'components/Icons/FileValueIcon';
import LinkValueIcon from 'components/Icons/LinkValueIcon';
import ListIcon from 'components/Icons/ListIcon';
import LogicalValueIcon from 'components/Icons/LogicalValueIcon';
import NumberValueIcon from 'components/Icons/NumberValueIcon';
import PlusIcon from 'components/Icons/PlusIcon';
import RangeValueIcon from 'components/Icons/RangeValueIcon';
import TextValueIcon from 'components/Icons/TextValueIcon';
import TrashIcon from 'components/Icons/TrashIcon';
import UpIcon from 'components/Icons/UpIcon';
import { Box, Stack, Typography, CircularProgress, Chip, IconButton } from '@mui/material';
import { DataType, DataTypeNames, DocumentSettingNames, DocumentSettingType, ParameterMode } from 'shared/enums';
import { IGroupParameter, IParameter, ISelectOption } from 'shared/interfaces';
import { ParameterModel } from 'shared/models';
import Utils from 'shared/utils/Utils';
import { CustomMultipleSelect, CustomSelect } from './Components';
import s from './ParameterTable.module.scss';

export const HEIGHT_CONTENT = 148;

const ParameterTable: React.FC = () => {
  const confirm = useConfirm();
  const apiRef = useGridApiRef();
  const [isHover, setHover] = useState<number | null>(null);

  const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy;

  const RenderEditableCell: GridColDef['renderCell'] = (params: GridRenderEditCellParams): JSX.Element => {
    const { id, value, field } = params;

    const onChangeValue = async (value: string) => {
      if (parameterStore.selectedParameterTable[field as keyof ParameterModel] !== value) {
        setNewValues(field, value);
        parameterStore.updateParam().finally(() => apiRef.current.stopCellEditMode({ id, field }));
      } else {
        apiRef.current.stopCellEditMode({ id, field });
      }
    };

    return (
      <Stack sx={{ width: '100%', padding: '0 10px' }} flexDirection="row" gap={1} alignItems="center">
        <Input
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiOutlinedInput-input': {
              padding: 0,
            },
            '& .MuiOutlinedInput-root': {
              padding: 0,
            },
          }}
          multiline={field === 'name' || field === 'description'}
          isLoading={parameterStore.updateLoading}
          variant="outlined"
          autoComplete="off"
          canFocus={true}
          value={value}
          onChange={onChangeValue}
        />
      </Stack>
    );
  };

  const RenderSelectCell: GridColDef['renderCell'] = (params: any): JSX.Element => {
    const { id, value, field } = params;

    const onSelectChange = async (e: any) => {
      if (parameterStore.selectedParameterTable[field as keyof ParameterModel] !== e.target.value) {
        setNewValues(field, e.target.value === '' ? null : e.target.value);
        parameterStore.updateParam().finally(() => apiRef.current.stopCellEditMode({ id, field }));
      } else {
        apiRef.current.stopCellEditMode({ id, field });
      }
    };

    const getOptions = (): ISelectOption[] => {
      switch (field) {
        case 'dataType':
          return parameterStore.dataTypeForOptions;

        case 'measurementUnitId':
          return measurementUnitStore.measurementForOptions;

        default:
          return [];
      }
    };

    return <CustomSelect value={value} field={field} isLoading={parameterStore.updateLoading} options={getOptions()} onChange={onSelectChange} />;
  };

  const RenderMultipleSelectCell: GridColDef['renderCell'] = (params: GridRenderEditCellParams): JSX.Element => {
    const { field, row } = params;

    const getOptions = (): ISelectOption[] => {
      switch (row.dataType) {
        case DataType.List:
          return parameterStore.docSettingListTypeForOptions;

        default:
          return parameterStore.docSettingUntitledTypeForOptions;
      }
    };

    return (
      <CustomMultipleSelect
        value={row.documentSettings}
        options={getOptions()}
        onChange={(value: string[]) => onMultipleSelectChange(row.id, field, value)}
      />
    );
  };

  const checkboxColumn: GridColumns = [
    {
      field: '__check__',
      type: 'checkboxSelection',
      width: 10,
      minWidth: 40,
      resizable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
  ];

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: Translator.translate('parameterDocSettings.columns.name'),
      flex: 1,
      minWidth: 150,
      resizable: true,
      hideSortIcons: true,
      editable: true,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      colSpan: (param: GridCellParams) => {
        const { row }: { row: IParameter | IGroupParameter } = param;

        return !row.hasOwnProperty('parameterIds') ? 1 : 5;
      },
      renderEditCell: (params) => RenderEditableCell(params),
    },
    {
      field: 'dataType',
      headerName: Translator.translate('params.parameter.columns.dataType'),
      flex: 1,
      minWidth: 180,
      resizable: true,
      hideSortIcons: true,
      editable: true,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (param: GridRenderCellParams) => {
        const { row }: { row: IParameter } = param;

        const iconValue = (): React.ReactNode => {
          switch (row.dataType) {
            case DataType.Number:
              return <NumberValueIcon />;

            case DataType.Range:
              return <RangeValueIcon />;

            case DataType.Boolean:
              return <LogicalValueIcon />;

            case DataType.String:
              return <TextValueIcon />;

            case DataType.Link:
              return <LinkValueIcon />;

            case DataType.List:
              return <ListIcon />;

            case DataType.File:
              return <FileValueIcon />;

            case DataType.DateTime:
              return <DateValueIcon />;

            default:
              return <TextValueIcon />;
          }
        };

        if (row.dataType === DataType.List) {
          return (
            <Stack direction="row" alignItems="center" gap={1}>
              {iconValue()}

              <Typography variant="body2">{Translator.translate(DataTypeNames.get(row.dataType))}</Typography>

              <IconButtonNew
                tooltip={Translator.translate('params.parameter.listTooltip')}
                sx={(theme) => ({
                  svg: {
                    width: 16,
                    height: 16,
                    fill: theme.palette.primary.main,
                    fillOpacity: 1,
                  },
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  parameterStore.selectedParameterTable.setId(row.id);
                  parameterStore.selectedParameterTable.setFullName(row.fullName);
                  parameterStore.selectedParameterTable.setName(row.name);
                  row.measurementUnit && parameterStore.selectedParameterTable.setMeasurementUnitName(row.measurementUnit.name);
                  parameterListValueStore.setOpenDialog(true);
                }}>
                <EditPencilIcon />
              </IconButtonNew>
            </Stack>
          );
        } else {
          return (
            <>
              <Stack direction="row" alignItems="center" gap={1}>
                {iconValue()}

                <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                  {Translator.translate(DataTypeNames.get((row as IParameter).dataType))}
                </Typography>
              </Stack>
            </>
          );
        }
      },
      renderEditCell: (params) => RenderSelectCell(params),
    },
    {
      field: 'measurementUnitId',
      headerName: Translator.translate('params.parameter.columns.measurementUnit'),
      flex: 1,
      minWidth: 150,
      resizable: true,
      hideSortIcons: true,
      editable: true,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (param: GridRenderCellParams) => {
        const { row }: { row: IParameter } = param;

        return (
          <Stack flexDirection="row" alignItems="center" width="80%" justifyContent="space-between">
            <Typography variant="body2" component="span" sx={{ wordBreak: 'break-word' }}>
              {row.measurementUnit && row.measurementUnit.name !== '' ? row.measurementUnit.name : '-'}
            </Typography>

            {parameterStore.updateLoading &&
              parameterStore.selectedParameterTable.id === row.id &&
              parameterStore.selectedParameterTable.currentEditingColumn === param.field && (
                <Stack sx={{ pl: row.description ? 2 : 0 }} justifyContent="flex-end">
                  <CircularProgress size="1.5rem" />
                </Stack>
              )}
          </Stack>
        );
      },
      renderEditCell: (params) => RenderSelectCell(params),
    },
    {
      field: 'documentSettings',
      headerName: Translator.translate('params.parameter.columns.docSettings'),
      flex: 1,
      minWidth: 150,
      resizable: true,
      hideSortIcons: true,
      editable: true,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (param: GridRenderCellParams) => {
        const { row }: { row: IParameter } = param;

        return (
          <Stack flexWrap="wrap" flexDirection="row" gap={2} overflow="auto">
            {row.documentSettings.map((value) => {
              return (
                <Chip
                  sx={{ width: 'fit-content' }}
                  key={value}
                  clickable
                  label={Translator.translate(DocumentSettingNames.get(value))}
                  deleteIcon={
                    <IconButton
                      sx={{
                        padding: 0,
                        svg: {
                          fill: 'rgba(0, 0, 0, 0.26)',
                          width: '16px',
                          height: '16px',
                        },
                      }}
                      disableRipple
                      onMouseDown={(event: any) => event.stopPropagation()}>
                      <CancelIcon />
                    </IconButton>
                  }
                  onDelete={async (e) => {
                    parameterStore.selectedParameterTable.setId(row.id);
                    await parameterStore.getParameterById();

                    onMultipleSelectChange(
                      row.id,
                      param.field,
                      row.documentSettings.filter((f) => f !== value)
                    );
                  }}
                  size="small"
                />
              );
            })}
          </Stack>
        );
      },
      renderEditCell: (params) => RenderMultipleSelectCell(params),
    },
    {
      field: 'description',
      headerName: Translator.translate('params.parameter.columns.description'),
      flex: 1,
      minWidth: 50,
      resizable: true,
      hideSortIcons: true,
      editable: true,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (param: GridRenderCellParams) => {
        const { row }: { row: IParameter } = param;

        return (
          <Stack flexDirection="row" alignItems="center" width="100%" justifyContent="space-between">
            <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
              {Utils.deleteInnerWrap(row.description)}
            </Typography>

            {parameterStore.updateLoading &&
              parameterStore.selectedParameterTable.id === row.id &&
              parameterStore.selectedParameterTable.currentEditingColumn === param.field && (
                <Stack sx={{ pl: row.description ? 2 : 0 }} justifyContent="flex-end">
                  <CircularProgress size="1.5rem" />
                </Stack>
              )}
          </Stack>
        );
      },
      renderEditCell: (params) => RenderEditableCell(params),
    },
    {
      field: 'document',
      headerName: Translator.translate('params.parameter.columns.document'),
      width: 100,
      minWidth: 50,
      resizable: true,
      hideSortIcons: true,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (param: GridRenderCellParams) => {
        const { row }: { row: IParameter } = param;

        return (
          <Stack direction="row" alignItems="center">
            <IconButtonNew
              sx={(theme) => ({
                svg: {
                  width: 16,
                  height: 16,
                  fill: theme.palette.primary.main,
                  fillOpacity: 1,
                },
              })}
              onClick={() => parameterStore.setDocumentDialogOpen(true)}
              tooltip={
                row.documentsCount > 0
                  ? Translator.translate('params.parameter.columns.document')
                  : Translator.translate('params.parameter.addDocumentTooltip')
              }>
              {row.documentsCount > 0 ? <DocumentExistIcon /> : <PlusIcon />}
            </IconButtonNew>

            {row.documentsCount > 0 && <Typography variant="body2" color="primary.main">{`(${row.documentsCount})`}</Typography>}
          </Stack>
        );
      },
    },
    /*{
      field: 'actions',
      headerName: '',
      width: 12,
      minWidth: 120,
      align: 'right',
      resizable: false,
      hideSortIcons: true,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (param: GridRenderCellParams) => {
        const { row }: { row: Array<IParameter | IGroupParameter> } = param;

        if (row.isGroup) return;
        else {
          const parameters = parameterStore.parameters.filter((parameter) => parameter.groupId === row.groupId && !parameter.isGroup);

          return (
            <>
              {row.id == isHover && (
                <Stack
                  flexDirection="row"
                  justifyContent="flex-end"
                  sx={{
                    svg: {
                      width: 16,
                      height: 16,
                    },
                  }}>
                  <IconButtonNew onClick={() => onAdd(row.id!)} tooltip={Translator.translate('actions.add')}>
                    <PlusIcon />
                  </IconButtonNew>

                  <IconButtonNew
                    onClick={() => changeOrder(-1, row.id!)}
                    tooltip={Translator.translate('actions.up')}
                    disabled={parameters[0]?.id === row.id || parameterStore.checkedParameters.length > 0}>
                    <UpIcon />
                  </IconButtonNew>

                  <IconButtonNew
                    onClick={() => changeOrder(1, row.id!)}
                    tooltip={Translator.translate('actions.down')}
                    disabled={parameters[parameters.length - 1]?.id === row.id || parameterStore.checkedParameters.length > 0}>
                    <DownIcon />
                  </IconButtonNew>

                  <IconButtonNew tooltip={Translator.translate('actions.delete')} onClick={() => onDelete(row.id!)}>
                    <TrashIcon />
                  </IconButtonNew>
                </Stack>
              )}
            </>
          );
        }
      },
    },*/
  ];

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    width: 10,
    minWidth: 30,
    headerName: '',
    renderCell: (params) => <CustomCellGrouping isGroup={params.row.hasOwnProperty('parameterIds')} {...params} />,
  };

  const onMultipleSelectChange = async (id: number, field: string, value: string[]) => {
    const initialValue = parameterStore.selectedParameterTable[field as keyof ParameterModel] as string[];

    if (parameterStore.getParameterIds.length > 0) {
      if (initialValue.length !== value.length || !value.every((value) => initialValue.includes(value))) {
        setNewValues(field, value);
        parameterStore.updateParamDocSettings().finally(() => apiRef.current.stopCellEditMode({ id, field }));
      } else {
        parameterStore.setCheckedParameters([]);
        apiRef.current.stopCellEditMode({ id, field });
      }

      return;
    }

    if (initialValue.length !== value.length) {
      setNewValues(field, value);
      parameterStore.updateParam().finally(() => apiRef.current.stopCellEditMode({ id, field }));
      return;
    }

    if (!value.every((value) => initialValue.includes(value))) {
      setNewValues(field, value);
      parameterStore.updateParam().finally(() => apiRef.current.stopCellEditMode({ id, field }));
    } else {
      apiRef.current.stopCellEditMode({ id, field });
    }
  };

  const getStagesColumns = (): GridColumns => {
    const columns: GridColumns = [
      {
        field: 'name',
        headerName: Translator.translate('params.parameter.columns.name'),
        flex: 1,
        minWidth: 150,
        resizable: true,
        hideSortIcons: true,
        editable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        disableReorder: true,
        colSpan: (param: GridCellParams) => {
          const { row }: { row: IParameter | IGroupParameter } = param;

          return !row.hasOwnProperty('parameterIds') ? 1 : sectionSetStore.sectionSets.length;
        },
      },
    ];

    const res = sectionSetStore.sectionSets.map((el) => {
      return {
        field: `${el.id}`,
        headerName: `${el.name}`,
        flex: 1,
        minWidth: 80,
        resizable: true,
        hideSortIcons: true,
        editable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        disableReorder: true,
        renderCell: (param: GridRenderCellParams) => {
          const { row }: { row: IParameter } = param;

          return (
            <Typography variant="body2" className={row[el.id] ? s.sectionName : s.emptySectionName} sx={{ wordBreak: 'break-word' }}>
              {row[el.id]}
            </Typography>
          );
        },
      };
    });

    return [...checkboxColumn, ...columns, ...res];
  };

  const setNewValues = (field: string, value: string | number | string[]) => {
    parameterStore.selectedParameterTable.setCurrentEditingColumn(field);

    switch (field) {
      case 'name':
        parameterStore.selectedParameterTable.setName(value as string);
        return;

      case 'description':
        parameterStore.selectedParameterTable.setDescription(value as string);
        return;

      case 'dataType':
        parameterStore.selectedParameterTable.setDataType(value as DataType);
        return;

      case 'measurementUnitId':
        parameterStore.selectedParameterTable.setMeasurementUnitId(value as number);
        return;

      case 'documentSettings':
        parameterStore.selectedParameterTable.setDocSettings(value as DocumentSettingType[]);
        return;

      default:
        return;
    }
  };

  /*const updateParameter = useCallback(
    debounce(() => parameterStore.updateParam(), 350),
    []
  );

  const changeOrder = (order: 1 | -1, rowId: number) => {
    const findParameter = parameterStore.parameters.find((parameter) => parameter.id === rowId && !parameter.isGroup);
    parameterStore.selectedParameterTable = findParameter ?? new Array<IParameter | IGroupParameter>();
    parameterStore.selectedParameterTable.setOrderNum(findParameter!.orderNum! + order);
    updateParameter();
  };

  const onDelete = (rowId: number) => {
    confirm.show('Вы уверены, что хотите удалить параметр?', 'Удалить').then(() => {
      parameterStore.deleteParameter(rowId);
    });
  };

  const onAdd = (rowId: number) => {
    parameterStore.setOpenAddParameterDialog(true);

    const findParam = parameterStore.parameters.find((parameter) => rowId === parameter.id);
    parameterStore.selectedParameterTable = findParam ?? new Array<IParameter | IGroupParameter>();

    const paramGroupId = parameterStore.selectedParameterTable.groupId;
    const orderNum = parameterStore.selectedParameterTable.orderNum;

    parameterStore.selectedParameterTable = new Array<IParameter | IGroupParameter>();
    parameterStore.selectedParameterTable.setOrderNum(orderNum);
    parameterStore.selectedParameterTable.setGroupId(paramGroupId);
  };*/

  const onSelectedRow = async (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    const { row }: { row: IParameter | IGroupParameter } = params;

    if (parameterStore.loadingParameters || parameterStore.updateLoading) return;

    if (row.hasOwnProperty('parameterIds') && params.field !== '__check__') {
      apiRef.current.setRowChildrenExpansion(row.id!, !(params.rowNode.childrenExpanded ?? false));
      parameterStore.selectedParameterTable = new ParameterModel();

      return;
    }

    if (row.hasOwnProperty('parameterIds') && (row as IGroupParameter).parameterIds.length === 0) {
      parameterStore.selectedParameterTable = new ParameterModel();
      return;
    }

    if (params.field !== '__check__' && params.field !== 'actions' && params.cellMode !== 'edit') {
      parameterStore.selectedParameterTable.setId(row.id);
      await parameterStore.getParameterById();
    }

    if (params.field === '__tree_data_group__' || params.field === 'actions') return;

    if (params.field === '__check__') {
      if (row.hasOwnProperty('parameterIds')) {
        (row as IGroupParameter).parameterIds.forEach((_) => parameterStore.setCheckedParameter(_, (event.target as any).checked));
        parameterStore.setCheckedParameter(row.id, (event.target as any).checked);
      } else {
        parameterStore.setCheckedParameter(row.id, (event.target as any).checked);

        const groupId = (row as IParameter).groupId;

        const isAllParameterChecked = (parameterStore.parameters as IGroupParameter[])
          .find((f: IGroupParameter) => f.id === groupId)
          ?.parameterIds.every((parameter) => parameterStore.checkedParameters.includes(parameter));

        isAllParameterChecked !== undefined && parameterStore.setCheckedParameter(groupId, isAllParameterChecked);
      }

      return;
    }

    if (parameterStore.filterParameters.modeParameter === ParameterMode.Parameter) {
      !row.hasOwnProperty('parameterIds') && apiRef.current.startCellEditMode({ id: row.id, field: params.field });
      return;
    }
  };

  const noRows = (): JSX.Element => (
    <Stack alignItems="center" mt={8}>
      <img
        style={{ alignSelf: 'center' }}
        src={
          parameterStore.filterParameters.searchPattern === ''
            ? parameterStore.filterParameters.parameterGroupId
              ? `${process.env.PUBLIC_URL}/img/empty_data.svg`
              : `${process.env.PUBLIC_URL}/img/not_selected_data.svg`
            : `${process.env.PUBLIC_URL}/img/not_found_data.svg`
        }
        alt=""
      />
      <Typography variant="subtitle1" color="text.disabled">
        {parameterStore.filterParameters.searchPattern === ''
          ? parameterStore.filterParameters.parameterGroupId
            ? Translator.translate('system.emptyData')
            : Translator.translate('params.parameter.notSelectedGroup')
          : Translator.translate('system.notFoundData')}
      </Typography>
    </Stack>
  );

  return (
    <Box
      sx={{
        height: `calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight()}px)`,
        width: '100%',
        '.selectedRow': {
          backgroundColor: 'rgba(123, 104, 238, 0.08)',
        },
      }}>
      <DataGridPro
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            color: 'text.secondary',
          },
          '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeader:nth-child(2) .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeader:nth-child(1) .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none',
          },
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
            width: '100%',
          },
          '& .selectedRow': {
            cursor: 'rgba(123, 104, 238, 0.08)',
          },
        }}
        /*componentsProps={{
            row: {
              onMouseEnter: (event: any) => setHover(event.currentTarget.dataset.id),
              onMouseLeave: () => setHover(null),
            },
          }}*/
        apiRef={apiRef}
        checkboxSelection
        hideFooter
        className={s.root}
        getRowHeight={() => {
          return 'auto';
        }}
        headerHeight={36}
        treeData
        rows={parameterStore.filteredParameterTableData}
        isRowSelectable={(params: GridRowParams) => {
          if (params.row.hasOwnProperty('parameterIds') && params.row.parameterIds.length !== 0) return true;

          if (!params.row.hasOwnProperty('parameterIds')) return true;

          return false;
        }}
        isCellEditable={(params: GridCellParams) => {
          return !params.row.isGroup;
        }}
        onCellDoubleClick={(params) => {
          apiRef.current.stopCellEditMode({ id: params.row.id!, field: params.field });
        }}
        columns={parameterStore.filterParameters.modeParameter === ParameterMode.Parameter ? [...checkboxColumn, ...columns] : getStagesColumns()}
        disableColumnMenu
        onCellClick={onSelectedRow}
        onColumnHeaderClick={(params, event: any) => {
          if (params.field !== '__check__') return;

          event.target.checked
            ? parameterStore.setCheckedParameters(parameterStore.filteredParameterTableData.map((_) => _.id))
            : parameterStore.setCheckedParameters([]);
        }}
        getRowClassName={(params) => {
          if (params.id === parameterStore.selectedParameterTable.id) return 'selectedRow';
          else return '';
        }}
        getTreeDataPath={getTreeDataPath}
        selectionModel={parameterStore.checkedParameters}
        groupingColDef={groupingColDef}
        defaultGroupingExpansionDepth={1}
        experimentalFeatures={{ newEditingApi: true }}
        onCellKeyDown={(params, event) => {
          if (event.key === 'Enter' && params.cellMode === 'edit') {
            event.stopPropagation();
          }
        }}
        loading={parameterStore.loadingParameters}
        components={{
          Row: CustomGridParameterRow,
          NoRowsOverlay: noRows,
        }}
      />
    </Box>
  );
};

export default observer(ParameterTable);
