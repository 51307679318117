import { makeAutoObservable, runInAction } from 'mobx';
import { MeasurementUnitService } from 'services';
import { Translator, toastStore } from 'stores';
import { MeasurementUnitType, MeasurementUnitTypeNames, NestedLevel } from 'shared/enums';
import { IGetMeasurementUnitDto, IMeasurementUnit, ISelectOption } from 'shared/interfaces';
import { MeasurementUnitModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

class MeasurementUnitStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public isDialogOpen = false;

  public filterName = '';

  public measurementUnits: IGetMeasurementUnitDto[] = [];

  public selectedMeasurementUnit = new MeasurementUnitModel();

  public get measurementForOptions(): ISelectOption[] {
    return this.measurementUnits.map((item) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
  }

  public get measurementUnitTypeForOptions(): ISelectOption[] {
    return Object.values(MeasurementUnitType).map((item) => {
      return {
        id: item,
        name: Translator.translate(MeasurementUnitTypeNames.get(item)),
      };
    });
  }

  public get filterMeasurementUnits(): IMeasurementUnit[] {
    const filteredMeasurementUnits = this.measurementUnits.filter(
      (unit) => unit.name.toLowerCase().includes(this.filterName.toLowerCase()) || unit.fullName.toLowerCase().includes(this.filterName.toLowerCase())
    );

    return this.prepareMeasurementData(filteredMeasurementUnits);
  }

  public prepareMeasurementData(data: IGetMeasurementUnitDto[]): IMeasurementUnit[] {
    const measurementUnits = data.map((measurementUnit) => {
      return {
        ...measurementUnit,
        level: NestedLevel.First,
        children: [],
      };
    });

    return measurementUnits;
  }

  public setDialogOpen(isOpen: boolean) {
    this.isDialogOpen = isOpen;
  }

  public setEditingMode(id: number) {
    this.setDialogOpen(true);
    this.getMeasurementUnitById(id);
  }

  public async getMeasurementUnits(): Promise<void> {
    try {
      this.isLoading = true;

      const result = await MeasurementUnitService.getAllMeasurementUnits();
      if (!result) return;

      runInAction(() => {
        this.measurementUnits = Utils.sortByField(result, 'name');
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} MeasurementUnits`);
    } finally {
      this.isLoading = false;
    }
  }

  public async getMeasurementUnitById(id: number): Promise<void> {
    try {
      this.isLoading = true;
      const result = await MeasurementUnitService.getMeasurementUnit(id);

      if (!result) return;

      runInAction(() => {
        this.selectedMeasurementUnit = new MeasurementUnitModel(result[0]);
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message);
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} MeasurementUnit`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createMeasurementUnit(): Promise<void> {
    if (!this.selectedMeasurementUnit.postPutDto) return;

    try {
      this.isLoading = true;
      await MeasurementUnitService.postMeasurementUnit(this.selectedMeasurementUnit.postPutDto);
      this.getMeasurementUnits();
    } catch (e: any) {
      toastStore.showError(e.data?.message);
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} MeasurementUnit`);
    } finally {
      this.isLoading = false;
    }
  }

  public async updateMeasurementUnit(): Promise<void> {
    if (!this.selectedMeasurementUnit.id || !this.selectedMeasurementUnit.postPutDto) return;

    try {
      this.isLoading = true;
      await MeasurementUnitService.putMeasurementUnit(this.selectedMeasurementUnit.id, this.selectedMeasurementUnit.postPutDto);
      this.getMeasurementUnits();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} MeasurementUnit`);
    } finally {
      this.isLoading = false;
    }
  }

  public async removeMeasurementUnit(id: number): Promise<void> {
    try {
      this.isLoading = true;
      await MeasurementUnitService.deleteMeasurementUnit(id);
      this.getMeasurementUnits();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} MeasurementUnit`);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new MeasurementUnitStore();
