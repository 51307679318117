import React from 'react';

const ArrowLeftIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F0B1F" fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L4.41421 9L18 9C18.5523 9 19 9.44772 19 10C19 10.5523 18.5523 11 18 11L4.41421 11L6.70711 13.2929C7.09763 13.6834 7.09763 14.3166 6.70711 14.7071C6.31658 15.0976 5.68342 15.0976 5.29289 14.7071L1.29289 10.7071C0.902369 10.3166 0.902369 9.68342 1.29289 9.29289L5.29289 5.29289Z" />
    </svg>
  );
};

export default ArrowLeftIcon;
