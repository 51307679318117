import React from 'react';
import { Translator } from 'stores';
import CheckIcon from 'components/Icons/CheckIcon';
import CloseDialogIcon from 'components/Icons/CloseDialogIcon';
import TrashIcon from 'components/Icons/TrashIcon';
import IconButtonNew from 'components/UI/IconButtonNew';
import { Stack } from '@mui/material';

interface ISuggestionActionProps {
  isHideApprove?: boolean;
  isHideCancel?: boolean;
  isHideDelete?: boolean;
  onApprove?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
}

const SuggestionAction: React.FC<ISuggestionActionProps> = (props) => {
  const { isHideApprove, isHideCancel, isHideDelete, onApprove, onCancel, onDelete } = props;

  const handleApprove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onApprove && onApprove();
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onCancel && onCancel();
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDelete && onDelete();
  };

  return (
    <>
      {!isHideApprove || !isHideCancel || !isHideDelete ? (
        <Stack flexDirection="row" gap={2} alignItems="center">
          {!isHideApprove && (
            <IconButtonNew
              sx={(theme) => ({
                svg: {
                  fill: theme.palette.success.main,
                },
              })}
              tooltip={Translator.translate('actions.agree')}
              onClick={handleApprove}
              disableRipple>
              <CheckIcon />
            </IconButtonNew>
          )}

          {!isHideCancel && (
            <IconButtonNew
              sx={(theme) => ({
                svg: {
                  fill: theme.palette.error.main,
                  width: 16,
                  height: 16,
                },
              })}
              tooltip={Translator.translate('actions.decline')}
              onClick={handleCancel}
              disableRipple>
              <CloseDialogIcon />
            </IconButtonNew>
          )}

          {!isHideDelete && (
            <IconButtonNew
              sx={{
                svg: {
                  width: 16,
                  height: 16,
                },
              }}
              tooltip={Translator.translate('actions.delete')}
              onClick={handleDelete}
              disableRipple>
              <TrashIcon />
            </IconButtonNew>
          )}
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
};

export default SuggestionAction;
