import { makeAutoObservable } from 'mobx';
import { definitionStore } from 'stores';
import {
  IPutSpecificationDto,
  IGetSpecificationDto,
  ISpecificationForm,
  IFileTemplateDto,
  IPostSpecificationCollectionDto,
  ISpecificationCollectionForm,
} from 'shared/interfaces';

class SpecificationModel {
  constructor(dto?: IGetSpecificationDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.definitionId = dto.definitionId;
    this.comment = dto.comment;
    this.fileTemplate = dto.fileTemplate;
  }

  public id: number | null = null;

  public name = '';

  public sectionSetCollectionId: number | null = null;

  public definitionId: number | null = null;

  public fileTemplate = {} as IFileTemplateDto;

  public templateFile: File | null = null;

  public comment = '';

  public updateSpecificationForm(data: ISpecificationForm) {
    this.name = data.name;
    this.comment = data.comment;
    this.templateFile = data.templateFile;
  }

  public updateSpecificationCollectionForm(data: ISpecificationCollectionForm) {
    this.sectionSetCollectionId = data.sectionSetCollection;
  }

  public setSectionSetCollectionId(sectionSetCollectionId: number | null) {
    this.sectionSetCollectionId = sectionSetCollectionId;
  }

  public get postFileDto(): FormData | null {
    if (!this.templateFile) return null;

    const payload = new FormData();
    payload.append('file', this.templateFile);

    return payload;
  }

  public get postDto(): IPostSpecificationCollectionDto | null {
    const definitionId = Number(definitionStore.currentDefinition.id);
    if (!this.sectionSetCollectionId || definitionId === null) return null;

    return {
      sectionSetCollectionId: this.sectionSetCollectionId,
      definitionId: definitionId,
    };
  }

  public get putDto(): IPutSpecificationDto | null {
    if (this.name === '') return null;

    return {
      name: this.name,
      comment: this.comment,
    };
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.comment = '';
    this.sectionSetCollectionId = null;
    this.fileTemplate = {} as IFileTemplateDto;
    this.templateFile = null;
  }
}

export default SpecificationModel;
