import { makeAutoObservable } from 'mobx';
import { ICopySectionSetDto, IGetSectionSetDto, IPostPutSectionSetDto, ISectionSetForm } from 'shared/interfaces';

class SectionSetModel {
  constructor(dto?: IGetSectionSetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.stageIds = dto.stages.map((el) => el.stage.id);
    this.sectionSetCollectionId = dto.sectionSetCollection.id;
  }

  public id: number | null = null;

  public name = '';

  public stageIds: number[] = [];

  public sourceId: number | null = null;

  public sectionSetCollectionId: number | null = null;

  public setSectionSetCollectionId(sectionSetCollectionId: number) {
    this.sectionSetCollectionId = sectionSetCollectionId;
  }

  public updateSectionSetDialogForm(values: ISectionSetForm) {
    this.name = values.name;
    this.stageIds = values.stageIds;
    this.sourceId = values.sectionSet;
    this.sectionSetCollectionId = values.sectionSetCollection;
  }

  public get postPutDto(): IPostPutSectionSetDto | null {
    if (this.name === '' || this.stageIds.length === 0) return null;

    return {
      name: this.name,
      stageIds: this.stageIds,
      sectionSetCollectionId: this.sectionSetCollectionId,
    };
  }

  public get copyDto(): ICopySectionSetDto | null {
    if (!this.sourceId) return null;

    return {
      name: this.name,
      stageIds: this.stageIds,
      sourceId: this.sourceId,
      sectionSetCollectionId: this.sectionSetCollectionId,
    };
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.stageIds = [];
    this.sourceId = null;
    this.sectionSetCollectionId = null;
  }
}

export default SectionSetModel;
