import { GridCellIndexCoordinates, GridRowsProp } from '@mui/x-data-grid-pro';
import { makeAutoObservable, runInAction } from 'mobx';
import { ParameterGroupService, ParameterService } from 'services';
import { Translator, sectionSetStore, toastStore } from 'stores';
import { ActionTableMode, DataType, DataTypeNames, DocumentSettingNames, DocumentSettingType } from 'shared/enums';
import {
  IAutocompleteParamDocSetting,
  IAutocompleteParameter,
  IGetParameterGroupParametersDto,
  IGetSectionSetDto,
  IGroupParameter,
  IParameter,
  IParameterDto,
  ISelectOption,
} from 'shared/interfaces';
import { DocumentListModel, DocumentModel } from 'shared/models';
import { FilterParameterModel, ParameterModel } from 'shared/models/ParameterModel';
import Utils from 'shared/utils/Utils';

class ParameterStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public loadingParameters = false;

  public isDocumentDialogOpen = false;

  public updateLoading = false;

  public selectedParameterTable = new ParameterModel();

  public documentsByParameter: DocumentListModel[] = [];

  public selectDocumentByParameter = new DocumentModel();

  public copyMoveParameterIds: number[] | null = null;

  public actionParameterMode = ActionTableMode.Copy;

  public currentGroupForChecked: number | null = null;

  public checkedParameters: number[] = [];

  public isDialogAddParameter = false;

  public chooseAttachSections: number[] = [];

  public allParameters: IParameterDto[] = [];

  public filterParameters = new FilterParameterModel();

  public parameters: Array<IParameter | IGroupParameter> = [];

  public originParamsGroupDto: IGetParameterGroupParametersDto[] = [];

  public coordinates: GridCellIndexCoordinates | null = null;

  public get parametersForAutocomplete(): IAutocompleteParameter[] {
    return this.allParameters.map((el) => {
      return {
        id: el.id,
        fullName: el.fullName,
      };
    });
  }

  public get paramDocSetForAutocomplete(): IAutocompleteParamDocSetting[] {
    return this.allParameters.map((el) => {
      return {
        id: el.id,
        fullName: el.fullName,
        documentSettings: el.documentSettings,
      };
    });
  }

  public get dataTypeForOptions(): ISelectOption[] {
    return Object.values(DataType).map((item) => {
      return {
        id: item,
        name: Translator.translate(DataTypeNames.get(item)),
      };
    });
  }

  public get docSettingListTypeForOptions(): ISelectOption[] {
    return Object.values(DocumentSettingType).map((item) => {
      return {
        id: item,
        name: Translator.translate(DocumentSettingNames.get(item)),
      };
    });
  }

  public get docSettingUntitledTypeForOptions(): ISelectOption[] {
    return [
      {
        id: DocumentSettingType.HideName,
        name: Translator.translate(DocumentSettingNames.get(DocumentSettingType.HideName)),
      },
    ];
  }

  public get isSelectedParameterFirst(): boolean {
    const parameters = this.filteredParameterTableData.filter(
      (parameter) => parameter.groupId === this.selectedParameterTable?.groupId && !parameter.isGroup
    );

    return parameters[0]?.id === this.selectedParameterTable?.id;
  }

  public get getParameterIds(): number[] {
    const groupIds = new Set(this.parameters.filter((f) => f.hasOwnProperty('parameterIds')).map((_) => _.id));
    const paramIds = this.checkedParameters.filter((id) => !groupIds.has(id));

    return paramIds;
  }

  public get isSelectedParameterLast(): boolean {
    const parameters = this.filteredParameterTableData.filter(
      (parameter) => parameter.groupId === this.selectedParameterTable?.groupId && !parameter.isGroup
    );

    return parameters[parameters.length - 1]?.id === this.selectedParameterTable?.id;
  }

  public get filteredParameterTableData(): GridRowsProp {
    const groupIds = this.parameters
      .filter((parameter) => parameter.fullName.toLowerCase().includes(this.filterParameters.searchPattern.toLowerCase()))
      .map((data) => (data.hasOwnProperty('groupId') ? (data as IParameter).groupId : (data as IGroupParameter).id));

    const setGroupIds = new Set(groupIds);

    const filterData = this.parameters.filter(
      (parameter) => parameter.fullName.toLowerCase().includes(this.filterParameters.searchPattern.toLowerCase()) || setGroupIds.has(parameter.id)
    );

    //фильтрация parameterIds в корневой группе
    filterData.map((filterParameter) => {
      if (filterParameter.hasOwnProperty('parameterIds')) {
        const filterParameterIds = filterData
          .filter((parameter) => parameter.hasOwnProperty('groupId') && (parameter as IParameter).groupId === filterParameter.id)
          .map((data) => data.id);

        (filterParameter as IGroupParameter).parameterIds = filterParameterIds;
      }

      return filterParameter;
    });

    return filterData;
  }

  public setLoadingParameters(value: boolean) {
    this.loadingParameters = value;
  }

  public setCoordinates(value: GridCellIndexCoordinates | null) {
    this.coordinates = value;
  }

  public setDocumentDialogOpen(isOpen: boolean) {
    this.isDocumentDialogOpen = isOpen;
  }

  public setChooseAttachSections(chooseAttachSections: number[]) {
    this.chooseAttachSections = chooseAttachSections;
  }

  public setOpenAddParameterDialog(isDialogAddParamOpen: boolean) {
    this.isDialogAddParameter = isDialogAddParamOpen;
  }

  public setCopyMoveParameterIds(parameterIds: number[]) {
    this.copyMoveParameterIds = parameterIds;
  }

  public setCurrentGroupForChecked(currentGroupForChecked: number) {
    this.currentGroupForChecked = currentGroupForChecked;
  }

  public setCheckedParameter(id: number, checked: boolean) {
    if (checked) {
      this.checkedParameters.push(id);
      this.checkedParameters = [...new Set(this.checkedParameters)];
    } else {
      const idx = this.checkedParameters.indexOf(id);
      if (idx !== -1) {
        this.checkedParameters.splice(idx, 1);
        this.checkedParameters = [...new Set(this.checkedParameters)];
      }
    }
  }

  public setCheckedParameters(ids: number[]) {
    this.checkedParameters = ids;
  }

  public setActionParameterMode(actionParameterMode: ActionTableMode) {
    this.actionParameterMode = actionParameterMode;
  }

  public changeOrder(order: 1 | -1) {
    if (this.selectedParameterTable.id) {
      this.selectedParameterTable.setOrderNum(this.selectedParameterTable.orderNum! + order);
    }
  }

  public addDocument(documents: DocumentListModel[]) {
    documents.forEach((document) => this.documentsByParameter.push(document));
  }

  public deleteDocument(id: number) {
    const findDocumentIndex = this.documentsByParameter.findIndex((doc) => doc.id === id);
    this.documentsByParameter.splice(findDocumentIndex, 1);
  }

  public prepareParameterForDataGrid(data: IGetParameterGroupParametersDto[]): Array<IParameter | IGroupParameter> {
    const res: Array<IParameter | IGroupParameter> = [];

    data.forEach((el) => {
      res.push({
        hierarchy: [el.id],
        fullName: el.fullName,
        id: el.id,
        parameterIds: el.parameters.map((parameter) => parameter.id),
      });

      el.parameters.forEach((item) => {
        const obj: IParameter = {
          ...item,
          hierarchy: [el.id, item.id],
          groupId: el.id,
          measurementUnitId: item.measurementUnit !== null ? item.measurementUnit.id : null,
        };

        sectionSetStore.sectionSets.forEach((sectionSet: IGetSectionSetDto) => {
          obj[sectionSet.id] = obj.sectionParameters?.find((sec) => sec.sectionSetId === sectionSet.id)?.section.shortName ?? null;
        });

        res.push(obj);
      });
    });

    return res;
  }

  public async getParametersAll(dataType?: DataType, searchString?: string) {
    try {
      this.loadingParameters = true;

      const result = await ParameterService.getParametersAll(dataType, searchString);
      if (!result) return;

      runInAction(() => {
        this.allParameters = result;
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} Parameters`);
    } finally {
      this.loadingParameters = false;
    }
  }

  public async getParameterGroupParameters() {
    if (!this.filterParameters.parameterGroupId) return;

    try {
      const result = await ParameterGroupService.getAllParameterGroupParameters({
        parentId: this.filterParameters.parameterGroupId,
        includeRoot: this.filterParameters.includeRoot,
      });

      if (!result) return;

      runInAction(() => {
        this.parameters = this.prepareParameterForDataGrid(result); /* .map((data) => new Array<IParameter | IGroupParameter>(data)); */
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} ParameterGroupParameters`);
    }
  }

  public async getParameterById(): Promise<void> {
    if (!this.selectedParameterTable.id) return;

    try {
      const result = await ParameterService.getById(this.selectedParameterTable.id);
      if (!result) return;

      runInAction(() => {
        this.selectedParameterTable = new ParameterModel(result);
        this.selectDocumentByParameter = new DocumentModel(result);
      });

      if (result.documents.length > 0) {
        const sortArray = Utils.sortByField(result.documents, 'orderNum');
        this.documentsByParameter = sortArray.map((dto) => new DocumentListModel(dto));
      } else this.documentsByParameter = [];
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} Parameter`);
    } finally {
    }
  }

  public async createParameter(): Promise<void> {
    if (!this.selectedParameterTable.postDto) return;

    try {
      const result = await ParameterService.postParameter(this.selectedParameterTable.postDto);
      await this.getParameterGroupParameters();

      const findEl = this.parameters.find((f) => f.id === result);
      //this.selectedParameterTable = findEl ?? new ParameterModel();

      /*const rowIndex = this.filteredParameterTableData.findIndex((f) => f.id === result);
      this.setCoordinates({ rowIndex, colIndex: 2 }); */
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} Parameter`);
    }
  }

  public async updateParam(): Promise<void> {
    if (!this.selectedParameterTable.putDto || !this.selectedParameterTable.id) return;

    try {
      this.updateLoading = true;

      await ParameterService.putParameter(this.selectedParameterTable.id!, this.selectedParameterTable.putDto!);
      this.getParameterGroupParameters();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} Param`);
    } finally {
      this.updateLoading = false;
    }
  }

  public async updateParamDocSettings(): Promise<void> {
    if (this.getParameterIds.length === 0) return;

    try {
      this.updateLoading = true;

      await ParameterService.putParameterDocSettings({ settings: this.selectedParameterTable.documentSettings, parameterIds: this.getParameterIds });
      this.getParameterGroupParameters();

      this.checkedParameters = [];
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} Param`);
    } finally {
      this.updateLoading = false;
    }
  }

  public async updateParameterDocument(): Promise<void> {
    if (!this.selectDocumentByParameter.putDto) return;

    try {
      await ParameterService.putParameter(Number(this.selectDocumentByParameter.id), this.selectDocumentByParameter.putDto);
      this.getParameterGroupParameters();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} ParameterDocument`);
    }
  }

  public async copyParameter(): Promise<void> {
    if (!this.copyMoveParameterIds || !this.filterParameters.parameterGroupId) return;
    try {
      await ParameterService.postCopyParameter({
        groupId: this.filterParameters.parameterGroupId,
        parameterIds: this.copyMoveParameterIds,
      });
      this.getParameterGroupParameters();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.copyErrorMessage')} Parameter`);
    } finally {
      //this.checkedParameters = [];
    }
  }

  public async moveParameter(): Promise<void> {
    if (!this.copyMoveParameterIds || !this.filterParameters.parameterGroupId) return;
    try {
      await ParameterService.postMoveParameter({
        groupId: this.filterParameters.parameterGroupId,
        parameterIds: this.copyMoveParameterIds,
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.moveErrorMessage')} Parameter`);
    } finally {
      this.getParameterGroupParameters();
      //this.checkedParameters = [];
    }
  }

  public async deleteParameter(parameterId?: number): Promise<void> {
    try {
      await ParameterService.deleteParameterBatch(parameterId ? [parameterId] : this.getParameterIds);
      await this.getParameterGroupParameters();

      this.selectedParameterTable.clear();
      this.checkedParameters = [];
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} Parameter`);
    }
  }
}

export default new ParameterStore();
