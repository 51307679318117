import React, { useEffect } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ParameterValueService } from 'services';
import { Translator, approvalSuggestionRowStore, parameterListValueStore } from 'stores';
import { CustomDatePicker, DialogWrapper, EditParameterListValue, Input, TogglerTreeState } from 'components';
import { RangeEditor } from 'components/App/EditableTable/editors';
import DateValueIcon from 'components/Icons/DateValueIcon';
import FileValueIcon from 'components/Icons/FileValueIcon';
import LinkValueIcon from 'components/Icons/LinkValueIcon';
import ListIcon from 'components/Icons/ListIcon';
import LogicalValueIcon from 'components/Icons/LogicalValueIcon';
import NumberValueIcon from 'components/Icons/NumberValueIcon';
import RangeValueIcon from 'components/Icons/RangeValueIcon';
import TextValueIcon from 'components/Icons/TextValueIcon';
import { Stack } from '@mui/material';
import { DataType } from 'shared/enums';
import { IParameterSuggestion } from 'shared/interfaces';
import { ParameterListValueModel } from 'shared/models/ParameterListValueModel';

interface ICreateSuggestionValueProps {
  parameterRow: IParameterSuggestion;
}

const CreateSuggestionValueDialog: React.FC<ICreateSuggestionValueProps> = (props) => {
  const { parameterRow } = props;

  useEffect(() => {
    if (parameterRow.dataType === DataType.List) {
      runInAction(() => {
        approvalSuggestionRowStore.approvalSuggestionRow.parameterListValues = parameterRow.parameterListValues!.map((value) => value.id!);
      });
    } else {
      approvalSuggestionRowStore.approvalSuggestionRow.setValue(parameterRow.value!);
    }

    return () => {
      runInAction(() => {
        parameterListValueStore.parameterListValues = [];
      });
    };
  }, []);

  const onCloseCreateValue = () => {
    approvalSuggestionRowStore.setCreateValueDialogOpen(false);
  };

  const onCreateValue = async () => {
    //добавление в массив

    approvalSuggestionRowStore.clearParametersSuggestion();

    if (parameterRow.dataType !== DataType.List) {
      if (approvalSuggestionRowStore.approvalSuggestionRow.value) {
        parameterRow.value = approvalSuggestionRowStore.approvalSuggestionRow.value;
      }
    } else {
      const compactListValue = await ParameterValueService.getCompactListValue({
        listValueIds: approvalSuggestionRowStore.approvalSuggestionRow.parameterListValues,
      });

      if (compactListValue.compactValue && compactListValue.compactValue !== '') {
        parameterRow.value = compactListValue.compactValue;
      } else {
        parameterRow.parameterListValues = parameterListValueStore.parameterListValues.filter((f) =>
          approvalSuggestionRowStore.approvalSuggestionRow.parameterListValues.includes(f.id!)
        );
      }
    }

    approvalSuggestionRowStore.approvalSuggestionRow.setParameterId(parameterRow.id);

    onCloseCreateValue();
  };

  const IconValue = (): JSX.Element => {
    switch (parameterRow.dataType) {
      case DataType.Number:
        return <NumberValueIcon />;

      case DataType.Range:
        return <RangeValueIcon />;

      case DataType.Boolean:
        return <LogicalValueIcon />;

      case DataType.String:
        return <TextValueIcon />;

      case DataType.Link:
        return <LinkValueIcon />;

      case DataType.List:
        return <ListIcon />;

      case DataType.DateTime:
        return <DateValueIcon />;

      case DataType.File:
        return <FileValueIcon />;

      default:
        return <TextValueIcon />;
    }
  };

  const editableCell = (): JSX.Element => {
    const onChangeValue = (value: string) => {
      runInAction(() => {
        approvalSuggestionRowStore.approvalSuggestionRow.parameterListValues = [];
      });

      approvalSuggestionRowStore.approvalSuggestionRow.setValue(value);
    };

    const onChangeList = (list: ParameterListValueModel) => {
      approvalSuggestionRowStore.approvalSuggestionRow.setValue('');
      approvalSuggestionRowStore.approvalSuggestionRow.setParameterListValues(list.id!);
    };

    const onCheckParamListValues = (checkedIds: number[]) => {
      approvalSuggestionRowStore.approvalSuggestionRow.setValue('');
      approvalSuggestionRowStore.approvalSuggestionRow.setParameterListValueIds(checkedIds);
    };

    const onCancelEditData = () => {
      approvalSuggestionRowStore.approvalSuggestionRow.setValue('');
    };

    switch (parameterRow.dataType) {
      case DataType.Range:
        return <RangeEditor value={approvalSuggestionRowStore.approvalSuggestionRow.value!} onChange={onChangeValue} />;

      case DataType.Boolean:
        return <TogglerTreeState selectedTab={approvalSuggestionRowStore.approvalSuggestionRow.value!} onChangeTab={onChangeValue} />;

      case DataType.DateTime:
        return (
          <CustomDatePicker
            value={approvalSuggestionRowStore.approvalSuggestionRow.value}
            outline
            label=""
            onSubmit={(value) => onChangeValue(value as string)}
            onCancel={onCancelEditData}
          />
        );

      case DataType.List:
        return <EditParameterListValue onClickList={onChangeList} onCheckParamListValues={onCheckParamListValues} />;

      default:
        return (
          <Input
            size="small"
            type={parameterRow.dataType === DataType.Number ? 'number' : 'string'}
            variant="outlined"
            autoComplete="off"
            value={approvalSuggestionRowStore.approvalSuggestionRow.value}
            onChange={onChangeValue}
          />
        );
    }
  };

  return (
    <DialogWrapper
      title={Translator.translate('approvals.approvalRowsPage.suggestionDialog.createSuggestionValueDialog.title')}
      subTitle={`${Translator.translate('approvals.approvalRowsPage.suggestionDialog.createSuggestionValueDialog.subTitle')}: ${parameterRow.name}`}
      open={approvalSuggestionRowStore.isCreateValueDialogOpen}
      onClose={onCloseCreateValue}
      onSubmit={onCreateValue}
      onCancel={onCloseCreateValue}
      maxWidth="md">
      <Stack flexDirection="row" gap={2}>
        <Stack>{IconValue()}</Stack>

        {editableCell()}
      </Stack>
    </DialogWrapper>
  );
};

export default observer(CreateSuggestionValueDialog);
