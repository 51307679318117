import React, { useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { definitionStore, Translator, specificationStore } from 'stores';
import { DownloadSpecificationButton, IconButtonNew, InputSearch, NestedTable } from 'components';
import { useConfirm } from 'components/App/Confirm';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import TrashIcon from 'components/Icons/TrashIcon';
import { Box, Button, Chip, Paper, Typography } from '@mui/material';
import { LangNames, NestedLevel, SpecificationApprovalStatus, SpecificationApprovalStatusNames } from 'shared/enums';
import { ISpecificationCollection, ITableColumn } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';
import { SpecificationDialog } from './Components';
import CollectionDialog from './Components/CollectionDialog';

export const HEIGHT_CONTENT = 88;

const SpecificationsPage: React.FC = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { currentDefinitionId } = useParams();
  const [hoveredRowId, setHoveredRowId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    currentDefinitionId && specificationStore.setSpecificationFilters(Number(currentDefinitionId), '');
    setIsLoading(true);
    specificationStore.getSpecifications().finally(() => setIsLoading(false));

    return () => {
      specificationStore.selectedSpecification.clear();
      specificationStore.specificationFiles = [];
      specificationStore.setSpecificationFilters(null, '');
    };
  }, []);

  const paint = (status: SpecificationApprovalStatus) => {
    switch (status) {
      /*case SpecificationApprovalStatus.Formed:
        return 'warning';

      case SpecificationApprovalStatus.InProgress:
        return 'primary';*/

      case SpecificationApprovalStatus.PartiallyApproved:
        return 'info';

      case SpecificationApprovalStatus.Approved:
        return 'success';
    }
  };

  const columns: ITableColumn[] = [
    {
      key: 'collapse',
      label: '',
      align: 'left',
      calcWidth: (row: ISpecificationCollection) => (row.level === NestedLevel.First ? '30px' : '40px'),
    },
    {
      key: 'name',
      label: Translator.translate('specifications.columns.name'),
      align: 'left',
      calcWidth: (row: ISpecificationCollection) => (row.level === NestedLevel.First ? '480px' : '470px'),
      cell: (row: ISpecificationCollection) => (
        <Typography
          variant="body2"
          sx={{
            color: row.level === NestedLevel.Second && row.id === hoveredRowId ? 'primary.main' : 'text.primary',
            textAlign: 'left',
            wordBreak: 'break-word',
            fontWeight: row.level === NestedLevel.First ? 500 : 400,
          }}>
          {row.name}
        </Typography>
      ),
    },
    {
      key: 'date',
      label: Translator.translate('specifications.columns.date'),
      align: 'left',
      width: '150px',
      minWidth: '120px',
      cell: (row: ISpecificationCollection) => {
        if (row.createdAt) {
          return (
            <div>{dayjs(row.createdAt).format(Translator.selectedLocalization === LangNames.Ru ? 'DD.MM.YYYY HH:mm' : 'MM/DD/YYYY h:mm A')}</div>
          );
        } else return null;
      },
    },
    {
      key: 'sectionSet',
      label: Translator.translate('specifications.columns.sectionSet'),
      align: 'left',
      width: 'auto',
      minWidth: '325px',
      cell: (row: ISpecificationCollection) => {
        return <div>{row.sectionSet?.name}</div>;
      },
    },
    {
      key: 'status',
      label: Translator.translate('specifications.columns.status'),
      align: 'left',
      width: '250px',
      minWidth: '200px',
      cell: (row: ISpecificationCollection) => (
        <Chip
          variant="outlined"
          size="small"
          color={paint(row.status!)}
          label={SpecificationApprovalStatusNames.get(row.status!) ? Translator.translate(SpecificationApprovalStatusNames.get(row.status!)) : ''}
        />
      ),
    },
    {
      key: 'comment',
      label: Translator.translate('specifications.columns.comment'),
      align: 'left',
      width: '300px',
      minWidth: '175px',
    },
    {
      key: 'actions',
      label: '',
      align: 'right',
      width: '100px',
      minWidth: '100px',
      cell: (row: ISpecificationCollection) => {
        const onEditSpecification = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();

          specificationStore.setDialogOpen(true);
          await specificationStore.getSpecificationById(row.id);
        };

        const onRemoveSpecificationCollection = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();

          confirm
            .show(Translator.translate('specifications.confirmMessage.deleteCollection'), Translator.translate('actions.delete'))
            .then(() => specificationStore.deleteSpecificationCollection(row.id));
        };

        return (
          <Stack
            flexDirection="row"
            gap={2}
            alignItems="center"
            justifyContent="flex-end"
            sx={(theme) => ({
              mr: 2,
              svg: {
                ':hover': {
                  fill: theme.palette.primary.main,
                  fillOpacity: 1,
                },
              },
            })}>
            {row.level === NestedLevel.Second && (
              <>
                <DownloadSpecificationButton name={row.name} specificationId={row.id} isIconOnly />
                <IconButtonNew tooltip={Translator.translate('actions.edit')} onClick={onEditSpecification}>
                  <EditPencilIcon />
                </IconButtonNew>
              </>
            )}

            {row.level === NestedLevel.First && (
              <IconButtonNew tooltip={Translator.translate('actions.delete')} onClick={onRemoveSpecificationCollection}>
                <TrashIcon />
              </IconButtonNew>
            )}
          </Stack>
        );
      },
    },
  ];

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    specificationStore.setSpecificationFilters(definitionStore.currentDefinitionId, event.target.value);
    updateSpecification();
  };

  const updateSpecification = useCallback(
    debounce(() => {
      specificationStore.filteredSpecificationCollections();
    }, 350),
    []
  );

  const onHover = (rowId: number | null) => {
    setHoveredRowId(rowId);
  };

  const onRedirectSpecification = (selectedRow: ISpecificationCollection) => {
    if (selectedRow.level !== NestedLevel.Second) return;

    //Переход в ТЗ
    specificationStore.getSpecificationById(selectedRow.id);
    //navigate(`${definitionStore.currentDefinition.id}/${routesPath.SPECIFICATIONS}/${selectedRow.id}`);
    navigate(`${selectedRow.id}`);
  };

  return (
    <>
      <Paper elevation={0} sx={{ p: 6, borderRadius: 3, display: 'flex', flex: 1, flexDirection: 'column' }}>
        <Stack direction="row" justifyContent="flex-end" gap={5}>
          <div style={{ width: '200px' }}>
            <InputSearch value={specificationStore.specificationFilters.name} onSearch={() => console.log('1')} onChange={onSearch} />
          </div>

          <Button variant="contained" startIcon={<PlusIconForButton />} onClick={() => specificationStore.setDialogCollectionOpen(true)}>
            {Translator.translate('specifications.addCollection')}
          </Button>
        </Stack>

        <Box sx={{ height: `calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight()}px)` }}>
          <NestedTable<ISpecificationCollection>
            columns={columns}
            tableData={specificationStore.specificationCollections}
            isLoading={isLoading}
            isColorFirstLevel={true}
            isActionsHover
            isExpandOnClickRow
            hasFilteredData={specificationStore.specificationFilters.name !== ''}
            onSelectRow={(selectedRow) => onRedirectSpecification(selectedRow as ISpecificationCollection)}
            onHover={onHover}
            hoveredRowIds={specificationStore.hoveredRowIds}
          />
        </Box>
      </Paper>

      {specificationStore.isDialogOpen && <SpecificationDialog />}
      {specificationStore.isDialogCollectionOpen && <CollectionDialog />}
    </>
  );
};

export default observer(SpecificationsPage);
