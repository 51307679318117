import React from 'react';

const NumberValueIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="rgba(169, 171, 185, 1)" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 2.99997C6 2.59551 5.75636 2.23087 5.38268 2.07609C5.00901 1.92131 4.57889 2.00686 4.29289 2.29286L1.29289 5.29286C0.902369 5.68339 0.902369 6.31655 1.29289 6.70708C1.68342 7.0976 2.31658 7.0976 2.70711 6.70708L4 5.41418V13C4 13.5523 4.44772 14 5 14C5.55228 14 6 13.5523 6 13V2.99997Z" />
      <path d="M10 5.36361C10 4.61049 10.6105 3.99997 11.3636 3.99997C12.2674 3.99997 13 4.73259 13 5.63633V5.89692C13 6.54998 12.763 7.18085 12.3329 7.67233L8.24742 12.3415C7.98904 12.6368 7.92723 13.0559 8.08937 13.4132C8.2515 13.7705 8.60762 14 9 14H14C14.5523 14 15 13.5523 15 13C15 12.4477 14.5523 12 14 12H11.2038L13.8381 8.98934C14.5871 8.13327 15 7.03443 15 5.89692V5.63633C15 3.62802 13.3719 1.99997 11.3636 1.99997C9.50595 1.99997 8 3.50592 8 5.36361V5.85711C8 6.4094 8.44772 6.85711 9 6.85711C9.55229 6.85711 10 6.4094 10 5.85711V5.36361Z" />
    </svg>
  );
};

export default NumberValueIcon;
