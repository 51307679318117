import { BASE_PATH } from 'shared/constants/constants';
import { DataType, DocumentSettingType } from 'shared/enums';
import {
  ICreateParameterCommandDto,
  IParameterByIdDto,
  IParameterCopyMoveDto,
  IParameterDto,
  IParameterFilterDto,
  IParameterDocSettingGroupDto,
  IPutParameterDto,
  IPatchParameterDocSettingDto,
  IPutParameterDocSettingDto,
} from 'shared/interfaces';
import HTTPService from './HttpService';

class ParameterService extends HTTPService {
  constructor() {
    super(BASE_PATH.PARAMETER);
  }

  public getParametersAll(dataType?: DataType, searchString?: string): Promise<IParameterDto[]> {
    const params = {
      dataType,
      searchString,
    };
    return this.GET<IParameterDto[]>('', { params });
  }

  public getById(parameterId: number) {
    return this.GET<IParameterByIdDto>(`${parameterId}`);
  }

  public getParameters(params: IParameterFilterDto): Promise<IParameterDto[]> {
    return this.GET<IParameterDto[]>('', { params });
  }

  public postParameter(body: ICreateParameterCommandDto): Promise<number> {
    return this.POST('', body);
  }

  public putParameter(id: number, body: IPutParameterDto) {
    return this.PUT(`${id}`, body);
  }

  public putParameterDocSettings(body: IPutParameterDocSettingDto): Promise<void> {
    return this.PUT('document-settings', body);
  }

  public postCopyParameter(body: IParameterCopyMoveDto): Promise<number> {
    return this.POST('copy', body);
  }

  public postMoveParameter(body: IParameterCopyMoveDto): Promise<number> {
    return this.POST('move', body);
  }

  public deleteParameterBatch(body: number[]): Promise<number> {
    return this.DELETE('batch', body);
  }

  public getParameterDocSettings(settings: DocumentSettingType): Promise<IParameterDocSettingGroupDto[]> {
    const params = {
      settings,
    };

    return this.GET<IParameterDocSettingGroupDto[]>(`document-settings`, { params });
  }

  public patchParameterDocSettings(id: number, body: IPatchParameterDocSettingDto) {
    return this.PATCH(`${id}/document-settings`, body);
  }
}

export default new ParameterService();
