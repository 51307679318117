import React from 'react';

const ApprovedSuggestionIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#2FCB6D" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM12.0096 6.30033C12.3135 6.01891 12.3317 5.54439 12.0503 5.24045C11.7689 4.93651 11.2944 4.91826 10.9904 5.19967C9.31606 6.75 8.32472 7.71993 6.83703 9.19883L5.022 7.43951C4.72458 7.15122 4.24976 7.15862 3.96147 7.45604C3.67318 7.75346 3.68058 8.22828 3.978 8.51657L6.3219 10.7885C6.61559 11.0732 7.08321 11.0701 7.37306 10.7815C9.16465 8.99779 10.1669 8.00643 12.0096 6.30033Z"
      />
    </svg>
  );
};

export default ApprovedSuggestionIcon;
