import React, { ChangeEvent, useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator } from 'stores';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import { Stack, Button, Box } from '@mui/material';
import { IAutocompleteParameter } from 'shared/interfaces';
import ConditionListModel from 'shared/models/ConditionListModel';
import OperationRow from './OperationRow';

interface IOperationRowsProps {
  operationRows: ConditionListModel[];
  onChangeParameter: (value: IAutocompleteParameter, row: ConditionListModel) => void;
  onChangeValues: (e: ChangeEvent<any>, row: ConditionListModel) => void;
  onDeleteRow: (row: ConditionListModel) => void;
  onAddRow: () => void;
}

const OperationRows: React.FC<IOperationRowsProps> = (props) => {
  const { operationRows, onChangeValues, onDeleteRow, onChangeParameter, onAddRow } = props;

  const operationRowRef = useRef<null | HTMLDivElement>(null);
  const [isAdd, setIsAdd] = useState(false);

  useEffect(() => {
    if (isAdd) {
      operationRowRef && operationRowRef.current?.scrollIntoView({ block: 'end', behavior: 'smooth' });
      setIsAdd(false);
    }
  }, [isAdd]);

  const onAddOperationRow = () => {
    onAddRow();
    setIsAdd(true);
  };

  return (
    <>
      <Stack paddingTop={2} marginBottom={6} gap={6} maxHeight={document.documentElement.clientHeight - 410} sx={{ overflowY: 'auto' }}>
        {operationRows.map((row, idx) => {
          return (
            <OperationRow
              ref={idx === operationRows.length - 1 ? operationRowRef : undefined}
              key={idx}
              parameter={row.parameterThen}
              listValue={row.parameterListValueThen.id!}
              options={row.selectValueOptions}
              onChangeParameter={(value) => onChangeParameter(value, row)}
              onChangeValues={(e) => onChangeValues(e, row)}
              onDelete={() => onDeleteRow(row)}
            />
          );
        })}
      </Stack>

      <Button
        variant="text"
        sx={{ width: 'fit-content' }}
        onClick={onAddOperationRow}
        startIcon={
          <Box
            sx={(theme) => ({
              svg: {
                fill: theme.palette.primary.main,
              },
            })}>
            <PlusIconForButton />
          </Box>
        }>
        {Translator.translate('common.operationRows.actionAddTitle')}
      </Button>
    </>
  );
};

export default observer(OperationRows);
