import React from 'react';

const DocIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z" fill="#E9E9F4" />
      <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#A9ABB9" />
      <path d="M30 14L24 8H30V14Z" fill="#7B68EE" fillOpacity="0.08" />
      <path d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z" fill="#016BDC" />
      <path
        d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z"
        fill="url(#paint0_linear_12607_183095)"
        fillOpacity="0.24"
      />
      <path
        d="M5.78607 24C5.52207 24 5.23407 23.855 5.23407 23.504V18.963C5.23407 18.675 5.52207 18.467 5.78607 18.467H7.61707C11.2711 18.467 11.1901 24 7.68807 24H5.78607ZM6.29007 19.442V23.024H7.61707C9.77607 23.024 9.87107 19.442 7.61707 19.442H6.29007Z"
        fill="white"
      />
      <path
        d="M14.25 24.0801C12.771 24.1441 11.235 23.1601 11.235 21.2011C11.235 19.2341 12.77 18.2671 14.25 18.2671C15.649 18.3381 17.112 19.3061 17.112 21.2011C17.112 23.0971 15.649 24.0801 14.25 24.0801ZM14.162 19.3071C13.266 19.3071 12.291 19.9391 12.291 21.2021C12.291 22.4581 13.267 23.0981 14.162 23.0981C15.082 23.0981 16.065 22.4581 16.065 21.2021C16.065 19.9381 15.082 19.3071 14.162 19.3071Z"
        fill="white"
      />
      <path
        d="M18.0532 21.193C18.0532 19.65 19.0212 18.323 20.8602 18.323C21.5561 18.323 22.1081 18.531 22.6911 19.035C22.9071 19.234 22.9311 19.586 22.7151 19.81C22.4991 20.001 22.1711 19.978 21.9791 19.786C21.6521 19.442 21.3161 19.347 20.8602 19.347C19.6292 19.347 19.0382 20.219 19.0382 21.194C19.0382 22.186 19.6212 23.097 20.8602 23.097C21.3161 23.097 21.7401 22.912 22.1081 22.585C22.3551 22.393 22.6991 22.488 22.8431 22.681C22.9711 22.857 23.0341 23.153 22.7551 23.432C22.1951 23.953 21.5241 24.057 20.8592 24.057C18.9252 24.057 18.0532 22.736 18.0532 21.193Z"
        fill="white"
      />
      <path d="M25 27H6V28H25C25.55 28 26 27.55 26 27V26C26 26.55 25.55 27 25 27Z" fill="#7B68EE" fillOpacity="0.12" />
      <defs>
        <linearGradient id="paint0_linear_12607_183095" x1="14" y1="15" x2="14" y2="27" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DocIcon;
