import { makeAutoObservable, runInAction } from 'mobx';
import { SectionSetCollectionService } from 'services';
import { Translator, sectionSetStore, toastStore } from 'stores';
import { IGetSectionSetCollectionDto, ISelectOption } from 'shared/interfaces';
import { SectionSetCollectionModel } from 'shared/models';

class SectionSetCollectionStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public isDialogOpen = false;

  public sectionSetCollections: IGetSectionSetCollectionDto[] = [];

  public selectedSectionSetCollection = new SectionSetCollectionModel();

  public get sectionSetCollectionOptions(): ISelectOption[] {
    return this.sectionSetCollections.map((item) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
  }

  public setDialogOpen(isOpen: boolean) {
    this.isDialogOpen = isOpen;
  }

  public setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  public async getSectionSetCollections(): Promise<void> {
    try {
      this.setIsLoading(true);

      const result = await SectionSetCollectionService.getAllSectionSetCollections();
      if (!result) return;

      runInAction(() => {
        this.sectionSetCollections = result;
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} SectionSetCollections`);
    } finally {
      this.setIsLoading(false);
    }
  }

  public async getSectionSetCollection(id: number): Promise<void> {
    this.setIsLoading(true);

    try {
      const result = await SectionSetCollectionService.getSectionSetCollection(id);

      if (result.length > 0) {
        runInAction(() => {
          this.selectedSectionSetCollection = new SectionSetCollectionModel(result[0]);
        });
      }
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} SectionSetCollection`);
    } finally {
      this.setIsLoading(false);
    }
  }

  public async updateSectionSetCollections(): Promise<void> {
    if (!this.selectedSectionSetCollection.id || !this.selectedSectionSetCollection.putDto) return;

    try {
      this.setIsLoading(true);

      await SectionSetCollectionService.putSectionSetCollection(this.selectedSectionSetCollection.id, this.selectedSectionSetCollection.putDto);
      sectionSetStore.getSectionSets();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} SectionSetCollection`);
    } finally {
      this.setIsLoading(false);
    }
  }
}

export default new SectionSetCollectionStore();
