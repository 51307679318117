import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Translator, classificationDictionaryStore, classificationDictionaryValueStore, definitionStore, projectStore } from 'stores';
import { InputSearch, Switch, Toggler } from 'components';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import { Button, Divider, Paper, Stack } from '@mui/material';
import { MAIN_PROJECT } from 'shared/constants/constants';
import { ProjectType } from 'shared/enums';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import { DefinitionDialog, MainTable, ProjectDialog, RecentlyDefinitions } from './Components';

import s from './MainPage.module.scss';

const MainPage: React.FC = () => {
  const [filterName, setFilterName] = useState('');

  useEffect(() => {
    localStorage.removeItem('currentDefinitionId');
    definitionStore.currentDefinition.clear();
    definitionStore.getRecentlyDefinitions();
    classificationDictionaryStore.getDictionaryList().then(async () => {
      const ids = classificationDictionaryStore.dictionaries.map((el) => el.id);
      await classificationDictionaryValueStore.getDictionaryValueList(ids);
    });
    projectStore.projectFilters.setType(ProjectType.Standard);
    projectStore.getProjectList();

    return () => {
      projectStore.projectFilters.clear();
      projectStore.projectsByType = [];
      projectStore.setFilterName('');
    };
  }, []);

  const onChangeProjectType = (value: string | number) => {
    projectStore.projectFilters.clearColumnFilters();
    projectStore.projectFilters.setType(value as ProjectType);
    projectStore.getProjectList();
  };

  const onCreateDefinition = () => {
    projectStore.getProjectListByStandard().then(() => {
      const findMainProject = projectStore.projectsByStandard.find((f) => (f.id as number) === MAIN_PROJECT);

      projectStore.selectedProject.setProjectId(findMainProject ? (findMainProject.id as number) : null);

      if (projectStore.projectFilters.type === ProjectType.Local) {
        projectStore.selectedProject.setBuildingType(classificationDictionaryValueStore.buildingTypes[0].id as number);
      }

      projectStore.setOpenDialog(true);
    });
  };

  useEffect(() => {
    const idx = projectStore.projectsByType.findIndex((_) => _.id === Number(projectStore.currentProjectId));
    if (idx > -1) projectStore.projectsByType[idx].setCollapseRow(true);
  }, [definitionStore.currentDefinition.id]);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.target.value);

    if (event.target.value === '') {
      projectStore.setFilterName('');
    }
  };

  const onClickSearch = () => {
    projectStore.setFilterName(filterName);
  };

  const onClose = () => {
    definitionStore.setDialogOpen(false);
  };

  return (
    <>
      {definitionStore.recentlyDefinitions.length > 0 && <RecentlyDefinitions />}

      <Paper elevation={0} sx={{ p: 6, borderRadius: '12px', flex: 1 }} component={Stack}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Toggler
            btnWidth="125px"
            value={projectStore.projectFilters.type!}
            tabItems={projectStore.projectTypesForRadioGroup}
            onChangeTab={onChangeProjectType}
          />

          <Stack direction="row" gap={5} minWidth={625} justifyContent="flex-end">
            <Switch
              label={Translator.translate('projects.myProjects')}
              labelPlacement="end"
              checked={projectStore.isShowAvailableProjects}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => projectStore.setShowAvailableProjects(event.target.checked)}
            />

            <div className={s.searchWrapper}>
              <InputSearch value={filterName} onSearch={onClickSearch} onChange={onSearch} />
            </div>

            <Button variant="contained" startIcon={<PlusIconForButton />} onClick={onCreateDefinition}>
              {Translator.translate('projects.addProject')}
            </Button>
          </Stack>
        </Stack>

        <Divider />

        <MainTable />

        {projectStore.isDialogOpen && <ProjectDialog />}
        {definitionStore.isDialogOpen && <DefinitionDialog open={definitionStore.isDialogOpen} onClose={onClose} />}
      </Paper>
    </>
  );
};
export default observer(MainPage);
