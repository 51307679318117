import React from 'react';

const PinIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="rgba(15, 11, 31, 0.54)" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.801 3.7922L10.7009 3.69208C10.3138 3.30499 10.3138 2.6774 10.7009 2.29031C11.088 1.90323 11.7156 1.90323 12.1026 2.29031L17.7097 7.89736C18.0968 8.28444 18.0968 8.91203 17.7097 9.29912C17.3226 9.68621 16.695 9.68621 16.3079 9.29912L16.2078 9.19899L13.4043 12.0025C12.9529 12.4539 12.7052 12.9651 12.6342 13.3913C12.5628 13.8198 12.6739 14.0756 12.8035 14.2053C13.1906 14.5924 13.1906 15.22 12.8035 15.607C12.4164 15.9941 11.7888 15.9941 11.4018 15.607L8.59824 12.8035L3.69208 17.7097C3.30499 18.0968 2.6774 18.0968 2.29031 17.7097C1.90323 17.3226 1.90323 16.695 2.29031 16.3079L7.19648 11.4018L4.39296 8.59824C4.00587 8.21115 4.00587 7.58356 4.39296 7.19648C4.78004 6.80939 5.40763 6.80939 5.79472 7.19648C5.92435 7.32611 6.1802 7.43722 6.60873 7.3658C7.03494 7.29476 7.54609 7.04711 7.99748 6.59572L10.801 3.7922ZM12.2028 5.19396L9.39924 7.99748C8.89896 8.49777 8.31596 8.87766 7.70785 9.10962L10.8904 12.2921C11.1223 11.684 11.5022 11.101 12.0025 10.6008L14.806 7.79723L12.2028 5.19396Z"
      />
    </svg>
  );
};

export default PinIcon;
