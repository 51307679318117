import { makeAutoObservable } from 'mobx';
import { IGetGlobalSettingDto, IPutGlobalSettingDto } from 'shared/interfaces';

class GlobalSettingModel {
  constructor(dto?: IGetGlobalSettingDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.initByDto(dto);
  }

  public id: number | null = null;

  public name = '';

  public value: number | null = null;

  public get putDto(): IPutGlobalSettingDto | null {
    if (this.name === '') return null;

    return {
      name: this.name,
      value: !this.value ? '' : String(this.value),
    };
  }

  public initByDto(dto: IGetGlobalSettingDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.value = dto.value.length > 0 ? Number(dto.value) : null;
  }

  public setValue(value: string) {
    this.value = value.length > 0 ? Number(value) : null;
  }

  public clear() {
    this.id = null;
    this.name = '';
    this.value = null;
  }
}

export default GlobalSettingModel;
