import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { Translator, usersStore } from 'stores';
import { Link, Menu, Stack, Typography } from '@mui/material';
import { RolesNames } from 'shared/enums';
import { Routes } from 'shared/enums/Routes';

interface IUserInfoProps {
  userAnchorEl: null | HTMLElement;
  onClose: () => void;
}

const UserInfo: React.FC<IUserInfoProps> = (props) => {
  const { userAnchorEl, onClose } = props;

  const navigate = useNavigate();
  const { currentDefinitionId } = useParams();

  const redirectToRolePage = () => {
    usersStore.setShowAvailableInfo(true);
    usersStore.setUserFiltersId(usersStore.getUserData().id);
    usersStore.getUsersList();
    navigate(currentDefinitionId ? `/${currentDefinitionId}${Routes.SETTINGS}/${Routes.ROLES}` : `${Routes.SETTINGS}/${Routes.ROLES}`);

    onClose();
  };

  return (
    <Menu
      sx={{
        '.MuiPaper-root': {
          borderRadius: '12px',
          padding: '12px',
        },
        '.MuiList-root': {
          padding: '0px',
        },
      }}
      open={!!userAnchorEl}
      anchorEl={userAnchorEl}
      onClose={onClose}>
      <Stack width="15vw">
        <Typography sx={{ fontWeight: 500, lineHeight: 'unset' }} variant="h3">
          {Translator.translate('userInfo.userName')}
        </Typography>
        <Typography sx={{ ml: 1 }}>{usersStore.getUserData().name ? usersStore.getUserData().name : '—'}</Typography>
        <Typography sx={{ fontWeight: 500, lineHeight: 'unset' }} variant="h3">
          {Translator.translate('userInfo.email')}
        </Typography>
        <Typography sx={{ ml: 1 }}>{usersStore.getUserData().email ? usersStore.getUserData().email : '—'}</Typography>
        <Link sx={{ textAlign: 'left' }} component="button" onClick={() => redirectToRolePage()}>
          <Typography sx={{ fontWeight: 500, lineHeight: 'unset' }} variant="h3">
            {Translator.translate('userInfo.myRoles')}
          </Typography>
        </Link>
        <Typography sx={{ ml: 1 }}>
          {usersStore.getUserData().roles
            ? usersStore
                .getUserData()
                .roles?.map((f: any) => Translator.translate(RolesNames.get(f)))
                .join(', ')
            : '—'}
        </Typography>
      </Stack>
    </Menu>
  );
};

export default observer(UserInfo);
