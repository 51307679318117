import React from 'react';

const CopyIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#7B68EE" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.15771 3.15771C3.25869 3.05673 3.39565 3 3.53846 3H10.4615C10.6043 3 10.7413 3.05673 10.8423 3.15771C10.9433 3.25869 11 3.39565 11 3.53846V4.30769C11 4.85998 11.4477 5.30769 12 5.30769C12.5523 5.30769 13 4.85998 13 4.30769V3.53846C13 2.86522 12.7326 2.21955 12.2565 1.7435C11.7804 1.26744 11.1348 1 10.4615 1H3.53846C2.86522 1 2.21955 1.26744 1.7435 1.7435C1.26744 2.21955 1 2.86522 1 3.53846V10.4615C1 11.1348 1.26744 11.7804 1.7435 12.2565C2.21955 12.7326 2.86522 13 3.53846 13H4.30769C4.85998 13 5.30769 12.5523 5.30769 12C5.30769 11.4477 4.85998 11 4.30769 11H3.53846C3.39565 11 3.25869 10.9433 3.15771 10.8423C3.05673 10.7413 3 10.6043 3 10.4615V3.53846C3 3.39565 3.05673 3.25869 3.15771 3.15771Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53846 7C8.13651 7 7 8.13651 7 9.53846V16.4615C7 17.8635 8.13651 19 9.53846 19H16.4615C17.8635 19 19 17.8635 19 16.4615V9.53846C19 8.13651 17.8635 7 16.4615 7H9.53846ZM9 9.53846C9 9.24108 9.24108 9 9.53846 9H16.4615C16.7589 9 17 9.24108 17 9.53846V16.4615C17 16.7589 16.7589 17 16.4615 17H9.53846C9.24108 17 9 16.7589 9 16.4615V9.53846Z"
      />
    </svg>
  );
};

export default CopyIcon;
