import React from 'react';
import { Translator } from 'stores';
import CloseIcon from 'components/Icons/CloseIcon';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import { Tabs as MuiTabs, Tab, Stack, Box, Typography } from '@mui/material';
import IconButtonNew from '../IconButtonNew';
import { PartialApprovalStatus } from 'shared/enums';
import { ITabItem } from 'shared/interfaces';

import s from './TabsContent.module.scss';

interface ITabsContentProps {
  selectedTab: number;
  tabItems: ITabItem[];
  scrollButtons?: boolean;
  tabWidth?: string;
  onChangeTab: (event: React.SyntheticEvent<Element, Event>, value: number) => void;
  onAddTab?: () => void;
  onDeleteTab?: (id: number) => void;
}

const TabsContent: React.FC<ITabsContentProps> = (props) => {
  const { tabItems, selectedTab, tabWidth, scrollButtons, onChangeTab, onAddTab, onDeleteTab } = props;

  return (
    <>
      <MuiTabs
        value={selectedTab}
        scrollButtons={scrollButtons ?? false}
        variant="scrollable"
        onChange={onChangeTab}
        color="primary"
        sx={(theme) => ({
          width: 'auto',
          minHeight: 'auto',
          '.MuiTabs-scrollButtons.Mui-disabled': {
            opacity: 0.3,
          },
          '.MuiTabs-indicator': {
            display: 'none',
          },
          '.MuiTabs-flexContainer': {
            alignItems: 'center',
          },
          '.MuiTabScrollButton-root': {
            svg: {
              fill: theme.palette.primary.main,
            },
          },
        })}>
        {tabItems.map((tab) => (
          <Tab
            disableRipple
            style={{ width: tabWidth }}
            sx={{
              width: 'fit-content',
              maxWidth: 'unset !important',
              minHeight: 'auto',
            }}
            className={tab.value === selectedTab ? s.buttonActive : s.button}
            iconPosition="start"
            key={tab.value}
            value={tab.value}
            label={
              <Stack flexDirection="row" alignItems="center" className={tab.value === selectedTab ? s.labelActive : s.label}>
                {tab.label}

                {tab.value === selectedTab && (
                  <>
                    {onDeleteTab && tab.status !== PartialApprovalStatus.Canceled && (
                      <IconButtonNew
                        sx={(theme) => ({
                          width: '5px',
                          height: '5px !important',
                          svg: {
                            fill: theme.palette.common.white,
                          },
                        })}
                        tooltip={Translator.translate('actions.delete')}
                        onClick={() => onDeleteTab(tab.value as number)}
                        disableRipple>
                        <CloseIcon />
                      </IconButtonNew>
                    )}
                  </>
                )}
              </Stack>
            }
          />
        ))}

        {onAddTab && (
          <IconButtonNew tooltip={Translator.translate('actions.add')} onClick={onAddTab}>
            <Box
              sx={(theme) => ({
                svg: {
                  fill: theme.palette.primary.main,
                },
              })}>
              <PlusIconForButton />
            </Box>
          </IconButtonNew>
        )}
      </MuiTabs>

      {tabItems.map((tab) => (
        <Box
          role="tabpanel"
          key={tab.value}
          height={selectedTab === tab.value ? '100%' : ''}
          border={selectedTab === tab.value ? '2px solid ' : ''}
          borderRadius="6px"
          overflow="auto"
          sx={(theme) => ({
            borderColor: theme.palette.primary.main,
          })}>
          {selectedTab === tab.value ? (
            tab.content ? (
              tab.content
            ) : (
              <Stack alignItems="center" height="100%" justifyContent="center">
                <img style={{ alignSelf: 'center' }} src={`${process.env.PUBLIC_URL}/img/empty_data.svg`} alt="" />
                <Typography variant="subtitle1" color="text.disabled">
                  {Translator.translate('system.emptyData')}
                </Typography>
              </Stack>
            )
          ) : (
            <></>
          )}
        </Box>
      ))}
    </>
  );
};

export default TabsContent;
