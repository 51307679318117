import React from 'react';

const InsertIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#7B68EE" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3H5.5C4.82498 3 4.1853 3.27842 3.7195 3.76148C3.25488 4.2433 3 4.88928 3 5.55556V16.4444C3 17.1107 3.25488 17.7567 3.7195 18.2385C4.1853 18.7216 4.82498 19 5.5 19H14.5C15.175 19 15.8147 18.7216 16.2805 18.2385C16.7451 17.7567 17 17.1107 17 16.4444V5.55556C17 4.88928 16.7451 4.24329 16.2805 3.76148C15.8147 3.27842 15.175 3 14.5 3H14V2.75C14 1.7835 13.2165 1 12.25 1H7.75C6.7835 1 6 1.7835 6 2.75V3ZM8 4V3H12V4H8ZM13.8316 5C13.5507 5.59124 12.9481 6 12.25 6H7.75C7.0519 6 6.44927 5.59124 6.16841 5H5.5C5.37937 5 5.25599 5.04936 5.15918 5.14975C5.06119 5.25137 5 5.39672 5 5.55556V16.4444C5 16.6033 5.06119 16.7486 5.15918 16.8503C5.25599 16.9506 5.37937 17 5.5 17H14.5C14.6206 17 14.744 16.9506 14.8408 16.8503C14.9388 16.7486 15 16.6033 15 16.4444V5.55556C15 5.39672 14.9388 5.25137 14.8408 5.14975C14.744 5.04936 14.6206 5 14.5 5H13.8316Z"
      />
    </svg>
  );
};

export default InsertIcon;
