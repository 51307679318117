import React, { useEffect, useState } from 'react';
import { Translator } from 'stores';
import { SelectChangeEvent } from '@mui/material';
import { Select } from '../../../index';
import { ISelectProps } from '../../../UI/Select/Select';
import { TableEditorProps } from '../types';

interface SelectEditorProps extends TableEditorProps, Omit<ISelectProps, 'onChange'> {
  isShowIconType?: boolean;
}

export const SelectEditor = (props: SelectEditorProps) => {
  const [value, setValue] = useState<any>(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setValue(value);
    props.onChange && props.onChange(value);

    if (props.onBlur) {
      setTimeout(props.onBlur);
    }
  };

  return (
    <Select
      {...props}
      sx={{ width: '100%' }}
      label={props.label}
      isShowIconType={props.isShowIconType}
      value={value}
      options={props.options ?? []}
      onBlur={props.onBlur}
      onChange={onChange}
      hideNotSelectItem={props.label !== Translator.translate('common.editableTable.selectEditor.hideNotSelectItem')}
      defaultOpen
      autoFocus
    />
  );
};
