import React, { createContext, ReactNode, useContext, useState } from 'react';
import { Confirm } from './Confirm';

interface AuthContextValue {
  show: (content: string | ReactNode, okText?: string, isSuccess?: boolean) => Promise<boolean>;
}

const ConfirmContext = createContext<AuthContextValue>(null!);

export const ConfirmProvider = ({ children }: { children: ReactNode }) => {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [content, setContent] = useState<string | ReactNode>();
  const [okText, setOkText] = useState<string>();
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [promiseState, setPromiseState] = useState<any>();

  const value: AuthContextValue = {
    show: (_content: string | ReactNode, _okText?: string, _isSuccess?: boolean) => {
      setContent(_content);

      if (_okText) {
        setOkText(_okText);
      }

      if (_isSuccess) {
        setSuccess(_isSuccess);
      }

      setConfirmVisible(true);
      return new Promise((resolve, reject) => setPromiseState({ resolve, reject }));
    },
  };

  const onOk = () => {
    if (promiseState?.resolve) {
      promiseState.resolve();
    }

    resetAndClose();
  };

  const onCancel = () => {
    if (promiseState?.reject) {
      promiseState.reject();
    }

    resetAndClose();
  };

  const resetAndClose = () => {
    setOkText('');
    setContent('');
    setPromiseState(null);
    setConfirmVisible(false);
    setSuccess(false);
  };

  return (
    <>
      <ConfirmContext.Provider value={value}>{children}</ConfirmContext.Provider>
      <Confirm content={content} okText={okText} visible={confirmVisible} onOk={onOk} onCancel={onCancel} isSuccess={isSuccess} />
    </>
  );
};

export const useConfirm = () => useContext(ConfirmContext);
