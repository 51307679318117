import { BASE_PATH } from 'shared/constants/constants';
import { IGetXmlSchemaTemplateByIdDto } from 'shared/interfaces';
import HTTPService from './HttpService';

/* Сервис для получения данных схем xml*/
class XmlSchemaTemplateService extends HTTPService {
  constructor() {
    super(BASE_PATH.XSD);
  }

  public getXmlSchemaTemplateById(xsdId: number) {
    return this.GET<IGetXmlSchemaTemplateByIdDto>(`${xsdId}`);
  }

  public postXmlSchemaTemplate(body: FormData): Promise<number> {
    return this.POST('', body, { headers: { 'Content-Type': 'multipart/form-data' } });
  }
}

export default new XmlSchemaTemplateService();
