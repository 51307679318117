import { BASE_PATH } from 'shared/constants/constants';
import { IGetDocumentDto, IPostPutDocumentDto } from 'shared/interfaces';
import HTTPService from './HttpService';

class DocumentService extends HTTPService {
  constructor() {
    super(BASE_PATH.DOCUMENTS);
  }

  public getAllDocuments(): Promise<IGetDocumentDto[]> {
    return this.GET('');
  }

  public getDocument(id: number): Promise<IGetDocumentDto[]> {
    return this.GET('', { params: { id } });
  }

  public postDocument(body: IPostPutDocumentDto): Promise<number> {
    return this.POST('', body);
  }

  public putDocument(id: number, body: IPostPutDocumentDto): Promise<IGetDocumentDto> {
    return this.PUT(`${id}`, body);
  }

  public deleteDocument(id: number): Promise<void> {
    return this.DELETE(`${id}`);
  }
}

export default new DocumentService();
