import React, { useEffect, useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { Translator, xmlDocumentInstanceStore } from 'stores';
import { InputSearch, TableView } from 'components';
import PlusIconForButton from 'components/Icons/PlusIconForButton';
import { Stack, Button, Box, CircularProgress, Typography } from '@mui/material';
import { IGetXmlDocumentDto, ITableColumn } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';
import XMLDocumentsDialog from './Components';

const HEIGHT_CONTENT = 148;

const XMLDocuments: React.FC = () => {
  const navigate = useNavigate();
  const [hoveredRowId, setHoveredRowId] = useState<number | null>(null);

  const columns: ITableColumn[] = [
    {
      key: 'name',
      label: Translator.translate('catalogs.xmlDocuments.columns.name'),
      width: '400px',
      align: 'left',
      cell: (row: IGetXmlDocumentDto) => {
        return (
          <Typography
            variant="body2"
            sx={{
              color: row.id === hoveredRowId ? 'primary.main' : 'text.primary',
              textAlign: 'left',
              wordBreak: 'break-word',
              fontWeight: 400,
            }}>
            {row.name}
          </Typography>
        );
      },
    },
  ];

  useEffect(() => {
    xmlDocumentInstanceStore.getXmlDocuments();
  }, []);

  const onOpenCreateDialog = () => {
    xmlDocumentInstanceStore.setDialogOpen(true);
  };

  const onChangeParamSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    xmlDocumentInstanceStore.filterXmlDocuments.setName(event.target.value);
    updateXmlDocuments();
  };

  const updateXmlDocuments = useCallback(
    debounce(() => {
      xmlDocumentInstanceStore.getXmlDocuments();
    }, 350),
    []
  );

  const onHover = (rowId: number | null) => {
    setHoveredRowId(rowId);
  };

  const onRedirect = (row: any) => {
    navigate(`${row.id}`);
  };

  return (
    <Stack gap={2} alignItems="flex-start">
      <Stack direction="row" gap={5} width="100%" justifyContent="end">
        <Box width="20vw">
          <InputSearch value={xmlDocumentInstanceStore.filterXmlDocuments.name} onSearch={() => console.log('1')} onChange={onChangeParamSearch} />
        </Box>

        <Button variant="contained" startIcon={<PlusIconForButton />} onClick={onOpenCreateDialog}>
          {Translator.translate('actions.add')}
        </Button>
      </Stack>

      <Box sx={{ height: `calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight()}px)`, width: '100%' }}>
        {xmlDocumentInstanceStore.isLoading ? (
          <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableView
            data={xmlDocumentInstanceStore.xmlDocuments}
            columns={columns}
            hideSelectRowOnMount
            isActionsHover
            onHover={onHover}
            onClick={onRedirect}
            height={`calc(100vh - ${HEIGHT_CONTENT + Utils.projectHeight()}px)`}
          />
        )}
      </Box>

      {xmlDocumentInstanceStore.isDialogOpen && <XMLDocumentsDialog />}
    </Stack>
  );
};

export default observer(XMLDocuments);
