import React, { useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { Translator, classStore } from 'stores';
import { DialogWrapper } from 'components';
import { Autocomplete, TextField } from '@mui/material';
import { IClassForm } from 'shared/interfaces';
import Utils from 'shared/utils/Utils';

const AddClassDialog = () => {
  const formRef = useRef<FormikProps<IClassForm>>(null);

  const onSubmit = (values: IClassForm) => {
    classStore.classObject.updateClassDialogForm(values);

    if (!classStore.treeObject.selected || !classStore.classObject.copyClassId) {
      classStore.createClass();
    } else {
      classStore.copyClass();
    }
  };

  const onClose = async () => {
    classStore.classObject.clearModel();
    classStore.setIsDialogCreateOpen(false);
  };

  const addClassForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        className: classStore.classObject.name,
        copyClassId: null,
      }}
      validationSchema={Yup.object().shape({
        className: Yup.string().max(255).required(Translator.translate('classes.validationSchema.className')),
      })}>
      {({ values, errors, touched, setFieldValue }) => {
        return (
          <Autocomplete
            options={
              classStore.treeObject.selectedKeys.length === 0
                ? []
                : classStore.classOptions
                    .filter((item) => item.id !== Number(classStore.treeObject.selectedKeys[0]) && Utils.includes(item.name, values.className))
                    .map((item) => item.name)
            }
            openOnFocus
            clearOnBlur={false}
            //скрыть сообщение "No options"
            freeSolo
            value={values.className}
            onInputChange={(e, value) => {
              setFieldValue('copyClassId', null);
              setFieldValue('className', value);
            }}
            onChange={(e, value) => {
              const findEl = classStore.classesDto.find((f) => f.name === value);

              setFieldValue('copyClassId', findEl ? findEl.id : null);
              setFieldValue('className', value ? value : '');
            }}
            renderInput={(params) => (
              <TextField
                autoFocus
                {...params}
                name="className"
                error={Boolean(touched.className && errors.className)}
                helperText={(touched.className as any) && (errors.className as any)}
                label={
                  classStore.treeObject.selectedKeys.length === 0
                    ? Translator.translate('classes.classDialog.dialogFormFields.classNameNewLabel')
                    : Translator.translate('classes.classDialog.dialogFormFields.classNameSelectLabel')
                }
              />
            )}
          />
        );
      }}
    </Formik>
  );

  return (
    <DialogWrapper
      title={Translator.translate('classes.addClassButtonTitle')}
      maxWidth="md"
      open={classStore.isDialogCreateOpen}
      onClose={onClose}
      onCancel={onClose}
      titleSubmit={Translator.translate('actions.create')}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}>
      {addClassForm}
    </DialogWrapper>
  );
};

export default observer(AddClassDialog);
