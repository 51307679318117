import Keycloak, { KeycloakInitOptions } from 'keycloak-js';

export const keycloak = new Keycloak({
  url: 'https://sso.rubius.com',
  realm: 'etalon',
  clientId: 'etalon-family-manager',
});

export const keycloakOptions: KeycloakInitOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
};
